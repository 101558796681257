import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nutrient-farm-acres',
  templateUrl: './nutrient-farm-acres.component.html',
  styleUrls: ['./nutrient-farm-acres.component.scss']
})
export class NutrientFarmAcresComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
