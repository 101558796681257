/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */


export const CONSTANTS = {
  IS_HEAT_MAP: false,
  INACTIVITY_TIMEOUT: 60 * 60 * 4, // (In seconds) Will log the user out due to inactivity
  // http://prod-api-elb-735590460.us-west-2.elb.amazonaws.com  --> *https://api.ketoswebportal.com*
  // http://prod-api-um-elb-320414578.us-west-2.elb.amazonaws.com --> *https://api-um.ketoswebportal.com*

  // API_URL and API_URL2 constants are used in all API calls
  API_NG: 'https://api.stage.ketoswebportal.com/api/v3.0',
  // API_NG: 'http://213.219.125.148:5775/api/v3.0',
  // API_NG: 'http://localhost:5775/api/v3.0',
  API_SOCKET: 'https://api.stage.ketoswebportal.com/messages',
  OLD_URL: 'http://ec2-54-184-226-234.us-west-2.compute.amazonaws.com/',

  // API_DS: 'https://na4.docusign.net',
  // DS_AID: '201b35b2-5d75-4f8e-97d6-9fd862d5dd8d',
  // DS_CID: 'eec38294-9616-4745-bc41-c0d69b3a1b74',
  API_DS: 'https://demo.docusign.net',
  DS_AID: 'd68c4d8e-5674-407f-8b7e-994ae8cbcc08',
  DS_CID: '9454acf1-555b-4472-a25e-5123c68b5ae8',

  API_URL2: 'https://staging-api.ketoswebportal.com/api/v1/', // test server Wave APIs
  API_ICEBERG_URL: 'https://staging-api.ketoswebportal.com/api/v1/', // test server Iceberg APIs
  API_SHIELD_URL: 'https://staging-api.ketoswebportal.com/api/v1/', // Shield API
  DEFAULT_PIN: [{
    'latitude': -83.168916,
    'longitude': 41.113848
  }],
  SHIELD_LOCATIONS_STATUS: {
    green: 'good',
    yellow: 'poor',
    orange: 'bad',
    red: 'ugly',
  }, WAVE_LOCATIONS_STATUS: {
    green: 'good',
    yellow: 'poor',
    orange: 'bad',
    red: 'ugly',
  },
  SHIELD_MODELLER_API: 'https://predictive-modeler.ktplatformservices.com',
  WAVE_MODELLER_API: 'https://predictive-modeler.ktplatformservices.com:8050',
  googleMapAPiKey: 'AIzaSyByL2F3wvr5huQDRYHX_QBnZaoQAnD_kAE'
};
