/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Shield_result_aggregates } from '../models/shield-_result-_aggregates';

/**
 * Aggregated result related operations
 */
@Injectable({
  providedIn: 'root',
})
class ResultAggregatesService extends __BaseService {
  static readonly getShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDatePath = '/result_aggregates/shield/{start_date}/{end_date}';
  static readonly getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalPath = '/result_aggregates/shield/{start_date}/{end_date}/{interval}';
  static readonly getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdPath = '/result_aggregates/shield/{start_date}/{end_date}/{interval}/{water_source_id}';
  static readonly getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdStringTimezonePath = '/result_aggregates/shield/{start_date}/{end_date}/{interval}/{water_source_id}/{timezone}';
  static readonly getShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateIntWaterSourceIdPath = '/result_aggregates/shield/{start_date}/{end_date}/{water_source_id}';
  static readonly GetAllResultsGivenOptionalWaterSourceIdAndOptionalExperimentTypesByBucketPath = '/result_aggregates/{start_date}/{end_date}/{water_source_ids}/{experiment_types}/{buckets}/{time_zone}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ResultAggregatesService.GetShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateResponse(params: ResultAggregatesService.GetShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateParams): __Observable<__StrictHttpResponse<Shield_result_aggregates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/result_aggregates/shield/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_result_aggregates>;
      })
    );
  }
  /**
   * @param params The `ResultAggregatesService.GetShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDate(params: ResultAggregatesService.GetShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateParams): __Observable<Shield_result_aggregates> {
    return this.getShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateResponse(params).pipe(
      __map(_r => _r.body as Shield_result_aggregates)
    );
  }

  /**
   * @param params The `ResultAggregatesService.GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `interval`: <count><unit>' Count must be a positive integer.  Unit must be one of the following: [ms', 'm', 'h', 'd', 'w', 'M', 'q', 'y']  If count is not 1, unit must be one of the following: ['ms', 's', 'm', 'h', 'd']
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalResponse(params: ResultAggregatesService.GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalParams): __Observable<__StrictHttpResponse<Shield_result_aggregates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/result_aggregates/shield/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.interval))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_result_aggregates>;
      })
    );
  }
  /**
   * @param params The `ResultAggregatesService.GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `interval`: <count><unit>' Count must be a positive integer.  Unit must be one of the following: [ms', 'm', 'h', 'd', 'w', 'M', 'q', 'y']  If count is not 1, unit must be one of the following: ['ms', 's', 'm', 'h', 'd']
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringInterval(params: ResultAggregatesService.GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalParams): __Observable<Shield_result_aggregates> {
    return this.getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalResponse(params).pipe(
      __map(_r => _r.body as Shield_result_aggregates)
    );
  }

  /**
   * @param params The `ResultAggregatesService.GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `interval`: <count><unit>' Count must be a positive integer.  Unit must be one of the following: [ms', 'm', 'h', 'd', 'w', 'M', 'q', 'y']  If count is not 1, unit must be one of the following: ['ms', 's', 'm', 'h', 'd']
   *
   * - `water_source_id`: Water Source ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdResponse(params: ResultAggregatesService.GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdParams): __Observable<__StrictHttpResponse<Shield_result_aggregates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/result_aggregates/shield/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.interval))}/${encodeURIComponent(String(params.waterSourceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_result_aggregates>;
      })
    );
  }
  /**
   * @param params The `ResultAggregatesService.GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `interval`: <count><unit>' Count must be a positive integer.  Unit must be one of the following: [ms', 'm', 'h', 'd', 'w', 'M', 'q', 'y']  If count is not 1, unit must be one of the following: ['ms', 's', 'm', 'h', 'd']
   *
   * - `water_source_id`: Water Source ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceId(params: ResultAggregatesService.GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdParams): __Observable<Shield_result_aggregates> {
    return this.getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdResponse(params).pipe(
      __map(_r => _r.body as Shield_result_aggregates)
    );
  }

  /**
   * @param params The `ResultAggregatesService.GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdStringTimezoneParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `interval`: <count><unit>' Count must be a positive integer.  Unit must be one of the following: [ms', 'm', 'h', 'd', 'w', 'M', 'q', 'y']  If count is not 1, unit must be one of the following: ['ms', 's', 'm', 'h', 'd']
   *
   * - `water_source_id`: Water Source ID
   *
   * - `timezone`: Time zone in various formats.  Can also be an offset such as "+3"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdStringTimezoneResponse(params: ResultAggregatesService.GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdStringTimezoneParams): __Observable<__StrictHttpResponse<Shield_result_aggregates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/result_aggregates/shield/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.interval))}/${encodeURIComponent(String(params.waterSourceId))}/${encodeURIComponent(String(params.timezone))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_result_aggregates>;
      })
    );
  }
  /**
   * @param params The `ResultAggregatesService.GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdStringTimezoneParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `interval`: <count><unit>' Count must be a positive integer.  Unit must be one of the following: [ms', 'm', 'h', 'd', 'w', 'M', 'q', 'y']  If count is not 1, unit must be one of the following: ['ms', 's', 'm', 'h', 'd']
   *
   * - `water_source_id`: Water Source ID
   *
   * - `timezone`: Time zone in various formats.  Can also be an offset such as "+3"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdStringTimezone(params: ResultAggregatesService.GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdStringTimezoneParams): __Observable<Shield_result_aggregates> {
    return this.getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdStringTimezoneResponse(params).pipe(
      __map(_r => _r.body as Shield_result_aggregates)
    );
  }

  /**
   * @param params The `ResultAggregatesService.GetShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateIntWaterSourceIdParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `water_source_id`: Water Source ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateIntWaterSourceIdResponse(params: ResultAggregatesService.GetShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateIntWaterSourceIdParams): __Observable<__StrictHttpResponse<Shield_result_aggregates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/result_aggregates/shield/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.waterSourceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_result_aggregates>;
      })
    );
  }
  /**
   * @param params The `ResultAggregatesService.GetShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateIntWaterSourceIdParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `water_source_id`: Water Source ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateIntWaterSourceId(params: ResultAggregatesService.GetShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateIntWaterSourceIdParams): __Observable<Shield_result_aggregates> {
    return this.getShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateIntWaterSourceIdResponse(params).pipe(
      __map(_r => _r.body as Shield_result_aggregates)
    );
  }

  /**
   * @param params The `ResultAggregatesService.GetAllResultsGivenOptionalWaterSourceIdAndOptionalExperimentTypesByBucketParams` containing the following parameters:
   *
   * - `start_date`: ISO 8601 start date/time or "none" (inclusive)
   *
   * - `end_date`: ISO 8601 end data/time or "none" (inclusive)
   *
   * - `water_source_ids`: Comma separated list of water source IDs or "all"
   *
   * - `experiment_types`: Comma separated list of experiment types "all"
   *
   * - `buckets`: Number of buckets to split aggregates into
   *
   * - `time_zone`: Time zone examples: "UTC", "UTC-01:00", "UTC+10:30"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAllResultsGivenOptionalWaterSourceIdAndOptionalExperimentTypesByBucketResponse(params: ResultAggregatesService.GetAllResultsGivenOptionalWaterSourceIdAndOptionalExperimentTypesByBucketParams): __Observable<__StrictHttpResponse<Shield_result_aggregates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;






    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/result_aggregates/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.waterSourceIds))}/${encodeURIComponent(String(params.experimentTypes))}/${encodeURIComponent(String(params.buckets))}/${encodeURIComponent(String(params.timeZone))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_result_aggregates>;
      })
    );
  }
  /**
   * @param params The `ResultAggregatesService.GetAllResultsGivenOptionalWaterSourceIdAndOptionalExperimentTypesByBucketParams` containing the following parameters:
   *
   * - `start_date`: ISO 8601 start date/time or "none" (inclusive)
   *
   * - `end_date`: ISO 8601 end data/time or "none" (inclusive)
   *
   * - `water_source_ids`: Comma separated list of water source IDs or "all"
   *
   * - `experiment_types`: Comma separated list of experiment types "all"
   *
   * - `buckets`: Number of buckets to split aggregates into
   *
   * - `time_zone`: Time zone examples: "UTC", "UTC-01:00", "UTC+10:30"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAllResultsGivenOptionalWaterSourceIdAndOptionalExperimentTypesByBucket(params: ResultAggregatesService.GetAllResultsGivenOptionalWaterSourceIdAndOptionalExperimentTypesByBucketParams): __Observable<Shield_result_aggregates> {
    return this.GetAllResultsGivenOptionalWaterSourceIdAndOptionalExperimentTypesByBucketResponse(params).pipe(
      __map(_r => _r.body as Shield_result_aggregates)
    );
  }
}

module ResultAggregatesService {

  /**
   * Parameters for getShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDate
   */
  export interface GetShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateParams {

    /**
     * Start date epoch
     */
    startDate: number;

    /**
     * End date epoch
     */
    endDate: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringInterval
   */
  export interface GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalParams {

    /**
     * Start date epoch
     */
    startDate: number;

    /**
     * End date epoch
     */
    endDate: number;

    /**
     * <count><unit>' Count must be a positive integer.  Unit must be one of the following: [ms', 'm', 'h', 'd', 'w', 'M', 'q', 'y']  If count is not 1, unit must be one of the following: ['ms', 's', 'm', 'h', 'd']
     */
    interval: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceId
   */
  export interface GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdParams {

    /**
     * Start date epoch
     */
    startDate: number;

    /**
     * End date epoch
     */
    endDate: number;

    /**
     * <count><unit>' Count must be a positive integer.  Unit must be one of the following: [ms', 'm', 'h', 'd', 'w', 'M', 'q', 'y']  If count is not 1, unit must be one of the following: ['ms', 's', 'm', 'h', 'd']
     */
    interval: string;

    /**
     * Water Source ID
     */
    waterSourceId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdStringTimezone
   */
  export interface GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdStringTimezoneParams {

    /**
     * Start date epoch
     */
    startDate: number;

    /**
     * End date epoch
     */
    endDate: number;

    /**
     * <count><unit>' Count must be a positive integer.  Unit must be one of the following: [ms', 'm', 'h', 'd', 'w', 'M', 'q', 'y']  If count is not 1, unit must be one of the following: ['ms', 's', 'm', 'h', 'd']
     */
    interval: string;

    /**
     * Water Source ID
     */
    waterSourceId: number;

    /**
     * Time zone in various formats.  Can also be an offset such as "+3"
     */
    timezone: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateIntWaterSourceId
   */
  export interface GetShieldResultHistogramResultAggregatesShieldIntStartDateIntEndDateIntWaterSourceIdParams {

    /**
     * Start date epoch
     */
    startDate: number;

    /**
     * End date epoch
     */
    endDate: number;

    /**
     * Water Source ID
     */
    waterSourceId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetAllResultsGivenOptionalWaterSourceIdAndOptionalExperimentTypesByBucket
   */
  export interface GetAllResultsGivenOptionalWaterSourceIdAndOptionalExperimentTypesByBucketParams {

    /**
     * ISO 8601 start date/time or "none" (inclusive)
     */
    startDate: string;

    /**
     * ISO 8601 end data/time or "none" (inclusive)
     */
    endDate: string;

    /**
     * Comma separated list of water source IDs or "all"
     */
    waterSourceIds: string;

    /**
     * Comma separated list of experiment types "all"
     */
    experimentTypes: string;

    /**
     * Number of buckets to split aggregates into
     */
    buckets: number;

    /**
     * Time zone examples: "UTC", "UTC-01:00", "UTC+10:30"
     */
    timeZone: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ResultAggregatesService }
