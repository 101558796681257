import { Injectable } from '@angular/core';
import {ILoginResponse} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AppStorage} from '../utils/AppStorage';
import {AuditService} from '../../../../api/src/lib/services';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KetosAuditService {

  private userData: ILoginResponse;
  private username:string = 'default';
  private auditAPIURL: string;

  constructor(private auditService: AuditService) {
    this.userData = JSON.parse(AppStorage.getItem('user'));
  }

  public isAuditUrl(url: string) {
    return url === this.auditService.rootUrl + AuditService.postAuditLogPath;
  }

  public updateUserData(){
    this.userData = JSON.parse(AppStorage.getItem('user'));
    this.username = this.userData.username;
  }

  public setUserName(username:string){
    this.username = username;
  }

  // public postLogoutAuditEvent(location_url: string, component_name: string, event_type: string, event_description: string): Observable<any> {
  //   if(this.username === 'default'){
  //     this.updateUserData();
  //   }
  //   const auditRequest = {
  //     "user_id": this.username,
  //     "location_url": location_url,
  //     "component_name": component_name,
  //     "event_type": event_type,
  //     "event_description": event_description,
  //     "source":"web"
  //   };
  //
  //   return this.http.post(this.auditAPIURL, auditRequest, {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  // }

  public postAuditEvent(location_url: string, component_name: string, event_type: string, event_description: string) {
    // if(this.username === 'default'){
    //   this.updateUserData();
    // }
    // const auditRequest = {
    //   "user_id": this.username,
    //   "location_url": location_url,
    //   "component_name": component_name,
    //   "event_type": event_type,
    //   "event_description": event_description,
    //   "source":"web"
    // };
    //
    // this.http.post(this.auditAPIURL, auditRequest, {
    //   headers: {
    //     'Content-Type': 'application/json'
    //   }
    // }).subscribe(res => {
    //   console.log(res);
    // });
    this.auditService.postAuditLog({
      component_name: component_name,
      event_description: event_description,
      event_type: event_type,
      location_url: location_url,
      source: 'web'
    })
      .subscribe(res => {
          console.log('audit res', res);
        });
  }
}
