/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Shield_continuous_schedules } from '../models/shield-_continuous-_schedules';
import { Shield_continuous_schedule } from '../models/shield-_continuous-_schedule';
import { Water_source_input } from '../models/water-_source-_input';

/**
 * Shield continuous related operations
 */
@Injectable({
  providedIn: 'root',
})
class ShieldService extends __BaseService {
  static readonly getShieldContinuousSchedulesPath = '/shield/continuous_schedules';
  static readonly postShieldContinuousSchedulesPath = '/shield/continuous_schedules';
  static readonly deleteShieldContinuousSchedulePath = '/shield/continuous_schedules/{id}';
  static readonly putWaterSourcePath = '/shield/water_source';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ShieldService.GetShieldContinuousSchedulesParams` containing the following parameters:
   *
   * - `ids`: List of ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldContinuousSchedulesResponse(params: ShieldService.GetShieldContinuousSchedulesParams): __Observable<__StrictHttpResponse<Shield_continuous_schedules>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.waterSourceIds || []).forEach(val => {if (val != null) __params = __params.append('water_source_ids', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield/continuous_schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_continuous_schedules>;
      })
    );
  }
  /**
   * @param params The `ShieldService.GetShieldContinuousSchedulesParams` containing the following parameters:
   *
   * - `ids`: List of ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldContinuousSchedules(params: ShieldService.GetShieldContinuousSchedulesParams): __Observable<Shield_continuous_schedules> {
    return this.getShieldContinuousSchedulesResponse(params).pipe(
      __map(_r => _r.body as Shield_continuous_schedules)
    );
  }

  /**
   * @param payload undefined
   */
  postShieldContinuousSchedulesResponse(payload: Shield_continuous_schedule): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shield/continuous_schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postShieldContinuousSchedules(payload: Shield_continuous_schedule): __Observable<null> {
    return this.postShieldContinuousSchedulesResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  deleteShieldContinuousScheduleResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/shield/continuous_schedules/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  deleteShieldContinuousSchedule(id: number): __Observable<null> {
    return this.deleteShieldContinuousScheduleResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  putWaterSourceResponse(payload: Water_source_input): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/shield/water_source`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  putWaterSource(payload: Water_source_input): __Observable<null> {
    return this.putWaterSourceResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ShieldService {

  /**
   * Parameters for getShieldContinuousSchedules
   */
  export interface GetShieldContinuousSchedulesParams {

    /**
     * List of ids
     */
    ids?: Array<number>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of water source ids
     */
    waterSourceIds?: Array<number>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ShieldService }
