/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Alert_summary } from '../models/alert-_summary';

/**
 * Alert summary related operations
 */
@Injectable({
  providedIn: 'root',
})
class AlertSummaryService extends __BaseService {
  static readonly GetAlertSummaryPath = '/alert_summary/{sensor_type}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AlertSummaryService.GetAlertSummaryParams` containing the following parameters:
   *
   * - `sensor_type`: wave | shield | iceberg
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAlertSummaryResponse(params: AlertSummaryService.GetAlertSummaryParams): __Observable<__StrictHttpResponse<Alert_summary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alert_summary/${encodeURIComponent(String(params.sensorType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Alert_summary>;
      })
    );
  }
  /**
   * @param params The `AlertSummaryService.GetAlertSummaryParams` containing the following parameters:
   *
   * - `sensor_type`: wave | shield | iceberg
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAlertSummary(params: AlertSummaryService.GetAlertSummaryParams): __Observable<Alert_summary> {
    return this.GetAlertSummaryResponse(params).pipe(
      __map(_r => _r.body as Alert_summary)
    );
  }
}

module AlertSummaryService {

  /**
   * Parameters for GetAlertSummary
   */
  export interface GetAlertSummaryParams {

    /**
     * wave | shield | iceberg
     */
    sensorType: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { AlertSummaryService }
