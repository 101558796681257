import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumberWithWords'
})
export class FormatNumberWithWordsPipe implements PipeTransform {
  transform(value: number): string {
    if (value >= 1e18) {
      return `${(value / 1e18).toFixed(2)} Quintillion`;
    } else if (value >= 1e15) {
      return `${(value / 1e15).toFixed(2)} Quadrillion`;
    } else if (value >= 1e12) {
      return `${(value / 1e12).toFixed(2)} Trillion`;
    } else if (value >= 1e9) {
      return `${(value / 1e9).toFixed(2)} Billion`;
    } else if (value >= 1e6) {
      return `${(value / 1e6).toFixed(2)} Million`;
    } else if (value >= 1e3) {
      return `${(value / 1e3).toFixed(2)} Thousand`;
    } else {
      return `${value}`;
    }
  }
}
