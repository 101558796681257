import {NgModule} from '@angular/core';
import {KetosLibComponent} from './ketos-lib.component';
import {FullCalendarModule} from '@fullcalendar/angular';
import {IonicModule} from '@ionic/angular';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HighchartsChartModule} from 'highcharts-angular';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {CalendarTypePopoverComponent} from './pages/start-schedule-test/schedule-calendar/calendar-type-popover/calendar-type-popover.component';
import {PipesModule} from './pipes/pipes.module';
import {AllSensorsMapComponent} from './components/all-sensors-map/all-sensors-map.component';
import {SingleSelectPopoverComponent} from './components/single-select-popover/single-select-popover.component';
import {SingleSelectComponent} from './components/single-select-popover/single-select-wrapper/single-select.component';
import {MultiSelectComponent} from './components/multi-select-popover/multi-select-wrapper/multi-select.component';
import {LoadingOverlayComponent} from './components/loading-overlay/loading-overlay.component';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {GroupedSensorsSelectComponent} from './components/multi-select-popover/grouped-sensors-select/grouped-sensors-select.component';
import {GroupedSensorPopoverComponent} from './components/multi-select-popover/grouped-sensor-popover/grouped-sensor-popover.component';
import {CustomSelectComponent} from './components/select-popover/custom-select/custom-select.component';
import {buildVersions} from '../../../../versions';
import {CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {TopMenuComponent} from './components/top-menu/top-menu.component';
import {LeftMenuComponent} from './components/left-menu/left-menu.component';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {RouterModule} from '@angular/router';
import {GroupedDropdownComponent} from './components/grouped-dropdown/grouped-dropdown.component';
import { FormatResultValuePipe } from './pipes/format-result-value.pipe';
import { ImperialUnitConversionPipe } from './pipes/imperial-unit-conversion.pipe';
import {VarDirective} from './directives/var.directive';
import {KetosLoadingOrEmptyComponent} from './components/ketos-loading-or-empty/ketos-loading-or-empty.component';
import { AddUserModalComponent } from './pages/users/add-user-modal/add-user-modal.component';
import { CustomerInputModalComponent } from './pages/savings/customer-input-modal/customer-input-modal.component';
import {environment} from '../../../../environments/environment';
import { AmbientTemperatureComponent } from './pages/data-correlation/ambient-temperature/ambient-temperature.component';
import { SensorComparisonComponent } from './pages/data-correlation/sensor-comparison/sensor-comparison.component';
import { KetosAggregateGraphComponent } from './pages/ketos-graph/ketos-aggregate-graph/ketos-aggregate-graph.component';
import { KetosAllDataChartComponent } from './pages/ketos-graph/ketos-all-data-chart/ketos-all-data-chart.component';
import { KetosChartAdvanceOptionMultiselectPopover } from './pages/ketos-graph/ketos-chart-advance-option-multiselect-popover/ketos-chart-advance-option-multiselect-popover';
import { KetosIntervalChartComponent } from './pages/ketos-graph/ketos-interval-chart/ketos-interval-chart.component';
import { ExternalResultsModalComponent } from './pages/external-lab-data/external-results-modal/external-results-modal.component';
import { SolveViewAlertModalComponent } from './pages/alerts/solve-view-alert-modal/solve-view-alert-modal.component';
import { AlertsDownloadModalComponent } from './pages/alerts/utils/alerts-download-modal.component';
import { AlertsFilterModalComponent } from './pages/alerts/utils/alerts-filter-modal.component';
import {TestResultsModalPage} from './pages/test-results-modal/test-results-modal.page';
import {KetosGraphPage} from './pages/ketos-graph/ketos-graph.page';
import { KetosShieldSensorsComponent } from './pages/sensors/ketos-shield-sensors/ketos-shield-sensors.component';
import { SensorMapComponent } from './pages/sensors/sensor-map/sensor-map.component';
import { DashboardModuleHeaderComponent } from './components/dashboard-module-header/dashboard-module-header.component';
import { ChordComponent } from './components/chord/chord.component';
import { SensorTabsComponent } from './components/sensor-tabs/sensor-tabs.component';
import { CalendarModule } from './pages/calendar/calendar.module';
import { KetosCompoenentsModule } from './components/ketos.components.module';
import { ChordLegacyComponent } from './components/chord-legacy/chord-legacy.component';
import { AlertSettingModalComponent } from './components/alert-setting-modal/alert-setting-modal.component';
import { LocationsPopoverComponent } from './components/sensor-tabs/modal/locations-popover/locations-popover.component';
import {CustomChordComponent} from './components/custom-chord/custom-chord.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormatNumberWithWordsPipe } from './pipes/format-number-with-words.pipe';
import { DndModule } from 'ngx-drag-drop';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    KetosLibComponent,
    KetosAllDataChartComponent,
    KetosIntervalChartComponent,
    KetosAggregateGraphComponent,
    KetosChartAdvanceOptionMultiselectPopover,
    MultiSelectComponent,
    CalendarTypePopoverComponent,
    AllSensorsMapComponent,
    SolveViewAlertModalComponent,
    SingleSelectComponent,
    SingleSelectPopoverComponent,
    ExternalResultsModalComponent,
    CustomerInputModalComponent,
    AddUserModalComponent,
    SensorComparisonComponent,
    AmbientTemperatureComponent,
    LoadingOverlayComponent,
    SensorMapComponent,
    KetosShieldSensorsComponent,
    GroupedSensorsSelectComponent,
    GroupedSensorPopoverComponent,
    CustomSelectComponent,
    AlertsDownloadModalComponent,
    AlertsFilterModalComponent,
    TopMenuComponent,
    LeftMenuComponent,
    GroupedDropdownComponent,
    VarDirective,
    KetosLoadingOrEmptyComponent,
    TestResultsModalPage,
    FormatNumberWithWordsPipe,
    KetosGraphPage,
    DashboardModuleHeaderComponent,
    ChordComponent,
    ChordLegacyComponent,
    CustomChordComponent,
    SensorTabsComponent,
    AlertSettingModalComponent,
    LocationsPopoverComponent

  ],
  imports: [
    CalendarModule,
    KetosCompoenentsModule,
    HighchartsChartModule,
    IonicModule.forRoot(),
    FullCalendarModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatSidenavModule,
    MatTooltipModule,
    MatSnackBarModule,
    TooltipModule.forRoot(),
    NgbTooltipModule,
    IonicModule,
    RouterModule,
    GoogleMapsModule,
    DndModule
  ],
  exports: [
    HighchartsChartModule,
    KetosLibComponent,
    KetosAllDataChartComponent,
    KetosIntervalChartComponent,
    KetosAggregateGraphComponent,
    AllSensorsMapComponent,
    FormsModule,
    ReactiveFormsModule,
    SingleSelectComponent,
    MultiSelectComponent,
    ExternalResultsModalComponent,
    CustomerInputModalComponent,
    AddUserModalComponent,
    SensorComparisonComponent,
    AmbientTemperatureComponent,
    LoadingOverlayComponent,
    SensorMapComponent,
    KetosShieldSensorsComponent,
    GroupedSensorsSelectComponent,
    GroupedSensorPopoverComponent,
    CustomSelectComponent,
    PipesModule,
    AlertsDownloadModalComponent,
    AlertsFilterModalComponent,
    TopMenuComponent,
    LeftMenuComponent,
    GroupedDropdownComponent,
    VarDirective,
    KetosLoadingOrEmptyComponent,
    TestResultsModalPage,
    GoogleMapsModule,
    KetosGraphPage,
    DashboardModuleHeaderComponent,
    ChordComponent,
    ChordLegacyComponent,
    CustomChordComponent,
    SensorTabsComponent,
    AlertSettingModalComponent,
    LocationsPopoverComponent,
    FormatNumberWithWordsPipe,
    DndModule
  ],
  providers: [
    BarcodeScanner,
    DatePipe,
    InAppBrowser,
    FormatResultValuePipe,
    FormatNumberWithWordsPipe,
    ImperialUnitConversionPipe
  ],
})
export class KetosLibModule {
  constructor() {
    if (environment.production) {
      if (window.location.href.includes('localhost:')) {
        console.log(buildVersions.libBuild);
      }
    } else {
      console.log(buildVersions.libBuild);
    }
  }
}
