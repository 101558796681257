import { Pipe, PipeTransform } from '@angular/core';
import {TimeIntervalInterface} from '../services/app-state.service';

@Pipe({
  name: 'calculateDays'
})
export class CalculateDaysPipe implements PipeTransform {

  transform(value: TimeIntervalInterface): unknown {
    if (value === undefined || value === null) {
      return 0 + 'days';
    }
    const date1: any = new Date(value.min);
    const date2: any = new Date(value.max);
    // const display = date2 - date1;
    const diff = Math.abs(date1.getTime() - date2.getTime()) / 3600000;
//     let days = ((display) / (1000 * 60 * 60 * 24));
    let days = Math.ceil(diff / 24);
    if (days === 0) {
      days = days + 1;
    }
    if (days > 1) {
      return days + ' days';
    } else {
      return days + ' day';
    }
  }
}
