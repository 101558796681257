/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Shield_test_schedule2 } from '../models/shield-_test-_schedule-2';
import { Shield_schedule_test_dto } from '../models/shield-_schedule-_test-_dto';
import { Shield_test_schedule1 } from '../models/shield-_test-_schedule-1';

/**
 * Shield test schedule related operations
 */
@Injectable({
  providedIn: 'root',
})
class TestScheduleService extends __BaseService {
  static readonly ScheduleANewManualScheduledTestWithRecurringOptionsPath = '/test_schedule/shield/schedule_test/';
  static readonly getScheduledTestsPath = '/test_schedule/shield/scheduled_tests/{start_date}/{end_date}/{sensor_id}/';
  static readonly getScheduledTestsPendingPath = '/test_schedule/shield/scheduled_tests_pending/{sensor_id}/';
  static readonly getTestSchedulesTestScheduleShieldSchedulesLimitlessStringWaterSourceIdPath = '/test_schedule/shield/schedules/limitless/{water_source_id}';
  static readonly getTestSchedulesTestScheduleShieldSchedulesStringStartDateStringEndDateStringSensorIdPath = '/test_schedule/shield/schedules/{start_date}/{end_date}/{sensor_id}';
  static readonly DeleteATestSchedulePath = '/test_schedule/shield/schedules/{test_id}/';
  static readonly GetATestSchedulePath = '/test_schedule/shield/schedules/{test_id}/';
  static readonly checkIfATestIsRunningPath = '/test_schedule/shield/shield_test_status/{sensor_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Schedule a new manual/scheduled test with recurring options
   * @param payload undefined
   */
  ScheduleANewManualScheduledTestWithRecurringOptionsResponse(payload: Shield_test_schedule2): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/test_schedule/shield/schedule_test/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Schedule a new manual/scheduled test with recurring options
   * @param payload undefined
   */
  ScheduleANewManualScheduledTestWithRecurringOptions(payload: Shield_test_schedule2): __Observable<null> {
    return this.ScheduleANewManualScheduledTestWithRecurringOptionsResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get all scheduled tests
   * @param params The `TestScheduleService.GetScheduledTestsParams` containing the following parameters:
   *
   * - `start_date`:
   *
   * - `end_date`:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getScheduledTestsResponse(params: TestScheduleService.GetScheduledTestsParams): __Observable<__StrictHttpResponse<Shield_schedule_test_dto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/scheduled_tests/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.sensorId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_schedule_test_dto>;
      })
    );
  }
  /**
   * Get all scheduled tests
   * @param params The `TestScheduleService.GetScheduledTestsParams` containing the following parameters:
   *
   * - `start_date`:
   *
   * - `end_date`:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getScheduledTests(params: TestScheduleService.GetScheduledTestsParams): __Observable<Shield_schedule_test_dto> {
    return this.getScheduledTestsResponse(params).pipe(
      __map(_r => _r.body as Shield_schedule_test_dto)
    );
  }

  /**
   * Get pending scheduled tests
   * @param params The `TestScheduleService.GetScheduledTestsPendingParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getScheduledTestsPendingResponse(params: TestScheduleService.GetScheduledTestsPendingParams): __Observable<__StrictHttpResponse<Shield_schedule_test_dto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/scheduled_tests_pending/${encodeURIComponent(String(params.sensorId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_schedule_test_dto>;
      })
    );
  }
  /**
   * Get pending scheduled tests
   * @param params The `TestScheduleService.GetScheduledTestsPendingParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getScheduledTestsPending(params: TestScheduleService.GetScheduledTestsPendingParams): __Observable<Shield_schedule_test_dto> {
    return this.getScheduledTestsPendingResponse(params).pipe(
      __map(_r => _r.body as Shield_schedule_test_dto)
    );
  }

  /**
   * Get all test schedules  by water source id
   * @param params The `TestScheduleService.GetTestSchedulesTestScheduleShieldSchedulesLimitlessStringWaterSourceIdParams` containing the following parameters:
   *
   * - `water_source_id`: Water Source ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getTestSchedulesTestScheduleShieldSchedulesLimitlessStringWaterSourceIdResponse(params: TestScheduleService.GetTestSchedulesTestScheduleShieldSchedulesLimitlessStringWaterSourceIdParams): __Observable<__StrictHttpResponse<Shield_test_schedule1>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/schedules/limitless/${encodeURIComponent(String(params.waterSourceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test_schedule1>;
      })
    );
  }
  /**
   * Get all test schedules  by water source id
   * @param params The `TestScheduleService.GetTestSchedulesTestScheduleShieldSchedulesLimitlessStringWaterSourceIdParams` containing the following parameters:
   *
   * - `water_source_id`: Water Source ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getTestSchedulesTestScheduleShieldSchedulesLimitlessStringWaterSourceId(params: TestScheduleService.GetTestSchedulesTestScheduleShieldSchedulesLimitlessStringWaterSourceIdParams): __Observable<Shield_test_schedule1> {
    return this.getTestSchedulesTestScheduleShieldSchedulesLimitlessStringWaterSourceIdResponse(params).pipe(
      __map(_r => _r.body as Shield_test_schedule1)
    );
  }

  /**
   * Get all test schedules  by sensor id
   * @param params The `TestScheduleService.GetTestSchedulesTestScheduleShieldSchedulesStringStartDateStringEndDateStringSensorIdParams` containing the following parameters:
   *
   * - `start_date`: Start date
   *
   * - `end_date`: End date
   *
   * - `sensor_id`: Sensor ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getTestSchedulesTestScheduleShieldSchedulesStringStartDateStringEndDateStringSensorIdResponse(params: TestScheduleService.GetTestSchedulesTestScheduleShieldSchedulesStringStartDateStringEndDateStringSensorIdParams): __Observable<__StrictHttpResponse<Shield_test_schedule1>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/schedules/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test_schedule1>;
      })
    );
  }
  /**
   * Get all test schedules  by sensor id
   * @param params The `TestScheduleService.GetTestSchedulesTestScheduleShieldSchedulesStringStartDateStringEndDateStringSensorIdParams` containing the following parameters:
   *
   * - `start_date`: Start date
   *
   * - `end_date`: End date
   *
   * - `sensor_id`: Sensor ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getTestSchedulesTestScheduleShieldSchedulesStringStartDateStringEndDateStringSensorId(params: TestScheduleService.GetTestSchedulesTestScheduleShieldSchedulesStringStartDateStringEndDateStringSensorIdParams): __Observable<Shield_test_schedule1> {
    return this.getTestSchedulesTestScheduleShieldSchedulesStringStartDateStringEndDateStringSensorIdResponse(params).pipe(
      __map(_r => _r.body as Shield_test_schedule1)
    );
  }

  /**
   * Delete the test schedule given test_id
   * @param test_id undefined
   * @return Success
   */
  DeleteATestScheduleResponse(testId: string): __Observable<__StrictHttpResponse<Shield_test_schedule1>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/test_schedule/shield/schedules/${encodeURIComponent(String(testId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test_schedule1>;
      })
    );
  }
  /**
   * Delete the test schedule given test_id
   * @param test_id undefined
   * @return Success
   */
  DeleteATestSchedule(testId: string): __Observable<Shield_test_schedule1> {
    return this.DeleteATestScheduleResponse(testId).pipe(
      __map(_r => _r.body as Shield_test_schedule1)
    );
  }

  /**
   * Get the test schedule given test_id
   * @param params The `TestScheduleService.GetATestScheduleParams` containing the following parameters:
   *
   * - `test_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetATestScheduleResponse(params: TestScheduleService.GetATestScheduleParams): __Observable<__StrictHttpResponse<Shield_test_schedule1>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/schedules/${encodeURIComponent(String(params.testId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test_schedule1>;
      })
    );
  }
  /**
   * Get the test schedule given test_id
   * @param params The `TestScheduleService.GetATestScheduleParams` containing the following parameters:
   *
   * - `test_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetATestSchedule(params: TestScheduleService.GetATestScheduleParams): __Observable<Shield_test_schedule1> {
    return this.GetATestScheduleResponse(params).pipe(
      __map(_r => _r.body as Shield_test_schedule1)
    );
  }

  /**
   * Schedule a new manual/scheduled test with recurring options
   * @param sensor_id undefined
   */
  checkIfATestIsRunningResponse(sensorId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/shield_test_status/${encodeURIComponent(String(sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Schedule a new manual/scheduled test with recurring options
   * @param sensor_id undefined
   */
  checkIfATestIsRunning(sensorId: string): __Observable<null> {
    return this.checkIfATestIsRunningResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TestScheduleService {

  /**
   * Parameters for getScheduledTests
   */
  export interface GetScheduledTestsParams {
    startDate: string;
    endDate: string;
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getScheduledTestsPending
   */
  export interface GetScheduledTestsPendingParams {
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getTestSchedulesTestScheduleShieldSchedulesLimitlessStringWaterSourceId
   */
  export interface GetTestSchedulesTestScheduleShieldSchedulesLimitlessStringWaterSourceIdParams {

    /**
     * Water Source ID
     */
    waterSourceId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getTestSchedulesTestScheduleShieldSchedulesStringStartDateStringEndDateStringSensorId
   */
  export interface GetTestSchedulesTestScheduleShieldSchedulesStringStartDateStringEndDateStringSensorIdParams {

    /**
     * Start date
     */
    startDate: string;

    /**
     * End date
     */
    endDate: string;

    /**
     * Sensor ID
     */
    sensorId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetATestSchedule
   */
  export interface GetATestScheduleParams {
    testId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { TestScheduleService }
