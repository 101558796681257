/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Shield_test_schedule1 } from '../models/shield-_test-_schedule-1';
import { Shield_test_schedules } from '../models/shield-_test-_schedules';
import { Shield_test_schedule_titles } from '../models/shield-_test-_schedule-_titles';

/**
 * Shield test schedule related operations
 */
@Injectable({
  providedIn: 'root',
})
class ShieldTestSchedulesService extends __BaseService {
  static readonly putShieldTestSchedulesPath = '/shield/test_schedules';
  static readonly getShieldTestSchedulesPath = '/shield/test_schedules';
  static readonly getShieldTestScheduleTitlesPath = '/shield/test_schedules/titles';
  static readonly deleteShieldTestSchedulePath = '/shield/test_schedules/{shield_test_schedule_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Update a test schedule
   * @param payload undefined
   */
  putShieldTestSchedulesResponse(payload: Shield_test_schedule1): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/shield/test_schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update a test schedule
   * @param payload undefined
   */
  putShieldTestSchedules(payload: Shield_test_schedule1): __Observable<null> {
    return this.putShieldTestSchedulesResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldTestSchedulesService.GetShieldTestSchedulesParams` containing the following parameters:
   *
   * - `ids`: List of shield test schedule ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `organization_ids`: List of organization ids
   *
   * - `start_start_time`: Start start_time (ISO 8601)
   *
   * - `end_start_time`: End start_time (ISO 8601)
   *
   * - `start_end_time`: Start end_time (ISO 8601)
   *
   * - `end_end_time`: End end_time (ISO 8601)
   *
   * - `start`: Start of range of schedules to return (ISO 8601)
   *
   * - `end`: Start of range of schedules to return (ISO 8601)
   *
   * - `sorts`: Examples: "start_time desc", "end_recur asc"
   *
   * - `page`: Page number
   *
   * - `per_page`: Per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestSchedulesResponse(params: ShieldTestSchedulesService.GetShieldTestSchedulesParams): __Observable<__StrictHttpResponse<Shield_test_schedules>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.waterSourceIds || []).forEach(val => {if (val != null) __params = __params.append('water_source_ids', val.toString())});
    (params.operatingStatuses || []).forEach(val => {if (val != null) __params = __params.append('operating_statuses', val.toString())});
    (params.organizationIds || []).forEach(val => {if (val != null) __params = __params.append('organization_ids', val.toString())});
    if (params.startStartTime != null) __params = __params.set('start_start_time', params.startStartTime.toString());
    if (params.endStartTime != null) __params = __params.set('end_start_time', params.endStartTime.toString());
    if (params.startEndTime != null) __params = __params.set('start_end_time', params.startEndTime.toString());
    if (params.endEndTime != null) __params = __params.set('end_end_time', params.endEndTime.toString());
    if (params.start != null) __params = __params.set('start', params.start.toString());
    if (params.end != null) __params = __params.set('end', params.end.toString());
    (params.sorts || []).forEach(val => {if (val != null) __params = __params.append('sorts', val.toString())});
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.perPage != null) __params = __params.set('per_page', params.perPage.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield/test_schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test_schedules>;
      })
    );
  }
  /**
   * @param params The `ShieldTestSchedulesService.GetShieldTestSchedulesParams` containing the following parameters:
   *
   * - `ids`: List of shield test schedule ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `organization_ids`: List of organization ids
   *
   * - `start_start_time`: Start start_time (ISO 8601)
   *
   * - `end_start_time`: End start_time (ISO 8601)
   *
   * - `start_end_time`: Start end_time (ISO 8601)
   *
   * - `end_end_time`: End end_time (ISO 8601)
   *
   * - `start`: Start of range of schedules to return (ISO 8601)
   *
   * - `end`: Start of range of schedules to return (ISO 8601)
   *
   * - `sorts`: Examples: "start_time desc", "end_recur asc"
   *
   * - `page`: Page number
   *
   * - `per_page`: Per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestSchedules(params: ShieldTestSchedulesService.GetShieldTestSchedulesParams): __Observable<Shield_test_schedules> {
    return this.getShieldTestSchedulesResponse(params).pipe(
      __map(_r => _r.body as Shield_test_schedules)
    );
  }

  /**
   * Get a list of unique titles
   * @param params The `ShieldTestSchedulesService.GetShieldTestScheduleTitlesParams` containing the following parameters:
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestScheduleTitlesResponse(params: ShieldTestSchedulesService.GetShieldTestScheduleTitlesParams): __Observable<__StrictHttpResponse<Shield_test_schedule_titles>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.waterSourceIds || []).forEach(val => {if (val != null) __params = __params.append('water_source_ids', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield/test_schedules/titles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test_schedule_titles>;
      })
    );
  }
  /**
   * Get a list of unique titles
   * @param params The `ShieldTestSchedulesService.GetShieldTestScheduleTitlesParams` containing the following parameters:
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestScheduleTitles(params: ShieldTestSchedulesService.GetShieldTestScheduleTitlesParams): __Observable<Shield_test_schedule_titles> {
    return this.getShieldTestScheduleTitlesResponse(params).pipe(
      __map(_r => _r.body as Shield_test_schedule_titles)
    );
  }

  /**
   * @param shield_test_schedule_id undefined
   */
  deleteShieldTestScheduleResponse(shieldTestScheduleId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/shield/test_schedules/${encodeURIComponent(String(shieldTestScheduleId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param shield_test_schedule_id undefined
   */
  deleteShieldTestSchedule(shieldTestScheduleId: string): __Observable<null> {
    return this.deleteShieldTestScheduleResponse(shieldTestScheduleId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ShieldTestSchedulesService {

  /**
   * Parameters for getShieldTestSchedules
   */
  export interface GetShieldTestSchedulesParams {

    /**
     * List of shield test schedule ids
     */
    ids?: Array<string>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of water source ids
     */
    waterSourceIds?: Array<number>;

    /**
     * List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
     */
    operatingStatuses?: Array<number>;

    /**
     * List of organization ids
     */
    organizationIds?: Array<number>;

    /**
     * Start start_time (ISO 8601)
     */
    startStartTime?: string;

    /**
     * End start_time (ISO 8601)
     */
    endStartTime?: string;

    /**
     * Start end_time (ISO 8601)
     */
    startEndTime?: string;

    /**
     * End end_time (ISO 8601)
     */
    endEndTime?: string;

    /**
     * Start of range of schedules to return (ISO 8601)
     */
    start?: string;

    /**
     * Start of range of schedules to return (ISO 8601)
     */
    end?: string;

    /**
     * Examples: "start_time desc", "end_recur asc"
     */
    sorts?: Array<string>;

    /**
     * Page number
     */
    page?: number;

    /**
     * Per page
     */
    perPage?: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldTestScheduleTitles
   */
  export interface GetShieldTestScheduleTitlesParams {

    /**
     * List of water source ids
     */
    waterSourceIds?: Array<number>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ShieldTestSchedulesService }
