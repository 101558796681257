import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Router} from '@angular/router';
import {Platform} from '@ionic/angular';
import {combineLatest, Subscription} from 'rxjs';
import {AppStateService} from '../../services/app-state.service';
import {SensorTypes} from '../../models/sensor-types.enum';
import {ResultCountsCachedService} from '../../services/result-counts-cached.service';
import {map_styles} from '../../../../../shared/map-styles';
import {SensorsService} from '../../../../../api/src/lib/services';
import {ShieldDevicesCachedService} from '../../services/shield-devices-cached.service';
import {ShieldDeviceExtended} from '../../models/extensions/shield-device-extended';
import {WaterSourceExtended} from '../../models/extensions/water-source-extended';
import {GoogleMapsService} from '../../../../../web/src/app/core/api/services/google-maps.service';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';

export interface LocationInterface {
  location_name: string;
  type: string;
  iconUrl: string | {};
  lastTestTime: string;
  total_tests: number;
  sensor?: ShieldDeviceExtended | WaterSourceExtended | any;
  label?: string;
}

export interface MarkerInterface {
  lat: number;
  lon: number;
  locations: LocationInterface[];
  selectedIndex: number;
  highValues?: number;
  lowValues?: number;
}

@Component({
  selector: 'lib-all-sensors-map',
  templateUrl: './all-sensors-map.component.html',
  styleUrls: ['./all-sensors-map.component.scss'],
})
export class AllSensorsMapComponent implements OnInit, OnDestroy {
  map_styles = map_styles;
  iosSpecificMargin = false;
  isMapLoaded = false;
  center: {lat: 35.0265, lng: -118.955}
  waveLocations = [];
  shieldLocations: ShieldDeviceExtended[] = [];
  iceLocations = [];
  markers: MarkerInterface[] = [];

  sub: Subscription;

  @ViewChildren(MapInfoWindow) infoWindowsView!: QueryList<MapInfoWindow>;
  @ViewChildren(MapMarker) mapMarkersView!: QueryList<MapMarker>;

  constructor(public router: Router,
              private sensorsService: SensorsService,
              public shieldDevicesCachedService: ShieldDevicesCachedService,
              public resultCountsCachedService: ResultCountsCachedService,
              public plt: Platform,
              private googleMapsService:GoogleMapsService
            ) {
              this.googleMapsService.initMaps().then(() => { });
              this.googleMapsService.loadMarkerCluster();
              setTimeout(()=>{ this.isMapLoaded = true; }, 1000);  
  }

  ngOnInit() {
    this.iosSpecificMargin = this.plt.is('cordova') && this.plt.is('ios');
    // console.log('win', window)
    this.fetchData();
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onMapLoad(map: google.maps.Map){
    map.setOptions({ styles: map_styles });
  }
  
  findAggTime(obj,loc){
    return obj.items.forEach((item) => {
      return item.items.find(obj => obj.water_source_id === loc.sensor.id)
    })
  }
  fetchData(event?) {
    // let fresh = false;
    // if (event) {
    //   fresh = true;
    // }
    this.sub = combineLatest([
      this.shieldDevicesCachedService.getCached(true),
      this.resultCountsCachedService.getCached(true),
      this.sensorsService.GetAllShieldSensorsAggregates()
    ])
      .subscribe(res => {
        console.log(res);
        this.shieldLocations = [];
        if (res[0]) {
          let i = 0;
          let j = 0;
          while (i < res[0].length) {
            // for(var i=0;i<res[1].length;i++){
            if (res[1][j] && res[1][j].sensor_id === res[0][i].sensor.id) {
              res[0][i]['test_counts'] = res[1][j].count;
              j++;
              i = 0;
            } else {
              i++;
            }
            //}
          }
          i = 0;
          j = 0;
          while (i < res[0].length) {
            if (res[2].items[j] && res[2].items[j].sensor_id === res[0][i].sensor.id) {
              //console.log(i,res[2].items[i].last_experiment_test_time)
              res[0][i]['last_experiment_test_time'] = res[2].items[j].last_experiment_test_time;
              j++;
              i = 0;
            } else {
              i++;
            }
          }
          //}
          // for(var i=0;i<res[0].shield_sensors.length;i++){
          //   res[0].shield_sensors[i]['last_experiment_test_time']=res[2].items[i].last_experiment_test_time
          // }
          this.shieldLocations = res[0];
          //this.shieldLocations=res
          console.log(this.shieldLocations);
        }
        const testTimes = {};
        if (res[2] && res[2].items) {
          this.shieldLocations.map(loc => {
            //const timeObj = res[2].items.find(obj => obj.sensor_id === loc.sensor.id);
            const timeObj = this.findAggTime(res[2],loc)
            if (timeObj) {
              loc.lastTestTime = timeObj.last_experiment_test_time + 'Z';
            }
            const countObj = res[1].find(obj => obj.sensor_id === loc.sensor.id);
            if (countObj) {
              loc.total_results = countObj.count;
            }
          });
        }
        this.waveLocations = [];
        // if (res.wave) {
        //   // this.waveLocations.push(...res.wave.data.map( item => {
        //   //   const key = Object.keys(item)[0];
        //   //   const loc = item[key];
        //   //   loc.sensor_id = key;
        //   //   return loc;
        //   // }));
        //
        // }
        this.iceLocations = [];
        // if (res.iceberg) {
        //   // this.iceLocations.push(...Object.keys(res.iceberg.locations).map( key => {
        //   //   return res.iceberg.locations[key];
        //   // }));
        // }
        console.log(this.waveLocations, this.shieldLocations, 'ice', this.iceLocations);
        if (event) {
          event.target.complete();
        }
        this.calculateMarkers();
      });
  }

  calculateCluster = (markers: any, count: number) => {
    let sum = 0;
    for (const marker of markers) {
      if (marker.getLabel().text !== ' ') {
        sum += parseInt(marker.getLabel().text);
      } else {
        sum += 1;
      }
    }
    return {title:'', text: sum.toString(), index: 2};
  };

  calculateMarkers() {
    this.markers = [];
    for (const loc of this.waveLocations) {
      const marker = this.findMarkerWithCoords(loc.latitude, loc.longitude);
      if (marker) {
        marker.locations.push(this.createWaveMarker(loc));
      } else {
        this.markers.push(
          {
            locations: [this.createWaveMarker(loc)],
            selectedIndex: 0,
            lat: loc.latitude,
            lon: loc.longitude
          }
        );
      }
    }

    for (const loc of this.shieldLocations) {
      const marker = this.findMarkerWithCoords(loc.sensor.latitude, loc.sensor.longitude);
      if (marker) {
        marker.locations.push(this.createShieldMarker(loc));
      } else {
        this.markers.push(
          {
            locations: [this.createShieldMarker(loc)],
            selectedIndex: 0,
            lat: loc.sensor.latitude,
            lon: loc.sensor.longitude
          }
        );
      }
    }

    for (const loc of this.iceLocations) {
      const marker = this.findMarkerWithCoords(loc.latitude, loc.longitude);
      if (marker) {
        marker.locations.push(this.createIceMarker(loc));
      } else {
        this.markers.push(
          {
            locations: [this.createIceMarker(loc)],
            selectedIndex: 0,
            lat: loc.latitude,
            lon: loc.longitude
          }
        );
      }
    }

    for (const marker of this.markers) {
      marker['initialFit'] = marker.lon > -180 && marker.lon < -50;
    }
    for (const marker of this.markers) {
      let type = marker.locations[0].type;
      for (const location of marker.locations) {
        location.iconUrl = {icon: location.iconUrl as string, label:{color: 'white', fontWeight: 'bold', text: marker.locations.length >= 1 ? marker.locations.length.toString() : ' '}, labelOrigin: {x: 26, y: 24}};
        if (type !== location.type) {
          type = 'mixed';
        }
      }
      if (type === 'mixed') {
        for (const location of marker.locations) {
          location.iconUrl = null;
        }
      }
    }
  }

  findMarkerWithCoords(lat: number, lon: number): MarkerInterface {
    for (const marker of this.markers) {
      if (marker.lat === lat && marker.lon === lon) {
        return marker;
      }
    }
    return null;
  }

  createWaveMarker(location): any {
    let iconUrl = '../../../../assets/google-map/images/wavegreen.png';
    if (location.status === AppStateService.environment().CONSTANTS.WAVE_LOCATIONS_STATUS.red) {
      iconUrl = '../../../../assets/google-map/images/wavered.png';
    } else if (location.status === AppStateService.environment().CONSTANTS.WAVE_LOCATIONS_STATUS.orange) {
      iconUrl = '../../../../assets/google-map/images/waveorange1.png';
    } else if (location.status === AppStateService.environment().CONSTANTS.WAVE_LOCATIONS_STATUS.yellow) {
      iconUrl = '../../../../assets/google-map/images/waveyellow.png';
    }
    return {
      location_name: location.location_name,
      type: SensorTypes.wave,
      iconUrl: iconUrl,
      flow_rate: location.flow_rate,
      avg_pressure: location.avg_pressure,
      total_volume: location.total_volume,
      total_measurements: location.total_measurements
    };
  }

  createShieldMarker(location: any): LocationInterface {
    console.log(location, 'createMarker');
    let iconUrl = '../../../../assets/google-map/images/shieldgreen.png';
    if (location.heartbeat_status === AppStateService.environment().CONSTANTS.SHIELD_LOCATIONS_STATUS.red) {
      iconUrl = '../../../../assets/google-map/images/shieldred.png';
    } else if (location.heartbeat_status === AppStateService.environment().CONSTANTS.SHIELD_LOCATIONS_STATUS.orange) {
      iconUrl = '../../../../assets/google-map/images/shieldorange.png';
    } else if (location.heartbeat_status === AppStateService.environment().CONSTANTS.SHIELD_LOCATIONS_STATUS.yellow) {
      iconUrl = '../../../../assets/google-map/images/shieldyellow.png';
    }
    return {
      location_name: location.sensor.location_name,
      type: SensorTypes.shield,
      iconUrl: iconUrl,
      lastTestTime: location.last_experiment_test_time,
      total_tests: location.test_counts
    };
  }

  createIceMarker(location): any {

    let iconUrl = '../../../../assets/google-map/images/icegreen.png';
    if (location.status === AppStateService.environment().CONSTANTS.SHIELD_LOCATIONS_STATUS.red) {
      iconUrl = '../../../../assets/google-map/images/icered.png';
    } else if (location.status === AppStateService.environment().CONSTANTS.SHIELD_LOCATIONS_STATUS.orange) {
      iconUrl = '../../../../assets/google-map/images/iceorange.png';
    } else if (location.status === AppStateService.environment().CONSTANTS.SHIELD_LOCATIONS_STATUS.yellow) {
      iconUrl = '../../../../assets/google-map/images/iceyellow.png';
    }

    return {
      location_name: location.location_name,
      type: SensorTypes.iceberg,
      iconUrl: iconUrl,
      tank_volume: location.tank_volume,
      current_volume: location.current_volume,
      volume_percentage: location.volume_percentage,
      current_battery_level: location.current_battery_level
    };
  }

  markerClick(marker: MarkerInterface,index :number) {
    if (marker.locations.length > 0) {
      marker.selectedIndex += 1;
      marker.selectedIndex %= marker.locations.length;
      this.initilizeWindowMarker(index)
    }
  }

  initilizeWindowMarker(id: number) {
    this.infoWindowsView.forEach((infoWindow, index) => {
      if(index===id){
        const marker = this.mapMarkersView.toArray()[index];
        infoWindow.open(marker);
      }
    });
  }

  infoWindowClick(marker) {
    // this.router.navigate(['/tabs/sensors', {name: marker.locations[marker.selectedIndex].location_name, type: marker.locations[marker.selectedIndex].type}]);
  }
}
