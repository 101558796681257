import { Injectable } from '@angular/core';
import {CachedBaseService} from './cached-base.service';
import {forkJoin, Observable, ReplaySubject, Subscription} from 'rxjs';
import {KetosSocketActionEnum, KetosSocketEventEnum, KetosSocketService} from './ketos-socket.service';
import {AuthService} from './auth.service';
import {Water_source} from '../../../../api/src/lib/models/water-_source';
import {WaterSourcesService} from '../../../../api/src/lib/services/water-sources.service';
import {map} from 'rxjs/operators';
import {WaterSourceExtended} from '../models/extensions/water-source-extended';
import {Shield_device} from '../../../../api/src/lib/models/shield-_device';
import {ShieldDevicesCachedService} from './shield-devices-cached.service';

@Injectable({
  providedIn: 'root'
})
export class WaterSourcesCachedService extends CachedBaseService {
  subject: ReplaySubject<WaterSourceExtended[]>;
  model: WaterSourceExtended[];
  dictById: { [key: number]: WaterSourceExtended };
  dictBySensorId: { [key: number]: WaterSourceExtended };

  socketSub: Subscription;
  constructor(private ketosSocketService: KetosSocketService,
              private waterSourcesService: WaterSourcesService,
              private shieldDevicesService: ShieldDevicesCachedService,
              public authService: AuthService) {
    super();
    this.setupSubject();
  }

  setupSubject() {
    if (!this.socketSub && this.authService.getUser()?.organization_id) {
      this.ketosSocketService.getSubjectForRoom(this.authService.getUser().organization_id, KetosSocketEventEnum.water_source)
        .subscribe(data => {
          this.updateFromSocket(data);
        });
    }
  }

  public getCached(live = true, forceRefresh = false): Observable<WaterSourceExtended[]> {
    return this.getCachedBase(live, forceRefresh);
  }

  fetchData(): Observable<Water_source[]> {
    return forkJoin([
      this.waterSourcesService.getWaterSources({}),
      this.shieldDevicesService.getCached(false)
    ])
      .pipe(
        map((res: [{ items: WaterSourceExtended[] }, Shield_device[]]) => {
            this.dictById = {};
            res[0].items.map(s => {
              this.processNewModel(s);
              this.dictById[s.id] = s;
            });
            this.model = res[0].items.sort((a, b) => a.water_source_name?.localeCompare(b?.water_source_name));
            this.subject.next(this.model);
            return this.model;
          }
        )
      );
  }

  processNewModel(newModel: WaterSourceExtended) {
    newModel.location_name = newModel.water_source_name;
    const device = this.shieldDevicesService.dictById[newModel.sensor.sensor_sn];
    if (device) {
      newModel.exp_bitmap = device.exp_bitmap;
      newModel.water_status = device.water_status;
      newModel.continuous_mode_status = device.continuous_mode_status;
      newModel.test_status = device.test_status;
      newModel.operating_status = device.operating_status;
      newModel.test_schedule_updated_on = device.test_schedule_updated_on;
    }
  }

  // live logic
  updateFromSocket(data: {action: KetosSocketActionEnum, event_type: KetosSocketEventEnum, organization_id: number, room: string, sensor_id: string, message: {water_source: Water_source} }) {
    const newModel = data.message.water_source;
    const idx = this.model.findIndex(obj => obj.id === newModel?.id);
    if (data.action === KetosSocketActionEnum.update || data.action === KetosSocketActionEnum.insert) {
      // BLS Socket won't always send a nested sensor record
      if (!newModel.sensor && idx !== -1) {
        newModel.sensor = this.model[idx].sensor;
      } else {
        console.error('No nested sensor and no existing water source!');
        return;
      }

      if (newModel) {
        this.processNewModel(newModel);
      }
      if (idx !== -1) {
        this.model[idx] = newModel;
      } else {
        this.model.push(newModel);
        this.model = this.model.sort((a, b) => a.water_source_name.localeCompare(b.water_source_name));
      }
      this.dictById[newModel.id] = newModel;
      this.subject.next(this.model);
    } else if (data.action === KetosSocketActionEnum.delete) {
      if (idx !== -1) {
        this.model.splice(idx, 1);
        delete this.dictById[newModel.id]
        this.subject.next(this.model);
      }
    }
  }
}
