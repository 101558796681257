import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../../../../../ketos-lib/src/lib/services/legacy-services/app.service';
import {AppStateService, TimeIntervalInterface} from '../../../../../../ketos-lib/src/lib/services/app-state.service';
import {of, Subscription} from 'rxjs';
import * as Highcharts from 'highcharts';
import * as baseHighcharts from 'highcharts';
import {DashboardUiStateService} from '../../../../../../ketos-lib/src/lib/services/dashboard-ui-state.service';
import {ResultsService} from '../../../../../../api/src/lib/services/results.service';
import * as moment from 'moment';
import {cloneDeep} from 'lodash';
import {OrganizationSettingsService} from '../../../services/organization-settings.service';
import {UnitConverter} from '../../../utils/UnitConverter';
import {ExperimentConfigurationsService} from '../../../services/experiment-configurations.service';
import {WaterSourceExtended} from '../../../models/extensions/water-source-extended';
import {UsersService} from '../../../../../../api/src/lib/services/users.service';
import {DataCorelationModelService} from '../../../services/data-corelation-modal.service';
import {Shield_result} from '../../../../../../api/src/lib/models/shield-_result';
import {flatMap} from 'rxjs/operators';
import {Aggregate_results} from '../../../../../../api/src/lib/models/aggregate-_results';
import {Shield_result_aggregates} from '../../../../../../api/src/lib/models/shield-_result-_aggregates';
import {UiPlatformService} from '../../../services/ui-platform.service';
import {UserSettingsCachedService} from '../../../services/user-settings-cached.service';
import { OptionInterface } from '../../../components/multi-select-popover/multi-select-popover.component';
import { DataCorelationSettingsInterface } from '../../ketos-graph/ketos-graph.page';

const HighchartsMore = require('highcharts/highcharts-more.src');
HighchartsMore(Highcharts);
const HC_solid_gauge = require('highcharts/modules/solid-gauge.src');
HC_solid_gauge(Highcharts);

const HighChartsDumbbell = require('highcharts/modules/dumbbell');
HighChartsDumbbell(baseHighcharts);


const HighChartsLollipop = require('highcharts/modules/lollipop');
HighChartsLollipop(baseHighcharts);

const NoDataDisplay = require('highcharts/modules/no-data-to-display');
NoDataDisplay(baseHighcharts);

Highcharts.addEvent(Highcharts.Series, 'afterInit', function() {
  this['symbolUnicode'] = {
    circle: '●',
    diamond: '♦',
    square: '■',
    triangle: '▲',
    'triangle-down': '▼'
  }[this['symbol']] || '●';
});

export interface DataCorelationSesnorSettingsInterface {
  //experiments: string[];
  dateRange?: TimeIntervalInterface;
  sensorIds?: number[],
  //selectedInterval: string;
  // preSelectedExperiments?: DropdownValueInterface[];
  // selectedLocation: DropdownValueInterface[];
  // options?: GraphOptionsInterface;
}

@Component({
  selector: 'lib-ambient-temperature',
  templateUrl: './ambient-temperature.component.html',
  styleUrls: ['./ambient-temperature.component.scss']
})

export class AmbientTemperatureComponent implements OnInit {
  @ViewChild('chart', {static: false}) chart;
  chartInstance: Highcharts.Chart;
  chartInitialized = false;

  chartCallback;
  onSelectYaxisLimits;

  yAxisInterval;
  initDone = false;
  _dateInterval: TimeIntervalInterface;
  @Input() set dateInterval(value: TimeIntervalInterface) {
    // console.log('setDateInterval', value);
    this._dateInterval = value;
    if (!this.chartInitialized) {
      this.createChart();
      this.chartInitialized = true;
    }
    if (this.initDone) {
      this.fetchData();
    } else {
      this.initOnce();
    }
  }

  get dateInterval(): TimeIntervalInterface {
    return this._dateInterval;
  }


  _waterSourceId: number;
  @Input() set waterSourceId(value: number) {
    // console.log('set waterSourceId', value);
    this._waterSourceId = value;
    if (!this.chartInitialized) {
      this.createChart();
      this.chartInitialized = true;
    }
    if (this.initDone) {
      this.fetchData();
    } else {
      this.initOnce();
    }
  }

  get waterSourceId(): number {
    return this._waterSourceId;
  }

  _userSettings: any;
  @Input() set userSettings(value: any) {
    // console.log('setUserSettings:', value);
    this._userSettings = value;
    if (!this.chartInitialized) {
      this.createChart();
      this.chartInitialized = true;
    }
    if (this.initDone) {
      this.fetchData();
    } else {
      this.initOnce();
    }
  }

  get userSettings(): any {
    return this._userSettings;
  }

  highcharts = Highcharts;
  chartOptions;
  updateChartFlag = false;

  experiments = ['ambient_temperature', 'water_temperature'];
  settingKey = 'dataCorelationSensorGraphs';
  sub: Subscription[] = [];

  selectedWaterSourceOption: OptionInterface;
  waterSources: WaterSourceExtended[];
  waterSourceOptions: OptionInterface[];

  fetchDataSubscription: Subscription;

  constructor(public appService: AppService,
              public appStateService: AppStateService,
              private resultsService: ResultsService,
              public dashboardUiStateService: DashboardUiStateService,
              private experimentConfigService: ExperimentConfigurationsService,
              private unitSettingsService: UserSettingsCachedService,
              public usersService: UsersService,
              public dataCorelationModelService: DataCorelationModelService,
              public uiPlatformService: UiPlatformService,
  ) {}

  ngOnInit() {

    // this.createChart();
    // this.fetchData();
    // this.sub.push(this.appStateService.getAppState().subscribe(appState => {
    //   this.fetchData();
    // })
    // )
    // this.sub.push(this.dashboardUiStateService.dashboardUiStateSubject.subscribe((data:any)=>{
    //   setTimeout( () => {
    //     this.chartInstance?.reflow();
    //   }, 20);
    // })
    // )
  }

  initOnce() {
    // console.log('initOnce', this.initDone, this.dateInterval, this.userSettings, this.waterSourceId);

    if (this.initDone || !this.dateInterval || !this.userSettings || !this.waterSourceId) {
      // console.log('initRejected');
      return;
    }

    this.initDone = true;
    this.initLocationsDropDown();
    this.fetchData();
  }

  fetchUserSettings() {
    this.dataCorelationModelService.loadDataCorelationViewModels(true)
      .then(models => {
        console.log(models, 'models---x');
        this.userSettings = models[0];
        this.waterSourceId = models[0].sensorIds[0];
        //console.log(this.dataCorelationModelService.dataCorelationModel)
        //this.dataCorrelationStateService.dateInterval = cloneDeep
      });
  }

  setSelectedWaterSource(selected: OptionInterface) {
    // console.log('waterSourcesSelected', selected, this.waterSourceOptions);
    this.selectedWaterSourceOption = selected;
    if (selected) {
      this.waterSourceId = selected.value;
      this.saveSelectedSensors();
      this.fetchData();
    }
  }

  initLocationsDropDown() {

    this.waterSources = this.appStateService.getAppState().getValue().locations;
    this.waterSourceOptions = this.waterSources.map(src => {
      const option = {
        title: src.water_source_name,
        value: src.id,
      };
      if (this._waterSourceId && src.id === this._waterSourceId) {
        this.selectedWaterSourceOption = option;
      }
      return option;
    });

    if (this.userSettings) {
      const sensorComparisonExperimentsSensorIds: DataCorelationSesnorSettingsInterface = this.userSettings;


      if (sensorComparisonExperimentsSensorIds && sensorComparisonExperimentsSensorIds?.sensorIds?.length > 0) {
        // this.selectedWaterSource = [];
        for (const waterSourceOption of this.waterSourceOptions) {
          if (sensorComparisonExperimentsSensorIds.sensorIds.indexOf(waterSourceOption.value) !== -1) {
            this.selectedWaterSourceOption = waterSourceOption;
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  clean(obj) {
    for (var propName in obj) {
      if (obj[propName].length === 0 || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }


  customTooltip() {
    return {
      pointFormatter: function() {
        return `<span style="color:${this.series['color']}">${this.series['symbolUnicode']}</span>${this.series.name}: <b>${parseFloat(this.y.toFixed(3))}${this.series['tooltipOptions'].valueSuffix}</b><br/>`;
      },
      valueSuffix: ` ${this.unitSettingsService.selectedUnitsDict.water_temperature}`
    };
  }

  customDatalabels() {
    return {
      enabled: false,
    };

  }

  fetchData() {
    if (!this.waterSourceId) {
      return;
    }

    // console.log('fetchData', this.waterSourceId, this.dateInterval)
    //this.chartOptions.xAxis.max = this._dateInterval.max.getTime();
    //this.chartOptions.xAxis.min = this._dateInterval.min.getTime();
    let startDate = moment(this._dateInterval.min).utc(false);
    let endDate = moment(this._dateInterval.max).utc(false);

    this.fetchDataSubscription?.unsubscribe();
    this.fetchDataSubscription = this.resultsService.getShieldResults({
      startTimestamp: startDate.format(),
      endTimestamp: endDate.format(),
      waterSourceIds: [this.waterSourceId],
      experiments: this.experiments,
      operatingStatuses: [1],
      page: 1,
      perPage: 100
    })
      .pipe(
        flatMap(res => {
          if (res.total <= 100) {
            this.setResultsOnGraph(res.items);
            return of(null);
          }
          return this.resultsService.getShieldResultsHistogramResultsHistogramIntBuckets({
            startTimestamp: startDate.format(),
            endTimestamp: endDate.format(),
            waterSourceIds: [this.waterSourceId],
            experiments: this.experiments,
            operatingStatuses: [1],
            buckets: Math.min(2000, Math.max(700, this.chart?.el?.nativeElement?.offsetWidth || 0)),
            timeZone: 'UTC' + moment().format('Z')
          });
        })
      )
      .subscribe((res: Shield_result_aggregates) => {
        if (res?.items) {
          this.setBucketsOnGraph(res.items);
        }
      });
  }

  setResultsOnGraph(results: Shield_result[]) {
    if (results.length && results.length > 0) {
      const dataMap: { [key: string]: number[][] } = {};
      this.experiments.map(exp => dataMap[exp] = []);

      const expConf = this.experimentConfigService.allExperimentConfigurationsMap.water_temperature;
      results.map(result => {
        if (result.timestamp && result.value && result.experiment) {
          const currentUnit = UnitConverter.getTargetUnitFromConfAndUnitsDict(expConf, this.unitSettingsService.selectedUnitsDict);
          let confData = result.value !== null ? UnitConverter.convertFromUnitTo(result.value, expConf.unit, this.unitSettingsService.selectedUnitsDict.water_temperature) : null;
          // console.log(currentUnit,'currentUnit', confData,result.value)
          dataMap[result.experiment].push([moment.utc(result.timestamp).valueOf(), confData]);
        }
      });
      const filteredDataMap = this.clean(dataMap);
      var arr = Object.keys(filteredDataMap);
      const series = [];
      this.chartOptions.series = [];

      if (filteredDataMap[arr[0]].length || filteredDataMap[arr[1]].length) {
        let hasData = false;
        for (let i = 0; i < arr.length; i++) {
          this.chartOptions.series.push({
            name: arr[i].replace('_', ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase()),
            tooltip: this.customTooltip(),
            dataLabels: this.customDatalabels(),
            data: filteredDataMap[arr[i]]
          });
          hasData = hasData || filteredDataMap[arr[i]].length > 0;
        }

        this.chartOptions.series?.map(s => {
          s.showInLegend = hasData;
        });
      }
    } else {
      this.createChart();
      this.chartOptions.series?.map(s => {
        s.showInLegend = false;
      });
    }
    this.chartOptions.plotOptions.series.marker.enabled = true;
    this.chartOptions.plotOptions.series.lineWidth = 2;
    this.chartOptions.series = cloneDeep(this.chartOptions.series);
    this.updateChartFlag = true;
  }

  setBucketsOnGraph(rawAggregateResults: Aggregate_results[]) {
    const aggregateResults = rawAggregateResults.filter(aggRes => this.experiments.indexOf(aggRes.experiment) !== -1);

    if (aggregateResults?.length < 1) {
      this.showEmptyGraph();
    }

    if (aggregateResults.length > 1 && aggregateResults[0].experiment === this.experiments[1]) {
      aggregateResults.reverse();
    }

    const expConf = this.experimentConfigService.allExperimentConfigurationsMap.water_temperature;

    this.chartOptions.series = [];

    aggregateResults.map(aggregateResult => {
      const seriesData = [];
      aggregateResult.items.map(result => {
        if (result.doc_count > 0) {
          if (result.min_value === result.max_value) {
            seriesData.push([moment.utc(parseInt(result.key, 10)).valueOf(), UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(result.avg_value.value, expConf, this.unitSettingsService.selectedUnitsDict)]);
          } else {
            seriesData.push([moment.utc(parseInt(result.key, 10)).valueOf(), UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(result.avg_value.value, expConf, this.unitSettingsService.selectedUnitsDict)]);
            seriesData.push([moment.utc(parseInt(result.key, 10)).valueOf(), UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(result.min_value.value, expConf, this.unitSettingsService.selectedUnitsDict)]);
            seriesData.push([moment.utc(parseInt(result.key, 10)).valueOf(), UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(result.max_value.value, expConf, this.unitSettingsService.selectedUnitsDict)]);
            seriesData.push([moment.utc(parseInt(result.key, 10)).valueOf(), UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(result.avg_value.value, expConf, this.unitSettingsService.selectedUnitsDict)]);
          }
        } else {
          seriesData.push([moment.utc(parseInt(result.key, 10)).valueOf(), null]);
        }
      });

      this.chartOptions.series.push({
        name: aggregateResult.experiment.replace('_', ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase()),
        tooltip: this.customTooltip(),
        dataLabels: this.customDatalabels(),
        data: seriesData,
        marker: {
          enabled: true,
          radius: 0.5,
        }
      });
    });

    const hasData = aggregateResults.map(aggregateResult => aggregateResult?.items?.length || 0).reduce((previousValue, currentValue) => previousValue + currentValue) > 0;
    this.chartOptions.series?.map(s => {
      s.showInLegend = hasData;
    });

    this.chartOptions.plotOptions.series.marker.enabled = false;
    this.chartOptions.plotOptions.series.lineWidth = 1;
    // this.chartOptions.series = cloneDeep(this.chartOptions.series);
    this.updateChartFlag = true;
  }

  showEmptyGraph() {
    this.createChart();
    this.chartOptions.series?.map(s => {
      s.showInLegend = false;
    });
    this.updateChartFlag = true;
  }

  saveSelectedSensors() {
    const sensorComparisonSensorIds: DataCorelationSettingsInterface = {
      dateRange: this.dateInterval,
      sensorIds: [this.waterSourceId],
    };
    // console.log('saveSelectedExperimentsAndSensors', sensorComparisonSensorIds);

    this.usersService.UpdateUserSpecificSettings({
        items: [
          {
            key: this.settingKey,
            value: JSON.stringify(sensorComparisonSensorIds)
          }
        ]
      }
    ).subscribe(res => {
      // console.log('result from saving: ', res);
    });
  }

  createChart() {
    const timezone = new Date().getTimezoneOffset();
    this.chartOptions = {
      chart: {
        // margin: 0,
        //type: 'lollipop',
        type: 'spline',
        // type: 'column',
        backgroundColor: '#242434',
        // width:'100%',
        className: 'shieldChartContainer',
        // width:null,
        // style:{
        //   width:'auto',
        //   height:600,
        // },
        events: {
          redraw: function() {
            var chart = this;
            // self.setdataPointsStatus(chart,self)
          }
        }
      },
      time: {
        timezoneOffset: timezone
      },
      legend: {
        align: 'center',
        verticalAlign: 'top',
        itemStyle: {
          color: '#7cb5ec'
        }
      },
      title: {
        text: '',
        style: {
          color: '#ffffff'
        }
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date'
        },
        lineColor: '#ffffff',
        tickColor: '#ffffff',
        labels: {
          style: {
            color: '#ffffff'
          }
        },
        style: {
          color: '#ffffff'
        },
        gridLineDashStyle: 'ShortDash',
        //min:1596031232908,
        // max:1596636032908,
        showEmpty: false,
      },
      yAxis: {
        title: {
          text: this.unitSettingsService.selectedUnitsDict.water_temperature,
          style: {
            color: '#ffffff'
          },
        },
        // gridLineDashStyle: 'ShortDash',
        gridLineDashStyle: 'dot',
        gridLineColor: '#AAAAAA',
        style: {
          color: '#ffffff'
        },
        labels: {
          style: {
            color: '#ffffff'
          }
        },
        min: 0,
        showEmpty: false,
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 'auto'
          },
          chartOptions: {
            xAxis: {
              //categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              style: {
                color: '#ffffff'
              }
            },
            yAxis: {
              style: {
                color: '#ffffff'
              }
            }
          }
        }]
      },
      tooltip: {
        shared: true
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true
            //  fillColor:  (function(check){return check==1?"#ff0000":"#00ff00";})(this.chartOptions.series)
          },
          point: {
            events: {
              update: function(event) {
                console.log(event, '------------event');
              }
            }
          }
        }
      },
      lang: {
        noData: 'No data for current filter applied.',
      },
      noData: {
        style: {
          // fontWeight: 'bold',
          fontSize: '16px',
          color: '#ffffff',
          fontFamily: 'RobotoLight',
        }
      },
      colors: ['#DF843C', '#6CF', '#06C', '#036', '#000'],
      // series:[],
      series: [
        {
          name: 'Water Temperature',
          showInLegend: false,
          dataLabels: {
            enabled: false,
            //   enabled: true,
            //   color: '#ffffff',
            //   formatter: function () {
            //     return parseFloat(this.point.y.toFixed(2));
            //   },
          },
          data: [],
          tooltip: {
            pointFormatter: function() {
              return `<span style="color:${this.series['color']}">${this.series['symbolUnicode']}</span> ${this.series.name}: <b>${parseFloat(this.y.toFixed(3))}${this.series['tooltipOptions'].valueSuffix}</b><br/>`;
            },
            valueSuffix: ' °F'
          }
          // type: 'lollipop'
        },
        {
          name: 'Ambient Temperature',
          showInLegend: false,
          dataLabels: {
            enabled: false,
            //   enabled: true,
            //   color: '#ffffff',
            //   formatter: function () {
            //     return parseFloat(this.point.y.toFixed(2));
            //   },
          },
          data: [],
          tooltip: {
            pointFormatter: function() {
              return `<span style="color:${this.series['color']}">${this.series['symbolUnicode']}</span> ${this.series.name}: <b>${parseFloat(this.y.toFixed(3))}${this.series['tooltipOptions'].valueSuffix}</b><br/>`;
            },
            valueSuffix: ' °F'
          }
          // type: 'lollipop'
        },
      ],
      exporting: {
        enabled: false,
      }
    };
  }
}
