import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Result} from '../../../../../../api-internal/src/lib/models/result';
import {ExperimentConfigurationsService} from '../../../services/experiment-configurations.service';
import {AppStateService} from '../../../services/app-state.service';
import {Test} from '../../../../../../api/src/lib/models/test';
import {UnitConverter} from '../../../utils/UnitConverter';
import {OrganizationSettingsService} from '../../../services/organization-settings.service';
import {FormatDisplayNumberPipe} from '../../../pipes/format-display-number.pipe';

interface LabResultInterface extends Result {
  experimentTitle: string;
  status: string;
  unit?: string;
}

@Component({
  selector: 'lib-external-results-modal',
  templateUrl: './external-results-modal.component.html',
  styleUrls: ['./external-results-modal.component.scss'],
  providers: [FormatDisplayNumberPipe]
})
export class ExternalResultsModalComponent implements OnInit {
  @Input() results: LabResultInterface[];
  @Input() test: Test;

  // thresholds: { [key: string]: number } = {};
  externalConfigs = {
    "ammonium-nitrogen": {
      "title": "Ammonium-Nitrogen"
    },
    "potassium": {
      "title": "Potassium"
    },
    "sulfate-sulfur": {
      "title": "Sulfate-Sulfur"
    },
    "molybdenum": {
      "title": "Molybdenum"
    },
    "sodium": {
      "title": "Sodium"
    },
    "aluminium": {
      "title": "Aluminium"
    },
    "aluminum": {
      "title": "Aluminum"
    },
    "sio2": {
      "title": "SiO2",
      "unit": "ppm",
      "unitMultiplier": 1000
    },
    "po34": {
      "title": "PO4-P",
      "unit": "ppm",
      "unitMultiplier": 1000
    },
    "no3":  {
      "title": "N-NO3",
      "unit": "ppm",
      "unitMultiplier": 1000
    },
    "hg": {
      "title": "Hg",
      "unit": "ppm",
      "unitMultiplier": 1000
    }
  }

  constructor(private appStateService: AppStateService,
              private modalController: ModalController,
              private experminetConfService: ExperimentConfigurationsService,
              private formatDisplayNumberPipe: FormatDisplayNumberPipe,
              private orgSettingsService: OrganizationSettingsService) {
  }

  ngOnInit() {
    // this.thresholds = this.appStateService.thresholdsExternalService.getAllThresholdsForSensorId(this.appStateService.getAppState().getValue().location.sensor_id);

    // console.log('init external lab report modal with: ', this.results, this.experminetConfService.experimentConfigurationsMap);
    this.results?.map(result => {

      const conf = this.experminetConfService.getConfByExperimentMethodBitmap(result.experiment, result.method);
        if (conf) {
          result.experimentTitle = conf.title;
        } else if (this.externalConfigs[result.experiment]) {
          const cf = this.externalConfigs[result.experiment];
          result.experimentTitle = cf.title;
          if (cf.unit) {
            result.unit = cf.unit;
            result.value = result.value/cf.unitMultiplier;
          } else {
            result.unit = 'ppb';
          }
          const targetUnit = UnitConverter.getTargetUnitFromDisplayUnitsDict(result.unit, this.orgSettingsService.displayUnitsDict);
          result.value = this.formatDisplayNumberPipe.transform(UnitConverter.convertFromUnitTo(result.value, result.unit, targetUnit)) + ' ' + targetUnit as any;
        } else {
          result.experimentTitle = result.experiment.split('_').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ');
          result.unit = 'ppb';
          const targetUnit = UnitConverter.getTargetUnitFromDisplayUnitsDict(result.unit, this.orgSettingsService.displayUnitsDict);
          result.value = this.formatDisplayNumberPipe.transform(UnitConverter.convertFromUnitTo(result.value, result.unit, targetUnit)) + ' ' + targetUnit as any;
        }

        // if (this.thresholds[result.experiment]) {
        //   result.status = this.appStateService.thresholdsExternalService.getStatusForThresholdSensorIdAndExperiment(result.value, this.test.sensor_id, result.experiment);
        // } else {
        result.status = 'ok';
        // }
      }
    );
  }

  async cancel() {
    await this.modalController.dismiss();
  }
}
