/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Ki_validation_rules } from '../models/ki-_validation-_rules';
import { Ki_validation_rule } from '../models/ki-_validation-_rule';
import { Ki_validation_rule_input } from '../models/ki-_validation-_rule-_input';

/**
 * KI validation rule related operations
 */
@Injectable({
  providedIn: 'root',
})
class KiValidationRulesService extends __BaseService {
  static readonly getKiValidationRulesPath = '/ki_validation_rules';
  static readonly postKiValidationRulesPath = '/ki_validation_rules';
  static readonly getValidatePath = '/ki_validation_rules/{sensor_id}/validate';
  static readonly deleteKiValidationRuleKiValidationRulesIntSensorIdStringKeyPath = '/ki_validation_rules/{sensor_id}/{key}';
  static readonly putKiValidationRuleKiValidationRulesIntSensorIdStringKeyPath = '/ki_validation_rules/{sensor_id}/{key}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get KI validation rules
   * @param params The `KiValidationRulesService.GetKiValidationRulesParams` containing the following parameters:
   *
   * - `sensor_ids`: List of sensor/location ids
   *
   * - `keys`: List of keys (field names)
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getKiValidationRulesResponse(params: KiValidationRulesService.GetKiValidationRulesParams): __Observable<__StrictHttpResponse<Ki_validation_rules>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.keys || []).forEach(val => {if (val != null) __params = __params.append('keys', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ki_validation_rules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Ki_validation_rules>;
      })
    );
  }
  /**
   * Get KI validation rules
   * @param params The `KiValidationRulesService.GetKiValidationRulesParams` containing the following parameters:
   *
   * - `sensor_ids`: List of sensor/location ids
   *
   * - `keys`: List of keys (field names)
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getKiValidationRules(params: KiValidationRulesService.GetKiValidationRulesParams): __Observable<Ki_validation_rules> {
    return this.getKiValidationRulesResponse(params).pipe(
      __map(_r => _r.body as Ki_validation_rules)
    );
  }

  /**
   * Create KI validation rule
   * @param params The `KiValidationRulesService.PostKiValidationRulesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postKiValidationRulesResponse(params: KiValidationRulesService.PostKiValidationRulesParams): __Observable<__StrictHttpResponse<Ki_validation_rule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ki_validation_rules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Ki_validation_rule>;
      })
    );
  }
  /**
   * Create KI validation rule
   * @param params The `KiValidationRulesService.PostKiValidationRulesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postKiValidationRules(params: KiValidationRulesService.PostKiValidationRulesParams): __Observable<Ki_validation_rule> {
    return this.postKiValidationRulesResponse(params).pipe(
      __map(_r => _r.body as Ki_validation_rule)
    );
  }

  /**
   * @param sensor_id undefined
   */
  getValidateResponse(sensorId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ki_validation_rules/${encodeURIComponent(String(sensorId))}/validate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_id undefined
   */
  getValidate(sensorId: string): __Observable<null> {
    return this.getValidateResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete KI validation rule
   * @param params The `KiValidationRulesService.DeleteKiValidationRuleKiValidationRulesIntSensorIdStringKeyParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `key`: Field name
   */
  deleteKiValidationRuleKiValidationRulesIntSensorIdStringKeyResponse(params: KiValidationRulesService.DeleteKiValidationRuleKiValidationRulesIntSensorIdStringKeyParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/ki_validation_rules/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete KI validation rule
   * @param params The `KiValidationRulesService.DeleteKiValidationRuleKiValidationRulesIntSensorIdStringKeyParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `key`: Field name
   */
  deleteKiValidationRuleKiValidationRulesIntSensorIdStringKey(params: KiValidationRulesService.DeleteKiValidationRuleKiValidationRulesIntSensorIdStringKeyParams): __Observable<null> {
    return this.deleteKiValidationRuleKiValidationRulesIntSensorIdStringKeyResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update KI validation rule
   * @param params The `KiValidationRulesService.PutKiValidationRuleKiValidationRulesIntSensorIdStringKeyParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `key`: Field name
   */
  putKiValidationRuleKiValidationRulesIntSensorIdStringKeyResponse(params: KiValidationRulesService.PutKiValidationRuleKiValidationRulesIntSensorIdStringKeyParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ki_validation_rules/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update KI validation rule
   * @param params The `KiValidationRulesService.PutKiValidationRuleKiValidationRulesIntSensorIdStringKeyParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `key`: Field name
   */
  putKiValidationRuleKiValidationRulesIntSensorIdStringKey(params: KiValidationRulesService.PutKiValidationRuleKiValidationRulesIntSensorIdStringKeyParams): __Observable<null> {
    return this.putKiValidationRuleKiValidationRulesIntSensorIdStringKeyResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module KiValidationRulesService {

  /**
   * Parameters for getKiValidationRules
   */
  export interface GetKiValidationRulesParams {

    /**
     * List of sensor/location ids
     */
    sensorIds?: Array<number>;

    /**
     * List of keys (field names)
     */
    keys?: Array<string>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postKiValidationRules
   */
  export interface PostKiValidationRulesParams {
    payload: Ki_validation_rule;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for deleteKiValidationRuleKiValidationRulesIntSensorIdStringKey
   */
  export interface DeleteKiValidationRuleKiValidationRulesIntSensorIdStringKeyParams {

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * Field name
     */
    key: string;
  }

  /**
   * Parameters for putKiValidationRuleKiValidationRulesIntSensorIdStringKey
   */
  export interface PutKiValidationRuleKiValidationRulesIntSensorIdStringKeyParams {
    payload: Ki_validation_rule_input;

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * Field name
     */
    key: string;
  }
}

export { KiValidationRulesService }
