import { Pipe, PipeTransform } from '@angular/core';
import {ExperimentConfigurationsService, ExperimentConfInterface} from '../services/experiment-configurations.service';
import {OrganizationSettingsService} from '../services/organization-settings.service';
import {formatNumber} from '@angular/common';
import {UnitConverter} from '../utils/UnitConverter';
import {UserSettingsCachedService} from '../services/user-settings-cached.service';

@Pipe({
  name: 'resultUnit'
})
export class ResultUnitPipe implements PipeTransform {
  constructor(private expConfService: ExperimentConfigurationsService,
              private userSettingsCachedService: UserSettingsCachedService
  ) {
  }

  transform(value: any, type: string): string {
    if (value === null || value === undefined) {
      return 'N/A';
    }

    const conf = this.expConfService.getConfByExperimentMethodBitmap(type);
    let unit = UnitConverter.getTargetUnitFromConfAndUnitsDict(conf, this.userSettingsCachedService.selectedUnitsDict);

    // console.log('uniting:', conf.key, value, typeof value, conf.unit, unit);
    if (typeof value === 'string') {
      if ((value.substring(0, 2) === '< ' || value.substring(0, 2) === '> ') && !isNaN(value.substring(2).replace(/,/g, '') as any)) {
        return value + ' ' + unit;
      }
      if (!isNaN(value.replace(/,/g, '') as any)) {
        return value + ' ' + unit;
      }
    }
    if (!isNaN(value as any)) {
      return value + ' ' + unit;
    }
    // console.log('uniting failed:', conf.key, value, typeof value, typeof value === 'string', conf.unit, unit, value.replace(/,/g, ''));
    return value as string;
  }
}
