import {Injectable} from '@angular/core';
import {Shield_device} from "../../../../api/src/lib/models/shield-_device";
import {Observable} from "rxjs";
import {SensorsService} from "../../../../api/src/lib/services/sensors.service";
import {map} from "rxjs/operators";
import {Sensor_setting} from "../../../../api/src/lib/models/sensor-_setting";

@Injectable({
  providedIn: 'root'
})
export class SensorsBlacklistService {

  experimentsBlackdict: {[key: number]: {[key: string]: boolean}};

  constructor(private sensorsService: SensorsService) {
  }

  getSensorBlackdict(): Observable<{ [key: number]: { [key: string]: boolean } }> {
    let devices: Shield_device[];
    let settings: Sensor_setting[] = [];
    return this.sensorsService.getSettings()
      .pipe(
        map(res => {
          res.items?.map((settingArray: any) => {
            const setting = settingArray.find(setting => setting.key === 'cp_experiment_blacklist');
            if (setting) {
              settings.push(setting);
            }
          });

          const blackDict: { [key: number]: { [key: string]: boolean } } = {};
          settings.map(setting => {
            if (setting && typeof setting.value === "string" && setting.value.length > 0) {
              const disableDict = {};
              setting.value.split(',').map(exp => disableDict[exp] = true);
              blackDict[setting['sensor_id']] = disableDict;
            }
          });
          this.experimentsBlackdict = blackDict;
          return blackDict;
        })
      )
  }
}
