import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class DashboardShieldGuard implements CanActivate {
  last = '';
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (state.url === '/tabs/dashboard/shield') {
      if (this.last) {
        this.router.navigate([this.last]);
      } else {
        this.router.navigate(['/tabs/dashboard/shield/summary']);
      }
      return false;
    }
    this.last = state.url;
    return true;
  }
}
