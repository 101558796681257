export const boronDataValues ={
    "Shield1":{
        Annual:{
            xAxis: [{
                accessibility: {
                description: 'Years'
                },
                categories:['2016','2017','2018','2019','2020']
            }],

            yAxis: {
                title: {
                text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },

            series: [{
                name: 'Boron',
                data: [
                    [0.1, 0.2, 0.4],
                    [0.2, 0.4, 0.5],
                    [0.1, 0.3, 0.7],
                    [0.3, 0.5, 0.7],
                    [0.2, 0.5, 0.8],
                  ]
            }]
        },
        Winter:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['December','January','February']
              }],
            
              yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
              },
            series: [{
                name: 'Boron',
                data: [
                    [0.05, 0.25, 0.4],
                    [0.0, 0.3, 0.5],
                    [0.0, 0.5, 1.5],
                  ]
              }]
        },
        Spring:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['March','April','May']
              }],
            
              yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
              },
            series: [{
                name: 'Boron',
                data: [
                    [0.1, 0.4, 2.7],
                    [0.0, 0.3, 0.5],
                    [0.6, 0.7, 3.5],
                  ]
              }]
        },
        Summer:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['June','July','August']
              }],
            yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },
            series: [{
                name: 'Boron',
                data: [
                    [.8, 1.0, 1.1],
                    [.0, 1.2, 2.2],
                    [.4, 1.0, 2.5],
                  ]
            }]
        },
        Autumn:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['September','October','November']
              }],
            
              yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },
            series: [{
                name: 'Boron',
                data: [
                    [.2, .8, 1.2],
                    [1.6, 1.8, 5.5],
                    [.7, .8, 1.5],
                  ]
            }]
        },
        Daily:{
            xAxis: [{
                accessibility: {
                  description: 'Days'
                },
                categories:['02/03/2020','02/04/2020','02/05/2020','02/06/2020','02/07/2020']
              }],
            
            yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },
            series: [{
                name: 'Boron',
                data: [
                    [.0, .8, 1.5],
                    [.0, .3, 4.5],
                    [1.3, 2.4, 1.5],
                    [1.5, 2.2, 6.6],
                    [2.0, 2.0, 2.4],
                  ]
            }]
        }
    },
    "Shield2":{
        Annual:{
            xAxis: [{
                accessibility: {
                description: 'Years'
                },
                categories:['2016','2017','2018','2019','2020']
            }],

            yAxis: {
                title: {
                text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },

            series: [{
                name: 'Boron',
                data: [
                    [.0, 1.2, 1.4],
                    [.4, .5, .6],
                    [.3, .8, 1.3],
                    [.1, .3, .8],
                    [.2, .2, .5],
                  ]
            }]
        },
        Winter:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['December','January','February']
              }],
            
              yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
              },
            series: [{
                name: 'Boron',
                data: [
                    [.0, 1.2, 1.3],
                    [.1, 1.2, 1.3],
                    [.1, .4, 1.3],
                  ]
              }]
        },
        Spring:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['March','April','May']
              }],
            
              yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
              },
            series: [{
                name: 'Boron',
                data: [
                    [.4, 1.2, 1.4],
                    [.3, 1.1, 1.1],
                    [.4, .5, .6],
                  ]
              }]
        },
        Summer:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['June','July','August']
              }],
            yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },
            series: [{
                name: 'Boron',
                data: [
                    [.8, 1.2, 1.4],
                    [1.1, 1.2, 1.3],
                    [1.0, 1.3, 1.4],
                  ]
            }]
        },
        Autumn:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['September','October','November']
              }],
            
              yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },
            series: [{
                name: 'Boron',
                data: [
                    [.4, .8, 1.2],
                    [.4, .7, .8],
                    [.0, 1.2, 1.3],
                  ]
            }]
        },
        Daily:{
            xAxis: [{
                accessibility: {
                  description: 'Days'
                },
                categories:['02/03/2020','02/04/2020','02/05/2020','02/06/2020','02/07/2020']
              }],
            
            yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },
            series: [{
                name: 'Boron',
                data: [
                    [.1, .3, .5],
                    [.0, .2, .6],
                    [.0, .2, .7],
                    [.1, .3, .9],
                    [.2, .3, .10],
                  ]
            }]
        }
    },
}

export const chlorineDataValues ={
    "Shield1":{
        Annual:{
            xAxis: [{
                accessibility: {
                description: 'Years'
                },
                categories:['2016','2017','2018','2019','2020']
            }],

            yAxis: {
                title: {
                text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },

            series: [{
                name: 'Chlorine',
                data: [
                    [0, 80, 140],
                    [20, 100, 170],
                    [10, 150, 270],
                    [140, 160, 190],
                    [20, 80, 210],
                  ]
            }]
        },
        Winter:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['December','January','February']
              }],
            
              yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
              },
            series: [{
                name: 'Chlorine',
                data: [
                    [0, 8, 13],
                    [6, 8, 9],
                    [3, 8, 15],
                ]
              }]
        },
        Spring:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['March','April','May']
              }],
            
              yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
              },
            series: [{
                name: 'Chlorine',
                data: [
                    [100, 200, 300],
                    [30, 300, 500],
                    [800, 800, 900],
                  ]
              }]
        },
        Summer:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['June','July','August']
              }],
            yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },
            series: [{
                name: 'Chlorine',
                data: [
                    [80, 100, 110],
                    [60, 70, 100],
                    [60, 100, 110],
                  ]
            }]
        },
        Autumn:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['September','October','November']
              }],
            
              yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },
            series: [{
                name: 'Chlorine',
                data: [
                    [80, 80, 120],
                    [40, 70, 80],
                    [30, 80, 130],
                  ]
            }]
        },
        Daily:{
            xAxis: [{
                accessibility: {
                  description: 'Days'
                },
                categories:['02/03/2020','02/04/2020','02/05/2020','02/06/2020','02/07/2020']
              }],
            
            yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },
            series: [{
                name: 'Chlorine',
                data: [
                    [30, 30, 50],
                    [0, 10, 20],
                    [30, 40, 50],
                    [10, 20, 60],
                    [20, 20, 70],
                  ]
            }]
        }
    },
    "Shield2":{
        Annual:{
            xAxis: [{
                accessibility: {
                description: 'Years'
                },
                categories:['2016','2017','2018','2019','2020']
            }],

            yAxis: {
                title: {
                text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },

            series: [{
                name: 'Chlorine',
                data: [
                    [0, 1200, 1400],
                    [400, 500, 600],
                    [300, 800, 1300],
                    [100, 300, 800],
                    [200, 200, 500],
                  ]
            }]
        },
        Winter:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['December','January','February']
              }],
            
              yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
              },
            series: [{
                name: 'Chlorine',
                data: [
                    [0, 120, 130],
                    [100, 120, 130],
                    [100, 400, 1300],
                  ]
              }]
        },
        Spring:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['March','April','May']
              }],
            
              yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
              },
            series: [{
                name: 'Chlorine',
                data: [
                    [40, 120, 140],
                    [30, 110, 110],
                    [40, 500, 600],
                  ]
              }]
        },
        Summer:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['June','July','August']
              }],
            yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },
            series: [{
                name: 'Chlorine',
                data: [
                    [8, 12, 14],
                    [11, 12, 13],
                    [10, 13, 14],
                  ]
            }]
        },
        Autumn:{
            xAxis: [{
                accessibility: {
                  description: 'Months'
                },
                categories:['September','October','November']
              }],
            
              yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },
            series: [{
                name: 'Chlorine',
                data: [
                    [40, 80, 120],
                    [40, 70, 80],
                    [0, 120, 130],
                  ]
            }]
        },
        Daily:{
            xAxis: [{
                accessibility: {
                  description: 'Days'
                },
                categories:['02/03/2020','02/04/2020','02/05/2020','02/06/2020','02/07/2020']
              }],
            
            yAxis: {
                title: {
                  text: 'Contaminant Concentration (ppm)'
                },
                min: 0
            },
            series: [{
                name: 'Chlorine',
                data: [
                    [10, 300, 500],
                    [0, 200, 600],
                    [0, 200, 700],
                    [1, 300, 900],
                    [2, 300, 1000],
                  ]
            }]
        }
    },
}


export const nitrateDataValues = {
    "Shield1":{
        Annual:{
    
      xAxis: [{
        accessibility: {
          description: 'Years'
        },
        categories:['2016','2017','2018','2019','2020']
      }],
    
      yAxis: {
        title: {
          text: 'Contaminant Concentration (ppm)'
        },
        min: 0
      },
    
    series: [{
        name: 'Nitrate',
        data: [
          [0, 2, 6],
          [0, 1, 1.5],
          [0, 3, 3.5],
          [1, 2, 4],
          [1, 4, 6],
        ]
      }]
    },
    
    Winter:{
      xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['December','January','February']
      }],
    
      yAxis: {
        title: {
          text: 'Contaminant Concentration (ppm)'
        },
        min: 0
      },
    
    series: [{
        name: 'Nitrate',
        data: [
          [0, 1, 4],
          [2, 2, 4],
          [2, 2, 6],
        ]
      }]
    },
    
    Spring:{
      xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['March','April','May']
      }],
    
      yAxis: {
        title: {
          text: 'Contaminant Concentration (ppm)'
        },
        min: 0
      },
    
    series: [{
        name: 'Nitrate',
        data: [
          [1, 4, 6],
          [2, 7, 2],
          [5, 2, 2],
        ]
      }]
    },
    Summer:{
    
      xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['June','July','August']
      }],
    
      yAxis: {
        title: {
          text: 'Contaminant Concentration (ppm)'
        },
        min: 0
      },
    series: [{
        name: 'Nitrate',
        data: [
          [0, 1, 2],
          [0, 2, 4],
          [0, 2, 3.5],
        ]
      }]
    },
    Autumn:{
    
      xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['September','October','November']
      }],
    
      yAxis: {
        title: {
          text: 'Contaminant Concentration (ppm)'
        },
        min: 0
      },
    
    series: [{
        name: 'Nitrate',
        data: [
          [2, 2, 4.5],
          [1, 2, 3.5],
          [2, 4, 6],
        ]
      }]
    },
    Daily:{
    
      xAxis: [{
        accessibility: {
          description: 'Days'
        },
        categories:['02/03/2020','02/04/2020','02/05/2020','02/06/2020','02/07/2020']
      }],
    
      yAxis: {
        title: {
          text: 'Contaminant Concentration (ppm)'
        },
        min: 0
      },
    
    series: [{
        name: 'Nitrate',
        data: [
          [0, 1, 1.5],
          [0, 3, 4.5],
          [1.3, 2.4, 1.5],
          [1.5, 2.2, 6.6],
          [2.0, 2.0, 2.4],
        ]
      }]
    
    },
    },
    
    "Shield2":{
        Annual:{
      xAxis: [{
        accessibility: {
          description: 'Years'
        },
        categories:['2016','2017','2018','2019','2020']
      }],
    
      yAxis: {
        title: {
          text: 'Contaminant Concentration (ppm)'
        },
        min: 0
      },
    
    series: [{
        name: 'Nitrate',
        data: [
          [0.5, 1.20, 1.4],
          [4.5, 5.0, 6.0],
          [3.5, 8.0, 1.3],
          [1.5, 3.5, 8.0],
          [2.0, 2.0, 5.0],
        ]
      }]
    },
     Winter:{
    
      xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['December','January','February']
      }],
    
      yAxis: {
        title: {
          text: 'Contaminant Concentration (ppm)'
        },
        min: 0
      },
    
    series: [{
        name: 'Nitrate',
        data: [
          [0, 1.2, 1.3],
          [1, 1.2, 1.3],
          [0, 2.0, 3.3],
        ]
      }]
    },
     
    Spring:{
        xAxis: [{
          accessibility: {
            description: 'Months'
          },
          categories:['March','April','May']
        }],
      
        yAxis: {
          title: {
            text: 'Contaminant Concentration (ppm)'
          },
          min: 0
        },
      
      series: [{
          name: 'Nitrate',
          data: [
            [1, 4, 6],
            [2, 7, 2],
            [5, 2, 2],
          ]
        }]
      },
    Summer:{
      xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['June','July','August']
      }],
    
      yAxis: {
        title: {
          text: 'Contaminant Concentration (ppm)'
        },
        min: 0
      },
    
    series: [{
        name: 'Nitrate',
        data: [
          [0.8, 1.2, 1.4],
          [1.1, 1.2, 1.3],
          [1.0, 1.3, 1.4],
        ]
      }]
    },
     Autumn:{
      xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['September','October','November']
      }],
    
      yAxis: {
        title: {
          text: 'Contaminant Concentration (ppm)'
        },
        min: 0
      },
    
    series: [{
        name: 'Nitrate',
        data: [
          [0.5, 0.8, 1.2],
          [0.0, 0.77, 0.8],
          [0.0, 1.2, 1.3],
        ]
      }]
    },
     Daily:{
    
      xAxis: [{
        accessibility: {
          description: 'Days'
        },
        categories:['02/03/2020','02/04/2020','02/05/2020','02/06/2020','02/07/2020']
      }],
    
      yAxis: {
        title: {
          text: 'Contaminant Concentration (ppm)'
        },
        min: 0
      },
    
    series: [{
        name: 'Nitrate',
        data: [
          [1.0, 3.0, 5.0],
          [0, 2.0, 6.0],
          [0, 2.0, 7.0],
          [1.0, 3.0, 9.0],
          [2.0, 3.0, 4.0],
        ]
      }]
    
     }
    }
}
    
    