import { Component, OnInit } from '@angular/core';
import {DropdownOptionsInterface} from "../../../../components/shared/dropdown/models/dropdown-options.interface";

@Component({
  selector: 'app-nutrient-wells',
  templateUrl: './nutrient-wells.component.html',
  styleUrls: ['./nutrient-wells.component.scss']
})
export class NutrientWellsComponent implements OnInit {

  wellsOptions: DropdownOptionsInterface = {
    values: [
      { title: 'North well (drilled)', value: 'North well (drilled)'},
      { title: 'South well (surface)', value: 'South well (surface)'}
    ]
  }

  wells = {
    'North well (drilled)': {
      name: 'North well (drilled)',
      ph: 6,
      iron: 8,
      mg: 2,
      insights: [
        'High iron content, usnsuitable for drinking unfiltered',
        'Grapes, carrots and tomatos will grow well with this water',
        'Water is unsuitable for wheat'
      ]
    },
    'South well (surface)': {
      name: 'South well',
      ph: 7,
      iron: 0,
      mg: 8,
      insights: [
        'Olives, corn and avocados will grow well with this water',
        'Water is unsuitable for peas'
      ]
    }
  }
  selectedWell = this.wells["North well (drilled)"];
  constructor() { }

  ngOnInit() {
  }

  updateSelection(sel) {
    // console.log('sel', sel);
    this.selectedWell = this.wells[sel.value]
  }
}
