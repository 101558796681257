/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import * as cloneDeep from 'lodash/cloneDeep';

import {
  DropdownGroupInterface,
  DropdownModelInterface,
  DropdownOptionsInterface,
  DropdownValueInterface
} from './models/dropdown-options.interface';
import {isBoolean} from "util";
import {AppService} from '../../../services/legacy-services/app.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  locationsStatusConstants = AppService.environment().CONSTANTS.WAVE_LOCATIONS_STATUS;

  private _options: DropdownOptionsInterface;
  private _preSelected: DropdownValueInterface;
  @Input() alignLeft: boolean;
  @Input() statusEnabled: boolean;
  @Input() filterStatusEnabled: boolean;
  @Input() multipleSelection: boolean;
  @Input() multipleSelectionLimit: number;
  @Input() listValues: boolean;
  @Input() searchEnabled: boolean;
  @Input() tableDesign: boolean;
  @Input() hideChevron: boolean = false;
  // @Input() groupsEnabled: boolean;
  @Input() maxTitleWidth: 10000;
  groupsEnabled: boolean;
  @Input() dropdownLabel: any;
  @Output() updateSelection: EventEmitter<DropdownValueInterface | DropdownValueInterface[]> =
    new EventEmitter<DropdownValueInterface | DropdownValueInterface[]>();
  @Output() optionsVisible: EventEmitter<boolean> = new EventEmitter<boolean>();

  public selection: DropdownValueInterface;
  public selectionGroup: DropdownGroupInterface;
  public searchModel: any = {
    inputModel: null,
    enabled: false
  };

  filteredOptions: DropdownValueInterface[] = [];

  public dropdownModel: DropdownModelInterface = {
    data: {
      dirty: [],
      pristine: [],
      noValuesMessage: ''
    },
    groupsData: {
      dirty: [],
      pristine: [],
      allData: [],
    },
    ui: {
      areOptionsVisible: false,
      multipleSelected: []
    }
  };

  @ViewChild('dropdownSearchInput', {static: true}) searchInputElement: ElementRef;

  static findDropdownOption(options: DropdownOptionsInterface, locationName: string, groupName: string = null): DropdownValueInterface {
    for (const value of options.values) {
      if ((value.title === locationName && (groupName === null || groupName === value.group)) || typeof value.value === 'string' && value.value === locationName) {
        if (groupName && !value.hasOwnProperty('group')) {
          value.group = groupName;
        }
        return Object.assign({}, value);
      }
    }
  }

  @HostListener('document:click', ['$event']) clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
    } else {
      this.fold();
    }
  }

  @Input() set options(value: DropdownOptionsInterface) {
    if (Array.isArray(value)) {
      value = {values: value};
    }
    this._options = value;
    this.groupsEnabled = !(value.groups === null || value.groups === undefined || value.groups.length === 0);
    this.setSelectBoxData(value);
    // this.select(value, false);
  }


  get options(): DropdownOptionsInterface {
    return this._options;
  }

  @Input() set preSelected(value: DropdownValueInterface) {
    this._preSelected = value;
    if (this._options) {
      this.dropdownModel.ui.multipleSelected = [];
      this.setSelectBoxData(this.options);
    }
  }

  get preSelected(): DropdownValueInterface {
    return this._preSelected;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private eRef: ElementRef) {
  }

  ngOnInit() {
    this.setSelectBoxData(this.options);
    // @TODO: reimplement
    // this.focusSearchInput();
  }

  setSelectBoxData(data: DropdownOptionsInterface) {
    if (this.groupsEnabled) {
      this.dropdownModel.groupsData.allData = this.deepCopy(data.values);
      // this.dropdownModel.data.noValuesMessage = this.deepCopy(data.noValuesMessage); @TODO: reimplement
      this.dropdownModel.groupsData.pristine = data.groups.map((ditem) => {
        return this.deepCopy(ditem);
      });
      this.dropdownModel.groupsData.dirty = this.deepCopy(this.dropdownModel.groupsData.pristine);
    } else {
      if (data.values === null || data.values === undefined) {
        data.values = [];
      }
      this.dropdownModel.data.pristine = data.values.map((ditem) => {
        const item = this.deepCopy(ditem);
        if (this.multipleSelection) {
          if (item.checked) {
            this.dropdownModel.ui.multipleSelected.push(item);
          } else {
            item.checked = false;
          }
        }
        return item;
      });
      this.dropdownModel.data.dirty = this.deepCopy(this.dropdownModel.data.pristine);
    }

    // console.log(this.preSelected);

    if (this.multipleSelection) {
      if (!this.preSelected) {
        this.dropdownModel.ui.multipleSelected = [];
      }
      if (Array.isArray(this.preSelected)) {
        this.dropdownModel.ui.multipleSelected = [];
        // this.dropdownModel.ui.multipleSelected = cloneDeep(this.preSelected);
        // const selected = this.dropdownModel.ui.multipleSelected.find((itemSelected) => {
        //   return itemSelected.title === item.title && itemSelected.value === item.value;
        // });
        // this.dropdownModel.ui.multipleSelected.splice(this.dropdownModel.ui.multipleSelected.indexOf(selected), 1);
        this.preSelected.map((item) => {
          const selected = this.dropdownModel.data.pristine.find((itemSelected) => {
            return itemSelected.title === item.title && itemSelected.value === item.value;
          });
          if (selected) {
            selected.checked = true;
            this.dropdownModel.ui.multipleSelected.push(selected);
          }
        });
        this.dropdownModel.data.dirty = this.deepCopy(this.dropdownModel.data.pristine);
      }
      if (this.dropdownModel.ui.multipleSelected.length) {
        if (this.listValues) {
          this.selection = {
            title: this.dropdownModel.ui.multipleSelected.map(sel => sel.title).join(', '),
            value: null,
            group: null
          };
        } else {
          this.selection = {
            title: this.dropdownModel.ui.multipleSelected.length + ' selected',
            value: null,
            group: null
          };
        }
      } else if (this.dropdownLabel) {
        this.selection = {
          title: this.deepCopy(this.dropdownLabel),
          value: null,
          group: null
        };
      } else {
        this.selection = this.deepCopy(this.options.values[0]);
      }
    } else {
      if (this.preSelected) {
        this.selection = this.deepCopy(this.preSelected); // seems here
        // this.selection = this.preSelected; // seems here
        // if (this.groupsEnabled)
        // console.log(this.selection);
      } else if (this.dropdownLabel) {
        this.selection = {
          title: this.deepCopy(this.dropdownLabel),
          value: null,
          group: null
        };
      } else if (!this.preSelected) {
        // console.log();
        if (this.options.values.length > 0) {
          this.selection = this.deepCopy(this.options.values[0]);
        }

      } else {
        // console.log();
        // @TODO: reimplement
        // this.selection = this.deepCopy(this.options[0]); // hidden to avoid preselect
      }
      if (this.groupsEnabled) {
        if (this.selection) {
          if (this.selection.hasOwnProperty('group')) {
            this.selectionGroup = data.groups.find(item => this.selection.group
              && this.selection.group === item.group);
            this.dropdownModel.data.pristine = this.dropdownModel.groupsData.allData.filter((ditem) => {
              if (ditem.group === this.selectionGroup.group) {
                return ditem;
              }
            });
          }
        }

        this.dropdownModel.data.dirty = this.deepCopy(this.dropdownModel.data.pristine);
      }
    }

    this.calculateFilteredOptions();
    // this.updateDropdownData(this.selection);
  }

  select(event, item, isInitialValue = true) {
    event.stopPropagation();
    if (this.multipleSelection) {
      if (item && item.value !== null) {
        item.checked = !item.checked;

        if (item.checked) {
          this.dropdownModel.ui.multipleSelected.push(item);
        } else {
          const selected = this.dropdownModel.ui.multipleSelected.find((itemSelected) => {
            return itemSelected.title === item.title && itemSelected.value === item.value;
          });
          item.checked = false;
          this.dropdownModel.ui.multipleSelected.splice(this.dropdownModel.ui.multipleSelected.indexOf(selected), 1);
        }

        if (this.dropdownModel.ui.multipleSelected.length) {
          if (this.listValues) {
            this.selection = {
              title: this.dropdownModel.ui.multipleSelected.map(sel => sel.title).join(', '),
              value: null,
              group: null
            };
          } else {
            this.selection = {
              title: this.dropdownModel.ui.multipleSelected.length + ' selected',
              value: null,
              group: null
            };
          }
        } else if (this.dropdownLabel) {
          this.selection = {
            title: this.deepCopy(this.dropdownLabel),
            value: null,
            group: null
          };
        } else {
          this.selection = this.deepCopy(this.options[0]);
        }

        if (this.updateSelection && isInitialValue) {
          // console.log('emitting;', this.dropdownModel, this.dropdownModel.ui.multipleSelected);
          this.updateSelection.emit(this.dropdownModel.ui.multipleSelected);
          // this.updateSelection({ selection: this.dropdownModel.ui.multipleSelected, itemData: this.itemData });
        }
      }
    } else {
      if (item) {
        this.selection = this.deepCopy(item);
        // this.updateDropdownData(selection);

        if (this.updateSelection && isInitialValue) {
          // this.updateSelection({ selection: this.selection, itemData: this.itemData });
          this.updateSelection.emit(this.selection);
        }
      }
      this.fold();
    }
  }

  selectGroup(groupItem) {
    event.stopPropagation();
    this.selectionGroup = this.deepCopy(groupItem);
    this.dropdownModel.data.pristine = this.dropdownModel.groupsData.allData.filter((ditem) => {
      if (ditem.group === this.selectionGroup.group) {
        return this.deepCopy(ditem);
      }
    });
    this.dropdownModel.data.dirty = this.deepCopy(this.dropdownModel.data.pristine);
    this.calculateFilteredOptions();
  }

  /**ok, push it for now and we can fix it tomorrow
   * @description Remove the selected item from the dropdown.
   * @param item
   */
  updateDropdownData(item) {
    if (item) {
      this.dropdownModel.data.dirty = [].concat(this.dropdownModel.data.pristine)
        .filter(ditem => ditem.title !== item.title || ditem.value !== item.value);
    }
  }

  fold() {
    this.dropdownModel.ui.areOptionsVisible = false;
    this.optionsVisible.emit(this.dropdownModel.ui.areOptionsVisible);
    this.searchModel.enabled = false;
    this.searchModel.inputModel = null;
  }

  expand(event) {
    event.stopPropagation();
    if (!this.searchModel.enabled) {
      this.dropdownModel.ui.areOptionsVisible = !this.dropdownModel.ui.areOptionsVisible;
      this.optionsVisible.emit(this.dropdownModel.ui.areOptionsVisible);
      this.cd.detectChanges();
    }
  }

  calculateFilteredOptions() {
    this.filteredOptions = this.dropdownModel.data.dirty.filter(this.searchHandler());
  }

  searchHandler() {
    return (item) => {
      if (this.searchModel.inputModel && item.title) {
        const searchNeedle = this.searchModel.inputModel.toLowerCase();
        const searchHaystackLocation = item.title.toLowerCase();
        return searchHaystackLocation.includes(searchNeedle);
      } else {
        return true;
      }
    };
  }

  enableSearch() {
    event.stopPropagation();
    this.calculateFilteredOptions();
    this.searchModel.enabled = true;
    this.dropdownModel.ui.areOptionsVisible = true;
    this.optionsVisible.emit(this.dropdownModel.ui.areOptionsVisible);
  }

  // @TODO: reimplement
  focusSearchInput() {
    // let searchInputElement = this.$window.document.getElementById('dropdown-search-input-id');
    // this.searchInputElement.nativeElement.focus();
  }

  deepCopy(obj: any): any {
    return cloneDeep(obj);
  }
}
