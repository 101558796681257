import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeDifference'
})
export class TimeDifferencePipe implements PipeTransform {

  transform(value: Date): string {
    if (!value) {
      return '';
    }
    const diffMs = moment().diff(value);
    if (diffMs < 60 * 1000) {
      return '< 1 minute ago'
    } else if (diffMs < 2 * 60 * 1000) {
      return '1 minute ago';
    } else if (diffMs < 60 * 60 * 1000) {
      return Math.floor(diffMs/(60 * 1000)) + ' minutes ago';
    } else if (diffMs < 2 * 60 * 60 * 1000) {
      return '1 hour ago';
    } else if (diffMs < 24 * 60 * 60 * 1000) {
      return Math.floor(diffMs/(60 * 60 * 1000)) + ' hours ago';
    } else if (diffMs < 2 * 24 * 60 * 60 * 1000) {
      return '1 day ago';
    } else {
      return Math.floor(diffMs/(24 * 60 * 60 * 1000)) + ' days ago';
    }
  }

}
