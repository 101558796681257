export interface UserRoleInterface {
  id: number;
  name: string;
}
export const UserRoles: UserRoleInterface[] = [
  {id: 1, name: 'SuperAdmin'},
  {id: 2, name: 'OrgAdmin'},
  {id: 3, name: 'GroupAdmin'},
  {id: 4, name: 'BusinessUser'},
  {id: 5, name: 'GuestUser'}
];

export const UserRolesByIdDict: {[key: number]: UserRoleInterface} = {
  1: {id: 1, name: 'SuperAdmin'},
  2: {id: 2, name: 'OrgAdmin'},
  3: {id: 3, name: 'GroupAdmin'},
  4: {id: 4, name: 'BusinessUser'},
  5: {id: 5, name: 'GuestUser'}
};
