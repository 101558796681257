/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainRoutes } from './main.routes';
import {IonicModule, Platform} from '@ionic/angular';
import {MainPage} from "./main.page";
import {SharedModule} from "../../components/shared/shared.module";
import {KetosLibModule} from "../../ketos-lib.module";
import {HeaderComponent} from "../../components/shared/layout/header/header.component";
import {FooterComponent} from "../../components/shared/layout/footer/footer.component";
import {SensorDetailsModule} from "../sensor-details/sensor-details.module";
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(MainRoutes),
    SharedModule,
    KetosLibModule,
    IonicModule,
    SensorDetailsModule
  ],
  providers:[
    Platform,
  ],
  declarations: [MainPage],
  exports: [
    HeaderComponent,
    FooterComponent,
  ]
})
export class MainModule {
  constructor() {
  }
}
