/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {KetosAuditService} from '../../../../../../ketos-lib/src/lib/services/ketos-audit.service';
import {AppStorage} from '../../../../../../ketos-lib/src/lib/utils/AppStorage';
import {HTTPStatus} from '../../../../../../ketos-lib/src/lib/services/http-status.service';
import {KetosErrorService} from '../../../../../../ketos-lib/src/lib/services/ketos-error.service';

@Injectable()
export class HTTPListener implements HttpInterceptor {
  constructor(
    private status: HTTPStatus,
    private auditService: KetosAuditService,
    private ketosErrorService: KetosErrorService,
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = AppStorage.getItem('token');
    if (token && !req.headers.get('Authorization')) {
      req = req.clone({
        setHeaders: {
          'Authorization': token
        }
      });
    }

    let hidden = false;
    return next.handle(req).pipe(
      map(event => {
        if (event.type === 0) {
          if (!req.url.endsWith(this.status.hideOverlayForEnd) && !this.auditService.isAuditUrl(req.url)) {
            this.status.filteredReqCount ++;
            this.status.filteredCountSubject.next(this.status.filteredReqCount);
          } else {
            hidden = true;
          }
        }
        return event;
      }),

      catchError((error: any) => {
        if (this.auditService.isAuditUrl(error.url) || this.status.passErrorForEndpoint && error.url.includes(this.status.passErrorForEndpoint)) {
          return throwError(error);
        }
        this.ketosErrorService.showConfirmPopupForError(error)
          .catch(message => {
            console.log('Auth error, log out')
            return throwError(message);
          });

        return of(error);
      }),
      finalize(() => {
        if (!hidden) {
          this.status.filteredReqCount --;
          this.status.filteredCountSubject.next(this.status.filteredReqCount);
        }
      })
    );
  }
}

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Apply the headers
    console.log('HERE!');
    req = req.clone({
      setHeaders: {
        'ApiToken': AppStorage.getItem('token')
      }
    });

    // Also handle errors globally
    return next.handle(req).pipe(
      tap(x => x, err => {
        // Handle this err
        console.error(`Error performing request, status code = ${err.status}`);
      })
    );
  }
}
