/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Doc_report_pdf } from '../models/doc-_report-_pdf';
import { Doc_report } from '../models/doc-_report';

/**
 * Demonstration Of Capability report related operations
 */
@Injectable({
  providedIn: 'root',
})
class ShieldDocReportService extends __BaseService {
  static readonly postDocReportPdfPath = '/shield/doc_report/pdf/{sensor_id}';
  static readonly getGetResultsPath = '/shield/doc_report/results/{sensor_id}';
  static readonly getDocReportPath = '/shield/doc_report/{sensor_id}';
  static readonly postDocReportPath = '/shield/doc_report/{sensor_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ShieldDocReportService.PostDocReportPdfParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  postDocReportPdfResponse(params: ShieldDocReportService.PostDocReportPdfParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shield/doc_report/pdf/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldDocReportService.PostDocReportPdfParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  postDocReportPdf(params: ShieldDocReportService.PostDocReportPdfParams): __Observable<null> {
    return this.postDocReportPdfResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param sensor_id undefined
   */
  getGetResultsResponse(sensorId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield/doc_report/results/${encodeURIComponent(String(sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_id undefined
   */
  getGetResults(sensorId: number): __Observable<null> {
    return this.getGetResultsResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param sensor_id undefined
   */
  getDocReportResponse(sensorId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield/doc_report/${encodeURIComponent(String(sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_id undefined
   */
  getDocReport(sensorId: number): __Observable<null> {
    return this.getDocReportResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Save user entered data
   * @param params The `ShieldDocReportService.PostDocReportParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  postDocReportResponse(params: ShieldDocReportService.PostDocReportParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shield/doc_report/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Save user entered data
   * @param params The `ShieldDocReportService.PostDocReportParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  postDocReport(params: ShieldDocReportService.PostDocReportParams): __Observable<null> {
    return this.postDocReportResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ShieldDocReportService {

  /**
   * Parameters for postDocReportPdf
   */
  export interface PostDocReportPdfParams {
    payload: Doc_report_pdf;
    sensorId: number;
  }

  /**
   * Parameters for postDocReport
   */
  export interface PostDocReportParams {
    payload: Doc_report;
    sensorId: number;
  }
}

export { ShieldDocReportService }
