/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Component, OnInit } from '@angular/core';
import {NavParams, PopoverController} from '@ionic/angular';
import {WaterSourceExtended} from '../../../../models/extensions/water-source-extended';

@Component({
  selector: 'lib-locations-popover',
  templateUrl: './locations-popover.component.html',
  styleUrls: ['./locations-popover.component.scss']
})
export class LocationsPopoverComponent implements OnInit {

  locations: WaterSourceExtended[] = [];
  selectedLocation: WaterSourceExtended;

  constructor(private navParams: NavParams, public popoverController: PopoverController) { }

  ngOnInit() {
    this.locations = this.navParams.data.locations;
    this.selectedLocation = this.navParams.data.selectedLocation;
  }

  locationClicked(location) {
    this.popoverController.dismiss(location, null, 'locations-popover');
  }
}
