/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../pipes/pipes.module';
import { RunCustomFunctionPipe } from '../pipes/run-custom-function.pipe';
import { MultiSelectPopoverComponent } from './multi-select-popover/multi-select-popover.component';
import { SelectPopoverComponent } from './select-popover/select-popover.component';


@NgModule({
    imports: [
        IonicModule.forRoot(),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule
      ],
  declarations: [
    MultiSelectPopoverComponent,
    SelectPopoverComponent,
],
  exports:[MultiSelectPopoverComponent,
    SelectPopoverComponent,
    ]
})
export class KetosCompoenentsModule {}
