/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from '../../../environments/environment';

if (environment.production) {
  window.console.log = function () {};
  window.console.debug = function () {};
  enableProdMode();
}
Sentry.init({
  dsn: "https://116d015e9df045bda82fd417ba9e3792@o1020411.ingest.sentry.io/5996533" ,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "https://ng4-stageui.ketoswebportal.com/"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    // Can produce console.log with origins while running sentry
    // new Sentry.Integrations.Breadcrumbs({
    //   console: false
    // }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: environment.key,
  enabled: environment.production
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
