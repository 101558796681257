import { Pipe, PipeTransform } from '@angular/core';
export interface StatusInterface {
  id: number;
  statusText: string;
}
export const shieldManualTestStatusLevels: StatusInterface[] = [
  {id: 1, statusText: 'Starting'},
  {id: 2, statusText: 'In Progress'},
  {id: 3, statusText: 'Complete'},
  {id: 4, statusText: 'Incomplete'},
  {id: 5, statusText: 'Did Not Start'},
  {id: 6, statusText: 'Error'},
  {id: 7, statusText: 'Sensor Error'},
  {id: 8, statusText: 'Cancelled'},
];
export const shieldScheduledTestStatusLevels: StatusInterface[] = [
  {id: 0, statusText: 'Scheduled to start'},
  {id: 1, statusText: 'Trying to start'},
  {id: 2, statusText: 'Started'},
  {id: 3, statusText: 'Error'},
  {id: 4, statusText: 'Cancelled'},
];
/** scheduled_test
 * 0=Scheduled, 1=Trying to start, 2=Started, 3=Error, 4=Cancelled
 */
/** shield_test
 * 1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error
 */
@Pipe({
  name: 'testStatusNumberToName'
})
export class TestStatusNumberToNamePipe implements PipeTransform {

  transform(status: number, schedueldTest?: boolean): string {
    if (typeof status !== 'number') {
      return status;
    }
    if (schedueldTest) {
      return shieldScheduledTestStatusLevels.find(st => st.id === status)?.statusText;
    }
    return shieldManualTestStatusLevels.find(st => st.id === status)?.statusText;
  }

}
