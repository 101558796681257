/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */
import { Routes } from '@angular/router';
import {MainPage} from './main.page';
import {AuthGuard} from '../../guards/auth-guard.service';
import {LicenseGuard} from '../../../../../web/src/app/guards/license-guard.service';
import {BlankComponent} from '../blank/blank/blank.component';
import {PlatformAuthGuard} from './platformAuthGuard';
export const MainRoutes: Routes = [
    {
      path: 'insights',
      loadChildren: () => import('../insights/insights.module').then(m => m.InsightsPageModule),
      pathMatch: 'full'
    },
    {
      path: '',
      component: MainPage,
      children: [
        {
          path: '',
          component: MainPage,
          canActivate: [PlatformAuthGuard],
          pathMatch: 'full'
        },
        {
          path: 'home',
          children: [{
            path: '',
            loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardPageModule),
            canActivate: [AuthGuard]
          }],
        },
        {
          path: 'dashboard',
          canActivate: [AuthGuard],
          children: [
            {
              path: '',
              redirectTo: 'insights',
              pathMatch: 'full'
            },
            {
              path: 'insights',
              canActivate: [AuthGuard],
              loadChildren: () => import('../insights/insights.module').then(m => m.InsightsPageModule),
              pathMatch: 'full'
            },
            {
              path: 'real-time-data/:selectedTab',
              canActivate: [AuthGuard],
              loadChildren: () => import('../real-time-data/real-time-data.module').then(m => m.RealTimeDataPageModule),
              pathMatch: 'full',
              data: {
                showSubmenu: false
              }
            },
            {
              path: 'summary',
              loadChildren: () => import('../sensor-summary/sensor-summary.module').then(m => m.SensorSummaryModule),
              canActivate: [AuthGuard],
              pathMatch: 'full'
            },
            {
              path: 'start-schedule-test',
              loadChildren: () => import('../start-schedule-test/start-schedule-test.module').then(m => m.StartScheduleTestPageModule),
              canActivate: [AuthGuard],
            },
            {
              path: 'real-time-data',
              loadChildren: () => import('../real-time-data/real-time-data.module').then(m => m.RealTimeDataPageModule),
              canActivate: [AuthGuard],
            },
            {
              path: 'alerts',
              loadChildren: () => import('../alerts/alerts.module').then(m => m.AlertsPageModule),
              canActivate: [AuthGuard],
            },
            {
              path: 'graphical-view',
              loadChildren: () => import('../ketos-graph/ketos-shield-graph/ketos-shield-graph.module').then(m => m.KetosShieldGraphPageModule),
              canActivate: [AuthGuard],
            },
            {
              path: 'data-correlation',
              loadChildren: () => import('../data-correlation/data-correlation.module').then(m => m.DataCorrelationPageModule),
              canActivate: [AuthGuard],
            },
            {
              path: 'savings',
              loadChildren: () => import('../savings/savings.module').then(m => m.SavingsPageModule),
              canActivate: [AuthGuard],
            },
            {
              path: 'reporting',
              loadChildren: () => import('../reports/reports.module').then(m => m.ReportsPageModule),
              canActivate: [AuthGuard],
            },
            {
              path: 'water-safety',
              loadChildren: () => import('../water-safety/water-safety.module').then(m => m.WaterSafetyPageModule),
              canActivate: [AuthGuard],
            },
            {
              path: 'lab-data',
              loadChildren: () => import('../external-lab-data/external-lab-data.module').then(m => m.ExternalLabDataPageModule),
              canActivate: [AuthGuard],
            },
          ],
          data: {
            showSubmenu: true
          }
        },
        {
          path: 'blank',
          canActivate: [AuthGuard],
          component: BlankComponent,
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'alerts',
          loadChildren: () => import('../alerts/alerts.module').then(m => m.AlertsPageModule),
          canActivate: [AuthGuard],
        },
        {
          path: 'users',
          loadChildren: () => import('../users/users.module').then(m => m.UsersPageModule),
          canActivate: [AuthGuard],
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'unit-settings',
          loadChildren: () => import('../unit-settings/unit-settings.module').then(m => m.UnitSettingsPageModule),
          canActivate: [AuthGuard],
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'sensors',
          loadChildren: () => import('../sensors/sensors.module').then(m => m.SensorsPageModule),
          canActivate: [AuthGuard],
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'locations',
          loadChildren: () => import('../locations/locations.module').then(m => m.LocationsPageModule),
          canActivate: [AuthGuard],
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'predictive-modeller',
          canActivate: [AuthGuard, LicenseGuard],
          loadChildren: () => import('../modeller/modeller.module').then(m => m.ModellerModule),
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'operational-intelligence',
          canActivate: [AuthGuard],
          loadChildren: () => import('../operational-intelligence/operational-intelligence.module').then(m => m.OperationalIntelligenceModule),
          data: {
            showSubmenu: true
          }
        },
        {
          path: 'about',
          loadChildren: () => import('../about/about.module').then(m => m.AboutPageModule),
          canActivate: [AuthGuard],
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'profile',
          loadChildren: () => import('../profile/profile.module').then(m => m.ProfilePageModule),
          canActivate: [AuthGuard],
          data: {
            showSubmenu: false
          }
        },
        {
          path: 'support',
          loadChildren: () => import('../support/support.module').then(m => m.SupportPageModule),
          canActivate: [AuthGuard],
          data: {
            showSubmenu: false
          }
        }
      ]
    }
  ];

