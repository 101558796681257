/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output} from '@angular/core';
import {AppStateService} from '../../../services/app-state.service';
import {SensorTypes} from '../../../models/sensor-types.enum';
import {Alert, Pagination} from '../../../../../../api/src/lib/models';
import {AlertsService} from '../../../../../../api/src/lib/services';
import {asyncScheduler, merge, Subscription} from 'rxjs';
import {KetosSocketEventEnum, KetosSocketService} from '../../../services/ketos-socket.service';
import {throttleTime} from 'rxjs/operators';
import {WaterSourcesCachedService} from '../../../services/water-sources-cached.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnDestroy {
  @Input()
  smallHeader: boolean = false;
  @Output() onOpenDashboardAlerts: EventEmitter<any> = new EventEmitter<any>();

  public open = false;
  public totalNotifications: any;
  public seeMoreFlag = true;
  public sensorType: SensorTypes = SensorTypes.shield;

  @HostListener('document:click', ['$event']) clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
    } else {
      this.closeNotoficationInfo();
    }
  }
  model: Alert[] = [];
  pagination: Pagination;
  currentPage: number;
  pageSize = 3;
  countsText: string;

  sub: Subscription;
  sensorsSub: Subscription;
  socketSub: Subscription;
  waterSources = []

  constructor(
    private alertsService: AlertsService,
    public appStateService: AppStateService,
    private waterSourcesCachedService: WaterSourcesCachedService,
    private ketosSocketService: KetosSocketService,
    private eRef: ElementRef,
  ) {}

  ngOnInit() {
    this.sensorType = this.appStateService?.getAppState()?.getValue()?.sensorType;
    this.init();
  }

  init() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.waterSourcesCachedService.getCached(true,).subscribe( res => {
      this.fetchData(1);
    })

  }

  fetchNextPage() {
    if (this.pagination && this.pagination.has_next) {
      this.fetchData(this.pagination.next_num);
    }
  }

  fetchPreviousPage() {
    if (this.pagination && this.pagination.has_prev) {
      this.fetchData(this.pagination.prev_num);
    }
  }

  fetchData(page?: number, pageSize?: number) {
    if (typeof page !== 'number') {
      page = this.currentPage;
    } else {
      this.currentPage = page;
    }
    // this.alertsService.GetPaginatedAlertsByStatusAndSeverity({
    //   sensorType: 2,
    //   page: page,
    //   perPage: pageSize || 20,
    //   status: 'live',
    //   warningLevel: 'all'
    // })
    this.alertsService.getAlertsPaginated({
      page: page,
      perPage: pageSize || 20,
      resolved: false,
      warningLevels: [1,2,3,4]
    })
      .subscribe((res: any) => {
         console.log('got alerts', res);
        this.totalNotifications = res.pagination.total.toString();
        this.pagination = res.pagination;
        res?.items?.map((alert: any) => {
        const tSplitAlertString = alert.alert_string.split(' | ');
          if(tSplitAlertString.length > 1){
            console.log(tSplitAlertString[0].split(':')[0],":")
            //tSplitAlertString[0]=tSplitAlertString[0].charAt( tSplitAlertString[0].length-1) === ':'? tSplitAlertString[0].substring(0,  tSplitAlertString[0].length-1): tSplitAlertString[0];
            alert.alert_string = tSplitAlertString[0].split(':').length > 1 ? tSplitAlertString[0].split(':')[0]:tSplitAlertString[0];

          }
        })
        this.model = res.items;
        let lastItemIdx = this.pagination.page * this.pagination.per_page;
        if (lastItemIdx > this.pagination.total) {
          lastItemIdx = this.pagination.total;
        }
        this.countsText = '';
        this.countsText += (this.pagination.page - 1) * this.pagination.per_page + 1;
        this.countsText += ' - ' + lastItemIdx;
        this.countsText += ' of ' + this.pagination.total;
        this.watchSockets();
      });
  }

  watchSockets() {
    if (this.sensorsSub) {
      return;
    }

    this.sensorsSub = this.waterSourcesCachedService.getCached(true, false)
      .subscribe(shieldSensors => {
        shieldSensors.map((src) => {

          if(src.id && src.location_name)
          this.waterSources[src.id] = src.location_name;
        })
        console.log(this.waterSources,"----snes")
        const oldSub = this.socketSub;
        // @TODO: Johannes waterSources refactor socket
        this.socketSub = merge(
          ...shieldSensors.map(src =>
            this.ketosSocketService.getSubjectForRoom(src.id, KetosSocketEventEnum.shield_alerts)
          )
        )
          .pipe(
            throttleTime(5000, asyncScheduler, {trailing: true})
          )
          .subscribe(res => {
            console.log('UPDATE NOTIFICATIONS', res);
            if (!this.open && this.model.length === 3 && this.pagination.total > 3) {
              this.fetchData(1, 3);
            } else {
              this.fetchData();
            }
          });
        oldSub?.unsubscribe();
      });
  }

  closeNotoficationInfo() {
    this.open = false;
    // this.model = [];
  }

  openDashboardAlerts() {
    this.onOpenDashboardAlerts.emit(this.sensorType);
    this.closeNotoficationInfo();
  }

  updateNotifications(event, sensorType) {
    if (event !== null) {
      event.stopPropagation();
    }

    this.sensorType = sensorType;
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.sensorsSub) {
      this.sensorsSub.unsubscribe();
    }
    if (this.socketSub) {
      this.socketSub.unsubscribe();
    }
  }
}
