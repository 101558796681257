import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {MultiSelectPopoverComponent, OptionInterface} from '../multi-select-popover.component';

@Component({
  selector: 'lib-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit {
  @Input() titleKey = 'title';
  @Input() uniqueKey = 'id';
  @Input() values: OptionInterface[] = [];
  @Input() selected: OptionInterface[] = [];
  @Input() maxSelected = Number.MAX_SAFE_INTEGER; // for efficiency use one number smaller
  @Input() showConfirm = false;
  @Input() showSelectAll = false;
  @Output() updateSelection = new EventEmitter<OptionInterface[]>();
  @ViewChild('popovercontainer') div;

  constructor(private popoverController: PopoverController) {}

  ngOnInit() {}

  async openPopover(event: Event) {
    const {y} = this.div.nativeElement.getBoundingClientRect();
    const popover = await this.popoverController.create({
      component: MultiSelectPopoverComponent,
      componentProps: {
        titleKey: this.titleKey,
        uniqueKey: this.uniqueKey,
        values: this.values,
        selected: this.selected,
        maxSelected: this.maxSelected,
        showSelectAll: this.showSelectAll,
        updateSelected: (selection: OptionInterface[]) => {
          this.updateSelection.emit(selection);
        }
      },
      cssClass: window.devicePixelRatio >= 3 || (window.innerHeight - y) < 500 || window.innerHeight < 600 ? 'dark-popover unset-top-popover' : 'dark-popover',
      translucent: true,
      event
    });

    popover.onDidDismiss().then(modalData => {
      this.updateSelection.emit(modalData.data as OptionInterface[]);
    });

    return await popover.present();
  }

}
