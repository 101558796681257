import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nutrient-soil-health',
  templateUrl: './nutrient-soil-health.component.html',
  styleUrls: ['./nutrient-soil-health.component.scss']
})
export class NutrientSoilHealthComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
