/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {KetosLibModule} from '../../../../../ketos-lib/src/lib/ketos-lib.module';
import { IonicModule } from '@ionic/angular';
import {SensorSummaryDetailsPage} from "./sensor-details.page";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    KetosLibModule,
  ],
  declarations: [SensorSummaryDetailsPage],
  exports:[SensorSummaryDetailsPage]
})
export class SensorDetailsModule {}
