/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import * as cloneDeep from 'lodash/cloneDeep';
import {Group_master} from '../../../../../api/src/lib/models/group-_master';
import {Sensor_group_objects} from '../../../../../api/src/lib/models/sensor-_group-_objects';
import {WaterSourceExtended} from '../../models/extensions/water-source-extended';

@Component({
  selector: 'lib-grouped-dropdown',
  templateUrl: './grouped-dropdown.component.html',
  styleUrls: ['./grouped-dropdown.component.scss']
})
export class GroupedDropdownComponent implements OnInit {

  @HostListener('document:click', ['$event']) clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
    } else {
      this.open = false;
    }
  }

  _groups: Group_master[];
  @Input() set groups(value: Group_master[]) {
    this._groups = value;
    this.init();
  }
  get groups(): Group_master[] {
    return this._groups;
  }

  _groupMapping: Sensor_group_objects[] = [];
  @Input() set groupMapping(value: Sensor_group_objects[]) {
    this._groupMapping = value;
    this.init();
  }
  get groupMapping(): Sensor_group_objects[] {
    return this._groupMapping;
  }

  _waterSources: WaterSourceExtended[];
  @Input() set waterSources(value: WaterSourceExtended[]) {
    this._waterSources = value;
    this.init();
  }
  get waterSources(): WaterSourceExtended[] {
    return this._waterSources;
  }

  _selectedGroup: Group_master;
  @Input() set selectedGroup(value: Group_master) {
    this._selectedGroup = value;
    this.init();
  }
  get selectedGroup(): Group_master {
    return this._selectedGroup;
  }

  _selectedWaterSource: WaterSourceExtended;
  @Input() set selectedWaterSource(value: WaterSourceExtended) {
    this._selectedWaterSource = value;
    this.init();
  }
  get selectedWaterSource(): WaterSourceExtended {
    return this._selectedWaterSource;
  }

  @Output() sensorSelected: EventEmitter<{group: Group_master, sensor: WaterSourceExtended}> =
    new EventEmitter<{group: Group_master, sensor: WaterSourceExtended}>();

  visibleGroups: Group_master[] = [];
  visibleSensors: WaterSourceExtended[] = [];
  sensorIdsToWaterSourcesDict: {[key: string]: WaterSourceExtended[]};
  waterSourceByIdDict: {[key: number]: WaterSourceExtended};

  open = false;
  searchActive = false;
  searchText = '';

  constructor(private eRef: ElementRef) {
  }

  ngOnInit() {
    // setTimeout(()=> {
    //   console.log('eell', this.groups, this.groupMapping, this.sensors);
    // }, 500)
  }

  init() {
    if (this._groups !== undefined && this._waterSources && this._groupMapping) {

      this.visibleGroups = this._groups;

      this.sensorIdsToWaterSourcesDict = {};
      this.waterSourceByIdDict = {};
      this._waterSources.map(src => {
        if (!this.sensorIdsToWaterSourcesDict[src.sensor_id]) {
          this.sensorIdsToWaterSourcesDict[src.sensor_id] = [];
        }
        this.sensorIdsToWaterSourcesDict[src.sensor_id].push(src);
        this.waterSourceByIdDict[src.id] = src;
      });

      if (this._selectedGroup) {
        this.selectGroup(this._selectedGroup);
      } else {
        if(this.visibleGroups && this.visibleGroups.length){
          for (const group of this.visibleGroups) {
            if (group.is_default_group === 'True') {
              this.selectGroup(group);
            }
          }
        } else {
          this.setAllSensorsVisible();
        }
      }

      if (!this.selectedWaterSource && this.visibleSensors.length > 0) {
        for (const sensor of this.visibleSensors) {
          // if (sensor.is_)
        }
        this._selectedWaterSource = this.visibleSensors[0];
      }
    }
  }

  searchChanged() {
    // console.log('searchChanged', this.searchText);
    const searchTerm = this.searchText.toLowerCase();
    this.visibleGroups = this._groups.filter(group => group.group_name.toLowerCase().includes(searchTerm));
    this.setVisibleLocationsBasedOnGroup();
    this.visibleSensors = this.visibleSensors.filter(sensor => sensor.location_name.toLowerCase().includes(searchTerm));
  }

  selectGroup(group: Group_master) {
    this._selectedGroup = group;
    this.setVisibleLocationsBasedOnGroup();
    this.searchText = '';
    this.searchActive = false;
  }

  setVisibleLocationsBasedOnGroup() {
    const mappings: Sensor_group_objects[] = []
    for (const mapping of this._groupMapping) {
      if (mapping.group_id === this._selectedGroup.id) {
        mappings.push(mapping);
      }
    }
    this.visibleSensors = [];
    for (const mapping of mappings) {
      const sourcesArray = this.sensorIdsToWaterSourcesDict[mapping.sensor_id]
      if (sourcesArray) {
        this.visibleSensors.push(...sourcesArray);
        this.visibleSensors.sort((a, b) => a.water_source_name.localeCompare(b.water_source_name))
      }
    }

    if (!this._selectedWaterSource) {
      for (const mapping of mappings) {
        if (mapping.is_default_location === 1) {
          this._selectedWaterSource = this.sensorIdsToWaterSourcesDict[mapping.sensor_id][0];
          break;
        }
      }
    }
  }

  setAllSensorsVisible() {
    this.visibleSensors = this.waterSources.slice();
    if (this.selectedWaterSource && this.waterSourceByIdDict[this.selectedWaterSource.id]) {
      this._selectedWaterSource = this.waterSourceByIdDict[this.selectedWaterSource.id];
    }
  }

  openOptions() {
    if(this.searchActive) {
      this.open = true;
    }
    else {
      this.open = !this.open;
    }
  }

  selectSensor(sensor: WaterSourceExtended) {
    this._selectedWaterSource = sensor;
    this.sensorSelected.emit({group: this._selectedGroup, sensor: this._selectedWaterSource});
    this.open = false;
  }
}
