import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Chart, ChartConfiguration, ChartOptions} from 'chart.js';
import {DropdownOptionsInterface} from "../../../../components/shared/dropdown/models/dropdown-options.interface";

@Component({
  selector: 'app-nutrient-season',
  templateUrl: './nutrient-season.component.html',
  styleUrls: ['./nutrient-season.component.scss']
})
export class NutrientSeasonComponent implements AfterViewInit {
  @ViewChild('canvas', {static: true}) canvas;
  public selected = "season"
  chart: Chart;
  nutrinenOptions: DropdownOptionsInterface = {
    values: [
      { title: 'Temperature', value: 'Temperature'},
      { title: 'pH', value: 'pH'}
    ]
  }
  cropOptions: DropdownOptionsInterface = {
    values: [
      { title: 'Almond', value: 'Almond'},
      { title: 'Grape', value: 'Grape'}
    ]
  }
  timeframeOptions: DropdownOptionsInterface = {
    values: [
      { title: 'All year', value: 'All year'},
      { title: 'Spring - Fall', value: 'Spring - Fall'},
      { title: 'Summer', value: 'Summer'},
    ]
  }
  constructor() { }

  ngAfterViewInit(): void {
    this.chart = new Chart(this.canvas.nativeElement, {
      type: 'line',
      data: {
        labels: [new Date("2015-3-15 13:3").toLocaleString(), new Date("2015-3-25 13:2").toLocaleString(), new Date("2015-4-25 14:12").toLocaleString()],
        datasets: [{
          label: 'Nutrient concentration',
          data: [{
            t: new Date("2015-3-15 13:3"),
            y: 6
          },
            {
              t: new Date("2015-3-25 13:2"),
              y: 13
            },
            {
              t: new Date("2015-4-10 13:2"),
              y: 21
            },
            {
              t: new Date("2015-4-25 14:12"),
              y: 4
            }
          ],
          // backgroundColor: [
          //   'rgba(255, 99, 132, 0.2)',
          //   'rgba(54, 162, 235, 0.2)',
          //   'rgba(255, 206, 86, 0.1)',
          //   'rgba(75, 192, 192, 0.2)',
          //   'rgba(153, 102, 255, 0.2)',
          //   'rgba(255, 159, 64, 0.2)'
          // ],
          borderColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 2
        }]
      },
      options: {
        scales: {
          xAxes: [{
            type: 'time'
          }]
        },
        annotation: {
          // drawTime: "afterDraw",
          drawTime: "beforeDatasetsDraw",
          events: [],
          annotations: [{
            id: 'hi-box',
            type: 'box',
            xScaleID: 'x-axis-0',
            yScaleID: 'y-axis-0',
            // xMin: new Date("2015-3-25 13:2"),
            // xMax: new Date("2015-4-25 14:12"),
            yMin: 14,
            yMax: 23,
            backgroundColor: 'rgba(0, 255, 0, 0.2)',
            //borderColor: 'rgb(255, 0, 0)',
            borderWidth: 1
          },
          //   {
          //   id: 'med-box',
          //   type: 'box',
          //   xScaleID: 'x-axis-0',
          //   yScaleID: 'y-axis-0',
          //   // xMin: new Date("2015-3-25 13:2"),
          //   // xMax: new Date("2015-4-25 14:12"),
          //   yMin: 7,
          //   yMax: 14,
          //   backgroundColor: 'rgba(255, 255, 138, 0.7)',
          //   //borderColor: 'rgb(255, 0, 0)',
          //   borderWidth: 1
          // }
          ]
        }
      }
    } as ChartConfiguration);
  }
  ngOnDestroy(): void {
    this.selected = null
  }
}
