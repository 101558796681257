import {Component, Injector, Input, OnInit} from '@angular/core';
import {CustomReportService} from '../../../../../api/src/lib/services/custom-report.service';
import {OptionInterface} from '../multi-select-popover/multi-select-popover.component';
import {UiPlatformService} from '../../services/ui-platform.service';
import * as moment from 'moment';
import {AppStorage} from '../../utils/AppStorage';
import {Encoding} from '@capacitor/filesystem';


@Component({
  selector: 'lib-custom-chord',
  templateUrl: './custom-chord.component.html',
  styleUrls: ['./custom-chord.component.scss'],
})

export class CustomChordComponent implements OnInit {
  @Input() mobileUI = false;
  onlyThresholdAbove = false;
  periodDropdownValues: OptionInterface[] = [];
  selectedPeriodValues: OptionInterface[] = [];
  public userData: any;
  constructor( private customReportService: CustomReportService,
               private  uiPlatformService: UiPlatformService) {
    this.userData = JSON.parse(AppStorage.getItem('user'));
  }

  ngOnInit() {
      this.populateMonthlyPeriod();
  }

  populateMonthlyPeriod(){
    const d = new Date();
    const month = d.getMonth();
    for (let i = 0; i < 12 + month; i++) {
      const makeDate = new Date();
      makeDate.setMonth(makeDate.getMonth() - i);
      this.periodDropdownValues.push({title: moment(makeDate).format('MMM YYYY'), value: moment(makeDate).format('MMM YYYY')});
    }
    this.selectedPeriodValues = [this.periodDropdownValues[0]];
  }
  getMonthStartAndEndDate(selectedMonth) {
    const month = moment(selectedMonth, 'MMMM YYYY');
    const isCurrentMonth = month.isSame(moment(), 'month');
    const startDate = month.startOf('month').format('YYYY-MM-DDTHH:mm:ss');
    const endDate = isCurrentMonth
      ? moment().format('YYYY-MM-DDTHH:mm:ss')
      : month.endOf('month').format('YYYY-MM-DDTHH:mm:ss');
    return { startDate, endDate };
  }
  download(){
    const { startDate, endDate } = this.getMonthStartAndEndDate(this.selectedPeriodValues[0].value);
    console.log(startDate, endDate, this.userData.organization_id);
    const payload: CustomReportService.GetCustomReportParams = {} as unknown as CustomReportService.GetCustomReportParams;
    payload.endTime = endDate;
    payload.startTime = startDate;
    payload.orgId = this.userData.organization_id;

    this.customReportService.getCustomReport(payload).subscribe(res => {
      // if(!res){
      //   this.ketosPopupService.presentOkAlert('','The records are downloaded!')
      // }
      if (this.uiPlatformService.isMobileDevice) {
        this.uiPlatformService.saveAndOpenFileMobile(res, 'UniversalStudios-Custom Report.csv', 'text/csv', Encoding.UTF16);
      } else {
        this.downloadStringAsCsv(res, 'UniversalStudios-Custom Report');
      }
    });
  }

  downloadStringAsCsv(csvString: string, filename: string, isMobile: boolean = false) {
    // console.log(csvString,"csvString")
    const blob = new Blob(['\ufeff' + csvString], {type: 'text/csv;charset=utf-8;'});
    const url = URL.createObjectURL(blob);
    if (isMobile) {
      return url;
    }
    // console.log(isMobile)
    const dwldLink = document.createElement('a');

    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}
