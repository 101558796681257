import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppState, AppStateService, ExperimentOptionInterface, TimeIntervalInterface} from '../../../services/app-state.service';
import {ExperimentConfigurationsService} from '../../../services/experiment-configurations.service';
import {AuthService} from '../../../services/auth.service';
import {UsersService} from '../../../../../../api/src/lib/services/users.service';
import {Subscription} from 'rxjs';
import {WaterSourceExtended} from '../../../models/extensions/water-source-extended';
import { DataCorelationModelService } from '../../../services/data-corelation-modal.service';
import { OrganizationCustomParamsSettingsCachedService } from '../../../services/organization-customParams-settings.cached.service';
import { GraphModelService } from '../../../services/graph-model-service';
import { Experiments } from '../../../../../../api/src/lib/models';
import { CloneDeep } from '../../../utils/utils';
import { OptionInterface } from '../../../components/multi-select-popover/multi-select-popover.component';
import { ChartSettingsInterface, DataCorelationSettingsInterface } from '../../ketos-graph/ketos-graph.page';

@Component({
  selector: 'lib-sensor-comparison',
  templateUrl: './sensor-comparison.component.html',
  styleUrls: ['./sensor-comparison.component.scss'],
})
export class SensorComparisonComponent implements OnInit, OnDestroy {

  graphSettings: ChartSettingsInterface;

  @Input() dateRange: TimeIntervalInterface;
 // @Input() userSettings:any[]


 _userSettings:any;
 @Input() set userSettings(value: any) {
   this._userSettings = value;
     this.init();
 }

 get userSettings(): any {
   return this._userSettings;
 }


  experimentsDropdown: Experiments[];
  selectedExperiments: Experiments[] = [];
  maxExperiments = 3;
  waterSourceOptions: WaterSourceExtended[];
  selectedSensors: WaterSourceExtended[];
  maxSensors = 3;
  settingKey = 'dataCorelationGraphs';
  appStateSub: Subscription;
  customParamSettings: Subscription;
  appState: AppState;
  isExternalLabDataVisible: boolean;
  constructor(public appSateService: AppStateService,
              public graphModelService: GraphModelService,
              private authService: AuthService,
              private experimentConfigurationsService: ExperimentConfigurationsService,
              private usersService: UsersService,
              private dataCorelationModelService: DataCorelationModelService,
              private organizationCustomParamsSettingsCachedService: OrganizationCustomParamsSettingsCachedService
  ) {

    this.appStateSub = this.appSateService.getAppState().subscribe(appState => {
      console.log(appState,'appState-----')
      this.appState = appState
     // this.experimentsDropdown = this.getExperimentsDropdownOptions();
      this.waterSourceOptions = appState.locations;
    });
    this.organizationCustomParamsSettingsCachedService.getCached(false,true)
    this.customParamSettings=this.organizationCustomParamsSettingsCachedService.subject
    .subscribe(data => {
      // console.log(data,'data')
      this.isExternalLabDataVisible = data.isExternalLabDataEnabled;
      //this.experimentsDropdown = this.getExperimentsDropdownOptions();
    });
  }

  ngOnInit() {

    this.init()
  }
  init(){
    this.selectedExperiments=[];
    console.log(Array.isArray(this.userSettings),"arrayCheck",this.userSettings)
    //if (Array.isArray(this.userSettings)) {
     // for (const data of Object.keys(this.userSettings )as any[]) {
       // console.log(data,"fsddd")
       // if (data.key === this.settingKey) {
         // const sensorComparisonExperimentsSensorIds: {sensorIds: number[], experiments: string[]} = JSON.parse(data.value);
         if(this.userSettings){
         const sensorComparisonExperimentsSensorIds:DataCorelationSettingsInterface =this.userSettings;
          this.waterSourceOptions = this.appSateService.getAppState().getValue().locations;
          console.log(this.waterSourceOptions,"sensorOptions",sensorComparisonExperimentsSensorIds,'sensorComparisonExperimentsSensorIds')
          if (sensorComparisonExperimentsSensorIds && sensorComparisonExperimentsSensorIds?.sensorIds?.length > 0) {
            this.selectedSensors=[];
            for (const opt of this.waterSourceOptions) {
              if (sensorComparisonExperimentsSensorIds.sensorIds.indexOf(opt.id) !== -1) {
                this.selectedSensors.push(opt);
              }
            }
            this.selectedSensors = this.selectedSensors.slice();
            console.log(this.selectedSensors,"selectedSensors")
          }
          if (sensorComparisonExperimentsSensorIds && sensorComparisonExperimentsSensorIds?.experiments?.length > 0 ) {
            for (const exp of this.appSateService.dropdownExperiments) {
              if (sensorComparisonExperimentsSensorIds.experiments.indexOf(exp.experiment) !== -1) {
                this.selectedExperiments.push(exp);
              }
            }
            this.selectedExperiments = this.selectedExperiments.slice();
         // }
          this.calculateMaxExperimentMaxSensors();
         // break;
        //}
          }
        }
    //}
  }
  // setSensorSelectionSubject():Subject<WaterSourceExtended[]> {
  //   this.sensorOptions = this.appSateService.getAppState().getValue().locations;
  //   console.log(this.sensorOptions,"sensorOptions")
  //   if (sensorComparisonExperimentsSensorIds && sensorComparisonExperimentsSensorIds?.sensorIds?.length > 0) {
  //     this.selectedSensors=[];
  //     for (const opt of this.sensorOptions) {
  //       if (sensorComparisonExperimentsSensorIds.sensorIds.indexOf(opt.id) !== -1) {
  //         this.selectedSensors.push(opt);
  //       }
  //     }
  //     this.selectedSensors = this.selectedSensors.slice();
  //   }
  // }
  setSelectedExperiments(selected: any[]) {
    if (selected) {
      console.log(selected,'----')
      const filterExp:ExperimentOptionInterface = {} as unknown as ExperimentOptionInterface;


      this.selectedExperiments = selected;
      this.selectedExperiments = CloneDeep(this.selectedExperiments)
      this.calculateMaxExperimentMaxSensors();
      this.saveSelectedExperimentsAndSensors();
    }
  }

  trimSelectedExperiments() {
    const availaleExperiments = this.appSateService.filterExperimentsBasedOnSensorIds(this.selectedSensors.map(ws => ws.id), []);
    const filteredExperiments = this.selectedExperiments.filter(exp => !!availaleExperiments.find(axp => axp.experiment === exp.experiment));
    if (filteredExperiments.length !== this.selectedExperiments.length) {
      this.selectedExperiments = filteredExperiments;
    }
  }

  setSelectedSensors(selected: OptionInterface[]) {
    this.selectedSensors = selected.slice();
    this.calculateMaxExperimentMaxSensors();
    this.trimSelectedExperiments();
    this.saveSelectedExperimentsAndSensors();
  }

  calculateMaxExperimentMaxSensors() {
    if (this.selectedExperiments?.length > 1 && this.selectedExperiments?.length > 1) {
      this.maxSensors = 1;
      this.maxExperiments = 1;
    } else if (this.selectedExperiments?.length > 2) {
      this.maxSensors = 0;
      this.maxExperiments = 3;
    } else if (this.selectedSensors?.length > 2) {
      this.maxSensors = 3;
      this.maxExperiments = 0;
    } else if (this.selectedSensors?.length === 2) {
      this.maxSensors = 1;
      this.maxExperiments = 1;
    } else if (this.selectedExperiments.length === 2) {
      this.maxSensors = 1;
      this.maxExperiments = 1;
    } else {
      this.maxSensors = 3;
      this.maxExperiments = 3;
    }
  }

  saveSelectedExperimentsAndSensors() {
    const sensorComparisonExperimentsSensorIds: DataCorelationSettingsInterface = {
      dateRange: this.dateRange,
      sensorIds: this.selectedSensors?.map(sn => sn.id),
      experiments: this.selectedExperiments.map(ex => ex.experiment)
    }

    this.usersService.UpdateUserSpecificSettings({
      items: [
        {
          key: this.settingKey,
          value: JSON.stringify(sensorComparisonExperimentsSensorIds)
        }
      ]
      }
    ).subscribe(res => {
       console.log('result from saving: ', res);
     //  this.userSettings =[]

    })
  }
  ngOnDestroy() {
    if (this.appStateSub) {
      this.appStateSub.unsubscribe();
    }
    if(this.customParamSettings){
      this.customParamSettings.unsubscribe();
    }
  }

}
