import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {KetosPopupService} from '../../../services/ketos-popup.service';
import {UiPlatformService} from '../../../services/ui-platform.service';
import {Get_user_parser} from '../../../../../../api/src/lib/models/get-_user-_parser';
import {AuthUserResponse, ngRoles, UserService} from '../../../services/user.service';
import {UserRoleInterface, UserRoles, UserRolesByIdDict} from '../../../constants/UserRoles';
import {GroupCachedService} from '../../../services/group-cached.service';
import {CpUsersService} from '../../../../../../api/src/lib/services/cp-users.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { OptionInterface } from '../../../components/multi-select-popover/multi-select-popover.component';

@Component({
  selector: 'lib-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.scss'],
})
export class AddUserModalComponent implements OnInit {
  @Input() existingUser: Get_user_parser;

  formGroup: UntypedFormGroup;
  title: string;
  currentUser: AuthUserResponse;
  userAddableRoles: UserRoleInterface[];
  userRolesByIdDict = UserRolesByIdDict;
  showBottomBar: boolean;
  showPassword = false;
  selectedCountry: OptionInterface;
  showPhoneNumber= false;
  countriesList:OptionInterface[]=[
    {
      title: 'USA',
      value: '+1'
    },
    {
      title: 'CAN',
      value: '+1'
    },
    {
      title: 'BR',
      value: '+55'
    },
    {
      title: 'IND',
      value: '+91'
    }
  ]

  constructor(public modalController: ModalController,
              public uiPlatformService: UiPlatformService,
              private ketosPopupService: KetosPopupService,
              private userService: UserService,
              private cpUsersService: CpUsersService,
              public groupCachedService: GroupCachedService,
              private fb: UntypedFormBuilder
  ) {
    this.currentUser = UserService.getCurrentUser();
  }

  ngOnInit() {
    this.selectedCountry = this.countriesList[0]
    if (this.existingUser) {
      if (this.existingUser.role_id > this.currentUser.role_id) {
        this.showBottomBar = true;
        this.title = 'Edit User Details';
      } else {
        this.title = 'User Details';
      }
    } else {
      this.showBottomBar = true;
      this.title = 'Add User';
    }

    let defaultRoleId = 3;
    if (this.currentUser.role_id === 4) {
      this.userAddableRoles = UserRoles.filter(role => {
        return role.id > this.currentUser.role_id;
      });
      defaultRoleId = 5;
    } else {
      this.userAddableRoles = UserRoles.filter(role => {
        return role.id >= this.currentUser.role_id;
      });
    }

    this.formGroup = this.fb.group({
      first_name: [this.existingUser?.first_name ?? '', [Validators.required, Validators.minLength(1)]],
      last_name: [this.existingUser?.last_name ?? '', Validators.required],
      email_id: [this.existingUser?.email_id ?? '', [Validators.required, Validators.email]],
      phone_number: [this.existingUser?.phone_number ?? '', [Validators.required, Validators.pattern('^[+]*\\d{11,}$')]],
      email_alert: [this.existingUser?.email_alert ?? false, Validators.required],
      sms_alert: [this.existingUser?.sms_alert ?? false, Validators.required],
      group_id: [this.existingUser?.group_id ?? this.currentUser.group_id, Validators.required],
      role_id: [this.existingUser?.role_id ?? defaultRoleId, Validators.required],
      eula: 0,
      water_safety_customer: this.existingUser?.water_safety_customer ?? +this.currentUser.water_safety_customer,
      organization_id: this.currentUser.organization_id,
      status: this.existingUser?.status ?? true,
      
    });
      this.formGroup.setValidators(this.countryCodeValidator())
    if (!this.existingUser) {
      this.formGroup.addControl('password', new UntypedFormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{6,}')]));
      this.formGroup.addControl('confirm_password', new UntypedFormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{6,}')]));
    }

    if(!this.formGroup.get('phone_number').value){
      this.formGroup.get('phone_number').setValue(this.selectedCountry.value)
    }

  }
  countryCodeValidator() : ValidatorFn{
    var reg = new RegExp('^[+]*\\d{11,}$')

    return (group: UntypedFormGroup): ValidationErrors => {
       const control = group.controls['phone_number'];
       if (!this.formGroup.get('phone_number').value.includes("+") ) {
        control.setErrors({notValid: true});
       } 
       if(!reg.test(this.formGroup.get('phone_number').value)){
        control.setErrors({notValid: true});
       }
         else {
          control.setErrors(null);
       }
       return;
 };
}
  selectCountryCode(ev){
    this.selectedCountry = ev
    this.showPhoneNumber = true
    this.formGroup.get('phone_number').setValue(this.selectedCountry.value)
  }
  addUser() {
    if (!this.formGroup.valid || this.formGroup.get('password').value !== this.formGroup.get('confirm_password').value) {
      return;
    }
    const newUser = this.formGroup.getRawValue();
    delete newUser.confirm_password;
    this.cpUsersService.postCpUsersCpUsers(newUser).toPromise()
      .then(res => {
        if (res) {
          this.modalController.dismiss({addUser: true, message: res});
        }
      })
      .catch(error => {
        let title, message;
        if (error.status === 409) {
          if (error.error?.message?.includes('User Already exists')) {
            title = 'Email Conflict';
            message = 'User with this email address already exists. Please sign in.';
          }
          else{
            message = error.error.message;
          }
        }
        if (error.status !== 0) {
          this.ketosPopupService.confirmOkPopup({title: title, message: message}, 'dark-alert');
        }
      })
  }

  saveUser() {
    const toSave: Get_user_parser = this.formGroup.getRawValue();
    return this.cpUsersService.putCpUserCpUsersStringEmailId({emailId: toSave.email_id, payload: toSave}).toPromise()
      .then(res => {
        this.modalController.dismiss({saveUser: true, message: res});
      });
  }

  pauseUser() {
    this.userService.pauseUser(this.formGroup.getRawValue())
      .then( res => {
        this.modalController.dismiss({pauseUser: true, message: res});
      });
  }

  deleteUser() {
    this.userService.deleteUserWithConfirmation(this.formGroup.getRawValue())
      .then( res => {
        this.modalController.dismiss({deleteUser: true, message: res});
      });
  }
}
