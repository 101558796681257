import { Injectable } from '@angular/core';
import {CachedBaseService} from './cached-base.service';
import {Observable, ReplaySubject} from 'rxjs';
import {SensorsService} from '../../../../api/src/lib/services/sensors.service';
import {map} from 'rxjs/operators';
import {Shield_sensors_aggregates} from '../../../../api/src/lib/models';

@Injectable({
  providedIn: 'root'
})
export class ResultAggregatesCachedService extends CachedBaseService {
  subject: ReplaySubject<Shield_sensors_aggregates>;

  constructor(private sensorsService: SensorsService) {
    super();
  }

  public getCached(live = true, forceRefresh = false): Observable<Shield_sensors_aggregates> {
    return this.getCachedBase(live, forceRefresh);
  }

  fetchData(): Observable<Shield_sensors_aggregates> {
    return this.sensorsService.GetAllShieldSensorsAggregates()
      .pipe(
        map((res) => {
            this.subject.next(res);
            return res;
          }
        )
      );
  }
}
