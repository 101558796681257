/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { New_test } from '../models/new-_test';
import { Tests } from '../models/tests';

/**
 * Test Updates
 */
@Injectable({
  providedIn: 'root',
})
class TestService extends __BaseService {
  static readonly CreateNewShieldTestPath = '/test/new_shield_test/';
  static readonly UpdateAScheduledTestPath = '/test/update_scheduled_test/';
  static readonly UpdateShieldDevicePath = '/test/update_shield_device/';
  static readonly GetPaginatedTestsGivenOptionalSensorIdsPath = '/test/{sensor_ids}/{page}/{per_page}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Used by Lambda
   * @param payload undefined
   */
  CreateNewShieldTestResponse(payload: New_test): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/test/new_shield_test/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by Lambda
   * @param payload undefined
   */
  CreateNewShieldTest(payload: New_test): __Observable<null> {
    return this.CreateNewShieldTestResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by Lambda
   */
  UpdateAScheduledTestResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/test/update_scheduled_test/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by Lambda
   */
  UpdateAScheduledTest(): __Observable<null> {
    return this.UpdateAScheduledTestResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by Lambda
   */
  UpdateShieldDeviceResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/test/update_shield_device/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by Lambda
   */
  UpdateShieldDevice(): __Observable<null> {
    return this.UpdateShieldDeviceResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TestService.GetPaginatedTestsGivenOptionalSensorIdsParams` containing the following parameters:
   *
   * - `sensor_ids`: Comma separated list of sensor IDs or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetPaginatedTestsGivenOptionalSensorIdsResponse(params: TestService.GetPaginatedTestsGivenOptionalSensorIdsParams): __Observable<__StrictHttpResponse<Tests>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test/${encodeURIComponent(String(params.sensorIds))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Tests>;
      })
    );
  }
  /**
   * @param params The `TestService.GetPaginatedTestsGivenOptionalSensorIdsParams` containing the following parameters:
   *
   * - `sensor_ids`: Comma separated list of sensor IDs or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetPaginatedTestsGivenOptionalSensorIds(params: TestService.GetPaginatedTestsGivenOptionalSensorIdsParams): __Observable<Tests> {
    return this.GetPaginatedTestsGivenOptionalSensorIdsResponse(params).pipe(
      __map(_r => _r.body as Tests)
    );
  }
}

module TestService {

  /**
   * Parameters for GetPaginatedTestsGivenOptionalSensorIds
   */
  export interface GetPaginatedTestsGivenOptionalSensorIdsParams {

    /**
     * Comma separated list of sensor IDs or "all"
     */
    sensorIds: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { TestService }
