import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {Cognito_user_credentials} from '../../../../api/src/lib/models/cognito-_user-_credentials';
import {AppStorage} from '../utils/AppStorage';
import {Get_user_parser} from '../../../../api/src/lib/models/get-_user-_parser';
import {CpUsersService, UsersService} from '../../../../api/src/lib/services';
import {catchError} from 'rxjs/operators';
import {KetosPopupService} from './ketos-popup.service';
import {CloneDeep} from '../utils/utils';

export interface AuthUserResponse {
  customer_sub_type: number;
  customer_type: number;
  eula: number;
  group_id: number;
  group_name: string;
  locations_dictionary: {
    [key: number]: string;
  };
  username: string;
  name: string;
  organization_id: number;
  organization_name: string;
  premium: boolean;
  role_id: number;
  roleName?: string; // @TODO: remove in the future
  userData?: any;
  defaultLocationNameDictionary?: any;
  token: {
    AccessToken: string;
    ExpiresIn: number;
    IdToken: string;
    RefreshToken: string;
    TokenType: string;
  }
  water_safety_customer: number;
}

export enum NGRoleEnum {
  SuperAdmin = 1, // id 1
  OrgAdmin = 2, // id 2
  GroupAdmin = 3, // id 3
  BusinessUser = 4, // id 4
  GuestUser = 5 // id 5
}

export interface UserRoleInterface {
  id: number;
  name: string;
}
export const ngRoles: UserRoleInterface[] = [
    {id: 1, name: 'SuperAdmin'},
    {id: 2, name: 'OrgAdmin'},
    {id: 3, name: 'GroupAdmin'},
    {id: 4, name: 'BusinessUser'},
    {id: 5, name: 'GuestUser'},
]

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public users: Get_user_parser[];

  constructor(private usersService: UsersService,
              private cpUsersService: CpUsersService,
              private ketosPopupsService: KetosPopupService,
  ) { }

  static getCurrentUser(): AuthUserResponse | null {
    const user = AppStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  static getCurrentOrgId(): number {
    let orgId = 0;
    const user = AppStorage.getItem('user');
    if (user) {
      const usr = JSON.parse(user);
      orgId = usr.organization_id;
      if (usr.role_id === 1) {
        const org = AppStorage.getItem('selected-org');
        if (org) {
          orgId = JSON.parse(org).id;
        }
      }
    }
    return orgId;
  }

  checkUserVersion(email: string): Observable<boolean> {
    return this.usersService.FindIfUserBelongsToKSE40ForKSE30(email)
      .pipe(
        catchError( (err: any, caught) => {
          console.log('heeeloooo', err);
          return of(false);
        }),
      )
  }

  authenticateUser(credentials: Cognito_user_credentials): Observable<AuthUserResponse> {
    return this.usersService.postAuthenticateUser(credentials);
  }

  getUsers(): Observable<Get_user_parser[]> {
    return this.usersService.GetUserDetails_1({organizationId: UserService.getCurrentOrgId()});
  }
  getProfile(): Observable<Get_user_parser> {
    return this.usersService.GetUserDetails_2();
  }

  pauseUser(user: Get_user_parser): Promise<string> {
    const currentUser = UserService.getCurrentUser();
    if (currentUser.username === user.email_id) {
      this.ketosPopupsService.confirmOkPopup({title: 'Oops!', message: 'Cannot pause/unpause yourself!', ok: 'ok'});
      return;
    }

    const popupTexts = {
      ok: 'Yes',
      cancel: 'No',
      title: (user.status ? 'Pause User' : 'Unpause User') + ': ' + user.email_id,
      message: 'Are you sure?'
    };

    const userCopy = CloneDeep(user);
    userCopy.status = userCopy.status !== true;
    return this.ketosPopupsService.confirmPopup(popupTexts)
      .then( confirmed => {
        return this.cpUsersService.putCpUserCpUsersStringEmailId({emailId: userCopy.email_id, payload: userCopy}).toPromise();
      });
  }

  deleteUserWithConfirmation(user: Get_user_parser): Promise<string> {
    const popupTexts = {
      ok: 'Yes',
      cancel: 'No',
      title: 'Delete User',
      message: 'Are you sure?'
    };
    return this.ketosPopupsService.confirmPopup(popupTexts)
      .then( confirmed => {
        return this.cpUsersService.deleteCpUserCpUsersStringEmailId(user.email_id).toPromise();
      });
  }
}
