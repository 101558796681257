/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Ph_alk_devices } from '../models/ph-_alk-_devices';
import { Ph_alk_probe_device } from '../models/ph-_alk-_probe-_device';
import { Ph_alk_probe_device_input } from '../models/ph-_alk-_probe-_device-_input';

/**
 * pH Alkalinity probe device related operations
 */
@Injectable({
  providedIn: 'root',
})
class PhAlkProbeDevicesService extends __BaseService {
  static readonly getPhAlkProbeDevicesPath = '/ph_alk_probe_devices';
  static readonly postPhAlkProbeDevicesPath = '/ph_alk_probe_devices';
  static readonly putPhAlkProbeDevicePhAlkProbeDevicesStringPhAlkProbeDeviceIdPath = '/ph_alk_probe_devices/{ph_alk_probe_device_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Devices
   * @param params The `PhAlkProbeDevicesService.GetPhAlkProbeDevicesParams` containing the following parameters:
   *
   * - `ids`: List of pH Alkalinity device ids (Serial Number)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `shield_device_ids`: List of shield device serial numbers
   *
   * - `assigned`: Include devices with (True)/without (False) parent device
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getPhAlkProbeDevicesResponse(params: PhAlkProbeDevicesService.GetPhAlkProbeDevicesParams): __Observable<__StrictHttpResponse<Ph_alk_devices>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.shieldDeviceIds || []).forEach(val => {if (val != null) __params = __params.append('shield_device_ids', val.toString())});
    if (params.assigned != null) __params = __params.set('assigned', params.assigned.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ph_alk_probe_devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Ph_alk_devices>;
      })
    );
  }
  /**
   * Get Devices
   * @param params The `PhAlkProbeDevicesService.GetPhAlkProbeDevicesParams` containing the following parameters:
   *
   * - `ids`: List of pH Alkalinity device ids (Serial Number)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `shield_device_ids`: List of shield device serial numbers
   *
   * - `assigned`: Include devices with (True)/without (False) parent device
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getPhAlkProbeDevices(params: PhAlkProbeDevicesService.GetPhAlkProbeDevicesParams): __Observable<Ph_alk_devices> {
    return this.getPhAlkProbeDevicesResponse(params).pipe(
      __map(_r => _r.body as Ph_alk_devices)
    );
  }

  /**
   * Create Device
   * @param params The `PhAlkProbeDevicesService.PostPhAlkProbeDevicesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postPhAlkProbeDevicesResponse(params: PhAlkProbeDevicesService.PostPhAlkProbeDevicesParams): __Observable<__StrictHttpResponse<Ph_alk_probe_device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ph_alk_probe_devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Ph_alk_probe_device>;
      })
    );
  }
  /**
   * Create Device
   * @param params The `PhAlkProbeDevicesService.PostPhAlkProbeDevicesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postPhAlkProbeDevices(params: PhAlkProbeDevicesService.PostPhAlkProbeDevicesParams): __Observable<Ph_alk_probe_device> {
    return this.postPhAlkProbeDevicesResponse(params).pipe(
      __map(_r => _r.body as Ph_alk_probe_device)
    );
  }

  /**
   * Update Device
   * @param params The `PhAlkProbeDevicesService.PutPhAlkProbeDevicePhAlkProbeDevicesStringPhAlkProbeDeviceIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `ph_alk_probe_device_id`: Device Serial Number
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putPhAlkProbeDevicePhAlkProbeDevicesStringPhAlkProbeDeviceIdResponse(params: PhAlkProbeDevicesService.PutPhAlkProbeDevicePhAlkProbeDevicesStringPhAlkProbeDeviceIdParams): __Observable<__StrictHttpResponse<Ph_alk_probe_device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/ph_alk_probe_devices/${encodeURIComponent(String(params.phAlkProbeDeviceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Ph_alk_probe_device>;
      })
    );
  }
  /**
   * Update Device
   * @param params The `PhAlkProbeDevicesService.PutPhAlkProbeDevicePhAlkProbeDevicesStringPhAlkProbeDeviceIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `ph_alk_probe_device_id`: Device Serial Number
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putPhAlkProbeDevicePhAlkProbeDevicesStringPhAlkProbeDeviceId(params: PhAlkProbeDevicesService.PutPhAlkProbeDevicePhAlkProbeDevicesStringPhAlkProbeDeviceIdParams): __Observable<Ph_alk_probe_device> {
    return this.putPhAlkProbeDevicePhAlkProbeDevicesStringPhAlkProbeDeviceIdResponse(params).pipe(
      __map(_r => _r.body as Ph_alk_probe_device)
    );
  }
}

module PhAlkProbeDevicesService {

  /**
   * Parameters for getPhAlkProbeDevices
   */
  export interface GetPhAlkProbeDevicesParams {

    /**
     * List of pH Alkalinity device ids (Serial Number)
     */
    ids?: Array<string>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of shield device serial numbers
     */
    shieldDeviceIds?: Array<string>;

    /**
     * Include devices with (True)/without (False) parent device
     */
    assigned?: boolean;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postPhAlkProbeDevices
   */
  export interface PostPhAlkProbeDevicesParams {
    payload: Ph_alk_probe_device;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putPhAlkProbeDevicePhAlkProbeDevicesStringPhAlkProbeDeviceId
   */
  export interface PutPhAlkProbeDevicePhAlkProbeDevicesStringPhAlkProbeDeviceIdParams {
    payload: Ph_alk_probe_device_input;

    /**
     * Device Serial Number
     */
    phAlkProbeDeviceId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { PhAlkProbeDevicesService }
