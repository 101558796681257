import {Injectable} from '@angular/core';
import {OrganizationService} from '../../../../api/src/lib/services/organization.service';
import {Observable} from 'rxjs';
import {Organization_settings_show_partial_results_out} from '../../../../api/src/lib/models/organization-_settings-_show-_partial-_results-_out';
import {map} from 'rxjs/operators';
import { AuthUserResponse } from './user.service';
import { AppStorage } from '../utils/AppStorage';
import { OrganizationSettingsInterface } from '../models/organization-settings.interface'
import { Organizations } from '../../../../api/src/lib/models';
import { OrganizationsService } from '../../../../api/src/lib/services';
import { OrganizationsService as OrganizationsInternalService } from '../../../../api-internal/src/lib/services';

@Injectable({
  providedIn: 'root'
})

export class OrganizationCustomParamsSettings{
    constructor(private organizationsService: OrganizationsService,private organizationsInternalService: OrganizationsInternalService){

    }
    getOrganizationSettings(orgId?:number,portal='cp'): Observable<OrganizationSettingsInterface> {
        const userData: AuthUserResponse = JSON.parse(AppStorage.getItem('user'));
        const id = orgId ? orgId : userData.organization_id;
        const settings:OrganizationSettingsInterface = {} as unknown as OrganizationSettingsInterface ;
        let url;
        if(portal === 'cp'){
            url = this.organizationsService.getOrganizationSettings({organizationId:id})
        }else{
            url = this.organizationsInternalService.getOrganizationSettings({organizationId:id})
        }
        return url
            .pipe(
                map((res:any) => {
                    //return { allSettings: res.items, is   }
                    settings.allSettings = res.items;
                    settings.id = id;
                    settings.isExternalLabDataEnabled = res.items.filter(item => item.key === 'cp_external_lab_enabled')[0].value ? true : false
                    settings.isMagnesiumEnabled = res.items.filter(item => item.key === 'cp_magnesium_enabled')[0].value ? true : false
                    settings.isLsiEnabled = res.items.filter(item => item.key === 'cp_lsi_enabled')[0].value ? true : false
                    settings.isPartialResultsEnabled = res.items.filter(item => item.key === 'cp_show_partial_results')[0].value ? true : false
                    settings.isNitratesHREnabled = res.items.filter(item => item.key === 'cp_nitrates_enabled')[0].value ? true : false
                    settings.isManganeseHrEnabled = res.items.filter(item => item.key === 'cp_manganese_hr_enabled')[0]?.value ? true : false
                    settings.isZincHREnabled = res.items.filter(item => item.key === 'cp_zinc_hr_enabled')[0].value ? true : false;
                    return settings;
                })
            )
    }
}
