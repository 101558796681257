/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Shield_tests_paginated } from '../models/shield-_tests-_paginated';
import { Shield_test } from '../models/shield-_test';
import { Shield_test_status } from '../models/shield-_test-_status';
import { Shield_test_length } from '../models/shield-_test-_length';

/**
 * Shield test related operations
 */
@Injectable({
  providedIn: 'root',
})
class ShieldTestsService extends __BaseService {
  static readonly getShieldTestsShieldTestsPath = '/shield/tests';
  static readonly patchShieldTestStatusShieldTestsStringIdStatusPath = '/shield/tests/{id}/status';
  static readonly getShieldTestShieldTestsIntSensorIdStringBitmapLengthPath = '/shield/tests/{sensor_id}/{bitmap}/length';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get paginated list of shield tests
   * @param params The `ShieldTestsService.GetShieldTestsShieldTestsParams` containing the following parameters:
   *
   * - `ids`: List of shield result ids
   *
   * - `statuses`: List of statuses (1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error, 8=cancelled)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `test_numbers`: List of test numbers
   *
   * - `start_start_time`: Start start datetime (ISO 8601)
   *
   * - `end_start_time`: End start datetime (ISO 8601)
   *
   * - `start_expected_end_time`: Start end datetime (ISO 8601)
   *
   * - `end_expected_end_time`: End end datetime (ISO 8601)
   *
   * - `start_end_time`: Start end datetime (ISO 8601)
   *
   * - `end_end_time`: End end datetime (ISO 8601)
   *
   * - `start_updated_on`: Start datetime of last update (ISO 8601)
   *
   * - `end_updated_on`: End datetime of last update (ISO 8601)
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `updated_by`: Updated by
   *
   * - `time_zone`: Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
   *
   * - `titles`: Title from test schedule
   *
   * - `include_shield_test_schedule`: Embed associated shield_test_schedule record if any
   *
   * - `include_shield_test_results`: Get shield test results from OQ
   *
   * - `scroll_id`: PAGINATION: Pass to get next page for result sets over 10000
   *
   * - `per_page`: PAGINATION: Number of results to return (10000 max)
   *
   * - `page`: PAGINATION: Page number for result sets under 10000
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestsShieldTestsResponse(params: ShieldTestsService.GetShieldTestsShieldTestsParams): __Observable<__StrictHttpResponse<Shield_tests_paginated>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.waterSourceIds || []).forEach(val => {if (val != null) __params = __params.append('water_source_ids', val.toString())});
    (params.testNumbers || []).forEach(val => {if (val != null) __params = __params.append('test_numbers', val.toString())});
    if (params.startStartTime != null) __params = __params.set('start_start_time', params.startStartTime.toString());
    if (params.endStartTime != null) __params = __params.set('end_start_time', params.endStartTime.toString());
    if (params.startExpectedEndTime != null) __params = __params.set('start_expected_end_time', params.startExpectedEndTime.toString());
    if (params.endExpectedEndTime != null) __params = __params.set('end_expected_end_time', params.endExpectedEndTime.toString());
    if (params.startEndTime != null) __params = __params.set('start_end_time', params.startEndTime.toString());
    if (params.endEndTime != null) __params = __params.set('end_end_time', params.endEndTime.toString());
    if (params.startUpdatedOn != null) __params = __params.set('start_updated_on', params.startUpdatedOn.toString());
    if (params.endUpdatedOn != null) __params = __params.set('end_updated_on', params.endUpdatedOn.toString());
    (params.operatingStatuses || []).forEach(val => {if (val != null) __params = __params.append('operating_statuses', val.toString())});
    if (params.updatedBy != null) __params = __params.set('updated_by', params.updatedBy.toString());
    if (params.timeZone != null) __params = __params.set('time_zone', params.timeZone.toString());
    (params.titles || []).forEach(val => {if (val != null) __params = __params.append('titles', val.toString())});
    if (params.includeShieldTestSchedule != null) __params = __params.set('include_shield_test_schedule', params.includeShieldTestSchedule.toString());
    if (params.includeShieldTestResults != null) __params = __params.set('include_shield_test_results', params.includeShieldTestResults.toString());
    if (params.scrollId != null) __params = __params.set('scroll_id', params.scrollId.toString());
    if (params.perPage != null) __params = __params.set('per_page', params.perPage.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield/tests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_tests_paginated>;
      })
    );
  }
  /**
   * Get paginated list of shield tests
   * @param params The `ShieldTestsService.GetShieldTestsShieldTestsParams` containing the following parameters:
   *
   * - `ids`: List of shield result ids
   *
   * - `statuses`: List of statuses (1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error, 8=cancelled)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `test_numbers`: List of test numbers
   *
   * - `start_start_time`: Start start datetime (ISO 8601)
   *
   * - `end_start_time`: End start datetime (ISO 8601)
   *
   * - `start_expected_end_time`: Start end datetime (ISO 8601)
   *
   * - `end_expected_end_time`: End end datetime (ISO 8601)
   *
   * - `start_end_time`: Start end datetime (ISO 8601)
   *
   * - `end_end_time`: End end datetime (ISO 8601)
   *
   * - `start_updated_on`: Start datetime of last update (ISO 8601)
   *
   * - `end_updated_on`: End datetime of last update (ISO 8601)
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `updated_by`: Updated by
   *
   * - `time_zone`: Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
   *
   * - `titles`: Title from test schedule
   *
   * - `include_shield_test_schedule`: Embed associated shield_test_schedule record if any
   *
   * - `include_shield_test_results`: Get shield test results from OQ
   *
   * - `scroll_id`: PAGINATION: Pass to get next page for result sets over 10000
   *
   * - `per_page`: PAGINATION: Number of results to return (10000 max)
   *
   * - `page`: PAGINATION: Page number for result sets under 10000
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestsShieldTests(params: ShieldTestsService.GetShieldTestsShieldTestsParams): __Observable<Shield_tests_paginated> {
    return this.getShieldTestsShieldTestsResponse(params).pipe(
      __map(_r => _r.body as Shield_tests_paginated)
    );
  }

  /**
   * Update the test status
   * @param params The `ShieldTestsService.PatchShieldTestStatusShieldTestsStringIdStatusParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  patchShieldTestStatusShieldTestsStringIdStatusResponse(params: ShieldTestsService.PatchShieldTestStatusShieldTestsStringIdStatusParams): __Observable<__StrictHttpResponse<Shield_test>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/shield/tests/${encodeURIComponent(String(params.id))}/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test>;
      })
    );
  }
  /**
   * Update the test status
   * @param params The `ShieldTestsService.PatchShieldTestStatusShieldTestsStringIdStatusParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  patchShieldTestStatusShieldTestsStringIdStatus(params: ShieldTestsService.PatchShieldTestStatusShieldTestsStringIdStatusParams): __Observable<Shield_test> {
    return this.patchShieldTestStatusShieldTestsStringIdStatusResponse(params).pipe(
      __map(_r => _r.body as Shield_test)
    );
  }

  /**
   * Get length of test in seconds
   * @param params The `ShieldTestsService.GetShieldTestShieldTestsIntSensorIdStringBitmapLengthParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `bitmap`: Bitmap of experiments
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestShieldTestsIntSensorIdStringBitmapLengthResponse(params: ShieldTestsService.GetShieldTestShieldTestsIntSensorIdStringBitmapLengthParams): __Observable<__StrictHttpResponse<Shield_test_length>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield/tests/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.bitmap))}/length`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test_length>;
      })
    );
  }
  /**
   * Get length of test in seconds
   * @param params The `ShieldTestsService.GetShieldTestShieldTestsIntSensorIdStringBitmapLengthParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `bitmap`: Bitmap of experiments
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestShieldTestsIntSensorIdStringBitmapLength(params: ShieldTestsService.GetShieldTestShieldTestsIntSensorIdStringBitmapLengthParams): __Observable<Shield_test_length> {
    return this.getShieldTestShieldTestsIntSensorIdStringBitmapLengthResponse(params).pipe(
      __map(_r => _r.body as Shield_test_length)
    );
  }
}

module ShieldTestsService {

  /**
   * Parameters for getShieldTestsShieldTests
   */
  export interface GetShieldTestsShieldTestsParams {

    /**
     * List of shield result ids
     */
    ids?: Array<number>;

    /**
     * List of statuses (1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error, 8=cancelled)
     */
    statuses?: Array<number>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of water source ids
     */
    waterSourceIds?: Array<number>;

    /**
     * List of test numbers
     */
    testNumbers?: Array<number>;

    /**
     * Start start datetime (ISO 8601)
     */
    startStartTime?: string;

    /**
     * End start datetime (ISO 8601)
     */
    endStartTime?: string;

    /**
     * Start end datetime (ISO 8601)
     */
    startExpectedEndTime?: string;

    /**
     * End end datetime (ISO 8601)
     */
    endExpectedEndTime?: string;

    /**
     * Start end datetime (ISO 8601)
     */
    startEndTime?: string;

    /**
     * End end datetime (ISO 8601)
     */
    endEndTime?: string;

    /**
     * Start datetime of last update (ISO 8601)
     */
    startUpdatedOn?: string;

    /**
     * End datetime of last update (ISO 8601)
     */
    endUpdatedOn?: string;

    /**
     * List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
     */
    operatingStatuses?: Array<number>;

    /**
     * Updated by
     */
    updatedBy?: string;

    /**
     * Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
     */
    timeZone?: string;

    /**
     * Title from test schedule
     */
    titles?: Array<string>;

    /**
     * Embed associated shield_test_schedule record if any
     */
    includeShieldTestSchedule?: boolean;

    /**
     * Get shield test results from OQ
     */
    includeShieldTestResults?: boolean;

    /**
     * PAGINATION: Pass to get next page for result sets over 10000
     */
    scrollId?: string;

    /**
     * PAGINATION: Number of results to return (10000 max)
     */
    perPage?: number;

    /**
     * PAGINATION: Page number for result sets under 10000
     */
    page?: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for patchShieldTestStatusShieldTestsStringIdStatus
   */
  export interface PatchShieldTestStatusShieldTestsStringIdStatusParams {
    payload: Shield_test_status;
    id: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldTestShieldTestsIntSensorIdStringBitmapLength
   */
  export interface GetShieldTestShieldTestsIntSensorIdStringBitmapLengthParams {

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * Bitmap of experiments
     */
    bitmap: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ShieldTestsService }
