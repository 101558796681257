/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';


/**
 * Custom report generator endpoint
 */
@Injectable({
  providedIn: 'root',
})
class CustomReportService extends __BaseService {
  static readonly getCustomReportPath = '/custom_report/{org_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CustomReportService.GetCustomReportParams` containing the following parameters:
   *
   * - `org_id`:
   *
   * - `start_time`: start time (inclusive) resolved date (ISO 8601
   *
   * - `end_time`: end time (inclusive) resolved date (ISO 8601
   *
   * - `over_threshold`: Include records which are only above threshold
   */
  getCustomReportResponse(params: CustomReportService.GetCustomReportParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.startTime != null) __params = __params.set('start_time', params.startTime.toString());
    if (params.endTime != null) __params = __params.set('end_time', params.endTime.toString());
    if (params.overThreshold != null) __params = __params.set('over_threshold', params.overThreshold.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/custom_report/${encodeURIComponent(String(params.orgId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CustomReportService.GetCustomReportParams` containing the following parameters:
   *
   * - `org_id`:
   *
   * - `start_time`: start time (inclusive) resolved date (ISO 8601
   *
   * - `end_time`: end time (inclusive) resolved date (ISO 8601
   *
   * - `over_threshold`: Include records which are only above threshold
   */
  getCustomReport(params: CustomReportService.GetCustomReportParams): __Observable<null> {
    return this.getCustomReportResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CustomReportService {

  /**
   * Parameters for getCustomReport
   */
  export interface GetCustomReportParams {
    orgId: number;

    /**
     * start time (inclusive) resolved date (ISO 8601
     */
    startTime?: string;

    /**
     * end time (inclusive) resolved date (ISO 8601
     */
    endTime?: string;

    /**
     * Include records which are only above threshold
     */
    overThreshold?: boolean;
  }
}

export { CustomReportService }
