import {Component, Input, OnInit, Output} from '@angular/core';
import {OptionInterface} from '../multi-select-popover/multi-select-popover.component';
import {AppService} from '../../services/legacy-services/app.service';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'lib-single-select-popover',
  templateUrl: './single-select-popover.component.html',
  styleUrls: ['./single-select-popover.component.scss'],
})
export class SingleSelectPopoverComponent implements OnInit {
  @Input() componentName: string
  @Input() selectedOption: string;
  @Input() options: OptionInterface[];
  @Input() updateSelected: (selected: OptionInterface) => void;
  @Input() filterStatusEnabled: boolean;
  @Input() onOptionClick: (event: Event, option: OptionInterface) => void;
  @Input() showBackButton: boolean;
  @Input() radioMode = true;
  @Input() fromMobileAddGraph = false;

  locationsStatusConstants = AppService.environment().CONSTANTS.WAVE_LOCATIONS_STATUS;

  constructor(private popoverController: PopoverController) { }

  private dismissPopover() {
    this.popoverController.dismiss(this.options.find(option => option.value === this.selectedOption));
    if (this.onOptionClick){
      this.onOptionClick(event, this.options.find(option => option.value === this.selectedOption));
    }
  }
  ngOnInit() {}

  setSelected(event: CustomEvent){
    this.selectedOption = event.detail.value;
    this.dismissPopover();
  }

  optionClick(option) {
    this.selectedOption = option.value;
    this.dismissPopover();
  }

  onBackClick() {
    this.popoverController.dismiss();
  }
}
