import {Component, OnInit} from '@angular/core';
import {Alert} from '../../../../../../api/src/lib/models/alert';
import {ModalController} from '@ionic/angular';
import {AlertsService} from '../../../../../../api/src/lib/services/alerts.service';
import {forkJoin} from 'rxjs';
import { UiPlatformService } from '../../../services/ui-platform.service';
import { KetosPopupService } from '../../../services/ketos-popup.service';

@Component({
  selector: 'lib-solve-view-alert-modal',
  templateUrl: './solve-view-alert-modal.component.html',
  styleUrls: ['./solve-view-alert-modal.component.scss'],
})
export class SolveViewAlertModalComponent implements OnInit {
  alerts: Alert[];
  resolveComment: string;
  liveAlert: boolean;

  tmpArchivedStatus: boolean;

  constructor(private modalController: ModalController,
              private alertsService: AlertsService,
              private ketosPopupService: KetosPopupService,
              public uiPlatformService: UiPlatformService) {
  }

  ngOnInit() {
    console.log('Opened Solve Alert:', this.alerts, this.liveAlert);
    if (!this.liveAlert && this.alerts && this.alerts.length > 0) {
      this.resolveComment = this.alerts[0].comment;
    }
  }

  solveAlerts(alerts: Alert[]) {
    forkJoin(...alerts.map(alert => {return this.alertsService.SolveAlert({alertId: alert['id'], sensorType: 2, payload: {comment: this.resolveComment || ''}})}))
      .subscribe(ress => {
        // console.log('reeeeee alert', ress);
        const alertString = alerts.length === 1 ? 'Alert has been resolved!' : 'Alerts have been resolved!';
        this.ketosPopupService.presentOkAlert(alertString);
        this.cancel(true);
      });
  }

  async cancel(refresh = false) {
    await this.modalController.dismiss(refresh);
  }
}
