import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ShieldDeviceExtended} from '../../../models/extensions/shield-device-extended';
import {asyncScheduler, combineLatest, forkJoin, merge, Observable, of, Subject, Subscription} from 'rxjs';
import {AppState, AppStateService} from '../../../services/app-state.service';
import {AuthService} from '../../../services/auth.service';
import {HTTPStatus} from '../../../services/http-status.service';
import {GroupCachedService} from '../../../services/group-cached.service';
import {SensorsService} from '../../../../../../api/src/lib/services/sensors.service';
import {ResultsService} from '../../../../../../api/src/lib/services/results.service';
import {ShieldDevicesCachedService} from '../../../services/shield-devices-cached.service';
import {SensorGroupCachedService} from '../../../services/sensor-group-cached.service';
import {ResultCountsCachedService} from '../../../services/result-counts-cached.service';
import {ResultAggregatesCachedService} from '../../../services/result-aggregates-cached.service';
import {ContinuousModeService} from '../../../../../../api/src/lib/services/continuous-mode.service';
import {ModalController} from '@ionic/angular';
import {KetosPopupService} from '../../../services/ketos-popup.service';
import {KetosSocketService} from '../../../services/ketos-socket.service';
import {DatePipe} from '@angular/common';
import {Result_count} from '../../../../../../api/src/lib/models/result-_count';
import {Shield_sensors_aggregates} from '../../../../../../api/src/lib/models/shield-_sensors-_aggregates';
import {map, tap, throttleTime} from 'rxjs/operators';
import {Group_master} from '../../../../../../api/src/lib/models/group-_master';
import {Shield_device} from '../../../../../../api/src/lib/models/shield-_device';
import {CalculateHoursToNow} from '../../../utils/TimeHelper';
import * as moment from 'moment';
import {CsvGenerator} from '../../../utils/CsvGenerator';
import {SensorMapComponent} from '../sensor-map/sensor-map.component';
import * as cloneDeep from 'lodash/cloneDeep';
import {UiPlatformService} from '../../../services/ui-platform.service';
import {AuthUserResponse} from '../../../services/user.service';
import {Shield_sensors_aggregate} from '../../../../../../api/src/lib/models/shield-_sensors-_aggregate';
import {Sensor_group_objects} from '../../../../../../api/src/lib/models/sensor-_group-_objects';
import {Encoding} from '@capacitor/filesystem';
import {  NavigationStart, Router } from '@angular/router';
import {timer} from 'rxjs'
import {takeWhile} from 'rxjs/operators'
import {AlertSettingModalComponent} from "../../../components/alert-setting-modal/alert-setting-modal.component";
import {SensorSettingsModalPage} from "../sensor-settings-modal/sensor-settings-modal.page";

export interface SensorsDeployedInterface {
  total: {
    label: string,
    value: number
  },
  offline: {
    label: string,
    value: number
  },
  attention: {
    label: string,
    value: number
  }
  showDownload: boolean
}

export interface StatusSelectionInterface {
  title: string;
  value: string;
  status?: string;
}


@Component({
  selector: 'lib-ketos-shield-sensors',
  templateUrl: './ketos-shield-sensors.component.html',
  styleUrls: ['./ketos-shield-sensors.component.scss'],
})
export class KetosShieldSensorsComponent implements OnInit, OnDestroy {

  _statusSelection: StatusSelectionInterface;
  @Input() set statusSelection(value: StatusSelectionInterface) {
    this._statusSelection = value;
    this.statusSelectionChanged();
  }

  get statusSelection(): StatusSelectionInterface {
    return this._statusSelection;
  }

  _search: string;
  @Input() set search(value: string) {
    this._search = value;
    this.searchChanged();
  }

  get search(): string {
    return this._search;
  }

  _triggerDownload;
  @Input() set triggerDownload(value: boolean) {
    if (value === true) {
      this.downloadAllShieldSensors();
    }
    this._triggerDownload = value;
  }

  get triggerDownload(): boolean {
    return this._triggerDownload;
  }
  @Output() updateDownloadStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sensorsDeployedChanged: EventEmitter<SensorsDeployedInterface> = new EventEmitter<SensorsDeployedInterface>();

  shieldSensors: ShieldDeviceExtended[];
  private unfilteredSensorsModelData: ShieldDeviceExtended[];
  socketSub: Subscription;
  showItem: boolean;
  sensorGroups: string[] = [];
  subs: Subscription[] = [];
  sortState = 'ascending';
  sortGroupState = 'ascending';
  groupColDisplay: boolean = true;
  appState: AppState;
  allSensorSub: Subscription;
  sensorSub: Subscription;
  socketSubAggData: Subscription;
  sensorIds: any = [];
  subjectArray: any = [];
  sensors: ShieldDeviceExtended[] = [];
  //contSub: Subscription;
  selectedSensor: string;
  user: AuthUserResponse;
  timeOut;
  alive: boolean = true;
  public contModeTooltip = true;
  private timer: Observable<any>;

  constructor(private authService: AuthService,
              private httpStatus: HTTPStatus,
              public appStateService: AppStateService,
              private groupService: GroupCachedService,
              private sensorsService: SensorsService,
              private resultsService: ResultsService,
              private shieldDevicesCachedService: ShieldDevicesCachedService,
              private sensorGroupService: SensorGroupCachedService,
              // private allSensorsCachedService: AllSensorsCachedService,
              private resultCountsCachedService: ResultCountsCachedService,
              private resultAggregatesCachedService: ResultAggregatesCachedService,
              // private testsScheduleService: TestScheduleService,
              private continuousModeService: ContinuousModeService,
              private modalController: ModalController,
              private popupService: KetosPopupService,
              private ketosSocketService: KetosSocketService,
              private datePipe: DatePipe,
              private router: Router,
              public uiPlatformService: UiPlatformService) {
                if(!uiPlatformService.webPlatform){
                  this.router.events.subscribe(event =>{
                    if (event instanceof NavigationStart ){
                      this.routerChangeMethod(event.url);
                    }
                  })
                }
              }

   manageTooltip(){
     this.contModeTooltip = true;

     this.timeOut = setTimeout(() => {
        this.contModeTooltip = false;
      }, 5000);

   // this.timer        = timer(5000); // 5000 millisecond means 5 seconds
    // this.contSub = this.timer.subscribe(() => {
    //     // set showloader to false to hide loading div from view after 5 seconds
    //     this.contModeTooltip = false;
    // });
  }
  getAggDataSubject(): Subject<{ counts: Result_count[], aggregates: Shield_sensors_aggregates }> {
    const sub = new Subject<{ counts: any, aggregates: any }>();
    this.fetchAggData().subscribe((res) => {
      sub.next(res);
    });
    const oldSocketAggDataSub = this.socketSubAggData;
    console.log(this.sensorIds, 'this._id');
    if (this.sensorIds.length > 0) {
      const subjectArray1 = [];
      // for(let id of this.sensorIds){
      //   subjectArray1.push(this.ketosSocketService.getSubjectForRoom(id, KetosSocketEventEnum.shield_results))
      // }
      this.socketSubAggData = merge(...this.subjectArray)
        .pipe(
          throttleTime(5000, asyncScheduler, {trailing: true})
        )
        .subscribe(res => {
          console.log(res, 'UPDATE SensorPage');
          this.fetchAggData(true).subscribe((res) => {
            sub.next(res);
          });
        });
      if (oldSocketAggDataSub) {
        oldSocketAggDataSub.unsubscribe();
      }
      // }
    }

    this.fetchAggData();
    return sub;
  }

  openSensorSettings(sensor: ShieldDeviceExtended ) {
    if (this.user.role_id > 2) {
      return;
    }
    console.log('Open Snesor settings modal', sensor);
    this.modalController.create({
      component: SensorSettingsModalPage,
      cssClass: 'setting-modal',
      componentProps: {
        sensorId: sensor.sensor?.id
      }
    })
      .then( modal => {
        modal.onDidDismiss()
          .then((res: any) => {
            // console.log(res,'resDismiss')
          });
        modal.present();
      })
  }

  fetchAggData(polling = false): Observable<{ counts: Result_count[], aggregates: Shield_sensors_aggregates }> {

    if (polling) {
      const str = '/sensors/shield/aggregates';
      this.httpStatus.hideOverlayForEnd = str;
    }
    return combineLatest([
      this.resultCountsCachedService.getCached(true, true),
      //this.allShieldsensorsAggregatesCachedservice.getCached(true,true),
      this.resultAggregatesCachedService.getCached(true, true),
      //this.resultsService.GetResultCountsBySensor(),
      //this.sensorsService.GetAllShieldSensorsAggregates()
    ]).pipe(
      map(result => {
        return {counts: result[0], aggregates: result[1]};
      })
    );
  }

  ngOnInit() {
    this.user = this.authService.getUser();
    this.fetchData();
    //console.log('ngoinit');

  }
  routerChangeMethod(url){
    console.log(url,"url")
    clearTimeout(this.timeOut)
    this.timeOut = null;
    this.manageTooltip();
  }

  fetchData(refreshEvent?) {
   //this.manageTooltip();
   setTimeout(() => {
    this.contModeTooltip = false;
  }, 5000);
    let tmpRefreshEvent = refreshEvent;
    this.groupColDisplay = this.authService.getUser().role_id < 3 ? true : false;

    this.allSensorSub?.unsubscribe();
    this.allSensorSub = this.shieldDevicesCachedService.getCached(true, true)
      .pipe(
        throttleTime(5000, asyncScheduler, {leading: true, trailing: true})
      )
      .subscribe(devices => {
      devices.map(sn => {
        // if(sn.operating_status === 1 ){
        this.sensorIds.push(sn.id);
        // }
      });

      this.sensors = cloneDeep(devices);
      //this.setSensorData(this.sensors)
      this.loadLatestData(false, tmpRefreshEvent);
      tmpRefreshEvent = null;
    });
    // @TODO: Johannes watersources refactor reimplement
    // const oldSocketSub = this.socketSub;
    //  //const subjectArray = []
    //  console.log(this.sensorIds)
    //    for(let id of this.sensorIds){
    //      this.subjectArray.push(this.ketosSocketService.getSubjectForRoom(id, KetosSocketEventEnum.shield_sensor))
    //    }
    //    this.socketSub=merge(...this.subjectArray)
    //    .pipe(
    //      throttleTime(5000, asyncScheduler, {trailing: true})
    //    )
    //      .subscribe((res:any) => {
    //        console.log(res,"subjectArray")
    //        this.sensors.map(sn => {
    //          if(sn.sensor_id === res?.sensor_id){
    //            sn.heartbeat = res?.message?.shield_sensor?.heartbeat;
    //            sn.continuous_mode_status= res?.message?.shield_sensor?.continuous_mode_status
    //          }
    //        })
    //        this.loadLatestData(true);
    //      });
    //  if (oldSocketSub) {
    //    oldSocketSub.unsubscribe();
    //  }
    // this.loadLatestData();
  }

  loadLatestData(polling = false, refreshEvent?) {
    let tmpRefreshEvent = refreshEvent;
    if (polling) {
      const str = '/sensors/shield/';
      this.httpStatus.hideOverlayForEnd = str;
    }

    this.subs.map(sub => {
      sub.unsubscribe();
    });
    this.subs = [];
    this.subs.push(
      combineLatest([
        //this.shieldSensorsService.getCached(true),
        this.authService.getUser().role_id < 3 ? this.groupService.getCached(true) : of([<Group_master> {
          id: this.authService.getUser()?.group_id,
          group_name: this.authService.getUser()?.group_name
        }]),
        // this.allSensorsCachedService.getCached(true),
        this.getAggDataSubject(),
        this.authService.getUser().role_id < 3 ? this.sensorGroupService.getCached(true) : of([]),
      ])
        .subscribe(res => {

         // console.log(this.sensors, res, 'fromSocket');
          // this.sensors=res[0].shield_sensors;
          this.setSensorData(this.sensors, res[0], res[1].counts, res[2], res[1].aggregates?.items);
          // this.setSensorData(res[0],res[1],res[2],res[3])

          tmpRefreshEvent?.target?.complete();
          tmpRefreshEvent = null;
        })
    );
  }

  setSensorData(shieldDevices: Shield_device[], groupMasters: Group_master[], resultCounts: Result_count[], sensorGroupObjects: Sensor_group_objects[], aggregateCounts: Shield_sensors_aggregate[]) {

    const countMap = {};
    resultCounts.map(cnt => {
      countMap[cnt.sensor_id] = cnt.count;
    });
   // console.log(aggregateCounts, 'aggData');
    const lastTestTimeMap = {};
    const lastContinuousTestTimeMap = {};
    const continuousCountMap = {};
    const scheduledTestCountsDict = {};
    aggregateCounts.map(item => {
      lastTestTimeMap[item.sensor_id] = item.last_experiment_test_time;
      lastContinuousTestTimeMap[item.sensor_id] = item.last_continuous_test_time;
      continuousCountMap[item.sensor_id] = item.continuous_tests_count;
      scheduledTestCountsDict[item.sensor_id] = item.scheduled_tests_count;
    });

    this.shieldSensors = cloneDeep(shieldDevices);
    const downloadStatus = this.shieldSensors.length > 0 ? true : false;
    this.updateDownloadStatus.emit(downloadStatus)
    this.shieldSensors.map(device => {

      // device.sensor_sn = this.shieldDevicesCachedService.map[device.sensor_id]?.sensor_sn;
      device.runtime = CalculateHoursToNow([device.sensor.installation_date]);
      device.lastTestTime = lastTestTimeMap[device.sensor.id];
      device.lastContinuousTestTime = lastContinuousTestTimeMap[device.sensor.id];
      device.total_results = countMap[device.sensor.id];
      device.total_continuous_results = continuousCountMap[device.sensor.id];
      device.totalScheduledTests = scheduledTestCountsDict[device.sensor.id];
      device.group_names = sensorGroupObjects.filter(mp => mp.sensor_id === device.sensor.id).map(mp => mp.group_name);
    });
    groupMasters.forEach((item) => {
      this.sensorGroups.push(item.group_name);
    });
   // console.log(this.shieldSensors, 'sensorDevices');
    //this.sensorGroups= res[0]
    this.initShield();
  }

  initShield() {
    for (let i = 0; i < this.shieldSensors.length; i++) {
      //   if (sensors[i].operating_status === AppService.environment().CONSTANTS.WAVE_LOCATIONS_STATUS.orange
      //     || this.sensorsModel.data[l].raw_status === AppService.environment().CONSTANTS.WAVE_LOCATIONS_STATUS.red) {
      //     this.sensorsDeployedModel.attention.value = this.sensorsDeployedModel.attention.value + 1;
      //   }
      //   if (this.sensorsModel.data[l].raw_status === AppService.environment().CONSTANTS.WAVE_LOCATIONS_STATUS.green) {
      //     // if sensor good
      //   } else {
      //     this.sensorsDeployedModel.offline.value = this.sensorsDeployedModel.offline.value + 1;
      //   }
      // this.shieldSensors[i].group_names = this.sensorGroups;
    }
    this.unfilteredSensorsModelData = this.shieldSensors.slice();
    this.emitSensorsDeployed();
    this.searchChanged();
  }

  statusSelectionChanged() {
    if (this.unfilteredSensorsModelData && this.shieldDevicesCachedService && this.statusSelection) {
      this.searchChanged();
      if (this.statusSelection && this.statusSelection.status) {
        this.shieldSensors = this.shieldSensors.filter(sensor => sensor.heartbeat_status === this.statusSelection.status);
      }
    }
  }

  searchChanged() {
    if (this.unfilteredSensorsModelData) {
      this.shieldSensors = this.unfilteredSensorsModelData.filter(this.searchShield(this.search));
    }
  }

  searchShield(needle) {
    //console.log(needle)
    return (item: ShieldDeviceExtended) => {
      if (needle && item.sensor.location_name) {
        // console.log(item)
        const searchNeedle = needle.toLowerCase();
        const searchHaystackLocation = item.sensor.location_name.toLowerCase();
        // const searchHaystackCountry = item.location_country.toLowerCase();
        const searchHaystackSensorId = item.sensor.sensor_sn?.toLowerCase();
        return searchHaystackLocation.includes(searchNeedle)
          // || searchHaystackCountry.includes(searchNeedle)
          || searchHaystackSensorId.includes(searchNeedle);
      } else {
        return true;
      }
    };
  }

  emitSensorsDeployed() {
    this.sensorsDeployedChanged.emit({
      total: {
        label: 'Ketos Shield sensors deployed',
        value: this.shieldSensors.length
      },
      offline: {
        label: 'Sensors ' + 'are' + ' critical',
        value: 0
      },
      attention: {
        label: 'Locations need your attention',
        value: 0
      },
      showDownload: this.shieldSensors.length ? true : false
    });
    /*
    //   if (sensors[i].operating_status === AppService.environment().CONSTANTS.WAVE_LOCATIONS_STATUS.orange
          //     || this.sensorsModel.data[l].raw_status === AppService.environment().CONSTANTS.WAVE_LOCATIONS_STATUS.red) {
          //     this.sensorsDeployedModel.attention.value = this.sensorsDeployedModel.attention.value + 1;
          //   }
          //   if (this.sensorsModel.data[l].raw_status === AppService.environment().CONSTANTS.WAVE_LOCATIONS_STATUS.green) {
          //     // if sensor good
          //   } else {
          //     this.sensorsDeployedModel.offline.value = this.sensorsDeployedModel.offline.value + 1;
          //   }
     */
  }

  async openMap(sensor: ShieldDeviceExtended) {
    const chartMap = {
      markers: [{
        lat: sensor['latitude'] || sensor.sensor?.latitude,
        lon: sensor['longitude'] || sensor.sensor?.longitude,
        locations: [sensor.location_name || sensor.sensor.location_name],
        initialFit: sensor['longitude'] || sensor.sensor?.longitude > -180 && sensor['longitude'] || sensor.sensor?.longitude < -50
      }],
      details: {
        LOCATION_NAME: sensor.location_name,
        SENSOR_TYPE: 'shield'
      },
      locationCount: null,
      openTooltipOnMarkerClick: true,
      headerDetails: null,
      centerLocation: true
    };

    const modal = await this.modalController.create({
      component: SensorMapComponent,
      cssClass: 'locations-map-modal',
      componentProps: {
        markers: chartMap.markers
      },
    });
    await modal.present();
  }

  public opStatusToString(op_status: number): string {
    switch (op_status) {
      case 0:
        return '(End Of Line)';
      case 1:
        return '(Operational)';
      case 2:
        return '(Maintenance)';
      case 3:
        return '(Decommissioned)';
      case 4:
        return '(Service Ops)';
      default:
        return '';
      // return '(Status Unknown)'
    }
  }

  continuousModeChanged(sensor: ShieldDeviceExtended, checked: boolean, e, index) {
    console.log('setting continuous mode for: ', sensor.sensor.id, ':', sensor.sensor.location_name, checked ? 'on' : 'off');

    this.shieldDevicesCachedService.getCached(false, true).subscribe(res => {
      console.log(res);
      const filteredSensor = res.filter(sn => sn.id === sensor.id as unknown as any);
      //console.log(filteredSensor)
      if (!filteredSensor[0].water_status) {
        //if(!sensor.water_status){
        //this.shieldSensors[index].continuous_mode_status = 0
        this.popupService.confirmOkPopup({
          title: 'Water pump is off',
          message: 'Cannot change the continuous mode right now. Please try again later.'
        }).then(res => {
          // this.shieldSensorsService.getCached(false, true).toPromise();
          //this.shieldSensors= this.shieldSensors.slice()
        });
        this.loadLatestData(true);
        return false;
      }
      this.continuousModeService.UpdateShieldContinuousMode({sensor_id: sensor.sensor.id, action: checked ? 'on' : 'off'})
        .subscribe(res => {
          console.log('Set continuous mode response: ', res);
          this.shieldDevicesCachedService.getCached(true).toPromise();
          this.loadLatestData(true);
          //setTimeout(() => {
          //this.fetchData()
          //this.shieldSensorsService.getCached(false,true).toPromise();
          //},5000)

        });
    });
  }

  public downloadAllShieldSensors() {
    console.log({startDate: moment().utc(false).format(), endDate: moment().add(10, 'years').utc(false).format()});
    // this.testsScheduleService.GetTestSchedules({startDate: moment().utc(false).format(), endDate: moment().add(10, 'years').utc(false).format()})
    //   .subscribe( schedules => {

    //     const scheduleCountMap = ScheduleCalculations.scheduledTestsCountMapBySensorId(schedules as unknown as Shield_test_schedule2[]);
    //     this.unfilteredSensorsModelData.map(sensor => {
    //       sensor.totalScheduledTests = scheduleCountMap[sensor.sensor_id];
    //       sensor.onlineStatus = sensor.heartbeat_status === 'good' ? 'online' : 'offline';
    //     })
    //     const headers = [
    //       {key: 'water_monitored', title: 'water_monitored', defaultValue: 0},
    //       {key: 'sensor_sn', title: 'sensor_id'},
    //       {key: 'operating_status', title: 'op_status'},
    //       {key: 'lastTestTime', title: 'last_test_timestamp'},
    //       {key: 'location_name', title: 'location_name'},
    //       {key: 'exp_bitmap', title: 'allowed_bitmap'},
    //       {key: 'installation_date', title: 'installation_date'},
    //       {key: 'total_results', title: 'total_tests', defaultValue: 0},
    //       {key: 'group_names', title: 'group_names'},
    //       {key: 'latitude', title: 'latitude'},
    //       {key: 'longitude', title: 'longitude'},
    //       {key: 'onlineStatus', title: 'status'},
    //       {key: 'total_continuous_results', title: 'total_continuous_tests', defaultValue: 0},
    //       {key: 'continuous_mode_status', title: 'continuous_mode'},
    //       {key: 'lastContinuousTestTime', title: 'last_continuous_timestamp'},
    //       {key: 'totalScheduledTests', title: 'scheduled_tests', defaultValue: 0},
    //       {key: 'runtime', title: 'runtime'},
    //     ]
    //     CsvGenerator.downloadAdCsv(this.unfilteredSensorsModelData, headers, 'Ketos-Shield-Data');
    //   })
    console.log(this.shieldSensors);
    const csvData: any = cloneDeep(this.shieldSensors.slice());
    csvData.forEach(data => {
      data.opStatus = this.opStatusToString(data.operating_status);
      data.groupNames = '"' + data.group_names.join('-') + '"';
      data.lastContinuousTestTime = data.lastContinuousTestTime ? this.datePipe.transform(data.lastContinuousTestTime, 'MMM dd YYYY', 'UTC') : 'N/A',
        //data.lastContinuousTestTime = data.lastContinuousTestTime?moment(data.lastContinuousTestTime).format("MMM Do YYYY"):'N/A';
        // data.test_schedule_updated_on = data.test_schedule_updated_on?moment(data.test_schedule_updated_on).format("MMM Do YYYY"):'N/A';
        data.test_schedule_updated_on = data.test_schedule_updated_on ? this.datePipe.transform(data.test_schedule_updated_on, 'MMM dd YYYY', 'UTC') : 'N/A';
      //data.installation_date = data.installation_date?moment(data.installation_date).format("MMM Do YYYY"):'N/A';
      data.installation_date = data.sensor?.installation_date ? this.datePipe.transform(data.sensor.installation_date, 'MMM dd YYYY', 'UTC') : 'N/A';
      //data.lastTestTime = data.lastTestTime?moment(data.lastTestTime).format("MMM Do YYYY"):'N/A';
      data.lastTestTime = data.lastTestTime ? this.datePipe.transform(data.lastTestTime, 'MMM dd YYYY', 'UTC') : 'N/A';
      data.total_results = data.total_results ? data.total_results : 'N/A';
      data.location_name = '"' + data.sensor?.location_name + '"';
    });
    console.log(csvData);

    const headers = [
      {key: 'opStatus', title: 'Status', defaultValue: 0},
      {key: 'id', title: 'Sensor ID'},
      {key: 'location_name', title: 'Location'},
      {key: 'groupNames', title: 'Location Group'},
      // {key: 'continuous_mode_status', title: 'Continuous Mode'},
      {key: 'lastContinuousTestTime', title: 'Last Continuous Test Date'},
      {key: 'test_schedule_updated_on', title: 'Last Scheduled Test Date'},
      {key: 'installation_date', title: 'Installation Date'},
      {key: 'lastTestTime', title: 'Last Test Date'},
      {key: 'runtime', title: 'Runtime'},
      {key: 'total_results', title: 'No. of datapoints'},
    ];

    if (this.uiPlatformService.isMobileDevice) {
      const csvString = CsvGenerator.objectArrayToCsvStringFromHeaders(csvData, headers);
      this.uiPlatformService.saveAndOpenFileMobile(csvString, 'Ketos-Shield-Sensors-Data' + '.csv', 'text/csv', Encoding.UTF16);
    } else {
      CsvGenerator.downloadAdCsv(csvData, headers, 'Ketos-Shield-Sensors-Data');
    }
  }

  sortSensorGroupListing(sortBy, state) {
    if (state === 'ascending') {
      this.shieldSensors = this.shieldSensors.sort((a, b) => (a[sortBy] < b[sortBy]) ? 1 : ((b[sortBy] < a[sortBy]) ? -1 : 0));
      if (sortBy === 'location_name') {
        this.sortState = 'descending';
      } else {
        this.sortGroupState = 'descending';
      }
    } else {
      this.shieldSensors = this.shieldSensors.sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : ((b[sortBy] > a[sortBy]) ? -1 : 0));
      if (sortBy === 'location_name') {
        this.sortState = 'ascending';
      } else {
        this.sortGroupState = 'ascending';
      }
    }
  }

  sensorClicked(sensorId: string) {
    if (sensorId === this.selectedSensor) {
      this.selectedSensor = null;
    } else {
      this.selectedSensor = sensorId;
    }
  }

  ngOnDestroy(): void {
    this.timeOut = false;
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
    if (this.allSensorSub) {
      this.allSensorSub.unsubscribe();
      this.allSensorSub = null;
    }
    if (this.socketSub) {
      this.socketSub.unsubscribe();
    }
    if (this.socketSubAggData) {
      this.socketSubAggData.unsubscribe();
    }
  }

}
