import { Pipe, PipeTransform } from '@angular/core';
import {ExperimentConfigurationsService} from '../services/experiment-configurations.service';

@Pipe({
  name: 'bitmapToNamesPipe'
})
export class BitmapToNamesPipe implements PipeTransform {
  constructor(private expConfService: ExperimentConfigurationsService) {
  }

  transform(bitmap: string): string {
    if (typeof bitmap !== 'string') {
      return bitmap;
    }
    let text = [...bitmap].map((bit, idx) => {
      if (bit === '1' && this.expConfService.bitIdxToExperimentsMapFiltered[idx]) {
        return this.expConfService.bitIdxToExperimentsMapFiltered[idx].map(exp => exp.title).join(', ') + ', ';
      }
      return '';
    })
      .join('');
    if (text.length > 0) {
      text = text.substring(0, text.length - 2);
    }
    return text;
  }

}
