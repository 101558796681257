import {AfterViewInit, Component, OnInit, ViewChild,Input} from '@angular/core';
import * as Highcharts from 'highcharts';
import * as yieldHighcharts from 'highcharts';

const HighchartsMore = require("highcharts/highcharts-more.src");
HighchartsMore(Highcharts);
const HC_solid_gauge = require("highcharts/modules/solid-gauge.src");
HC_solid_gauge(Highcharts);import { Chart, ChartOptions } from 'chart.js';
import {boronDataValues,chlorineDataValues,nitrateDataValues} from './crop-toxity-data'
import {DropdownOptionsInterface, DropdownValueInterface} from "../../../../components/shared/dropdown/models/dropdown-options.interface";
import { CropService, CropsInterface } from '../../nutrient-management.service';
import { Subscription } from 'rxjs';
import * as cloneDeep from 'lodash/cloneDeep';
import { SeasonService } from '../nutrient-season-service';
(function(highcharts) {
  highcharts.seriesType('lowmedhigh', 'boxplot', {
    keys: ['low', 'median', 'high'],
    tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: ' +
            'Low <b>{point.low}</b> - Median <b>{point.median}</b> - High <b>{point.high}</b><br/>'
    }
}, {
    // Change point shape to a line with three crossing lines for low/median/high
    // Stroke width is hardcoded to 1 for simplicity
    drawPoints: function () {
        var series = this;
        Highcharts.each(this.points, function (point) {
            var graphic = point.graphic,
                verb = graphic ? 'animate' : 'attr',
                shapeArgs = point.shapeArgs,
                width = shapeArgs.width,
                left = Math.floor(shapeArgs.x) + 0.5,
                right = left + width,
                crispX = left + Math.round(width / 2) + 0.5,
                highPlot = Math.floor(point.highPlot) + 0.5,
                medianPlot = Math.floor(point.medianPlot) + 0.5,
                lowPlot = Math.floor(point.lowPlot) + 0.5 - (point.low === 0 ? 1 : 0); // Sneakily draw low marker even if 0

            if (point.isNull) {
                return;
            }

            if (!graphic) {
                point.graphic = graphic = series.chart.renderer.path('point').add(series.group);
            }

            graphic.attr({
                stroke: point.color || series.color,
                "stroke-width": 1
            });

            graphic[verb]({
                d: [
                    'M', left, highPlot,
                    'H', right,
                    'M', left, medianPlot,
                    'H', right,
                    'M', left, lowPlot,
                    'H', right,
                    'M', crispX, highPlot,
                    'V', lowPlot
                ]
            });
        });
    }
})})(Highcharts)
@Component({
  selector: 'app-nutrient-crops-grown',
  templateUrl: './nutrient-crops-grown.component.html',
  styleUrls: ['./nutrient-crops-grown.component.scss']
})
export class NutrientCropsGrownComponent  {

  @Input() selectedSeason:string;
  @Input() selectedYear:string;
 // @ViewChild('canvas', {static: true}) canvas;
  subsrciption = new Subscription();
  //selectedNutrient;
  selectedCrop;
  placeHolderImage;
  cropOptions={
    values:[]
  }
  nutientOptions={
    values:[

    {title: 'Boron', value: "boron"},
    {title: 'Chlorine', value: "chlorine"},
    {title: 'Nitrate', value: "nitrate"}
    ]
  }
  selectedNutrient = this.nutientOptions.values[0].title;
  selectedNutrientDatavalue = []
  updateFlag;
  updateYieldFlag;

  chart;
  chartCallback;
  title = 'myHighchart';
  highcharts = Highcharts;
  yieldHighcharts = yieldHighcharts;
  chartConstructor = "chart";
  minRate;
  maxRate;
  chartOptions:any={
    chart: {
    type: 'lowmedhigh',
    backgroundColor:'#242434',
    className:'shieldChartContainer'
    },
    title:{},
    xAxis:[],
    yAxis: {},
    tooltip: {
      shared: true
    },
    plotOptions: {
      series: {
        stickyTracking: true,
        whiskerWidth: 5
      }
    },
    series:[],

  }
  yieldChartOptions:any={
    chart: {
    type: 'line',
    backgroundColor:'#242434',
    },
    title:{},
    xAxis:[],
    yAxis: {},
    tooltip: {
      shared: true
    },
    series:[],

  }
  constructor(private cropService:CropService, private seasonService:SeasonService ) {
    console.log(this.selectedSeason)

   // this.loadPlaceholderData()
   const self = this;

    this.chartCallback = chart => {
      // saving chart reference
      self.chart = chart;
    };
  }

  ngOnInit() {
    this.cropsData()
    this.calculateMinMaxRate();
    this.loadGraph(this.selectedYear,this.selectedSeason)
    this.loadYieldGraph(this.selectedSeason)
  }
  cropsData(){
    if(this.cropService.data){
    this.cropService.data.subscribe((data:any) => {
      console.log(data,"data")

      if(data){
          this.cropOptions = cloneDeep(this.cropOptions)

          if(!this.cropOptions.values.length){
             this.cropOptions.values=this.cropOptions.values.concat(data)
          }
          else{
          let y;
          let j=0;
          let arr = [];
         // for (var i of data){
            this.cropOptions.values.forEach(function(o){
              arr.push(o.title)
            })
            console.log(arr)
            //this.cropOptions.values.push(y[0])
          //}
          for(var i of data){
            if(!arr.includes(i.title)){
              this.cropOptions.values.push(i)
            }
          }

        }
     }
     console.log(this.cropOptions)
     //this.selectedNutrient = this.nutientOptions.values[0].title;
     this.selectedCrop = this.cropOptions.values[0].title;
     this.loadPlaceholderData()
    })
  }
  }
  calculateMinMaxRate(){
    switch(this.selectedNutrient){
      case "Boron":
        this.minRate = boronDataValues[this.selectedCrop]['Deficiency']
        this.maxRate =boronDataValues[this.selectedCrop]['toxicity']
        break;
      case "Chlorine":
        this.minRate = chlorineDataValues[this.selectedCrop]['Deficiency']
        this.maxRate =chlorineDataValues[this.selectedCrop]['toxicity']
        break;
      case "Nitrate":
        this.minRate = nitrateDataValues[this.selectedCrop]['Deficiency']
        this.maxRate =nitrateDataValues[this.selectedCrop]['toxicity']
        break;
      default:
        //this.selectedNutrientDatavalue.push(nutrientDataValues);
    }

  }
  setYaxisPlotLines(){
   return [{
      // value:minVal,
       value:this.minRate,
        color: 'green',
        dashStyle: 'shortdash',
        width: 2,
        zIndex: 1.67,
        label: {
            text: '🠗 Deficiency',
            style:{
              color:'#ffffff'
            },
            y:15
        }
    }, {
        //value: maxVal,
        value:this.maxRate,
        color: 'red',
        dashStyle: 'shortdash',
        width: 2,
        zIndex: 1.67,
        label: {
            text: '🠕 Toxicity',
            style:{
              color:'#ffffff'
            },
        }
    }]
  }
  loadGraph(year,season){
    //this.dataValues = dataValues
   let titleText;
   this.subsrciption = this.seasonService.data.subscribe((data:any) => {
     console.log(data,"data")
     year= data.year?data.year:this.selectedYear;
    //console.log(boronDataValues.Shield1[season].xAxis[0].categories)
    if(data){
      if(data.title){
         season =data.title;
         if(this.selectedNutrient === "Boron"){
           this.chartOptions = cloneDeep(boronDataValues.Boron[season]);
         }else if(this.selectedNutrient === "Chlorine"){
           this.chartOptions = cloneDeep(chlorineDataValues.Chlorine[season]);
         }else{
           this.chartOptions = cloneDeep(nitrateDataValues.Nitrate[season]);
         }

        titleText = season === 'Annual'?` ${this.selectedNutrient} Annual`:` ${this.selectedNutrient} ${season} ${year}`
        this.chartOptions.title = {
               text: titleText,
               style:{color:'#ffffff'}
             };
        //this.chartOptions.yAxis.plotLines[0].value= this.minRate
       // this.chartOptions.yAxis.plotLines[1].value= this.maxRate
       console.log("dataTitleIF")
       this.chartOptions.yAxis.plotLines= this.setYaxisPlotLines();
        this.updateFlag = true;
        this.loadYieldGraph(season)
       }
       if(data.year){
         this.selectedYear= data.year
        // this.chartOptions = cloneDeep(boronDataValues[this.selectedSheild][season]);
         titleText = season === 'Annual'?` ${this.selectedNutrient} Annual`:` ${this.selectedNutrient} ${season} ${data.year}`
         this.chartOptions.title = {
               text: titleText,
               style:{color:'#ffffff'}
             };
        this.chartOptions.yAxis.plotLines= this.setYaxisPlotLines();
        this.updateFlag = true;
       }
    }else{
      console.log("else")
     titleText = season === 'Annual'? ' Boron Annual': ' Boron '+ season+" "+year
     this.chartOptions.yAxis.plotLines= this.setYaxisPlotLines();
    }
   })

   //titleText = `${season} === 'Annual'? '${this.selectedSheild} ${this.selectedNutrient} Annual': '${this.selectedSheild} ${this.selectedNutrient} '+ ${season}+" "+${year}`;

   this.chartOptions =  {

     chart: {
       type: 'lowmedhigh',
       backgroundColor:'#242434',
       className:'shieldChartContainer'
       },
     title: {
       text: titleText,
       style:{
         color:'#ffffff'
       }
     },
     xAxis: [{
       lineColor: '#ffffff',
       tickColor: '#ffffff',
       labels:{
         style:{
           color:'#ffffff'
         }
       },
       style:{
         color:'#ffffff'
       },
       accessibility: {
         description: 'Months of the year',
         style:{
           color:'#ffffff'
         }
       },
       categories:boronDataValues.Boron[season].xAxis[0].categories
       //categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
     }],
     yAxis: {
       title: {
         text: 'Contaminant Concentration (ppm)',
         style:{
           color:'#ffffff'
         },
       },
       plotLines: [{
       value: boronDataValues[this.selectedCrop]['Deficiency'],
       //value:this.minRate,
        color: 'green',
        dashStyle: 'shortdash',
        width: 2,
        zIndex: 1.67,
        label: {
          text: '🠗 Deficiency',
          style:{
              color:'#ffffff'
          },
          y:15
        }
    }, {
        value: boronDataValues[this.selectedCrop]['toxicity'],
       // value:this.maxRate,
        color: 'red',
        dashStyle: 'shortdash',
        width: 2,
        zIndex: 1.67,
        label: {
          text: '🠕 Toxicity',
          style:{
              color:'#ffffff'
            },
        }
    }],
       style:{
         color:'#ffffff'
       },
       labels:{
         style:{
           color:'#ffffff'
         }
       },
       min: 0
     },
     legend:{
      itemStyle:{
        color:'#7cb5ec'
      }
     },
     series:[{name:boronDataValues.Boron[season].series[0].name,data:[...boronDataValues.Boron[season].series[0].data]}]
   };



  }
  loadYieldGraph(season){
    this.yieldChartOptions =  {

      chart: {
        type: 'line',
        backgroundColor:'#242434',
        },
      title: {
        //text: this.selectedCrop+' Boron '+season,
        text: `Boron Measured/Optimum Ratio for ${this.selectedCrop}`,
        style:{
          color:'#ffffff'
        }
      },
      xAxis: [{
        lineColor: '#ffffff',
        tickColor: '#ffffff',
        labels:{
          style:{
            color:'#ffffff'
          }
        },
        style:{
          color:'#ffffff'
        },
        accessibility: {
          description: 'Months of the year',
          style:{
            color:'#ffffff'
          }
        },
        categories:boronDataValues[this.selectedCrop][season].xAxis[0].categories
        //categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      }],
      yAxis: {
        title: {
          text: 'Ratio',
          style:{
            color:'#ffffff'
          },
        },
        style:{
          color:'#ffffff'
        },
        labels:{
          style:{
            color:'#ffffff'
          }
        },
        min: 0
      },
      legend:{
        itemStyle:{
          color:'#7cb5ec'
        }
       },
      series:[{name:boronDataValues[this.selectedCrop][season].series[0].name,data:[...boronDataValues[this.selectedCrop][season].series[0].data[0]]}]
      //series:[0.5, 1.2, 1.3, 1.25,0.86]
    };
  }

  loadPlaceholderData(){
    this.placeHolderImage = `/assets/placeholderData/Chemicals_${this.selectedCrop}_${this.selectedNutrient}.PNG`;
  }
  ngOnDestroy(){
    this.subsrciption.unsubscribe();
  }
  updateSelectedCrop(data:any){
    this.selectedCrop = data.title;
    if(this.selectedNutrient === 'Boron'){
    const self = this;
    self.yieldChartOptions = cloneDeep(boronDataValues[this.selectedCrop][this.selectedSeason])
    self.yieldChartOptions.series=[{name:boronDataValues[this.selectedCrop][this.selectedSeason].series[0].name,data:[...boronDataValues[this.selectedCrop][this.selectedSeason].series[0].data[0]]}]
    self.yieldChartOptions.title = {
     // text: this.selectedCrop+" "+this.selectedNutrient+" "+ this.selectedSeason ,
      text: `Boron Measured/Optimum Ratio for ${this.selectedCrop}`,
      style:{color:'#ffffff'}
    };
    self.updateYieldFlag = true;
    }
    this.updateSelectedNutrient(this.selectedNutrient);
  }
  // updateSelectedNutrient(data:any){
  //   console.log(data)
  //   this.selectedNutrient=data.title
  //   this.loadPlaceholderData();
  // }
  updateSelectedNutrient(data:any){

    console.log(this.selectedNutrient,"this.selectedNutrient")
    this.selectedNutrient=data.title?data.title:this.selectedNutrient;
    const self = this,
      chart = this.chart;
      //self.chart = {}
      let minVal,maxVal

    switch(this.selectedNutrient){
      case "Boron":
       // minVal = boronDataValues[this.selectedCrop]['Deficiency'];
       // maxVal = boronDataValues[this.selectedCrop]['toxicity']
        self.chartOptions = cloneDeep(boronDataValues.Boron[this.selectedSeason])

        //self.chartOptions.yAxis.plotLines[0].value= boronDataValues[this.selectedCrop]['Deficiency']
       // self.chartOptions.yAxis.plotLines[1].value= boronDataValues[this.selectedCrop]['toxicity']

        break;
      case "Chlorine":
        //minVal = chlorineDataValues[this.selectedCrop]['Deficiency'];
        //maxVal = chlorineDataValues[this.selectedCrop]['toxicity']
        self.chartOptions = cloneDeep(chlorineDataValues.Chlorine[this.selectedSeason])
         break;
      case "Nitrate":
        //minVal = nitrateDataValues[this.selectedCrop]['Deficiency'];
        //maxVal = nitrateDataValues[this.selectedCrop]['toxicity'];
        self.chartOptions = cloneDeep(nitrateDataValues.Nitrate[this.selectedSeason])
        break;
      default:
        //this.selectedNutrientDatavalue.push(nutrientDataValues);
    }
    let titleText = this.selectedSeason === 'Annual'?` ${this.selectedNutrient} Annual`:`${this.selectedNutrient} ${this.selectedSeason} ${this.selectedYear}`
    this.calculateMinMaxRate();

  self.chartOptions.title = {
          text: titleText,
          style:{color:'#ffffff'}
        };
  self.chartOptions.yAxis.plotLines= this.setYaxisPlotLines();
  self.updateFlag = true;
}
}
