import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {UsersService} from '../../../../api/src/lib/services/users.service';
import {AppState, AppStateService, TimeIntervalInterface} from './app-state.service';
import * as cloneDeep from 'lodash/cloneDeep';
import { DataCorelationSettingsInterface } from '../pages/ketos-graph/ketos-graph.page';


@Injectable({
  providedIn: 'root'
})
export class DataCorelationModelService {
  public dataCorelationModel: DataCorelationSettingsInterface[] = [];
  public dataCorrelationType: 'temperature' | 'sensors' = 'sensors';
  settingKey = 'dataCorelationGraphs';

  constructor(private authService: AuthService,
              private   appStateService: AppStateService,
              private usersService: UsersService) {
  }


  async loadDataCorelationViewModels(onlyLocation = false): Promise<any> {
    if (this.authService.getUser().roleName === 'SuperAdmin') {
      return Promise.resolve(this.dataCorelationModel);
    }
    let models = [];
    return await this.usersService.GetUserSpecificSettings().toPromise()
      .then(data => {
        if (Array.isArray(data)) {
          for (const val of data as any[]) {
            if (val.key === this.settingKey) {
              //models=[];
              const shieldModels: DataCorelationSettingsInterface[] = JSON.parse(val.value);
              const shieldModelsObj: DataCorelationSettingsInterface = JSON.parse(val.value);
              if(shieldModels[0]?.dateRange || shieldModelsObj?.dateRange ){
                console.log('loadDataCorelationViewModels',shieldModelsObj)
                if (Array.isArray(shieldModels)) {
                  shieldModels[0].dateRange.min = new Date(shieldModels[0].dateRange.min);
                  shieldModels[0].dateRange.max = new Date(shieldModels[0].dateRange.max);
                  //shieldModels[0].dateRange.min = shieldModels[0].dateRange.min?new Date(shieldModels[0].dateRange.min):this.appStateService.selectedTimeInterval.min;
                  //shieldModels[0].dateRange.max = shieldModels[0].dateRange.max?new Date(shieldModels[0].dateRange.max):this.appStateService.selectedTimeInterval.max;
                // console.log(shieldModels,"shieldModels")
                  models.push(...shieldModels)
                //}
                }else{
                  shieldModelsObj.dateRange.min = shieldModelsObj.dateRange.min?new Date(shieldModelsObj.dateRange.min):this.appStateService.selectedTimeInterval.min;
                  shieldModelsObj.dateRange.max = shieldModelsObj.dateRange.max?new Date(shieldModelsObj.dateRange.max):this.appStateService.selectedTimeInterval.max;
                  console.log(shieldModelsObj,"shieldModelsObj")
                  //models.push({...shieldModelsObj})
                  models = [{...shieldModelsObj}]
                }
              }
            }
            if (val.key === 'dataCorelationSensorGraphs' && onlyLocation) {
              //models=[];
              const shieldModels: DataCorelationSettingsInterface[] = JSON.parse(val.value);
              const shieldModelsObj: DataCorelationSettingsInterface = JSON.parse(val.value);
               if (Array.isArray(shieldModels)) {
                console.log(shieldModels[0],"shieldModels")
                shieldModels[0].dateRange.min = new Date(shieldModels[0].dateRange.min)
                shieldModels[0].dateRange.max = new Date(shieldModels[0].dateRange.max)
                //shieldModels[0].dateRange.min = shieldModels[0].dateRange.min?new Date(shieldModels[0].dateRange.min):this.appStateService.selectedTimeInterval.min;
                //shieldModels[0].dateRange.max = shieldModels[0].dateRange.max?new Date(shieldModels[0].dateRange.max):this.appStateService.selectedTimeInterval.max;
              // console.log(shieldModels,"shieldModels")
                models.push(...shieldModels)
               //}
               }else{
                shieldModelsObj.dateRange.min = shieldModelsObj.dateRange.min?new Date(shieldModelsObj.dateRange.min):this.appStateService.selectedTimeInterval.min;
                shieldModelsObj.dateRange.max = shieldModelsObj.dateRange.max?new Date(shieldModelsObj.dateRange.max):this.appStateService.selectedTimeInterval.max;
                console.log(shieldModelsObj,"shieldModelsObj")
                //models.push({...shieldModelsObj})
                models = [{...shieldModelsObj}]
               }
            }
          }
        }

        this.dataCorelationModel = models;
        return models;
      });
  }

  public saveDataCorelationModels() {
    // console.log('appStateService', this.appService.graphicalViewModels);
    if (this.authService.getUser().roleName === 'SuperAdmin') {
      return;
    }
    this.usersService.UpdateUserSpecificSettings({
      items: [
        {
          key: this.settingKey,
          value: JSON.stringify(this.dataCorelationModel)
        }
      ]
    }).subscribe(res => {
      // console.log('saved graphs:', res);
    });
    return;
  }
}
