/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SensorTypes} from '../../models/sensor-types.enum';
import {combineLatest, of, Subscription, timer} from 'rxjs';
import {AppStateService} from '../..//services/app-state.service';
import {WaterSourcesCachedService} from '../..//services/water-sources-cached.service';
import {SensorGroupCachedService} from '../..//services/sensor-group-cached.service';
import {Sensor_group_objects} from '../../../../../api/src/lib/models/sensor-_group-_objects';
import {WaterSourceExtended} from '../..//models/extensions/water-source-extended';
import {ResultCountsCachedService} from '../..//services/result-counts-cached.service';
import {TestSchedulesCachedService} from '../../services/test-schedules-cached.service';
import {AuthService} from '../..//services/auth.service';
import {SensorsService} from '../../../../../api/src/lib/services';
import {DashboardUiStateService} from '../..//services/dashboard-ui-state.service';
import {Shield_sensors_aggregate} from '../../../../../api/src/lib/models/shield-_sensors-_aggregate';
import {AuthUserResponse} from "../../services/user.service";
import {AppStorage} from "../../utils/AppStorage";


export interface ResourceEfficiencyReport {
  waterUsage?: number;
  waterMonitored?: number;
  totalTests?: number,
  scheduledTests?: any,
  measurements?: number;
  runtime: number;
  group: {
    waterUsage?: number;
    waterMonitored?: number;
    measurements?: number;
    totalTests?: number,
    scheduledTests?: any,
    runtime: number;
    name?: string;
  };
}

@Component({
  selector: 'lib-sensor-details',
  templateUrl: './sensor-details.page.html',
  styleUrls: ['./sensor-details.page.scss']
})
export class SensorSummaryDetailsPage implements OnInit, OnDestroy {
  private subscribers: Subscription[] = [];
  private runtimeSubscription: Subscription;
  public selectedSensorType: SensorTypes = SensorTypes.shield;
  public resourceEfficiencyReport: ResourceEfficiencyReport = {
    waterUsage: 0,
    waterMonitored: 0,
    measurements: 0,
    totalTests: 0,
    scheduledTests: 0,
    runtime: 0,
    group: {
      waterMonitored: 0,
      measurements: 0,
      totalTests: 0,
      scheduledTests: 0,
      runtime: 0,
      name: ''
    }
  };
  public selectedLocation: WaterSourceExtended = null;
  public locationGroups = null;
  public selectedLocationsData;
  public data;


  userData: AuthUserResponse = JSON.parse(AppStorage.getItem('user'));
  showAll = false;
  @ViewChild('myname') ionicon;

  constructor(
    public appStateService: AppStateService,
    private authService: AuthService,
    public dashboardUiStateService: DashboardUiStateService,
    private waterSourcesCachedService: WaterSourcesCachedService,
    private resultCountsCachedService: ResultCountsCachedService,
    private sensorGroupMappingService: SensorGroupCachedService,
    private testSchedulesCachedService: TestSchedulesCachedService,
    private sensorsService: SensorsService,

  ) {
  }

  ngOnInit() {
    this.showAll = this.userData.role_id <= 2;

    this.subscribers.push(combineLatest([
        this.appStateService.getAppState(),
        this.resultCountsCachedService.getCached(false,true),
        this.authService.getUser()?.role_id < 4 ? this.sensorGroupMappingService.getCached(true) : of([]),
        this.sensorsService.GetAllShieldSensorsAggregates(),
      ])
        .subscribe((res) => {
          const appState = res[0];
          this.selectedSensorType = appState.sensorType;
          this.selectedLocation = appState.location;
          this.selectedLocationsData = appState.locations;

          this.resourceEfficiencyReport = this.calculateShieldSensorSummary(res[2], res[3].items, res[1]);
          this.startRuntimeCalculation(res[1],res[3].items);

          let totalTests = 0;
          res[1].map(res => {
            totalTests += res.count;
          });
          this.resourceEfficiencyReport.totalTests = totalTests;
          let groupSensorIds;
          let groupWaterSourceIdsDict;
          if (this.resourceEfficiencyReport.group) {

            if (appState.group.is_default_group === 'True') {
              this.resourceEfficiencyReport.group.totalTests = totalTests;
              groupSensorIds = res[2].filter(mapping => mapping.group_id === appState.group.id).map(mapping => mapping.sensor_id);

            } else {
              groupSensorIds = res[2].filter(mapping => mapping.group_id === appState.group.id).map(mapping => mapping.sensor_id);
              let groupTests = 0;
              res[1].filter(count => groupSensorIds.indexOf(count.sensor_id) !== -1)
                .map(count => {
                  groupTests += count.count;
                });
              this.resourceEfficiencyReport.group.totalTests = groupTests;
            }
          }
        })
    );


    setTimeout(() => {
      if (this.ionicon?.el && this.ionicon?.el.shadowRoot && this.ionicon?.el.shadowRoot.querySelector('.icon-inner svg title')) {
        this.ionicon.el.shadowRoot.querySelector('.icon-inner svg title').innerHTML = '';
      }
    }, 1000);
  }

  calculateScheduleTotalTestsCount(counts: Shield_sensors_aggregate[], sensor_id) {
    let count = 0;
    const isScheduleTestCountAvailable = counts.filter(c => c.sensor_id === sensor_id);
    if (!isScheduleTestCountAvailable.length && sensor_id) {
      return count;
    }
    if (sensor_id && sensor_id !== null) {
      return count = isScheduleTestCountAvailable[0].scheduled_tests_count;
    } else {
      counts.forEach((item) => {
        if (item.items) {
          item.items.map(wsItem => {
            count += wsItem.scheduled_tests_count;
          })
        } else {
          count += item.scheduled_tests_count;
        }
      });
    }
    return count;
  }

  calculateGroupScheduledTestCount(groupSensorIds: number[], scheduleCountItems: Shield_sensors_aggregate[]) {
    let count = 0;
    scheduleCountItems.forEach((item) => {
      if (groupSensorIds.includes(item.sensor_id)) {
        if (item.items) {
          item.items.map(wsItem => {
            count += wsItem.scheduled_tests_count;
          })
        } else {
          count += item.scheduled_tests_count;
        }
      }
    });
    return count;
  }
  calculateGroupTestCount(counts, sensor_id) {
    let count = 0;
    const isCountAvailable = counts.filter(c => c.sensor_id === sensor_id);
    if (!isCountAvailable.length && sensor_id) {
      return count;
    }
    if (sensor_id && sensor_id !== null) {
      return count = isCountAvailable[0].count;
    } else {
      counts.forEach((item) => {
        count += item.count;
      });
    }
    return count;
  }
  toggleSensorSummary() {
    this.dashboardUiStateService.setSensorSummaryMinimized(!this.dashboardUiStateService.sensorSummaryMinimized);

    setTimeout(() => {
      if (this.ionicon.el && this.ionicon.el.shadowRoot && this.ionicon.el.shadowRoot.querySelector('.icon-inner svg title')) {
        this.ionicon.el.shadowRoot.querySelector('.icon-inner svg title').innerHTML = '';
      }
    }, 200);
  }

  calculateShieldSensorSummary(sensorGroups, scheduleCountItems: Shield_sensors_aggregate[], countsRes): ResourceEfficiencyReport {
    const appState = this.appStateService.getAppState().value;
    const groupSensorIds = sensorGroups.filter(mapping => mapping.group_id === appState.group.id).map(mapping => mapping.sensor_id);
    let group;
    if (appState.group) {
      group = {
        totalTests: this.resourceEfficiencyReport.group?.totalTests || 0,
        //scheduledTests: this.resourceEfficiencyReport.group?.scheduledTests || 0,
        //scheduledTests: this.calculateScheduleTotalTestsCount(scheduleCountItems,appState.location.id),
        scheduledTests: this.calculateGroupScheduledTestCount(groupSensorIds, scheduleCountItems),
        runtime: this.resourceEfficiencyReport.group?.runtime || 0,
        name: appState.group?.group_name || 'N/A'
      };
    }

    return {
      totalTests: this.resourceEfficiencyReport.totalTests,
      scheduledTests: this.calculateScheduleTotalTestsCount(scheduleCountItems, null),
      runtime: this.resourceEfficiencyReport.runtime,
      group: group
    };
  }

  startRuntimeCalculation(countsRes, scheduleCountItemsAggregates) {
    if (this.runtimeSubscription) {
      this.runtimeSubscription.unsubscribe();
    }

    this.runtimeSubscription = combineLatest([
      this.waterSourcesCachedService.getCached(),
      this.authService.getUser()?.role_id < 4 ? this.sensorGroupMappingService.getCached() : of([]),
      timer(0, 5 * 60 * 1000),

    ])
      .subscribe((result: [WaterSourceExtended[], Sensor_group_objects[], number]) => {
        const appState = this.appStateService.getAppState().value;
        this.resourceEfficiencyReport.runtime = this.calculateHoursToNow(result[0].map(src => src.sensor.installation_date));
        if (this.resourceEfficiencyReport.group) {
          const sensorsMap = {};
          result[0].map(src => {
            sensorsMap[src.sensor.id] = src;
          });
          this.resourceEfficiencyReport.group.runtime = this.calculateHoursToNow(
            result[1].filter(mapping => mapping.group_id === appState.group.id && sensorsMap[mapping.sensor_id])
              .map(mapping => sensorsMap[mapping.sensor_id].sensor.installation_date)
          );
        } else {
          // this.resourceEfficiencyReport.group.runtime = this.calculateHoursToNow([appState.location.installation_date])
          const appState = this.appStateService.getAppState().value;
          const groupRuntime = this.calculateHoursToNow([appState.location.sensor.installation_date]);
          if (this.resourceEfficiencyReport.group === undefined && this.authService.getUser()?.role_id >= 4) {
            this.resourceEfficiencyReport.group = {
              runtime: groupRuntime,
              totalTests: this.calculateGroupTestCount(countsRes, appState.location.sensor_id) || 0,
              //scheduledTests: this.resourceEfficiencyReport.group?.scheduledTests || 0,
              scheduledTests: this.calculateGroupScheduledTestCount([appState.location.sensor_id],scheduleCountItemsAggregates),
              name: ''
            };
          }
        }
      });
    this.subscribers.push(this.runtimeSubscription);
  }



  calculateHoursToNow(timeStrings: string[]): number {
    let hours = 0;
    const now = new Date().getTime();
    for (const timeString of timeStrings) {
      const past = new Date(timeString).getTime();
      hours += (now - past) / (60 * 60 * 1000);
    }
    return hours;
  }

  ngOnDestroy() {
    this.subscribers.forEach((s: Subscription) => s.unsubscribe());
  }
}
