import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {AppStorage} from '../utils/AppStorage';
import {KetosPopupService} from './ketos-popup.service';

@Injectable({
  providedIn: 'root'
})
export class HTTPStatus {
  public hideOverlayForEnd: string;
  public passErrorForEndpoint: string;
  public filteredCountSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  public filteredReqCount = 0;
  public forceOverlaySubject: Subject<boolean> = new Subject()
  constructor() {
  }
}
