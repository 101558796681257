/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {forkJoin} from 'rxjs';
import {AppStateService} from '../../services/app-state.service';
import {AppStorage} from '../../utils/AppStorage';
import {ExperimentConfInterface} from '../../services/experiment-configurations.service';
import {Aggregate_result} from '../../../../../api/src/lib/models/aggregate-_result';
import { UiPlatformService } from '../../services/ui-platform.service';
import { ModalController } from '@ionic/angular';
import { AlertSettingModalComponent } from '../alert-setting-modal/alert-setting-modal.component';

export interface RealTimeDataInterface extends ExperimentConfInterface {
  index: number;
  icon: string;
  unit: string;
  title: string;
  settings: boolean;
  settingsOpen?: boolean;
  epaLimit?: {
    US: number,
    Mexico: number,
    India: number
  };
  threshold?: number;
  thresholdInternal?: number;
  threshold_key: string;
  bdl: number;
  adl: number;
  key: string;
  type: 'parameters' | 'metals' | 'nutrients' | 'inorganics' | 'organics';
  oldType: 'water' | 'toxins';
  value?: Aggregate_result;
  latestResult?: number;
  latestResutTimestamp?: string;
  thresholdLimitMin?: number;
  thresholdLimitMax?: number;
  titleStatus?: string;
  alertStatus?: boolean
}

export interface ChordValueInterface {
  day: string;
  location_name: string;
  timestamp: string;
  volume: string;
}

export interface ChordDataInterface extends RealTimeDataInterface {
  settings: boolean;
  icon: string;
  info: string;
  value?: Aggregate_result;
  latestResult?: number;
  key: string;
  listHeaders?: ChordValueInterface;
  valuesList?: ChordValueInterface[];
  subtitle?: string;
  status?: string;
  titleStatus?: string;
  maxim?: string;
  minim?: string;
  threshold?: number;
  thresholdInternal?: number;
  alerts_higher_threshold?: number;
  alerts_lower_threshold?: number;
  sensorId: string;
  selectedSensorType: string;
  epaLimit?: {
    US: number;
    Mexico: number;
    India: number;
  };
  isInput?: boolean;
  inputValue?: number;
}

@Component({
  selector: 'lib-chord',
  templateUrl: './chord.component.html',
  styleUrls: ['./chord.component.scss']
})
export class ChordComponent  {
  private _editSettings: boolean;

  @Input() downloadCSVForUStudios = false
  @Input()
  set editSettings(visible: boolean) {
    this._editSettings = visible;
  }
  get editSettings(): boolean {
    return this._editSettings;
  }
  @Input() showMinMaxAvg = true;
  @Input() showAvg = false;
  @Input() valuePointer = false;
  mobileChordName;
  private _data: ChordDataInterface;
  @Input() set data(value: ChordDataInterface) {
    if (this.editSettings === true) {
      return;
    }
    if (!value) {
      return;
    }
    if (typeof value.info === 'string') {
      // @TODO: reimplement
      this.chordName = value.info;
      this.mobileChordName = value.titleShort
    }
    this._data = Object.assign({}, value);
  }

  get data(): ChordDataInterface {
    return this._data;
  }

  @Input() mobileUI = false;
  @Input() mobileNarrow: boolean;
  @Input() sensorBitmap: string;
  @Output() chordWrapClick: EventEmitter<any> = new EventEmitter();
  @Output() valueClick: EventEmitter<ChordDataInterface> = new EventEmitter();

  @Output() changeAlertStatus = new EventEmitter();
  public chordName: string;
  public userData: any;
  isAlertEnabled = false;
  constructor(
    private appStateService: AppStateService,
    public uiPlatformService: UiPlatformService,
    public modalController: ModalController,
  ) {
    this.userData = JSON.parse(AppStorage.getItem('user'));
  }
  ngOnInit() {
  }
  canEdit(): boolean {
    if (this.data.titleStatus === '(Upcoming)') {
      return false;
    }
    const role = this.userData.roleName.toLocaleLowerCase();
    return ['superadmin', 'orgadmin', 'groupadmin'].indexOf(role) !== -1;
  }
  async openALertSetttingsModal(exp,data){
    const modal = await this.modalController.create({
      component: AlertSettingModalComponent,
      cssClass: 'setting-modal',
      componentProps: {
        data: data,
        exp:exp,
      }
    });
    modal.onDidDismiss()
      .then((res:any) => {
        console.log(res,'resDismiss')
        this.changeAlertStatus.emit()
        if(res?.data?.alertSettingSubmitted) {
        forkJoin([
          this.appStateService.thresholdsExternalService.getCached(false, true),
        ]).subscribe(res2 => {
         });
        }
      });
    await modal.present();

  }
}
