import {Injectable, OnInit} from '@angular/core';
import {Socket} from 'ngx-socket-io';

import {Observable, Subject} from 'rxjs';
import {finalize, share} from 'rxjs/operators';

// Enum for different types of WebSocket events
export enum KetosSocketEventEnum {
  'shield_threshold' = 'shield_threshold',          // use org_id
  'sensor_group_mapping' = 'sensor_group_mapping',  // use org_id
  'group_master' = 'group_master',                  // use org_id
  'shield_sensor' = 'shield_sensor',                // use org_id
  'shield_device' = 'shield_device',                // use org_id
  'water_source' = 'water_source',                  // use org_id
  'sensor' = 'sensor',                              // use org_id
  'shield_results' = 'shield_results',  // use sensor_id
  'shield_test_schedule' = 'shield_test_schedule',  // use sensor_id
  'shield_scheduled_test' = 'shield_scheduled_test',  // use sensor_id
  'shield_tests' = 'shield_tests',  // use sensor_id
  'shield_alerts'= 'shield_alerts', // use sensor_id
  'cp_user' = 'cp_user',
  'cp_user_settings' = 'cp_user_settings',
  'organization_settings' = 'organization_settings'
}

// Actions to be taken on the WebSocket server
export enum KetosSocketActionEnum {
  'write' = 'write',
  'insert' = 'insert',
  'update' = 'update',
  'patch' = 'patch',
  'delete' = 'delete'
}

// Interface for messages sent through the Ketos socket, including action,
// event type, message content, organization ID, room, and sensor ID.
export interface MessageInterface {
  action: KetosSocketActionEnum;
  event_type: KetosSocketEventEnum;
  message: any;
  organization_id: number;
  room: string;
  sensor_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class KetosSocketService {
  private roomSubjectMap: { [key: string]: Subject<MessageInterface> } = {};
  private roomObserverMap: { [key: string]: Observable<MessageInterface> } = {};

  private socket: Socket;

  constructor() {
  }

  // Create a new WebSocket connection
  private createSocket(token: string, tokenKey: string, url: string): Socket {
    const tokenObj = {};
    tokenObj[tokenKey] = token;
    const socket = new Socket({
      url: url, options: {
        transports: ['websocket'], upgrade: false, query: tokenObj
      }
    });
    // Handle WebSocket connection event
    socket.on('connect', () => {
      console.debug('socket connected');
      if (this.roomObserverMap && Object.keys(this.roomObserverMap).length > 0) {
        for (const roomName of Object.keys(this.roomObserverMap)) {
          console.debug('re-join room: ', roomName);
          socket.emit('join', {
            json: {'event_name': roomName},
            path: '/messages'
          });
        }
      }
    });
    // Handle incoming WebSocket messages
    socket.on('message', (data: MessageInterface) => {
      if (this.roomSubjectMap.hasOwnProperty(data.room)) {
        if (typeof data.message === 'string') {
          data.message = JSON.parse(data.message);
        }
        // data.message = JSON.parse(data.message);
        this.roomSubjectMap[data.room].next(data);
      } else {
        // console.debug('message from unused room:', data);
      }
    });
    return socket;
  }

  // Connect to the WebSocket server
  public connectSocket(url: string, token: string, tokenKey = 'cp_token') {
    // console.debug('connect socket', url, token,  tokenKey)
    this.disconnectSocket();
    this.socket = this.createSocket(token, tokenKey, url);
  }
  
  // Disconnect from the WebSocket server
  public disconnectSocket() {
    if (this.socket) {
      this.socket.disconnect(true);
      this.socket.removeAllListeners();
      setTimeout(() => {
        this.socket = null;
      }, 1000);
    }
  }

   // Get an observable for a specific room
  getSubjectForRoom(sensorOrOrgId: number, eventType: KetosSocketEventEnum, portal = 'cp'): Observable<MessageInterface> {
    let roomName;
    //=  portal === 'ip' ? 'internal/' + sensorOrOrgId + '/' + eventType : sensorOrOrgId + '/' + eventType
    if (portal === 'ip') {
      roomName = 'internal/' + sensorOrOrgId + '/' + eventType;
    } else {
      roomName = sensorOrOrgId + '/' + eventType;
    }
    console.debug(portal, roomName);
     // Check if an observable for the room already exists, if not create a new
    if (this.roomObserverMap[roomName]) {
      return this.roomObserverMap[roomName];
    } else {
      console.debug('join room: ', roomName);
      this.socket?.emit('join', {
        json: {'event_name': roomName},
        path: '/messages'
      });
     // Create a new Subject for the room
     this.roomSubjectMap[roomName] = new Subject<MessageInterface>();
     this.roomObserverMap[roomName] = this.roomSubjectMap[roomName].pipe(
        // Finalize operator to handle cleanup when the observable is unsubscribed
        finalize(() => {
          console.debug('leave room:', roomName);
          // Emit a leave event to the WebSocket server
          this.socket?.emit('leave', {
            json: {'event_name': roomName},
            path: '/messages'
          });
          delete this.roomSubjectMap[roomName];
          delete this.roomObserverMap[roomName];
        }),
        share()
      );
    }
    return this.roomObserverMap[roomName];
  }
}
