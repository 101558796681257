import { Injectable } from '@angular/core';
import {AppStorage} from '../utils/AppStorage';
import {throwError} from 'rxjs';
import {KetosPopupService} from './ketos-popup.service';
import {KetosAuditService} from './ketos-audit.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class KetosErrorService {

  constructor(private auditService: KetosAuditService,
              private popupService: KetosPopupService,
              private router: Router,) {
  }

  showConfirmPopupForError(error: any, overrideTitle: string = null, overrideMessage: string = null): Promise<any> {
    let message = 'Something went wrong, please try again after some time';
    let title = 'Error';
    let postAuditEvent = true;
    if(error.status === 400) {
      message = error.error.message
    }
    if (error.status === 401) {
      this.auditService.setUserName(AppStorage.getItem('tempuser'));
      postAuditEvent = AppStorage.getItem('tempuser') !== null;
      message = 'Incorrect Username or Password';
      if (error.error?.status === 'NOT_AUTHORIZED') {
        // if(error.error?.message.includes("not supported")) {
        //   title = '';
        //   message = 'Current User Role Does not have access to this Page';
        // }
        if (error.error?.message === 'Unable to read auth token' || error.error?.message === 'User not found in RDS') {
          localStorage.clear();
          this.router.navigate(['/login'])
            .then(() => {
              window.location.reload();
            });
          console.log('Logged out due to auth error', error);
        } else if (error.error?.message === 'API not supported for the given role') {
          title = '';
          message = 'Current User Role Does not have access to this Page';
        } else if (error.error?.message === 'API is not supported for Given Role ') {
          title = '';
          message = 'Current User Role Does not have access to this Page';
        }
          //else if (error.error?.message === 'UnAuthorized User') {
          //   title = ''
          //   message = 'Incorrect Username or Password';
        // }
        else {
          message = error.error.message;
        }
      }
    }
    if (error.status === 404) {
      if (error.error?.status === 'USER_NOT_FOUND') {
        console.log(error);
        if (error.error?.message === 'User does not exist in the system') {
          console.log('Logged out due to auth error', error);
          localStorage.clear();
          message = 'Incorrect Username or Password';
          //Observable.throw(error)
          // this.router.navigate(['/login'])
          //   .then(() => {
          //     window.location.reload(true);
          //   });
          console.log('Logged out due to auth error', error);
        }
      }
      else{
        message = error.error.message;
      }
    }
    if (error.status === 422) {
      if (typeof error.error === 'string') {
        message = error.error;
      }

      if (error.error?.status === 'INVALID_FIELD') {
        if (error.error?.message.includes('ChangePassword')) {
          title = '';
          message = 'Incorrect current password';
        }
        if (error.error?.message.includes('maintenance')) {
          title = '';
          message = 'The Sensor is under Maintenance! Please try some other sensor';
        }
        if (error.error?.message.includes('confirmForgotPassword') || error.error?.message.includes('ConfirmForgotPassword')) {
          title = '';
          if (error.error?.message.includes('UserNotFoundException')) {
            message = 'Invalid Email Id';
          } else {
            message = 'Incorrect Validation Code';
          }
        }
        if (error.error?.message.includes('Invalid password Parameter validation failed')) {
          title = '';
          message = 'Incorrect current password';
          console.log('Logged out due to auth error', error);
          return Promise.reject(message);
        }
        if (error.error?.message.includes('This Group is associated with user')) {
          title = '';
          message = "Cannot delete group, it is associated with a user"
        }
      }
    }
    if (error.status === 409) {
      if (error.error?.message?.includes('User Already exists')) {
        title = 'Email Conflict';
        message = 'User with this email address already exists. Please sign in.';
      }
      else{
        message = error.error.message;
      }
    }
    // if (postAuditEvent && !this.auditService.isAuditUrl(req.url)) {
    //   // this.auditService.postAuditEvent(req.method + ':' + req.urlWithParams, 'API Interceptor', 'API Error:' + error.status, JSON.stringify(error));
    //   return throwError(error);
    // }

    if (error.status === 455) {
      if (error.hasOwnProperty('error') && error.error.hasOwnProperty('Action')) {
        message = error.error.Action;
      } else if (error.hasOwnProperty('error') && typeof error.error === 'string') {
        message = error.error;
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('msg')) {
        // @todo Standardize the message for 455
        message = error.error.msg;
      } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('message')) {
        // @todo Standardize the message for 455
        message = error.error.message;
      } else {
        message = error.message;
      }
    }

    if (typeof overrideMessage === 'string') {
      message = overrideMessage;
    }
    if (typeof overrideTitle === 'string') {
      title = overrideTitle;
    }
    // else{
    //   console.log(3)

    //   message = error.error.message;
    // }
    // See https://stackoverflow.com/questions/58020180/getting-neterr-http2-protocol-error-in-angular-7-app
    if (error.status !== 0) {  // TODO Returns net::ERR_HTTP2_PROTOCOL_ERROR
      return this.popupService.confirmOkPopup({title: title, message: message}, 'dark-alert');
    }
  }
}
