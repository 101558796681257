/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Oq_results } from '../models/oq-_results';

/**
 * oq result
 */
@Injectable({
  providedIn: 'root',
})
class OqResultService extends __BaseService {
  static readonly getOqResultPath = '/oq_result';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OqResultService.GetOqResultParams` containing the following parameters:
   *
   * - `ids`: List of oq result ids
   *
   * - `oq_report_ids`: list of oq report ids
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOqResultResponse(params: OqResultService.GetOqResultParams): __Observable<__StrictHttpResponse<Oq_results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.oqReportIds || []).forEach(val => {if (val != null) __params = __params.append('oq_report_ids', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/oq_result`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Oq_results>;
      })
    );
  }
  /**
   * @param params The `OqResultService.GetOqResultParams` containing the following parameters:
   *
   * - `ids`: List of oq result ids
   *
   * - `oq_report_ids`: list of oq report ids
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOqResult(params: OqResultService.GetOqResultParams): __Observable<Oq_results> {
    return this.getOqResultResponse(params).pipe(
      __map(_r => _r.body as Oq_results)
    );
  }
}

module OqResultService {

  /**
   * Parameters for getOqResult
   */
  export interface GetOqResultParams {

    /**
     * List of oq result ids
     */
    ids?: Array<number>;

    /**
     * list of oq report ids
     */
    oqReportIds?: Array<number>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { OqResultService }
