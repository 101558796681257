/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import {AppStorage} from '../../utils/AppStorage';
import {ILoginResponse} from '../auth.service';
import {CONSTANTS} from '../../../../../shared/constants_pro';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public static environment(email?: string) {
    if (environment.production) {
      return environment;
    }
    const user = JSON.parse(AppStorage.getItem('user'));
    const prodEmail = AppStorage.getItem('prod-email');
    if (prodEmail && (user && user.username === prodEmail && user.roleName === 'SuperAdmin' || prodEmail === email)) {
      return {
        production: environment.production,
        key: environment.key,
        CONSTANTS: CONSTANTS
      };
    }
    return environment;
  }

  constructor() {
    const selectedUser = JSON.parse(AppStorage.getItem('selectedUser'));
    if (selectedUser) {
      this.impersonateOwner(selectedUser.ownerId.OWNER_ID);
      this.impersonateUser(selectedUser.ownerId.USER_ID);
    }
  }

  private impersonateOwnerId: number;
  private impersonateUserId: number;

  public httpApiRequestsHeaders = {
    'Authorization': '',
    'Content-Type': 'application/json'
  };

  public userToken: string;

  public userRole = '';
  public userName = '';
  public defaultLocationName = '';

  public impersonateOwner(ownerId: number) {
    this.impersonateOwnerId = ownerId;
  }

  public impersonateUser(userId: number) {
    this.impersonateUserId = userId;
  }


  public set ownerId(ownerId) {
    const userData: ILoginResponse = JSON.parse(AppStorage.getItem('user'));

    userData.OwnerId = ownerId;
    AppStorage.setItem('user', JSON.stringify(userData));
  }

  public get ownerId(): number | null {
    if (this.impersonateOwnerId) {
      return this.impersonateOwnerId;
    }

    const userData = JSON.parse(AppStorage.getItem('user'));
    if (userData !== null && userData.hasOwnProperty('OwnerId')) {
      return userData['OwnerId'];
    }

    return null;
  }

  public set userId(userId) {
    const userData = JSON.parse(AppStorage.getItem('user'));

    userData.userId = userId;
    AppStorage.setItem('user', JSON.stringify(userData));
  }

  public get userId(): number | null {
    if (this.impersonateUserId) {
      return this.impersonateUserId;
    }

    const userData = JSON.parse(AppStorage.getItem('user'));
    if (userData !== null && userData.hasOwnProperty('UserId')) {
      return userData['UserId'];
    }

    return null;
  }

  public getWaveAlertsUrl(alertId?, comment?): string {
    let url = `${AppService.environment().CONSTANTS.API_URL2}wavealerts/${this.ownerId}/?user_id=${this.userId}`;
    if (alertId) {
      url = url.concat('&alert_id=' + alertId);
      if (comment) {
        url = url.concat('&comment=' + encodeURIComponent(comment));
      }
    }
    return url;
  }

  public getWaveSensorsLocationsUrl(pageNumber, polling = false, csv = false): string {
    let url = `${AppService.environment().CONSTANTS.API_URL2}waveall/${this.ownerId}/?user_id=${this.userId}`;

    if (pageNumber) {
      url = url.concat(`&page_number=${pageNumber}`);
    }
    if (polling === true) {
      url = url.concat(`&isPolling=true`);
    }
    if (csv === true) {
      url = url.concat(`&csv=1`);
    }

    return url;
  }

  public getShieldThresholdsUrl(sensorId): string {
    return `${AppService.environment().CONSTANTS.API_SHIELD_URL}get_shield_thresholds/${this.ownerId}/${sensorId}/`;
  }

  public getShieldDropdownLocationsUrl(polling = false, csv = false): string {
    let url = `${AppService.environment().CONSTANTS.API_SHIELD_URL}shield_sensor_details/${this.ownerId}/?user_id=${this.userId}`;

    if (polling === true) {
      url = url.concat(`&isPolling=true`);
    }

    if (csv === true) {
      url = url.concat(`&csv=1`);
    }

    return url;
  }

  public setShieldThresholdUrl(sensorId): string {
    return `${AppService.environment().CONSTANTS.API_SHIELD_URL}set_threshold/${this.ownerId}/${sensorId}/`;
  }
  public setShieldThresholdInternalUrl(sensorId): string {
    return `${AppService.environment().CONSTANTS.API_SHIELD_URL}set_threshold_internal/${this.ownerId}/${sensorId}/`;
  }

  public setAllShieldThresholdUrl(sensorId): string {
    return `${AppService.environment().CONSTANTS.API_SHIELD_URL}set_threshold/${this.ownerId}/${sensorId}/?locations=all`;
  }
  public setAllShieldThresholdInternalUrl(sensorId): string {
    return `${AppService.environment().CONSTANTS.API_SHIELD_URL}set_threshold_internal/${this.ownerId}/${sensorId}/?locations=all`;
  }

  public getIcebergThresholdUrl(sensorId){
    return `${AppService.environment().CONSTANTS.API_ICEBERG_URL}ice/threshold/${this.ownerId}/${sensorId}/`;

  }

  public getIcebergLocationsUrl(polling = false, csv = false): string {
    let end = polling ? '?isPolling=true' : '';
    if (csv === true) {
      end += '?csv=1';
    }
    return `${AppService.environment().CONSTANTS.API_ICEBERG_URL}ice/${this.userId}/${this.ownerId}/${end}`;
  }

  public getIcebergAlertsUrl(alertId?, comment?): string {
    let url = `${AppService.environment().CONSTANTS.API_URL2}icealerts/${this.ownerId}/`;
    if (alertId) {
      url = url.concat('?alert_id=' + alertId);
      if (comment) {
        url = url.concat('&comment=' + encodeURIComponent(comment));
      }
    }
    return url;
  }

  getWaveShutOffValveUrl(sensorId: string): string {
    return `${AppService.environment().CONSTANTS.API_URL2}shutoff/${this.ownerId}/${sensorId}/`;
  }

  getWaveConnectivityUrl(): string {
    return `${AppService.environment().CONSTANTS.API_URL2}wave_connectivity/${this.ownerId}/?user_id=${this.userId}`;
  }

  getHubsUrl(): string {
    return `${environment.CONSTANTS.API_URL2}get_hubs/${this.ownerId}/`;
  }

  getRegistrationUrl(type): string {
    return `${environment.CONSTANTS.API_URL2}register/${type}/`;
  }
}
