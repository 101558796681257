import {AfterViewInit, Component, OnInit, ViewChild,Input} from '@angular/core';
import * as Highcharts from 'highcharts';
const HighchartsMore = require("highcharts/highcharts-more.src");
HighchartsMore(Highcharts);
const HC_solid_gauge = require("highcharts/modules/solid-gauge.src");
HC_solid_gauge(Highcharts);
import { Chart, ChartOptions } from 'chart.js';
import {DropdownOptionsInterface, DropdownValueInterface} from "../../../../components/shared/dropdown/models/dropdown-options.interface";
import { CropService, CropsInterface } from '../../nutrient-management.service';
import { Subscription } from 'rxjs';
import * as cloneDeep from 'lodash/cloneDeep';
import {boronDataValues,chlorineDataValues,nitrateDataValues} from './nutrient-mockup-data'
import { SeasonService } from '../nutrient-season-service';
(function(highcharts) {
  highcharts.seriesType('lowmedhigh', 'boxplot', {
    keys: ['low', 'median', 'high'],
    tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: ' +
            'Low <b>{point.low}</b> - Median <b>{point.median}</b> - High <b>{point.high}</b><br/>'
    }
}, {
    // Change point shape to a line with three crossing lines for low/median/high
    // Stroke width is hardcoded to 1 for simplicity
    drawPoints: function () {
        var series = this;
        Highcharts.each(this.points, function (point) {
            var graphic = point.graphic,
                verb = graphic ? 'animate' : 'attr',
                shapeArgs = point.shapeArgs,
                width = shapeArgs.width,
                left = Math.floor(shapeArgs.x) + 0.5,
                right = left + width,
                crispX = left + Math.round(width / 2) + 0.5,
                highPlot = Math.floor(point.highPlot) + 0.5,
                medianPlot = Math.floor(point.medianPlot) + 0.5,
                lowPlot = Math.floor(point.lowPlot) + 0.5 - (point.low === 0 ? 1 : 0); // Sneakily draw low marker even if 0

            if (point.isNull) {
                return;
            }

            if (!graphic) {
                point.graphic = graphic = series.chart.renderer.path('point').add(series.group);
            }

            graphic.attr({
                stroke: point.color || series.color,
                "stroke-width": 1
            });

            graphic[verb]({
                d: [
                    'M', left, highPlot,
                    'H', right,
                    'M', left, medianPlot,
                    'H', right,
                    'M', left, lowPlot,
                    'H', right,
                    'M', crispX, highPlot,
                    'V', lowPlot
                ]
            });
        });
    }
})})(Highcharts)
@Component({
  selector: 'app-nutrient-dynamic-shield-data',
  templateUrl: './nutrient-dynamic-shield-data.component.html',
  styleUrls: ['./nutrient-dynamic-shield-data.component.scss']
})

export class NutrientDynamicShieldDataComponent implements OnInit {

  @Input() selectedSeason:string;
  @Input() selectedYear:string;
 // @ViewChild('canvas', {static: true}) canvas;
  subsrciption = new Subscription();
  placeHolderImage;
  nutientOptions={
    values:[
      // {title:'All Contaminants',value:'All_Contaminants'},
      {title: 'Boron', value: "Boron"},
      {title: 'Chlorine', value: "Chlorine"},
      {title: 'Nitrate', value: "Nitrate"}
    ]
  }
  sheildOptions={
    values:[
      {title:'Shield1',value:'Shield1'},
      {title:'Shield2',value:'Shield2'},
      // {title:'Shield3',value:'Shield3'},
      // {title:'All Shields', value: 'All_Shields'}
    ]
  }
  selectedSheild = this.sheildOptions.values[0].value;
  selectedNutrient = this.nutientOptions.values[0].value;
  selectedNutrientDatavalue = []
  updateFlag;

  chart;
  chartCallback;
  title = 'myHighchart';
  highcharts = Highcharts;
  chartConstructor = "chart";
  chartOptions:any={
    chart: {
    type: 'lowmedhigh',
    backgroundColor:'#242434',
    className:'shieldChartContainer'
    },
    title:{},
    xAxis:[],
    yAxis:{},
    tooltip: {
      shared: true
    },
    plotOptions: {
      series: {
        stickyTracking: true,
        whiskerWidth: 5
      }
    },
    series:[],

  }
  dataValues:any;

  constructor(private seasonService:SeasonService ) {
    console.log(this.selectedSeason)

   // this.loadPlaceholderData()
   const self = this;

    this.chartCallback = chart => {
      // saving chart reference
      self.chart = chart;
    };
   }
   ngOnInit(){
     this.loadGraph(this.selectedYear,this.selectedSeason)
   }
   loadGraph(year,season){
     //this.dataValues = dataValues
    let titleText;
    this.subsrciption = this.seasonService.data.subscribe((data:any) => {
      console.log(data,"data")
      year= data.year?data.year:this.selectedYear;
     //console.log(boronDataValues.Shield1[season].xAxis[0].categories)
     if(data){
       if(data.title){
          season =data.title;
          if(this.selectedNutrient === "Boron"){
            this.chartOptions = cloneDeep(boronDataValues[this.selectedSheild][season]);
          }else if(this.selectedNutrient === "Chlorine"){
            this.chartOptions = cloneDeep(chlorineDataValues[this.selectedSheild][season]);
          }else{
            this.chartOptions = cloneDeep(nitrateDataValues[this.selectedSheild][season]);
          }

          titleText = season === 'Annual'?`${this.selectedSheild} ${this.selectedNutrient} Annual`:`${this.selectedSheild} ${this.selectedNutrient} ${season} ${year}`
          this.chartOptions.title = {
                text: titleText,
                style:{color:'#ffffff'}
              };
          this.updateFlag = true;
        }
        if(data.year){
          this.selectedYear= data.year
         // this.chartOptions = cloneDeep(boronDataValues[this.selectedSheild][season]);
          titleText = season === 'Annual'?`${this.selectedSheild} ${this.selectedNutrient} Annual`:`${this.selectedSheild} ${this.selectedNutrient} ${season} ${data.year}`
          this.chartOptions.title = {
                text: titleText,
                style:{color:'#ffffff'}
              };
          this.updateFlag = true;
        }
     }else{
      titleText = season === 'Annual'? 'Shield1 Boron Annual': 'Shield1 Boron '+ season+" "+year

     }
    })

    //titleText = `${season} === 'Annual'? '${this.selectedSheild} ${this.selectedNutrient} Annual': '${this.selectedSheild} ${this.selectedNutrient} '+ ${season}+" "+${year}`;

    this.chartOptions =  {

      chart: {
        type: 'lowmedhigh',
        backgroundColor:'#242434',
        className:'shieldChartContainer'
        },
      title: {
        text: titleText,
        style:{
          color:'#ffffff'
        }
      },
      xAxis: [{
        lineColor: '#ffffff',
        tickColor: '#ffffff',
        labels:{
          style:{
            color:'#ffffff'
          }
        },
        style:{
          color:'#ffffff'
        },
        accessibility: {
          description: 'Months of the year',
          style:{
            color:'#ffffff'
          }
        },
        categories:boronDataValues.Shield1[season].xAxis[0].categories
        //categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      }],
      yAxis: {
        title: {
          text: 'Contaminant Concentration (ppb)',
          style:{
            color:'#ffffff'
          }
        },
        style:{
          color:'#ffffff'
        },
        labels:{
          style:{
            color:'#ffffff'
          }
        },
        min: 0
      },
      legend:{
        itemStyle:{
          color:'#7cb5ec'
        }
       },
      series:[{name:boronDataValues.Shield1[season].series[0].name,data:[...boronDataValues.Shield1[season].series[0].data]}]
    };

   }

  updateSelectedSheild(data){
    this.selectedSheild=data.value
    this.updateSelectedNutrient(this.selectedNutrient);
  }
  updateSelectedNutrient(data:any){

    console.log(data)
    this.selectedNutrient=data.value?data.value:this.selectedNutrient;
    const self = this,
      chart = this.chart;
      //self.chart = {}
    switch(this.selectedNutrient){
      case "Boron":
        self.chartOptions = cloneDeep(boronDataValues[this.selectedSheild][this.selectedSeason])
        break;
      case "Chlorine":
        self.chartOptions = cloneDeep(chlorineDataValues[this.selectedSheild][this.selectedSeason])
        break;
      case "Nitrate":
        self.chartOptions = cloneDeep(nitrateDataValues[this.selectedSheild][this.selectedSeason])
        break;
      default:
        //this.selectedNutrientDatavalue.push(nutrientDataValues);
    }

  //   if(this.selectedNutrient == "Boron"){
  //     //self.chartOptions = cloneDeep(this.dataValues.boronDataValues[this.selectedSheild][this.selectedSeason])
  //     let BoronName = boronDataValues.Shield1.Spring.series[0].name;
  //     let BoronData = [...boronDataValues.Shield1.Spring.series[0].data];
  //     var obj = Object.assign({},{name:BoronName,data:BoronData})
  //     console.log(boronDataValues.Shield1.Spring.series[0],"L")
  //     //self.chartOptions.series =Object.assign([],[{...obj}] )
  //   }else if(this.selectedNutrient == "Chlorine"){
  //    // self.chartOptions = cloneDeep(this.dataValues.chlorineDataValues[this.selectedSheild][this.selectedSeason])
  //    let BoronName = chlorineDataValues.Shield1.Spring.series[0].name;
  //    let BoronData =[...chlorineDataValues.Shield1.Spring.series[0].data];
  //    var obj = Object.assign({},{name:BoronName,data:BoronData})
  //   // console.log(chlorineDataValues.Shield1.Spring.series[0],"m")
  //  // self.chartOptions.series =Object.assign([],[{...obj}] )

  //           //alert(i);
  //           //chartBench.series[i].setData(my_data[i].data);

  //   }else{
  //     self.chartOptions = nitrateDataValues[this.selectedSheild][this.selectedSeason];

  //   }
    console.log(self.chartOptions)
    //chart.showLoading();
  //setTimeout(() => {
      //chart.hideLoading();
    //  console.log(this.selectedNutrientDatavalue,"---",`${nutrientDataValues}.${this.selectedSheild}[0]`)
    //   if(this.selectedNutrientDatavalue.length == 1){
    //     self.chartOptions.series = [{name:this.selectedNutrientDatavalue[0].name,data:this.selectedNutrientDatavalue[0].data}];
    //   }else{
    //     self.chartOptions.series = nutrientDataValues.Shield1;
    //   }
    //   self.chartOptions.title = {
    //     text: `${this.selectedSheild} ${this.selectedNutrient} Concentration (Monthly, ppb)`,
    //     style:{color:'#ffffff'}
    //   };

    let titleText = this.selectedSeason === 'Annual'?`${this.selectedSheild} ${this.selectedNutrient} Annual`:`${this.selectedSheild} ${this.selectedNutrient} ${this.selectedSeason} ${this.selectedYear}`
    self.chartOptions.title = {
          text: titleText,
          style:{color:'#ffffff'}
        };

    //self.chartOptions.series =[obj]
      self.updateFlag = true;
  // }, 500);

  }
  ngOnDestroy(){
    this.subsrciption.unsubscribe();
  }
}

