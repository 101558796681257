import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs'
import {NutrientManagementComponent} from "./nutrient-management.component";
import {NutrientSeasonComponent} from "./nutrient-season/nutrient-season.component";
import {RouterModule, Routes} from "@angular/router";
import { NutrientCropsGrownComponent } from './nutrient-crops-grown/nutrient-crops-grown.component';
import { NutrientFarmAcresComponent } from './nutrient-farm-acres/nutrient-farm-acres.component';
import { NutrientWellsComponent } from './nutrient-wells/nutrient-wells.component';
import { NutrientShieldMonitoringComponent } from './nutrient-shield-monitoring/nutrient-shield-monitoring.component';
import { NutrientDynamicShieldDataComponent } from './nutrient-dynamic-shield-data/nutrient-dynamic-shield-data.component';
import { NutrientChemicalsComponent } from './nutrient-chemicals/nutrient-chemicals.component';
import { NutrientSoilHealthComponent } from './nutrient-soil-health/nutrient-soil-health.component';
import {SharedModule} from "../../../components/shared/shared.module";
import {IonRangeSliderModule} from '../../modeller/range-slider/ion-range-slider.module';
import { NutrientForcastComponent } from './nutrient-forcast/nutrient-forcast.component';

const nutrientManagementRoutes: Routes = [
  {path: 'nutrient-season', component: NutrientSeasonComponent}
]

@NgModule({
  declarations: [
    NutrientManagementComponent,
    NutrientSeasonComponent,
    NutrientCropsGrownComponent,
    NutrientFarmAcresComponent,
    NutrientWellsComponent,
    NutrientShieldMonitoringComponent,
    NutrientDynamicShieldDataComponent,
    NutrientChemicalsComponent,
    NutrientSoilHealthComponent,
    NutrientForcastComponent
  ],
    imports: [
        RouterModule.forChild(nutrientManagementRoutes),
        SharedModule,
        MatTabsModule,
        CommonModule,
        IonRangeSliderModule
    ]
})
export class NutrientManagementModule { }
