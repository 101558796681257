/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Alert_check } from '../models/alert-_check';
import { Alerts_config } from '../models/alerts-_config';
import { Error_code } from '../models/error-_code';

/**
 * Notification/Alerting service related backend operations
 */
@Injectable({
  providedIn: 'root',
})
class NotificationServiceService extends __BaseService {
  static readonly CheckShieldAlertsInLast24HoursPath = '/notification_service/check_last_24_hour_alert/{sensor_id}/{experiment}/{alert_state}/{water_source_id}/';
  static readonly CheckLastShieldAlertsStateSentPath = '/notification_service/check_last_alert_state_sent/{sensor_id}/{alert_code}/';
  static readonly GetAlertsConfigPath = '/notification_service/config/{sensor_id}/{experiment}/{method}/{water_source_id}/';
  static readonly CheckShieldExpAlertsPath = '/notification_service/create_exp_alerts/{sensor_id}/';
  static readonly CheckShieldStatusAlertsPath = '/notification_service/create_new_status_alerts/{sensor_id}/';
  static readonly INTERNALProcessErrorCodeRequiredByEMRPath = '/notification_service/error_code/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Used by Lambda
   * @param params The `NotificationServiceService.CheckShieldAlertsInLast24HoursParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `experiment`:
   *
   * - `alert_state`:
   *
   * - `water_source_id`:
   */
  CheckShieldAlertsInLast24HoursResponse(params: NotificationServiceService.CheckShieldAlertsInLast24HoursParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/notification_service/check_last_24_hour_alert/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.experiment))}/${encodeURIComponent(String(params.alertState))}/${encodeURIComponent(String(params.waterSourceId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by Lambda
   * @param params The `NotificationServiceService.CheckShieldAlertsInLast24HoursParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `experiment`:
   *
   * - `alert_state`:
   *
   * - `water_source_id`:
   */
  CheckShieldAlertsInLast24Hours(params: NotificationServiceService.CheckShieldAlertsInLast24HoursParams): __Observable<null> {
    return this.CheckShieldAlertsInLast24HoursResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by Lambda
   * @param params The `NotificationServiceService.CheckLastShieldAlertsStateSentParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `alert_code`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  CheckLastShieldAlertsStateSentResponse(params: NotificationServiceService.CheckLastShieldAlertsStateSentParams): __Observable<__StrictHttpResponse<Alert_check>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/notification_service/check_last_alert_state_sent/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.alertCode))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Alert_check>;
      })
    );
  }
  /**
   * Used by Lambda
   * @param params The `NotificationServiceService.CheckLastShieldAlertsStateSentParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `alert_code`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  CheckLastShieldAlertsStateSent(params: NotificationServiceService.CheckLastShieldAlertsStateSentParams): __Observable<Alert_check> {
    return this.CheckLastShieldAlertsStateSentResponse(params).pipe(
      __map(_r => _r.body as Alert_check)
    );
  }

  /**
   * Used by Lambda
   * @param params The `NotificationServiceService.GetAlertsConfigParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `experiment`:
   *
   * - `method`:
   *
   * - `water_source_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAlertsConfigResponse(params: NotificationServiceService.GetAlertsConfigParams): __Observable<__StrictHttpResponse<Alerts_config>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/notification_service/config/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.experiment))}/${encodeURIComponent(String(params.method))}/${encodeURIComponent(String(params.waterSourceId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Alerts_config>;
      })
    );
  }
  /**
   * Used by Lambda
   * @param params The `NotificationServiceService.GetAlertsConfigParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `experiment`:
   *
   * - `method`:
   *
   * - `water_source_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAlertsConfig(params: NotificationServiceService.GetAlertsConfigParams): __Observable<Alerts_config> {
    return this.GetAlertsConfigResponse(params).pipe(
      __map(_r => _r.body as Alerts_config)
    );
  }

  /**
   * Used by Lambda
   * @param params The `NotificationServiceService.CheckShieldExpAlertsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  CheckShieldExpAlertsResponse(params: NotificationServiceService.CheckShieldExpAlertsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/notification_service/create_exp_alerts/${encodeURIComponent(String(params.sensorId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by Lambda
   * @param params The `NotificationServiceService.CheckShieldExpAlertsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  CheckShieldExpAlerts(params: NotificationServiceService.CheckShieldExpAlertsParams): __Observable<null> {
    return this.CheckShieldExpAlertsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by Lambda
   * @param params The `NotificationServiceService.CheckShieldStatusAlertsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  CheckShieldStatusAlertsResponse(params: NotificationServiceService.CheckShieldStatusAlertsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/notification_service/create_new_status_alerts/${encodeURIComponent(String(params.sensorId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by Lambda
   * @param params The `NotificationServiceService.CheckShieldStatusAlertsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  CheckShieldStatusAlerts(params: NotificationServiceService.CheckShieldStatusAlertsParams): __Observable<null> {
    return this.CheckShieldStatusAlertsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Required by EMR
   * @param payload undefined
   */
  INTERNALProcessErrorCodeRequiredByEMRResponse(payload: Error_code): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/notification_service/error_code/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Required by EMR
   * @param payload undefined
   */
  INTERNALProcessErrorCodeRequiredByEMR(payload: Error_code): __Observable<null> {
    return this.INTERNALProcessErrorCodeRequiredByEMRResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module NotificationServiceService {

  /**
   * Parameters for CheckShieldAlertsInLast24Hours
   */
  export interface CheckShieldAlertsInLast24HoursParams {
    sensorId: number;
    experiment: string;
    alertState: string;
    waterSourceId: number;
  }

  /**
   * Parameters for CheckLastShieldAlertsStateSent
   */
  export interface CheckLastShieldAlertsStateSentParams {
    sensorId: number;
    alertCode: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetAlertsConfig
   */
  export interface GetAlertsConfigParams {
    sensorId: number;
    experiment: string;
    method: string;
    waterSourceId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for CheckShieldExpAlerts
   */
  export interface CheckShieldExpAlertsParams {
    payload: Alerts_config;
    sensorId: number;
  }

  /**
   * Parameters for CheckShieldStatusAlerts
   */
  export interface CheckShieldStatusAlertsParams {
    payload: Alerts_config;
    sensorId: number;
  }
}

export { NotificationServiceService }
