import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {WaterSourcesCachedService} from '../../../services/water-sources-cached.service';
import {GroupCachedService} from '../../../services/group-cached.service';
import {Water_source} from '../../../../../../api/src/lib/models/water-_source';
import {forkJoin, ReplaySubject} from 'rxjs';
import {SensorGroupCachedService} from '../../../services/sensor-group-cached.service';
import {Group_master} from '../../../../../../api/src/lib/models/group-_master';
import {PopoverController} from '@ionic/angular';
import {take} from 'rxjs/operators';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'lib-grouped-sensor-popover',
  templateUrl: './grouped-sensor-popover.component.html',
  styleUrls: ['./grouped-sensor-popover.component.scss'],
})
export class GroupedSensorPopoverComponent implements OnInit {
  @Input() titleKey = 'title';
  @Input() selected: Water_source[] = [];
  @Input() showConfirm = false;
  @Input() maxSelected = Number.MAX_SAFE_INTEGER; // for efficiency use one number smaller
  @Input() showSelectAll = false;
  @Input() selectAllOnGroupClick = false;
  @Input() updateSelected: (selected: Water_source[]) => void;
  @Input() selectedGroups: Group_master[];
  @Input() groupsChanged: (groups: Group_master[]) => void;

  prevSelectedGroups: Group_master[];
  waterSources: Water_source[];
  selectedWaterSources: Water_source[];
  groupsEnabled = false;
  search: string;

  onInit = new ReplaySubject<boolean>(1);

  constructor(private authService: AuthService,
              public waterSourcesService: WaterSourcesCachedService,
              public groupsService: GroupCachedService,
              public sensorGroupCachedService: SensorGroupCachedService,
              private popoverController: PopoverController,
              private cd: ChangeDetectorRef,
  ) {
    this.groupsEnabled = authService.getUser().role_id < 3;
    if (this.groupsEnabled) {
      this.initWithGroups();
    } else {
      forkJoin([
        waterSourcesService.getCached(false).pipe(take(1)),
        this.onInit
      ])
        .subscribe(([sources, groups]) => {
          this.waterSources = waterSourcesService.model;
          if (this.selected) {
            const selectedIds = this.selected.map(sel => sel.id);
            this.selectedWaterSources = this.waterSources.filter(src => selectedIds.indexOf(src.id) !== -1);
          }
        });
    }
  }

  initWithGroups() {
    forkJoin([
      this.groupsService.getCached(false).pipe(take(1)),
      this.waterSourcesService.getCached(false).pipe(take(1)),
      this.sensorGroupCachedService.getCached(false).pipe(take(1)),
      this.onInit
    ])
      .subscribe(([sources, groups, sensorGroup, bl]) => {
        this.groupsSelected(null);

        if (this.selected) {
          const selectedIds = this.selected.map(sel => sel.id);
          this.selectedWaterSources = this.waterSources.filter(src => selectedIds.indexOf(src.id) !== -1);
        }
      });
  }

  ngOnInit() {
    this.onInit.next(true);
    this.onInit.complete();
  }

  groupsSelected(groups: Group_master[]) {
    if (this.groupsChanged) {
      this.groupsChanged(groups);
    }
    if (!groups || groups.length < 1) {
      this.waterSources = this.waterSourcesService.model;
      return;
    }
    const groupIds = groups.map(g => g.id);
    const sensorIds = this.sensorGroupCachedService.models
      .filter(mapObj => groupIds.indexOf(mapObj.group_id ) !== -1)
      .map(mapObj => mapObj.sensor_id);
    const waterSources = this.waterSourcesService.model
      .filter(src => sensorIds.indexOf(src.sensor.id) !== -1);
    if (!(this.selectedGroups?.length === 0 && this.prevSelectedGroups.length === 1)) {
      if (this.selectAllOnGroupClick) {
        this.selectedWaterSources = waterSources.slice();
      }
      this.waterSources = waterSources;
    }
    this.prevSelectedGroups = groups.slice();
  }

  waterSourcesSelected(waterSources: Water_source[]) {
    this.updateSelected(waterSources);
  }

  clear() {
    this.selectedWaterSources = [];
  }

  selectPressed() {
    // this.updateSelected(this.selected);
    if (this.selectedWaterSources?.length > 0) {
      this.popoverController.dismiss(this.selectedWaterSources);
    }
    if (this.waterSources?.length > 0) {
      this.popoverController.dismiss(this.waterSources);
    }
  }

  cancel() {
    this.popoverController.dismiss();
  }
}
