import {Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {SingleSelectPopoverComponent} from '../single-select-popover.component';
import {OptionInterface} from '../../multi-select-popover/multi-select-popover.component';

@Component({
  selector: 'lib-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
})
export class SingleSelectComponent implements OnInit {
 @Input() options: OptionInterface[];
//  _options:OptionInterface[];
//  @Input() set options(value: OptionInterface[]) {
//   if(value.length === 0){
//     return;
//   }
//   this._options = value;
  
// }

// get options(): OptionInterface[] {
//   return this._options;
// }
  @Input() componentName: string
  @Input() selectedOption: OptionInterface;
  @Input() radioMode = true;
  @Input() popoverCssClass = '';
  @Input() matchContentWidth = false;
  @Input() fromMobileAddGraph = false;
  @Input() selectEmptyOnOutsideClick = true;
  @Output() updateSelection = new EventEmitter<OptionInterface>();

  @ViewChild('contentWrapper') contentWrapper: ElementRef;
  constructor(private popoverController: PopoverController) {
  }

  ngOnInit() {
  }

  async openPopover(event: Event) {
    console.log(this.options,'options')
    if(this.options.length > 0){
    const popover = await this.popoverController.create({
      component: SingleSelectPopoverComponent,
      componentProps: {
        options: this.options,
        componentName: this.componentName,
        selectedOption: this.selectedOption?.value,
        filterStatusEnabled: true,
        radioMode: this.radioMode,
        fromMobileAddGraph: this.fromMobileAddGraph,
      },
      cssClass: 'dark-popover' + ' ' + this.popoverCssClass,
      translucent: true,
      event
    });
    if (this.matchContentWidth === true ) {
      popover.style.cssText = popover.style.cssText + '--min-width: ' + this.contentWrapper.nativeElement.offsetWidth + 'px;'
    }

    popover.onDidDismiss().then(modalData => {
      console.log('selectinnng', modalData);
      if (modalData?.data || this.selectEmptyOnOutsideClick ) {
        this.updateSelection.emit(modalData.data as OptionInterface);
      }
    });
    return await popover.present();
  }
  }
}
