import { Injectable } from '@angular/core';
import {CachedBaseService} from './cached-base.service';
import {Observable, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {ResultsService} from '../../../../api/src/lib/services';
import {Result_count} from '../../../../api/src/lib/models';

@Injectable({
  providedIn: 'root'
})
export class ResultCountsCachedService extends CachedBaseService {
  subject: ReplaySubject<Result_count[]>;

  constructor(private resultsService: ResultsService) {
    super();
  }

  public getCached(live = true, forceRefresh = false): Observable<Result_count[]> {
    return this.getCachedBase(live, forceRefresh);
  }

  fetchData(): Observable<Result_count[]> {
    return this.resultsService.GetResultCountsBySensor()
      .pipe(
        map((res) => {
            this.subject.next(res);
            return res;
          }
        )
      );
  }
}
