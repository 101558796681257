/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Organization_address } from '../models/organization-_address';
import { Organization } from '../models/organization';
import { Organization_settings } from '../models/organization-_settings';
import { Organization_settings_show_partial_results_in } from '../models/organization-_settings-_show-_partial-_results-_in';
import { Organization_settings_show_partial_results_out } from '../models/organization-_settings-_show-_partial-_results-_out';

/**
 * Organization related operations
 */
@Injectable({
  providedIn: 'root',
})
class OrganizationService extends __BaseService {
  static readonly getOrganizationPath = '/organization/';
  static readonly putOrganizationAddressUpdatePath = '/organization/address';
  static readonly getOrganizationAddressPath = '/organization/address/{address_id}';
  static readonly getOrganizationsBasedOnOperatingStatusOrganizationCustomersStringOperatingStatusStringOrganizationTypePath = '/organization/customers/{operating_status}/{organization_type}';
  static readonly putOrganizationDetailsPath = '/organization/details';
  static readonly getOrganizationDetailsPath = '/organization/details';
  static readonly getLabReportsCustomersBasedOnOperatingStatusPath = '/organization/lab_reports_customers';
  static readonly getOrganizationSettingBySensorIdPath = '/organization/settings/{sensor_id}/show_partial_results';
  static readonly getOrganizationSettingsDeprecatedPath = '/organization/{organization_id}/settings';
  static readonly putOrganizationSettingsShowPartialResultsPath = '/organization/{organization_id}/settings/show_partial_results';
  static readonly getOrganizationSettingsShowPartialResultsPath = '/organization/{organization_id}/settings/show_partial_results';
  static readonly getOrganizationsOrganizationStringOrganizationTypePath = '/organization/{organization_type}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  getOrganizationResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  getOrganization(): __Observable<null> {
    return this.getOrganizationResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  putOrganizationAddressUpdateResponse(payload: Organization_address): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organization/address`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  putOrganizationAddressUpdate(payload: Organization_address): __Observable<null> {
    return this.putOrganizationAddressUpdateResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `OrganizationService.GetOrganizationAddressParams` containing the following parameters:
   *
   * - `address_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOrganizationAddressResponse(params: OrganizationService.GetOrganizationAddressParams): __Observable<__StrictHttpResponse<Organization_address>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/address/${encodeURIComponent(String(params.addressId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Organization_address>;
      })
    );
  }
  /**
   * @param params The `OrganizationService.GetOrganizationAddressParams` containing the following parameters:
   *
   * - `address_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOrganizationAddress(params: OrganizationService.GetOrganizationAddressParams): __Observable<Organization_address> {
    return this.getOrganizationAddressResponse(params).pipe(
      __map(_r => _r.body as Organization_address)
    );
  }

  /**
   * @param params The `OrganizationService.GetOrganizationsBasedOnOperatingStatusOrganizationCustomersStringOperatingStatusStringOrganizationTypeParams` containing the following parameters:
   *
   * - `operating_status`: 0=FAT, 1=Customer, 2=ServiceOps, 3=Decommissioned, 4=InHouse or all
   *
   * - `organization_type`:
   */
  getOrganizationsBasedOnOperatingStatusOrganizationCustomersStringOperatingStatusStringOrganizationTypeResponse(params: OrganizationService.GetOrganizationsBasedOnOperatingStatusOrganizationCustomersStringOperatingStatusStringOrganizationTypeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/customers/${encodeURIComponent(String(params.operatingStatus))}/${encodeURIComponent(String(params.organizationType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `OrganizationService.GetOrganizationsBasedOnOperatingStatusOrganizationCustomersStringOperatingStatusStringOrganizationTypeParams` containing the following parameters:
   *
   * - `operating_status`: 0=FAT, 1=Customer, 2=ServiceOps, 3=Decommissioned, 4=InHouse or all
   *
   * - `organization_type`:
   */
  getOrganizationsBasedOnOperatingStatusOrganizationCustomersStringOperatingStatusStringOrganizationType(params: OrganizationService.GetOrganizationsBasedOnOperatingStatusOrganizationCustomersStringOperatingStatusStringOrganizationTypeParams): __Observable<null> {
    return this.getOrganizationsBasedOnOperatingStatusOrganizationCustomersStringOperatingStatusStringOrganizationTypeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  putOrganizationDetailsResponse(payload: Organization): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organization/details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  putOrganizationDetails(payload: Organization): __Observable<null> {
    return this.putOrganizationDetailsResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getOrganizationDetailsResponse(XFields?: string): __Observable<__StrictHttpResponse<Organization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Organization>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getOrganizationDetails(XFields?: string): __Observable<Organization> {
    return this.getOrganizationDetailsResponse(XFields).pipe(
      __map(_r => _r.body as Organization)
    );
  }
  getLabReportsCustomersBasedOnOperatingStatusResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/lab_reports_customers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  getLabReportsCustomersBasedOnOperatingStatus(): __Observable<null> {
    return this.getLabReportsCustomersBasedOnOperatingStatusResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `OrganizationService.GetOrganizationSettingBySensorIdParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOrganizationSettingBySensorIdResponse(params: OrganizationService.GetOrganizationSettingBySensorIdParams): __Observable<__StrictHttpResponse<Array<Organization_settings>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/settings/${encodeURIComponent(String(params.sensorId))}/show_partial_results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Organization_settings>>;
      })
    );
  }
  /**
   * @param params The `OrganizationService.GetOrganizationSettingBySensorIdParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOrganizationSettingBySensorId(params: OrganizationService.GetOrganizationSettingBySensorIdParams): __Observable<Array<Organization_settings>> {
    return this.getOrganizationSettingBySensorIdResponse(params).pipe(
      __map(_r => _r.body as Array<Organization_settings>)
    );
  }

  /**
   * @param params The `OrganizationService.GetOrganizationSettingsDeprecatedParams` containing the following parameters:
   *
   * - `organization_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOrganizationSettingsDeprecatedResponse(params: OrganizationService.GetOrganizationSettingsDeprecatedParams): __Observable<__StrictHttpResponse<Array<Organization_settings>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/${encodeURIComponent(String(params.organizationId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Organization_settings>>;
      })
    );
  }
  /**
   * @param params The `OrganizationService.GetOrganizationSettingsDeprecatedParams` containing the following parameters:
   *
   * - `organization_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOrganizationSettingsDeprecated(params: OrganizationService.GetOrganizationSettingsDeprecatedParams): __Observable<Array<Organization_settings>> {
    return this.getOrganizationSettingsDeprecatedResponse(params).pipe(
      __map(_r => _r.body as Array<Organization_settings>)
    );
  }

  /**
   * @param params The `OrganizationService.PutOrganizationSettingsShowPartialResultsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organization_id`:
   */
  putOrganizationSettingsShowPartialResultsResponse(params: OrganizationService.PutOrganizationSettingsShowPartialResultsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organization/${encodeURIComponent(String(params.organizationId))}/settings/show_partial_results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `OrganizationService.PutOrganizationSettingsShowPartialResultsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organization_id`:
   */
  putOrganizationSettingsShowPartialResults(params: OrganizationService.PutOrganizationSettingsShowPartialResultsParams): __Observable<null> {
    return this.putOrganizationSettingsShowPartialResultsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `OrganizationService.GetOrganizationSettingsShowPartialResultsParams` containing the following parameters:
   *
   * - `organization_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOrganizationSettingsShowPartialResultsResponse(params: OrganizationService.GetOrganizationSettingsShowPartialResultsParams): __Observable<__StrictHttpResponse<Array<Organization_settings_show_partial_results_out>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/${encodeURIComponent(String(params.organizationId))}/settings/show_partial_results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Organization_settings_show_partial_results_out>>;
      })
    );
  }
  /**
   * @param params The `OrganizationService.GetOrganizationSettingsShowPartialResultsParams` containing the following parameters:
   *
   * - `organization_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOrganizationSettingsShowPartialResults(params: OrganizationService.GetOrganizationSettingsShowPartialResultsParams): __Observable<Array<Organization_settings_show_partial_results_out>> {
    return this.getOrganizationSettingsShowPartialResultsResponse(params).pipe(
      __map(_r => _r.body as Array<Organization_settings_show_partial_results_out>)
    );
  }

  /**
   * @param organization_type 1=Internal, 2=External or "all"
   */
  getOrganizationsOrganizationStringOrganizationTypeResponse(organizationType: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organization/${encodeURIComponent(String(organizationType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param organization_type 1=Internal, 2=External or "all"
   */
  getOrganizationsOrganizationStringOrganizationType(organizationType: string): __Observable<null> {
    return this.getOrganizationsOrganizationStringOrganizationTypeResponse(organizationType).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module OrganizationService {

  /**
   * Parameters for getOrganizationAddress
   */
  export interface GetOrganizationAddressParams {
    addressId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getOrganizationsBasedOnOperatingStatusOrganizationCustomersStringOperatingStatusStringOrganizationType
   */
  export interface GetOrganizationsBasedOnOperatingStatusOrganizationCustomersStringOperatingStatusStringOrganizationTypeParams {

    /**
     * 0=FAT, 1=Customer, 2=ServiceOps, 3=Decommissioned, 4=InHouse or all
     */
    operatingStatus: string;
    organizationType: string;
  }

  /**
   * Parameters for getOrganizationSettingBySensorId
   */
  export interface GetOrganizationSettingBySensorIdParams {
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getOrganizationSettingsDeprecated
   */
  export interface GetOrganizationSettingsDeprecatedParams {
    organizationId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putOrganizationSettingsShowPartialResults
   */
  export interface PutOrganizationSettingsShowPartialResultsParams {
    payload: Organization_settings_show_partial_results_in;
    organizationId: number;
  }

  /**
   * Parameters for getOrganizationSettingsShowPartialResults
   */
  export interface GetOrganizationSettingsShowPartialResultsParams {
    organizationId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { OrganizationService }
