/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Continuous_mode_action } from '../models/continuous-_mode-_action';
import { Shield_continuous_results } from '../models/shield-_continuous-_results';
import { Shield_manual_values } from '../models/shield-_manual-_values';
import { Shield_tests_with_results } from '../models/shield-_tests-_with-_results';
import { Shield_scheduled_tests } from '../models/shield-_scheduled-_tests';

/**
 * APIs related to shield tests with results
 */
@Injectable({
  providedIn: 'root',
})
class ShieldTestService extends __BaseService {
  static readonly postShieldContinuousModePath = '/shield_test/continuous_mode';
  static readonly getShieldContinuousResultsShieldTestContinuousResultsStringSensorIdPath = '/shield_test/continuous_results/{sensor_id}';
  static readonly getShieldContinuousResultsShieldTestContinuousResultsStringSensorIdIntOperatingStatusPath = '/shield_test/continuous_results/{sensor_id}/{operating_status}';
  static readonly postShieldContinuousManualValuesShieldTestContinuousValuesManualStringSensorIdPath = '/shield_test/continuous_values_manual/{sensor_id}';
  static readonly GetTestsForOQTestPath = '/shield_test/oq_tests/{sensor_id}/{operating_status}/{page}/{per_page}';
  static readonly GetTestsPendingApprovalPath = '/shield_test/pending_approval/{organization_ids}/{water_source_ids}/{statuses}/{operating_statuses}/{page}/{per_page}';
  static readonly GetScheduledTestsPath = '/shield_test/scheduled_tests/{sensor_id}/{status}/{page}/{per_page}';
  static readonly CancelTheOngoingTestPath = '/shield_test/shield/{sensor_id}/test/{test_number}/{operating_status}';
  static readonly getShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPagePath = '/shield_test/tests_with_results/{water_source_id}/{status}/{page}/{per_page}';
  static readonly getShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageIntOperatingStatusPath = '/shield_test/tests_with_results/{water_source_id}/{status}/{page}/{per_page}/{operating_status}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param payload undefined
   */
  postShieldContinuousModeResponse(payload: Continuous_mode_action): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shield_test/continuous_mode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postShieldContinuousMode(payload: Continuous_mode_action): __Observable<null> {
    return this.postShieldContinuousModeResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldTestService.GetShieldContinuousResultsShieldTestContinuousResultsStringSensorIdParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldContinuousResultsShieldTestContinuousResultsStringSensorIdResponse(params: ShieldTestService.GetShieldContinuousResultsShieldTestContinuousResultsStringSensorIdParams): __Observable<__StrictHttpResponse<Shield_continuous_results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_test/continuous_results/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_continuous_results>;
      })
    );
  }
  /**
   * @param params The `ShieldTestService.GetShieldContinuousResultsShieldTestContinuousResultsStringSensorIdParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldContinuousResultsShieldTestContinuousResultsStringSensorId(params: ShieldTestService.GetShieldContinuousResultsShieldTestContinuousResultsStringSensorIdParams): __Observable<Shield_continuous_results> {
    return this.getShieldContinuousResultsShieldTestContinuousResultsStringSensorIdResponse(params).pipe(
      __map(_r => _r.body as Shield_continuous_results)
    );
  }

  /**
   * @param params The `ShieldTestService.GetShieldContinuousResultsShieldTestContinuousResultsStringSensorIdIntOperatingStatusParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `operating_status`: operating_status
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldContinuousResultsShieldTestContinuousResultsStringSensorIdIntOperatingStatusResponse(params: ShieldTestService.GetShieldContinuousResultsShieldTestContinuousResultsStringSensorIdIntOperatingStatusParams): __Observable<__StrictHttpResponse<Shield_continuous_results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_test/continuous_results/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_continuous_results>;
      })
    );
  }
  /**
   * @param params The `ShieldTestService.GetShieldContinuousResultsShieldTestContinuousResultsStringSensorIdIntOperatingStatusParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `operating_status`: operating_status
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldContinuousResultsShieldTestContinuousResultsStringSensorIdIntOperatingStatus(params: ShieldTestService.GetShieldContinuousResultsShieldTestContinuousResultsStringSensorIdIntOperatingStatusParams): __Observable<Shield_continuous_results> {
    return this.getShieldContinuousResultsShieldTestContinuousResultsStringSensorIdIntOperatingStatusResponse(params).pipe(
      __map(_r => _r.body as Shield_continuous_results)
    );
  }

  /**
   * @param params The `ShieldTestService.PostShieldContinuousManualValuesShieldTestContinuousValuesManualStringSensorIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`: Sensor ID
   */
  postShieldContinuousManualValuesShieldTestContinuousValuesManualStringSensorIdResponse(params: ShieldTestService.PostShieldContinuousManualValuesShieldTestContinuousValuesManualStringSensorIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shield_test/continuous_values_manual/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldTestService.PostShieldContinuousManualValuesShieldTestContinuousValuesManualStringSensorIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`: Sensor ID
   */
  postShieldContinuousManualValuesShieldTestContinuousValuesManualStringSensorId(params: ShieldTestService.PostShieldContinuousManualValuesShieldTestContinuousValuesManualStringSensorIdParams): __Observable<null> {
    return this.postShieldContinuousManualValuesShieldTestContinuousValuesManualStringSensorIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldTestService.GetTestsForOQTestParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID or "all"
   *
   * - `operating_status`: Operating status
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetTestsForOQTestResponse(params: ShieldTestService.GetTestsForOQTestParams): __Observable<__StrictHttpResponse<Shield_tests_with_results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_test/oq_tests/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.operatingStatus))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_tests_with_results>;
      })
    );
  }
  /**
   * @param params The `ShieldTestService.GetTestsForOQTestParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID or "all"
   *
   * - `operating_status`: Operating status
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetTestsForOQTest(params: ShieldTestService.GetTestsForOQTestParams): __Observable<Shield_tests_with_results> {
    return this.GetTestsForOQTestResponse(params).pipe(
      __map(_r => _r.body as Shield_tests_with_results)
    );
  }

  /**
   * @param params The `ShieldTestService.GetTestsPendingApprovalParams` containing the following parameters:
   *
   * - `organization_ids`: Comma seperated list of organization ids or "all"
   *
   * - `water_source_ids`: Comma seperated list of water source ids or "all"
   *
   * - `statuses`: Comma seperated list of statuses or "all"
   *
   * - `operating_statuses`: Comma seperated list of operating statuses or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetTestsPendingApprovalResponse(params: ShieldTestService.GetTestsPendingApprovalParams): __Observable<__StrictHttpResponse<Shield_tests_with_results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;






    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_test/pending_approval/${encodeURIComponent(String(params.organizationIds))}/${encodeURIComponent(String(params.waterSourceIds))}/${encodeURIComponent(String(params.statuses))}/${encodeURIComponent(String(params.operatingStatuses))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_tests_with_results>;
      })
    );
  }
  /**
   * @param params The `ShieldTestService.GetTestsPendingApprovalParams` containing the following parameters:
   *
   * - `organization_ids`: Comma seperated list of organization ids or "all"
   *
   * - `water_source_ids`: Comma seperated list of water source ids or "all"
   *
   * - `statuses`: Comma seperated list of statuses or "all"
   *
   * - `operating_statuses`: Comma seperated list of operating statuses or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetTestsPendingApproval(params: ShieldTestService.GetTestsPendingApprovalParams): __Observable<Shield_tests_with_results> {
    return this.GetTestsPendingApprovalResponse(params).pipe(
      __map(_r => _r.body as Shield_tests_with_results)
    );
  }

  /**
   * @param params The `ShieldTestService.GetScheduledTestsParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID or "all"
   *
   * - `status`: Comma seperated list from the following: 1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetScheduledTestsResponse(params: ShieldTestService.GetScheduledTestsParams): __Observable<__StrictHttpResponse<Shield_scheduled_tests>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_test/scheduled_tests/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.status))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_scheduled_tests>;
      })
    );
  }
  /**
   * @param params The `ShieldTestService.GetScheduledTestsParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID or "all"
   *
   * - `status`: Comma seperated list from the following: 1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetScheduledTests(params: ShieldTestService.GetScheduledTestsParams): __Observable<Shield_scheduled_tests> {
    return this.GetScheduledTestsResponse(params).pipe(
      __map(_r => _r.body as Shield_scheduled_tests)
    );
  }

  /**
   * @param params The `ShieldTestService.CancelTheOngoingTestParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `test_number`:
   *
   * - `operating_status`:
   */
  CancelTheOngoingTestResponse(params: ShieldTestService.CancelTheOngoingTestParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/shield_test/shield/${encodeURIComponent(String(params.sensorId))}/test/${encodeURIComponent(String(params.testNumber))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldTestService.CancelTheOngoingTestParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `test_number`:
   *
   * - `operating_status`:
   */
  CancelTheOngoingTest(params: ShieldTestService.CancelTheOngoingTestParams): __Observable<null> {
    return this.CancelTheOngoingTestResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldTestService.GetShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageParams` containing the following parameters:
   *
   * - `water_source_id`: Water Source ID or "all"
   *
   * - `status`: Comma seperated list from the following: 1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageResponse(params: ShieldTestService.GetShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageParams): __Observable<__StrictHttpResponse<Shield_tests_with_results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_test/tests_with_results/${encodeURIComponent(String(params.waterSourceId))}/${encodeURIComponent(String(params.status))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_tests_with_results>;
      })
    );
  }
  /**
   * @param params The `ShieldTestService.GetShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageParams` containing the following parameters:
   *
   * - `water_source_id`: Water Source ID or "all"
   *
   * - `status`: Comma seperated list from the following: 1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPage(params: ShieldTestService.GetShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageParams): __Observable<Shield_tests_with_results> {
    return this.getShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageResponse(params).pipe(
      __map(_r => _r.body as Shield_tests_with_results)
    );
  }

  /**
   * @param params The `ShieldTestService.GetShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageIntOperatingStatusParams` containing the following parameters:
   *
   * - `water_source_id`: Water Source ID or "all"
   *
   * - `status`: Comma seperated list from the following: 1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `operating_status`: Operating status
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageIntOperatingStatusResponse(params: ShieldTestService.GetShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageIntOperatingStatusParams): __Observable<__StrictHttpResponse<Shield_tests_with_results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_test/tests_with_results/${encodeURIComponent(String(params.waterSourceId))}/${encodeURIComponent(String(params.status))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_tests_with_results>;
      })
    );
  }
  /**
   * @param params The `ShieldTestService.GetShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageIntOperatingStatusParams` containing the following parameters:
   *
   * - `water_source_id`: Water Source ID or "all"
   *
   * - `status`: Comma seperated list from the following: 1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `operating_status`: Operating status
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageIntOperatingStatus(params: ShieldTestService.GetShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageIntOperatingStatusParams): __Observable<Shield_tests_with_results> {
    return this.getShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageIntOperatingStatusResponse(params).pipe(
      __map(_r => _r.body as Shield_tests_with_results)
    );
  }
}

module ShieldTestService {

  /**
   * Parameters for getShieldContinuousResultsShieldTestContinuousResultsStringSensorId
   */
  export interface GetShieldContinuousResultsShieldTestContinuousResultsStringSensorIdParams {

    /**
     * Sensor ID
     */
    sensorId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldContinuousResultsShieldTestContinuousResultsStringSensorIdIntOperatingStatus
   */
  export interface GetShieldContinuousResultsShieldTestContinuousResultsStringSensorIdIntOperatingStatusParams {

    /**
     * Sensor ID
     */
    sensorId: string;

    /**
     * operating_status
     */
    operatingStatus: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postShieldContinuousManualValuesShieldTestContinuousValuesManualStringSensorId
   */
  export interface PostShieldContinuousManualValuesShieldTestContinuousValuesManualStringSensorIdParams {
    payload: Shield_manual_values;

    /**
     * Sensor ID
     */
    sensorId: string;
  }

  /**
   * Parameters for GetTestsForOQTest
   */
  export interface GetTestsForOQTestParams {

    /**
     * Sensor ID or "all"
     */
    sensorId: string;

    /**
     * Operating status
     */
    operatingStatus: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetTestsPendingApproval
   */
  export interface GetTestsPendingApprovalParams {

    /**
     * Comma seperated list of organization ids or "all"
     */
    organizationIds: string;

    /**
     * Comma seperated list of water source ids or "all"
     */
    waterSourceIds: string;

    /**
     * Comma seperated list of statuses or "all"
     */
    statuses: string;

    /**
     * Comma seperated list of operating statuses or "all"
     */
    operatingStatuses: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetScheduledTests
   */
  export interface GetScheduledTestsParams {

    /**
     * Sensor ID or "all"
     */
    sensorId: string;

    /**
     * Comma seperated list from the following: 1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error or "all"
     */
    status: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for CancelTheOngoingTest
   */
  export interface CancelTheOngoingTestParams {
    sensorId: number;
    testNumber: number;
    operatingStatus: number;
  }

  /**
   * Parameters for getShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPage
   */
  export interface GetShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageParams {

    /**
     * Water Source ID or "all"
     */
    waterSourceId: string;

    /**
     * Comma seperated list from the following: 1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error or "all"
     */
    status: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageIntOperatingStatus
   */
  export interface GetShieldTestShieldTestTestsWithResultsStringWaterSourceIdStringStatusIntPageIntPerPageIntOperatingStatusParams {

    /**
     * Water Source ID or "all"
     */
    waterSourceId: string;

    /**
     * Comma seperated list from the following: 1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error or "all"
     */
    status: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * Operating status
     */
    operatingStatus: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ShieldTestService }
