/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Sensor_task } from '../models/sensor-_task';
import { Test_task } from '../models/test-_task';

/**
 * Scheduler tasks (Common)
 */
@Injectable({
  providedIn: 'root',
})
class TasksService extends __BaseService {
  static readonly postContinuousModeOnWithNoWaterPath = '/tasks/continuous_mode_on_with_no_water';
  static readonly postLogSvcopsModeOver24HoursPath = '/tasks/log_svcops_mode_over_24_hours';
  static readonly postLogTestFailedOnDeviceMultipleTimeIn24HoursPath = '/tasks/log_test_failed_on_device_multiple_times_in_24_hours';
  static readonly postLogTestPendingApproval12HourWarningPath = '/tasks/log_test_pending_approval_12_hour_warning';
  static readonly postLogTestPendingApproval2HourWarningPath = '/tasks/log_test_pending_approval_2_hour_warning';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param payload undefined
   */
  postContinuousModeOnWithNoWaterResponse(payload: Sensor_task): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tasks/continuous_mode_on_with_no_water`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postContinuousModeOnWithNoWater(payload: Sensor_task): __Observable<null> {
    return this.postContinuousModeOnWithNoWaterResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  postLogSvcopsModeOver24HoursResponse(payload: Sensor_task): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tasks/log_svcops_mode_over_24_hours`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postLogSvcopsModeOver24Hours(payload: Sensor_task): __Observable<null> {
    return this.postLogSvcopsModeOver24HoursResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  postLogTestFailedOnDeviceMultipleTimeIn24HoursResponse(payload: Sensor_task): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tasks/log_test_failed_on_device_multiple_times_in_24_hours`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postLogTestFailedOnDeviceMultipleTimeIn24Hours(payload: Sensor_task): __Observable<null> {
    return this.postLogTestFailedOnDeviceMultipleTimeIn24HoursResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  postLogTestPendingApproval12HourWarningResponse(payload: Test_task): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tasks/log_test_pending_approval_12_hour_warning`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postLogTestPendingApproval12HourWarning(payload: Test_task): __Observable<null> {
    return this.postLogTestPendingApproval12HourWarningResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  postLogTestPendingApproval2HourWarningResponse(payload: Test_task): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tasks/log_test_pending_approval_2_hour_warning`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postLogTestPendingApproval2HourWarning(payload: Test_task): __Observable<null> {
    return this.postLogTestPendingApproval2HourWarningResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TasksService {
}

export { TasksService }
