import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lib-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
})
export class LoadingOverlayComponent implements OnInit {
  @Input() showOverlay: boolean;
  @Input() fullHeight: boolean;
  constructor() { }

  ngOnInit() {
  }

}

