/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Shield_led_intensity } from '../models/shield-_led-_intensity';
import { Test_status } from '../models/test-_status';
import { Shield_sensors_list } from '../models/shield-_sensors-_list';
import { Shield_device } from '../models/shield-_device';
import { Sensor_bitmap } from '../models/sensor-_bitmap';
import { Shield_sensor_ext } from '../models/shield-_sensor-_ext';
import { Sensor_setting_supports_partial_results_in } from '../models/sensor-_setting-_supports-_partial-_results-_in';
import { Sensor_setting_supports_partial_results_out } from '../models/sensor-_setting-_supports-_partial-_results-_out';

/**
 * APIs related to sensors
 */
@Injectable({
  providedIn: 'root',
})
class SensorService extends __BaseService {
  static readonly getShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvPath = '/sensor/firmware_status/{organization_type}/{csv}';
  static readonly getShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvStringTimezonePath = '/sensor/firmware_status/{organization_type}/{csv}/{timezone}';
  static readonly getShieldFirmwareStatusSensorFirmwareStatusStringOrganizationTypeIntPageIntPerPagePath = '/sensor/firmware_status/{organization_type}/{page}/{per_page}';
  static readonly getSensorShieldExperimentConfigurationsPath = '/sensor/shield/exp_config_internal.json';
  static readonly CreateShieldLEDIntensityPath = '/sensor/shield/led_intensity';
  static readonly GetShieldLEDIntensitiesPath = '/sensor/shield/led_intensity/{start_date}/{end_date}/{sensor_id}/{metal_name}';
  static readonly GetShieldTestStatusBasedOnTheSensorIdPath = '/sensor/shield/test_status/{sensor_id}';
  static readonly getShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringOperatingStatusPath = '/sensor/shield/{customer_id}/sensor/{sensor_id}/{alert_type}/{starttime}/{endtime}/{csv}/{operating_status}';
  static readonly getShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringTimezoneStringOperatingStatusPath = '/sensor/shield/{customer_id}/sensor/{sensor_id}/{alert_type}/{starttime}/{endtime}/{csv}/{timezone}/{operating_status}';
  static readonly getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueStringOperatingStatusPath = '/sensor/shield/{customer_id}/sensor/{sensor_id}/{alert_type}/{starttime}/{endtime}/{page}/{per_page}/search/{sort_column}/{order}/{search_column}/{search_value}/{operating_status}';
  static readonly getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSortStringSortColumnStringOrderStringOperatingStatusPath = '/sensor/shield/{customer_id}/sensor/{sensor_id}/{alert_type}/{starttime}/{endtime}/{page}/{per_page}/sort/{sort_column}/{order}/{operating_status}';
  static readonly getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageStringOperatingStatusPath = '/sensor/shield/{customer_id}/sensor/{sensor_id}/{alert_type}/{starttime}/{endtime}/{page}/{per_page}/{operating_status}';
  static readonly getShieldSensorsAllSensorShieldIntPageIntPerPagePath = '/sensor/shield/{page}/{per_page}';
  static readonly getShieldSensorsSensorShieldIntSensorIdPath = '/sensor/shield/{sensor_id}';
  static readonly SaveSensorBitmapPath = '/sensor/shield/{sensor_id}/bitmap';
  static readonly getShieldSensorsExtSensorShieldIntSensorIdExtPath = '/sensor/shield/{sensor_id}/ext';
  static readonly getShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvPath = '/sensor/summary/{organization_type}/{csv}';
  static readonly getShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvStringTimezonePath = '/sensor/summary/{organization_type}/{csv}/{timezone}';
  static readonly getShieldSummarySensorSummaryStringOrganizationTypeIntPageIntPerPagePath = '/sensor/summary/{organization_type}/{page}/{per_page}';
  static readonly getFirmwareListSensorStringSensorIdFirmwaresStringFirmwareTypePath = '/sensor/{sensor_id}/firmwares/{firmware_type}';
  static readonly PutSensorSettingSupportsPartialResultsPath = '/sensor/{sensor_id}/settings/supports_partial_results';
  static readonly GetSensorSettingSupportsPartialResultsPath = '/sensor/{sensor_id}/settings/supports_partial_results';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SensorService.GetShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvParams` containing the following parameters:
   *
   * - `organization_type`: 1=Internal, 2=External or "all"
   *
   * - `csv`: csv
   */
  getShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvResponse(params: SensorService.GetShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/firmware_status/${encodeURIComponent(String(params.organizationType))}/${encodeURIComponent(String(params.csv))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvParams` containing the following parameters:
   *
   * - `organization_type`: 1=Internal, 2=External or "all"
   *
   * - `csv`: csv
   */
  getShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsv(params: SensorService.GetShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvParams): __Observable<null> {
    return this.getShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorService.GetShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvStringTimezoneParams` containing the following parameters:
   *
   * - `organization_type`: 1=Internal, 2=External or "all"
   *
   * - `csv`: csv
   *
   * - `timezone`: Asia-Calcutta
   */
  getShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvStringTimezoneResponse(params: SensorService.GetShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvStringTimezoneParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/firmware_status/${encodeURIComponent(String(params.organizationType))}/${encodeURIComponent(String(params.csv))}/${encodeURIComponent(String(params.timezone))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvStringTimezoneParams` containing the following parameters:
   *
   * - `organization_type`: 1=Internal, 2=External or "all"
   *
   * - `csv`: csv
   *
   * - `timezone`: Asia-Calcutta
   */
  getShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvStringTimezone(params: SensorService.GetShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvStringTimezoneParams): __Observable<null> {
    return this.getShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvStringTimezoneResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorService.GetShieldFirmwareStatusSensorFirmwareStatusStringOrganizationTypeIntPageIntPerPageParams` containing the following parameters:
   *
   * - `organization_type`: 1=Internal, 2=External or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   */
  getShieldFirmwareStatusSensorFirmwareStatusStringOrganizationTypeIntPageIntPerPageResponse(params: SensorService.GetShieldFirmwareStatusSensorFirmwareStatusStringOrganizationTypeIntPageIntPerPageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/firmware_status/${encodeURIComponent(String(params.organizationType))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldFirmwareStatusSensorFirmwareStatusStringOrganizationTypeIntPageIntPerPageParams` containing the following parameters:
   *
   * - `organization_type`: 1=Internal, 2=External or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   */
  getShieldFirmwareStatusSensorFirmwareStatusStringOrganizationTypeIntPageIntPerPage(params: SensorService.GetShieldFirmwareStatusSensorFirmwareStatusStringOrganizationTypeIntPageIntPerPageParams): __Observable<null> {
    return this.getShieldFirmwareStatusSensorFirmwareStatusStringOrganizationTypeIntPageIntPerPageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
  getSensorShieldExperimentConfigurationsResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/shield/exp_config_internal.json`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  getSensorShieldExperimentConfigurations(): __Observable<null> {
    return this.getSensorShieldExperimentConfigurationsResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  CreateShieldLEDIntensityResponse(payload: Shield_led_intensity): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/sensor/shield/led_intensity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  CreateShieldLEDIntensity(payload: Shield_led_intensity): __Observable<null> {
    return this.CreateShieldLEDIntensityResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorService.GetShieldLEDIntensitiesParams` containing the following parameters:
   *
   * - `start_date`: ISO 8601 start date/time or "none" (inclusive)
   *
   * - `end_date`: ISO 8601 end data/time or "none" (inclusive)
   *
   * - `sensor_id`: Sensor ID
   *
   * - `metal_name`: Such as "lead"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetShieldLEDIntensitiesResponse(params: SensorService.GetShieldLEDIntensitiesParams): __Observable<__StrictHttpResponse<Array<Shield_led_intensity>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/shield/led_intensity/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.metalName))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Shield_led_intensity>>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldLEDIntensitiesParams` containing the following parameters:
   *
   * - `start_date`: ISO 8601 start date/time or "none" (inclusive)
   *
   * - `end_date`: ISO 8601 end data/time or "none" (inclusive)
   *
   * - `sensor_id`: Sensor ID
   *
   * - `metal_name`: Such as "lead"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetShieldLEDIntensities(params: SensorService.GetShieldLEDIntensitiesParams): __Observable<Array<Shield_led_intensity>> {
    return this.GetShieldLEDIntensitiesResponse(params).pipe(
      __map(_r => _r.body as Array<Shield_led_intensity>)
    );
  }

  /**
   * @param params The `SensorService.GetShieldTestStatusBasedOnTheSensorIdParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetShieldTestStatusBasedOnTheSensorIdResponse(params: SensorService.GetShieldTestStatusBasedOnTheSensorIdParams): __Observable<__StrictHttpResponse<Test_status>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/shield/test_status/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Test_status>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldTestStatusBasedOnTheSensorIdParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetShieldTestStatusBasedOnTheSensorId(params: SensorService.GetShieldTestStatusBasedOnTheSensorIdParams): __Observable<Test_status> {
    return this.GetShieldTestStatusBasedOnTheSensorIdResponse(params).pipe(
      __map(_r => _r.body as Test_status)
    );
  }

  /**
   * @param params The `SensorService.GetShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringOperatingStatusParams` containing the following parameters:
   *
   * - `operating_status`: 0,1,2,3,4,all
   *
   * - `customer_id`: 0=All customers
   *
   * - `sensor_id`: 0=All sensors
   *
   * - `alert_type`: all=All alerts, internal, customer,
   *
   * - `starttime`: 0=All alerts
   *
   * - `endtime`: 0=All alerts
   *
   * - `csv`: csv
   */
  getShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringOperatingStatusResponse(params: SensorService.GetShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringOperatingStatusParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;







    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/shield/${encodeURIComponent(String(params.customerId))}/sensor/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.alertType))}/${encodeURIComponent(String(params.starttime))}/${encodeURIComponent(String(params.endtime))}/${encodeURIComponent(String(params.csv))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringOperatingStatusParams` containing the following parameters:
   *
   * - `operating_status`: 0,1,2,3,4,all
   *
   * - `customer_id`: 0=All customers
   *
   * - `sensor_id`: 0=All sensors
   *
   * - `alert_type`: all=All alerts, internal, customer,
   *
   * - `starttime`: 0=All alerts
   *
   * - `endtime`: 0=All alerts
   *
   * - `csv`: csv
   */
  getShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringOperatingStatus(params: SensorService.GetShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringOperatingStatusParams): __Observable<null> {
    return this.getShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringOperatingStatusResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorService.GetShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringTimezoneStringOperatingStatusParams` containing the following parameters:
   *
   * - `operating_status`: 0,1,2,3,4,all
   *
   * - `customer_id`: 0=All customers
   *
   * - `sensor_id`: 0=All sensors
   *
   * - `alert_type`: all=All alerts, internal, customer,
   *
   * - `starttime`: 0=All alerts
   *
   * - `endtime`: 0=All alerts
   *
   * - `csv`: csv
   *
   * - `timezone`: Asia-Kolkata
   */
  getShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringTimezoneStringOperatingStatusResponse(params: SensorService.GetShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringTimezoneStringOperatingStatusParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;








    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/shield/${encodeURIComponent(String(params.customerId))}/sensor/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.alertType))}/${encodeURIComponent(String(params.starttime))}/${encodeURIComponent(String(params.endtime))}/${encodeURIComponent(String(params.csv))}/${encodeURIComponent(String(params.timezone))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringTimezoneStringOperatingStatusParams` containing the following parameters:
   *
   * - `operating_status`: 0,1,2,3,4,all
   *
   * - `customer_id`: 0=All customers
   *
   * - `sensor_id`: 0=All sensors
   *
   * - `alert_type`: all=All alerts, internal, customer,
   *
   * - `starttime`: 0=All alerts
   *
   * - `endtime`: 0=All alerts
   *
   * - `csv`: csv
   *
   * - `timezone`: Asia-Kolkata
   */
  getShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringTimezoneStringOperatingStatus(params: SensorService.GetShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringTimezoneStringOperatingStatusParams): __Observable<null> {
    return this.getShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringTimezoneStringOperatingStatusResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorService.GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueStringOperatingStatusParams` containing the following parameters:
   *
   * - `operating_status`: 0,1,2,3,4,all
   *
   * - `customer_id`: 0=All customers
   *
   * - `sensor_id`: 0=All sensors
   *
   * - `alert_type`: all=All alerts, internal, customer,
   *
   * - `starttime`: 0=All alerts
   *
   * - `endtime`: 0=All alerts
   *
   * - `sort_column`: column on which we need to sort, 'none' if no sorting
   *
   * - `search_column`: column on which we need to search
   *
   * - `page`:
   *
   * - `per_page`:
   *
   * - `order`:
   *
   * - `search_value`:
   */
  getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueStringOperatingStatusResponse(params: SensorService.GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueStringOperatingStatusParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;












    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/shield/${encodeURIComponent(String(params.customerId))}/sensor/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.alertType))}/${encodeURIComponent(String(params.starttime))}/${encodeURIComponent(String(params.endtime))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}/search/${encodeURIComponent(String(params.sortColumn))}/${encodeURIComponent(String(params.order))}/${encodeURIComponent(String(params.searchColumn))}/${encodeURIComponent(String(params.searchValue))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueStringOperatingStatusParams` containing the following parameters:
   *
   * - `operating_status`: 0,1,2,3,4,all
   *
   * - `customer_id`: 0=All customers
   *
   * - `sensor_id`: 0=All sensors
   *
   * - `alert_type`: all=All alerts, internal, customer,
   *
   * - `starttime`: 0=All alerts
   *
   * - `endtime`: 0=All alerts
   *
   * - `sort_column`: column on which we need to sort, 'none' if no sorting
   *
   * - `search_column`: column on which we need to search
   *
   * - `page`:
   *
   * - `per_page`:
   *
   * - `order`:
   *
   * - `search_value`:
   */
  getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueStringOperatingStatus(params: SensorService.GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueStringOperatingStatusParams): __Observable<null> {
    return this.getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueStringOperatingStatusResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorService.GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSortStringSortColumnStringOrderStringOperatingStatusParams` containing the following parameters:
   *
   * - `operating_status`: 0,1,2,3,4,all
   *
   * - `customer_id`: 0=All customers
   *
   * - `sensor_id`: 0=All sensors
   *
   * - `alert_type`: all=All alerts, internal, customer,
   *
   * - `starttime`: 0=All alerts
   *
   * - `endtime`: 0=All alerts
   *
   * - `sort_column`: column on which we need to sort
   *
   * - `order`: asc/desc
   *
   * - `page`:
   *
   * - `per_page`:
   */
  getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSortStringSortColumnStringOrderStringOperatingStatusResponse(params: SensorService.GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSortStringSortColumnStringOrderStringOperatingStatusParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;










    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/shield/${encodeURIComponent(String(params.customerId))}/sensor/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.alertType))}/${encodeURIComponent(String(params.starttime))}/${encodeURIComponent(String(params.endtime))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}/sort/${encodeURIComponent(String(params.sortColumn))}/${encodeURIComponent(String(params.order))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSortStringSortColumnStringOrderStringOperatingStatusParams` containing the following parameters:
   *
   * - `operating_status`: 0,1,2,3,4,all
   *
   * - `customer_id`: 0=All customers
   *
   * - `sensor_id`: 0=All sensors
   *
   * - `alert_type`: all=All alerts, internal, customer,
   *
   * - `starttime`: 0=All alerts
   *
   * - `endtime`: 0=All alerts
   *
   * - `sort_column`: column on which we need to sort
   *
   * - `order`: asc/desc
   *
   * - `page`:
   *
   * - `per_page`:
   */
  getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSortStringSortColumnStringOrderStringOperatingStatus(params: SensorService.GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSortStringSortColumnStringOrderStringOperatingStatusParams): __Observable<null> {
    return this.getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSortStringSortColumnStringOrderStringOperatingStatusResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorService.GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageStringOperatingStatusParams` containing the following parameters:
   *
   * - `operating_status`: 0,1,2,3,4,all
   *
   * - `customer_id`: 0=All customers
   *
   * - `sensor_id`: 0=All sensors
   *
   * - `alert_type`: all=All alerts, internal, customer,
   *
   * - `starttime`: 0=All alerts
   *
   * - `endtime`: 0=All alerts
   *
   * - `page`:
   *
   * - `per_page`:
   */
  getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageStringOperatingStatusResponse(params: SensorService.GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageStringOperatingStatusParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;








    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/shield/${encodeURIComponent(String(params.customerId))}/sensor/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.alertType))}/${encodeURIComponent(String(params.starttime))}/${encodeURIComponent(String(params.endtime))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageStringOperatingStatusParams` containing the following parameters:
   *
   * - `operating_status`: 0,1,2,3,4,all
   *
   * - `customer_id`: 0=All customers
   *
   * - `sensor_id`: 0=All sensors
   *
   * - `alert_type`: all=All alerts, internal, customer,
   *
   * - `starttime`: 0=All alerts
   *
   * - `endtime`: 0=All alerts
   *
   * - `page`:
   *
   * - `per_page`:
   */
  getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageStringOperatingStatus(params: SensorService.GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageStringOperatingStatusParams): __Observable<null> {
    return this.getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageStringOperatingStatusResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorService.GetShieldSensorsAllSensorShieldIntPageIntPerPageParams` containing the following parameters:
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * - `organization_type`: 1=Internal, 2=External or "all"
   *
   * @return Success
   */
  getShieldSensorsAllSensorShieldIntPageIntPerPageResponse(params: SensorService.GetShieldSensorsAllSensorShieldIntPageIntPerPageParams): __Observable<__StrictHttpResponse<Shield_sensors_list>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    if (params.organizationType != null) __params = __params.set('organization_type', params.organizationType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/shield/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_sensors_list>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldSensorsAllSensorShieldIntPageIntPerPageParams` containing the following parameters:
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * - `organization_type`: 1=Internal, 2=External or "all"
   *
   * @return Success
   */
  getShieldSensorsAllSensorShieldIntPageIntPerPage(params: SensorService.GetShieldSensorsAllSensorShieldIntPageIntPerPageParams): __Observable<Shield_sensors_list> {
    return this.getShieldSensorsAllSensorShieldIntPageIntPerPageResponse(params).pipe(
      __map(_r => _r.body as Shield_sensors_list)
    );
  }

  /**
   * @param params The `SensorService.GetShieldSensorsSensorShieldIntSensorIdParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldSensorsSensorShieldIntSensorIdResponse(params: SensorService.GetShieldSensorsSensorShieldIntSensorIdParams): __Observable<__StrictHttpResponse<Shield_device>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/shield/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_device>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldSensorsSensorShieldIntSensorIdParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldSensorsSensorShieldIntSensorId(params: SensorService.GetShieldSensorsSensorShieldIntSensorIdParams): __Observable<Shield_device> {
    return this.getShieldSensorsSensorShieldIntSensorIdResponse(params).pipe(
      __map(_r => _r.body as Shield_device)
    );
  }

  /**
   * @param params The `SensorService.SaveSensorBitmapParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`: Sensor ID
   */
  SaveSensorBitmapResponse(params: SensorService.SaveSensorBitmapParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sensor/shield/${encodeURIComponent(String(params.sensorId))}/bitmap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorService.SaveSensorBitmapParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`: Sensor ID
   */
  SaveSensorBitmap(params: SensorService.SaveSensorBitmapParams): __Observable<null> {
    return this.SaveSensorBitmapResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorService.GetShieldSensorsExtSensorShieldIntSensorIdExtParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldSensorsExtSensorShieldIntSensorIdExtResponse(params: SensorService.GetShieldSensorsExtSensorShieldIntSensorIdExtParams): __Observable<__StrictHttpResponse<Shield_sensor_ext>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/shield/${encodeURIComponent(String(params.sensorId))}/ext`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_sensor_ext>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldSensorsExtSensorShieldIntSensorIdExtParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldSensorsExtSensorShieldIntSensorIdExt(params: SensorService.GetShieldSensorsExtSensorShieldIntSensorIdExtParams): __Observable<Shield_sensor_ext> {
    return this.getShieldSensorsExtSensorShieldIntSensorIdExtResponse(params).pipe(
      __map(_r => _r.body as Shield_sensor_ext)
    );
  }

  /**
   * @param params The `SensorService.GetShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvParams` containing the following parameters:
   *
   * - `organization_type`: 1=Internal, 2=External or "all"
   *
   * - `csv`: csv
   */
  getShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvResponse(params: SensorService.GetShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/summary/${encodeURIComponent(String(params.organizationType))}/${encodeURIComponent(String(params.csv))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvParams` containing the following parameters:
   *
   * - `organization_type`: 1=Internal, 2=External or "all"
   *
   * - `csv`: csv
   */
  getShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsv(params: SensorService.GetShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvParams): __Observable<null> {
    return this.getShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorService.GetShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvStringTimezoneParams` containing the following parameters:
   *
   * - `organization_type`: 1=Internal, 2=External or "all"
   *
   * - `csv`: csv
   *
   * - `timezone`: Asia-Calcutta
   */
  getShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvStringTimezoneResponse(params: SensorService.GetShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvStringTimezoneParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/summary/${encodeURIComponent(String(params.organizationType))}/${encodeURIComponent(String(params.csv))}/${encodeURIComponent(String(params.timezone))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvStringTimezoneParams` containing the following parameters:
   *
   * - `organization_type`: 1=Internal, 2=External or "all"
   *
   * - `csv`: csv
   *
   * - `timezone`: Asia-Calcutta
   */
  getShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvStringTimezone(params: SensorService.GetShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvStringTimezoneParams): __Observable<null> {
    return this.getShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvStringTimezoneResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorService.GetShieldSummarySensorSummaryStringOrganizationTypeIntPageIntPerPageParams` containing the following parameters:
   *
   * - `organization_type`: 1=Internal, 2=External or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   */
  getShieldSummarySensorSummaryStringOrganizationTypeIntPageIntPerPageResponse(params: SensorService.GetShieldSummarySensorSummaryStringOrganizationTypeIntPageIntPerPageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/summary/${encodeURIComponent(String(params.organizationType))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetShieldSummarySensorSummaryStringOrganizationTypeIntPageIntPerPageParams` containing the following parameters:
   *
   * - `organization_type`: 1=Internal, 2=External or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   */
  getShieldSummarySensorSummaryStringOrganizationTypeIntPageIntPerPage(params: SensorService.GetShieldSummarySensorSummaryStringOrganizationTypeIntPageIntPerPageParams): __Observable<null> {
    return this.getShieldSummarySensorSummaryStringOrganizationTypeIntPageIntPerPageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorService.GetFirmwareListSensorStringSensorIdFirmwaresStringFirmwareTypeParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `firmware_type`: Firmware type
   */
  getFirmwareListSensorStringSensorIdFirmwaresStringFirmwareTypeResponse(params: SensorService.GetFirmwareListSensorStringSensorIdFirmwaresStringFirmwareTypeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/${encodeURIComponent(String(params.sensorId))}/firmwares/${encodeURIComponent(String(params.firmwareType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetFirmwareListSensorStringSensorIdFirmwaresStringFirmwareTypeParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `firmware_type`: Firmware type
   */
  getFirmwareListSensorStringSensorIdFirmwaresStringFirmwareType(params: SensorService.GetFirmwareListSensorStringSensorIdFirmwaresStringFirmwareTypeParams): __Observable<null> {
    return this.getFirmwareListSensorStringSensorIdFirmwaresStringFirmwareTypeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorService.PutSensorSettingSupportsPartialResultsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  PutSensorSettingSupportsPartialResultsResponse(params: SensorService.PutSensorSettingSupportsPartialResultsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/sensor/${encodeURIComponent(String(params.sensorId))}/settings/supports_partial_results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SensorService.PutSensorSettingSupportsPartialResultsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  PutSensorSettingSupportsPartialResults(params: SensorService.PutSensorSettingSupportsPartialResultsParams): __Observable<null> {
    return this.PutSensorSettingSupportsPartialResultsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SensorService.GetSensorSettingSupportsPartialResultsParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetSensorSettingSupportsPartialResultsResponse(params: SensorService.GetSensorSettingSupportsPartialResultsParams): __Observable<__StrictHttpResponse<Array<Sensor_setting_supports_partial_results_out>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sensor/${encodeURIComponent(String(params.sensorId))}/settings/supports_partial_results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Sensor_setting_supports_partial_results_out>>;
      })
    );
  }
  /**
   * @param params The `SensorService.GetSensorSettingSupportsPartialResultsParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetSensorSettingSupportsPartialResults(params: SensorService.GetSensorSettingSupportsPartialResultsParams): __Observable<Array<Sensor_setting_supports_partial_results_out>> {
    return this.GetSensorSettingSupportsPartialResultsResponse(params).pipe(
      __map(_r => _r.body as Array<Sensor_setting_supports_partial_results_out>)
    );
  }
}

module SensorService {

  /**
   * Parameters for getShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsv
   */
  export interface GetShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvParams {

    /**
     * 1=Internal, 2=External or "all"
     */
    organizationType: string;

    /**
     * csv
     */
    csv: string;
  }

  /**
   * Parameters for getShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvStringTimezone
   */
  export interface GetShieldFirmwareStatusCsvSensorFirmwareStatusStringOrganizationTypeStringCsvStringTimezoneParams {

    /**
     * 1=Internal, 2=External or "all"
     */
    organizationType: string;

    /**
     * csv
     */
    csv: string;

    /**
     * Asia-Calcutta
     */
    timezone: string;
  }

  /**
   * Parameters for getShieldFirmwareStatusSensorFirmwareStatusStringOrganizationTypeIntPageIntPerPage
   */
  export interface GetShieldFirmwareStatusSensorFirmwareStatusStringOrganizationTypeIntPageIntPerPageParams {

    /**
     * 1=Internal, 2=External or "all"
     */
    organizationType: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;
  }

  /**
   * Parameters for GetShieldLEDIntensities
   */
  export interface GetShieldLEDIntensitiesParams {

    /**
     * ISO 8601 start date/time or "none" (inclusive)
     */
    startDate: string;

    /**
     * ISO 8601 end data/time or "none" (inclusive)
     */
    endDate: string;

    /**
     * Sensor ID
     */
    sensorId: string;

    /**
     * Such as "lead"
     */
    metalName: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetShieldTestStatusBasedOnTheSensorId
   */
  export interface GetShieldTestStatusBasedOnTheSensorIdParams {

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringOperatingStatus
   */
  export interface GetShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringOperatingStatusParams {

    /**
     * 0,1,2,3,4,all
     */
    operatingStatus: string;

    /**
     * 0=All customers
     */
    customerId: number;

    /**
     * 0=All sensors
     */
    sensorId: number;

    /**
     * all=All alerts, internal, customer,
     */
    alertType: string;

    /**
     * 0=All alerts
     */
    starttime: number;

    /**
     * 0=All alerts
     */
    endtime: number;

    /**
     * csv
     */
    csv: string;
  }

  /**
   * Parameters for getShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringTimezoneStringOperatingStatus
   */
  export interface GetShieldAlertsCsvSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeStringCsvStringTimezoneStringOperatingStatusParams {

    /**
     * 0,1,2,3,4,all
     */
    operatingStatus: string;

    /**
     * 0=All customers
     */
    customerId: number;

    /**
     * 0=All sensors
     */
    sensorId: number;

    /**
     * all=All alerts, internal, customer,
     */
    alertType: string;

    /**
     * 0=All alerts
     */
    starttime: number;

    /**
     * 0=All alerts
     */
    endtime: number;

    /**
     * csv
     */
    csv: string;

    /**
     * Asia-Kolkata
     */
    timezone: string;
  }

  /**
   * Parameters for getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueStringOperatingStatus
   */
  export interface GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueStringOperatingStatusParams {

    /**
     * 0,1,2,3,4,all
     */
    operatingStatus: string;

    /**
     * 0=All customers
     */
    customerId: number;

    /**
     * 0=All sensors
     */
    sensorId: number;

    /**
     * all=All alerts, internal, customer,
     */
    alertType: string;

    /**
     * 0=All alerts
     */
    starttime: number;

    /**
     * 0=All alerts
     */
    endtime: number;

    /**
     * column on which we need to sort, 'none' if no sorting
     */
    sortColumn: string;

    /**
     * column on which we need to search
     */
    searchColumn: string;
    page: number;
    perPage: number;
    order: string;
    searchValue: string;
  }

  /**
   * Parameters for getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSortStringSortColumnStringOrderStringOperatingStatus
   */
  export interface GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageSortStringSortColumnStringOrderStringOperatingStatusParams {

    /**
     * 0,1,2,3,4,all
     */
    operatingStatus: string;

    /**
     * 0=All customers
     */
    customerId: number;

    /**
     * 0=All sensors
     */
    sensorId: number;

    /**
     * all=All alerts, internal, customer,
     */
    alertType: string;

    /**
     * 0=All alerts
     */
    starttime: number;

    /**
     * 0=All alerts
     */
    endtime: number;

    /**
     * column on which we need to sort
     */
    sortColumn: string;

    /**
     * asc/desc
     */
    order: string;
    page: number;
    perPage: number;
  }

  /**
   * Parameters for getShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageStringOperatingStatus
   */
  export interface GetShieldAlertsSensorShieldIntCustomerIdSensorIntSensorIdStringAlertTypeIntStarttimeIntEndtimeIntPageIntPerPageStringOperatingStatusParams {

    /**
     * 0,1,2,3,4,all
     */
    operatingStatus: string;

    /**
     * 0=All customers
     */
    customerId: number;

    /**
     * 0=All sensors
     */
    sensorId: number;

    /**
     * all=All alerts, internal, customer,
     */
    alertType: string;

    /**
     * 0=All alerts
     */
    starttime: number;

    /**
     * 0=All alerts
     */
    endtime: number;
    page: number;
    perPage: number;
  }

  /**
   * Parameters for getShieldSensorsAllSensorShieldIntPageIntPerPage
   */
  export interface GetShieldSensorsAllSensorShieldIntPageIntPerPageParams {

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * An optional fields mask
     */
    XFields?: string;

    /**
     * 1=Internal, 2=External or "all"
     */
    organizationType?: string;
  }

  /**
   * Parameters for getShieldSensorsSensorShieldIntSensorId
   */
  export interface GetShieldSensorsSensorShieldIntSensorIdParams {

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for SaveSensorBitmap
   */
  export interface SaveSensorBitmapParams {
    payload: Sensor_bitmap;

    /**
     * Sensor ID
     */
    sensorId: number;
  }

  /**
   * Parameters for getShieldSensorsExtSensorShieldIntSensorIdExt
   */
  export interface GetShieldSensorsExtSensorShieldIntSensorIdExtParams {

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsv
   */
  export interface GetShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvParams {

    /**
     * 1=Internal, 2=External or "all"
     */
    organizationType: string;

    /**
     * csv
     */
    csv: string;
  }

  /**
   * Parameters for getShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvStringTimezone
   */
  export interface GetShieldSummaryCsvSensorSummaryStringOrganizationTypeStringCsvStringTimezoneParams {

    /**
     * 1=Internal, 2=External or "all"
     */
    organizationType: string;

    /**
     * csv
     */
    csv: string;

    /**
     * Asia-Calcutta
     */
    timezone: string;
  }

  /**
   * Parameters for getShieldSummarySensorSummaryStringOrganizationTypeIntPageIntPerPage
   */
  export interface GetShieldSummarySensorSummaryStringOrganizationTypeIntPageIntPerPageParams {

    /**
     * 1=Internal, 2=External or "all"
     */
    organizationType: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;
  }

  /**
   * Parameters for getFirmwareListSensorStringSensorIdFirmwaresStringFirmwareType
   */
  export interface GetFirmwareListSensorStringSensorIdFirmwaresStringFirmwareTypeParams {

    /**
     * Sensor ID
     */
    sensorId: string;

    /**
     * Firmware type
     */
    firmwareType: string;
  }

  /**
   * Parameters for PutSensorSettingSupportsPartialResults
   */
  export interface PutSensorSettingSupportsPartialResultsParams {
    payload: Sensor_setting_supports_partial_results_in;
    sensorId: number;
  }

  /**
   * Parameters for GetSensorSettingSupportsPartialResults
   */
  export interface GetSensorSettingSupportsPartialResultsParams {
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { SensorService }
