/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { User_action_response } from '../models/user-_action-_response';
import { Sensor_config } from '../models/sensor-_config';
import { User_action } from '../models/user-_action';

/**
 * User Actions related operations
 */
@Injectable({
  providedIn: 'root',
})
class UserActionsService extends __BaseService {
  static readonly getUserActionsPath = '/user_actions';
  static readonly getGetUserActionsListPath = '/user_actions/get/list/{page}/{per_page}';
  static readonly getGetUserActionsPath = '/user_actions/get/{sensor_id}';
  static readonly getGetHardwareTestingStatusPath = '/user_actions/hwts/{sensor_id}';
  static readonly postUserActionsResponsePath = '/user_actions/response';
  static readonly SaveSensorConfigPath = '/user_actions/sensor_config/{sensor_id}';
  static readonly postTriggerUserActionsPath = '/user_actions/trigger/{sensor_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param sensor_ids List of sensor ids
   */
  getUserActionsResponse(sensorIds?: Array<number>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user_actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_ids List of sensor ids
   */
  getUserActions(sensorIds?: Array<number>): __Observable<null> {
    return this.getUserActionsResponse(sensorIds).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserActionsService.GetGetUserActionsListParams` containing the following parameters:
   *
   * - `page`:
   *
   * - `per_page`:
   */
  getGetUserActionsListResponse(params: UserActionsService.GetGetUserActionsListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user_actions/get/list/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserActionsService.GetGetUserActionsListParams` containing the following parameters:
   *
   * - `page`:
   *
   * - `per_page`:
   */
  getGetUserActionsList(params: UserActionsService.GetGetUserActionsListParams): __Observable<null> {
    return this.getGetUserActionsListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param sensor_id undefined
   */
  getGetUserActionsResponse(sensorId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user_actions/get/${encodeURIComponent(String(sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_id undefined
   */
  getGetUserActions(sensorId: string): __Observable<null> {
    return this.getGetUserActionsResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param sensor_id undefined
   */
  getGetHardwareTestingStatusResponse(sensorId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user_actions/hwts/${encodeURIComponent(String(sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_id undefined
   */
  getGetHardwareTestingStatus(sensorId: string): __Observable<null> {
    return this.getGetHardwareTestingStatusResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  postUserActionsResponseResponse(payload: User_action_response): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user_actions/response`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postUserActionsResponse(payload: User_action_response): __Observable<null> {
    return this.postUserActionsResponseResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserActionsService.SaveSensorConfigParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  SaveSensorConfigResponse(params: UserActionsService.SaveSensorConfigParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user_actions/sensor_config/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserActionsService.SaveSensorConfigParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  SaveSensorConfig(params: UserActionsService.SaveSensorConfigParams): __Observable<null> {
    return this.SaveSensorConfigResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserActionsService.PostTriggerUserActionsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  postTriggerUserActionsResponse(params: UserActionsService.PostTriggerUserActionsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user_actions/trigger/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserActionsService.PostTriggerUserActionsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  postTriggerUserActions(params: UserActionsService.PostTriggerUserActionsParams): __Observable<null> {
    return this.postTriggerUserActionsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserActionsService {

  /**
   * Parameters for getGetUserActionsList
   */
  export interface GetGetUserActionsListParams {
    page: number;
    perPage: number;
  }

  /**
   * Parameters for SaveSensorConfig
   */
  export interface SaveSensorConfigParams {
    payload: Sensor_config;
    sensorId: string;
  }

  /**
   * Parameters for postTriggerUserActions
   */
  export interface PostTriggerUserActionsParams {
    payload: User_action;
    sensorId: string;
  }
}

export { UserActionsService }
