import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';


@Injectable({
    providedIn:'root'
})
export class SeasonService {

  private dataSource = new BehaviorSubject<String>(null);
  data = this.dataSource.asObservable();
  year = this.dataSource.asObservable();

  constructor() { }

  updatedDataSelection(data){
    this.dataSource.next(data);
  }
  updatedYearSelection(year){
      this.dataSource.next(year)
  }
  
}