import {CachedBaseService} from './cached-base.service';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {Shield_threshold} from '../../../../api/src/lib/models/shield-_threshold';
import {ShieldThresholdService} from '../../../../api/src/lib/services';
import {ExtendedConfigService} from '../../../../api/src/lib/extended-services/config.service';
import {map} from 'rxjs/operators';
import * as cloneDeep from 'lodash/cloneDeep';
import {KetosSocketActionEnum, KetosSocketEventEnum, KetosSocketService} from './ketos-socket.service';
import {WaterSourcesCachedService} from './water-sources-cached.service';
import {Experiments} from '../../../../api/src/lib/models/experiments';

export enum ThresholdTypeEnum {
  internal = 1,
  external = 2
}

export interface ThresholdDictInterface {
  [key: string]: {
    [key: string]: Shield_threshold
  };
}

export class ThresholdsCachedService extends CachedBaseService {
  subject: ReplaySubject<Shield_threshold[]>;
  thresholdType: ThresholdTypeEnum;
  thresholds: Shield_threshold[];
  thresholdDict: ThresholdDictInterface;
  socketSub: Subscription;
  filteredExperiments: Experiments[];

  constructor(
    private waterSourcesCachedService: WaterSourcesCachedService,
    private shieldThresholdService: ShieldThresholdService,
    private configService: ExtendedConfigService,
    thresholdType: ThresholdTypeEnum,
    ketosSocketService?: KetosSocketService,
    orgId?: number,
    ) {
    super();
    this.thresholdType = thresholdType;
    this.configService.getExperiments({}).pipe(map(res =>{
      return this.removeDuplicates(res, 'experiment').filter(exp => exp.method !== 'lab' && exp.experiment !== 'ambient_temperature' && exp.method !== 'w1' && exp.method !== 'k1' && exp.method !== 'a1' && exp.method !== 'b1');
    }))
      .subscribe((res: any) => {
        this.filteredExperiments = res;
      });
    if (ketosSocketService && orgId) {
      this.socketSub = ketosSocketService.getSubjectForRoom(orgId, KetosSocketEventEnum.shield_threshold)
        .subscribe( data => {
          this.updateFromSocket(data);
        }
      );
    }

  }

  // public getAllThresholdsForSensorId(waterSourceId: number): { [key: string]: Shield_threshold } {
  //   const responseDict: { [key: string]: Shield_threshold } = {};
  //   this.configService.getExperiments({}).pipe(map(res =>{
  //     return this.removeDuplicates(res, "experiment").filter(exp => exp.method !== 'lab' && exp.experiment !== 'ambient_temperature' && exp.method !== 'w1' && exp.method !== 'k1' && exp.method !== 'a1' && exp.method !== 'b1')
  //   }))
  //     .subscribe((res:any) => {
  //       this.filteredExperiments = res
  //       res.forEach(exp => {
  //         responseDict[exp.experiment] = this.getShieldThresholdForSensorIdAndExperiment(waterSourceId, exp.experiment,exp);
  //       })
  //     })
  //   return responseDict;
  // }
  public getAllThresholdsForSensorId(waterSourceId: number): { [key: string]: Shield_threshold } {
    const responseDict: { [key: string]: Shield_threshold } = {};

    for (const exp of this.filteredExperiments) {
      responseDict[exp.experiment] = this.getShieldThresholdForSensorIdAndExperiment(waterSourceId, exp.experiment, exp);
    }

    return responseDict;
  }

  removeDuplicates(arr, prop) {
    const uniqueArray = [];
    const map = new Map();

    for (const obj of arr) {
      const key = obj[prop];
      if (!map.has(key)) {
        map.set(key, true);
        uniqueArray.push(obj);
      }
    }

    return uniqueArray;
  }

  public getStatusForThresholdSensorIdAndExperimentByMinAndMax(value: number, waterSourceId: number, experiment: string): string {
      const threshold = this.getShieldThresholdForSensorIdAndExperiment(waterSourceId,experiment);
      if (threshold && typeof value === 'number') {
        if (typeof threshold?.min_value === 'number' && value < threshold.min_value) {
          return 'low';
        }
        if (typeof threshold?.max_value === 'number' && value > threshold.max_value) {
          return 'high';
        }
      }
      return 'ok';
  }

  public getShieldThresholdForSensorIdAndExperiment(waterSourceId: number, experiment:string, exp?: Experiments): Shield_threshold {
    if (this.thresholdDict[waterSourceId]?.hasOwnProperty(experiment)) {
      return this.thresholdDict[waterSourceId][experiment];
    }
    return this.getDefaultThreshold(waterSourceId, experiment,exp);
  }

  public getDefaultThreshold(waterSourceId: number, experiment: string, exp?: Experiments) {
    let expeirmentData: Experiments;
    if(!exp){
      expeirmentData = this.filteredExperiments.filter(exp => exp.experiment === experiment)[0]
    }else{
      expeirmentData = exp
    }
    const defaultThreshold: Shield_threshold = {
      sensor_id: this.waterSourcesCachedService.dictById[waterSourceId]?.sensor?.id,
      water_source_id: waterSourceId,
      experiment: experiment,
      threshold_type: this.thresholdType,
      max_value: expeirmentData?.defaultThreshold?.external?.max,
      min_value: expeirmentData?.defaultThreshold?.external?.min
    };
    return defaultThreshold;
  }


  public getThresholdsForSensorIdsAndExperiment(waterSourceIds: number[], experiment: string): Shield_threshold[] {
    const thresholds: Shield_threshold[] = [];
    for (const waterSourceId of waterSourceIds) {
      thresholds.push(cloneDeep(this.getShieldThresholdForSensorIdAndExperiment(waterSourceId, experiment)));
    }
    return thresholds;
  }

  // cached logic

  getCached(live = true, forceRefresh = false): Observable<Shield_threshold[]> {
    return super.getCachedBase(live, forceRefresh);
  }

  fetchData(): Observable<Shield_threshold[]> {
    return this.shieldThresholdService.getShieldAllThresholdsDeprecatedShieldThresholdShieldIntThresholdType({thresholdType: this.thresholdType})
      .pipe(
        map(res => {
          this.thresholds = res as unknown as Shield_threshold[];
          this.thresholds.map((th:any) => {
            if(th.max_value){
              th.max_value = parseFloat(th.max_value)
            }
            if(th.min_value){
              th.min_value = parseFloat(th.min_value)
            }
          })
          const thresoldDict: ThresholdDictInterface = {};
          for (const threshold of this.thresholds) {
            if (!thresoldDict.hasOwnProperty(threshold.water_source_id)) {
              thresoldDict[threshold.water_source_id] = {};
            }
            thresoldDict[threshold.water_source_id][threshold.experiment] = threshold;
          }
          this.thresholdDict = thresoldDict;
          this.subject.next(this.thresholds);
          // console.debug('this.thresholdss', this.thresholdDict);
          return this.thresholds;
        })
      );
  }

  close() {
    this.subject.complete();
    this.subject = null;
    if (this.socketSub) {
      this.socketSub.unsubscribe();
      this.socketSub = null;
    }
  }

  // live logic
  updateFromSocket(data: {action: KetosSocketActionEnum, event_type: KetosSocketEventEnum, organization_id: number, room: string, sensor_id: string, message: {shield_threshold: Shield_threshold} }) {
    if (data.message.shield_threshold.threshold_type !== this.thresholdType) {
      return;
    }
    if (data.action === KetosSocketActionEnum.write) {
      this.thresholds.push(data.message.shield_threshold);
      if (!this.thresholdDict.hasOwnProperty(data.message.shield_threshold.water_source_id)) {
        this.thresholdDict[data.message.shield_threshold.water_source_id] = {};
      }
      this.thresholdDict[data.message.shield_threshold.water_source_id][data.message.shield_threshold.experiment] = data.message.shield_threshold;
      this.subject.next(this.thresholds);
    } else if (data.action === KetosSocketActionEnum.update) {
      const idx = this.thresholds.findIndex(th => th.id === data.message.shield_threshold.id);
      if (idx !== -1) {
        this.thresholds[idx] = data.message.shield_threshold;
      }
      this.thresholdDict[data.message.shield_threshold.water_source_id][data.message.shield_threshold.experiment] = data.message.shield_threshold;
      this.subject.next(this.thresholds);
    }
  }
}
