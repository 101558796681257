/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Routes } from '@angular/router';
import {UnauthGuard} from '../../../ketos-lib/src/lib/guards/unauth.guard';

export const AppRoutes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('../../../ketos-lib/src/lib/pages/login/login.module').then(m => m.LoginModule),
    data: {title: 'Login'},
    canActivate: [UnauthGuard]
  },
  {
    path: 'login/:existingUser',
    loadChildren: () => import('../../../ketos-lib/src/lib/pages/login/login.module').then(m => m.LoginModule),
    data: {title: 'Login'},
    canActivate: [UnauthGuard]
  },
  {
    path: 'forgot/:useremail',
    loadChildren: () => import('../../../ketos-lib/src/lib/pages/forgot-modal/forgot-modal.module').then(m => m.ForgotModalModule),
    data: {title: 'Forgot Password'},
    canActivate: [UnauthGuard]
  },
  {
    path: 'water-safety-kit',
    loadChildren: () => import('../../../ketos-lib/src/lib/pages/water-safety-kit/water-safety-kit.module').then(mod => mod.WaterSafetyKitModule),
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('../../../ketos-lib/src/lib/pages/main/main.module').then(m => m.MainModule),
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: ''
  }
];
