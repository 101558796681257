import {Observable, of, ReplaySubject, Subject} from 'rxjs';
import {flatMap, map, take} from 'rxjs/operators';

export abstract class CachedBaseService {
  abstract subject: ReplaySubject<any>;

  constructor() {
  }

  public getCachedBase(live = true, forceRefresh = false): Observable<any> {
    // console.log('getShieldSensors', live, forceRefresh);
    if (!this.subject) {
      this.subject = new ReplaySubject<any[]>(1);
      this.fetchData().subscribe(res => {
      });
    } else if (forceRefresh) {
      return this.fetchData().pipe(
        flatMap(res => {
          if (live) {
            return this.subject;
          } else {
            return of(res);
          }
        })
      ) as Observable<any[]>;
    }
    if (live) {
      return this.subject;
    } else {
      return this.subject.pipe(take(1));
    }
  }

  abstract getCached(live: boolean, forceRefresh: boolean, filter1?: any): Observable<any>;
  abstract fetchData(): Observable<any>;
}
