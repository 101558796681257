/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../../../../ketos-lib/src/lib/services/auth.service';
import {KetosPopupService} from '../../../../ketos-lib/src/lib/services/ketos-popup.service';

@Injectable()
export class LicenseGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private ketosPopupsService: KetosPopupService) {
  }

  canActivate() {
    // @todo Check user info here
    if (this.authService.getUser().premium) {
      return true;
    } else {
      this.ketosPopupsService.confirmPopup({
        ok: 'Ok',
        title: '',
        message: 'Predictive Modeler Requires Premium License'
      });

      return false;
    }
  }
}
