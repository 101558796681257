/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import { CalendarValueInterface } from '../calendar.page';
import {CalendarModalComponent} from './calendar-modal.component';

@Component({
  selector: 'lib-modal-calendar',
  templateUrl: './modal-calendar.component.html',
  styleUrls: ['./modal-calendar.component.scss']
})
export class ModalCalendarComponent implements OnInit {

  private _value: CalendarValueInterface | Date;
  @Input() set value (value: CalendarValueInterface | Date) {
    this._value = value;
    // this.init();
  }
  get value(): CalendarValueInterface | Date {
    return this._value;
  }
  @Input() fromRTDmobile: boolean;
  @Input() selectRange: boolean;  // note: if selectRange = false then typeof value = Date
  @Input() forceMinutes: boolean;
  @Input() calendarTitle: string;
  @Input() hidePreviousDates: boolean;
  @Input() hideFutureDates: boolean;
  @Input() isCalendarVisible: boolean;
  @Input() emitOnDateChange: boolean;
  @Input() hideMinutes: boolean;
  @Input() showTodayButton: boolean = false;
  @Output() isCalendarVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() calendarRight: boolean;
  @Output() dateSelected: EventEmitter<CalendarValueInterface | Date> =
    new EventEmitter<CalendarValueInterface | Date>();

  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }

  async openCalendar() {
    const modal = await this.modalController.create({
      component: CalendarModalComponent,
      componentProps: {
        showTodayButton:this.showTodayButton,
        value: this._value,
        selectRange: this.selectRange,
        forceMinutes: this.forceMinutes,
        hidePreviousDates: this.hidePreviousDates,
        hideFutureDates: this.hideFutureDates,
        hideMinutes: this.hideMinutes,
      }
    });

    modal.onDidDismiss().then(modalData => {
      // console.log('calendar modal onDidDismiss', modalData);
      if (modalData.data) {
        this.dateSelected.emit(modalData.data);
      }
    });
    await modal.present();
  }
}
