import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TimeIntervalInterface} from '../../services/app-state.service';
import {CurrentRoundedIntervalIfLive} from '../../utils/TimeHelper';
import * as moment from 'moment';
import { flatMap, map, switchMap } from 'rxjs/operators';
import {forkJoin, Observable, of, Subscription, timer} from 'rxjs';
import {ResultsCachedService} from '../../services/results-cached.service';
import {ResultsService} from '../../../../../api/src/lib/services/results.service';
import {KetosSocketEventEnum, KetosSocketService} from '../../services/ketos-socket.service';
import {ResultAggregatesService} from '../../../../../api/src/lib/services/result-aggregates.service';
import {Shield_results} from '../../../../../api/src/lib/models/shield-_results';
import {DashboardUiStateService} from '../../services/dashboard-ui-state.service';
import {KetosGraphDataInterface, KetosGraphSeriesSettingsInterface} from './ketos-graph-base';
import {WaterSourcesCachedService} from '../../services/water-sources-cached.service';
import {Shield_result_aggregates} from '../../../../../api/src/lib/models/shield-_result-_aggregates';
import {ShieldDevicesCachedService} from '../../services/shield-devices-cached.service';
import {ExperimentConfigurationsService} from '../../services/experiment-configurations.service';
import {Aggregate_results} from '../../../../../api/src/lib/models/aggregate-_results';
import { Shield_forecast } from '../../../../../api/src/lib/models/shield-_forecast';
import {
  OrganizationCustomParamsSettingsCachedService
} from '../../services/organization-customParams-settings.cached.service';
import { round } from '@popperjs/core/lib/utils/math';

export interface ChartSeriesInterface {
  key: string;
  title: string;
  unit: string;
  value?: string;
  checked?: boolean;
}

export interface GraphOptionsInterface {
  title?: string;
  zoomDisabled?: boolean;
  seperateYaxis?: boolean;
  ymin?: number;
  ymax?: number;
  live?: boolean;
  connectingLine?: number;
  pointSize?: number;
  emitSvgOnRedraw?: boolean;
  leftAlignedTitle?: boolean;

  exp?: string;

  bit?: number;
}



export interface DataCorelationSettingsInterface {
  //experiments: string[];
  dateRange?: TimeIntervalInterface;
  sensorIds?: number[],
  experiments?: string[]
  //selectedInterval: string;
  // preSelectedExperiments?: DropdownValueInterface[];
  // selectedLocation: DropdownValueInterface[];
  // options?: GraphOptionsInterface;
}

export interface ChartSettingsInterface {
  experiments: string[];
  dateRange: TimeIntervalInterface;
  originalDateRange?: TimeIntervalInterface;
  waterSourceIds?: number[];
  options?: GraphOptionsInterface;
}

@Component({
  selector: 'lib-ketos-graph',
  templateUrl: './ketos-graph.page.html',
  styleUrls: ['./ketos-graph.page.scss'],
})
export class KetosGraphPage implements OnInit, OnDestroy {

  @Output() chartSVG: EventEmitter<Object> = new EventEmitter<Object>();
  @Input() headerWidth: number;
  @Input() height = 600;
  @Input() multiGraph = false;
  @Input() mobileDesign: boolean;
  @Input() allExperiments: string[];
  @Input() showLineCharts: boolean;
  @Input() reflowGraph: boolean;
  @Input() noInputsText = 'Please select contaminant(s) and location';

  @Output() dataLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  _forecastEnabled = false;
  @Input() set forecastEnabled(value: boolean) {
    this._forecastEnabled = value;
    // Clear the forecast data since we may check for it's existence in other parts of the code
    if (!this._forecastEnabled) {
      this.ketosGraphData.forecast = null;
    }
    this.fetchData();
  }

  get forecastEnabled(): boolean {
    return this._forecastEnabled;
  }

  noDataText = 'No data for current filter applied';

  _dateRange: TimeIntervalInterface;
  @Input() set dateRange(value: TimeIntervalInterface) {
    if (value === undefined || value === null) {
      return;
    }
    this._dateRange = value;
    this.fetchData();
    this.initSocketWatch();
  }

  get dateRange(): TimeIntervalInterface {
    return this._dateRange;
  }

  _selectedExperiments: string[];
  @Input() set selectedExperiments(value: string[]) {
    if (value === undefined) {
      return;
    }
    this._selectedExperiments = value;
    if (!value || value?.length === 0) {

      if (this.ketosGraphData) {
        this.ketosGraphData.seriesSettings = [];
      }
    }
    this.fetchData();
  }

  get selectedExperiments(): string[] {
    return this._selectedExperiments;
  }

  @Input() selectedGraphOptions: GraphOptionsInterface;

  _selectedWaterSourceIds: number[];
  @Input() set selectedWaterSourceIds(value: number[]) {
    if (value === undefined) {
      return;
    }
    if (!value || value?.length === 0) {
      if (this.ketosGraphData) {
        this.ketosGraphData.seriesSettings = [];
      }
      this._selectedWaterSourceIds = [];
    } else {
      this._selectedWaterSourceIds = value;
    }
    this.fetchData();
  }

  get selectedWaterSourceIds(): number[] {
    return this._selectedWaterSourceIds;
  }

  @Input() fallbackWaterSourceIds: number[];

  public updatedOn: Date;
  updatedOnTimerSub: Subscription;

  public displayDateMin: Date;
  public displayDateMax: Date;

  initDone = false;

  // tmp
  //noLocationSelected=true;
  //noExperimentOrLocationSelected=true;
  results: Shield_results;
  forecast: Shield_forecast;
  ketosGraphData: KetosGraphDataInterface;
  aggregates;
  public visibleDateRange: TimeIntervalInterface;
  aggregateInterval: string = null;
  scheduledTypes: string[];

  showingAggregates: boolean = null;

  showZoomButton = false;
  zoomSubscription: Subscription;
  ignoreUpdates = true;
  watchingId: number;
  socketSub: Subscription;

  constructor(public resultsCachedService: ResultsCachedService,
              private resultsService: ResultsService,
              public dashboardUiStateService: DashboardUiStateService,
              private ketosSocketService: KetosSocketService,
              private resultAggregatesService: ResultAggregatesService,
              private waterSourcesCachedService: WaterSourcesCachedService,
              private shieldDevicesCachedService: ShieldDevicesCachedService,
              private experimentConfigurationsService: ExperimentConfigurationsService,
              ) {

  }

  ngOnInit() {
    this.ketosGraphData = {
      forecastEnabled: this._forecastEnabled,
      noDataText: this.noInputsText,
      allExperiments: null,
      scheduledExperiments: null,
      dateInterval: null,
      seriesSettings: null,
      waterSourceIds: null
    };
    this.scheduledTypes = this.experimentConfigurationsService.allExperiments.filter(exp => typeof exp.bit === 'number' || exp.requiredBits?.length > 0).map(exp => exp.experiment);
  }

  fetchData(updateFromSocket = false, dateIntervalZoomOverride?: TimeIntervalInterface) {
    if (!this._selectedExperiments || this._selectedExperiments?.length < 1 || this._dateRange === undefined || !this._selectedWaterSourceIds || this._selectedWaterSourceIds?.length < 1) {
      this.ketosGraphData = {
        forecastEnabled: this._forecastEnabled,
        noDataText: this.noInputsText,
        allExperiments: null,
        scheduledExperiments: null,
        dateInterval: null,
        seriesSettings: null,
        waterSourceIds: null
      };
      return;
    }

    if (this.zoomSubscription) {
      // console.log('zoom detected while zooming, aborting zoom:', updateFromSocket, dateIntervalZoomOverride);
      return;
    }

    this.ignoreUpdates = true;
    this.showingAggregates = null;

    // Save date interval if switching to forecast mode
    // Restore date interval if switching back from forecast mode
    let intervalDate;
    intervalDate = dateIntervalZoomOverride || CurrentRoundedIntervalIfLive(this.dateRange, this.selectedGraphOptions?.live);

    this.displayDateMin = intervalDate.min;
    this.displayDateMax = intervalDate.max;
    if (this.selectedExperiments?.length === 0) {
      this.showingAggregates = false;
      return;
    }
    const minDate = moment(intervalDate.min).utc(false).format();
    const maxDate = moment(intervalDate.max).utc(false).format();
    const dateObj: ResultsService.GetShieldResultsParams = {
      startTimestamp: minDate,
      endTimestamp: maxDate,
      waterSourceIds: this.selectedWaterSourceIds,
      experiments: this.selectedExperiments, // ph, conductivity have data
      timeZone: 'UTC' + moment().format('Z'),
      operatingStatuses: [1],
      page: 1,
      perPage: 9
    };
    // this.resultsService.GetPaginatedResultsGivenOptionalSensorIdsAndOptionalExperimentTypes_1(dateObj)
    const subscription = this.resultsService.getShieldResults(dateObj)
      .pipe(
        flatMap(res => {
          // TODO SW-7879 Remove after testing
          // let result$;
          if (res.total > 100) {
            // if (this.showLineCharts) {
            //   return this.fetchAndSetAggregatedData(updateFromSocket, this.selectedExperiments, intervalDate);
            // }
            // SW-7879 This could be where we check the button and maybe pass it
            return this.fetchAndShowHistogram(this.selectedExperiments, intervalDate, this._forecastEnabled);
          } else {
            return this.fetchAndShowResultsData(this.selectedExperiments, intervalDate, this._forecastEnabled);
          }
          // this.displayDateMax = intervalDate.max;
          // this.displayDateMin = intervalDate.min;
          // this._dateRange.max = intervalDate.max;
          // this._dateRange.min = intervalDate.min;
          //
          // return result$;
        })
      )
      .subscribe(res => {
        this.showZoomButton = !!dateIntervalZoomOverride;
        if (dateIntervalZoomOverride) {
          if (this.updatedOnTimerSub) {
            this.updatedOnTimerSub.unsubscribe();
            this.updatedOnTimerSub = null;
          }
          this.stopSocketWatch();
          this.zoomSubscription = null;
        } else {
          this.updatedOn = new Date();
          if (!this.updatedOnTimerSub) {
            this.updatedOnTimerSub = timer(1000 + Math.floor(Math.random() * 29000), 30000)
              .subscribe(num => {
                this.updatedOn = new Date(this.updatedOn);
              })
          }
          if (!this.socketSub) {
            this.initSocketWatch();
          }
        }
        this.ignoreUpdates = false;
        this.dataLoaded.emit(true);
      });
    if (!!dateIntervalZoomOverride) {
      this.zoomSubscription = subscription;
    } else {
      this.zoomSubscription?.unsubscribe();
      this.zoomSubscription = null;
    }
  }

  fetchAndShowResultsData(experiments: string[], intervalDate: TimeIntervalInterface, forecastEnabled: boolean): Observable<number[][]> {
    this.aggregateInterval = null;
    this.showingAggregates = false;

    const dateObj: ResultsService.GetShieldResultsParams = {
      startTimestamp: moment(intervalDate.min).utc(false).format(),
      endTimestamp: moment(intervalDate.max).utc(false).format(),
      waterSourceIds: this.selectedWaterSourceIds,
      experiments: this.selectedExperiments, // ph, conductivity have data
      operatingStatuses: [1],
      timeZone: 'UTC' + moment().format('Z'),
      includeShieldTestSchedule: true,
      page: 1,
      perPage: 100
    };

    return this.resultsService.getShieldResults(dateObj)
      .pipe(
        switchMap(res => {
          if (!forecastEnabled) {
            if (!res || !res.items || res.items.length === 0) {
              return of([]);
            }
          }

          if (forecastEnabled) {
            // Get interval for forecasting
            const differenceMilliseconds = (intervalDate.max.getTime() - intervalDate.min.getTime()) / 2;
            const intervalMilliseconds = Math.floor(differenceMilliseconds / 7);

            const items = this.selectedWaterSourceIds.map(wsId => ({
              water_source_id: wsId,
              experiments: this.selectedExperiments,
              number_of_forecasts: 7,
              frequency_of_forecasts: intervalMilliseconds
            }));

            return this.resultsService.postShieldResultForecast({
              payload: { items: items }
            }).pipe(
              map(forecast => {
                console.log('SW-7879 - FORECAST', forecast);
                // Combine the forecast data with the original results
                return { results: res, forecast: forecast };
              })
            );
          } else {
            // Directly return results without forecast if forecastEnabled is false
            return of({ results: res, forecast: null });
          }
        }),
        map(({ results, forecast }: { results: any, forecast: any }) => {
          // Make room for the forecast by shifting the dates over
          if (forecastEnabled) {
            // SW-7879 - TODO Remove after testing
            // Current time (now)
            // const now = moment();
            // const duration = moment(intervalDate.max).diff(moment(intervalDate.min));
            //
            // // Set the end date to now and adjust the start date to maintain the same duration
            // intervalDate.max = now.toDate();
            // intervalDate.min = now.clone().subtract(duration, 'milliseconds').toDate();
            //
            // // Calculate half the duration
            // const halfDuration = duration / 2;
            //
            // // Adjust both min and max to ensure the midpoint is now
            // intervalDate.min = now.clone().subtract(halfDuration, 'milliseconds').toDate();
            // intervalDate.max = now.clone().add(halfDuration, 'milliseconds').toDate();

            this.displayDateMax = intervalDate.max;
            this.displayDateMin = intervalDate.min;
            this._dateRange.max = intervalDate.max;
            this._dateRange.min = intervalDate.min;
          }

          this.ketosGraphData = {
            forecast: forecast,
            forecastEnabled: forecastEnabled,
            results: results,
            dateInterval: intervalDate,
            allExperiments: this.allExperiments,
            scheduledExperiments: this.scheduledTypes,
            seriesSettings: this.getSeriesSettings('lollipop'),
            waterSourceIds: this._selectedWaterSourceIds.join(','),
            noDataText: this.noDataText
          };

          if (this.showLineCharts && !this.selectedGraphOptions?.emitSvgOnRedraw) {
            this.ketosGraphData.disableColorZones = true;
            this.ketosGraphData.disableThresholds = true;
            this.ketosGraphData.disableDownloading = true;
          }

          this.results = results;
          this.forecast = forecast;
          return [];
        })
      );
  }

  fetchAndShowHistogram(experiments: string[], intervalDate: TimeIntervalInterface, forecastEnabled: boolean): Observable<number[][]> {
    this.aggregateInterval = null;
    this.showingAggregates = false;

    const uniqueIds = [...new Set(this._selectedWaterSourceIds)];
    const queries = uniqueIds.map(waterSourceId => {
      return this.resultsService.getShieldResultsHistogramResultsHistogramIntBuckets({
        startTimestamp: moment(intervalDate.min).utc(false).format(),
        endTimestamp: moment(intervalDate.max).utc(false).format(),
        waterSourceIds: [waterSourceId],
        experiments: this.selectedExperiments,
        operatingStatuses: [1],
        timeZone: 'UTC' + moment().format('Z'),
        buckets: 1000
      });
    });

    return forkJoin(queries)
      .pipe(
        switchMap(resArr => {
          if (!forecastEnabled) {
            if (!resArr.some(res => res && res.items && res.items.length > 0)) {
              return of([]);
            }
          }

          const histogramsMap = {};
          for (let i = 0; i < uniqueIds.length; i++) {
            histogramsMap[uniqueIds[i]] = resArr[i].items;
          }

          if (forecastEnabled) {
            // Get interval for forecasting
            const differenceMilliseconds = (intervalDate.max.getTime() - intervalDate.min.getTime()) / 2;
            const intervalMilliseconds = Math.floor(differenceMilliseconds / 7);

            const items = uniqueIds.map(wsId => ({
              water_source_id: wsId,
              experiments: this.selectedExperiments,
              number_of_forecasts: 7,
              frequency_of_forecasts: intervalMilliseconds
            }));

            return this.resultsService.postShieldResultForecast({
              payload: { items: items }
            }).pipe(
              map(forecast => {
                // SW-7879 - TODO Remove after testing
                // Make room for the forecast by shifting the dates over
                // Current time (now)
                // const now = moment();
                // const duration = moment(intervalDate.max).diff(moment(intervalDate.min));
                //
                // // Set the end date to now and adjust the start date to maintain the same duration
                // intervalDate.max = now.toDate();
                // intervalDate.min = now.clone().subtract(duration, 'milliseconds').toDate();
                //
                // // Calculate half the duration
                // const halfDuration = duration / 2;
                //
                // // Adjust both min and max to ensure the midpoint is now
                // intervalDate.min = now.clone().subtract(halfDuration, 'milliseconds').toDate();
                // intervalDate.max = now.clone().add(halfDuration, 'milliseconds').toDate();

                this.displayDateMax = intervalDate.max;
                this.displayDateMin = intervalDate.min;
                this._dateRange.max = intervalDate.max;
                this._dateRange.min = intervalDate.min;

                this.ketosGraphData = {
                  histograms: histogramsMap,
                  forecast: forecast,
                  forecastEnabled: forecastEnabled,
                  dateInterval: intervalDate,
                  allExperiments: this.allExperiments,
                  scheduledExperiments: this.scheduledTypes,
                  seriesSettings: this.getSeriesSettings('line'),
                  waterSourceIds: this._selectedWaterSourceIds.join(','),
                  noDataText: this.noDataText
                };

                return [];
              })
            );
          } else {
            this.ketosGraphData = {
              histograms: histogramsMap,
              forecast: null,
              forecastEnabled: forecastEnabled,
              dateInterval: intervalDate,
              allExperiments: this.allExperiments,
              scheduledExperiments: this.scheduledTypes,
              seriesSettings: this.getSeriesSettings('line'),
              waterSourceIds: this._selectedWaterSourceIds.join(','),
              noDataText: this.noDataText
            };

            return of([]);
          }
        })
      );
  }

  fetchAndSetAggregatedData(updateFromSocket = false, experiments: string[], intervalDate: TimeIntervalInterface): Observable<number[][]> {
    this.showingAggregates = true;
    const aggregateInteval = this.getAggregateInterval(intervalDate);

    if (this.aggregateInterval === null || this.aggregateInterval !== aggregateInteval) {
      this.aggregateInterval = aggregateInteval;
    }
    const startDateSec = Math.round(intervalDate.min.getTime() / 1000);
    const endDateSec = Math.round(intervalDate.max.getTime() / 1000);

    const uniqueIds = [...new Set(this._selectedWaterSourceIds)];
    console.log('uinqueIds:', this._selectedWaterSourceIds, uniqueIds);
    const queries = uniqueIds.map(waterSourceId => {
      return this.resultAggregatesService.getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceId({
        startDate: startDateSec,
        endDate: endDateSec,
        interval: aggregateInteval,
        waterSourceId: waterSourceId
      });
    });

    return forkJoin(queries)
      .pipe(
        map(resArr => {

          const aggregatesMap: { [key: number]: Shield_result_aggregates } = {};
          for (let i = 0; i < uniqueIds.length; i++) {
            aggregatesMap[uniqueIds[i]] = resArr[i];
          }

          this.ketosGraphData = {
            forecastEnabled: this._forecastEnabled,
            aggregatesMap,
            dateInterval: intervalDate,
            allExperiments: this.allExperiments,
            scheduledExperiments: this.scheduledTypes,
            seriesSettings: this.getSeriesSettings(),
            waterSourceIds: this.selectedWaterSourceIds.join(','),
            noDataText: this.noDataText
          };

          if (this.showLineCharts && !this.selectedGraphOptions?.emitSvgOnRedraw) {
            this.ketosGraphData.disableColorZones = true;
            this.ketosGraphData.disableThresholds = true;
            this.ketosGraphData.disableDownloading = true;
          }

          this.aggregates = resArr[0];
          return [];
        }));
  }

  getSeriesSettings(seriesOverrideType?: string): KetosGraphSeriesSettingsInterface[] {
    const seriesSettings: KetosGraphSeriesSettingsInterface[] = [];
    if (this.selectedWaterSourceIds && this.selectedExperiments) {
      for (const waterSourceId of this.selectedWaterSourceIds) {
        const waterSource = this.waterSourcesCachedService.dictById[waterSourceId]
        for (const exp of this.selectedExperiments) {
          const setting: KetosGraphSeriesSettingsInterface = {
            sensorId: waterSource.sensor_id,
            sensorBitmap: this.shieldDevicesCachedService.dictBySensorId[waterSource.sensor.id]?.exp_bitmap,
            waterSourceId: waterSourceId,
            locationName: waterSource.location_name,
            experimentKey: exp,
          };

          if (this.showLineCharts) {
            setting.seriesTypeOverride = 'spline';
          } else {
            if (seriesOverrideType) {
              setting.seriesTypeOverride = seriesOverrideType;
            }
          }
          seriesSettings.push(setting);
        }
      }
    }
    return seriesSettings;
  }
  getAggregateInterval(intervalDate: TimeIntervalInterface): string {
    // 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year' | 'auto'
    const intervalMs = intervalDate.max.getTime() - intervalDate.min.getTime();
    // console.log('getting interval', intervalMs);
    if (intervalMs < 1000 * 60 * 60 * 24 * 4) { // < 4 days
      // console.log('hour');
      return '1h';
    } else if (intervalMs < 1000 * 60 * 60 * 24 * 7 * 4) { // < 4 weeks
      // console.log('day');
      return '1d';
    } else if (intervalMs < 1000 * 60 * 60 * 24 * 7 * 4 * 3) { // < 3 months
      // console.log('week');
      return '1w';
    } else if (intervalMs < 1000 * 60 * 60 * 24 * 7 * 4 * 12) { // < year
      // console.log('quarter');
      return '1q';
    } else {
      // console.log('year');
      return '1y';
    }
    // return 'hour';
    return 'auto';
  }

  initSocketWatch() {
    // console.log("initSocketWatch",this._dateRange?.defaultValue, this._selectedWaterSources);
    if (!this.showLineCharts && this._selectedWaterSourceIds?.length === 1) {

      // if (this._dateRange?.defaultValue === true && this.selectedGraphOptions && this.selectedGraphOptions?.live === true) {
      if (this.selectedGraphOptions && this.selectedGraphOptions?.live === true) {
        if (this.watchingId !== this._selectedWaterSourceIds[0]) {
          this.socketSub?.unsubscribe();
          this.socketSub = this.ketosSocketService.getSubjectForRoom(this._selectedWaterSourceIds[0], KetosSocketEventEnum.shield_results)
            .subscribe(data => {
               console.log('socket reached graph:', data, this.ignoreUpdates);
              if (this.ignoreUpdates === false && data.message.shield_results?.experiment && this._selectedExperiments?.length > 0) {
                const idx = this._selectedExperiments.indexOf(data.message.shield_results.experiment);
                // console.log('socket reached graph:', data, this.ignoreUpdates, this._selectedExperiments, idx);
                if (idx !== -1) {
                  // console.log('reacting to socket update: ', data);
                  //   intervalDate = CurrentIntervalIfLive(this.dateInterval);
                  //   this.chartOptions.series[idx].data.push([moment.utc(data.message.shield_results.timestamp).valueOf(), data.message.shield_results.value]);
                  //   this.chartOptions.xAxis[0].max = intervalDate.max.getTime();
                  //   this.chartOptions.xAxis[0].min = intervalDate.min.getTime();
                  this.dashboardUiStateService.graphicalViewLiveUpdateUiState.next( {liveUpdateDate:new Date()})

                  if (this.showingAggregates === true) {
                    this.fetchData(true);
                  } else if (this.showingAggregates === false) {
                    if (!this.results['items']) {
                      this.results['items'] = [];
                    }
                    console.log(this.ketosGraphData.results)
                    this.results['items'].push(data.message.shield_results);
                    this.results = Object.assign({}, this.results);
                    // this.ketosGraphData = Object.assign({}, this.ketosGraphData);
                    const intervalDate = CurrentRoundedIntervalIfLive(this.dateRange, this.selectedGraphOptions?.live);
                    this.displayDateMin = intervalDate.min;
                    this.displayDateMax = intervalDate.max;
                    this.ketosGraphData.dateInterval = intervalDate;
                    this.updatedOn = new Date(this.updatedOn);


                    this.ketosGraphData = {
                      forecastEnabled: this._forecastEnabled,
                      results: this.results,
                      dateInterval: intervalDate,
                      allExperiments: this.allExperiments,
                      scheduledExperiments: this.allExperiments,
                      seriesSettings: this.getSeriesSettings('spline'),
                      waterSourceIds: this.selectedWaterSourceIds.join(),
                      noDataText: this.noDataText
                    };

                    if (this.showLineCharts && !this.selectedGraphOptions?.emitSvgOnRedraw) {
                      this.ketosGraphData.disableColorZones = true;
                      this.ketosGraphData.disableThresholds = true;
                      this.ketosGraphData.disableDownloading = true;
                    }




                  }
                  // } else {
                  //   // this.results.
                  //
                  // }
                }
              }
            });
          this.watchingId = this._selectedWaterSourceIds[0];
        }
      } else {
        this.socketSub?.unsubscribe();
        this.watchingId = null;
      }
    }
  }

  stopSocketWatch() {
    if (this.socketSub) {
      this.socketSub.unsubscribe();
      this.socketSub = null;
      this.watchingId = null;
    }
  }
  getChartSVG(event){
    if(this.selectedGraphOptions?.emitSvgOnRedraw && event)
      this.chartSVG.emit(event)
  }
  ngOnDestroy(): void {
    this.stopSocketWatch();
    if (this.updatedOnTimerSub) {
      this.updatedOnTimerSub.unsubscribe();
    }
  }
}
