import { Pipe, PipeTransform } from '@angular/core';
import {ExperimentConfigurationsService} from '../services/experiment-configurations.service';
import {OrganizationSettingsService} from '../services/organization-settings.service';
import {UnitConverter} from '../utils/UnitConverter';
import {UserSettingsCachedService} from '../services/user-settings-cached.service';

@Pipe({
  name: 'unitFromSettingsConverter'
})
export class UnitFromSettingsConverterPipe implements PipeTransform {
  constructor(private expConfService: ExperimentConfigurationsService,
              private  userSettingsCachedService: UserSettingsCachedService) {
  }
  transform(value: number, type: string): number {
    if (value === null || isNaN(value) || !type) {
      return value;
    }
    const conf = this.expConfService.getConfByExperimentMethodBitmap(type);
    if (conf) {
      return UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(value, conf, this.userSettingsCachedService.selectedUnitsDict);
    }
    return value;
  }
}
