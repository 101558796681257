import { Injectable } from '@angular/core';
import { ConfigService as GeneratedConfigService } from '../services/config.service';
import { map } from 'rxjs/operators';
import { UserService } from '../../../../ketos-lib/src/lib/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class ExtendedConfigService {
  constructor(
    private generatedConfigService: GeneratedConfigService
  ) {}

  getExperiments(params: GeneratedConfigService.GetExperimentsParams) {
    return this.generatedConfigService.getExperiments(params).pipe(
      map(experiments => {
        // const organizationId = UserService.getCurrentUser().organization_id;
        const organizationId = UserService.getCurrentOrgId();

        // Check if organizationId is 9 or 143
        if (organizationId === 9 || organizationId === 143) {
          experiments.forEach(exp => {
            if (exp.bit === 9 && (exp.experiment === 'calcium' || exp.experiment === 'hardness')) {
              exp.adl = null;
            }
          });
        }

        return experiments;
      })
    );
  }
}
