/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Ksm_tests } from '../models/ksm-_tests';
import { Ksm_test_input } from '../models/ksm-_test-_input';
import { Kem_test_comment } from '../models/kem-_test-_comment';
import { Kem_test_result } from '../models/kem-_test-_result';

/**
 * Test related operations
 */
@Injectable({
  providedIn: 'root',
})
class ShieldKsmTestsService extends __BaseService {
  static readonly getKsmTestPath = '/shield/ksm_tests/{sensor_id}';
  static readonly postKsmTestPath = '/shield/ksm_tests/{sensor_id}';
  static readonly getKsmTestCsvPath = '/shield/ksm_tests/{sensor_id}/csv';
  static readonly deleteKsmTestRowPath = '/shield/ksm_tests/{sensor_id}/{key}';
  static readonly putKsmTestCommentPath = '/shield/ksm_tests/{sensor_id}/{key}/comment';
  static readonly putKsmTestResultPath = '/shield/ksm_tests/{sensor_id}/{key}/result';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ShieldKsmTestsService.GetKsmTestParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `per_page`: Number of results to return
   *
   * - `scroll_id`: PAGINATION: Pass to get next page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getKsmTestResponse(params: ShieldKsmTestsService.GetKsmTestParams): __Observable<__StrictHttpResponse<Ksm_tests>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.perPage != null) __params = __params.set('per_page', params.perPage.toString());
    if (params.scrollId != null) __params = __params.set('scroll_id', params.scrollId.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield/ksm_tests/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Ksm_tests>;
      })
    );
  }
  /**
   * @param params The `ShieldKsmTestsService.GetKsmTestParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `per_page`: Number of results to return
   *
   * - `scroll_id`: PAGINATION: Pass to get next page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getKsmTest(params: ShieldKsmTestsService.GetKsmTestParams): __Observable<Ksm_tests> {
    return this.getKsmTestResponse(params).pipe(
      __map(_r => _r.body as Ksm_tests)
    );
  }

  /**
   * @param params The `ShieldKsmTestsService.PostKsmTestParams` containing the following parameters:
   *
   * - `test_type`: Bad choice: {error_msg}
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  postKsmTestResponse(params: ShieldKsmTestsService.PostKsmTestParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.testType != null) __params = __params.set('test_type', params.testType.toString());
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shield/ksm_tests/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldKsmTestsService.PostKsmTestParams` containing the following parameters:
   *
   * - `test_type`: Bad choice: {error_msg}
   *
   * - `payload`:
   *
   * - `sensor_id`:
   */
  postKsmTest(params: ShieldKsmTestsService.PostKsmTestParams): __Observable<null> {
    return this.postKsmTestResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldKsmTestsService.GetKsmTestCsvParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `per_page`: Number of results to return
   *
   * - `scroll_id`: PAGINATION: Pass to get next page
   */
  getKsmTestCsvResponse(params: ShieldKsmTestsService.GetKsmTestCsvParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.perPage != null) __params = __params.set('per_page', params.perPage.toString());
    if (params.scrollId != null) __params = __params.set('scroll_id', params.scrollId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield/ksm_tests/${encodeURIComponent(String(params.sensorId))}/csv`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldKsmTestsService.GetKsmTestCsvParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `per_page`: Number of results to return
   *
   * - `scroll_id`: PAGINATION: Pass to get next page
   */
  getKsmTestCsv(params: ShieldKsmTestsService.GetKsmTestCsvParams): __Observable<null> {
    return this.getKsmTestCsvResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldKsmTestsService.DeleteKsmTestRowParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `key`:
   */
  deleteKsmTestRowResponse(params: ShieldKsmTestsService.DeleteKsmTestRowParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/shield/ksm_tests/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldKsmTestsService.DeleteKsmTestRowParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `key`:
   */
  deleteKsmTestRow(params: ShieldKsmTestsService.DeleteKsmTestRowParams): __Observable<null> {
    return this.deleteKsmTestRowResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldKsmTestsService.PutKsmTestCommentParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   *
   * - `key`:
   */
  putKsmTestCommentResponse(params: ShieldKsmTestsService.PutKsmTestCommentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/shield/ksm_tests/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.key))}/comment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldKsmTestsService.PutKsmTestCommentParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   *
   * - `key`:
   */
  putKsmTestComment(params: ShieldKsmTestsService.PutKsmTestCommentParams): __Observable<null> {
    return this.putKsmTestCommentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldKsmTestsService.PutKsmTestResultParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   *
   * - `key`:
   */
  putKsmTestResultResponse(params: ShieldKsmTestsService.PutKsmTestResultParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/shield/ksm_tests/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.key))}/result`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldKsmTestsService.PutKsmTestResultParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   *
   * - `key`:
   */
  putKsmTestResult(params: ShieldKsmTestsService.PutKsmTestResultParams): __Observable<null> {
    return this.putKsmTestResultResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ShieldKsmTestsService {

  /**
   * Parameters for getKsmTest
   */
  export interface GetKsmTestParams {
    sensorId: number;

    /**
     * Number of results to return
     */
    perPage?: number;

    /**
     * PAGINATION: Pass to get next page
     */
    scrollId?: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postKsmTest
   */
  export interface PostKsmTestParams {

    /**
     * Bad choice: {error_msg}
     */
    testType: 'sensor_advance_test' | 'contact_test' | 'fluid_1_test' | 'fluid_2_test' | 'fluid_3_test';
    payload: Ksm_test_input;
    sensorId: number;
  }

  /**
   * Parameters for getKsmTestCsv
   */
  export interface GetKsmTestCsvParams {
    sensorId: number;

    /**
     * Number of results to return
     */
    perPage?: number;

    /**
     * PAGINATION: Pass to get next page
     */
    scrollId?: string;
  }

  /**
   * Parameters for deleteKsmTestRow
   */
  export interface DeleteKsmTestRowParams {
    sensorId: number;
    key: string;
  }

  /**
   * Parameters for putKsmTestComment
   */
  export interface PutKsmTestCommentParams {
    payload: Kem_test_comment;
    sensorId: number;
    key: string;
  }

  /**
   * Parameters for putKsmTestResult
   */
  export interface PutKsmTestResultParams {
    payload: Kem_test_result;
    sensorId: number;
    key: string;
  }
}

export { ShieldKsmTestsService }
