import {Component, Input, OnInit} from '@angular/core';

export interface CalendarTypeInterface {
  title: string;
  key: string;
  values: string[];
  table: boolean;
  icon: string;
}

@Component({
  selector: 'lib-calendar-type-popover',
  templateUrl: './calendar-type-popover.component.html',
  styleUrls: ['./calendar-type-popover.component.scss'],
})
export class CalendarTypePopoverComponent implements OnInit {
  @Input() updateCallback: (selected: CalendarTypeInterface, selectedValue: string) => void;
  @Input() selectedType: CalendarTypeInterface;
  @Input() selectedValue: string;
  @Input() selections: CalendarTypeInterface[];

  defaultValueIdx = 0;
  selectedRowIdx = 0;
  selectedValueIdx = 0;



  constructor() {
    this.selectedValueIdx = this.defaultValueIdx;
  }

  ngOnInit() {
    if (this.selectedType) {
      for (let i = 0; i < this.selections.length; i++) {
        if (this.selections[i].title === this.selectedType.title) {
          this.selectedRowIdx = i;
          if (this.selectedValue) {
            for (let j = 0; j < this.selections[i].values.length; j++) {
              if (this.selections[i].values[j] === this.selectedValue) {
                this.selectedValueIdx = j;
              }
            }
          }
          break;
        }
      }
    }
  }

  selected(selectionIdx: number, valueIdx: number) {
    if (selectionIdx === this.selectedRowIdx && valueIdx === this.selectedValueIdx) {
      return;
    }
    this.selectedRowIdx = selectionIdx;
    this.selectedValueIdx = valueIdx;
    this.updateCallback(
      this.selections[selectionIdx],
      this.selections[selectionIdx].values[valueIdx]
    );
  }
}
