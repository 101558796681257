import { Pipe, PipeTransform } from '@angular/core';
import {formatNumber} from '@angular/common';

@Pipe({
  name: 'formatDisplayNumber'
})
export class FormatDisplayNumberPipe implements PipeTransform {

  transform(value: number | string, digitsInfo = '1.3-3'): string {
    // console.log('formaating', value, digitsInfo, formatNumber(value as number, 'en_US', digitsInfo || '1.3-3'), isNaN(value as any));
    if (value === null || value === undefined) {
      return 'N/A';
    }

    if (typeof value === 'string') {
      if ((value.substring(0, 2) === '< ' || value.substring(0, 2) === '> ') && !isNaN(value.substring(2) as any)) {
        // console.log('uuf', formatNumber(+value.substring(2), 'en_US',digitsInfo || '1.3-3'))
        return value.substring(0, 2) + formatNumber(+value.substring(2), 'en_US', digitsInfo || '1.3-3');
      }
      // if (!isNaN(value.replace(',',''))
    }
    if (!isNaN(value as any)) {
      return formatNumber(value as number, 'en_US', digitsInfo || '1.3-3');
    }
    // console.log('slipping:', value);
    return value as string;
  }
}
