import {Component, Input} from '@angular/core';
import {Savings} from '../../../../../../api/src/lib/models';
import {ModalController} from '@ionic/angular';
import {UiPlatformService} from '../../../services/ui-platform.service';

@Component({
  selector: 'lib-customer-input-modal',
  templateUrl: './customer-input-modal.component.html',
  styleUrls: ['./customer-input-modal.component.scss'],
})
export class CustomerInputModalComponent {

  @Input()
  customerInput: Savings;

  constructor(public modalController: ModalController,
    public uiPlatformService: UiPlatformService) {
  }

  dismiss() {
    this.modalController.dismiss();
  }
  saveCustomerInput() {
    this.modalController.dismiss(this.customerInput);
  }
}
