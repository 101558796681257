import {Injectable} from '@angular/core';
import {Aggregate_results} from '../../../../api/src/lib/models/aggregate-_results';

import * as moment from 'moment';
import {Observable, of} from 'rxjs';
import {TimeIntervalInterface} from './app-state.service';
import {Aggregate_results_extended} from '../models/extensions/aggregate-_results';
import {Shield_result_aggregates_extended} from '../models/extensions/shield-_result-_aggregates';
import {ShieldThresholdService} from '../../../../api/src/lib/services'
import {ResultAggregatesService} from '../../../../api/src/lib/services/result-aggregates.service'
import {ResultsService} from '../../../../api/src/lib/services/results.service'

import {Shield_threshold} from '../../../../api/src/lib/models/shield-_threshold';
import {map,delay} from 'rxjs/operators';
import { time } from 'console';
import GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdParams = ResultAggregatesService.GetShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringIntervalIntWaterSourceIdParams;

export const dummyAggregatesResult: Shield_result_aggregates_extended = {
  items: [
    {
      sensor_id: 2,
      thresholds_external: 15,
      thresholds_internal: 22,

      experiment: 'lead',
      items: [
        { key_as_string: '2020-08-27T19:34:47',doc_count: 1, min_value: {value: 1}, avg_value: {value: 10}, max_value: {value: 20}},
        { key_as_string: '2020-08-28T19:34:47',doc_count: 1, min_value: {value: 1}, avg_value: {value: 9}, max_value: {value: 20}},
        { key_as_string: '2020-08-29T19:34:47',doc_count: 1, min_value: {value: 2}, avg_value: {value: 12}, max_value: {value: 24}},
        { key_as_string: '2020-08-30T19:34:47',doc_count: 1, min_value: {value: 3}, avg_value: {value: 16}, max_value: {value: 29}},
        { key_as_string: '2020-08-31T19:34:47',doc_count: 1, min_value: {value: 2}, avg_value: {value: 16}, max_value: {value: 28}},
        { key_as_string: '2020-09-01T19:34:47',doc_count: 1, min_value: {value:0.7}, avg_value: {value: 1.5}, max_value: {value: 3}},
        { key_as_string: '2020-09-02T19:34:47',doc_count: 1, min_value: {value: 0.9}, avg_value: {value: 1.6}, max_value: {value: 2.9}},
        { key_as_string: '2020-09-03T19:34:47',doc_count: 1, min_value: {value: 0.6}, avg_value: {value: 1.4}, max_value: {value: 3.2}},
        { key_as_string: '2020-09-04T19:34:47',doc_count: 1, min_value: {value: 1}, avg_value: {value: 2}, max_value: {value: 4}},
       // { key_as_string: '2020-08-15T19:34:47',doc_count: 1, min_value: {value: 2}, avg_value: {value: 12}, max_value: {value: 20}},
        { key_as_string: '2020-09-07T19:34:47',doc_count: 1, min_value: {value: 0.9}, avg_value: {value: 1.8}, max_value: {value: 2.7}},
        { key_as_string: '2020-09-08T19:34:47',doc_count: 1, min_value: {value: 1.2}, avg_value: {value: 1.8}, max_value: {value: 2.4}},
      ]
    },
    {
      sensor_id: 2,
      thresholds_external:7,
      thresholds_internal:14,

      experiment: 'arsenic',
      items: [
        { key_as_string: '2020-07-27T19:34:47',doc_count: 1, min_value: {value: 1}, avg_value: {value: 10}, max_value: {value: 20}},
        { key_as_string: '2020-07-28T19:34:47',doc_count: 1, min_value: {value: 1}, avg_value: {value: 10}, max_value: {value: 20}},
        { key_as_string: '2020-07-29T19:34:47',doc_count: 1, min_value: {value: 2}, avg_value: {value: 5}, max_value: {value: 24}},
        { key_as_string: '2020-07-30T19:34:47',doc_count: 1, min_value: {value: 3}, avg_value: {value: 6}, max_value: {value: 25}},
        { key_as_string: '2020-07-31T19:34:47',doc_count: 1, min_value: {value: 2}, avg_value: {value: 13}, max_value: {value: 24}},
        { key_as_string: '2020-08-02T10:00:39', doc_count: 1, min_value: {value: 7}, avg_value: {value: 14}, max_value: {value: 21}},
        { key_as_string: '2020-08-11T19:34:47',doc_count: 1, min_value: {value: 1}, avg_value: {value: 12}, max_value: {value: 23}},
        { key_as_string: '2020-08-12T19:34:47',doc_count: 1, min_value: {value: 4}, avg_value: {value: 15}, max_value: {value: 26}},
        { key_as_string: '2020-08-13T19:34:47',doc_count: 1, min_value: {value: 3}, avg_value: {value: 18}, max_value: {value: 29}},
        { key_as_string: '2020-08-14T19:34:47',doc_count: 1, min_value: {value: 7}, avg_value: {value: 14}, max_value: {value: 25}},
        { key_as_string: '2020-08-15T19:34:47',doc_count: 1, min_value: {value: 4}, avg_value: {value: 16}, max_value: {value: 23}},
        { key_as_string: '2020-08-16T19:34:47',doc_count: 1, min_value: {value: 9}, avg_value: {value: 19}, max_value: {value: 30}},
        { key_as_string: '2020-08-17T19:34:47',doc_count: 1, min_value: {value: 9}, avg_value: {value: 19}, max_value: {value: 30}},
        { key_as_string: '2020-08-18T19:34:47',doc_count: 1, min_value: {value: 10}, avg_value: {value: 15}, max_value: {value: 28}},
        { key_as_string: '2020-08-19T19:34:47',doc_count: 1, min_value: {value: 11}, avg_value: {value: 18}, max_value: {value: 24}},
      ]
    },
    {
      sensor_id: 2,
      thresholds_external:2,
      thresholds_internal:10,
      experiment: 'cadmium',
      items: [
        { key_as_string: '2020-07-27T19:34:47',doc_count: 1, min_value: {value: 1}, avg_value: {value: 10}, max_value: {value: 20}},
        { key_as_string: '2020-07-28T19:34:47',doc_count: 1, min_value: {value: 1}, avg_value: {value: 11}, max_value: {value: 18}},
        { key_as_string: '2020-07-29T19:34:47',doc_count: 1, min_value: {value: 2}, avg_value: {value: 14}, max_value: {value: 20}},
        { key_as_string: '2020-07-30T19:34:47',doc_count: 1, min_value: {value: 3}, avg_value: {value: 15}, max_value: {value: 25}},
        { key_as_string: '2020-07-31T19:34:47',doc_count: 1, min_value: {value: 2}, avg_value: {value: 17}, max_value: {value: 23}},
        { key_as_string: '2020-08-11T19:34:47',doc_count: 1, min_value: {value: 1}, avg_value: {value: 18}, max_value: {value: 26}},
        { key_as_string: '2020-08-12T19:34:47',doc_count: 1, min_value: {value: 2}, avg_value: {value: 9}, max_value: {value: 22}},
        { key_as_string: '2020-08-13T19:34:47',doc_count: 1, min_value: {value: 3}, avg_value: {value: 7}, max_value: {value: 24}},
        { key_as_string: '2020-08-14T19:34:47',doc_count: 1, min_value: {value: 4}, avg_value: {value: 13}, max_value: {value: 25}},
        { key_as_string: '2020-08-15T19:34:47',doc_count: 1, min_value: {value: 2}, avg_value: {value: 14}, max_value: {value: 24}},
        { key_as_string: '2020-08-16T19:34:47',doc_count: 1, min_value: {value: 7}, avg_value: {value: 18}, max_value: {value: 30}},
        { key_as_string: '2020-08-17T19:34:47',doc_count: 1, min_value: {value: 9}, avg_value: {value: 19}, max_value: {value: 30}},
      ]
    },
    {
      sensor_id: 2,
      thresholds_external:12,
      thresholds_internal:14,
      experiment: 'copper',
      items: [
        { key_as_string: '2020-07-28T22:00:39', doc_count: 1, min_value: {value: 1}, avg_value: {value: 11}, max_value: {value: 20}},
        { key_as_string: '2020-07-31T20:00:39', doc_count: 1, min_value: {value: 2}, avg_value: {value: 15}, max_value: {value: 24}},
        { key_as_string: '2020-08-02T10:00:39', doc_count: 1, min_value: {value: 4}, avg_value: {value: 10}, max_value: {value: 26}},
        { key_as_string: '2020-08-03T18:00:39', doc_count: 1, min_value: {value: 3}, avg_value: {value: 13}, max_value: {value: 29}},
       // { key_as_string: '2020-08-14T19:34:47',doc_count: 1, min_value: {value: 4}, avg_value: {value: 13}, max_value: {value: 24}},
        { key_as_string: '2020-08-15T19:34:47',doc_count: 1, min_value: {value: 5}, avg_value: {value: 14}, max_value: {value: 23}},
        { key_as_string: '2020-08-16T19:34:47',doc_count: 1, min_value: {value: 5}, avg_value: {value: 18}, max_value: {value: 26}},
        { key_as_string: '2020-08-17T19:34:47',doc_count: 1, min_value: {value: 6}, avg_value: {value: 19}, max_value: {value: 27}},
      ]
    },
  ]
}

export interface ChartDataSeriesInterface {
  name: string;
  data: { x: number, y: number }[];
  // categories:any
  // lowmedhigh:any;
  // marker: {symbol: string, lineWidth: number,fillcolor:string,
  //};
}

// export interface ChartIntervalDataSeriesInterface{
//   name: string,
//   predata:any
// }
// export interface ChartDataThresholdInterface {
//   threshold_internal:number;
//   threshold_external:number;
// }

export interface ChartDataThresholdInterface {
  threshold_min: number;
  threshold_max: number;
  experiment: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChartDataService {
  constructor(private shieldThresholdService: ShieldThresholdService,
              private resultAggregatesService: ResultAggregatesService,
              private resultsService: ResultsService) {
  }

  formatDateToDisplayTimestamp(data: any) {
    const date = new Date(data);
    date.setHours(0, 0, 0, 0);
    const epoch = (date.getTime() - (date.getTime() % 1000)) / 1000;
    return epoch;
  }

  // @TODO Johannes WaterSource refactor unused?
  getData(experiments: string[], timeInterval: TimeIntervalInterface, waterSourceId): Observable<ChartDataSeriesInterface[]> {

    let minDate = moment(timeInterval.min).utc(false).format();
    // var maxDate = moment(timeInterval.max).format('YYYY-MM-DD h:mm:ss A')
    let maxDate = moment(timeInterval.max).utc(false).format();
    let dateObj = {
      startDate: minDate,
      endDate: maxDate,
      waterSourceIds: waterSourceId.toString(),
      experimentTypes: experiments.toString(),
      page: 1,
      perPage: 100
    };

    const series: ChartDataSeriesInterface[] = [];
    let Api = null;
    Api = this.resultsService.GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes(dateObj);
    Api.subscribe((res) => {
      /** Remove duplicate entries */
      const data = res.items.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.experiment === thing.experiment
        ))
      );
      //console.log(data)
      //for (const experiment of experiments) {
      for (const agg of data) {
        //if(agg.experiment === experiment){
        series.push(this.parseDataSeries(agg));
        // }
      }
      // }
    });
    return of(series).pipe(
      delay(2000)
    );
  }

  parseDataSeries(aggregate): ChartDataSeriesInterface {
    const series: ChartDataSeriesInterface = {
      name: '',
      data: [],
    };
    series.name = aggregate['experiment'];
    series.data.push({x: moment(aggregate['timestamp']).valueOf(), y: aggregate['value']});
    return series;
  }

  getDerivedLowMedHighData(dataObj) {
    // OLD NAME: return this.resultAggregatesService.GetShieldResultAggregatesWithInterval_1(dataObj);
    return this.resultAggregatesService.getShieldResultAggregatesResultAggregatesShieldIntStartDateIntEndDateStringInterval(dataObj);
  }

    getDataForInterval(experiments: string[], timeInterval: TimeIntervalInterface, interval: string, derivedExperiments, maxLength = 10000000): Observable<any[]> {
    const results: any = [];
    const processedResults: any = [];
    const minDate = moment(timeInterval.min.getTime());
    const maxDate = moment(timeInterval.max.getTime());

    // const dataObj = {
    //   startDate:this.formatDateToDisplayTimestamp(timeInterval.min),
    //   endDate:this.formatDateToDisplayTimestamp(timeInterval.max),
    //   interval:interval,
    //   sensorId:1,
    // }

      console.log(derivedExperiments)

    for (const experiment of experiments) {
      for (const agg of derivedExperiments) {
        if (agg.experiment === experiment) {
          results.push(this.parseDataToMatrix(agg, maxLength, minDate, maxDate,interval));
        }
      }
    }
    return of(results)
  }

  parseDataToMatrix(aggregate: Aggregate_results, maxLength, minDate, maxDate, interval): any {
    let matrix = {
      name: aggregate.experiment,
      predata: [],
      categories: []
    };
    var min = minDate.format('YYYY-MM-DD');
    var max = maxDate.format('YYYY-MM-DD');
    for (const item of aggregate.items) {
      //const itemUTCDateString = moment(item.key_as_string).valueOf();
      const itemUTCDate = parseInt(item.key);
      item.min_value.value=item.min_value.value!==null?Number(item.min_value.value.toFixed(2)):null;
      item.avg_value.value=item.avg_value.value!==null?Number(item.avg_value.value.toFixed(2)):null;
      item.max_value.value=item.max_value.value!==null?Number(item.max_value.value.toFixed(2)):null;
      //const itemUTCDate = parseInt(item.key_as_string);
      //console.log(moment(min).valueOf()," ---min ",moment(max).valueOf(),"----max ",item.key)
      if (moment(min).valueOf() <= itemUTCDate && itemUTCDate <= moment(max).valueOf()) {
        let itemCheck = moment(itemUTCDate).format('YYYY-MM-DD');
        // If you want an exclusive end date (half-open interval)
        // if(interval === 'day'){
        //   for (var m = moment(min); m.isBefore(max); m.add(1, 'days')) {
        //     console.log(itemCheck,m.format('YYYY-MM-DD'))
        //     //matrix.categories.push(m.format('YYYY-MM-DD'))
        //     if (itemCheck === m.format('YYYY-MM-DD')) {
        //       console.log(m.format('YYYY-MM-DD'),item.avg_value.value);
        //       matrix.predata.push([item.min_value?.value, item.avg_value.value, item.max_value?.value]);
        //       matrix.categories.push(m.format('YYYY-MM-DD'));
        //     } else {
        //       if (!matrix.categories.includes(m.format('YYYY-MM-DD'))) {
        //         if (!matrix.categories.includes(itemCheck)) {
        //           matrix.predata.push([0, 0, 0]);
        //           matrix.categories.push(m.format('YYYY-MM-DD'));
        //         }
        //       }
        //     }
        //   }
        // }
        // else{
          matrix.predata.push([item.min_value?.value, item.avg_value.value, item.max_value?.value]);
          if(interval === 'hour'){
            itemCheck = moment(itemUTCDate).format('MMM-D:h:a')
          }
          if(interval === 'month'){
            itemCheck = moment(itemUTCDate).format('MMM-D')
          }
          matrix.categories.push(itemCheck);
        //}
      }
      if (matrix.predata.length > maxLength) {
        break;
      }
    }
    return matrix;
  }
}
