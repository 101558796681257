import {ExperimentConfInterface} from '../services/experiment-configurations.service';
import {Experiments} from '../../../../api/src/lib/models/experiments';

export class UnitConverter {

  static multBy1K = (input: number): number => {
    return input * 1000;
  }

  static divBy1K = (input: number): number => {
    return input / 1000;
  }

  static sameValue = (input: number): number => {
    return input;
  }

  static toMol = (input: number, ratio: number) => {
    // console.log('toMol', input, ratio, input * ratio);
    return input * ratio;
  }

  static toMolMultBy1K = (input: number, ratio: number) => {
    // console.log('toMolMultBy1K', input, ratio, input * ratio);
    return input * ratio * 1000;
  }

  static toMolDivBy1K = (input: number, ratio: number) => {
    // console.log('toMolDivBy1K', input, ratio, input * ratio);
    return (input * ratio) / 1000;
  }

  static fromMol = (input: number, ratio: number) => {
    return input / ratio;
  }

  static fromMolMultBy1K = (input: number, ratio: number) => {
    return (input / ratio) * 1000;
  }

  static fromMolDivBy1K = (input: number, ratio: number) => {
    return (input / ratio) / 1000;
  }

  static fToC = (input: number): number => {
    return input === 33.8 ? 1 : (input - 32) * 5 / 9;
  }

  static cToF = (input: number): number => {
    return input * 9 / 5 + 32;
  }

  static ppbToDict = {
    'ppb': UnitConverter.sameValue,
    'ppb CaCO3': UnitConverter.sameValue,
    'ppm': UnitConverter.divBy1K,
    'ppm CaCO3': UnitConverter.divBy1K,
    'μg/L': UnitConverter.sameValue,
    'μg/L CaCO3': UnitConverter.sameValue,
    'mg/L': UnitConverter.divBy1K,
    'mg/L CaCO3': UnitConverter.divBy1K,
    'μmol/L': UnitConverter.toMol,
    'μmol/L CaCO3': UnitConverter.toMol,
    'mmol/L': UnitConverter.toMolDivBy1K,
    'mmol/L CaCO3': UnitConverter.toMolDivBy1K,
  }

  static ppmToDict = {
    'ppb': UnitConverter.multBy1K,
    'ppb CaCO3': UnitConverter.multBy1K,
    'μg/L': UnitConverter.multBy1K,
    'μg/L CaCO3': UnitConverter.multBy1K,
    'ppm': UnitConverter.sameValue,
    'ppm CaCO3': UnitConverter.sameValue,
    'mg/L': UnitConverter.sameValue,
    'mg/L CaCO3': UnitConverter.sameValue,
    'μmol/L': UnitConverter.toMolMultBy1K,
    'μmol/L CaCO3': UnitConverter.toMolMultBy1K,
    'mmol/L': UnitConverter.toMol,
    'mmol/L CaCO3': UnitConverter.toMol
  }

  static microMolToDict = {
    'ppb': UnitConverter.fromMol,
    'ppb CaCO3': UnitConverter.fromMol,
    'μg/L': UnitConverter.fromMol,
    'μg/L CaCO3': UnitConverter.fromMol,
    'ppm': UnitConverter.fromMolDivBy1K,
    'ppm CaCO3': UnitConverter.fromMolDivBy1K,
    'mg/L': UnitConverter.fromMolDivBy1K,
    'mg/L CaCO3': UnitConverter.fromMolDivBy1K,
    'mmol/L': UnitConverter.divBy1K,
    'mmol/L CaCO3': UnitConverter.divBy1K,
    'μmol/L': UnitConverter.sameValue,
    'μmol/L CaCO3': UnitConverter.sameValue
  }

  static milliMolToDict = {
    'ppb': UnitConverter.fromMolMultBy1K,
    'ppb CaCO3': UnitConverter.fromMolMultBy1K,
    'μg/L': UnitConverter.fromMolMultBy1K,
    'μg/L CaCO3': UnitConverter.fromMolMultBy1K,
    'ppm': UnitConverter.fromMol,
    'ppm CaCO3': UnitConverter.fromMol,
    'mg/L': UnitConverter.fromMol,
    'mg/L CaCO3': UnitConverter.fromMol,
    'mmol/L': UnitConverter.sameValue,
    'mmol/L CaCO3': UnitConverter.sameValue,
    'μmol/L': UnitConverter.multBy1K,
    'μmol/L CaCO3': UnitConverter.multBy1K
  }

  public static unitConversionFunctionDict: {[key: string]: {[key: string]: (input: number, ratio?: number) => number}} = {
    'ppb': UnitConverter.ppbToDict,
    'ppb CaCO3': UnitConverter.ppbToDict,
    'μg/L': UnitConverter.ppbToDict,
    'μg/L CaCO3': UnitConverter.ppbToDict,
    'ppm': UnitConverter.ppmToDict,
    'ppm CaCO3': UnitConverter.ppmToDict,
    'mg/L': UnitConverter.ppmToDict,
    'mg/L CaCO3': UnitConverter.ppmToDict,
    'μmol/L': UnitConverter.microMolToDict,
    'μmol/L CaCO3': UnitConverter.microMolToDict,
    'mmol/L': UnitConverter.milliMolToDict,
    'mmol/L CaCO3': UnitConverter.milliMolToDict,
    '°F': {
      '°F': UnitConverter.sameValue,
      '°C': UnitConverter.fToC
    },
    '°C': {
      '°C': UnitConverter.sameValue,
      '°F': UnitConverter.cToF
    },
  }

  // public static convertableReverseLookupDict: {[key: string]: {[key: string]: boolean}};
  //
  // static generateConvertableReverseLookupDict() {
  //   this.convertableReverseLookupDict = {}
  //   Object.keys(this.unitConversionFunctionDict).map( fromKey => {
  //     Object.keys(this.unitConversionFunctionDict[fromKey]).map( toKey => {
  //       if (!this.convertableReverseLookupDict[toKey]) {
  //         this.convertableReverseLookupDict[toKey] = {};
  //       }
  //       this.convertableReverseLookupDict[toKey][fromKey] = true;
  //     })
  //   })
  // }


  static getConvertableUnitsWhiteList(excludeDisplayUnits?: { [key: string]: boolean }): string[] {
    if (!excludeDisplayUnits) {
      return Object.keys(this.unitConversionFunctionDict);
    }
    return Object.keys(this.unitConversionFunctionDict).filter(unit => !excludeDisplayUnits[unit]);
  }

  static getTargetUnitFromConfAndUnitsDict(conf: ExperimentConfInterface, unitsDict: {[key:string]: string}): string {
    return unitsDict?.[conf?.key] || conf?.unit || '';
  }

  static getTargetUnitFromExperimentAndUnitsDict(exp: Experiments, unitsDict: {[key:string]: string}): string {
    return unitsDict?.[exp?.experiment] || exp?.unit || '';
  }

  public static getConversionFunctionToConfDefaultToUnitsDict(conf: ExperimentConfInterface, unitsDict: {[key:string]: string}): (input: number, umolPerL?: number) => number {
    if (
      !conf ||
      !conf.unit ||
      !this.unitConversionFunctionDict[conf.unit] ||
      !unitsDict[conf.key]
    ) {
      return;
    }
    return this.unitConversionFunctionDict[conf.unit]?.[this.getTargetUnitFromConfAndUnitsDict(conf, unitsDict)];
  }

  public static convertFromConfDefaultToUnitSettingsDictUnit(value: number, conf: ExperimentConfInterface, unitsDict: {[key: string]: string}): number {
    const fun = this.getConversionFunctionToConfDefaultToUnitsDict(conf, unitsDict);
    if (fun) {
      return fun(value, conf.umolPerL); // TODO: add ppmToUmol
    }
    return value;
  }

  public static getTargetUnitFromDisplayUnitsDict(fromUnit: string, targetUnitsDict: {[key:string]: boolean}): string {
    if (fromUnit && targetUnitsDict && !targetUnitsDict[fromUnit]) {
      const convDict = this.unitConversionFunctionDict[fromUnit];
      for (const targetUnit of Object.keys(targetUnitsDict)) {
        if (typeof convDict[targetUnit] === 'function') {
          return targetUnit;
        }
      }
    }
    return fromUnit;
  }

  // public static getTargetUnitFromUnitsDict(fromUnit: string, targetUnit: string): string {
  //   if (fromUnit && targetUnit && fromUnit !== targetUnit && this.unitConversionFunctionDict[fromUnit]?.[targetUnit]) {
  //     return targetUnit;
  //   }
  //   return fromUnit;
  // }

  public static convertFromUnitTo(value: number, sourceUnit: string, targetUnit: string, umolPerL?: number): number {
    if (sourceUnit && targetUnit && sourceUnit !== targetUnit && this.unitConversionFunctionDict[sourceUnit]) {
      const convertFunc = this.unitConversionFunctionDict[sourceUnit][targetUnit];
      if (typeof convertFunc === 'function') {
        return convertFunc(value, umolPerL);
      }
      return value;
    }
    return value;
  }
}
// UnitConverter.generateConvertableReverseLookupDict();
