import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SelectPopoverComponent} from '../select-popover.component';
import {PopoverController} from '@ionic/angular';
import { CloneDeep } from '../../../utils/utils';

@Component({
  selector: 'lib-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
})
export class CustomSelectComponent<T> implements OnInit {
  @Input() componentName: string
  @Input() titleKey = 'title';
  @Input() uniqueKey = 'id';
  @Input() emptyText = 'Select';
  @Input() customTemplate = false;
  @Input() customTemplateFlex = true;
  @Input() disableClickEvent: boolean;
  @Input() options: T[] = [];
  @Input() selectedValues: T[];
  @Input() selectedValue: T;
  @Input() selectedId: number | string;
  @Input() selectedIds: any[];
  @Input() maxSelected = Number.MAX_SAFE_INTEGER; // for efficiency use one number smaller
  @Input() showConfirm = false;
  @Input() showSelectAll = false;
  @Input() showClearAll = false;
  @Input() showSearch = false;
  @Input() singleSelect = false;
  @Output() updateSelection = new EventEmitter<T>();
  @Output() selectedValuesChange = new EventEmitter<T[]>();
  @Output() selectionFinished = new EventEmitter<T[]>();
  @ViewChild('customTemplateContainer') customDiv;

  @ViewChild('standardTemplateContainer') standardDiv;
  constructor(private popoverController: PopoverController) {}

  ngOnInit() {}

  maxSelectionsBasedOnOtherCount = (otherSelectionCount: number, maxCount = 4): number => {
    if (!otherSelectionCount || otherSelectionCount === 1) {
      return maxCount - 1;
    }
    return Math.floor(maxCount / otherSelectionCount) - 1;
  }

  labelFunction = (ids: (string | number)[], ...values: any[]): string => {
    if (this.selectedId !== undefined) {
      return this.options?.find(opt => opt[this.uniqueKey] === this.selectedId)?.[this.titleKey]  ?? this.emptyText;
    }
    if (this.selectedValue) {
      return this.selectedValue?.[this.titleKey] ?? this.emptyText;
    }
    if (this.selectedIds !== undefined) {
      if (Array.isArray(this.selectedIds) && this.selectedIds.length > 0) {
        return this.options?.filter(opt => this.selectedIds?.indexOf(opt[this.uniqueKey]) !== -1)?.map(opt => opt[this.titleKey])?.join(', ') ?? this.emptyText;
      } else {
        return this.emptyText;
      }
    }
    if (this.selectedValues) {
      return this.selectedValues?.map(val => val[this.titleKey])?.join(', ') ?? this.emptyText;
    }
    return this.emptyText;
  }

  async internalDivClicked(event: Event) {
    if (!this.disableClickEvent) {
      this.openPopover(event)
    }
  }

  async openPopover(event: Event) {

    let selectedVal: T[] = [];
    if (this.selectedId) {
      const option = this.options.find(val => val[this.uniqueKey] === this.selectedId);
      if (option) {
        selectedVal.push(option);
      }
    } else if (this.selectedValue) {
      selectedVal.push(this.selectedValue);
    } else if (this.selectedIds) {
      selectedVal = this.options?.filter(val => this.selectedIds?.indexOf(val[this.uniqueKey]) !== -1);
    } else {
      selectedVal = this.selectedValues;
    }

    let lastSelection: T[];
    let selectFired = false;
    const div = this.standardDiv || this.customDiv;
    const {y} = div.nativeElement.getBoundingClientRect();
    const popover = await this.popoverController.create({
      component: SelectPopoverComponent,
      componentProps: {
        componentName: this.componentName,
        titleKey: this.titleKey,
        uniqueKey: this.uniqueKey,
        values: this.options,
        selected: selectedVal,
        maxSelected: this.maxSelected,
        showConfirm: this.showConfirm,
        showSelectAll: this.showSelectAll,
        showClearAll: this.showClearAll,
        showSearch: this.showSearch,
        singleSelect: this.singleSelect,
        updateSelected: (selection: T[]) => {
          console.log('update selected:', selection);
          this.selectedValuesChange.emit(selection);
          if (this.singleSelect) {
            popover.dismiss(selection);
          } else {
            lastSelection = selection;
            selectFired = true;
          }
        }
      },
      // cssClass: window.devicePixelRatio >= 3 || (window.innerHeight - y) < 500 || window.innerHeight < 600 ? 'dark-popover unset-top-popover' : 'dark-popover',
      translucent: true,
      event
    });

    popover.onDidDismiss().then(modalData => {
      if (this.singleSelect) {
        if (modalData.role !== 'backdrop') {
          this.updateSelection.emit(modalData?.data?.[0]);
          this.selectionFinished.emit(modalData?.data);
        }
      } else {
        if (selectFired) {
          this.selectionFinished.emit(lastSelection);
        }
      }
    });
    return await popover.present();
  }
}
