import { Pipe, PipeTransform } from '@angular/core';
import {ExperimentConfigurationsService} from "../services/experiment-configurations.service";
import {Alert} from "../../../../api/src/lib/models/alert";
import {UnitConverter} from "../utils/UnitConverter";
import {UserSettingsCachedService} from "../services/user-settings-cached.service";
import {FormatDisplayNumberPipe} from "./format-display-number.pipe";

@Pipe({
  name: 'alertDetailUnitConverter'
})
export class AlertDetailUnitConverterPipe implements PipeTransform {
  constructor(private experimentConfigurationsService: ExperimentConfigurationsService,
              private unitSettingsCachedService: UserSettingsCachedService,
              private formatDisplayNumberPipe: FormatDisplayNumberPipe,
  ) {
  }

  transform(alert: Alert): string {
    let expName = alert['alert_code'] as string;
    // if (expName === 'ambient_temperature') {
    //   expName = 'water_temperature';
    // }
    if (typeof expName === 'string' && this.experimentConfigurationsService.experimentsDict[expName]?.unit) {
      const exp = this.experimentConfigurationsService.experimentsDict[expName];
      const tSplitArr = alert.alert_string.split(' - ');

      if (tSplitArr.length === 2) {
        const value = tSplitArr[1].split(' ')?.[0] as unknown as number;
        const srcUnit = tSplitArr[1].split(' ')?.[1];
        const targetUnit = UnitConverter.getTargetUnitFromExperimentAndUnitsDict(exp, this.unitSettingsCachedService.selectedUnitsDict);
        if (exp && !isNaN(value) &&
          typeof srcUnit === 'string' &&
          srcUnit.length > 0 &&
          targetUnit?.length > 0 &&
          srcUnit !== targetUnit &&
          typeof UnitConverter.unitConversionFunctionDict[srcUnit]?.[targetUnit] === 'function'
        ) {
          console.log('convert:', exp.experiment, value, srcUnit, '=>', UnitConverter.convertFromUnitTo(value, srcUnit, targetUnit, exp.umolPerL), targetUnit);
          return  tSplitArr[0] + ' - ' + this.formatDisplayNumberPipe.transform(UnitConverter.convertFromUnitTo(value, srcUnit, targetUnit, exp.umolPerL), '1.0-3') + ' ' + targetUnit;
        }
      }
    }
    return alert.alert_string || 'N/A';
  }
}
