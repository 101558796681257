import { Injectable } from '@angular/core';
import {AppStorage} from '../utils/AppStorage';

import {combineLatest, Subscription, Subject, timer} from 'rxjs';


export interface DashboardUiState {
  leftMenuExpanded: boolean;
  componentMaximized: boolean;
  userName: string;
}
export interface GraphicalViewLiveUpdateUiState{
  liveUpdateDate: Date
}

@Injectable({
  providedIn: 'root'
})
export class DashboardUiStateService {

  public sensorSummaryMinimized: boolean;
  public leftMenuExpanded: boolean;
  public componentMaximized = false;
  public userName;

  public dashboardUiStateSubject = new Subject<DashboardUiState>();
  public graphicalViewLiveUpdateUiState = new Subject<GraphicalViewLiveUpdateUiState>();


  constructor( ) {
    this.sensorSummaryMinimized = JSON.parse(AppStorage.getItem('sensorSummaryMinimized')) || false;
    const user = JSON.parse(AppStorage.getItem('user'))
    this.userName = user?.name;
    this.leftMenuExpanded = localStorage.getItem('isExpanded') === 'true'; // change to match the set function once the UI is updated to use this
  }

  public setSensorSummaryMinimized(value: boolean) {
    this.sensorSummaryMinimized = value;
    AppStorage.setItem('sensorSummaryMinimized', JSON.stringify(value));
  }

  public setLeftMenuExpanded(value: boolean) {
    // console.log(value)
    this.leftMenuExpanded = value;
    AppStorage.setItem('leftMenuExpanded', JSON.stringify(value));
    this.dashboardUiStateSubject.next({leftMenuExpanded: value, componentMaximized: this.componentMaximized,userName: this.userName});
  }

  public setUserName(value: string){
    const currentUser = JSON.parse(AppStorage.getItem('user'));
    if(currentUser){
        currentUser.name = value;
    }
        AppStorage.setItem('user', JSON.stringify(currentUser));
    this.dashboardUiStateSubject.next({leftMenuExpanded: this.leftMenuExpanded, componentMaximized: this.componentMaximized,userName: value});
  }
  public dashboardUiStateSubjectVal(){
    this.dashboardUiStateSubject.next({leftMenuExpanded: this.leftMenuExpanded, componentMaximized: this.componentMaximized,userName: this.userName});

  }

  public setComponentMaximized(value: boolean) {
    this.componentMaximized = value;
    this.dashboardUiStateSubject.next({leftMenuExpanded: this.leftMenuExpanded, componentMaximized: value,userName: this.userName});
  }
}
