import * as Highcharts from 'highcharts';
import {
  ExportingButtonsContextButtonThemeOptions,
  ResponsiveRulesConditionOptions,
  SeriesLineOptions,
  SeriesZonesOptionsObject, XAxisOptions,
  YAxisOptions,
  YAxisPlotLinesOptions
} from 'highcharts';
import * as moment from 'moment';
import {AppStateService, TimeIntervalInterface} from '../../services/app-state.service';
import {ResultsService} from '../../../../../api/src/lib/services/results.service';
import {ExperimentConfigurationsService} from '../../services/experiment-configurations.service';
import {CsvGenerator} from '../../utils/CsvGenerator';
import {EventEmitter} from '@angular/core';
import {Shield_threshold} from '../../../../../api/src/lib/models/shield-_threshold';
import {Shield_results} from '../../../../../api/src/lib/models/shield-_results';
import {Shield_result_aggregates} from '../../../../../api/src/lib/models/shield-_result-_aggregates';
import {OrganizationSettingsService} from '../../services/organization-settings.service';
import {UnitConverter} from '../../utils/UnitConverter';
import {UiPlatformService} from '../../services/ui-platform.service';
import {Encoding} from '@capacitor/filesystem';
import {FormatResultValuePipe} from '../../pipes/format-result-value.pipe';
import {Subscription, timer} from 'rxjs';
import {take} from 'rxjs/operators';
import {UserSettingsCachedService} from '../../services/user-settings-cached.service';
import {FormatDisplayNumberPipe} from '../../pipes/format-display-number.pipe';
import {WaterSourcesCachedService} from '../../services/water-sources-cached.service';
import { GraphOptionsInterface } from './ketos-graph.page';
import {KetosPopupService} from '../../services/ketos-popup.service';
import {Aggregate_results} from '../../../../../api/src/lib/models/aggregate-_results';
import { Shield_forecasts } from '../../../../../api/src/lib/models/shield-_forecasts';

export interface KetosGraphSeriesSettingsInterface {
  seriesTypeOverride?: string;
  sensorId: number;
  sensorBitmap: string;
  waterSourceId: number;
  locationName: string;

  experimentKey: string;
  experimentUnit?: string;
  experimentTitle?: string;

  adl?: number;
  bdl?: number;
  thresholdKey?: string;
  threshold?: Shield_threshold;
}

export interface KetosGraphDataInterface {
  forecast?: Shield_forecasts;
  forecastEnabled: boolean;
  results?: Shield_results;
  aggregatesMap?: {[key: number]: Shield_result_aggregates};
  histograms?: {[key: number]: Aggregate_results[]};
  dateInterval: TimeIntervalInterface;
  allExperiments: string[];
  scheduledExperiments: string[];
  seriesSettings: KetosGraphSeriesSettingsInterface[];
  waterSourceIds: string;
  noDataText: string;
  seriesTypeOverride?: string;
  disableThresholds?: boolean;
  disableColorZones?: boolean;
  disableDownloading?: boolean;
}
export const chartSymbols = ['circle', 'diamond', 'square', 'triangle', 'triangle-down'];
export abstract class KetosGraphBase {
  chartOptions: Highcharts.Options;
  abstract chartInstance: Highcharts.Chart;

  abstract _showZoomButton: boolean;

  abstract appStateService: AppStateService;
  abstract experimentConfigurationsService: ExperimentConfigurationsService;
  abstract unitSettingsService: UserSettingsCachedService;
  abstract orgSettingsService: OrganizationSettingsService;
  abstract resultsService: ResultsService;
  abstract uiPlatformService: UiPlatformService;
  abstract waterSourcesCachedService: WaterSourcesCachedService;
  abstract ketosPopupService: KetosPopupService;

  abstract formatResultValuePipe: FormatResultValuePipe;
  abstract formatDisplayNumberPipe: FormatDisplayNumberPipe;

  abstract zoomed: EventEmitter<TimeIntervalInterface>
  abstract liveUpdate: EventEmitter<TimeIntervalInterface>

  abstract updateChartFlag: boolean;

  bdlColor = '#00ff00';
  adlColor = '#00ff00';
  thrColor = '#ff0000';
  intervalBlockLevel;
  int=0;


  abstract ketosGraphData: KetosGraphDataInterface;

  noDataText = 'Loading Data';
  noDataSubscription: Subscription;

  constructor() {
  }

  createChart(selectedLocationName: string, multiGraph: boolean, selectedGraphOptions?: GraphOptionsInterface) {
    const timezone = new Date().getTimezoneOffset();
    const self = this;
    let redrawEnabled = true;

    let exportButtons = Highcharts.getOptions().exporting.buttons.contextButton.menuItems.slice() as any[];

    const newButtons = [
      {
        text: 'Download CSV',
        onclick: function () {
          const expSet = new Set(self.ketosGraphData.seriesSettings.map(se => se.experimentKey))
          self.downloadAndExportTypes([...expSet]);
        }
      },
      {
        text: 'Download Continuous Types CSV',
        onclick: function () {
          self.downloadAndExportTypes(self.ketosGraphData.allExperiments.filter(allExp => self.ketosGraphData.scheduledExperiments.indexOf(allExp) === -1), 'continuous');
        }
      },
      {
        text: 'Download Scheduled Types CSV',
        onclick: function () {
          self.downloadAndExportTypes(self.ketosGraphData.allExperiments.filter(allExp => self.ketosGraphData.scheduledExperiments.indexOf(allExp) !== -1), 'scheduled');
        }
      },
      {
        text: 'Download All Types CSV',
        onclick: function () {
          self.downloadAndExportTypes(self.ketosGraphData.allExperiments, 'all');
        }
      },
    ]

    if (this.uiPlatformService.isMobileDevice) {
      exportButtons.splice(0, exportButtons.length - 1, ...newButtons);
    } else {
      exportButtons.splice(exportButtons.length - 6, 1);
      exportButtons.splice(exportButtons.length - 3, 2, ...newButtons);
    }

    this.chartOptions = {
      chart: {
        backgroundColor: '#242434',
        className: 'shieldChartContainer',
        resetZoomButton: {
          position: {
            verticalAlign: 'top',
            x: -40,
            y: -40
        }
        },
        events: {
          load: function () {
            var chart = this;

          },
          redraw: function () {
            redrawEnabled = self.redraw(this, redrawEnabled);
            // Code for avoid overlapping the int threshold and ext threshold text over plot lines
            var chart = this;
           // console.log(self.chartOptions,"fdfdfd")

          //   chart.showLoading();




            if (self.ketosGraphData && self.ketosGraphData.seriesSettings?.length === 1) {
              self.intervalBlockLevel = (chart.yAxis[0] as any).tickInterval;
            } else {
              self.intervalBlockLevel = null;
            }

            self.int++;
            // console.log(self.intervalBlockLevel,self.int,self._selectedExperiments)
            // self.oldPlotlineExperiment = self._selectedExperiments;

            setTimeout(() => {
              // console.log(self.oldPlotlineExperiment, self._selectedExperiments);
              self.showThresholdLines(self.ketosGraphData);
            }, 200);

          },
          selection: function getSelection(event) {
           // if(self.ketosGraphData.aggregatesMap){
            self.zoomed.emit();
            return true;
            //}
            // else{
            //   if(event.resetSelection === true){
            //     self.zoomed.emit()
            //   }else{
            //     return self.zoomSelection(this, event);
            //   }
            // }
          },
        },
      },
      time: {
        timezoneOffset: timezone
      },
      loading: {
        labelStyle: {
          color: 'white'
        },
        style: {
          backgroundColor: '#242434',
          opacity: 0.8
        }
      },
      legend: {
        align: 'center',
        verticalAlign: 'top',
        itemStyle: {
          color: '#7cb5ec'
        },
        itemHoverStyle: {
          color: '#a4c0e4'
        }
      },
      title: {
        text: selectedGraphOptions?.title || '',
        style: {
          color: '#ffffff'
        }
      },
      credits: {
        enabled: false,
      },
      xAxis: [{
        type: 'datetime',
        title: {
          text: 'Dathovere'
        },
        lineColor: '#ffffff',
        tickColor: '#ffffff',
        minRange: 60 * 60 * 1000,
        labels: {
          style: {
            color: '#ffffff'
          },
          // step: 4,
        },
        style: {
          color: '#ffffff'
        },
        //minRange: 1,
        events: {
          afterSetExtremes: function (event) {
            let min = event.min;
            let max = event.max;
            let interval = max - min;
            let intervalDay = Math.floor(interval / 86400000);
            let step = Math.floor(intervalDay / 5);
          },
        },
        gridLineDashStyle: 'ShortDash',
        visible: false,
        showEmpty: false,
      } as XAxisOptions,
        {
          type: 'category',
          accessibility: {
            description: 'Months of the year'
          },
          title: {
            text: 'Date'
          },
          lineColor: '#ffffff',
          tickColor: '#ffffff',
          labels: {
            style: {
              color: '#ffffff'
            }
          },
          style: {
            color: '#ffffff'
          },
          gridLineDashStyle: 'ShortDash',
          // categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          categories: [],
          // tickmarkPlacement: 'on',
          visible: false,
          showEmpty: false,
          min: 0
         } as XAxisOptions],
      yAxis: [this.createYAxis()],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 'auto'
          } as unknown as ResponsiveRulesConditionOptions,
          chartOptions: {
            xAxis: {
              //categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              style: {
                color: '#ffffff'
              }
            } as XAxisOptions,
            yAxis: {
              style: {
                color: '#ffffff'
              }
            } as YAxisOptions
          }
        }]
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        series: {
          turboThreshold: 10000,
          // point: {
          //   events: {
          //     update: function (event) {
          //     }
          //   }
          // }
        }
      },
      lang: {
         noData: 'No data for current filter applied',
       //noData: 'Loading Graph Data'
      },
      noData: {
        style: {
          // fontWeight: 'bold',
          fontSize: '16px',
          color: '#ffffff',
          fontFamily: 'RobotoLight',
        }
      },
      colors: ['#6CF', '#DF843C', '#06C', '#59FFDE'],
      series: [
        { // a series with categories needs to be loaded first to allow correct switching to timeline
          name: '',
          type: ('lowmedhigh' as any),
          showInLegend: false,
          xAxis: 1,
          data: [],
        }
      ],
      exporting: {
        enabled: false,
        filename: `Ketos-Shield-Data-${selectedLocationName}-` + moment().utc(false).format('YYYY-MM-DD'),
        buttons: {
          contextButton: {
            menuItems: exportButtons,
            symbolStroke: '#00b2ff',
            theme: {
              fill: '#242434',
              states: {
                hover: {
                  fill: '#242434',
                  'stroke-width': 1,
                  stroke: '#00b2ff'
                },
                select: {
                  fill: '#242434',
                  'stroke-width': 1,
                  stroke: '#00b2ff'
                }
              }
            } as ExportingButtonsContextButtonThemeOptions
          }
        }
      },
      navigation: {
        menuStyle: {
          'border': '1px solid #999999',
          'background': '#242434',
          'padding': '5px 0'
        },
        menuItemStyle: {
          'padding': '0.5em 1em',
          'color': '#00b2ff',
          'background': 'none',
          'fontSize': '8px/10px',
          'transition': 'background 250ms, color 250ms'
        }
      }
    };
    this.setGraphZoom(this.chartOptions, multiGraph, selectedGraphOptions);
  }

  setGraphZoom(chartOptions: any, multiGraph: boolean, selectedGraphOptions?: GraphOptionsInterface) {
    if (multiGraph !== true && selectedGraphOptions?.zoomDisabled !== true) {
      chartOptions.chart.zoomType = 'x';
    } else {
      chartOptions.chart.zoomType = '';
    }
  }
  setLiveUpdate(){
    this.liveUpdate.emit()
  }
  createYAxis(visible = true, opposite = false, linkedTo?: number): YAxisOptions {
    return {
      title: {
        text: '',
        style: {
          color: '#ffffff'
        },
      },
      gridLineDashStyle: 'Dot',
      gridLineColor: '#AAAAAA',
      labels: {
        style: {
          color: '#ffffff'
        }
      },
      softMin: 0,
      showEmpty: false,
      visible: visible,
      opposite: opposite,
      startOnTick: false,
      endOnTick: false,
      plotLines:[]
    };
  }

  setYaxisMinMaxFromOptions(graphOptions: GraphOptionsInterface, yAxis?: YAxisOptions): boolean {
    if (yAxis) {
      if (graphOptions && graphOptions.seperateYaxis !== true) {
        if (typeof graphOptions.ymin === 'number') {
          yAxis.softMin = graphOptions.ymin;
          yAxis.min = graphOptions.ymin;
        } else {
          yAxis.min = null;
          yAxis.softMin = 0;
        }
        if (typeof graphOptions.ymax === 'number') {
          yAxis.max = graphOptions.ymax;
        } else {
          yAxis.max = null;
          if (yAxis.plotLines?.length > 0) {
            yAxis.softMax = Math.max(...yAxis.plotLines.map(pl => pl.value));
          } else {
            yAxis.softMax = null;
          }
        }
      } else {
        if (typeof yAxis.min === 'number') {
          yAxis.min = null;
          yAxis.softMin = 0;
        }
      }
      return true;
    }
    return false;
  }

  setNoDataText(newNoDataText) {
    if (typeof newNoDataText === 'string') {
      this.noDataText = newNoDataText;
    }

    this.noDataSubscription?.unsubscribe();

    const sub = timer(50, 200)
      .pipe(take(20))
      .subscribe( num => {
        if (typeof (this.chartInstance as any)?.hideNoData === 'function' && typeof (this.chartInstance as any)?.showNoData === 'function') {
          (this.chartInstance as any)?.hideNoData();
          if (!(this.chartInstance as any)?.hasData()) {
            (this.chartInstance as any)?.showNoData(this.noDataText);
          }
          sub.unsubscribe();
        }
      });
    this.noDataSubscription = sub;
  }

  setBdlAdlColorZones(experiment: string, seriesIdx: number, colorIdx: number, sensorBitmap?: string) {
    if (this.ketosGraphData.disableColorZones) {
      if (this.chartOptions.series[seriesIdx]) {
        (this.chartOptions.series[seriesIdx] as SeriesLineOptions).zones = [];
      }
      return
    }
    const conf = this.experimentConfigurationsService.getConfByExperimentMethodBitmap(experiment, null, sensorBitmap);
    let zones: SeriesZonesOptionsObject[] = [];

    if (this.ketosGraphData?.seriesSettings?.length > 0) {
      const extThresholdObj = this.appStateService.thresholdsExternalService.getShieldThresholdForSensorIdAndExperiment(this.ketosGraphData.seriesSettings[colorIdx].waterSourceId, experiment);
      let minThreshold: number;
      let maxThreshold: number;
      let bdl: number;
      let adl: number;

      if (typeof extThresholdObj.min_value === 'number') {
        minThreshold = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(extThresholdObj.min_value, conf, this.unitSettingsService.selectedUnitsDict);
      }
      if (typeof extThresholdObj.max_value === 'number') {
        maxThreshold = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(extThresholdObj.max_value, conf, this.unitSettingsService.selectedUnitsDict);
      }
      if (typeof conf?.bdl === 'number') {
        bdl = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(conf.bdl, conf, this.unitSettingsService.selectedUnitsDict);
      }
      if (typeof conf?.adl === 'number') {
        adl = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(conf.adl, conf, this.unitSettingsService.selectedUnitsDict)
      }


      if (typeof minThreshold === 'number') {
        zones.push({
          value: minThreshold,
          color: this.thrColor,
          fillColor: this.thrColor,
        });
        if (typeof bdl === 'number' && bdl > minThreshold) {
          zones.push({
            value: bdl,
            color: this.bdlColor,
            fillColor: this.bdlColor,
          });
        }
      } else if (typeof bdl === 'number') {
        zones.push({
          value: bdl,
          color: this.bdlColor,
          fillColor: this.bdlColor,
        });
      }

      if (zones.length > 0) {
        zones.push({
          color: this.chartOptions.colors[colorIdx],
          fillColor: this.chartOptions.colors[colorIdx]
        });
      }

      this.addColorZoneToEndOfArray(zones, adl, this.adlColor, this.chartOptions.colors[colorIdx]);
      this.addColorZoneToEndOfArray(zones, maxThreshold, this.thrColor, this.chartOptions.colors[colorIdx]);

    }
    if ((this.chartOptions.series[seriesIdx] as SeriesLineOptions)) {
      (this.chartOptions.series[seriesIdx] as SeriesLineOptions).zones = zones;
    }
  }

  addColorZoneToEndOfArray(zones: SeriesZonesOptionsObject[], value: number, color: string, backupColor: string) {
    if (typeof value !== 'number') {
      return;
    }
    if (zones.length === 0) {
      zones.push({
        value: value,
        color: backupColor,
        fillColor: backupColor
      });
      zones.push({
        color: color,
        fillColor: color
      });
      return;
    }

    let idx = zones.length - 1;
    while (idx > 0) {
      if (typeof zones[idx].value !== 'number') {
        if (zones[idx - 1].value < value) {
          zones[idx].value = value;
          zones.push({
            color: color,
            fillColor: color
          });
          return;
        } else {
          zones.pop();
          delete zones[idx - 1].value;
        }
      } else {
        if (zones[idx].value < value) {
          zones.push({
            color: backupColor,
            fillColor: backupColor
          });
          idx += 2;
        } else {
          delete zones[idx].value;
          idx++;
        }
      }
      idx--;
    }
  }

  createThresholdPlotLine(value: number, color: string, className: string, title: string, offset: number): YAxisPlotLinesOptions {
    return {
      value: value,
      color: color,
      dashStyle: 'ShortDash',
      className: className,
      width: 1.5,
      zIndex: 1.67,
      label: {
        text: title,
        style: {
          color: '#ffffff'
        },
        y: -5 + offset
      }
    };
  }

  showThresholdLines(graphData: KetosGraphDataInterface) {
    if (!this.chartOptions?.yAxis) {
      return;
    }
    if (!this.ketosGraphData?.seriesSettings || graphData.seriesSettings.length === 0 || graphData.disableThresholds) {
      this.chartOptions.yAxis[0].plotLines = [];
      return;
    } else if (graphData.seriesSettings.length > 1) {
      this.chartOptions.yAxis[0].plotLines = [];
      delete this.chartOptions.yAxis[0].softMax;
      return;
    }

    const exp = graphData.seriesSettings[0].experimentKey;
    const conf = this.experimentConfigurationsService.getConfByExperimentMethodBitmap(exp);

    const plotLines: YAxisPlotLinesOptions[] = [];


    const extThresholdObj = this.appStateService.thresholdsExternalService.getShieldThresholdForSensorIdAndExperiment(this.ketosGraphData.seriesSettings[0].waterSourceId, exp);
    let thresholdMin: number;
    let thresholdMax: number;
    let belowThresholdOffset = 0;
    let aboveThresholdOffset = 0;
    let yMinExtreme = this.chartOptions.series[0].custom.hardYMin;
    let yMaxExtreme = this.chartOptions.series[0].custom.hardYMax;

    if (extThresholdObj && typeof extThresholdObj[conf?.threshold_key] === 'number') {
      if (typeof extThresholdObj.min_value === 'number') {
        thresholdMin = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(extThresholdObj.min_value, conf, this.unitSettingsService.selectedUnitsDict);
        if (thresholdMin < yMinExtreme) {
          yMinExtreme = thresholdMin;
        }
      }
      if (typeof extThresholdObj.max_value === 'number') {
        thresholdMax = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(extThresholdObj.max_value, conf, this.unitSettingsService.selectedUnitsDict);
        if (thresholdMax > yMaxExtreme) {
          yMaxExtreme = thresholdMax;
        }
      }
      if (typeof thresholdMin === 'number') {

          if (thresholdMin > thresholdMax) {
            aboveThresholdOffset = 18;
          } else {
            belowThresholdOffset = 18;
          }
      } else {
        aboveThresholdOffset = -32;
        belowThresholdOffset = -16;
      }
    }

    let normalizedMinHeight = null;
    if (typeof thresholdMin === 'number') {
      normalizedMinHeight = (thresholdMin - yMinExtreme)/(yMaxExtreme - yMinExtreme);

      if (typeof normalizedMinHeight === 'number' && normalizedMinHeight < 0.057) {
        belowThresholdOffset = 0;
      } else {
        normalizedMinHeight = null;
      }
      plotLines.push(
        this.createThresholdPlotLine(thresholdMin, 'yellow', 'internal-threshold-line', '⍖ Alert Below Threshold', belowThresholdOffset)
      );
    }

    if (typeof thresholdMax === 'number') {
      if (typeof normalizedMinHeight === 'number') {
        const normalizedThresholdGap = (thresholdMax - yMinExtreme)/(yMaxExtreme - yMinExtreme) - normalizedMinHeight;
        if (normalizedThresholdGap < 0.035 && aboveThresholdOffset !== -32) {
          aboveThresholdOffset = aboveThresholdOffset - 12 + Math.min(Math.ceil(11 * 28 * normalizedThresholdGap), 12);
        }
      }

      plotLines.push(
        this.createThresholdPlotLine(thresholdMax, 'red','external-threshold-line', '⍏ Alert Above Threshold', aboveThresholdOffset)
      );
    }

    const yAxis = this.chartOptions.yAxis[0];
    if (typeof thresholdMin === 'number' && typeof thresholdMax === 'number') {
      if (thresholdMin > thresholdMax) {
        yAxis.softMax = thresholdMin;
      } else {
        yAxis.softMax = thresholdMax;
      }
    } else if (typeof thresholdMin === 'number' || typeof thresholdMax === 'number') {
      yAxis.softMax = thresholdMax || thresholdMin;
    } else {
      delete yAxis.softMax;
    }

    if (typeof yAxis.softMax === "number" && typeof yAxis.max === "number" && yAxis.softMax > yAxis.max) {
      yAxis.softMax = yAxis.max;
    }

    this.chartOptions.yAxis[0].plotLines = plotLines;
    if (this.int == 1) {
      this.updateChartFlag = true;

    }
  }

  redraw(chart: any, redrawEnabled): boolean {
    const arr = chart.options.exporting.buttons.contextButton.menuItems;
    const index = arr.indexOf('viewData');

    if (index !== -1) {
      arr.splice(index, 1);
    }
    if (redrawEnabled) {

      redrawEnabled = false;
      redrawEnabled = true;
    }
    if (this._showZoomButton && !chart.resetZoomButton) {
      chart.showResetZoom();
    }
    return redrawEnabled;
  }

  zoomSelection(chart, event) {
    if (event.resetSelection === true) {
      this.zoomed.emit();
    }
    return true;
  }

  createDatapointSeries(colorIdx: number, seriesId: string, experiment: string, seriesType = 'lollipop', sensorBitmap?: string, lineSize?: number, pointMarker?: {enabled: true, radius?: number}): any {
    const self = this;
    const dataLabels = {
      enabled: true,
        color: '#ffffff',
        formatter: function () {
          let method: string = null;
          if (this.series?.userOptions?.custom?.methods?.length === 1) {
            method = this.series.userOptions.custom.methods[0];
          }
        return  self.formatDisplayNumberPipe.transform(this.y, '1.0-3');//Math.floor(this.y * 1000) / 1000
      },
    };
    if (seriesType !== 'lollipop') {
      dataLabels.enabled = false;
    }
    let lineWidth = 2;
    let connectNulls = false;
    if (typeof lineSize === 'number') {
      lineWidth = lineSize;
      connectNulls = true;
    }
    return {
      name: '',
      id: seriesId,
      color: this.chartOptions.colors[colorIdx],
      xAxis: 0,
      yAxis: 0,
      dataLabels: dataLabels,
      data: [],
      type: seriesType,
      showInLegend: true,
      marker: pointMarker || {enabled: true},
      lineWidth,
      connectNulls,
      events: {
        mouseOver: function() {
          const chart = this.chart;
          chart.series.forEach(function(s) {
            if (s !== this) {
              s.setState('hover');
            }
          }, this);
        },
        mouseOut: function() {
          const chart = this.chart;
          chart.series.forEach(function(s) {
            if (s !== this) {
              s.setState('');
            }
          }, this);
        },
        legendItemClick: function () {
          // Toggle series visibility
          this.visible ? this.hide() : this.show();

          // Reference to the chart instance
          const chart = this.chart;

          // Function to check if all series are hidden
          const areAllSeriesHidden = (): boolean => {
            return chart.series.every(series => !series.visible);
          };

          // Function to update plotLines and plotBands visibility
          const updatePlotLinesAndBands = (): void => {
            const allSeriesHidden = areAllSeriesHidden();

            chart.xAxis.forEach(axis => {
              if (axis.plotLines) {
                axis.plotLines.forEach(plotLine => {
                  plotLine.options.visible = !allSeriesHidden;
                });
              }
              if (axis.plotBands) {
                axis.plotBands.forEach(plotBand => {
                  plotBand.options.visible = !allSeriesHidden;
                });
              }
              // Redraw axis to apply changes
              axis.update({}, false);
            });

            // Redraw the chart to apply the changes
            chart.redraw();
          };

          // Update plot lines and bands based on the new series visibility
          updatePlotLinesAndBands();

          // Prevent default action to allow custom visibility handling
          return false;
        }
      },
      tooltip: {
        useHTML: true,
        headerFormat: `<strong>{point.key}</strong><br>`,
        pointFormatter: function () {
          let method: string = null;
          if (this.series?.userOptions?.custom?.methods?.length === 1) {
            method = this.series.userOptions.custom.methods[0];
          }
          let title = '';
          if (this.title) {
            title ='<strong>Test title: </strong>' + this.title + '<br>';
          }
          if (!this.custom) {
            return `${title}<span style="color:${this.series['color']}">${this.series['symbolUnicode']}</span> ${this.series.name}: <b>${self.formatResultValuePipe.newPipesTransformConvertedValue(this.y, experiment, method, sensorBitmap)}</b><br/>`;
          }
          const min = self.formatResultValuePipe.newPipesTransformConvertedValue(this.custom.min, experiment, method, sensorBitmap);
          const avg = self.formatResultValuePipe.newPipesTransformConvertedValue(this.y, experiment, method, sensorBitmap);
          const max = self.formatResultValuePipe.newPipesTransformConvertedValue(this.custom.max, experiment, method, sensorBitmap);
          return `<span style="color:${this.series['color']}">${this.series['symbolUnicode']}</span> ${this.series.name}: Min <b>${min}</b> - Avg <b>${avg}</b> - Max <b>${max}</b><br/>`;
        },
        valueSuffix: ''
      },
      custom: {
        primary: true, // Primary series like pH, Temperature, etc. (Not forecast)
        colorIdx: colorIdx,
        sensorBitmap: sensorBitmap
      }
    };
  }

  createAreaRangeDatapointSeries(
    colorIdx: number,
    seriesId: string,
    experiment: string,
    sensorBitmap?: string,
    averages = [],
    ranges = [],
    name = ''
  ): any {
    const self = this;
    return [
      {
        name: name,
        id: `${seriesId}_forecast`,
        data: averages,
        lineWidth: 1, // This disables the line
        linkedTo: `${seriesId}`,
        color: this.chartOptions.colors[colorIdx],
        zIndex: 1,
        marker: {
          enabled: true,
          fillColor: 'white',
          lineWidth: 0,
          lineColor: this.chartOptions.colors[colorIdx]
          // lineColor: Highcharts.getOptions().colors[colorIdx]
        },
        showInLegend: true,
        index: colorIdx,
        events: {
          legendItemClick: function () {
            return false; // Prevent the default action
          }
        },
        tooltip: {
          useHTML: true,
          headerFormat: `<strong>{point.key}</strong><br>`,
          pointFormatter: function() {
            let method: string = null;
            if (this.series?.userOptions?.custom?.methods?.length === 1) {
              method = this.series.userOptions.custom.methods[0];
            }
            let title = '';
            if (this.title) {
              title = '<strong>Test title: </strong>' + this.title + '<br>';
            }
            if (!this.custom) {
              return `${title}<span style="color:${this.series.color}">${this.series.symbolUnicode}</span> ${this.series.name}: <b>${self.formatResultValuePipe.newPipesTransformConvertedValue(this.y, experiment, method, sensorBitmap)}</b><br/>`;
            }
            const min = self.formatResultValuePipe.newPipesTransformConvertedValue(this.custom.min, experiment, method, sensorBitmap);
            const avg = self.formatResultValuePipe.newPipesTransformConvertedValue(this.y, experiment, method, sensorBitmap);
            const max = self.formatResultValuePipe.newPipesTransformConvertedValue(this.custom.max, experiment, method, sensorBitmap);
            return `<span style="color:${this.series.color}">${this.series.symbolUnicode}</span> ${this.series.name}: Min <b>${min}</b> - Avg <b>${avg}</b> - Max <b>${max}</b><br/>`;
          },
          valueSuffix: ''
        },
        custom: {
          colorIdx: colorIdx,
          sensorBitmap: sensorBitmap
        }
      }, {
        name: '',
        id: `${seriesId}_forecast_range`,
        data: ranges,
        type: 'arearange',
        lineWidth: 0,
        linkedTo: `${seriesId}_forecast`,
        color: this.chartOptions.colors[colorIdx],
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {
          enabled: false
        },
        showInLegend: false,
        custom: {
          colorIdx: colorIdx,
          sensorBitmap: sensorBitmap
        }
      }
    ];
  }

  downloadAndExportTypes(types: string[], filenamesuffix = '') {
    console.log('download and export data for types:', types, 'and time: ', this.ketosGraphData.dateInterval);
    this.chartInstance.showLoading('Fetching Data...');
    let csvString = CsvGenerator.createHeaderCsvString(types, this.experimentConfigurationsService, this.unitSettingsService.selectedUnitsDict, this.ketosGraphData.seriesSettings[0]?.sensorBitmap);
    this.resultsService.getShieldResults({
      startTimestamp: moment(this.ketosGraphData.dateInterval.min).utc(false).format(),
      endTimestamp: moment(this.ketosGraphData.dateInterval.max).utc(false).format(),
      waterSourceIds: this.ketosGraphData.waterSourceIds.split(',').map(num => parseInt(num, 10)),
      experiments: types,
      operatingStatuses: [1],
      includeShieldTestSchedule: true,
      perPage: 5000
    })
      .subscribe(res => {
        csvString = csvString + CsvGenerator.csvStringFromResultsArray(res.items, types, this.experimentConfigurationsService, this.unitSettingsService.selectedUnitsDict, this.ketosGraphData.seriesSettings[0]?.sensorBitmap, this.waterSourcesCachedService.dictById);
        if (res.scroll_id && res.total > res.items.length) {
          this.fetchAndStringifyNextBatchUntilDone(csvString, res.scroll_id, res.items.length, types, filenamesuffix);
        } else {
          this.downloadFileStopLoading(csvString, types, filenamesuffix, res.items.length);
        }
      }, error => {
        this.chartInstance.hideLoading();
      });
  }

  fetchAndStringifyNextBatchUntilDone(csvString: string, scrollId: string, processedResults: number, types: string[], filenamesuffix = '') {
    this.resultsService.getShieldResults({scrollId: scrollId})
      .subscribe(res => {
        processedResults = processedResults + res.items.length;
        csvString = csvString + CsvGenerator.csvStringFromResultsArray(res.items, types, this.experimentConfigurationsService, this.unitSettingsService.selectedUnitsDict, this.ketosGraphData.seriesSettings[0]?.sensorBitmap, this.waterSourcesCachedService.dictById);
        if (res?.items?.length < 1 || processedResults >= res.total) {
          this.downloadFileStopLoading(csvString, types, filenamesuffix, processedResults);
        } else {
          this.fetchAndStringifyNextBatchUntilDone(csvString, scrollId, processedResults, types, filenamesuffix);
        }
      }, error => {
        this.chartInstance.hideLoading();
      });
  }

  downloadFileStopLoading(csvString: string, types: string[], filenamesuffix = '', processedResults: number) {
    this.chartInstance.hideLoading();

    if (!processedResults || processedResults === 0) {
      this.ketosPopupService.confirmPopup({message: 'No data for selected experiments, do you wish to download the empty file?', ok: 'Download'})
        .then(res => {
          this.downloadFile(csvString, types, filenamesuffix);
        })
    } else {
      this.downloadFile(csvString, types, filenamesuffix);
    }
  }

  downloadFile(csvString: string, types: string[], filenamesuffix = '') {
    const typesString = types === this.ketosGraphData.allExperiments ? 'all' : types.join('_');
    const uniqueSensorTitles = [...new Set(this.ketosGraphData.seriesSettings.map(se => se.locationName))];
    let filename = `Ketos-Shield-Data-${uniqueSensorTitles.join('_')}_${moment(this.ketosGraphData.dateInterval.min).utc(false).format()}-${moment(this.ketosGraphData.dateInterval.max).utc(false).format()}_${filenamesuffix || typesString}`;

    if (this.uiPlatformService.isMobileDevice) {
      this.uiPlatformService.saveAndOpenFileMobile(csvString, filename + '.csv', 'text/csv', Encoding.UTF16);
    } else {
      CsvGenerator.downloadStringAsCsv(csvString, filename);
    }
  }
}
