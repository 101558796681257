import {Injectable, Injector} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Directory, Encoding, Filesystem} from '@capacitor/filesystem';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {File} from '@ionic-native/file/ngx';

@Injectable({
  providedIn: 'root'
})
export class UiPlatformService {
  public mobileScreen = false;
  enableSwitching = false;

  isMobileDevice: boolean;
  private fileOpener: FileOpener;
  private file: File;

  webPlatform = true;

  constructor(public platform: Platform,
              private injector: Injector,) {
    this.onResize();
    window.addEventListener('resize', (e) => {
      this.onResize();
    });

    this.isMobileDevice = this.platform.is('cordova') || this.platform.is('capacitor');
    if (this.isMobileDevice) {
      this.fileOpener = this.injector.get(FileOpener);
      this.file = this.injector.get(File);
    }
  }

  isIosDevice(): boolean {
    return (this.platform.is('cordova') || this.platform.is('capacitor')) && this.platform.is('ios');
  }

  onResize() {
    if (this.enableSwitching) {
      this.mobileScreen = window.innerWidth < 992;
    }
  }

  saveAndOpenFileMobile(data: string, filename: string, fileType: string, encoding?: Encoding): Promise<any> {
    // const writeDirectory = this.platform.is('ios') ? this.file.dataDirectory : this.file.externalDataDirectory;
    return Filesystem.writeFile({
      path: filename,
      data: data,
      directory: Directory.External,
      encoding: encoding
    })
      .then(writeFileResult => {
        console.log('write result', writeFileResult);
        return Filesystem.getUri({
          directory: Directory.External,
          path: filename
        });
      })
      .then(getUriResult => {
        return this.fileOpener.open(getUriResult.uri, fileType);
      })
      .then(() => console.log('File is opened'));
  }
}
