/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Oq_report_input } from '../models/oq-_report-_input';
import { Oq_reports } from '../models/oq-_reports';
import { Oq_report } from '../models/oq-_report';
import { Oq_result_raw_output } from '../models/oq-_result-_raw-_output';
import { Bulk_oq_result_raw } from '../models/bulk-_oq-_result-_raw';

/**
 * oq_report
 */
@Injectable({
  providedIn: 'root',
})
class OqReportService extends __BaseService {
  static readonly putOqReportPath = '/oq_report';
  static readonly getOqReportPath = '/oq_report';
  static readonly getCurrentOqReportPath = '/oq_report/current/{sensor_sn}';
  static readonly getOqReportRawPath = '/oq_report/raw';
  static readonly postOqReportRawPath = '/oq_report/raw';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OqReportService.PutOqReportParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   */
  putOqReportResponse(params: OqReportService.PutOqReportParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/oq_report`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `OqReportService.PutOqReportParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   */
  putOqReport(params: OqReportService.PutOqReportParams): __Observable<null> {
    return this.putOqReportResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `OqReportService.GetOqReportParams` containing the following parameters:
   *
   * - `ids`: List of oq_report ids
   *
   * - `doc_types`: list of doc type
   *
   * - `organization_ids`: List of organization ids
   *
   * - `sensor_sns`: List of sensor sn
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOqReportResponse(params: OqReportService.GetOqReportParams): __Observable<__StrictHttpResponse<Oq_reports>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.docTypes || []).forEach(val => {if (val != null) __params = __params.append('doc_types', val.toString())});
    (params.organizationIds || []).forEach(val => {if (val != null) __params = __params.append('organization_ids', val.toString())});
    (params.sensorSns || []).forEach(val => {if (val != null) __params = __params.append('sensor_sns', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/oq_report`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Oq_reports>;
      })
    );
  }
  /**
   * @param params The `OqReportService.GetOqReportParams` containing the following parameters:
   *
   * - `ids`: List of oq_report ids
   *
   * - `doc_types`: list of doc type
   *
   * - `organization_ids`: List of organization ids
   *
   * - `sensor_sns`: List of sensor sn
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOqReport(params: OqReportService.GetOqReportParams): __Observable<Oq_reports> {
    return this.getOqReportResponse(params).pipe(
      __map(_r => _r.body as Oq_reports)
    );
  }

  /**
   * @param params The `OqReportService.GetCurrentOqReportParams` containing the following parameters:
   *
   * - `sensor_sn`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getCurrentOqReportResponse(params: OqReportService.GetCurrentOqReportParams): __Observable<__StrictHttpResponse<Oq_report>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/oq_report/current/${encodeURIComponent(String(params.sensorSn))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Oq_report>;
      })
    );
  }
  /**
   * @param params The `OqReportService.GetCurrentOqReportParams` containing the following parameters:
   *
   * - `sensor_sn`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getCurrentOqReport(params: OqReportService.GetCurrentOqReportParams): __Observable<Oq_report> {
    return this.getCurrentOqReportResponse(params).pipe(
      __map(_r => _r.body as Oq_report)
    );
  }

  /**
   * @param params The `OqReportService.GetOqReportRawParams` containing the following parameters:
   *
   * - `ids`: List of oq test ids
   *
   * - `oq_report_ids`: list of oq report ids
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOqReportRawResponse(params: OqReportService.GetOqReportRawParams): __Observable<__StrictHttpResponse<Oq_result_raw_output>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.oqReportIds || []).forEach(val => {if (val != null) __params = __params.append('oq_report_ids', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/oq_report/raw`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Oq_result_raw_output>;
      })
    );
  }
  /**
   * @param params The `OqReportService.GetOqReportRawParams` containing the following parameters:
   *
   * - `ids`: List of oq test ids
   *
   * - `oq_report_ids`: list of oq report ids
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOqReportRaw(params: OqReportService.GetOqReportRawParams): __Observable<Oq_result_raw_output> {
    return this.getOqReportRawResponse(params).pipe(
      __map(_r => _r.body as Oq_result_raw_output)
    );
  }

  /**
   * @param payload undefined
   */
  postOqReportRawResponse(payload: Bulk_oq_result_raw): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/oq_report/raw`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postOqReportRaw(payload: Bulk_oq_result_raw): __Observable<null> {
    return this.postOqReportRawResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module OqReportService {

  /**
   * Parameters for putOqReport
   */
  export interface PutOqReportParams {
    payload: Oq_report_input;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getOqReport
   */
  export interface GetOqReportParams {

    /**
     * List of oq_report ids
     */
    ids?: Array<number>;

    /**
     * list of doc type
     */
    docTypes?: Array<string>;

    /**
     * List of organization ids
     */
    organizationIds?: Array<number>;

    /**
     * List of sensor sn
     */
    sensorSns?: Array<string>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getCurrentOqReport
   */
  export interface GetCurrentOqReportParams {
    sensorSn: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getOqReportRaw
   */
  export interface GetOqReportRawParams {

    /**
     * List of oq test ids
     */
    ids?: Array<number>;

    /**
     * list of oq report ids
     */
    oqReportIds?: Array<number>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { OqReportService }
