import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectionToTitle'
})
export class SelectionToTitlePipe implements PipeTransform {
  transform(title: string, selection: [] = [], width: number = null): string {
    if (selection.length === 0) {
      return title;
    }

    if (width === null) {
      width = title.length;
    }

    return this.formatSelection(selection, width) as string;
  }

  formatSelection(selection, width) {
    const result = selection.join(', ');
    if (result.length > width) {
      return result.substring(0, width - 3) + '...';
    }
    return result;
  }
}
