/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import {KetosLibModule} from '../../../../../ketos-lib/src/lib/ketos-lib.module';
import { IonicModule } from '@ionic/angular';
import { CalendarModalComponent } from './calendar-modal/calendar-modal.component';
import { InlineCalendarComponent } from './calendar-modal/inline-calendar.component';
import { ModalCalendarComponent } from './calendar-modal/modal-calendar.component';
import { CalendarPage } from './calendar.page';
import { CalendarDropdownComponent } from './calendar-dropdown/calendar-dropdown.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule
  ],
  declarations: [
    CalendarPage,
    CalendarDropdownComponent,
    CalendarModalComponent,
    InlineCalendarComponent,
    ModalCalendarComponent],
  exports:[CalendarPage,
    CalendarModalComponent,
    InlineCalendarComponent,
    CalendarDropdownComponent,
    ModalCalendarComponent]
})
export class CalendarModule {}
