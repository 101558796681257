/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';

@Component({
  selector: 'lib-calendar-modal',
  templateUrl: './calendar-modal.component.html',
  styleUrls: ['./calendar-modal.component.scss']
})

export class CalendarModalComponent implements OnInit {

  // remap to input data
  selectRange = false;
  value;
  forceMinutes = false;
  hidePreviousDates = false;
  hideMinutes = false;
  hideFutureDates = false;
  showTodayButton:boolean = false;



  newValue;
  constructor(
    private modalController: ModalController,
    private navParams: NavParams
    ) { }

  ngOnInit() {
    if (this.navParams.data.value) {
      this.value = this.navParams.data.value;
    }

    if (this.navParams.data.forceMinutes) {
      this.forceMinutes = this.navParams.data.forceMinutes;
    }
    if (this.navParams.data.hidePreviousDates) {
      this.hidePreviousDates = this.navParams.data.hidePreviousDates;
    }
    if (this.navParams.data.hideMinutes) {
      this.hideMinutes = this.navParams.data.hideMinutes;
    }
    if (this.navParams.data.hidePreviousDates) {
      this.hidePreviousDates = this.navParams.data.hidePreviousDates;
    }
    if(this.navParams.data.showTodayButton){
      this.showTodayButton = this.navParams.data.showTodayButton
    }
    this.selectRange = this.navParams.data.selectRange;
  }

  async cancel() {
    await this.modalController.dismiss();
  }
  async selectToday(){
    const todayValue = {
      max: new Date(),
      min: new Date(),
      defaultValue: true
    }
    todayValue.min.setDate(todayValue.max.getDate() - 1);
    await this.modalController.dismiss(todayValue);
  }
  async default() {
    const defaultValue = {
      max: new Date(),
      min: new Date(),
      defaultValue: true
    }
    defaultValue.min.setDate(defaultValue.max.getDate() - 7);
    await this.modalController.dismiss(defaultValue);
  }

  async done() {
    await this.modalController.dismiss(this.newValue);
  }
}
