/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Continuous_mode_action } from '../models/continuous-_mode-_action';
import { Continuous_mode_ack } from '../models/continuous-_mode-_ack';

/**
 * Continuous mode related operations
 */
@Injectable({
  providedIn: 'root',
})
class ContinuousModeService extends __BaseService {
  static readonly UpdateShieldContinuousModePath = '/continuous_mode/';
  static readonly UpdateShieldContinuousModeOnAckPath = '/continuous_mode/ack/';
  static readonly ContinuousMaintenancePath = '/continuous_mode/maintenance/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param payload undefined
   * @return Success
   */
  UpdateShieldContinuousModeResponse(payload: Continuous_mode_action): __Observable<__StrictHttpResponse<Continuous_mode_action>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/continuous_mode/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Continuous_mode_action>;
      })
    );
  }
  /**
   * @param payload undefined
   * @return Success
   */
  UpdateShieldContinuousMode(payload: Continuous_mode_action): __Observable<Continuous_mode_action> {
    return this.UpdateShieldContinuousModeResponse(payload).pipe(
      __map(_r => _r.body as Continuous_mode_action)
    );
  }

  /**
   * Used by EMR
   * @param payload undefined
   * @return Success
   */
  UpdateShieldContinuousModeOnAckResponse(payload: Continuous_mode_ack): __Observable<__StrictHttpResponse<Continuous_mode_action>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/continuous_mode/ack/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Continuous_mode_action>;
      })
    );
  }
  /**
   * Used by EMR
   * @param payload undefined
   * @return Success
   */
  UpdateShieldContinuousModeOnAck(payload: Continuous_mode_ack): __Observable<Continuous_mode_action> {
    return this.UpdateShieldContinuousModeOnAckResponse(payload).pipe(
      __map(_r => _r.body as Continuous_mode_action)
    );
  }

  /**
   * Used by Lambda
   */
  ContinuousMaintenanceResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/continuous_mode/maintenance/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by Lambda
   */
  ContinuousMaintenance(): __Observable<null> {
    return this.ContinuousMaintenanceResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ContinuousModeService {
}

export { ContinuousModeService }
