import {ExperimentConfigurationsService, ExperimentConfInterface} from '../services/experiment-configurations.service';
import * as moment from 'moment';
import {UnitConverter} from './UnitConverter';
import {Shield_result} from '../../../../api/src/lib/models/shield-_result';
import {Water_source} from '../../../../api/src/lib/models/water-_source';
import {Result_input} from "../../../../api/src/lib/models/result-_input";

export class CsvGenerator {

  static downloadAdCsv(data: any[], headers: {key: string; title: string, defaultValue?: number | string}[], filename = 'data',isMobile:boolean=false) {
    const csvString = this.objectArrayToCsvStringFromHeaders(data, headers);
    if(isMobile){
    return this.downloadStringAsCsv(csvString, filename,true);
    }else{
      this.downloadStringAsCsv(csvString, filename);
    }
  }

  static downloadStringAsCsv(csvString: string, filename: string,isMobile:boolean=false) {
    // console.log('csvString:', csvString.split('\r\n').map(str => str.split(','), '\n'), filename, isMobile);

    let blob = new Blob(['\ufeff' + csvString], {type: 'text/csv;charset=utf-8;'});
    let url = URL.createObjectURL(blob);
    if(isMobile){
      return url;
    }
    //console.log(isMobile)
    let dwldLink = document.createElement('a');

    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  static objectArrayToCsvStringFromHeaders(objArray: any, headerList: {key: string; title: string, defaultValue?: number | string}[]) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    // console.log(array.length,"LengthArray",headerList,"heasderList")
    let str = '';
    let row = '';

    // Create header row
    for (const header of headerList) {
      row += header.title + ',';
    }
    row = row.slice(0, -1); // Remove trailing comma
    str += row + '\r\n';

    // Iterate through each object in the array
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const header of headerList) {
        let newField = '';
        const value = array[i][header.key];
        if (value !== undefined && value !== null) {
          if (Array.isArray(value)) {
            newField = '"' + value.join(', ') + '"';
          } else if (typeof value === 'string') {
            // Escape double quotes by replacing " with ""
            const escapedValue = value.replace(/"/g, '""');
            if (!/^\d+(\.\d+)?$/.test(value.trim())) {
              newField = `"${escapedValue}"`;
            } else {
              newField = escapedValue;
            }
          } else {
            newField = value;
          }
        } else {
          if (header.defaultValue !== undefined) {
            newField += header.defaultValue;
          }
        }
        // newField = newField.replace(/,\s*$/, "");
        line += newField  + ',';
      }
      line = line.replace(/,\s*$/, ""); // Remove trailing comma
      str += line + '\r\n';
    }

    return str;
  }

  static createHeaderCsvString(types: string[], experimentConfService: ExperimentConfigurationsService, unitsDict: {[key: string]: string}, bitmap: string): string {
    let csvString = '';
    let csvRow = 'Date,';
    for (const type of types) {
      const conf = experimentConfService.getConfByExperimentMethodBitmap(type, null, bitmap);
      if (conf) {
        csvRow += `${conf.title}`;
        let unit = UnitConverter.getTargetUnitFromConfAndUnitsDict(conf, unitsDict);
        if (unit) {
          csvRow += ` (${unit}),${conf.title} Detection Range,`;
        } else {
          csvRow += `,${conf.title} Detection Range,`;
        }
      } else {
        const friendlyName = type.split('_').map(wrd => wrd[0].toUpperCase() + wrd.substring(1)).join(' ');
        csvRow += `${friendlyName},${friendlyName} Detection Range,`;
      }
    }
    csvRow += 'Test titles,';
    csvRow += 'Water Source';
    csvString = csvRow + '\r\n';
    return csvString;
  }

  static csvStringFromResultsArray(results: Shield_result[], types: string[], experimentConfService: ExperimentConfigurationsService, unitsDict: {[key: string]: string}, bitmap: string, waterSourceById: {[key: number]: Water_source}): string {
    if (!results || results.length < 1) {
      return '';
    }

    let csvString = '';
    const typeIdxMap = {};
    types.map((type, idx) => typeIdxMap[type] = idx * 2);  // Adjusted index to account for Detection Range columns
    const valuesArrayBase = Array(types.length * 2).fill('');  // Adjusted to accommodate Detection Range columns
    let valuesArraysByWsIdDict = {};

    let trailingTimestamp = results[0].timestamp;
    const initialValuesArray = valuesArrayBase.slice();
    initialValuesArray[types.length * 2] = [];  // Adjusted index to account for Detection Range columns

    // TODO HCA - https://ketos7.atlassian.net/browse/SW-8243
    //  Show ADL/BDL noise in CSV for Ekonoke water sources only
    const showAdlBdlNoise = [34241, 34242].includes(results[0].water_source_id);
    initialValuesArray[typeIdxMap[results[0].experiment]] = this.ResultToAdlBdlClippedConvertedDisplayValue(results[0], experimentConfService, unitsDict, !showAdlBdlNoise);
    if (results[0].shield_test_schedule?.title) {
      initialValuesArray[types.length * 2].push(results[0].shield_test_schedule.title);  // Adjusted index to account for Detection Range columns
    }
    valuesArraysByWsIdDict[results[0].water_source_id] = initialValuesArray;
    let resultIdx = 1;
    while (resultIdx < results.length) {
      const result = results[resultIdx];
      if (result.timestamp !== trailingTimestamp) {
        for (const wsId of Object.keys(valuesArraysByWsIdDict)) {
          const valuesArray = valuesArraysByWsIdDict[wsId];
          for (let i = 0; i < valuesArray.length; i += 2) {
            if (typeof valuesArray[i] === 'string' && (valuesArray[i].includes('<') || valuesArray[i].includes('>'))) {
              valuesArray[i] = valuesArray[i].replace(/[<>]/g, '');
              valuesArray[i + 1] = 'Outside SHIELD Detection Range';
            } else {
              valuesArray[i + 1] = '';
            }
          }
          let titlesString = ',';
          if (valuesArray[types.length * 2].length > 0) {  // Adjusted index to account for Detection Range columns
            titlesString = ',"' + valuesArray[types.length * 2].join(', ') + '"';  // Adjusted index to account for Detection Range columns
          }
          csvString += moment.utc(trailingTimestamp).local().format() + ',' + valuesArray.slice(0, -2).join(',') + titlesString + ',"' + (waterSourceById[wsId]?.water_source_name || wsId) + '"' + '\r\n';  // Adjusted slice index to account for Detection Range columns
        }
        valuesArraysByWsIdDict = {};
        trailingTimestamp = result.timestamp;
      }

      if (!valuesArraysByWsIdDict[result.water_source_id]) {
        valuesArraysByWsIdDict[result.water_source_id] = valuesArrayBase.slice();
        valuesArraysByWsIdDict[result.water_source_id][types.length * 2] = [];  // Adjusted index to account for Detection Range columns
      }

      valuesArraysByWsIdDict[result.water_source_id][typeIdxMap[result.experiment]] = this.ResultToAdlBdlClippedConvertedDisplayValue(result, experimentConfService, unitsDict, !showAdlBdlNoise);
      if (result.shield_test_schedule?.title && valuesArraysByWsIdDict[result.water_source_id][types.length * 2].indexOf(result.shield_test_schedule.title) === -1) {  // Adjusted index to account for Detection Range columns
        valuesArraysByWsIdDict[result.water_source_id][types.length * 2].push(result.shield_test_schedule.title);  // Adjusted index to account for Detection Range columns
      }

      resultIdx++;
    }

    for (const wsId of Object.keys(valuesArraysByWsIdDict)) {
      const valuesArray = valuesArraysByWsIdDict[wsId];
      const anyTrailingValue = valuesArray.find(val => typeof val === 'number' || typeof val === 'string');
      if (typeof anyTrailingValue === 'number' || typeof anyTrailingValue === 'string') {
        for (let i = 0; i < valuesArray.length; i += 2) {
          if (typeof valuesArray[i] === 'string' && (valuesArray[i].includes('<') || valuesArray[i].includes('>'))) {
            valuesArray[i] = valuesArray[i].replace(/[<>]/g, '');
            valuesArray[i + 1] = '';
            valuesArray[i + 1] = 'Outside SHIELD Detection Range';
          } else {
          }
        }
        let titlesString = ',';
        if (valuesArray[types.length * 2].length > 0) {  // Adjusted index to account for Detection Range columns
          titlesString = ',"' + valuesArray[types.length * 2].join(', ') + '"';  // Adjusted index to account for Detection Range columns
        }
        csvString += moment.utc(trailingTimestamp).local().format() + ',' + valuesArray.slice(0, -2).join(',') + titlesString + ',"' + (waterSourceById[wsId]?.water_source_name || wsId) + '"' + '\r\n';  // Adjusted slice index to account for Detection Range columns
      }
    }

    return csvString;
  }

  static ResultToAdlBdlClippedConvertedDisplayValue(result: Result_input, experimentConfService: ExperimentConfigurationsService, displayUnitsDict: {[key: string]: string}, hideNoise: boolean = true): number | string {
    const conf = experimentConfService.getConfByExperimentMethodBitmap(result.experiment, result.method);
    let resultValue = result.value;
    let prefix;
    if (conf.adl && conf.adl < resultValue) {
      if (hideNoise) {
        resultValue = conf.adl;
      }
      prefix = '>';
    }
    if (conf.bdl && conf.bdl > resultValue) {
      if (hideNoise) {
        resultValue = conf.bdl;
      }
      prefix = '<';
    }
    resultValue = Number(UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(resultValue, conf, displayUnitsDict).toFixed(13));
    if (prefix) {
      resultValue = prefix + resultValue;
    }
    return resultValue;
  }
}
