import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'utcToLocalDate'
})
export class UtcToLocalDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  transform(value: number | string, defaultValue = '', format = 'dd MMM yy HH:mm', timezone?: string, locale?: string): string {
    if (!value) {
      return defaultValue;
    }
    return this.datePipe.transform(moment.utc(value).local().toDate(), format, timezone, locale);
  }
}
