import {Injectable} from '@angular/core';
import {CachedBaseService} from './cached-base.service';
import {Observable, ReplaySubject} from 'rxjs';
import {ResultsService} from '../../../../api/src/lib/services/results.service';
import {TimeIntervalInterface} from './app-state.service';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {Result_input} from '../../../../api/src/lib/models';
import {KetosSocketActionEnum, KetosSocketEventEnum, KetosSocketService} from './ketos-socket.service';


export interface ResultTimeSeries {
  timeInterval: TimeIntervalInterface;
  data: {[key: string]: [number, number][]};
}

@Injectable({
  providedIn: 'root'
})
export class ResultsCachedService extends CachedBaseService {
  subject: ReplaySubject<ResultTimeSeries>;
  models: ResultTimeSeries;

  selectedTimeInterval: TimeIntervalInterface;
  //@TODO: Johannes: refactor to waterSourceIds
  sensorIds: number[];
  experimentTypes: string[];

  lastParamsJson: string;

  constructor(private resultsService: ResultsService,
              private ketosSocketService: KetosSocketService,) {
    super();
  }

  getAllChartDataCachedLive(timeInterval: TimeIntervalInterface, sensorIds: number[], experimentTypes: string[], live = true, forceRefresh = false): Observable<ResultTimeSeries> {
    this.selectedTimeInterval = timeInterval;
    this.sensorIds = sensorIds;
    this.experimentTypes = experimentTypes;
    if (this.selectedTimeInterval.defaultValue === true) {
      return this.getCached(live, true);
    }
    return this.getCached(live, forceRefresh);
  }

  getCached(live = true, forceRefresh= false): Observable<ResultTimeSeries> {
    return super.getCachedBase(live, forceRefresh);
  }

  fetchData(): Observable<ResultTimeSeries> {
    let startDate = moment(this.selectedTimeInterval.min).utc(false);
    let endDate = moment(this.selectedTimeInterval.max).utc(false);
    if (this.selectedTimeInterval.defaultValue === true) {
      const diff = endDate.diff(startDate);
      endDate = moment().utc(false);
      startDate = moment().utc(false).subtract(diff, 'ms');
    }

    const params = {
      startDate: startDate.format(),
      endDate: endDate.format(),
      waterSourceIds: this.sensorIds.join(','),
      experimentTypes: this.experimentTypes.join(','),
      page: 1,
      perPage: 1000
    };

    return this.resultsService.GetPaginatedResultsGivenOptionalWaterSourceIdsAndOptionalExperimentTypes(params)
      .pipe(
        map((resObj) => {
          const res = resObj as {items: Result_input[]};
          this.models = {
            timeInterval: {
              min: new Date(startDate.milliseconds()),
              max: new Date(endDate.milliseconds()),
              defaultValue: this.selectedTimeInterval.defaultValue
            },
            data: {}
          };
          for (const result of res.items) {
            if (!this.models.data[result.experiment]) {
              this.models.data[result.experiment] = [];
            }
            this.models.data[result.experiment].push([moment(result.timestamp + 'Z').utc(false).valueOf(), result.value])
          }
          if (this.subject) {
            this.subject.next(this.models);
          }
          if (this.selectedTimeInterval.defaultValue === true) {
            // this.ketosSocketService.registerCallbackForSensorId(this.sensorIds[0], KetosSocketEventEnum.shield_results, this.updateFromSocket);
          }
          return this.models;
        })
      );
  }

  // live logic
  updateFromSocket = (data: {action: KetosSocketActionEnum, event_type: KetosSocketEventEnum, organization_id: number, room: string, sensor_id: string, message: {shield_sensor: any} }) => {
    console.log('results update from socket:', data);
    // if (data.action === KetosSocketActionEnum.write) {
    //   this.model.shield_sensors.push(data.message.shield_sensor);
    //   this.subject.next(this.model);
    // } else if (data.action === KetosSocketActionEnum.update) {
    //   const idx = this.model.shield_sensors.findIndex(obj => obj.id === data.message.shield_sensor.id);
    //   if (idx !== -1) {
    //     this.model.shield_sensors[idx] = data.message.shield_sensor;
    //   }
    //   this.subject.next(this.model);
    // } else if ( data.action === KetosSocketActionEnum.delete) {
    //   const idx = this.model.shield_sensors.findIndex(obj => obj.id === data.message.shield_sensor.id);
    //   if (idx !== -1) {
    //     this.model.shield_sensors.splice(idx, 1);
    //   }
    //   this.subject.next(this.model);
    // }
  }
}
