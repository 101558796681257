import {Pipe, PipeTransform} from '@angular/core';
import {ExperimentConfigurationsService} from '../services/experiment-configurations.service';
import {formatNumber} from '@angular/common';

@Pipe({
  name: 'ketosResult'
})
export class KetosResultPipe implements PipeTransform {
  constructor(private expConfService: ExperimentConfigurationsService) {
  }

  transform(originalValue: any, digitsInfo = '1.3-3', unitOverride?: string, experiment?: string): string {
    let fValue = formatNumber(originalValue, 'en_US', digitsInfo || '1.3-3');

    if (originalValue === null || originalValue === undefined) {
      return 'N/A';
    }

    if (isNaN(originalValue)) {
      return originalValue;
    }

    if (originalValue === 999999) {
      return 'ERROR';
    }

    if (typeof unitOverride === 'string') {
      if (unitOverride.length === 0) {
        return fValue;
      }
      return fValue + ' ' + unitOverride;
    }

    if (experiment) {
      const unit = this.expConfService.experimentConfigurationsMap[experiment]?.unit;
      if (typeof unit === 'string') {
        if (unit.length === 0) {
          return fValue;
        }
        return fValue + ' ' + unit;
      }
    }

    return fValue;
  }
}
