import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {Sensor_group_delete_object, Sensor_group_object, Sensor_group_objects} from '../../../../api/src/lib/models';
import {map} from 'rxjs/operators';
import {SensorGroupsService} from '../../../../api/src/lib/services';
import {UserService} from './user.service';
import {CachedBaseService} from './cached-base.service';
import {KetosSocketActionEnum, KetosSocketEventEnum, KetosSocketService} from './ketos-socket.service';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SensorGroupCachedService extends CachedBaseService {
  subject: ReplaySubject<Sensor_group_objects[]>;
  models: Sensor_group_objects[];
  socketSub: Subscription;
  constructor(private ketosSocketService: KetosSocketService,
              private sensorGroupsService: SensorGroupsService,
              public authService: AuthService) {
    super();
    this.setupSubject();
  }

  setupSubject() {
    if (!this.socketSub && this.authService.getUser()?.organization_id && this.authService.getUser()?.role_id < 4) {
      this.socketSub = this.ketosSocketService.getSubjectForRoom(this.authService.getUser().organization_id, KetosSocketEventEnum.sensor_group_mapping)
        .subscribe(data => {
          this.updateFromSocket(data);
        });
    }
  }

  public getCached(live = true, forceRefresh = false): Observable<Sensor_group_objects[]> {
    return this.getCachedBase(live, forceRefresh);
  }

  fetchData(): Observable<Sensor_group_objects[]> {
    return this.sensorGroupsService.GetSensorGroupDetails({sensorType: 2, organizationId: `${UserService.getCurrentOrgId().toString()}`})
      .pipe(
        map(res => {
          this.models = res as unknown as Sensor_group_objects[];
          this.subject.next(res as unknown as Sensor_group_objects[]);
          return this.models;
        })
      );
  }
  updateSensorGroup(sensorGroups: Sensor_group_object[]): Observable<string> {
    return this.sensorGroupsService.UpdateSensorGroup({payload: sensorGroups});
  }
  postSensorGroup(sensorGroups: Sensor_group_objects[]): Observable<string> {
    return this.sensorGroupsService.CreateASensorGroup({payload: sensorGroups});
  }

  deleteSensorGroup(sensorGroups: Sensor_group_delete_object[]): Observable<string> {
    return this.sensorGroupsService.DeleteASensorGroup({payload: sensorGroups});
  }

  // live logic
  updateFromSocket(data: {action: KetosSocketActionEnum, event_type: KetosSocketEventEnum, organization_id: number, room: string, sensor_id: string, message: {sensor_group_mapping: Sensor_group_objects} }) {
    if (data.action === KetosSocketActionEnum.write) {
      this.models.push(data.message.sensor_group_mapping);
      this.subject.next(this.models);
    } else if (data.action === KetosSocketActionEnum.update) {
      const idx = this.models.findIndex(obj => obj.sensor_id === data.message.sensor_group_mapping.sensor_id && obj.group_id === data.message.sensor_group_mapping.group_id);
      if (idx !== -1) {
        this.models[idx] = data.message.sensor_group_mapping;
      }
      this.subject.next(this.models);
    } else if ( data.action === KetosSocketActionEnum.delete) {
      const idx = this.models.findIndex(obj => obj.sensor_id === data.message.sensor_group_mapping.sensor_id && obj.group_id === data.message.sensor_group_mapping.group_id);
      if (idx !== -1) {
        this.models.splice(idx, 1);
      }
      this.subject.next(this.models);
    }
  }
}
