import { Component, OnInit } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import { DropdownOptionsInterface, DropdownValueInterface } from '../../../components/shared/dropdown/models/dropdown-options.interface';
import { CropService } from '../nutrient-management.service'
import { SeasonService } from './nutrient-season-service';
export enum SelectedTypeEnum {
  season,
  cropsGrown,
  farmAcres,
  wells,
  shieldMonitoring,
  dynamicDataShield,
  chemicals,
  forcast
}

@Component({
  selector: 'app-nutrient-management',
  templateUrl: './nutrient-management.component.html',
  styleUrls: ['./nutrient-management.component.scss','../../modeller/bootstrap.min.css']
})
export class NutrientManagementComponent implements OnInit {
  public cropsDropdown: DropdownOptionsInterface = {
    values: [],
    groups: []
  };
  ourFile:File;
  isFarmAcresInputBoxOpen:boolean;
  public showCropsGrown=false;
  public showSeasons = false;
  types = [
    'Season',
    'Crops Grown',
    'Farm Acres',
    'Wells',
  ];
  seasons = [
    {title: 'Annual', value: "annual"},
    {title: 'Spring', value: "spring"},
    {title: 'Summer', value: "summer"},
    {title: 'Autumn', value: "autumn"},
    {title: 'Winter', value: "winter"},
    {title: 'Daily', value: "daily"},
  ];
  availableCrops : DropdownOptionsInterface= {
    values : [
    {title: 'Walnut', value:'walnut'},
    {title: 'Grape', value: 'grape'},
    {title: 'Almond', value:'almond'},
    {title: 'Alfalfa', value:'alfalfa'},
    {title: 'Carrot', value:'carrot'},
    {title: 'Citrus', value:'citrus'},
    {title: 'Cotton', value:'cotton'},
    {title: 'Grape', value:'grape'},
    {title: 'Grapefruit', value:'grapefruit'},
    {title: 'Lemon', value:'lemon'},
    {title: 'Olive', value:'olive'},
    {title: 'Orange', value:'orange'},
    {title: 'Pecan', value:'pecan'},
    {title: 'Pistachio', value:'pistachio'},
    {title: 'Potato', value:'potato'},
    {title: 'Tomato', value:'tomato'},
  ]}

  selectedMinYear = 2016;
  selectedMaxYear = 2018;
  selectedYear=2016;
  lastSelectedMinYear = -1;
  lastSelectedMaxYear = -1;

  minYear = 2016;
  maxYear = 2020;

  actionTypes = [
    'Shield monitoring',
    'Dynamic Shield Data',
    'Nutrient Monitoring',
    'Satellite Imagery',
    'Forecast'
  ]
  public selectedCrop: DropdownOptionsInterface[] =[] ;

  selectedType = this.types[0];
  selected:any = this.actionTypes[0];
  selectedSeason:any ;
  // public getNutrientsAndChemicals(){
  //   return this.avalableNutrients
  // }
  public isShowSeason(){
    this.showSeasons = !this.showSeasons
  }
  public updateSelectedCrop(crop: any) {
    let arr = [] as DropdownOptionsInterface[]
    crop.forEach(i => {
      if(i.checked){
      //let temp : any= {group:i.title};
      let temp : any= {title:i.title, value:i.value};
      arr.push(temp)
      }
    })
    // let lastArray;
    // if(arr.length > 1){
    //    lastArray = arr.pop()
    // }else{
    //   lastArray =arr
    // }
    //console.log(lastArray,"lastarray")
    if(arr.length)
    this.cropService.updatedDataSelection(arr)
   this.selectedCrop = crop;
    //this.selected = crop


    //this.getNutrientsAndChemicals()
  }
  public updateSelectedSeason(season: any) {
    console.log(season);
    this.selectedSeason = season;
    this.seasonService.updatedDataSelection(season)

    //this.selected = season;

  }
  public isCropsGrown(cropStatus){
    this.showCropsGrown = !cropStatus;
    //this.selected=this.actionTypes[0];
  }
  fileChange(files: File[]) {
    if (files.length > 0) {
      this.ourFile = files[0];
    }
  }
  openFarmAcresInputBox(){
    this.isFarmAcresInputBoxOpen=!this.isFarmAcresInputBoxOpen;
  }
  constructor( private cropService: CropService, private seasonService:SeasonService) {
    this.isFarmAcresInputBoxOpen=false;
   }

  ngOnInit() {

  }
  applyYearFilter(value) {
    this.selectedYear = value.from;
    const obj = {
      year:value.from
    }
    this.seasonService.updatedYearSelection(obj)
    // console.log('Applying from year filter: ' + value.from);
    // console.log('Applying to year filter: ' + value.to);
    // this.selectedMaxYear = value.to;
    // this.selectedMinYear = value.from;
  }

}
