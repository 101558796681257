/*
 * Copyright (C) 2020-2021 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Kathriavan Panneeselvam
 */

import {Pipe, PipeTransform} from '@angular/core';



@Pipe({
    name: 'imperialResultValue'
  })
  export class ImperialUnitConversionPipe implements PipeTransform {
    transform(text:string,mmData:any){
        var inches:any = (mmData/25.4).toFixed(0);
        var feet = Math.floor(inches / 12);
        inches %= 12;
        return text+" "+feet + "ft " + inches + 'in';
      }
  }