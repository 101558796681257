/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Injectable } from '@angular/core';
import { HTTPStatus } from '../../../../../../ketos-lib/src/lib/services/http-status.service';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {
  container = document.body;
  element: HTMLElement = null;
  trailingCount = 0;
  timeout: NodeJS.Timer = null;
  forceOverlay = false;

  constructor(private httpStatus: HTTPStatus) {
    httpStatus.filteredCountSubject.subscribe(reqCount => {
      if (reqCount === this.trailingCount) {
        return;
      }
      if (reqCount > 0) {
        this.startPreloader();
      } else {
        this.removeDelayed();
      }
      this.trailingCount = reqCount;
    }, error => {
      this.resetPreloader();
    });

    httpStatus.forceOverlaySubject
      .subscribe(force => {
        if (force === true) {
          this.forceOverlay = true;
          this.startPreloader();
        } else {
          this.forceOverlay = false;
          this.removeDelayed();
        }
      });
  }

  show() {
    this.startPreloader();
  }

  hide() {
    this.removePreloader();
  }

  private removeDelayed() {
    setTimeout(() => {
      if (!this.forceOverlay && this.trailingCount < 1) {
        this.removePreloader();
      }
    }, 200);
  }

  private resetPreloader() {
    this.removePreloader();
  }

  private startPreloader() {
    if (!this.element) {
      this.element = document.createElement('div');
      this.element.innerHTML = `
        <div class="wrapper">
          <div class="loader" title="Loading...">
            <div class="gif"></div>
          </div>
        </div>
      `;
      this.element.className = 'preloader';
      this.container.appendChild(this.element);
    }

    // Clear existing timeout if any
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    // Set a timeout to automatically remove the preloader after 100 seconds
    this.timeout = setTimeout(() => {
      this.removePreloader();
    }, 100 * 1000);
  }

  private removePreloader() {
    if (this.element) {
      this.container.removeChild(this.element);
      this.element = null;
    }

    // Clear the timeout if the preloader is manually hidden
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}
