/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '../../services/legacy-services/app.service';
import {MenuItem} from '../left-menu/left-menu.component';
import {AppStorage} from '../../utils/AppStorage';
import {AppStateService} from '../../services//app-state.service';
import {AuthService} from '../../services//auth.service';
import { UiPlatformService } from '../../services//ui-platform.service';
import { UserStorageDataService } from '../../services/userStorageData.service';

export interface WebAndMobileTopMenuInterface extends MenuItem {
  mobileTitle?: string
  mobileVisibility?: boolean,
  mobileMenuItemPathName?: string,
  mobileIconName?:string
}
@Component({
  selector: 'lib-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
  @Input()
  smallHeader: boolean = false;

  public menu: WebAndMobileTopMenuInterface[] = [
    {
      title: 'Home',
      mobileTitle: "Dashboard",
      icon: 'home-icon',
      visibility: true,
      mobileVisibility: true,
      mobileMenuItemPathName: 'home',
      mobileIconName:'apps',
      route: '/dashboard'
    },
    {
      title: 'Sensors',
      icon: 'page-icon',
      visibility: true,
      mobileVisibility: true,
      mobileMenuItemPathName:'sensors',
      mobileIconName:'search',
      //visibility:this.userVisibilityCheck(),
      route: '/sensors'
    },
    {
      title: 'Predictive Modeler',
      icon: 'predictive-modeller-icon',
      mobileIconName:'earth-outline',
      visibility: this.userVisibilityCheck(),
      mobileVisibility: false,
      route: '/predictive-modeller'
    },
    {
      title: 'Locations',
      icon: 'locations-icon',
      visibility: this.locationsVisible(),
      mobileVisibility: true,
      mobileMenuItemPathName:'locations',
      mobileIconName:'pin',
      route: '/locations'
    },
  ];

  userMenu: any[] = ['/profile', '/users', '/unit-settings', '/about', '/support'];
  topMenu: any[] = ['/sensors', '/predictive-modeller', '/locations'];

  previousUrl;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public appService: AppService,
    public appStateService: AppStateService,
    public authService: AuthService,
    public uiPlatformServie: UiPlatformService,
    public userStorageDataService: UserStorageDataService
  ) {
    if(!uiPlatformServie.webPlatform){
      this.initAppStorage();
    }
  }

  public userVisibilityCheck(): boolean {
    const userData = JSON.parse(AppStorage.getItem('user'));
    const role = userData?.roleName.toLocaleLowerCase();
    return ['superadmin', 'orgadmin', 'groupadmin'].indexOf(role) !== -1;

  }

  public locationsVisible(): boolean {
    const userData = JSON.parse(AppStorage.getItem('user'));
    const role = userData?.roleName.toLocaleLowerCase();
    return ['superadmin', 'orgadmin'].indexOf(role) !== -1;
  }
  async initAppStorage(){
    const userData = await JSON.parse(AppStorage.getItem('user'));
    this.userStorageDataService.updateUserData(userData)
  }
  redirectFromHome($event: MouseEvent) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      // do something
      console.log(localStorage.getItem('prevURL'), 'prevURL');
      setTimeout(() => {
        this.appStateService.init()
          .subscribe((res) => {
            console.log(res);
          });
      }, 100);
      //setTimeout(() => {
      if (localStorage.getItem('prevURL') && !this.topMenu.includes(localStorage.getItem('prevURL')) && !this.userMenu.includes(localStorage.getItem('prevURL'))) {
        //if(localStorage.getItem('prevURL')){
        this.menu[0].route = localStorage.getItem('prevURL');
        this.router.navigate([localStorage.getItem('prevURL')]);
      } else {
        this.router.navigate(['/dashboard']);
      }

      //},500)

      return;
    }
  }

  checkRightColMargin() {
    //if(localStorage.getItem('prevURL') === this.router.url){
    //this.menu[0].route = localStorage.getItem('prevURL');
    // this.previousUrl= localStorage.getItem('prevURL')
    //localStorage.setItem('prevURL',this.router.url)
    //}else{
    // localStorage.setItem('prevURL',this.router.url)


    if (localStorage.getItem('prevURL') && !this.topMenu.includes(localStorage.getItem('prevURL')) && !this.userMenu.includes(localStorage.getItem('prevURL'))) {
      this.menu[0].route = localStorage.getItem('prevURL');
      //this.router.navigate([localStorage.getItem('prevURL')]);
    }
    // else{
    //   this.router.navigate(['/dashboard']);
    // }


    //}

    const rElement = document.getElementById('rightColumn');
    rElement.style.marginLeft = '24px';

    return true;
  }

  ngOnInit() {
    //console.log(this.router.url, localStorage.getItem('prevURL'),"------",this.previousUrl,"---------")
    //this.previousUrl = localStorage.getItem('prevURL')?localStorage.getItem('prevURL'):this.router.url
    // if(localStorage.getItem('prevURL')){
    //   //this.menu[0].route = localStorage.getItem('prevURL');
    //   //setTimeout(()=>{
    //     this.previousUrl= localStorage.getItem('prevURL')
    //   //},500)
    //   localStorage.setItem('prevURL',this.router.url)
    // }
    this.previousUrl = localStorage.getItem('prevURL') ? localStorage.getItem('prevURL') : this.menu[0].route;
    // localStorage.removeItem('prevURL')
    const userData = JSON.parse(AppStorage.getItem('user'));
    if (userData?.organization_id === 2 && this.uiPlatformServie.webPlatform) {
      this.menu[4] = this.menu[3];
      this.menu[3] = {
        title: 'Operational Intelligence',
        icon: 'operational-intelligence-icon',
        route: '/predictive-modeller',
        visibility: true,
        mobileVisibility: false,
        submenu: [
          {title: 'Industrial Process Control', enabled: false, route: ''},
          {title: 'Nutrient Management', enabled: true, route: '/operational-intelligence/nutrient-management'},
          {title: 'Water Recycling and Reuse', enabled: false, route: ''},
          {title: 'Public Health', enabled: false, route: ''},
        ]
      };
    } else {
      if (!this.uiPlatformServie.webPlatform) {
        this.menu[4] = this.menu[3];
        this.menu[3] = {
          title: 'Alerts',
          icon: 'notifications-outline',
          mobileIconName: 'notifications-outline',
          visibility: false,
          mobileVisibility: true,
          mobileMenuItemPathName: 'alerts',
          route: '/alerts'
        };
      }
    }

    if (!this.locationsVisible()) {
      this.menu = this.menu.filter(item => item.title !== 'Locations');
    }
  }

}
