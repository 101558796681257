/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { History } from '../models/history';
import { Operating_status_history } from '../models/operating-_status-_history';

/**
 * Operating status history related operations
 */
@Injectable({
  providedIn: 'root',
})
class OperatingStatusHistoryService extends __BaseService {
  static readonly getSensorOperatingStatusHistoryPath = '/operating_status_history';
  static readonly ListAllOrganizationAddressPath = '/operating_status_history/shield/{sensor_id}/current_operating_status_start_date';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OperatingStatusHistoryService.GetSensorOperatingStatusHistoryParams` containing the following parameters:
   *
   * - `sensor_id`: sensor id
   *
   * - `start_time`: start time (inclusive) resolved date (ISO 8601
   *
   * - `end_time`: end time (inclusive) resolved date (ISO 8601
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getSensorOperatingStatusHistoryResponse(params: OperatingStatusHistoryService.GetSensorOperatingStatusHistoryParams): __Observable<__StrictHttpResponse<History>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sensorId != null) __params = __params.set('sensor_id', params.sensorId.toString());
    if (params.startTime != null) __params = __params.set('start_time', params.startTime.toString());
    if (params.endTime != null) __params = __params.set('end_time', params.endTime.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/operating_status_history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<History>;
      })
    );
  }
  /**
   * @param params The `OperatingStatusHistoryService.GetSensorOperatingStatusHistoryParams` containing the following parameters:
   *
   * - `sensor_id`: sensor id
   *
   * - `start_time`: start time (inclusive) resolved date (ISO 8601
   *
   * - `end_time`: end time (inclusive) resolved date (ISO 8601
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getSensorOperatingStatusHistory(params: OperatingStatusHistoryService.GetSensorOperatingStatusHistoryParams): __Observable<History> {
    return this.getSensorOperatingStatusHistoryResponse(params).pipe(
      __map(_r => _r.body as History)
    );
  }

  /**
   * @param params The `OperatingStatusHistoryService.ListAllOrganizationAddressParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  ListAllOrganizationAddressResponse(params: OperatingStatusHistoryService.ListAllOrganizationAddressParams): __Observable<__StrictHttpResponse<Array<Operating_status_history>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/operating_status_history/shield/${encodeURIComponent(String(params.sensorId))}/current_operating_status_start_date`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Operating_status_history>>;
      })
    );
  }
  /**
   * @param params The `OperatingStatusHistoryService.ListAllOrganizationAddressParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  ListAllOrganizationAddress(params: OperatingStatusHistoryService.ListAllOrganizationAddressParams): __Observable<Array<Operating_status_history>> {
    return this.ListAllOrganizationAddressResponse(params).pipe(
      __map(_r => _r.body as Array<Operating_status_history>)
    );
  }
}

module OperatingStatusHistoryService {

  /**
   * Parameters for getSensorOperatingStatusHistory
   */
  export interface GetSensorOperatingStatusHistoryParams {

    /**
     * sensor id
     */
    sensorId: number;

    /**
     * start time (inclusive) resolved date (ISO 8601
     */
    startTime?: string;

    /**
     * end time (inclusive) resolved date (ISO 8601
     */
    endTime?: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for ListAllOrganizationAddress
   */
  export interface ListAllOrganizationAddressParams {
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { OperatingStatusHistoryService }
