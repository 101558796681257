import { Pipe, PipeTransform } from '@angular/core';
import {CalculateHoursToNow} from '../utils/TimeHelper';

@Pipe({
  name: 'runtime'
})
export class RuntimePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (typeof value !== 'string') {
      return null;
    }
    return CalculateHoursToNow([value]);
  }

}
