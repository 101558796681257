import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueFromNestedDicts'
})
export class ValueFromNestedDictsPipe implements PipeTransform {

  public transform(lastKey: number | string, dict: any, ...nestedKeysOverride: (string|number)[]): string {
     // console.log('valueFromNestedDicts', lastKey, dict);
    if (Array.isArray(nestedKeysOverride) && nestedKeysOverride.length > 0) {
      return this.recursiveAccess(dict, nestedKeysOverride) || '';
    } else {
      return dict?.[lastKey] || '';
    }
  }

  recursiveAccess(dict: any, args: (string|number)[]): string {
    if (args.length === 1) {
      return dict?.[args[0]];
    }
    return this.recursiveAccess(dict?.[args[0]], args.slice(1));
  }
}
