/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AlertSummaryService } from './services/alert-summary.service';
import { AlertsService } from './services/alerts.service';
import { AnalyticsService } from './services/analytics.service';
import { AuditService } from './services/audit.service';
import { ConcPredService } from './services/conc-pred.service';
import { ConfigService } from './services/config.service';
import { ContentService } from './services/content.service';
import { ContinuousModeService } from './services/continuous-mode.service';
import { CpUsersService } from './services/cp-users.service';
import { CustomReportService } from './services/custom-report.service';
import { DatasheetsService } from './services/datasheets.service';
import { GroupsService } from './services/groups.service';
import { InsightsService } from './services/insights.service';
import { MessageService } from './services/message.service';
import { NotificationServiceService } from './services/notification-service.service';
import { OrganizationService } from './services/organization.service';
import { OrganizationsService } from './services/organizations.service';
import { ResultAggregatesService } from './services/result-aggregates.service';
import { ResultsService } from './services/results.service';
import { SavingsService } from './services/savings.service';
import { SchedulerService } from './services/scheduler.service';
import { SensorGroupsService } from './services/sensor-groups.service';
import { SensorSummaryService } from './services/sensor-summary.service';
import { SensorsService } from './services/sensors.service';
import { ShieldService } from './services/shield.service';
import { ShieldFluidLevelsService } from './services/shield-fluid-levels.service';
import { ShieldKsmTestsService } from './services/shield-ksm-tests.service';
import { ShieldScheduledTestsService } from './services/shield-scheduled-tests.service';
import { ShieldTestSchedulesService } from './services/shield-test-schedules.service';
import { ShieldTestsService } from './services/shield-tests.service';
import { ShieldThresholdsService } from './services/shield-thresholds.service';
import { ShieldDevicesService } from './services/shield-devices.service';
import { ShieldThresholdService } from './services/shield-threshold.service';
import { StatusService } from './services/status.service';
import { SupportService } from './services/support.service';
import { TasksService } from './services/tasks.service';
import { TestService } from './services/test.service';
import { TestScheduleService } from './services/test-schedule.service';
import { UsersService } from './services/users.service';
import { WaterSourcesService } from './services/water-sources.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AlertSummaryService,
    AlertsService,
    AnalyticsService,
    AuditService,
    ConcPredService,
    ConfigService,
    ContentService,
    ContinuousModeService,
    CpUsersService,
    CustomReportService,
    DatasheetsService,
    GroupsService,
    InsightsService,
    MessageService,
    NotificationServiceService,
    OrganizationService,
    OrganizationsService,
    ResultAggregatesService,
    ResultsService,
    SavingsService,
    SchedulerService,
    SensorGroupsService,
    SensorSummaryService,
    SensorsService,
    ShieldService,
    ShieldFluidLevelsService,
    ShieldKsmTestsService,
    ShieldScheduledTestsService,
    ShieldTestSchedulesService,
    ShieldTestsService,
    ShieldThresholdsService,
    ShieldDevicesService,
    ShieldThresholdService,
    StatusService,
    SupportService,
    TasksService,
    TestService,
    TestScheduleService,
    UsersService,
    WaterSourcesService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
