/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

export enum SensorTypes {
  wave = 'wave',
  shield = 'shield',
  iceberg = 'iceberg'
}
