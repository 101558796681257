/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
@Input() showMenu;
  
  constructor() {
   

  }
  //showFooter:boolean=true;
  //footerBottomPostion;
  ngOnInit() {
   // this.showFooter = false;
    console.log(this.showMenu,"showMenu")
     //const appRoot = document.getElementsByTagName('app-root')[0];
     //setTimeout(() => {
      
      // if(!this.showMenu){
      //   this.showFooter = true;
      // }
     //}, 100)
    
    //console.log(window.innerHeight,appRoot.scrollHeight,window.screen.height, appRoot.clientHeight,appRoot.scrollTop)
  //   if(this.showMenu){
  //     appRoot
  //       .addEventListener('scroll',  () => {
  //         console.log(window.innerHeight,appRoot.scrollHeight,window.screen.height, appRoot.clientHeight,appRoot.scrollTop)
  //       //if (appRoot.scrollTop>100) {
  //         //const scrollableHeight = window.screen.height - appRoot.scrollHeight
  //       //   if ((appRoot.scrollHeight +appRoot.scrollTop) > window.innerHeight) { 
  //       //   this.showFooter = true;
  //       // }
  //       // else {
  //       //   this.showFooter = false;
  //       // }
  //         let pos = (document.documentElement.scrollTop || appRoot.scrollTop) + document.documentElement.offsetHeight;
  //       //let pos = appRoot.scrollTop + document.documentElement.offsetHeight
  //       let max = document.documentElement.scrollHeight;
  //       // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
  //       console.log(pos," pos-max ",max)

  //       // if(pos > max  )   {
  //       // //if((window.innerHeight + window.scrollY) >= document.body.offsetHeight ){
  //       // //Do your action here
  //       // this.showFooter = true;
  //       // }else{
  //       //   this.showFooter = false;
  //       // }
        
  //     });
  //   }
   }
 
  private init() {
  }

  
}
