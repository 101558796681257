/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {Component, ElementRef, EventEmitter, Input, Output, ViewChild, Renderer2} from '@angular/core';
import * as d3 from 'd3';
import * as cloneDeep from 'lodash/cloneDeep';
import { DropdownValueInterface } from '../dropdown/models/dropdown-options.interface';
import { ImperialUnitConversionPipe} from '../../../pipes/imperial-unit-conversion.pipe'
import { AppStorage} from '../../../utils/AppStorage';
import {FormatResultValuePipe} from '../../../pipes/format-result-value.pipe';
@Component({
  selector: 'app-water-tank',
  templateUrl: './water-tank.component.html',
  styleUrls: ['./water-tank.component.scss']
})
export class WaterTankComponent {
  @ViewChild('waterTankArea', {static: true}) waterTankArea: ElementRef;
  @ViewChild('dynamicTagsBlock',{static: true})
  public dynamicTagsBlock:ElementRef;
  private _data: DropdownValueInterface;
  @Input() set data(value: DropdownValueInterface) {
    // console.log(value)
    this.waterTankModel.data = value;
    this.init();
  }

  @Output() tanksChanged: EventEmitter<any> = new EventEmitter<any>();
  userData = JSON.parse(AppStorage.getItem('user'));
  public waterTankModel: any = {
    data: null,
    settings: {
      contentTop: 35,
      marginBottom: 50,
      marginTop: 30,
      marginRight: 50,
      marginLeft: 47,
      width: 500,
      height: 300,
      yPoints: 10
    }
  };
  canUserEnterCustomerInput = false;
  customerInput:any = {
    //connected_tanks:null,
    connected_tanks:1,
    tank_volume_2:0,
    tank_volume_1:0,
    sensor_id:null
  };
  extraTank;
  public topTankImg = new URL('../../../../assets/water-tank/images/top.svg', import.meta.url);
  public topWaterImg = new URL('../../../../assets/water-tank/images/wave.svg', import.meta.url);
  public waterLeftArrow = new URL('../../../../assets/water-tank/images/left-arrow.svg', import.meta.url);
  public waterRightArrow = new URL('../../../../assets/water-tank/images/right-arrow.svg', import.meta.url);
  constructor(
    private renderer:Renderer2,
    private imperialUnitConversionPipe:ImperialUnitConversionPipe,
    private formatResultValuePipe: FormatResultValuePipe
  ) {

    this.waterTankModel.settings.contentTop += this.waterTankModel.settings.marginTop;
  }
  public tankMeasurementData = {
    chord1: {
      icon: 'tankicon',
      values: '',
      info: 'Current Tank Level'
    },
    chord2: {
      icon: 'tankicon',
      values: '',
      info: 'Current Volume',
      settings:'true',
      threshold:'',
      thresholdInternal:'',
      sensorId:'',
      selectedSensorType:''
    },
    chord3: {
      icon: 'tankicon',
      values:'',
      info: 'Total Volume'
    },
    chord4: {
      icon: 'tankicon',
      values:'',
      info: 'Connected Tanks'
    }
  }
  public isSubmitUserInput:boolean=false;
  public sameVolumeTanks:boolean;
  public chordEditSettings: {
    thresHoldParameters: boolean[];
  } = {
    thresHoldParameters: []
  };
  init() {
    this.chordEditSettings.thresHoldParameters = [].fill(false, 0, 0);
    this.canUserEnterCustomerInput = this.canEnterCustomerInput();
    // console.log(this.waterTankModel)
    const yScale = this.getYScale();
    const yScalePercentage = this.calculatePercentage();

    const svg = d3.select(this.waterTankArea.nativeElement);

    svg.attr('height', this.waterTankModel.settings.height + this.waterTankModel.settings.contentTop + this.waterTankModel.settings.marginBottom);

    svg.select('.yAxisGroup')
      .attr('class', 'y axis water-tank__graph');

    svg.select('.yAxisContent')
      .attr('class', 'yAxisContent')
      .attr('height', this.waterTankModel.settings.height + this.waterTankModel.settings.contentTop)
      .attr('transform', `translate(${this.waterTankModel.settings.marginLeft}, 0)`);

    const yData = [0];
    const yMax = this.waterTankModel.data.tank_height;
    for (let i = 1; i <= this.waterTankModel.settings.yPoints; i++) {
      yData.push(+(Math.round((yMax / this.waterTankModel.settings.yPoints * i) * 2) / 2).toFixed(1)); // round to 0.5
    }

    const yAxis = svg
      .select('.yAxisContent');
    yAxis.selectAll('.yAxisContent *').remove();

    const yAxisText = yAxis
      .selectAll('.yaxistextLabels')
      .data(yData);

    yAxisText
      .enter()
      .append('text')
      .text((d, i) => {
        let text = '';
        if (i === yData.length - 1) {
          text = text.concat('Max Height: ');
        }
        return this.imperialUnitConversionPipe.transform(text,d)

       // return text.concat(d + ' mm');
      })
      .attr('class', (d, i) => {
          let textClass = '';
          if (i === yData.length - 1) {
            textClass = textClass.concat('waterTankLabelMax ');
          }
          return textClass.concat('yaxistextLabels');
        }
      )
      .attr('x', -10)
      .attr('y', (d, i) => yScale(d) + this.waterTankModel.settings.contentTop + 5);

    const maxHeightLabel = svg.select('.waterTankLabelMax');
    if (maxHeightLabel.node()) {
      const maxLabelBBox = (<any>maxHeightLabel).node().getBBox();
      this.waterTankModel.settings.marginLeft = maxLabelBBox.width + 10;
    }

    svg.attr('width', this.waterTankModel.settings.width + this.waterTankModel.settings.marginLeft + this.waterTankModel.settings.marginLeft);
    svg.select('.yAxisContent')
      .attr('transform', `translate(${this.waterTankModel.settings.marginLeft}, 0)`);

    yAxisText
      .exit()
      .remove();

    yAxis
      .selectAll('.horizontalLinesTank')
      .data(yData.slice(1))
      .enter()
      .append('line')
      .attr('class', 'horizontalLinesTank')
      .attr('x1', 10)
      .attr('y1', (d, i) => yScale(d) + this.waterTankModel.settings.contentTop)
      .attr('x2', 1)
      .attr('y2', (d, i) => yScale(d) + this.waterTankModel.settings.contentTop);

    yAxis.append('line')
      .style('stroke-dasharray', ('9, 9'))
      .attr('class', 'horizontalLinesTank')
      .attr('x1', this.waterTankModel.settings.width)
      .attr('y1', yScale(this.waterTankModel.data.current_height) + this.waterTankModel.settings.contentTop)
      .attr('x2', 0)
      .attr('y2', yScale(this.waterTankModel.data.current_height) + this.waterTankModel.settings.contentTop);
      // console.log(this.waterTankModel.data)
    // const calculatedVolumePercent = this.waterTankModel.data.volume_percentage;
    // yAxis
    //   .append('text')
    //   .text(`${calculatedVolumePercent}%`)
    //   .attr('class', 'waterTankPercent')
    //   .attr('y', yScale(this.waterTankModel.data.current_height) + this.waterTankModel.settings.contentTop + 6);

    // const waterTankPercent = svg.select('.waterTankPercent');
    // waterTankPercent
    //   .attr('x', this.waterTankModel.settings.width + (<any>waterTankPercent).node().getBBox().width + 5);

    this.generateImages(svg);
    this.tankMeasurementData.chord1.values = this.formatResultValuePipe.transform(this.waterTankModel.data.volume_percentage, 'cartridge_life');
    this.tankMeasurementData.chord2.values = this.formatResultValuePipe.transform(this.waterTankModel.data.current_volume, 'total_volume');
    this.tankMeasurementData.chord3.values = this.formatResultValuePipe.transform(this.waterTankModel.data.tank_volume, 'total_volume');
    this.tankMeasurementData.chord4.values = this.formatResultValuePipe.transform(this.waterTankModel.data.connected_tanks, 'total_measurements');
    this.tankMeasurementData.chord2.threshold = this.waterTankModel.data.alerts_higher_threshold;
    this.tankMeasurementData.chord2.thresholdInternal = this.waterTankModel.data.alerts_lower_threshold;
    this.tankMeasurementData.chord2.selectedSensorType = this.waterTankModel.data.selectedSensorType;
    this.tankMeasurementData.chord2.sensorId = this.waterTankModel.data.sensor_id;

    this.tankMeasurementData = cloneDeep(this.tankMeasurementData);

  }

  generateImages(svg) {
    const yScale = this.getYScale();
    const waterContent = svg.select('.waterContent');
    // waterContent.selectAll('.waterContent').remove();
    waterContent
      .attr('fill', 'url(#waterGradient)')
      .attr('height', this.waterTankModel.settings.height - yScale(this.waterTankModel.data.current_height))
      .attr('width', this.waterTankModel.settings.width)
      .attr('x', this.waterTankModel.settings.marginLeft)
      .attr('y', yScale(this.waterTankModel.data.current_height) + this.waterTankModel.settings.contentTop);

    let waterContentBBoxHeight;
    if (waterContent.node()) {
      const waterContentBBox = waterContent.node().getBBox();
      waterContentBBoxHeight = waterContentBBox.height;
    }

    svg.selectAll('.waterContentVolumeLabel').remove();
    // const waterContentText = svg
    //   .append('text')
    //   .text(`${this.waterTankModel.data.current_volume} G / ${this.waterTankModel.data.tank_volume} G`)
    //   .attr('class', 'waterContentVolumeLabel');

    // let waterContentTextBBoxWidth, waterContentTextBBoxHeight;
    // if (waterContentText.node()) {
    //   const waterContentBBox = waterContentText.node().getBBox();
    //   waterContentTextBBoxWidth = waterContentBBox.width;
    //   waterContentTextBBoxHeight = waterContentBBox.height;
    // }

    // waterContentText
    //   .attr('x', (this.waterTankModel.settings.width / 2) - (waterContentTextBBoxWidth / 2) + this.waterTankModel.settings.marginLeft -20)
    //   .attr('y', yScale(this.waterTankModel.data.current_height) + this.waterTankModel.settings.contentTop + (waterContentBBoxHeight / 2) + waterContentTextBBoxHeight);

    svg.select('.waterTop')
      .attr('x', this.waterTankModel.settings.marginLeft)
      .attr('y', yScale(this.waterTankModel.data.current_height) + this.waterTankModel.settings.contentTop - 36);

    svg.select('.waterBottom')
      .attr('fill', 'url(#waterGradientBottom)')
      .attr('transform', `translate(${this.waterTankModel.settings.marginLeft}, ${this.waterTankModel.settings.height + this.waterTankModel.settings.contentTop})`);

    /*let arrowsHeight, arrowsWidth;
    if (arrowLeft.node()) {
      let BBox = arrowLeft.node().getBBox();
      console.log(arrowLeft.node());
      arrowsHeight = BBox.height;
      arrowsWidth = BBox.width;
      console.log(arrowsWidth, arrowsHeight);
    }*/

    const arrowLeft = svg.select('.waterVolumeArrowLeft');
    const arrowRight = svg.select('.waterVolumeArrowRight');
    arrowLeft
      .attr('x', this.waterTankModel.settings.marginLeft)
      .attr('y', yScale(this.waterTankModel.data.current_height) + this.waterTankModel.settings.contentTop)
      .attr('width', 21)
      .attr('height', this.waterTankModel.settings.height - yScale(this.waterTankModel.data.current_height));

    arrowRight
      .attr('x', this.waterTankModel.settings.width + this.waterTankModel.settings.marginLeft - 21)
      .attr('y', yScale(this.waterTankModel.data.current_height) + this.waterTankModel.settings.contentTop)
      .attr('width', 21)
      .attr('height', this.waterTankModel.settings.height - yScale(this.waterTankModel.data.current_height));

    const tankTop = svg.select('.tankTop')
      .attr('x', this.waterTankModel.settings.marginLeft)
      .attr('y', 0);

    /*let tankTopBBoxHeight;
    if (tankTop.node()) {
      let tankTopBBox = tankTop.node().getBBox();
      tankTopBBoxHeight = tankTopBBox.height;
      console.log(tankTopBBoxHeight);
    }*/

    svg.select('.tankBackground')
      .attr('fill', '#ffffff')
      .attr('opacity', '0.13')
      .attr('rx', this.waterTankModel.settings.width / 2)
      .attr('ry', 36)
      .attr('height', this.waterTankModel.settings.height + this.waterTankModel.settings.marginBottom + this.waterTankModel.settings.marginTop)
      .attr('width', this.waterTankModel.settings.width)
      .attr('x', this.waterTankModel.settings.marginLeft)
      .attr('y', 4);
  }

  getYScale() {
    return d3.scaleLinear()
      .domain([0, this.waterTankModel.data.tank_height])
      .range([this.waterTankModel.settings.height, 0]);
  }

  calculatePercentage() {
    return d3.scaleLinear()
      .domain([0, this.waterTankModel.data.tank_height])
      .range([0, 100]);
  }
  canEnterCustomerInput(): boolean {
    const role = this.userData.roleName.toLocaleLowerCase();

    return ['superadmin', 'orgadmin', 'groupadmin'].indexOf(role) !== -1;
  }
  dataChanged(e){
    console.log(e)
    if(e!== "" && e){
    if((e !== this.waterTankModel.data.tank_volume_1 && e !== this.customerInput.tank_volume_1) || this.customerInput.tank_volume_2 !== 0){
      console.log(e,"inif")
      this.isSubmitUserInput  = true;
    }
    }else{
      this.isSubmitUserInput = false;
    }

  }
  checkboxChanged(e){

    if(!this.extraTank){
      this.customerInput.tank_volume_2 = '';
      this.isSubmitUserInput = true;
    }
    this.isSubmitUserInput = !this.extraTank
    this.customerInput = cloneDeep(this.customerInput);

  }
  customerInputPopup () {
    this.customerInput = {
      tank_volume_1:this.waterTankModel.data.tank_volume_1,
      tank_volume_2:this.waterTankModel.data.tank_volume_2?this.waterTankModel.data.tank_volume_2:'',
      sensor_id:this.waterTankModel.data.sensor_id
    }
    this.extraTank = this.waterTankModel.data.tank_volume_2?true:false
  }

  saveCustomerInput() {
    this.customerInput.connected_tanks=1;
    if(!this.extraTank){
      this.customerInput.tank_volume_2 = 0
      this.customerInput.connected_tanks=0;
    }
    this.extraTank=false;
  }

  close(string) {
    console.log('TODO: transition to new modals')
  }
}
