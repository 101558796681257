/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Component, Input, OnInit } from '@angular/core';
import {ModalController, NavParams, PopoverController} from '@ionic/angular';
import {SensorTypes} from '../../../models/sensor-types.enum';

export interface AlertFilterInterface {
  sensorType: SensorTypes;
  severity: string;
  liveOrArchived?: string;
}
export interface SensorFilterInterface {
  sensorType: SensorTypes;
  online: string;
}
export interface SensorFilterOptionsInterface {
  filters: SensorFilterInterface,
  title: string,
  showSeverity: boolean,
  showOnline: boolean,
}
export interface AlertFilterOptionsInterface {
  filters: AlertFilterInterface,
  title: string,
  showSeverity: boolean,
  showOnline: boolean,
  liveData: boolean
}

@Component({
  selector: 'app-alerts-filter-modal',
  templateUrl: './alerts-filter-modal.component.html',
  styleUrls: ['./alerts-filter-modal.component.scss']
})
export class AlertsFilterModalComponent implements OnInit {

  @Input() liveData: boolean = true;
  title = 'Filter Alerts';
  selectedSensorType = 'all';
  showSeverity = true;
  selectedSeverity = 'all';
  showOnline = false;
  selectedOnline = 'all';
  selectedData = 'live';
  //liveData = true;
  archivedData = false;
  constructor(private modalController: ModalController,
              public popoverController: PopoverController,
              private navParams: NavParams) { }

  ngOnInit() {
    if (this.navParams.data.filters) {
      this.selectedSensorType = this.navParams.data.filters.sensorType;
      this.selectedSeverity = this.navParams.data.filters.severity;
    }
    if (this.navParams.data.title) {
      this.title = this.navParams.data.title;
    }
    if (this.navParams.data.hasOwnProperty('showSeverity')) {
      this.showSeverity = this.navParams.data.showSeverity;
    }
    if (this.navParams.data.hasOwnProperty('showOnline')) {
      this.showOnline = this.navParams.data.showOnline;
    }
    if (this.navParams.data.filters?.hasOwnProperty('online')) {
      this.selectedOnline = this.navParams.data.filters?.online;
    }
  }

  async donePressed() {
    let alertObj={}
    if(this.selectedSeverity){
     alertObj = {
      sensorType: this.selectedSensorType,
      severity: this.selectedSeverity,
      online: this.selectedOnline,
      liveOrArchived:this.selectedData
    }
    }else{
      alertObj = {
        sensorType: this.selectedSensorType,
        severity: this.selectedSeverity,
        online: this.selectedOnline
      }
    }
    await this.popoverController.dismiss(<AlertFilterInterface>alertObj);
  }

  async cancel() {
    await this.popoverController.dismiss();
  }
  processData(ev, tab){
    console.log(tab)
    if(ev.detail.checked && tab === "live"){
      this.selectedData = "live";
      this.liveData = true;
    } else if(!ev.detail.checked && tab === "live"){
      this.selectedData = "archived";
      this.liveData = false;
    } 
    else if(ev.detail.checked && tab === "archived"){
      this.selectedData = "archived";
      this.liveData = false;
    } else if(!ev.detail.checked && tab === "archived"){
      this.selectedData = "live";
      this.liveData = true;
    } 

  }
  static openAsModal(popoverController: PopoverController, existingOptions, ev: Event) {
    console.log(existingOptions,"ext")
    return new Promise((resolve) => {
      popoverController.create({
        component: AlertsFilterModalComponent,
          componentProps: {
        filters: existingOptions.filters,
        title: existingOptions.title,
        showSeverity: existingOptions.showSeverity,
        showOnline: existingOptions.showOnline,
        liveData:existingOptions.liveData
      },
        cssClass: ['dark-popover','alert-popover'],
        event: ev,
        translucent: true,
      })
        .then((popover: HTMLIonPopoverElement) => {
          popover.onDidDismiss().then(result => {
            resolve(result?.data);
          });
          popover.present();
        });
    })
  }
}
