import {Component, Input, OnInit, Output} from '@angular/core';
import {AuthUserResponse} from "../../../services/user.service";
import {forkJoin, mergeMap, Observable} from "rxjs";
import {ModalController} from "@ionic/angular";
import {UiPlatformService} from "../../../services/ui-platform.service";
import {KetosPopupService} from "../../../services/ketos-popup.service";
import {AuthService} from "../../../services/auth.service";
import {ShieldDevicesCachedService} from "../../../services/shield-devices-cached.service";
import {ExperimentConfigurationsService} from "../../../services/experiment-configurations.service";
import {SensorsService} from "../../../../../../api/src/lib/services/sensors.service";
import {Sensor_settings} from "../../../../../../api/src/lib/models/sensor-_settings";
import {Sensor_setting} from "../../../../../../api/src/lib/models/sensor-_setting";
import {Sensor} from "../../../../../../api/src/lib/models/sensor";
import {CloneDeep} from "../../../utils/utils";
import {AppStateService} from "../../../services/app-state.service";
import {SensorsBlacklistService} from "../../../services/sensors-blacklist.service";


interface SensorSettingExtended extends Omit<Sensor_setting, 'value'> {
  value?: string;
}

@Component({
  selector: 'lib-sensor-settings-modal',
  templateUrl: './sensor-settings-modal.page.html',
  styleUrls: ['./sensor-settings-modal.page.scss'],
})
export class SensorSettingsModalPage implements OnInit {
  model: SensorSettingExtended;
  selectedOptions: {[key: string]: boolean} = {};
  originalSelectedOptions: {[key: string]: boolean} = {};
  saveEnabled = false;

  selectedSensors: Sensor[];
  @Input() sensorId: number;

  public user: AuthUserResponse;

  constructor(public modalController: ModalController,
              public uiPlatformService: UiPlatformService,
              public popupService:KetosPopupService,
              private auth: AuthService,
              public devicesCachedService: ShieldDevicesCachedService,
              public experimentConfigurationsService: ExperimentConfigurationsService,
              private sensorsService: SensorsService,
              private sensorsBlacklistService: SensorsBlacklistService,
  ) {
    this.user = auth.getUser();
  }

  getSensorLabel = (sensors: Sensor[]): string => {
    if (!sensors || sensors?.length < 1) {
      return 'All Sensors';
    }
    if (sensors.length < 6) {
      return sensors.map(sn => sn.location_name).join(', ');
    }
    if (sensors.length < this.devicesCachedService.model.length) {
      return sensors.length + ' Sensors';
    }
    return 'All Sensors';
  }

  ngOnInit() {
    if (this.sensorId) {
      const match = this.devicesCachedService.model.map(dev => dev.sensor).find(sn => sn.id === this.sensorId);
      if (match) {
        this.selectedSensors = [match];
      }
    }
    this.fetchData();
  }

  fetchData() {
    let sensors: Sensor[];
    forkJoin([
      this.devicesCachedService.getCached(false),
      this.experimentConfigurationsService.initOnce()
    ])
      .pipe(
        mergeMap(res => {
          const settingsQueries: Observable<Sensor_settings>[] = [];
          if (this.selectedSensors?.length > 0) {
            sensors = this.selectedSensors;
          } else {
            sensors = this.devicesCachedService.model.map(dev => dev.sensor);
          }
          sensors.map(sensor => {
            settingsQueries.push(this.sensorsService.getSensorSettings({sensorId: sensor.id}))
          })
          return forkJoin(settingsQueries)
        })
      )
      .subscribe(ress => {
        const settings = [];
        sensors.map((dev, idx) => {
          const setting = ress[idx].items?.find(setting => setting.key === 'cp_experiment_blacklist');
          if (setting) {
            settings.push(setting);
          }
        });
        if (settings.length > 0) {
          this.model = settings[0];
          this.selectedOptions = {};
          this.model.choices.map((choice: string) => this.selectedOptions[choice] = true);
          this.model.value?.split(',').map(key => {
            if (this.selectedOptions[key] === true) {
              this.selectedOptions[key] = false
            }
          });
          if (settings.length > 1) {
            for (let i = 1 ; i < settings.length ; i++) {
              let setting = settings[i];
              if (this.model.value !== setting.value) {
                setting.value?.split(',').map(selected => this.selectedOptions[selected] = false)
              }
            }
          }
          this.originalSelectedOptions = CloneDeep(this.selectedOptions);
        }
        this.calculateSaveEnabled();
      })
  }

  sensorsSelected(sensors: Sensor[]) {
    console.log('sensorsSelected', sensors);
    this.selectedSensors = sensors.slice();
    this.fetchData();
  }

  saveSettings() {
    let sensorIds = this.selectedSensors.map(sensor => sensor.id);
    if (sensorIds.length === 0) {
      sensorIds = this.devicesCachedService.model.map(dev => dev.sensor.id);
    }
    const valueStr = Object.keys(this.selectedOptions)
      .map(key => this.selectedOptions[key] !== true ? key : false)
      .filter(strOrFalse => typeof strOrFalse === "string")
      .join(',') || '';
    const puts = sensorIds.map(sensorId => this.sensorsService.putSensorSettings({sensorId, payload: {items: [{key: this.model.key, value: valueStr}]}}));
    forkJoin(puts)
      .subscribe((res: {status: string, message: string}[]) => {
        console.log('save setting result:', res);
        if (res.length > 0) {
          let message = res[0].message;
          if (res.length > 1) {
            const mismatches = res.filter(re => re.message !== message);
            if (mismatches.length > 0) {
              message = mismatches[0].message;
            }
          }
          this.popupService.presentOkAlert(message);
          this.sensorsBlacklistService.getSensorBlackdict().subscribe(res => {});
          this.fetchData();
        }
      })
  }

  calculateSaveEnabled() {
    for (let key of Object.keys(this.originalSelectedOptions)) {
      if (this.originalSelectedOptions[key] !== this.selectedOptions[key]) {
        this.saveEnabled = true;
        return;
      }
    }
    if (Object.keys(this.originalSelectedOptions).length !== Object.keys(this.selectedOptions).length) {
      this.saveEnabled = true;
      return;
    }
    this.saveEnabled = false;
  }
}
