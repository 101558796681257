import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluck'
})
export class PluckPipe implements PipeTransform {

  transform (input: any[], key: string, nestedIn?: string): any {
    if (nestedIn) {
      return input?.map(value => nestedIn in value ? value[nestedIn] : {}).map(value => value[key]);
    }
    return input?.map(value => value[key]);
  }

}
