/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivationEnd, Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { filter } from 'rxjs/operators';
import {Observable, of, Subscription} from 'rxjs';
import {AppState, AppStateService} from "../../services/app-state.service";
import {UiPlatformService} from "../../services/ui-platform.service";
import {SummaryShieldService} from "../sensor-summary/summary-shield.service";
import {SensorSummaryService} from "../../../../../api/src/lib/services/sensor-summary.service";
import {AlertController} from "@ionic/angular";
import {UserStorageDataService} from "../../services/userStorageData.service";
import { AppStorage } from '../../utils/AppStorage';

@Component({
  selector: 'lib-main',
  templateUrl: './main.page.html',
  styleUrls: ['./main.page.scss']
})
export class MainPage implements OnInit, OnDestroy {

  segmentButtons = [
    {icon: 'clipboard', name: 'summary', title: 'Summary', pulldown: false},
    {icon: 'pulse', name: 'real-time-data', title: 'Real Time Data', pulldown: false},
    {icon: 'stats-chart', name: 'graphical-view', title: 'Graphical View', pulldown: false},
    {icon: 'play-circle', name: 'start-schedule-test', title: 'Start/Schedule Test', pulldown: false},
    {icon: 'pulse', name: 'lab-data', title: 'External Lab Data', pulldown: false},
    // {icon: 'information', name: 'system-data', title: 'System Data', pulldown: false},
    {icon: 'stats-chart', name: 'data-correlation', title: 'Data Correlation', pulldown: false},// document
    {icon: 'water-outline', name: 'water-safety', title: 'Regional Water Safety', pulldown: false},// document
    {icon: 'cash-outline', name: 'savings', title: 'Savings', pulldown: false}, // document
    {icon: 'copy', name: 'reporting', title: 'Reports', pulldown: false}, // document

  ];

  public openPage;

  alerts = {orangeAlerts: 0, redAlerts: 0};
  public ready = false;
  public showTabs = true;
  public showMenu = true;
  public showSensorSummary = true;
  public hasSensors = true;
  private subscriptions: Subscription[] = [];
  previousUrl: string;
  pageTitle: string;
  constructor(
    private appStateService: AppStateService,
    public uiPlatformService: UiPlatformService,
    private router: Router,
    private route: ActivatedRoute,
    private summaryShieldService: SummaryShieldService,
    private sensorSummaryService: SensorSummaryService,

    private userStorageDataService: UserStorageDataService,

    public alertController: AlertController,
  ) {
    this.subscriptions.push(router.events.pipe(
      filter(event => event instanceof ActivationEnd),
    ).subscribe(event => {
      const url = (<ActivationEnd>event).snapshot['_routerState'].url;
      console.log(this.router.url,'url',this.segmentButtons)
      const page = url.split("/").pop();
      this.showTabs = !url.startsWith('/home') && url.startsWith('/dashboard');
      if(this.showTabs) {
        this.getPageName(page).subscribe(title => {
          console.log(page, "page", title);
          this.pageTitle = title;
        });
      }
    }));
  }

  ngOnInit() {



    this.sensorSummaryService.GetShieldSensorSummary()
      .subscribe(res => {
        if (res.sensors_with_warnings?.length > 0) {
          this.alerts.orangeAlerts = res.sensors_with_warnings?.filter(item => item.warning_level === 3)[0]?.sensor_count || 0;
          this.alerts.redAlerts = res.sensors_with_warnings?.filter(item => item.warning_level === 4)[0]?.sensor_count || 0;
        }
      });
    if(this.uiPlatformService.webPlatform){
      this.init()
    }else{

        this.initAppStorage()

    }
  }
  getPageName(pageName: string): Observable<any> {
    const page = this.segmentButtons.find(page => page.name === pageName);

    if (page) {
      return of(page.title);
    }

    return of(null);
  }
  async initAppStorage(){
    const userData = await JSON.parse(AppStorage.getItem('user'));
    this.userStorageDataService.updateUserData(userData)
  }
  private init() {
    localStorage.setItem('prevURL',this.router.url)
    this.appStateService.init().subscribe((appState: AppState | null) => {
      // No sensors so just show blank content area
      console.log('appState',appState)
      if (appState === null) {
        this.showTabs = false;
        this.showMenu = false;
        this.showSensorSummary = false;
        this.hasSensors = false;
        this.ready = true;

        return this.router.navigate(['/blank']);
      }
      console.log(this.router)
      // Show/hide tabs and sensor summary based on current url
      this.showTabs = this.router.url.startsWith('/dashboard') && !this.router.url.startsWith('/dashboard/dashboard');
      this.showMenu = this.router.url.startsWith('/dashboard');
      this.showSensorSummary = this.router.url.startsWith('/dashboard') && this.router.url !== '/dashboard' && !this.router.url.startsWith('/dashboard/dashboard');
      this.subscriptions.push(this.router.events.pipe(
        filter(event => event instanceof ActivationEnd),
      ).subscribe(event => {
        const url = (<ActivationEnd>event).snapshot['_routerState'].url;
        this.showTabs = url.startsWith('/dashboard') && !url.startsWith('/dashboard/dashboard');
        this.showMenu = url.startsWith('/dashboard') && !url.startsWith('/dashboard/dashboard');
        this.showSensorSummary = url.startsWith('/dashboard') && url !== '/dashboard' && !url.startsWith('/dashboard/dashboard');
      }));
      this.ready = true;
    });
  }

  doRefresh(event) {
    if (this.openPage === this.segmentButtons[0]) {
      this.refreshSensorSummary(event);
      return;
    }
    // else if (this.openPage === this.segmentButtons[1]) {
    //   this.refreshRealTimeData(event);
    //   return;
    // }
    console.log('Begin async operation');

    setTimeout(() => {
      console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

  refreshSensorSummary(event) {
    const sub = this.summaryShieldService.triggerReloadSubject
      .subscribe(loading => {
        if (loading === false) {
          event.target.complete();
          sub?.unsubscribe();
        }
      });
    this.summaryShieldService.reloadData();
  }

  refreshRealTimeData(event) {
    // this.realTimeDataShieldService.getData(
    //   '',//this.appStateService.getAppState().value.location.sensor_sn,
    //   Object.assign({}, this.appStateService.selectedTimeInterval),
    //   true
    // ).subscribe(res => {
    //   event.target.complete();
    // });
  }

  pageSelected(page) {
    console.log('pageSelected', page);
    this.openPage = page;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
