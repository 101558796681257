import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import { GraphOptionsInterface } from '../ketos-graph.page';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'lib-ketos-chart-advance-option-multiselect-popover',
  templateUrl: './ketos-chart-advance-option-multiselect-popover.html',
  styleUrls: ['./ketos-chart-advance-option-multiselect-popover.scss'],
})
export class KetosChartAdvanceOptionMultiselectPopover implements OnInit {
  defaultModel: GraphOptionsInterface = {
    title: '',
    zoomDisabled: false,
    seperateYaxis: false,
    pointSize: 2,
  };
  @Input() model: GraphOptionsInterface;
  originalModel: GraphOptionsInterface;

  valid: boolean;
  constructor(private popoverController: PopoverController) {
  }

  ngOnInit() {
    //this.model = cloneDeep(this.model);
    // if(!this.model?.title){
    //   this.model.title = this.defaultModel.title
    // }
    if (this.model) {
      this.originalModel = this.model;
      this.model = cloneDeep(this.originalModel);
    } else {
      this.model = cloneDeep(this.defaultModel);
      this.originalModel = cloneDeep(this.defaultModel);
    }
    this.updateValid();
  }

  clampNumber(value: number, max: number, min = 1): number {
    if (typeof value === 'number') {
      if (value > max) {
        return max;
      }
      if (value < min) {
        return min;
      }
    }
    return value;
  }

  updateValid() {
    if (this.model.seperateYaxis !== true && this.model.ymin && this.model.ymax && this.model.ymin >= this.model.ymax) {
      this.valid = false;
      return;
    }
    if (
      this.originalModel.live === this.model.live &&
      this.originalModel.zoomDisabled === this.model.zoomDisabled &&
      this.originalModel.seperateYaxis === this.model.seperateYaxis &&
      this.originalModel.ymin === this.model.ymin && this.originalModel.ymax === this.model.ymax &&
      this.originalModel.title === this.model.title  &&
      this.originalModel.connectingLine === this.model.connectingLine &&
      this.originalModel.pointSize === this.model.pointSize
    ) {
      this.valid = false;
      return;
    }
    this.valid = true;
  }

  clear(){
    this.model = this.defaultModel;
    this.confirm();
  }
  confirm(){
    this.popoverController.dismiss(this.model);
  }

  cancel() {
    this.popoverController.dismiss();
  }

  static openAsModal(popoverController: PopoverController, existingOptions: GraphOptionsInterface, ev: Event):Promise<GraphOptionsInterface> {
    return new Promise((resolve) => {
      popoverController.create({
        component: KetosChartAdvanceOptionMultiselectPopover,
        componentProps: {
          model: existingOptions,
        },
        cssClass: 'dark-popover',
        event: ev,
        translucent: true,
      })
        .then((popover: HTMLIonPopoverElement) => {
          popover.onDidDismiss().then(result => {
            resolve(result?.data);
          });
          popover.present();
        });
    })
  }
}
