/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {Pipe, PipeTransform} from '@angular/core';
import {formatNumber} from '@angular/common';
import {ExperimentConfigurationsService} from '../services/experiment-configurations.service';
import {OrganizationSettingsService} from '../services/organization-settings.service';
import {FormatDisplayNumberPipe} from './format-display-number.pipe';
import {UnitFromSettingsConverterPipe} from './unit-from-settings-converter.pipe';
import {ClipAdlBdlPipe} from './clip-adl-bdl.pipe';
import {ResultUnitPipe} from './result-unit.pipe';

@Pipe({
  name: 'formatResultValue'
})
export class FormatResultValuePipe implements PipeTransform {
  constructor(private expConfService: ExperimentConfigurationsService,
              private orgSettingsService: OrganizationSettingsService,
              private unitFromSettingsConverterPipe: UnitFromSettingsConverterPipe,
              private clipAdlBdlPipe: ClipAdlBdlPipe,
              private formatDisplayNumberPipe: FormatDisplayNumberPipe,
              private resultUnitPipe: ResultUnitPipe
  ) {
  }

  transform(originalValue: any, type: string, errorRate: number = 0, showUnits = true, digitsInfoOverride?: string, skipAdl?: boolean, skipBdl?: boolean): any {
    errorRate = 0; // @todo Enable when correct
    if (type === null || originalValue === null) {
      return 'N/A';
    }

    if (isNaN(originalValue)) {
      return originalValue;
    }

    if (originalValue === 999999) {
      return 'ERROR';
    }

    // const originalValue = value;
    let fValue = formatNumber(originalValue, 'en_US', digitsInfoOverride || '1.3-3');

    const getUnit = (unit: string): string => {
      if (showUnits) {
        return ' ' + unit;
      }
      return '';
    };

    const clipBDL = (currentValue: string, bdl: number): string => {
      if (originalValue < bdl && skipBdl !== true) {
        return `< ${formatNumber(bdl, 'en_US', digitsInfoOverride || '1.3-3')}`;
      }
      return currentValue;
    };

    const clipADL = (currentValue: string, adl: number): string => {
      if (adl !== null && skipAdl !== true && originalValue > adl) {
        return `> ${formatNumber(adl, 'en_US', digitsInfoOverride || '1.3-3')}`;
      }
      return currentValue;
    };

    const errorIfNegative = (currentValue: string): string => {
      if (originalValue < 0) {
        return 'Error';
      }
      return currentValue;
    };

    const getErrorRate = (): string => {
      if (errorRate !== 0) {
        return ` (±${errorRate})`;
      }
      return '';
    };

    const bdlErrorRate = (bdl: number): string => {
      if (errorRate !== 0 && originalValue >= bdl) {
        return ` (±${errorRate})`;
      }
      return '';
    };

    const conf = this.expConfService.allExperimentConfigurationsMap ? this.expConfService.allExperimentConfigurationsMap[type] : undefined;
    if (conf) {
      if (typeof conf.adl === 'number') {
        fValue = clipADL(fValue, conf.bdl);
      }
      if (typeof conf.bdl === 'number') {
        fValue = clipBDL(fValue, conf.bdl)
      }
      fValue += getUnit(conf.unit);
      return fValue;
    }

    switch (type) {
      // Water health
      case 'conductivityExternalThreshold':
        fValue += getUnit(this.expConfService.experimentConfigurationsMap.conductivity.unit);
        fValue += bdlErrorRate(this.expConfService.experimentConfigurationsMap.conductivity.bdl);
        break;
      case 'tdsExternalThreshold': // TDS < 10
        fValue += getUnit(this.expConfService.experimentConfigurationsMap.tds.unit);
        fValue += bdlErrorRate(this.expConfService.experimentConfigurationsMap.tds.bdl);
        break;
      case 'turbidity':
        fValue += getErrorRate();
        break;
      case 'leadExternalThreshold':
        fValue += getUnit(this.expConfService.experimentConfigurationsMap.lead.unit);
        fValue += bdlErrorRate(this.expConfService.experimentConfigurationsMap.lead.bdl);
        break;
      case 'arsenicExternalThreshold':
        fValue += getUnit(this.expConfService.experimentConfigurationsMap.arsenic.unit);
        fValue += getErrorRate();
        break;
      case 'cadmiumExternalThreshold':
        fValue += getUnit(this.expConfService.experimentConfigurationsMap.cadmium.unit);
        fValue += bdlErrorRate(this.expConfService.experimentConfigurationsMap.cadmium.bdl);
        break;
      case 'copperExternalThreshold':
        fValue += getUnit(this.expConfService.experimentConfigurationsMap.copper.unit);
        fValue += bdlErrorRate(this.expConfService.experimentConfigurationsMap.copper.bdl);
        break;
      case 'manganeseExternalThreshold':
        fValue += getUnit(this.expConfService.experimentConfigurationsMap.manganese.unit);
        fValue += getErrorRate();
        break;
      case 'chromiumExternalThreshold':
        fValue += getUnit(this.expConfService.experimentConfigurationsMap.chromium.unit);
        fValue += getErrorRate();
        break;
      case 'nitratesExternalThreshold':
        fValue += getUnit(this.expConfService.experimentConfigurationsMap.nitrates.unit);
        fValue += getErrorRate();
        break;

      // Water
      case 'total_water_consumption':
        return originalValue === 1 ? fValue : `${fValue}${getUnit('gallons')}`;
      case 'water_monitored':
        // value = formatNumber(originalValue, 'en_US', '1.1-1');
        return originalValue === 1 ? fValue : `${fValue}${getUnit('gallons')}`;
      case 'average_flow_rate':
        return originalValue === 1 ? `${fValue}${getUnit('/min')}` : `${fValue}${getUnit('gallons/min')}`;
      case 'avg_pressure':
        return `${fValue}${getUnit('psi')}`;
      case 'pressure':
        return `${fValue}${getUnit('psi')}`;
      case 'max_pressure':
        return `${fValue}${getUnit('psi')}`;
      case 'flow_rate':
        return originalValue === 1 ? `${fValue}${getUnit('/min')}` : `${fValue}${getUnit('gallons/min')}`;
      case 'max_flow_rate':
        return originalValue === 1 ? `${fValue}${getUnit('/min')}` : `${fValue}${getUnit('gallons/min')}`;

      // Summary
      case 'total_water_usage':
      case 'water_usage':
      case 'total_volume':
        fValue = formatNumber(originalValue, 'en_US', digitsInfoOverride || '1.2-2');
        return originalValue === 1 ? `${fValue}` : `${fValue}${getUnit('gallons')}`;
      case 'total_measurements':
        fValue = formatNumber(originalValue, 'en_US', digitsInfoOverride || '1.0-0');
        return `${fValue}`;
      case 'total_runtime':
        fValue = formatNumber(originalValue, 'en_US', digitsInfoOverride || '1.1-1');
        return originalValue === 1 ? `${fValue}${getUnit('hour')}` : `${fValue}${getUnit('hours')}`;

      case 'total_sensors_runtime':
        fValue = formatNumber(originalValue, 'en_US', digitsInfoOverride || '1.0-0');
        return originalValue === 1 ? `${fValue}${getUnit('hour')}` : `${fValue}${getUnit('hours')}`;

      case 'cartridge_life':
        fValue = formatNumber(originalValue, 'en_US', digitsInfoOverride || '1.0-0');
        if (showUnits) {
          return `${fValue}%`;
        }
        return fValue;
      case 'distance_cm':
        fValue = formatNumber(originalValue, 'en_US', digitsInfoOverride || '1.0-0');
        if (showUnits) {
          return `${fValue} cm`;
        }
        return fValue;
      case 'distance_mm':
        fValue = formatNumber(originalValue, 'en_US', digitsInfoOverride || '1.0-0');
        if (showUnits) {
          return `${fValue} mm`;
        }
        return fValue;
      case 'total_cost_usd':
        fValue = formatNumber(originalValue, 'en_US', digitsInfoOverride || '1.2-2');
        if (showUnits) {
          return `\$ ${fValue}`;
        }
        return fValue;
    }
    return fValue;
  }

  newPipesTransform(value: any, type: string, method?: string, bitmap?: string): string {
    let newValue: any = this.unitFromSettingsConverterPipe.transform(value, type);
    newValue = this.clipAdlBdlPipe.transform(newValue, type, method, bitmap);
    newValue = this.formatDisplayNumberPipe.transform(newValue);
    newValue = this.resultUnitPipe.transform(newValue, type);
    return newValue;
  }

  newPipesTransformConvertedValue(value: any, type: string, method?: string, bitmap?: string): string {
    let newValue = this.clipAdlBdlPipe.transform(value, type, method, bitmap);
    newValue = this.formatDisplayNumberPipe.transform(newValue);
    newValue = this.resultUnitPipe.transform(newValue, type);
    return newValue;
  }

}
