import {Injectable} from '@angular/core';
import {CachedBaseService} from './cached-base.service';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {KetosSocketActionEnum, KetosSocketEventEnum, KetosSocketService} from './ketos-socket.service';
import {ShieldDevicesService} from '../../../../api/src/lib/services/shield-devices.service';
//import {ShieldDevicesService as ShieldDevicesServiceIp} from '../../../../api-internal/src/lib/services/shield-devices.service';

import {AuthService} from './auth.service';
import {map} from 'rxjs/operators';
import {Shield_device} from '../../../../api/src/lib/models/shield-_device';

@Injectable({
  providedIn: 'root'
})
export class ShieldDevicesCachedService extends CachedBaseService {
  subject: ReplaySubject<Shield_device[]>;
  model: Shield_device[] = [];
  dictById: {[key: number]: Shield_device} = {};
  dictBySensorId: {[key: number]: Shield_device} = {};
  portal;
  fetchingMissingDevice = false;

  socketSub: Subscription;
  constructor(private ketosSocketService: KetosSocketService,
              private shieldDevicesService: ShieldDevicesService,
              //private shieldDevicesServiceIp: ShieldDevicesServiceIp,
              public authService: AuthService) {
    super();
    this.setupSubject();
  }

  setupSubject() {
    if (!this.socketSub && this.authService.getUser()?.organization_id) {
      this.ketosSocketService.getSubjectForRoom(this.authService.getUser()?.organization_id, KetosSocketEventEnum.shield_device)
        .subscribe(data => {
          this.updateFromSocket(data);
        });
    }
  }

  public getCached(live = true, forceRefresh = false,): Observable<Shield_device[]> {
    return this.getCachedBase(live, forceRefresh);
  }

  fetchData(): Observable<Shield_device[]> {
   // let url:any = this.portal === 'ip' ? this.shieldDevicesServiceIp.getShieldDevices({}) : this.shieldDevicesService.getShieldDevices({}) 
   // let obj;
      return this.shieldDevicesService.getShieldDevices({}) 
      .pipe(
        map((res:any) => {
            this.model = res.items;
            this.subject.next(res.items);
            this.dictById = {};
            this.dictBySensorId = {};
            res.items.map( dev => {
              this.dictById[dev.id] = dev;
              this.dictBySensorId[dev.sensor.id] = dev;
            })
            return res.items;
          }
        )
      );
      
  }

  // live logic
  updateFromSocket(data: {action: KetosSocketActionEnum, event_type: KetosSocketEventEnum, organization_id: number, room: string, sensor_id: string, message: {shield_device: Shield_device} }) {
    const newModel = data.message.shield_device;
    if (data.action === KetosSocketActionEnum.insert) {
      this.model.push(newModel);
      this.dictById[newModel.id] = newModel;
      this.dictBySensorId[newModel.sensor?.id] = newModel;
      this.subject.next(this.model);
    } else if (data.action === KetosSocketActionEnum.update || data.action === KetosSocketActionEnum.patch) {
      const idx = this.model.findIndex(obj => obj.id === newModel?.id);
      if (idx !== -1) {
        for (const key of Object.keys(this.model[idx])) {
          if (newModel[key] === undefined) {
            newModel[key] = this.model[idx][key];
          }
        }
        this.model[idx] = newModel;
        this.dictById[newModel.id] = newModel;
        this.dictBySensorId[newModel.sensor?.id] = newModel;
        this.subject.next(this.model);
      } else {
        if (!this.fetchingMissingDevice) {
          this.fetchingMissingDevice = true;
          this.fetchData().subscribe(res => {
            this.fetchingMissingDevice = false;
          });
        }
      }

    } else if (data.action === KetosSocketActionEnum.delete) {
      const idx = this.model.findIndex(obj => obj.id === newModel?.id);
      if (idx !== -1) {
        this.model.splice(idx, 1);
        delete this.dictById[newModel.id]
        delete this.dictBySensorId[newModel.sensor.id];
        this.subject.next(this.model);
      } else {
        if (!this.fetchingMissingDevice) {
          this.fetchingMissingDevice = true;
          this.fetchData().subscribe(res => {
            this.fetchingMissingDevice = false;
          });
        }
      }
    }
  }
}
