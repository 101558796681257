import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AnalyticsService } from '../api/src/lib/services/analytics.service';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PageDurationService {
  private enterTime: number;
  private platformName: string;

  constructor(private http: HttpClient, private analyticsService: AnalyticsService, private platform: Platform) {
    this.platformName = platform.is('cordova') ? 'mobile' : 'web';
    window.addEventListener('beforeunload', () => {
      this.sendDurationOnUnload();
    });

    this.enterTime = parseInt(localStorage.getItem('enterTime'), 10) || Date.now();
  }

  markEnterTime(): void {
    this.enterTime = Date.now();
    localStorage.setItem('enterTime', this.enterTime.toString());
  }

  sendDuration(pageUrl: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const relativePath = pageUrl.replace(/^\/#/, '');
      const durationMilliseconds = Date.now() - this.enterTime;
      if (durationMilliseconds < 1000) {
        resolve(); // Immediately resolve the promise for durations less than 1 second
        return;
      }

      const durationSeconds = Math.round(durationMilliseconds / 1000);
      const enterDateTime = new Date(this.enterTime).toISOString();

      this.analyticsService.postAnalytics({
        duration_seconds: durationSeconds,
        enter_datetime: enterDateTime,
        location_url: relativePath,
        source: this.platformName
      }).subscribe(
        res => {
          console.log('analytics res', res);
          resolve(); // Resolve the promise when the request completes successfully
        },
        err => {
          console.error('Error sending analytics data', err);
          reject(err); // Reject the promise if there's an error
        }
      );
    });
  }

  private sendDurationOnUnload(): void {
    let relativePath = window.location.href;
    if (relativePath.toLowerCase().startsWith('http://') || relativePath.toLowerCase().startsWith('https://')) {
      // Create a URL object to parse the full URL
      const url = new URL(relativePath);
      // Combine pathname and hash for the complete path
      relativePath = url.pathname + url.hash;
    }
    this.sendDuration(relativePath);
  }
}
