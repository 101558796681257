import { Injectable } from '@angular/core';
import {TestScheduleService} from '../../../../api/src/lib/services/test-schedule.service';
import {Observable, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Deprecated_shield_test_schedule2} from '../../../../api/src/lib/models';
import {CachedBaseService} from './cached-base.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TestSchedulesCachedService extends CachedBaseService {
  subject: ReplaySubject<Deprecated_shield_test_schedule2[]>;

  constructor(private testsScheduleService: TestScheduleService) {
    super();
  }

  public getCached(live = true, forceRefresh = false): Observable<Deprecated_shield_test_schedule2[]> {
    return this.getCachedBase(live, forceRefresh);
  }

  public fetchData(): Observable<Deprecated_shield_test_schedule2[]> {
    return this.testsScheduleService.getTestSchedules({startDate: moment().utc(false).format(), endDate: moment().add(10, 'years').utc(false).format()})
      .pipe(map(tests => {
        this.subject.next(tests as unknown as Deprecated_shield_test_schedule2[]);
        return tests as unknown as Deprecated_shield_test_schedule2[];
      }));
  }
}
