import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {AppStorage} from '../utils/AppStorage';

@Injectable({
    providedIn: 'root'
  })

export class UserStorageDataService{
    userData = new BehaviorSubject('')
    constructor() {
        const userData = JSON.parse(AppStorage.getItem('user'));
        this.userData.next(userData);
    }
    updateUserData(user: string) {
        this.userData.next(user);
        // also set the identity in localStorage;
    }
}
