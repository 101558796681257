import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {UsersService} from '../../../../api/src/lib/services/users.service';
import {WaterSourcesCachedService} from './water-sources-cached.service';
import { ChartSettingsInterface } from '../pages/ketos-graph/ketos-graph.page';


@Injectable({
  providedIn: 'root'
})
export class GraphModelService {
  public graphModels: ChartSettingsInterface[] = [];
  public sensors = [];
  constructor(private authService: AuthService,
              private waterSourcesService: WaterSourcesCachedService,
              private usersService: UsersService) {
  }

  public loadGraphicalViewModels(): Promise<any> {
    if (this.authService.getUser().roleName === 'SuperAdmin') {
      return Promise.resolve(this.graphModels);
    }
    const models = [];
    return this.usersService.GetUserSpecificSettings().toPromise()
      .then(data => {
        if (Array.isArray(data)) {
          for (const val of data as any[]) {
            if (val.key === 'shieldGraphs' ) {
              let shieldModels = JSON.parse(val.value);
                shieldModels = shieldModels.filter(graphModel => !graphModel.id || this.waterSourcesService.dictById[graphModel.id])

              if (shieldModels) {
                for (const gr of shieldModels) {
                  if (gr.dateRange && gr.dateRange) {
                    gr.dateRange.min = new Date(gr.dateRange.min);
                    gr.dateRange.max = new Date(gr.dateRange.max);
                  }
                  this.verifyShieldGraphModel(gr);
                }
                models.push(...shieldModels);
              }
            }
          }
        }
        this.graphModels = models;
        return models;
      });
  }

  public saveGraphicalViewModels() {
    // console.log('appStateService', this.appService.graphicalViewModels);
    if (this.authService.getUser().roleName === 'SuperAdmin') {
      return;
    }
    this.usersService.UpdateUserSpecificSettings({
      items: [
        {
          key: 'shieldGraphs',
          value: JSON.stringify(this.graphModels)
        }
      ]
    }).subscribe(res => {
    });
    return;
  }

  public verifyShieldGraphModel(model: ChartSettingsInterface): ChartSettingsInterface {
    if (!model.dateRange || !model.dateRange.min || !model.dateRange.max) {
      model.dateRange = {
        max: new Date(),
        min: new Date(),
        defaultValue: true
      }
      model.dateRange.min.setDate(model.dateRange.max.getDate() - 7);
    }
    return model;
  }
}
