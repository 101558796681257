/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subscription, timer} from 'rxjs';
import {AppState, AppStateService} from '../../services/app-state.service';
import {filter, map} from 'rxjs/operators';
import {WaterSourcesCachedService} from '../../services/water-sources-cached.service';
import {SensorGroupCachedService} from '../../services/sensor-group-cached.service';
import {Sensor_group_objects} from '../../../../../api/src/lib/models/sensor-_group-_objects';
import {AuthService} from '../../services/auth.service';
import {ResultCountsCachedService} from '../../services/result-counts-cached.service'
import {SensorsService} from '../../../../../api/src/lib/services';
import {AuthUserResponse} from '../../services/user.service';
import {AppStorage} from '../../utils/AppStorage';
import {Water_source} from '../../../../../api/src/lib/models/water-_source';

export interface SensorsSummaryInterface {
  totalTests?: number;
  scheduledTests?: number;
  runtime?: number;
  group: {
    totalTests: number,
    scheduledTests: number,
    runtime: number;
    name: string;
  };
  all: {
    totalTests: number;
    scheduledTests: number;
    runtime: number;
  };
}

@Injectable({
  providedIn: 'root'
})
export class SummaryShieldService {
  triggerReloadSubject = new BehaviorSubject<boolean>(true);

  constructor(public appStateService: AppStateService,
              private authService: AuthService,
              private waterSourcesCachedService: WaterSourcesCachedService,
              public resultCountsCachedService: ResultCountsCachedService,
              private sensorsService: SensorsService,
              private sensorGroupMappingService: SensorGroupCachedService
  ) {
  }

  reloadData() {
    this.triggerReloadSubject.next(true);
  }

  getShieldSummay(): Observable<SensorsSummaryInterface> {
    if (this.triggerReloadSubject.getValue() !== true) {
      this.triggerReloadSubject.next(true);
    }
    return combineLatest([
      this.appStateService.getAppState(),
      this.waterSourcesCachedService.getCached(true),
      this.authService.getUser()?.role_id < 4 ? this.sensorGroupMappingService.getCached(true) : of([]),
      timer(0, 5 * 60 * 1000),
      this.triggerReloadSubject.pipe(filter(state => state === true)),
      this.resultCountsCachedService.getCached(false,true),
      this.sensorsService.GetAllShieldSensorsAggregates()
    ]).pipe(
      map((values:[AppState, Water_source[], Sensor_group_objects[], any, boolean,any,any]) => {
        this.triggerReloadSubject.next(false);
        return this.calculateSensorSummary(values[0], values[1], values[2],values[5],values[6]);
      })
    );
  }

  calculateTotalTestsCount(counts,sensor_id){
    let count = 0;
    let isCountAvailable=[]
    if(sensor_id){
    counts?.map(c => {
    c.items.forEach((ws) => {
      if(ws.water_source_id === sensor_id){
        isCountAvailable.push(ws)
      }})
    })
    }
    if(!isCountAvailable.length && sensor_id){
      return count
    }
    if(sensor_id && sensor_id!== null ){
      return count = isCountAvailable[0].count
    }else{
    counts.forEach((item) => {
      item.items.forEach((ws) => {
        count += ws.count

      })
    })
    }

    return count ;
  }
  // calculateScheduleTotalTestsCount(counts,sensor_id){
  //   let count = 0;
  //   console.log(counts,"schcounts",sensor_id)

  //   const isScheduleTestCountAvailable = counts.items.filter(c => c.sensor_id === sensor_id)
  //   if(!isScheduleTestCountAvailable.length && sensor_id){
  //     return count
  //   }
  //   if(sensor_id && sensor_id!== null ){
  //     return count = isScheduleTestCountAvailable[0].scheduled_tests_count
  //   }else{
  //     counts.items.forEach((item) => {
  //       count += item.scheduled_tests_count
  //     })
  //   }
  //   return count ;
  // }
  calculateScheduleTotalTestsCount(counts,sensor_id){
    let count = 0;
    console.log(counts,"schcounts",sensor_id)

    //const isScheduleTestCountAvailable = counts.items.filter(c => c.water_source_id === sensor_id)
    let isScheduleTestCountAvailable =[]
    if(sensor_id){
      counts?.items?.map(c => {
      c.items.forEach((ws) => {
        if(ws.water_source_id === sensor_id){
          isScheduleTestCountAvailable.push(ws)
        }})
      })
      }
    if(!isScheduleTestCountAvailable.length && sensor_id){
      return count
    }
    console.log(isScheduleTestCountAvailable,"isSche")
    if(sensor_id && sensor_id!== null ){
      return count = isScheduleTestCountAvailable[0].scheduled_tests_count
    }else{
      counts?.items.forEach((item) => {
        item.items.forEach((ws) => {
          count += ws.scheduled_tests_count
        })
      })
    }
    return count ;
  }
  calculateSensorSummary(appState: AppState, sensors: Water_source[], groupMappings: Sensor_group_objects[],countsRes:any,sensorAggregates:any): SensorsSummaryInterface {
   console.log(appState,"appState",countsRes)
   let totalTests = 0;
   countsRes.map(res => {
     totalTests += res.count;
   });
    const userData: AuthUserResponse = JSON.parse(AppStorage.getItem('user'));
    let groupSensorIds;
    const sensorsSummary = {
      totalTests: this.calculateTotalTestsCount(countsRes,appState.location.id),
      scheduledTests: this.calculateScheduleTotalTestsCount(sensorAggregates,appState.location.id),
      runtime: this.calculateHoursToNow([appState.location.sensor.installation_date]),
      group: {
        name: appState.group?.group_name || userData?.group_name || '',
        totalTests: 0,
        scheduledTests: 0,
        runtime: 0
      },
      all: {
        totalTests: this.calculateTotalTestsCount(countsRes,null),
        scheduledTests: this.calculateScheduleTotalTestsCount(sensorAggregates,null),
        runtime: this.calculateHoursToNow(sensors.map(loc => loc.sensor.installation_date))
      }
    };
    const sensorsMap = {};
    sensors.map(sensor => {
      sensorsMap[sensor.sensor.id] = sensor.sensor;
    });
    // sensorsSummary.group.runtime = this.calculateHoursToNow(
    //   groupMappings.filter(mapping => mapping.group_id === appState.group.id && sensorsMap[mapping.sensor_id])
    //     .map(mapping => sensorsMap[mapping.sensor_id].installation_date)
    // );

    // if (appState.group.is_default_group === 'True') {
    //   sensorsSummary.group.totalTests = totalTests;
    //   groupSensorIds = groupMappings.filter(mapping => mapping.group_id === appState.group.id).map(mapping => mapping.sensor_id);

    // } else {
    //   groupSensorIds = groupMappings.filter(mapping => mapping.group_id === appState.group.id).map(mapping => mapping.sensor_id);
    //   let groupTests = 0;
    //   countsRes.filter(count => groupSensorIds.indexOf(count.sensor_id) !== -1)
    //     .map(count => {
    //       groupTests += count.count;
    //     });
    //     sensorsSummary.group.totalTests = groupTests;
    // }
    if(this.authService.getUser()?.role_id > 3 ){
      sensorsSummary.group.totalTests=this.calculateTotalTestsCount(countsRes,null);
      sensorsSummary.group.scheduledTests=this.calculateScheduleTotalTestsCount(sensorAggregates,null);
      sensorsSummary.group.runtime = this.calculateHoursToNow(sensors.map(loc => loc.sensor.installation_date));

    }
      return sensorsSummary;
  }


  calculateHoursToNow(timeStrings: string[]): number {
    let hours = 0;
    const now = new Date().getTime();
    for (const timeString of timeStrings) {
      const past = new Date(timeString).getTime();
      hours += (now - past) / (60 * 60 * 1000);
    }
    return hours;
  }
}
