/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Fluid_usage } from '../models/fluid-_usage';
import { Fluid_state_and_forecast } from '../models/fluid-_state-_and-_forecast';

/**
 * Shield device related operations
 */
@Injectable({
  providedIn: 'root',
})
class ShieldFluidLevelsService extends __BaseService {
  static readonly postFluidLevelsAlertsPath = '/shield/fluid_levels/alerts/{sensor_id}';
  static readonly putFluidUsagePath = '/shield/fluid_levels/fluid_usage';
  static readonly getFluidLevelsPath = '/shield/fluid_levels/{sensor_id}';
  static readonly postFluidsPurgePath = '/shield/fluid_levels/{sensor_id}/locations/{location_number}/reset';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param sensor_id undefined
   */
  postFluidLevelsAlertsResponse(sensorId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shield/fluid_levels/alerts/${encodeURIComponent(String(sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_id undefined
   */
  postFluidLevelsAlerts(sensorId: number): __Observable<null> {
    return this.postFluidLevelsAlertsResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  putFluidUsageResponse(payload: Fluid_usage): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/shield/fluid_levels/fluid_usage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  putFluidUsage(payload: Fluid_usage): __Observable<null> {
    return this.putFluidUsageResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldFluidLevelsService.GetFluidLevelsParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getFluidLevelsResponse(params: ShieldFluidLevelsService.GetFluidLevelsParams): __Observable<__StrictHttpResponse<Fluid_state_and_forecast>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield/fluid_levels/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Fluid_state_and_forecast>;
      })
    );
  }
  /**
   * @param params The `ShieldFluidLevelsService.GetFluidLevelsParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getFluidLevels(params: ShieldFluidLevelsService.GetFluidLevelsParams): __Observable<Fluid_state_and_forecast> {
    return this.getFluidLevelsResponse(params).pipe(
      __map(_r => _r.body as Fluid_state_and_forecast)
    );
  }

  /**
   * @param params The `ShieldFluidLevelsService.PostFluidsPurgeParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `location_number`:
   */
  postFluidsPurgeResponse(params: ShieldFluidLevelsService.PostFluidsPurgeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shield/fluid_levels/${encodeURIComponent(String(params.sensorId))}/locations/${encodeURIComponent(String(params.locationNumber))}/reset`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldFluidLevelsService.PostFluidsPurgeParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `location_number`:
   */
  postFluidsPurge(params: ShieldFluidLevelsService.PostFluidsPurgeParams): __Observable<null> {
    return this.postFluidsPurgeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ShieldFluidLevelsService {

  /**
   * Parameters for getFluidLevels
   */
  export interface GetFluidLevelsParams {
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postFluidsPurge
   */
  export interface PostFluidsPurgeParams {
    sensorId: number;
    locationNumber: number;
  }
}

export { ShieldFluidLevelsService }
