import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailFormat'
})
export class EmailFormatPipe implements PipeTransform {

  transform(value: string): any {
    if (!value || value.endsWith('@ketos.co') || value === 'N/A') {
      return 'KETOS Admin';
    }
    return value;
  }
}
