/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStorage} from '../../utils/AppStorage';
import { forkJoin } from 'rxjs';

export interface ChordValueInterface {
  day: string;
  location_name: string;
  timestamp: string;
  volume: string;
}

export interface ChordDataInterface {
  settings: boolean;
  icon: string;
  info: string;
  values?: string;
  key?: string;
  listHeaders?: ChordValueInterface;
  valuesList?: ChordValueInterface[];
  subtitle?: string;
  status?: string;
  titleStatus?: string;
  maxim?: string;
  minim?: string;
  threshold?: number;
  thresholdInternal?: number;
  alerts_higher_threshold?:number;
  alerts_lower_threshold?:number;
  sensorId:string;
  selectedSensorType:string;
  epaLimit?: {
    US: string;
    Mexico: string;
    India: string;
  };
  isInput?: boolean;
  inputValue?: number;
}

@Component({
  selector: 'lib-legacy-chord',
  templateUrl: './chord-legacy.component.html',
  styleUrls: ['./chord-legacy.component.scss']
  
  
  
})
export class ChordLegacyComponent implements OnInit {
  private _editSettings: boolean;
 // private _editIceSettings: boolean;

  @Output()
  chordWrapClick: EventEmitter<any> = new EventEmitter();

  private _data: ChordDataInterface;
  @Input() set data(value: ChordDataInterface) {
    if (this.editSettings === true) {
      return;
    }
    // if (this.editIceSettings === true) {
    //   return;
    // }
    if (!value) {
      return;
    }

    if (typeof value.info === 'string') {
      // @TODO: reimplement
      // this.chordName = this.$sce.trustAsHtml(this.data.info);
      this.chordName = value.info;
      this.selectedSensorType = value.selectedSensorType?value.selectedSensorType:''
      //this.higherThreshold = value.alerts_higher_threshold?value.alerts_higher_threshold:0;
      //this.lowerThreshold = value.alerts_lower_threshold?value.alerts_lower_threshold:0;

    }
    // this.tempValue = JSON.parse(JSON.stringify(value.values)); // This will fail if 'values' is empty
    this.tempValue = Object.assign({}, value.values);
    this._data = Object.assign({}, value);
  }

  get data(): ChordDataInterface {
    return this._data;
  }

  @Input() show3dShadow: boolean;

  @Output() inputChanged: EventEmitter<number> = new EventEmitter<number>();

  @Input('editSettings')
  set editSettings(visible: boolean) {
    if (visible === true) {
      this.originalExternalThreshold = this.data.threshold;
      this.originalInternalThreshold = this.data.thresholdInternal;
    }
    this._editSettings = visible;
    this.editSettingsChanged.emit(visible);
  }


  // @Input('editIceSettings')
  // set editIceSettings(visible: boolean) {
  //   if (visible === true) {
  //     this.originalExternalThreshold = this.data.threshold;
  //     this.originalInternalThreshold = this.data.thresholdInternal;
  //   }
  //   this._editIceSettings = visible;
  //   this.editIceSettingsChanged.emit(visible);
  // }
  // @Output('editIceSettingsChanged') editIceSettingsChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output('editSettingsChanged') editSettingsChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  get editSettings(): boolean {
    return this._editSettings;
  }
  // get editIceSettings(): boolean {
  //   return this._editIceSettings;
  // }

  public onSelectChord;
  public tempValue: any;
  public chordName: string;
  public selectedSensorType: string;
  public sensorId;
  public userData: any;
  // public threshold: number;
  public originalInternalThreshold;
  public originalExternalThreshold;

  public higherThreshold;
  public lowerThreshold;
  showError:boolean=false;
  public thresHoldDataIceberg ={
    alerts_higher_threshold:0,
    alerts_lower_threshold:0,
    sensorId:''
  }

  constructor() {
    this.userData = JSON.parse(AppStorage.getItem('user'));
  }

  ngOnInit() {
  }

  selectChord(chordValue, value) {
    if (this.onSelectChord) {
      this.onSelectChord({chordValue: chordValue, value: value});
    }
  }

  canEdit(): boolean {
    if (this.data.titleStatus === '(Upcoming)') {
      return false;
    }

    const role = this.userData.roleName.toLocaleLowerCase();
    return ['superadmin', 'orgadmin', 'groupadmin'].indexOf(role) !== -1;
  }

  actionLocationCheck() {
    console.log('in');
  }
  checkHigherValue(val){
    console.log(this.data.thresholdInternal, this.data.threshold)
    if(this.data.thresholdInternal >= this.data.threshold){
      this.showError = true
     // this.data.thresholdInternal = this.data.threshold -1;
    }else if(this.data.threshold !=null && this.data.thresholdInternal!=null){
      this.showError = false;
    }
    else{
      this.showError=false;
    }
    return true;

  }
 


  public inputChange() {
    this.inputChanged.emit(this.data.inputValue);
  }
  public validIceThresholds(){
    return this.data.alerts_higher_threshold !== null && this.data.alerts_lower_threshold !== null
  }
  public validThresholds() {
    return this.data.threshold !== null && this.data.thresholdInternal !== null;
  }
}
