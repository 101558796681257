import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';

export interface OptionInterface {
  title: string;
  value: any;
  id?: any;
  status?: string;
  forceSelectId?: any;  // option with this id will also be selected
  hasBeenForced?: boolean; // internal variable used to disable interaction on a forced selection
  disabled?: boolean;
  icon?: string;
}

@Component({
  selector: 'lib-multi-select-popover',
  templateUrl: './multi-select-popover.component.html',
  styleUrls: ['./multi-select-popover.component.scss'],
})
export class MultiSelectPopoverComponent implements OnInit {
  @Input() titleKey = 'title';
  @Input() uniqueKey = 'id';
  @Input() values: OptionInterface[] = [];
  @Input() selected: OptionInterface[] = [];
  @Input() showConfirm = false;
  @Input() maxSelected = Number.MAX_SAFE_INTEGER; // for efficiency use one number smaller
  @Input() showSelectAll = false;
  @Input() updateSelected: (selected: OptionInterface[]) => void;

  selectedValues: boolean[];
  selectedCount = 0;
  allSelected: boolean;
  constructor(private popoverController: PopoverController) {
  }

  ngOnInit() {
    this.selectedValues = [];
    if (this.selected) {
      for (const [idx, val] of this.values.entries()) {
        if (val[this.uniqueKey]) {
          this.selectedValues[idx] = this.selected.find(el => el[this.uniqueKey] === val[this.uniqueKey]) !== undefined;
        } else if (val.value?.id) {
          this.selectedValues[idx] = this.selected.find(el => el.value?.id === val.value?.id) !== undefined;
        } else {
          this.selectedValues[idx] = this.selected.find(el => el.value === val.value) !== undefined;
        }
      }
      let i = 0;
      while (i < this.selected.length) {
        this.checkAndForceValue(i);
        i++;
      }
      this.calculateSelectedCount();
    }
  }

  selectAllClicked(checkboxChecked: boolean) {

    const oldSelected = this.selected;
    this.selected = [];
    if (checkboxChecked) {
      for (const [idx, val] of this.values.entries()) {
        this.selectedValues[idx] = false;
      }
    } else {
      for (const [idx, val] of this.values.entries()) {
        this.selected.push(this.values[idx]);
        this.selectedValues[idx] = true;
      }
    }
    for (const [idx, val] of this.values.entries()) {
      this.checkAndForceValue(idx);
    }
    if (!this.showConfirm) {
      this.updateSelected(this.selected);
    }
    this.selectedCount = this.selectedValues.filter( sel => sel === true).length;
  }

  changeAt(i: number) {
    this.selected = [];
    for (const [idx, val] of this.values.entries()) {
      if (this.selectedValues[idx]) {
        this.selected.push(this.values[idx]);
      }
    }
    this.checkAndForceValue(i);
    if (!this.showConfirm) {
      this.updateSelected(this.selected);
    }
    this.calculateSelectedCount();
  }

  checkAndForceValue(i: number) {
    if (this.values[i].forceSelectId) {
      const forcedId = this.values[i].forceSelectId;
      for (const [idx, val] of this.values.entries()) {
        if (val.id === forcedId) {
          if (this.selectedValues[i] === true) {
            if (this.selectedValues[idx] === false) {
              this.selected.push(val);
              this.selectedValues[idx] = true;
            }
            val.hasBeenForced = true;
          } else {
            delete val.hasBeenForced;
          }
          break;
        }
      }
    }
  }

  calculateSelectedCount() {
    this.selectedCount = this.selectedValues.filter( sel => sel === true).length;
    this.allSelected = this.selected.length > 0 && this.selected.length === this.values.length;
  }

  confirm() {
    this.updateSelected(this.selected);
    this.popoverController.dismiss(this.selected);
  }

  cancel() {
    this.popoverController.dismiss();
  }
}
