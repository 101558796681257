/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Shield_scheduled_tests_paginated } from '../models/shield-_scheduled-_tests-_paginated';

/**
 * Shield scheduled test related operations
 */
@Injectable({
  providedIn: 'root',
})
class ShieldScheduledTestsService extends __BaseService {
  static readonly getShieldScheduledTestPath = '/shield/scheduled_tests';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ShieldScheduledTestsService.GetShieldScheduledTestParams` containing the following parameters:
   *
   * - `ids`: List of shield scheduled test ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `organization_ids`: List of organization ids
   *
   * - `start_scheduled_timestamp`: Start scheduled_timestamp (ISO 8601)
   *
   * - `end_scheduled_timestamp`: End scheduled_timestamp (ISO 8601)
   *
   * - `start_end_time`: Start end_time (ISO 8601)
   *
   * - `end_end_time`: End end_time (ISO 8601)
   *
   * - `statuses`: List of statuses (0=Scheduled, 1=Trying to start, 2=Running, 3=Error, 4=Cancelled)
   *
   * - `test_type`: Test type (m=Manual, s=Scheduled)
   *
   * - `test_numbers`: List of test numbers
   *
   * - `sorts`: Examples: "start_time desc", "end_recur asc"
   *
   * - `include_shield_test_schedule`: Embed associated shield_test_schedule record if any
   *
   * - `page`: Page number
   *
   * - `per_page`: Per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldScheduledTestResponse(params: ShieldScheduledTestsService.GetShieldScheduledTestParams): __Observable<__StrictHttpResponse<Shield_scheduled_tests_paginated>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.waterSourceIds || []).forEach(val => {if (val != null) __params = __params.append('water_source_ids', val.toString())});
    (params.operatingStatuses || []).forEach(val => {if (val != null) __params = __params.append('operating_statuses', val.toString())});
    (params.organizationIds || []).forEach(val => {if (val != null) __params = __params.append('organization_ids', val.toString())});
    if (params.startScheduledTimestamp != null) __params = __params.set('start_scheduled_timestamp', params.startScheduledTimestamp.toString());
    if (params.endScheduledTimestamp != null) __params = __params.set('end_scheduled_timestamp', params.endScheduledTimestamp.toString());
    if (params.startEndTime != null) __params = __params.set('start_end_time', params.startEndTime.toString());
    if (params.endEndTime != null) __params = __params.set('end_end_time', params.endEndTime.toString());
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.testType != null) __params = __params.set('test_type', params.testType.toString());
    (params.testNumbers || []).forEach(val => {if (val != null) __params = __params.append('test_numbers', val.toString())});
    (params.sorts || []).forEach(val => {if (val != null) __params = __params.append('sorts', val.toString())});
    if (params.includeShieldTestSchedule != null) __params = __params.set('include_shield_test_schedule', params.includeShieldTestSchedule.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.perPage != null) __params = __params.set('per_page', params.perPage.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield/scheduled_tests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_scheduled_tests_paginated>;
      })
    );
  }
  /**
   * @param params The `ShieldScheduledTestsService.GetShieldScheduledTestParams` containing the following parameters:
   *
   * - `ids`: List of shield scheduled test ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `organization_ids`: List of organization ids
   *
   * - `start_scheduled_timestamp`: Start scheduled_timestamp (ISO 8601)
   *
   * - `end_scheduled_timestamp`: End scheduled_timestamp (ISO 8601)
   *
   * - `start_end_time`: Start end_time (ISO 8601)
   *
   * - `end_end_time`: End end_time (ISO 8601)
   *
   * - `statuses`: List of statuses (0=Scheduled, 1=Trying to start, 2=Running, 3=Error, 4=Cancelled)
   *
   * - `test_type`: Test type (m=Manual, s=Scheduled)
   *
   * - `test_numbers`: List of test numbers
   *
   * - `sorts`: Examples: "start_time desc", "end_recur asc"
   *
   * - `include_shield_test_schedule`: Embed associated shield_test_schedule record if any
   *
   * - `page`: Page number
   *
   * - `per_page`: Per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldScheduledTest(params: ShieldScheduledTestsService.GetShieldScheduledTestParams): __Observable<Shield_scheduled_tests_paginated> {
    return this.getShieldScheduledTestResponse(params).pipe(
      __map(_r => _r.body as Shield_scheduled_tests_paginated)
    );
  }
}

module ShieldScheduledTestsService {

  /**
   * Parameters for getShieldScheduledTest
   */
  export interface GetShieldScheduledTestParams {

    /**
     * List of shield scheduled test ids
     */
    ids?: Array<string>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of water source ids
     */
    waterSourceIds?: Array<number>;

    /**
     * List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
     */
    operatingStatuses?: Array<number>;

    /**
     * List of organization ids
     */
    organizationIds?: Array<number>;

    /**
     * Start scheduled_timestamp (ISO 8601)
     */
    startScheduledTimestamp?: string;

    /**
     * End scheduled_timestamp (ISO 8601)
     */
    endScheduledTimestamp?: string;

    /**
     * Start end_time (ISO 8601)
     */
    startEndTime?: string;

    /**
     * End end_time (ISO 8601)
     */
    endEndTime?: string;

    /**
     * List of statuses (0=Scheduled, 1=Trying to start, 2=Running, 3=Error, 4=Cancelled)
     */
    statuses?: Array<number>;

    /**
     * Test type (m=Manual, s=Scheduled)
     */
    testType?: 'm' | 's';

    /**
     * List of test numbers
     */
    testNumbers?: Array<number>;

    /**
     * Examples: "start_time desc", "end_recur asc"
     */
    sorts?: Array<string>;

    /**
     * Embed associated shield_test_schedule record if any
     */
    includeShieldTestSchedule?: boolean;

    /**
     * Page number
     */
    page?: number;

    /**
     * Per page
     */
    perPage?: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ShieldScheduledTestsService }
