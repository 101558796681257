import {TimeIntervalInterface} from '../services/app-state.service';
import * as moment from 'moment';
import { Shield_test } from '../../../../api/src/lib/models';



// export function CalculateHoursToNow(timeStrings: string[]): number {
//   let hours = 0;
//   const now = new Date().getTime();
//   for (const timeString of timeStrings) {
//     const past = new Date(timeString).getTime();
//     hours += (now - past) / (60 * 60 * 1000);
//   }
//   return hours;
// }

export function CalculateHoursToNow(timeStrings: string[]): number {
  if (timeStrings.length === 0) {
    return 0;
  }

  const now = new Date().getTime();
  const oldestTimeString = timeStrings.reduce((oldest, current) => {
    return new Date(current).getTime() < new Date(oldest).getTime() ? current : oldest;
  }, timeStrings[0]);

  const past = new Date(oldestTimeString).getTime();
  const hours = (now - past) / (60 * 60 * 1000);

  return hours;
}

export function CurrentIntervalIfLive(timeInterval: TimeIntervalInterface): TimeIntervalInterface {
  if (timeInterval.defaultValue !== true) {
    return timeInterval;
  }
  const startDate = timeInterval.min;
  const endDate = timeInterval.max;
    const diff = endDate.getTime() - startDate.getTime();
    return {
      min: new Date(new Date().getTime() - diff),
      max: new Date(),
      defaultValue: true
    };
}

export function CurrentRoundedIntervalIfLive(timeInterval: TimeIntervalInterface, live = false, roundingMs = 15 * 60 * 1000): TimeIntervalInterface {
  if (live !== true) {
    return timeInterval;
  }
  const startDate = timeInterval.min;
  const endDate = timeInterval.max;
  const diff = endDate.getTime() - startDate.getTime();
  return {
    min: RoundToInterval(new Date(new Date().getTime() - diff), roundingMs),
    max: new Date(),
    defaultValue: timeInterval.defaultValue
  };
}


// Example: interval = 15 * 60 * 1000; // 15 minutes in milliseconds
export function RoundToInterval(date: Date, interval: number): Date {
  return new Date(Math.ceil(date.getTime() / interval) * interval);
}

export function TimeBasedProgressForTest(test: Shield_test): number {
  const now = moment();
  const start = moment(test.start_time + 'Z');
  const end = moment((test.expected_end_time || test.end_time) + 'Z');
  const totalMinutes = end.diff(start);
  const completedMinutes = now.diff(start);
  const prog = Math.round((completedMinutes / totalMinutes) * 100);

  return Math.max(0, Math.min(100, prog));

  // const start:any = new Date(test.start_time ); // Jan 1, 2015
  // const end:any = new Date(test.expected_end_time || test.end_time); // August 24, 2015
  // const today:any = new Date(); // April 23, 2015
  // const p:number = Math.round(((today - start) / (end - start)) * 100);
  // console.log(p)
  // return p
}

export function LocalWeekdaysToUtc(startTime: Date, localDaysOfWeek: string): string {
  const originalDay = moment(startTime);
  const newDay = moment.utc(startTime);
  const offset = newDay.day() - originalDay.day();
  if (offset !== 0 && localDaysOfWeek !== '0123456') {
    return localDaysOfWeek.split('')
      .map(st => parseInt(st, 10))
      .map(num => ((num + offset + 7) % 7))
      .sort()
      .join('');
  } else {
    return localDaysOfWeek;
  }
}

export function UtcWeekdaysToLocal(startTime: string, utcDaysOfWeek: string): string {
  const utcStartTime = moment(startTime + 'Z');
  const localStartTime = moment.utc(startTime + 'Z');
  const offset = utcStartTime.day() - localStartTime.day();
  if (offset !== 0 && utcDaysOfWeek?.length < 7) {
    return utcDaysOfWeek.split('')
      .map(st => parseInt(st, 10))
      .map(num => ((num + offset + 7) % 7))
      .sort()
      .join('');
  } else {
    return utcDaysOfWeek;
  }
}
