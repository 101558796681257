/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { External_result } from '../models/external-_result';
import { External_results } from '../models/external-_results';
import { External_test_update } from '../models/external-_test-_update';
import { External_result_update } from '../models/external-_result-_update';

/**
 * External test/result related operations
 */
@Injectable({
  providedIn: 'root',
})
class ExternalResultsService extends __BaseService {
  static readonly postExternalLabReportResultCreatePath = '/external_results';
  static readonly postParameterValuesPath = '/external_results/lap_report_data/parameter_values/';
  static readonly getExternalLabReportExternalResultsLapReportDataIntSensorIdStringTestIdIntPageIntPerPagePath = '/external_results/lap_report_data/{sensor_id}/{test_id}/{page}/{per_page}';
  static readonly deleteExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdPath = '/external_results/sensor/{sensor_id}/test/{test_id}';
  static readonly postExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdPath = '/external_results/sensor/{sensor_id}/test/{test_id}';
  static readonly deleteExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentPath = '/external_results/sensor/{sensor_id}/test/{test_id}/exp/{experiment}';
  static readonly postExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentPath = '/external_results/sensor/{sensor_id}/test/{test_id}/exp/{experiment}';
  static readonly GetResultsForTheGivenColumnPath = '/external_results/{sensor_id}/search/{column}/{value}/{page}/{per_page}';
  static readonly getExternalLabReportExternalResultsIntSensorIdIntPageIntPerPagePath = '/external_results/{sensor_id}/{page}/{per_page}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param payload undefined
   */
  postExternalLabReportResultCreateResponse(payload: External_result): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/external_results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postExternalLabReportResultCreate(payload: External_result): __Observable<null> {
    return this.postExternalLabReportResultCreateResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  postParameterValuesResponse(payload: External_result): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/external_results/lap_report_data/parameter_values/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postParameterValues(payload: External_result): __Observable<null> {
    return this.postParameterValuesResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ExternalResultsService.GetExternalLabReportExternalResultsLapReportDataIntSensorIdStringTestIdIntPageIntPerPageParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `test_id`: Lab Report ID
   *
   * - `page`:
   *
   * - `per_page`:
   *
   * @return Success
   */
  getExternalLabReportExternalResultsLapReportDataIntSensorIdStringTestIdIntPageIntPerPageResponse(params: ExternalResultsService.GetExternalLabReportExternalResultsLapReportDataIntSensorIdStringTestIdIntPageIntPerPageParams): __Observable<__StrictHttpResponse<External_results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/external_results/lap_report_data/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.testId))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<External_results>;
      })
    );
  }
  /**
   * @param params The `ExternalResultsService.GetExternalLabReportExternalResultsLapReportDataIntSensorIdStringTestIdIntPageIntPerPageParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `test_id`: Lab Report ID
   *
   * - `page`:
   *
   * - `per_page`:
   *
   * @return Success
   */
  getExternalLabReportExternalResultsLapReportDataIntSensorIdStringTestIdIntPageIntPerPage(params: ExternalResultsService.GetExternalLabReportExternalResultsLapReportDataIntSensorIdStringTestIdIntPageIntPerPageParams): __Observable<External_results> {
    return this.getExternalLabReportExternalResultsLapReportDataIntSensorIdStringTestIdIntPageIntPerPageResponse(params).pipe(
      __map(_r => _r.body as External_results)
    );
  }

  /**
   * @param params The `ExternalResultsService.DeleteExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `test_id`: External result id
   */
  deleteExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdResponse(params: ExternalResultsService.DeleteExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/external_results/sensor/${encodeURIComponent(String(params.sensorId))}/test/${encodeURIComponent(String(params.testId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ExternalResultsService.DeleteExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `test_id`: External result id
   */
  deleteExternalLabTestExternalResultsSensorIntSensorIdTestStringTestId(params: ExternalResultsService.DeleteExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdParams): __Observable<null> {
    return this.deleteExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ExternalResultsService.PostExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `test_id`: External result id
   */
  postExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdResponse(params: ExternalResultsService.PostExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/external_results/sensor/${encodeURIComponent(String(params.sensorId))}/test/${encodeURIComponent(String(params.testId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ExternalResultsService.PostExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `test_id`: External result id
   */
  postExternalLabTestExternalResultsSensorIntSensorIdTestStringTestId(params: ExternalResultsService.PostExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdParams): __Observable<null> {
    return this.postExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ExternalResultsService.DeleteExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `test_id`: External result id
   *
   * - `experiment`: Experiment name
   */
  deleteExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentResponse(params: ExternalResultsService.DeleteExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/external_results/sensor/${encodeURIComponent(String(params.sensorId))}/test/${encodeURIComponent(String(params.testId))}/exp/${encodeURIComponent(String(params.experiment))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ExternalResultsService.DeleteExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `test_id`: External result id
   *
   * - `experiment`: Experiment name
   */
  deleteExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperiment(params: ExternalResultsService.DeleteExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentParams): __Observable<null> {
    return this.deleteExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ExternalResultsService.PostExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `test_id`: External result id
   *
   * - `experiment`: Experiment name
   */
  postExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentResponse(params: ExternalResultsService.PostExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;



    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/external_results/sensor/${encodeURIComponent(String(params.sensorId))}/test/${encodeURIComponent(String(params.testId))}/exp/${encodeURIComponent(String(params.experiment))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ExternalResultsService.PostExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `test_id`: External result id
   *
   * - `experiment`: Experiment name
   */
  postExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperiment(params: ExternalResultsService.PostExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentParams): __Observable<null> {
    return this.postExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ExternalResultsService.GetResultsForTheGivenColumnParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `column`: Search Column: test_id, date_sampled, date_received, date_completed, experiment, value, range, parameter_type
   *
   * - `value`: Search Value for the Column
   *
   * - `page`: Page number
   *
   * - `per_page`: Per page
   *
   * @return Success
   */
  GetResultsForTheGivenColumnResponse(params: ExternalResultsService.GetResultsForTheGivenColumnParams): __Observable<__StrictHttpResponse<External_results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/external_results/${encodeURIComponent(String(params.sensorId))}/search/${encodeURIComponent(String(params.column))}/${encodeURIComponent(String(params.value))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<External_results>;
      })
    );
  }
  /**
   * @param params The `ExternalResultsService.GetResultsForTheGivenColumnParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `column`: Search Column: test_id, date_sampled, date_received, date_completed, experiment, value, range, parameter_type
   *
   * - `value`: Search Value for the Column
   *
   * - `page`: Page number
   *
   * - `per_page`: Per page
   *
   * @return Success
   */
  GetResultsForTheGivenColumn(params: ExternalResultsService.GetResultsForTheGivenColumnParams): __Observable<External_results> {
    return this.GetResultsForTheGivenColumnResponse(params).pipe(
      __map(_r => _r.body as External_results)
    );
  }

  /**
   * @param params The `ExternalResultsService.GetExternalLabReportExternalResultsIntSensorIdIntPageIntPerPageParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `page`:
   *
   * - `per_page`:
   *
   * @return Success
   */
  getExternalLabReportExternalResultsIntSensorIdIntPageIntPerPageResponse(params: ExternalResultsService.GetExternalLabReportExternalResultsIntSensorIdIntPageIntPerPageParams): __Observable<__StrictHttpResponse<External_results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/external_results/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<External_results>;
      })
    );
  }
  /**
   * @param params The `ExternalResultsService.GetExternalLabReportExternalResultsIntSensorIdIntPageIntPerPageParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `page`:
   *
   * - `per_page`:
   *
   * @return Success
   */
  getExternalLabReportExternalResultsIntSensorIdIntPageIntPerPage(params: ExternalResultsService.GetExternalLabReportExternalResultsIntSensorIdIntPageIntPerPageParams): __Observable<External_results> {
    return this.getExternalLabReportExternalResultsIntSensorIdIntPageIntPerPageResponse(params).pipe(
      __map(_r => _r.body as External_results)
    );
  }
}

module ExternalResultsService {

  /**
   * Parameters for getExternalLabReportExternalResultsLapReportDataIntSensorIdStringTestIdIntPageIntPerPage
   */
  export interface GetExternalLabReportExternalResultsLapReportDataIntSensorIdStringTestIdIntPageIntPerPageParams {

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * Lab Report ID
     */
    testId: string;
    page: number;
    perPage: number;
  }

  /**
   * Parameters for deleteExternalLabTestExternalResultsSensorIntSensorIdTestStringTestId
   */
  export interface DeleteExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdParams {

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * External result id
     */
    testId: string;
  }

  /**
   * Parameters for postExternalLabTestExternalResultsSensorIntSensorIdTestStringTestId
   */
  export interface PostExternalLabTestExternalResultsSensorIntSensorIdTestStringTestIdParams {
    payload: External_test_update;

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * External result id
     */
    testId: string;
  }

  /**
   * Parameters for deleteExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperiment
   */
  export interface DeleteExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentParams {

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * External result id
     */
    testId: string;

    /**
     * Experiment name
     */
    experiment: string;
  }

  /**
   * Parameters for postExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperiment
   */
  export interface PostExternalLabResultExternalResultsSensorIntSensorIdTestStringTestIdExpStringExperimentParams {
    payload: External_result_update;

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * External result id
     */
    testId: string;

    /**
     * Experiment name
     */
    experiment: string;
  }

  /**
   * Parameters for GetResultsForTheGivenColumn
   */
  export interface GetResultsForTheGivenColumnParams {

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * Search Column: test_id, date_sampled, date_received, date_completed, experiment, value, range, parameter_type
     */
    column: string;

    /**
     * Search Value for the Column
     */
    value: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Per page
     */
    perPage: number;
  }

  /**
   * Parameters for getExternalLabReportExternalResultsIntSensorIdIntPageIntPerPage
   */
  export interface GetExternalLabReportExternalResultsIntSensorIdIntPageIntPerPageParams {

    /**
     * Sensor ID
     */
    sensorId: number;
    page: number;
    perPage: number;
  }
}

export { ExternalResultsService }
