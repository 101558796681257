/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Organizations } from '../models/organizations';
import { Organization } from '../models/organization';
import { Organization_input } from '../models/organization-_input';
import { Organization_verticals } from '../models/organization-_verticals';
import { Organization_address } from '../models/organization-_address';
import { Organization_address_input } from '../models/organization-_address-_input';
import { Organization_setting_inputs } from '../models/organization-_setting-_inputs';
import { Organization_settings } from '../models/organization-_settings';

/**
 * Organization related operations
 */
@Injectable({
  providedIn: 'root',
})
class OrganizationsService extends __BaseService {
  static readonly getOrganizationsPath = '/organizations';
  static readonly postOrganizationsPath = '/organizations';
  static readonly getOrganizationVerticalsPath = '/organizations/verticals';
  static readonly putOrganizationPath = '/organizations/{organization_id}';
  static readonly postOrganizationAddressesPath = '/organizations/{organization_id}/addresses';
  static readonly putOrganizationAddressPath = '/organizations/{organization_id}/addresses/{organization_address_id}';
  static readonly putOrganizationSettingsPath = '/organizations/{organization_id}/settings';
  static readonly getOrganizationSettingsPath = '/organizations/{organization_id}/settings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OrganizationsService.GetOrganizationsParams` containing the following parameters:
   *
   * - `ids`: List of organization ids
   *
   * - `active`: Active/Inactive for organization
   *
   * - `premium`: Premium Enabled/Disabled for organization
   *
   * - `organization_types`: 1=Internal, 2=External
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOrganizationsResponse(params: OrganizationsService.GetOrganizationsParams): __Observable<__StrictHttpResponse<Organizations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.active != null) __params = __params.set('active', params.active.toString());
    if (params.premium != null) __params = __params.set('premium', params.premium.toString());
    (params.organizationTypes || []).forEach(val => {if (val != null) __params = __params.append('organization_types', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Organizations>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.GetOrganizationsParams` containing the following parameters:
   *
   * - `ids`: List of organization ids
   *
   * - `active`: Active/Inactive for organization
   *
   * - `premium`: Premium Enabled/Disabled for organization
   *
   * - `organization_types`: 1=Internal, 2=External
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOrganizations(params: OrganizationsService.GetOrganizationsParams): __Observable<Organizations> {
    return this.getOrganizationsResponse(params).pipe(
      __map(_r => _r.body as Organizations)
    );
  }

  /**
   * @param params The `OrganizationsService.PostOrganizationsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postOrganizationsResponse(params: OrganizationsService.PostOrganizationsParams): __Observable<__StrictHttpResponse<Organization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Organization>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.PostOrganizationsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postOrganizations(params: OrganizationsService.PostOrganizationsParams): __Observable<Organization> {
    return this.postOrganizationsResponse(params).pipe(
      __map(_r => _r.body as Organization)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getOrganizationVerticalsResponse(XFields?: string): __Observable<__StrictHttpResponse<Organization_verticals>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/verticals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Organization_verticals>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getOrganizationVerticals(XFields?: string): __Observable<Organization_verticals> {
    return this.getOrganizationVerticalsResponse(XFields).pipe(
      __map(_r => _r.body as Organization_verticals)
    );
  }

  /**
   * @param params The `OrganizationsService.PutOrganizationParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organization_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putOrganizationResponse(params: OrganizationsService.PutOrganizationParams): __Observable<__StrictHttpResponse<Organization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Organization>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.PutOrganizationParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organization_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putOrganization(params: OrganizationsService.PutOrganizationParams): __Observable<Organization> {
    return this.putOrganizationResponse(params).pipe(
      __map(_r => _r.body as Organization)
    );
  }

  /**
   * @param params The `OrganizationsService.PostOrganizationAddressesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organization_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postOrganizationAddressesResponse(params: OrganizationsService.PostOrganizationAddressesParams): __Observable<__StrictHttpResponse<Organization_address>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationId))}/addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Organization_address>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.PostOrganizationAddressesParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organization_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postOrganizationAddresses(params: OrganizationsService.PostOrganizationAddressesParams): __Observable<Organization_address> {
    return this.postOrganizationAddressesResponse(params).pipe(
      __map(_r => _r.body as Organization_address)
    );
  }

  /**
   * @param params The `OrganizationsService.PutOrganizationAddressParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organization_id`:
   *
   * - `organization_address_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putOrganizationAddressResponse(params: OrganizationsService.PutOrganizationAddressParams): __Observable<__StrictHttpResponse<Organization_address>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationId))}/addresses/${encodeURIComponent(String(params.organizationAddressId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Organization_address>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.PutOrganizationAddressParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organization_id`:
   *
   * - `organization_address_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putOrganizationAddress(params: OrganizationsService.PutOrganizationAddressParams): __Observable<Organization_address> {
    return this.putOrganizationAddressResponse(params).pipe(
      __map(_r => _r.body as Organization_address)
    );
  }

  /**
   * @param params The `OrganizationsService.PutOrganizationSettingsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organization_id`:
   */
  putOrganizationSettingsResponse(params: OrganizationsService.PutOrganizationSettingsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.PutOrganizationSettingsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organization_id`:
   */
  putOrganizationSettings(params: OrganizationsService.PutOrganizationSettingsParams): __Observable<null> {
    return this.putOrganizationSettingsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `OrganizationsService.GetOrganizationSettingsParams` containing the following parameters:
   *
   * - `organization_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOrganizationSettingsResponse(params: OrganizationsService.GetOrganizationSettingsParams): __Observable<__StrictHttpResponse<Organization_settings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Organization_settings>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.GetOrganizationSettingsParams` containing the following parameters:
   *
   * - `organization_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getOrganizationSettings(params: OrganizationsService.GetOrganizationSettingsParams): __Observable<Organization_settings> {
    return this.getOrganizationSettingsResponse(params).pipe(
      __map(_r => _r.body as Organization_settings)
    );
  }
}

module OrganizationsService {

  /**
   * Parameters for getOrganizations
   */
  export interface GetOrganizationsParams {

    /**
     * List of organization ids
     */
    ids?: Array<number>;

    /**
     * Active/Inactive for organization
     */
    active?: boolean;

    /**
     * Premium Enabled/Disabled for organization
     */
    premium?: boolean;

    /**
     * 1=Internal, 2=External
     */
    organizationTypes?: Array<number>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postOrganizations
   */
  export interface PostOrganizationsParams {
    payload: Organization_input;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putOrganization
   */
  export interface PutOrganizationParams {
    payload: Organization_input;
    organizationId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postOrganizationAddresses
   */
  export interface PostOrganizationAddressesParams {
    payload: Organization_address_input;
    organizationId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putOrganizationAddress
   */
  export interface PutOrganizationAddressParams {
    payload: Organization_address;
    organizationId: number;
    organizationAddressId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putOrganizationSettings
   */
  export interface PutOrganizationSettingsParams {
    payload: Organization_setting_inputs;
    organizationId: number;
  }

  /**
   * Parameters for getOrganizationSettings
   */
  export interface GetOrganizationSettingsParams {
    organizationId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { OrganizationsService }
