import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { style } from '@angular/animations';

@Component({
  selector: 'app-forcast',
  templateUrl: './nutrient-forcast.component.html',
  styleUrls: ['./nutrient-forcast.component.scss']
})
export class NutrientForcastComponent implements OnInit {
  highcharts = Highcharts;
  
  chart;
  chartCallback;
  title = 'myHighchart';
  chartConstructor = "chart";
  vegChartOptions: {};
  cropYieldChartOptions:{}
  tempChartOptions:{}
  costSavingsChartOptions:{}
  constructor() { }

  ngOnInit() {
    this.vegChartOptions = {
      chart: {
        type: "spline",
        backgroundColor:'#242434',
      },
      title: {
        text: 'Average Vegetation and Soil Moisture Index',
        style:{
          color:'#ffffff'
        }
      },
      yAxis: {
        title: {
          text: 'Index',
          style:{
            color:'#ffffff'
          }
        },
        style:{
          color:'#ffffff'
        },
        labels:{
          style:{
            color:'#ffffff'
          }
        }
      },
    
      xAxis: [{
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ],
        title:{
          text:null,
          style:{
            color:'#ffffff'
          }
        },
        style:{
          color:'#ffffff'
        },
        labels:{
          style:{
            color:'#ffffff'
          }
        }
      }],
    
      legend: {
        labelFormatter: function () {
          return '<span style="color: '+this.color+'">'+ this.name + '</span>';
        },
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        labels:{
        style:{
          color:'#ffffff'
        }
        }
      },
    
      // plotOptions: {
      //   series: {
      //     label: {
      //       connectorAllowed: false
      //     },
      //     pointStart: 2010
      //   }
      // },
    
      series: [{
        name: 'Vegetation',
        color:'#7cb5ec',
        data: [0.5, 0.3, 0.1, 0.2, 0.4, 0.6, 0.4, 0.6, 0.8, 0.6, 0.6, 0.4]
      }, {
        color:"#ffffff",
        name: 'Moisture',
        data: [0.2, 0.4, 0.4, 0.6, 0.3, 0.7, 0.8, 0.3, 0.3, 0.3, 0.5, 0.1]
      }],
    
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    
    }
    this.cropYieldChartOptions = {
      chart: {
        type: 'bar',
        backgroundColor:'#242434',
      },
      title: {
        text: 'Forecasted Crop Yield',
        style:{
          color:'#ffffff'
        }
      },
      xAxis: {
        categories: ['Grape', 'Walnut', 'Almond'],
        title: {
          text: null,
          style:{
            color:'#ffffff'
          }
        },
        style:{
          color:'#ffffff'
        },
        labels:{
          style:{
            color:'#ffffff'
          }
        }
      },
      yAxis: {
        min: 0,
        style:{
          color:'#ffffff'
        },
        title: {
          text: 'Crop Yield (kg/acre)',
          align: 'high',
          style:{
            color:'#ffffff'
          }
        },
        labels: {
          overflow: 'justify',
          style:{
            color:'#ffffff'
          }
        }
      },
      tooltip: {
        valueSuffix: ' kg/acre'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        labelFormatter: function () {
          return '<span style="color: '+this.color+'">'+ this.name + '</span>';
        },
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: [{
          color:'#7cb5ec',
          name: 'Optimum Nutrition',
          data: [10000, 6000, 5000]
        }, {
          color:'green',
          name: 'Nutrient Deficiency',
          data: [4000, 1000, 500]
        },
        {
          color:'red',
          name: 'Nutrient Toxicity',
          data: [6000, 3000, 2000]
        }
      ]
    }
    this.tempChartOptions = {
    
      chart: {
        zoomType: 'xy',
        backgroundColor:'#242434',
      },
      title: {
        text: 'Average Monthly Temperature and Rainfall Forecast',
        style:{
          color:'#ffffff'
        }
      },
      xAxis: [{
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ],
        crosshair: true,
        title:{
          text:null,
          style:{
            color:'#ffffff'
          }
        },
        style:{
          color:'#ffffff'
        },
        labels:{
          style:{
            color:'#ffffff'
          }
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value}°C',
          style: {
            color: '#ffffff'
          }
        },
        title: {
          text: 'Temperature',
          style: {
            color: '#ffffff'
          }
        }
      }, { // Secondary yAxis
        title: {
          text: 'Rainfall',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        labels: {
          format: '{value} mm',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        opposite: true
      }],
      tooltip: {
        shared: true
      },
      legend: {
        labelFormatter: function () {
          return '<span style="color: '+this.color+'">'+ this.name + '</span>';
        },
        layout: 'vertical',
        align: 'left',
        x: 120,
        verticalAlign: 'top',
        y: 100,
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || // theme
          'rgba(255,255,255,0.25)'
      },
      series: [{
        color:'#7cb5ec',
        name: 'Rainfall',
        type: 'column',
        yAxis: 1,
        data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
        tooltip: {
          valueSuffix: ' mm'
        }
    
      }, {
        color:'#ffffff',
        name: 'Temperature',
        type: 'spline',
        data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6],
        tooltip: {
          valueSuffix: '°C'
        },
      }]
    }
    this.costSavingsChartOptions = {
    
      chart: {
        type: 'column',
        backgroundColor:'#242434',
      },
      title: {
        text: 'Cost savings per acre',
        style:{
          color:'#ffffff'
        }
      },
      xAxis: [{
        categories: ['January', 'February', 'March', 'April', 'May','June', 'July','August', 'September','October','November', 'December'
        ],
        crosshair: true,
        title:{
          text:null,
          style:{
            color:'#ffffff'
          }
        },
        style:{
          color:'#ffffff'
        },
        labels:{
          style:{
            color:'#ffffff'
          }
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          //format: '{value}°C',
          style: {
            color: '#ffffff'
          }
        },
        title: {
          text: 'Values',
          style: {
            color: '#ffffff'
          }
        }
      }],
      series: [{name: 'Cost savings ($ per acre)',data: [6000, 5000, 4000, -10000, 3000, 1000, 2000, -4520, 6230, 2430, 1120, 1890]}],
      tooltip: {
        shared: true
      },
      legend: {
        labelFormatter: function () {
          return '<span style="color: '+this.color+'">'+ this.name + '</span>';
        },
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        labels:{
        style:{
          color:'#ffffff'
        }
        }
      },
    }
      
  }
  
}
