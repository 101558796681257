import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {CpUsersService} from '../../../../api/src/lib/services';
import {CachedBaseService} from './cached-base.service';
import {KetosSocketActionEnum, KetosSocketEventEnum, KetosSocketService} from './ketos-socket.service';
import {AuthService} from './auth.service';
import {CloneDeep} from '../utils/utils';
import {UnitSettingInterface} from '../models/unit-setting.interface';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsCachedService extends CachedBaseService {
  subject: ReplaySubject<UnitSettingInterface[]>;
  models: UnitSettingInterface[];

  selectedUnitsDict: {[key: string]: string};

  socketSub: Subscription;
  constructor(private ketosSocketService: KetosSocketService,
              private cpUsersService: CpUsersService,
              public authService: AuthService) {
    super();
    // this.getCached(false).subscribe(res => {
    //   this.setupSubject();
    // });
  }

  setupSubject() {
    if (!this.socketSub && this.authService.getUser()?.organization_id && this.authService.getUser()?.role_id < 4) {
      this.socketSub = this.ketosSocketService.getSubjectForRoom(this.authService.getUser().organization_id, KetosSocketEventEnum.cp_user_settings)
        .subscribe(data => {
          this.updateFromSocket(data);
        });
    }
  }

  public getCached(live = true, forceRefresh = false): Observable<UnitSettingInterface[]> {
    return this.getCachedBase(live, forceRefresh);
  }

  fetchData(): Observable<UnitSettingInterface[]> {
    return this.cpUsersService.getCpUserSettings({})
      .pipe(
        map(res => {
          this.models = res.items as unknown as UnitSettingInterface[];
          this.selectedUnitsDict = {};

          this.models = res.items.map( (setting: UnitSettingInterface) => {
            if (setting.key.length > 8) {
              let key = setting.key.slice(3, -5)

              if (setting.key.startsWith('cp_') && setting.key.endsWith('_unit')) {
                const newSetting = CloneDeep(setting);
                newSetting.value = (setting.value as string)?.replace('?', 'μ');
                if (newSetting.choices.indexOf(newSetting.value) === -1) {
                  newSetting.value = newSetting.default;
                  // newSetting.value = newSetting.choices[5] || newSetting.default;
                }
                this.selectedUnitsDict[key] = newSetting.value as string;
                if (key === 'temperature') {
                  this.selectedUnitsDict['water_temperature'] = newSetting.value as string;
                }
                return newSetting;
              }
            }
            return setting;
          });

          this.selectedUnitsDict.alkalinity_alkalinity_hr = this.selectedUnitsDict.alkalinity;
          this.selectedUnitsDict.boron_ksm = this.selectedUnitsDict.boron;
          this.selectedUnitsDict.chromium_ksm = this.selectedUnitsDict.boron;
          this.selectedUnitsDict.copper_bb = this.selectedUnitsDict.copper;
          this.selectedUnitsDict.manganese_bb = this.selectedUnitsDict.manganese;
          this.selectedUnitsDict.zinc_bb = this.selectedUnitsDict.zinc;
          this.selectedUnitsDict.nitrates_bb = this.selectedUnitsDict.nitrates;
          this.selectedUnitsDict.phosphates_bb_hr = this.selectedUnitsDict.phosphates;
          this.selectedUnitsDict.nickel_bb = this.selectedUnitsDict.nickel;
          this.selectedUnitsDict.phosphates_bb_hr = this.selectedUnitsDict.phosphates;
          this.subject.next(this.models);
          return this.models;
        })
      );
  }

  // live logic
  updateFromSocket(data: {action: KetosSocketActionEnum, event_type: KetosSocketEventEnum, organization_id: number, room: string, sensor_id: string, message: {cp_user_setting_input: UnitSettingInterface} }) {
    if (data.action === KetosSocketActionEnum.write) {
      this.models.push(data.message.cp_user_setting_input);
      this.subject.next(this.models);
    } else if (data.action === KetosSocketActionEnum.update) {
      const idx = this.models.findIndex(obj => obj.key === data.message.cp_user_setting_input.key);
      if (idx !== -1) {
        this.models[idx] = data.message.cp_user_setting_input;
      }
      this.subject.next(this.models);
    } else if ( data.action === KetosSocketActionEnum.delete) {
      const idx = this.models.findIndex(obj => obj.key === data.message.cp_user_setting_input.key);
      if (idx !== -1) {
        this.models.splice(idx, 1);
      }
      this.subject.next(this.models);
    }
  }
}
