/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Customer_success_emails } from '../models/customer-_success-_emails';

/**
 * Customer success emails endpoint
 */
@Injectable({
  providedIn: 'root',
})
class CustomerSuccessEmailsService extends __BaseService {
  static readonly getCustomerSuccessEmailsPath = '/customer_success_emails';
  static readonly postCustomerSuccessEmailsPath = '/customer_success_emails';
  static readonly postCustomerSuccessEmailGeneratorPath = '/customer_success_emails/generate_report/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CustomerSuccessEmailsService.GetCustomerSuccessEmailsParams` containing the following parameters:
   *
   * - `sensor_sns`: List of sensor sn
   *
   * - `requested_user_emails`: requested user email address
   */
  getCustomerSuccessEmailsResponse(params: CustomerSuccessEmailsService.GetCustomerSuccessEmailsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sensorSns || []).forEach(val => {if (val != null) __params = __params.append('sensor_sns', val.toString())});
    (params.requestedUserEmails || []).forEach(val => {if (val != null) __params = __params.append('requested_user_emails', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customer_success_emails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CustomerSuccessEmailsService.GetCustomerSuccessEmailsParams` containing the following parameters:
   *
   * - `sensor_sns`: List of sensor sn
   *
   * - `requested_user_emails`: requested user email address
   */
  getCustomerSuccessEmails(params: CustomerSuccessEmailsService.GetCustomerSuccessEmailsParams): __Observable<null> {
    return this.getCustomerSuccessEmailsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  postCustomerSuccessEmailsResponse(payload: Customer_success_emails): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/customer_success_emails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postCustomerSuccessEmails(payload: Customer_success_emails): __Observable<null> {
    return this.postCustomerSuccessEmailsResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  postCustomerSuccessEmailGeneratorResponse(payload: Customer_success_emails): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/customer_success_emails/generate_report/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postCustomerSuccessEmailGenerator(payload: Customer_success_emails): __Observable<null> {
    return this.postCustomerSuccessEmailGeneratorResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CustomerSuccessEmailsService {

  /**
   * Parameters for getCustomerSuccessEmails
   */
  export interface GetCustomerSuccessEmailsParams {

    /**
     * List of sensor sn
     */
    sensorSns?: Array<string>;

    /**
     * requested user email address
     */
    requestedUserEmails?: Array<string>;
  }
}

export { CustomerSuccessEmailsService }
