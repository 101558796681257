import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'ketosDateFormat'
})
export class KetosDateFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  transform(value: any) {
    var datePipe = new DatePipe('en-US');
    //value = value?this.datePipe.transform(value, 'MMM d y','UTC'):'N/A';
    value = value ? moment(value).format('YYYY-MM-DDTHH:mm:ss[Z]') : null;
    value = value ? moment.utc(value).format('MMM DD YYYY') : 'N/A';
    return value;
  }
}
