import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {WaterSourcesCachedService} from './water-sources-cached.service';
import {KetosSocketEventEnum, KetosSocketService} from './ketos-socket.service';
import {merge, Subscription} from 'rxjs';
import {Subject} from 'rxjs';
import {UiPlatformService} from './ui-platform.service';
import {ToastController} from '@ionic/angular';
import {AppStorage} from '../utils/AppStorage';
import { OrganizationCustomParamsSettingsCachedService } from './organization-customParams-settings.cached.service';
import { OrganizationSettingsInterface } from '../models/organization-settings.interface';

@Injectable({
  providedIn: 'root'
})
export class TestNotificationService {

//create an instance of MatSnackBar
  public notification$: Subject<string> = new Subject();
  public resultsSub: Subscription;
  public sensorSub: Subscription;

  constructor(private waterSourcesCachedService: WaterSourcesCachedService,
              private snackBar: MatSnackBar,
              private ketosSocketService: KetosSocketService,
              private uiPlatformService: UiPlatformService,
              public toastController: ToastController,
              public organizationCustomParamsSettingsCachedService: OrganizationCustomParamsSettingsCachedService
  ) {
    this.init();
  }

  /* It takes three parameters
    1.the message string
    2.the action
    3.the duration, alignment, etc. */
  init() {
    const user = JSON.parse(AppStorage.getItem('user'));
    if (!user) {
      return;
    }
    console.debug('init TestNotificationService');
    this.organizationCustomParamsSettingsCachedService.getCached(false, true)
        .subscribe((data:OrganizationSettingsInterface) => {
        console.log(data,'testNotificationService')
      });
    if (this.sensorSub) {
      this.sensorSub.unsubscribe();
    }
    this.sensorSub = this.waterSourcesCachedService.getCached(false, true).subscribe(waterSources => {
      const resultsRoomSubjects = waterSources.map(source => {
        return this.ketosSocketService.getSubjectForRoom(source.id, KetosSocketEventEnum.shield_tests);
      });
      console.debug(resultsRoomSubjects, 'resultsRoomSubjects---');
      if (this.resultsSub) {
        this.resultsSub.unsubscribe();
      }
      merge(...resultsRoomSubjects).subscribe(shieldTest => {
        console.debug(shieldTest, 'shieldTest');
        if (shieldTest?.message?.shield_tests?.status === 1 || shieldTest?.message?.shield_tests?.status === 2) {
          //this.notification$.next(`Test Number ${shieldTest?.message?.shield_test?.test_number} is In Process!`)
          this.openSnackBar(`Test Number ${shieldTest?.message?.shield_tests?.test_number} is In Progress!`);
        } else if (shieldTest?.message?.shield_tests?.status === 3) {
          //this.notification$.next(`Test Number ${shieldTest?.message?.shield_test?.test_number} has been Completed!`)
          this.openSnackBar(`Test Number ${shieldTest?.message?.shield_tests?.test_number} has been Completed!`);
        } else if (shieldTest?.message?.shield_tests?.status === 8) {
          //this.notification$.next(`Test Number ${shieldTest?.message?.shield_test?.test_number} has been Cancelled!`)
          console.debug('test cancelled!!');
          this.openSnackBar(`Test Number ${shieldTest?.message?.shield_tests?.test_number} has been Cancelled!`);
        }
      });
    });
  }

  public openSnackBar(message: string, action?: string) {
    if (this.uiPlatformService.webPlatform) {
      this.snackBar.open(message, 'Done', {
        duration: 5000,
        verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
        horizontalPosition: 'right' // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
      });
    } else {
      this.presentToast(message);
    }
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      header: message,
      duration: 5000,
      position: 'bottom',
      color: 'light',
      buttons: [
        {
          side: 'end',
          text: 'Done'
        }
      ]
    });
    toast.present();
  }
}
