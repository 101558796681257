/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Report } from '../models/report';
import { Uptime } from '../models/uptime';

/**
 * Report related operations
 */
@Injectable({
  providedIn: 'root',
})
class ReportsService extends __BaseService {
  static readonly getReportPath = '/reports';
  static readonly getUptimeReportPath = '/reports/uptime';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * For Power BI reports
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getReportResponse(XFields?: string): __Observable<__StrictHttpResponse<Report>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/reports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Report>;
      })
    );
  }
  /**
   * For Power BI reports
   * @param X-Fields An optional fields mask
   * @return Success
   */
  getReport(XFields?: string): __Observable<Report> {
    return this.getReportResponse(XFields).pipe(
      __map(_r => _r.body as Report)
    );
  }

  /**
   * Get uptime report
   * @param params The `ReportsService.GetUptimeReportParams` containing the following parameters:
   *
   * - `start_date`: Start date in ISO 8601 format
   *
   * - `end_date`: End date in ISO 8601 format
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getUptimeReportResponse(params: ReportsService.GetUptimeReportParams): __Observable<__StrictHttpResponse<Array<Uptime>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('start_date', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('end_date', params.endDate.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/reports/uptime`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Uptime>>;
      })
    );
  }
  /**
   * Get uptime report
   * @param params The `ReportsService.GetUptimeReportParams` containing the following parameters:
   *
   * - `start_date`: Start date in ISO 8601 format
   *
   * - `end_date`: End date in ISO 8601 format
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getUptimeReport(params: ReportsService.GetUptimeReportParams): __Observable<Array<Uptime>> {
    return this.getUptimeReportResponse(params).pipe(
      __map(_r => _r.body as Array<Uptime>)
    );
  }
}

module ReportsService {

  /**
   * Parameters for getUptimeReport
   */
  export interface GetUptimeReportParams {

    /**
     * Start date in ISO 8601 format
     */
    startDate: string;

    /**
     * End date in ISO 8601 format
     */
    endDate: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ReportsService }
