import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class WaveSensorsService {

  constructor() { }

  getWaveSensors(): Observable<any[]> {
    return of([]);
  }
}
