/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Audit_log } from '../models/audit-_log';

/**
 * Customer usage metrics
 */
@Injectable({
  providedIn: 'root',
})
class AuditService extends __BaseService {
  static readonly componentMetricsPath = '/audit/componentmetrics/{source}/{email}/{componentname}';
  static readonly metricsPath = '/audit/landingpage/{source}/{email}';
  static readonly SaveAuditPath = '/audit/save';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AuditService.ComponentMetricsParams` containing the following parameters:
   *
   * - `source`: all, web, mobile_app
   *
   * - `email`: all, email id of user
   *
   * - `componentname`: wave, shield, iceberg, download, api, Login
   */
  componentMetricsResponse(params: AuditService.ComponentMetricsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/audit/componentmetrics/${encodeURIComponent(String(params.source))}/${encodeURIComponent(String(params.email))}/${encodeURIComponent(String(params.componentname))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AuditService.ComponentMetricsParams` containing the following parameters:
   *
   * - `source`: all, web, mobile_app
   *
   * - `email`: all, email id of user
   *
   * - `componentname`: wave, shield, iceberg, download, api, Login
   */
  componentMetrics(params: AuditService.ComponentMetricsParams): __Observable<null> {
    return this.componentMetricsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AuditService.MetricsParams` containing the following parameters:
   *
   * - `source`: all, web, mobile_app
   *
   * - `email`: all, email id of user
   */
  metricsResponse(params: AuditService.MetricsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/audit/landingpage/${encodeURIComponent(String(params.source))}/${encodeURIComponent(String(params.email))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AuditService.MetricsParams` containing the following parameters:
   *
   * - `source`: all, web, mobile_app
   *
   * - `email`: all, email id of user
   */
  metrics(params: AuditService.MetricsParams): __Observable<null> {
    return this.metricsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  SaveAuditResponse(payload: Audit_log): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/audit/save`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  SaveAudit(payload: Audit_log): __Observable<null> {
    return this.SaveAuditResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AuditService {

  /**
   * Parameters for componentMetrics
   */
  export interface ComponentMetricsParams {

    /**
     * all, web, mobile_app
     */
    source: string;

    /**
     * all, email id of user
     */
    email: string;

    /**
     * wave, shield, iceberg, download, api, Login
     */
    componentname: string;
  }

  /**
   * Parameters for metrics
   */
  export interface MetricsParams {

    /**
     * all, web, mobile_app
     */
    source: string;

    /**
     * all, email id of user
     */
    email: string;
  }
}

export { AuditService }
