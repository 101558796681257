import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NutrientManagementComponent} from './nutrient-management/nutrient-management.component';
import {NutrientManagementModule} from "./nutrient-management/nutrient-management.module";
import {SharedModule} from '../../components/shared/shared.module';
import {IonRangeSliderModule} from '../modeller/range-slider/ion-range-slider.module';
import {RouterModule, Routes} from "@angular/router";

const routes: Routes = [
      {path: '', redirectTo: 'nutrient-management', pathMatch: 'full'},
      {path: 'nutrient-management', component: NutrientManagementComponent, data: {showSubmenu: true}},
];

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    NutrientManagementModule,
    SharedModule,
    IonRangeSliderModule,
    RouterModule.forChild(routes)
  ]
})
export class OperationalIntelligenceModule { }
