import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonRangeSliderComponent } from './ion-range-slider/ion-range-slider.component';



@NgModule({
  declarations: [IonRangeSliderComponent],
  imports: [
    CommonModule
  ],
  exports: [IonRangeSliderComponent]
})
export class IonRangeSliderModule { }
