/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterPipe} from './filter.pipe';
import {OrderByPipe} from './order-by.pipe';
import {FormatResultValuePipe} from './format-result-value.pipe';
import {ImperialUnitConversionPipe} from './imperial-unit-conversion.pipe';
import {RuntimePipe} from './runtime.pipe';
import {EmailFormatPipe} from './email-format.pipe';
import {PluckPipe} from './pluck.pipe';
import {JoinPipe} from './join.pipe';
import {CalculateDaysPipe} from './calculate-days.pipe';
import {KetosResultPipe} from './ketos-result.pipe';
import {UnitFromSettingsConverterPipe} from './unit-from-settings-converter.pipe';
import {ClipAdlBdlPipe} from './clip-adl-bdl.pipe';
import {FormatDisplayNumberPipe} from './format-display-number.pipe';
import {ResultUnitPipe} from './result-unit.pipe';
import {TimeDifferencePipe} from './time-difference.pipe';
import {KetosDateFormatPipe} from './ketos-date-format.pipe';
import {UtcToLocalDatePipe} from './utc-to-local-date.pipe';
import {RunCustomFunctionPipe} from './run-custom-function.pipe';
import {ValueFromNestedDictsPipe} from './value-from-nested-dicts.pipe';
import { BitmapToNamesPipe } from './bitmap-to-names.pipe';
import { TestStatusNumberToNamePipe } from './test-status-number-to-name.pipe';
import {AlertDetailUnitConverterPipe} from "./alert-detail-unit-converter.pipe";
import { KetosDatePipe } from './ketos-date.pipe';
import { SelectionToTitlePipe } from './selection-to-title.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FilterPipe,
    OrderByPipe,
    FormatResultValuePipe,
    ImperialUnitConversionPipe,
    RuntimePipe,
    EmailFormatPipe,
    PluckPipe,
    JoinPipe,
    CalculateDaysPipe,
    KetosResultPipe,
    UnitFromSettingsConverterPipe,
    ClipAdlBdlPipe,
    FormatDisplayNumberPipe,
    ResultUnitPipe,
    TimeDifferencePipe,
    KetosDateFormatPipe,
    UtcToLocalDatePipe,
    RunCustomFunctionPipe,
    ValueFromNestedDictsPipe,
    BitmapToNamesPipe,
    TestStatusNumberToNamePipe,
    AlertDetailUnitConverterPipe,
    KetosDatePipe,
    SelectionToTitlePipe
  ],
  exports: [
    FilterPipe,
    OrderByPipe,
    FormatResultValuePipe,
    ImperialUnitConversionPipe,
    EmailFormatPipe,
    RuntimePipe,
    PluckPipe,
    JoinPipe,
    CalculateDaysPipe,
    KetosResultPipe,
    UnitFromSettingsConverterPipe,
    ClipAdlBdlPipe,
    FormatDisplayNumberPipe,
    ResultUnitPipe,
    TimeDifferencePipe,
    KetosDateFormatPipe,
    UtcToLocalDatePipe,
    RunCustomFunctionPipe,
    ValueFromNestedDictsPipe,
    BitmapToNamesPipe,
    TestStatusNumberToNamePipe,
    AlertDetailUnitConverterPipe,
    KetosDatePipe,
    SelectionToTitlePipe
  ],
  providers: [
    UnitFromSettingsConverterPipe,
    ClipAdlBdlPipe,
    FormatDisplayNumberPipe,
    ResultUnitPipe,
    SelectionToTitlePipe
  ]

})
export class PipesModule {
}
