/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  initStarted = false;
  resolve: any;

  constructor() {
  }

  private getGoogleMaps() {
    this.initStarted = true;
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.CONSTANTS.googleMapAPiKey}`;
    document.body.appendChild(script);
  }

  public initMaps(): Promise<any> {
    if (window['google']) {
      return Promise.resolve();
    } else {
      if (!this.initStarted) {
        this.getGoogleMaps();
        window['initMap'] = () => {
          // window['initMap'] = null;
          if (this.resolve) {
            this.resolve();
          }
        };
      }
      return new Promise(resolve => {
        this.resolve = resolve;
      });
    }
  }

  loadMarkerCluster() {
    this.initStarted = true;
    const script = document.createElement('script');
    script.src = `https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js`;
    document.body.appendChild(script);
  }
}
