/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Shield_tests_paginated } from '../models/shield-_tests-_paginated';
import { Shield_test } from '../models/shield-_test';
import { Shield_test_status } from '../models/shield-_test-_status';
import { Shield_test_length } from '../models/shield-_test-_length';
import { Shield_test_counts } from '../models/shield-_test-_counts';
import { Shield_tests_data_quality } from '../models/shield-_tests-_data-_quality';
import { Shield_test_date_histogram } from '../models/shield-_test-_date-_histogram';
import { Shield_result_aggregates } from '../models/shield-_result-_aggregates';
import { Shield_tests_manual } from '../models/shield-_tests-_manual';
import { Results } from '../models/results';

/**
 * APIs related to shield tests
 */
@Injectable({
  providedIn: 'root',
})
class ShieldTestsService extends __BaseService {
  static readonly getShieldTestsShieldTestsPath = '/shield/tests';
  static readonly patchShieldTestStatusShieldTestsStringIdStatusPath = '/shield/tests/{id}/status';
  static readonly getShieldTestShieldTestsIntSensorIdStringBitmapLengthPath = '/shield/tests/{sensor_id}/{bitmap}/length';
  static readonly getShieldTestCountsPath = '/shield_tests/counts';
  static readonly postDataQualityMetricsPath = '/shield_tests/data_quality';
  static readonly postDataQualityMetricsShieldTestsDataQualityIntOperatingStatusPath = '/shield_tests/data_quality/{operating_status}';
  static readonly postDataQualityMetricsDownloadPath = '/shield_tests/data_quality_download';
  static readonly getShieldTestDateHistogramPath = '/shield_tests/date_histogram';
  static readonly getEolStatusPath = '/shield_tests/eol/{sensor_id}';
  static readonly getTriggerEolActionPath = '/shield_tests/eol/{sensor_id}/{eol_action}';
  static readonly getEolActionStatusPath = '/shield_tests/eol/{sensor_id}/{eol_action}/{parameter}/{status}';
  static readonly getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalOrganizationIntOrganizationIdStringElementPath = '/shield_tests/history/{start_date}/{end_date}/{interval}/organization/{organization_id}/{element}';
  static readonly getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalSensorIntSensorIdStringElementPath = '/shield_tests/history/{start_date}/{end_date}/{interval}/sensor/{sensor_id}/{element}';
  static readonly getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalStringElementPath = '/shield_tests/history/{start_date}/{end_date}/{interval}/{element}';
  static readonly persistManualValuesForTestsPath = '/shield_tests/manual/{sensor_id}/{test_number}/{operating_status}';
  static readonly getShieldResultsShieldTestsResultsIntStartDateIntEndDateOrganizationIntOrganizationIdStringElementStringMethodIntPageIntPerPagePath = '/shield_tests/results/{start_date}/{end_date}/organization/{organization_id}/{element}/{method}/{page}/{per_page}';
  static readonly getShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPagePath = '/shield_tests/results/{start_date}/{end_date}/water_source/{water_source_id}/{element}/{method}/{page}/{per_page}';
  static readonly getShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageIntOperatingStatusPath = '/shield_tests/results/{start_date}/{end_date}/water_source/{water_source_id}/{element}/{method}/{page}/{per_page}/{operating_status}';
  static readonly getShieldResultsShieldTestsResultsIntStartDateIntEndDateStringElementStringMethodIntPageIntPerPagePath = '/shield_tests/results/{start_date}/{end_date}/{element}/{method}/{page}/{per_page}';
  static readonly getShieldDatesSignaturesPath = '/shield_tests/shield_dates/signatures/{water_source_id}/{operating_status}';
  static readonly getShieldDatesPath = '/shield_tests/shield_dates/{sensor_id}/{operating_status}';
  static readonly shieldTestsRawDataPath = '/shield_tests/shield_test_data/{sensor_id}/{test_number}/{operating_status}';
  static readonly shieldTestsPath = '/shield_tests/{sensor_id}/{test_number}/{operating_status}/{page}/{per_page}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get paginated list of shield tests
   * @param params The `ShieldTestsService.GetShieldTestsShieldTestsParams` containing the following parameters:
   *
   * - `ids`: List of shield result ids
   *
   * - `statuses`: List of statuses (1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error, 8=cancelled)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `test_numbers`: List of test numbers
   *
   * - `start_start_time`: Start start datetime (ISO 8601)
   *
   * - `end_start_time`: End start datetime (ISO 8601)
   *
   * - `start_expected_end_time`: Start end datetime (ISO 8601)
   *
   * - `end_expected_end_time`: End end datetime (ISO 8601)
   *
   * - `start_end_time`: Start end datetime (ISO 8601)
   *
   * - `end_end_time`: End end datetime (ISO 8601)
   *
   * - `start_updated_on`: Start datetime of last update (ISO 8601)
   *
   * - `end_updated_on`: End datetime of last update (ISO 8601)
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `updated_by`: Updated by
   *
   * - `time_zone`: Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
   *
   * - `titles`: Title from test schedule
   *
   * - `include_shield_test_schedule`: Embed associated shield_test_schedule record if any
   *
   * - `include_shield_test_results`: Get shield test results from OQ
   *
   * - `scroll_id`: PAGINATION: Pass to get next page for result sets over 10000
   *
   * - `per_page`: PAGINATION: Number of results to return (10000 max)
   *
   * - `page`: PAGINATION: Page number for result sets under 10000
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestsShieldTestsResponse(params: ShieldTestsService.GetShieldTestsShieldTestsParams): __Observable<__StrictHttpResponse<Shield_tests_paginated>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.waterSourceIds || []).forEach(val => {if (val != null) __params = __params.append('water_source_ids', val.toString())});
    (params.testNumbers || []).forEach(val => {if (val != null) __params = __params.append('test_numbers', val.toString())});
    if (params.startStartTime != null) __params = __params.set('start_start_time', params.startStartTime.toString());
    if (params.endStartTime != null) __params = __params.set('end_start_time', params.endStartTime.toString());
    if (params.startExpectedEndTime != null) __params = __params.set('start_expected_end_time', params.startExpectedEndTime.toString());
    if (params.endExpectedEndTime != null) __params = __params.set('end_expected_end_time', params.endExpectedEndTime.toString());
    if (params.startEndTime != null) __params = __params.set('start_end_time', params.startEndTime.toString());
    if (params.endEndTime != null) __params = __params.set('end_end_time', params.endEndTime.toString());
    if (params.startUpdatedOn != null) __params = __params.set('start_updated_on', params.startUpdatedOn.toString());
    if (params.endUpdatedOn != null) __params = __params.set('end_updated_on', params.endUpdatedOn.toString());
    (params.operatingStatuses || []).forEach(val => {if (val != null) __params = __params.append('operating_statuses', val.toString())});
    if (params.updatedBy != null) __params = __params.set('updated_by', params.updatedBy.toString());
    if (params.timeZone != null) __params = __params.set('time_zone', params.timeZone.toString());
    (params.titles || []).forEach(val => {if (val != null) __params = __params.append('titles', val.toString())});
    if (params.includeShieldTestSchedule != null) __params = __params.set('include_shield_test_schedule', params.includeShieldTestSchedule.toString());
    if (params.includeShieldTestResults != null) __params = __params.set('include_shield_test_results', params.includeShieldTestResults.toString());
    if (params.scrollId != null) __params = __params.set('scroll_id', params.scrollId.toString());
    if (params.perPage != null) __params = __params.set('per_page', params.perPage.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield/tests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_tests_paginated>;
      })
    );
  }
  /**
   * Get paginated list of shield tests
   * @param params The `ShieldTestsService.GetShieldTestsShieldTestsParams` containing the following parameters:
   *
   * - `ids`: List of shield result ids
   *
   * - `statuses`: List of statuses (1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error, 8=cancelled)
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `water_source_ids`: List of water source ids
   *
   * - `test_numbers`: List of test numbers
   *
   * - `start_start_time`: Start start datetime (ISO 8601)
   *
   * - `end_start_time`: End start datetime (ISO 8601)
   *
   * - `start_expected_end_time`: Start end datetime (ISO 8601)
   *
   * - `end_expected_end_time`: End end datetime (ISO 8601)
   *
   * - `start_end_time`: Start end datetime (ISO 8601)
   *
   * - `end_end_time`: End end datetime (ISO 8601)
   *
   * - `start_updated_on`: Start datetime of last update (ISO 8601)
   *
   * - `end_updated_on`: End datetime of last update (ISO 8601)
   *
   * - `operating_statuses`: List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
   *
   * - `updated_by`: Updated by
   *
   * - `time_zone`: Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
   *
   * - `titles`: Title from test schedule
   *
   * - `include_shield_test_schedule`: Embed associated shield_test_schedule record if any
   *
   * - `include_shield_test_results`: Get shield test results from OQ
   *
   * - `scroll_id`: PAGINATION: Pass to get next page for result sets over 10000
   *
   * - `per_page`: PAGINATION: Number of results to return (10000 max)
   *
   * - `page`: PAGINATION: Page number for result sets under 10000
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestsShieldTests(params: ShieldTestsService.GetShieldTestsShieldTestsParams): __Observable<Shield_tests_paginated> {
    return this.getShieldTestsShieldTestsResponse(params).pipe(
      __map(_r => _r.body as Shield_tests_paginated)
    );
  }

  /**
   * Update the test status
   * @param params The `ShieldTestsService.PatchShieldTestStatusShieldTestsStringIdStatusParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  patchShieldTestStatusShieldTestsStringIdStatusResponse(params: ShieldTestsService.PatchShieldTestStatusShieldTestsStringIdStatusParams): __Observable<__StrictHttpResponse<Shield_test>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/shield/tests/${encodeURIComponent(String(params.id))}/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test>;
      })
    );
  }
  /**
   * Update the test status
   * @param params The `ShieldTestsService.PatchShieldTestStatusShieldTestsStringIdStatusParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  patchShieldTestStatusShieldTestsStringIdStatus(params: ShieldTestsService.PatchShieldTestStatusShieldTestsStringIdStatusParams): __Observable<Shield_test> {
    return this.patchShieldTestStatusShieldTestsStringIdStatusResponse(params).pipe(
      __map(_r => _r.body as Shield_test)
    );
  }

  /**
   * Get length of test in seconds
   * @param params The `ShieldTestsService.GetShieldTestShieldTestsIntSensorIdStringBitmapLengthParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `bitmap`: Bitmap of experiments
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestShieldTestsIntSensorIdStringBitmapLengthResponse(params: ShieldTestsService.GetShieldTestShieldTestsIntSensorIdStringBitmapLengthParams): __Observable<__StrictHttpResponse<Shield_test_length>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield/tests/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.bitmap))}/length`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test_length>;
      })
    );
  }
  /**
   * Get length of test in seconds
   * @param params The `ShieldTestsService.GetShieldTestShieldTestsIntSensorIdStringBitmapLengthParams` containing the following parameters:
   *
   * - `sensor_id`: Sensor ID
   *
   * - `bitmap`: Bitmap of experiments
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestShieldTestsIntSensorIdStringBitmapLength(params: ShieldTestsService.GetShieldTestShieldTestsIntSensorIdStringBitmapLengthParams): __Observable<Shield_test_length> {
    return this.getShieldTestShieldTestsIntSensorIdStringBitmapLengthResponse(params).pipe(
      __map(_r => _r.body as Shield_test_length)
    );
  }

  /**
   * Get counts of shield_tests grouped by experiment
   * @param params The `ShieldTestsService.GetShieldTestCountsParams` containing the following parameters:
   *
   * - `organization_ids`: List of organization ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `operating_statuses`: List of operating statuses: 0=FAT, 1=Customer, 2=ServiceOps, 3=Decommissioned, 4=InHouse
   *
   * - `start_date`: Start date (ISO 8601)
   *
   * - `end_date`: End date (ISO 8601)
   *
   * - `include_scheduled`: Include scheduled/manual Shield tests
   *
   * - `include_continuous`: Include continuous parameters
   *
   * - `include_3rd_party`: Include data from 3rd party labs
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestCountsResponse(params: ShieldTestsService.GetShieldTestCountsParams): __Observable<__StrictHttpResponse<Shield_test_counts>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.organizationIds || []).forEach(val => {if (val != null) __params = __params.append('organization_ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.operatingStatuses || []).forEach(val => {if (val != null) __params = __params.append('operating_statuses', val.toString())});
    if (params.startDate != null) __params = __params.set('start_date', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('end_date', params.endDate.toString());
    if (params.includeScheduled != null) __params = __params.set('include_scheduled', params.includeScheduled.toString());
    if (params.includeContinuous != null) __params = __params.set('include_continuous', params.includeContinuous.toString());
    if (params.include3rdParty != null) __params = __params.set('include_3rd_party', params.include3rdParty.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/counts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test_counts>;
      })
    );
  }
  /**
   * Get counts of shield_tests grouped by experiment
   * @param params The `ShieldTestsService.GetShieldTestCountsParams` containing the following parameters:
   *
   * - `organization_ids`: List of organization ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `operating_statuses`: List of operating statuses: 0=FAT, 1=Customer, 2=ServiceOps, 3=Decommissioned, 4=InHouse
   *
   * - `start_date`: Start date (ISO 8601)
   *
   * - `end_date`: End date (ISO 8601)
   *
   * - `include_scheduled`: Include scheduled/manual Shield tests
   *
   * - `include_continuous`: Include continuous parameters
   *
   * - `include_3rd_party`: Include data from 3rd party labs
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestCounts(params: ShieldTestsService.GetShieldTestCountsParams): __Observable<Shield_test_counts> {
    return this.getShieldTestCountsResponse(params).pipe(
      __map(_r => _r.body as Shield_test_counts)
    );
  }

  /**
   * @param payload undefined
   */
  postDataQualityMetricsResponse(payload: Shield_tests_data_quality): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shield_tests/data_quality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postDataQualityMetrics(payload: Shield_tests_data_quality): __Observable<null> {
    return this.postDataQualityMetricsResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Alias for /data_quality with operating_status parameter
   * @param params The `ShieldTestsService.PostDataQualityMetricsShieldTestsDataQualityIntOperatingStatusParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `operating_status`:
   */
  postDataQualityMetricsShieldTestsDataQualityIntOperatingStatusResponse(params: ShieldTestsService.PostDataQualityMetricsShieldTestsDataQualityIntOperatingStatusParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shield_tests/data_quality/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Alias for /data_quality with operating_status parameter
   * @param params The `ShieldTestsService.PostDataQualityMetricsShieldTestsDataQualityIntOperatingStatusParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `operating_status`:
   */
  postDataQualityMetricsShieldTestsDataQualityIntOperatingStatus(params: ShieldTestsService.PostDataQualityMetricsShieldTestsDataQualityIntOperatingStatusParams): __Observable<null> {
    return this.postDataQualityMetricsShieldTestsDataQualityIntOperatingStatusResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  postDataQualityMetricsDownloadResponse(payload: Shield_tests_data_quality): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shield_tests/data_quality_download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postDataQualityMetricsDownload(payload: Shield_tests_data_quality): __Observable<null> {
    return this.postDataQualityMetricsDownloadResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get date histogram of shield_tests grouped by experiment
   * @param params The `ShieldTestsService.GetShieldTestDateHistogramParams` containing the following parameters:
   *
   * - `organization_ids`: List of organization ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `operating_statuses`: List of operating statuses: 0=FAT, 1=Customer, 2=ServiceOps, 3=Decommissioned, 4=InHouse
   *
   * - `start_date`: Start date (ISO 8601)
   *
   * - `end_date`: End date (ISO 8601)
   *
   * - `include_scheduled`: Include scheduled/manual Shield tests
   *
   * - `include_continuous`: Include continuous parameters
   *
   * - `include_3rd_party`: Include data from 3rd party labs
   *
   * - `time_zone`: Time zone examples: "UTC", "UTC-01:00", "UTC+10:30"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestDateHistogramResponse(params: ShieldTestsService.GetShieldTestDateHistogramParams): __Observable<__StrictHttpResponse<Shield_test_date_histogram>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.organizationIds || []).forEach(val => {if (val != null) __params = __params.append('organization_ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.operatingStatuses || []).forEach(val => {if (val != null) __params = __params.append('operating_statuses', val.toString())});
    if (params.startDate != null) __params = __params.set('start_date', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('end_date', params.endDate.toString());
    if (params.includeScheduled != null) __params = __params.set('include_scheduled', params.includeScheduled.toString());
    if (params.includeContinuous != null) __params = __params.set('include_continuous', params.includeContinuous.toString());
    if (params.include3rdParty != null) __params = __params.set('include_3rd_party', params.include3rdParty.toString());
    if (params.timeZone != null) __params = __params.set('time_zone', params.timeZone.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/date_histogram`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test_date_histogram>;
      })
    );
  }
  /**
   * Get date histogram of shield_tests grouped by experiment
   * @param params The `ShieldTestsService.GetShieldTestDateHistogramParams` containing the following parameters:
   *
   * - `organization_ids`: List of organization ids
   *
   * - `sensor_ids`: List of sensor ids
   *
   * - `operating_statuses`: List of operating statuses: 0=FAT, 1=Customer, 2=ServiceOps, 3=Decommissioned, 4=InHouse
   *
   * - `start_date`: Start date (ISO 8601)
   *
   * - `end_date`: End date (ISO 8601)
   *
   * - `include_scheduled`: Include scheduled/manual Shield tests
   *
   * - `include_continuous`: Include continuous parameters
   *
   * - `include_3rd_party`: Include data from 3rd party labs
   *
   * - `time_zone`: Time zone examples: "UTC", "UTC-01:00", "UTC+10:30"
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldTestDateHistogram(params: ShieldTestsService.GetShieldTestDateHistogramParams): __Observable<Shield_test_date_histogram> {
    return this.getShieldTestDateHistogramResponse(params).pipe(
      __map(_r => _r.body as Shield_test_date_histogram)
    );
  }

  /**
   * @param sensor_id undefined
   */
  getEolStatusResponse(sensorId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/eol/${encodeURIComponent(String(sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_id undefined
   */
  getEolStatus(sensorId: string): __Observable<null> {
    return this.getEolStatusResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldTestsService.GetTriggerEolActionParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `eol_action`:
   */
  getTriggerEolActionResponse(params: ShieldTestsService.GetTriggerEolActionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/eol/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.eolAction))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldTestsService.GetTriggerEolActionParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `eol_action`:
   */
  getTriggerEolAction(params: ShieldTestsService.GetTriggerEolActionParams): __Observable<null> {
    return this.getTriggerEolActionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldTestsService.GetEolActionStatusParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `eol_action`:
   *
   * - `parameter`:
   *
   * - `status`:
   */
  getEolActionStatusResponse(params: ShieldTestsService.GetEolActionStatusParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/eol/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.eolAction))}/${encodeURIComponent(String(params.parameter))}/${encodeURIComponent(String(params.status))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldTestsService.GetEolActionStatusParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `eol_action`:
   *
   * - `parameter`:
   *
   * - `status`:
   */
  getEolActionStatus(params: ShieldTestsService.GetEolActionStatusParams): __Observable<null> {
    return this.getEolActionStatusResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldTestsService.GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalOrganizationIntOrganizationIdStringElementParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `interval`: 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'
   *
   * - `organization_id`: Organization ID
   *
   * - `element`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalOrganizationIntOrganizationIdStringElementResponse(params: ShieldTestsService.GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalOrganizationIntOrganizationIdStringElementParams): __Observable<__StrictHttpResponse<Shield_result_aggregates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/history/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.interval))}/organization/${encodeURIComponent(String(params.organizationId))}/${encodeURIComponent(String(params.element))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_result_aggregates>;
      })
    );
  }
  /**
   * @param params The `ShieldTestsService.GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalOrganizationIntOrganizationIdStringElementParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `interval`: 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'
   *
   * - `organization_id`: Organization ID
   *
   * - `element`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalOrganizationIntOrganizationIdStringElement(params: ShieldTestsService.GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalOrganizationIntOrganizationIdStringElementParams): __Observable<Shield_result_aggregates> {
    return this.getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalOrganizationIntOrganizationIdStringElementResponse(params).pipe(
      __map(_r => _r.body as Shield_result_aggregates)
    );
  }

  /**
   * @param params The `ShieldTestsService.GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalSensorIntSensorIdStringElementParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `interval`: 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'
   *
   * - `sensor_id`: Sensor ID
   *
   * - `element`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalSensorIntSensorIdStringElementResponse(params: ShieldTestsService.GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalSensorIntSensorIdStringElementParams): __Observable<__StrictHttpResponse<Shield_result_aggregates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/history/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.interval))}/sensor/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.element))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_result_aggregates>;
      })
    );
  }
  /**
   * @param params The `ShieldTestsService.GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalSensorIntSensorIdStringElementParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `interval`: 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'
   *
   * - `sensor_id`: Sensor ID
   *
   * - `element`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalSensorIntSensorIdStringElement(params: ShieldTestsService.GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalSensorIntSensorIdStringElementParams): __Observable<Shield_result_aggregates> {
    return this.getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalSensorIntSensorIdStringElementResponse(params).pipe(
      __map(_r => _r.body as Shield_result_aggregates)
    );
  }

  /**
   * @param params The `ShieldTestsService.GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalStringElementParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `interval`: 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'
   *
   * - `element`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalStringElementResponse(params: ShieldTestsService.GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalStringElementParams): __Observable<__StrictHttpResponse<Shield_result_aggregates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/history/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.interval))}/${encodeURIComponent(String(params.element))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_result_aggregates>;
      })
    );
  }
  /**
   * @param params The `ShieldTestsService.GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalStringElementParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `interval`: 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'
   *
   * - `element`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalStringElement(params: ShieldTestsService.GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalStringElementParams): __Observable<Shield_result_aggregates> {
    return this.getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalStringElementResponse(params).pipe(
      __map(_r => _r.body as Shield_result_aggregates)
    );
  }

  /**
   * @param params The `ShieldTestsService.PersistManualValuesForTestsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   *
   * - `test_number`:
   *
   * - `operating_status`:
   */
  persistManualValuesForTestsResponse(params: ShieldTestsService.PersistManualValuesForTestsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;



    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/shield_tests/manual/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.testNumber))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldTestsService.PersistManualValuesForTestsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`:
   *
   * - `test_number`:
   *
   * - `operating_status`:
   */
  persistManualValuesForTests(params: ShieldTestsService.PersistManualValuesForTestsParams): __Observable<null> {
    return this.persistManualValuesForTestsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateOrganizationIntOrganizationIdStringElementStringMethodIntPageIntPerPageParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `organization_id`: Organization ID
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `element`:
   *
   * - `method`:
   *
   * @return Success
   */
  getShieldResultsShieldTestsResultsIntStartDateIntEndDateOrganizationIntOrganizationIdStringElementStringMethodIntPageIntPerPageResponse(params: ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateOrganizationIntOrganizationIdStringElementStringMethodIntPageIntPerPageParams): __Observable<__StrictHttpResponse<Results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;







    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/results/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/organization/${encodeURIComponent(String(params.organizationId))}/${encodeURIComponent(String(params.element))}/${encodeURIComponent(String(params.method))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Results>;
      })
    );
  }
  /**
   * @param params The `ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateOrganizationIntOrganizationIdStringElementStringMethodIntPageIntPerPageParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `organization_id`: Organization ID
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `element`:
   *
   * - `method`:
   *
   * @return Success
   */
  getShieldResultsShieldTestsResultsIntStartDateIntEndDateOrganizationIntOrganizationIdStringElementStringMethodIntPageIntPerPage(params: ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateOrganizationIntOrganizationIdStringElementStringMethodIntPageIntPerPageParams): __Observable<Results> {
    return this.getShieldResultsShieldTestsResultsIntStartDateIntEndDateOrganizationIntOrganizationIdStringElementStringMethodIntPageIntPerPageResponse(params).pipe(
      __map(_r => _r.body as Results)
    );
  }

  /**
   * @param params The `ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `water_source_id`:
   *
   * - `element`:
   *
   * - `method`:
   *
   * - `sensor_id`: Water source ID
   *
   * @return Success
   */
  getShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageResponse(params: ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageParams): __Observable<__StrictHttpResponse<Results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;







    if (params.sensorId != null) __params = __params.set('sensor_id', params.sensorId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/results/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/water_source/${encodeURIComponent(String(params.waterSourceId))}/${encodeURIComponent(String(params.element))}/${encodeURIComponent(String(params.method))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Results>;
      })
    );
  }
  /**
   * @param params The `ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `water_source_id`:
   *
   * - `element`:
   *
   * - `method`:
   *
   * - `sensor_id`: Water source ID
   *
   * @return Success
   */
  getShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPage(params: ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageParams): __Observable<Results> {
    return this.getShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageResponse(params).pipe(
      __map(_r => _r.body as Results)
    );
  }

  /**
   * @param params The `ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageIntOperatingStatusParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `operating_status`: Operating status
   *
   * - `water_source_id`:
   *
   * - `element`:
   *
   * - `method`:
   *
   * - `sensor_id`: Water source ID
   *
   * @return Success
   */
  getShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageIntOperatingStatusResponse(params: ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageIntOperatingStatusParams): __Observable<__StrictHttpResponse<Results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;








    if (params.sensorId != null) __params = __params.set('sensor_id', params.sensorId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/results/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/water_source/${encodeURIComponent(String(params.waterSourceId))}/${encodeURIComponent(String(params.element))}/${encodeURIComponent(String(params.method))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Results>;
      })
    );
  }
  /**
   * @param params The `ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageIntOperatingStatusParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `operating_status`: Operating status
   *
   * - `water_source_id`:
   *
   * - `element`:
   *
   * - `method`:
   *
   * - `sensor_id`: Water source ID
   *
   * @return Success
   */
  getShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageIntOperatingStatus(params: ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageIntOperatingStatusParams): __Observable<Results> {
    return this.getShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageIntOperatingStatusResponse(params).pipe(
      __map(_r => _r.body as Results)
    );
  }

  /**
   * @param params The `ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateStringElementStringMethodIntPageIntPerPageParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `element`:
   *
   * - `method`:
   *
   * @return Success
   */
  getShieldResultsShieldTestsResultsIntStartDateIntEndDateStringElementStringMethodIntPageIntPerPageResponse(params: ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateStringElementStringMethodIntPageIntPerPageParams): __Observable<__StrictHttpResponse<Results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;






    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/results/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/${encodeURIComponent(String(params.element))}/${encodeURIComponent(String(params.method))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Results>;
      })
    );
  }
  /**
   * @param params The `ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateStringElementStringMethodIntPageIntPerPageParams` containing the following parameters:
   *
   * - `start_date`: Start date epoch
   *
   * - `end_date`: End date epoch
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `element`:
   *
   * - `method`:
   *
   * @return Success
   */
  getShieldResultsShieldTestsResultsIntStartDateIntEndDateStringElementStringMethodIntPageIntPerPage(params: ShieldTestsService.GetShieldResultsShieldTestsResultsIntStartDateIntEndDateStringElementStringMethodIntPageIntPerPageParams): __Observable<Results> {
    return this.getShieldResultsShieldTestsResultsIntStartDateIntEndDateStringElementStringMethodIntPageIntPerPageResponse(params).pipe(
      __map(_r => _r.body as Results)
    );
  }

  /**
   * @param params The `ShieldTestsService.GetShieldDatesSignaturesParams` containing the following parameters:
   *
   * - `water_source_id`:
   *
   * - `operating_status`:
   */
  getShieldDatesSignaturesResponse(params: ShieldTestsService.GetShieldDatesSignaturesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/shield_dates/signatures/${encodeURIComponent(String(params.waterSourceId))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldTestsService.GetShieldDatesSignaturesParams` containing the following parameters:
   *
   * - `water_source_id`:
   *
   * - `operating_status`:
   */
  getShieldDatesSignatures(params: ShieldTestsService.GetShieldDatesSignaturesParams): __Observable<null> {
    return this.getShieldDatesSignaturesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldTestsService.GetShieldDatesParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `operating_status`:
   */
  getShieldDatesResponse(params: ShieldTestsService.GetShieldDatesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/shield_dates/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldTestsService.GetShieldDatesParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `operating_status`:
   */
  getShieldDates(params: ShieldTestsService.GetShieldDatesParams): __Observable<null> {
    return this.getShieldDatesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldTestsService.ShieldTestsRawDataParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `test_number`:
   *
   * - `operating_status`:
   */
  shieldTestsRawDataResponse(params: ShieldTestsService.ShieldTestsRawDataParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/shield_test_data/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.testNumber))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldTestsService.ShieldTestsRawDataParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `test_number`:
   *
   * - `operating_status`:
   */
  shieldTestsRawData(params: ShieldTestsService.ShieldTestsRawDataParams): __Observable<null> {
    return this.shieldTestsRawDataResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ShieldTestsService.ShieldTestsParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `test_number`:
   *
   * - `operating_status`:
   *
   * - `page`:
   *
   * - `per_page`:
   */
  shieldTestsResponse(params: ShieldTestsService.ShieldTestsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/shield_tests/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.testNumber))}/${encodeURIComponent(String(params.operatingStatus))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ShieldTestsService.ShieldTestsParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `test_number`:
   *
   * - `operating_status`:
   *
   * - `page`:
   *
   * - `per_page`:
   */
  shieldTests(params: ShieldTestsService.ShieldTestsParams): __Observable<null> {
    return this.shieldTestsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ShieldTestsService {

  /**
   * Parameters for getShieldTestsShieldTests
   */
  export interface GetShieldTestsShieldTestsParams {

    /**
     * List of shield result ids
     */
    ids?: Array<number>;

    /**
     * List of statuses (1=starting, 2=running, 3=complete, 4=incomplete, 5=did_not_start, 6=error, 7=sensor_error, 8=cancelled)
     */
    statuses?: Array<number>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of water source ids
     */
    waterSourceIds?: Array<number>;

    /**
     * List of test numbers
     */
    testNumbers?: Array<number>;

    /**
     * Start start datetime (ISO 8601)
     */
    startStartTime?: string;

    /**
     * End start datetime (ISO 8601)
     */
    endStartTime?: string;

    /**
     * Start end datetime (ISO 8601)
     */
    startExpectedEndTime?: string;

    /**
     * End end datetime (ISO 8601)
     */
    endExpectedEndTime?: string;

    /**
     * Start end datetime (ISO 8601)
     */
    startEndTime?: string;

    /**
     * End end datetime (ISO 8601)
     */
    endEndTime?: string;

    /**
     * Start datetime of last update (ISO 8601)
     */
    startUpdatedOn?: string;

    /**
     * End datetime of last update (ISO 8601)
     */
    endUpdatedOn?: string;

    /**
     * List of operating statuses (0=End of line, 1=Operational, 2=Maintenance, 3=Decommissioned, 4=Service Ops)
     */
    operatingStatuses?: Array<number>;

    /**
     * Updated by
     */
    updatedBy?: string;

    /**
     * Valid values are ISO 8601 UTC offsets, such as +01:00 or -08:00
     */
    timeZone?: string;

    /**
     * Title from test schedule
     */
    titles?: Array<string>;

    /**
     * Embed associated shield_test_schedule record if any
     */
    includeShieldTestSchedule?: boolean;

    /**
     * Get shield test results from OQ
     */
    includeShieldTestResults?: boolean;

    /**
     * PAGINATION: Pass to get next page for result sets over 10000
     */
    scrollId?: string;

    /**
     * PAGINATION: Number of results to return (10000 max)
     */
    perPage?: number;

    /**
     * PAGINATION: Page number for result sets under 10000
     */
    page?: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for patchShieldTestStatusShieldTestsStringIdStatus
   */
  export interface PatchShieldTestStatusShieldTestsStringIdStatusParams {
    payload: Shield_test_status;
    id: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldTestShieldTestsIntSensorIdStringBitmapLength
   */
  export interface GetShieldTestShieldTestsIntSensorIdStringBitmapLengthParams {

    /**
     * Sensor ID
     */
    sensorId: number;

    /**
     * Bitmap of experiments
     */
    bitmap: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldTestCounts
   */
  export interface GetShieldTestCountsParams {

    /**
     * List of organization ids
     */
    organizationIds?: Array<number>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of operating statuses: 0=FAT, 1=Customer, 2=ServiceOps, 3=Decommissioned, 4=InHouse
     */
    operatingStatuses?: Array<number>;

    /**
     * Start date (ISO 8601)
     */
    startDate?: string;

    /**
     * End date (ISO 8601)
     */
    endDate?: string;

    /**
     * Include scheduled/manual Shield tests
     */
    includeScheduled?: boolean;

    /**
     * Include continuous parameters
     */
    includeContinuous?: boolean;

    /**
     * Include data from 3rd party labs
     */
    include3rdParty?: boolean;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postDataQualityMetricsShieldTestsDataQualityIntOperatingStatus
   */
  export interface PostDataQualityMetricsShieldTestsDataQualityIntOperatingStatusParams {
    payload: Shield_tests_data_quality;
    operatingStatus: number;
  }

  /**
   * Parameters for getShieldTestDateHistogram
   */
  export interface GetShieldTestDateHistogramParams {

    /**
     * List of organization ids
     */
    organizationIds?: Array<number>;

    /**
     * List of sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of operating statuses: 0=FAT, 1=Customer, 2=ServiceOps, 3=Decommissioned, 4=InHouse
     */
    operatingStatuses?: Array<number>;

    /**
     * Start date (ISO 8601)
     */
    startDate?: string;

    /**
     * End date (ISO 8601)
     */
    endDate?: string;

    /**
     * Include scheduled/manual Shield tests
     */
    includeScheduled?: boolean;

    /**
     * Include continuous parameters
     */
    includeContinuous?: boolean;

    /**
     * Include data from 3rd party labs
     */
    include3rdParty?: boolean;

    /**
     * Time zone examples: "UTC", "UTC-01:00", "UTC+10:30"
     */
    timeZone?: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getTriggerEolAction
   */
  export interface GetTriggerEolActionParams {
    sensorId: string;
    eolAction: string;
  }

  /**
   * Parameters for getEolActionStatus
   */
  export interface GetEolActionStatusParams {
    sensorId: string;
    eolAction: string;
    parameter: string;
    status: string;
  }

  /**
   * Parameters for getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalOrganizationIntOrganizationIdStringElement
   */
  export interface GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalOrganizationIntOrganizationIdStringElementParams {

    /**
     * Start date epoch
     */
    startDate: number;

    /**
     * End date epoch
     */
    endDate: number;

    /**
     * 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'
     */
    interval: string;

    /**
     * Organization ID
     */
    organizationId: number;
    element: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalSensorIntSensorIdStringElement
   */
  export interface GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalSensorIntSensorIdStringElementParams {

    /**
     * Start date epoch
     */
    startDate: number;

    /**
     * End date epoch
     */
    endDate: number;

    /**
     * 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'
     */
    interval: string;

    /**
     * Sensor ID
     */
    sensorId: number;
    element: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalStringElement
   */
  export interface GetShieldResultHistoryAggregatesShieldTestsHistoryIntStartDateIntEndDateStringIntervalStringElementParams {

    /**
     * Start date epoch
     */
    startDate: number;

    /**
     * End date epoch
     */
    endDate: number;

    /**
     * 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'
     */
    interval: string;
    element: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for persistManualValuesForTests
   */
  export interface PersistManualValuesForTestsParams {
    payload: Shield_tests_manual;
    sensorId: number;
    testNumber: number;
    operatingStatus: number;
  }

  /**
   * Parameters for getShieldResultsShieldTestsResultsIntStartDateIntEndDateOrganizationIntOrganizationIdStringElementStringMethodIntPageIntPerPage
   */
  export interface GetShieldResultsShieldTestsResultsIntStartDateIntEndDateOrganizationIntOrganizationIdStringElementStringMethodIntPageIntPerPageParams {

    /**
     * Start date epoch
     */
    startDate: number;

    /**
     * End date epoch
     */
    endDate: number;

    /**
     * Organization ID
     */
    organizationId: number;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;
    element: string;
    method: string;
  }

  /**
   * Parameters for getShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPage
   */
  export interface GetShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageParams {

    /**
     * Start date epoch
     */
    startDate: number;

    /**
     * End date epoch
     */
    endDate: number;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;
    waterSourceId: number;
    element: string;
    method: string;

    /**
     * Water source ID
     */
    sensorId?: string;
  }

  /**
   * Parameters for getShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageIntOperatingStatus
   */
  export interface GetShieldResultsShieldTestsResultsIntStartDateIntEndDateWaterSourceIntWaterSourceIdStringElementStringMethodIntPageIntPerPageIntOperatingStatusParams {

    /**
     * Start date epoch
     */
    startDate: number;

    /**
     * End date epoch
     */
    endDate: number;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * Operating status
     */
    operatingStatus: number;
    waterSourceId: number;
    element: string;
    method: string;

    /**
     * Water source ID
     */
    sensorId?: string;
  }

  /**
   * Parameters for getShieldResultsShieldTestsResultsIntStartDateIntEndDateStringElementStringMethodIntPageIntPerPage
   */
  export interface GetShieldResultsShieldTestsResultsIntStartDateIntEndDateStringElementStringMethodIntPageIntPerPageParams {

    /**
     * Start date epoch
     */
    startDate: number;

    /**
     * End date epoch
     */
    endDate: number;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;
    element: string;
    method: string;
  }

  /**
   * Parameters for getShieldDatesSignatures
   */
  export interface GetShieldDatesSignaturesParams {
    waterSourceId: string;
    operatingStatus: string;
  }

  /**
   * Parameters for getShieldDates
   */
  export interface GetShieldDatesParams {
    sensorId: string;
    operatingStatus: string;
  }

  /**
   * Parameters for shieldTestsRawData
   */
  export interface ShieldTestsRawDataParams {
    sensorId: string;
    testNumber: number;
    operatingStatus: number;
  }

  /**
   * Parameters for shieldTests
   */
  export interface ShieldTestsParams {
    sensorId: number;
    testNumber: string;
    operatingStatus: number;
    page: number;
    perPage: number;
  }
}

export { ShieldTestsService }
