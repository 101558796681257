/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DropdownComponent } from './dropdown/dropdown.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UserMenuComponent } from './layout/user-menu/user-menu.component';
import { WaterTankComponent } from './water-tank/water-tank.component';
import { PipesModule } from '../../pipes/pipes.module';
import { NumericInputDirective } from '../../../../../web/src/app/directives/numeric-input.directive';
import { OutsideClickCaptureDirective } from '../../../../../web/src/app/directives/outsideClickCapture';
import { DateFormatPipe } from '../../../../../web/src/app/directives/datePipe.directive'
import {HighchartsChartModule} from 'highcharts-angular';
import {KetosLibModule} from '../../ketos-lib.module';
import {MatListModule} from '@angular/material/list'
import {MatIconModule} from '@angular/material/icon'
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import{MatTooltipModule} from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {IonicModule} from '@ionic/angular';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    RouterModule,
    HighchartsChartModule,
    KetosLibModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatSidenavModule,
    MatTooltipModule,
    MatSnackBarModule,
    TooltipModule.forRoot(),
    NgbTooltipModule,
    IonicModule,
  ],
  declarations: [
    OutsideClickCaptureDirective,
    DateFormatPipe,
    NumericInputDirective,
    HeaderComponent,
    FooterComponent,
    DropdownComponent,
    //ChordLegacyComponent,
    NotificationsComponent,
    UserMenuComponent,
    WaterTankComponent,
    //ModalComponent,
  ],
  exports: [
    OutsideClickCaptureDirective,
    DateFormatPipe,
    DropdownComponent,
    //ChordLegacyComponent,
    FormsModule,
    //ModalComponent,
    NotificationsComponent,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
    HeaderComponent,
    FooterComponent,
    UserMenuComponent,
    WaterTankComponent,
    HighchartsChartModule,
    KetosLibModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    TooltipModule,
    NgbTooltipModule,
    MatSnackBarModule
  ]
})
export class SharedModule {
}
