/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { environment } from '../../../../../environments/environment';

export class AppStorage {
  public static getKey(key: string): string {
    return `${environment.key}-${key}`;
  }

  public static getItem(key: string): string | null {
    return localStorage.getItem(`${environment.key}-${key}`);
  }

  public static setItem(key: string, data: string) {
    localStorage.setItem(`${environment.key}-${key}`, data);
  }

  public static removeItem(key: string) {
    localStorage.removeItem(`${environment.key}-${key}`);
  }
}
