import {Injectable} from '@angular/core';
import {Group_master} from '../../../../api/src/lib/models/group-_master';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {Update_group_parser} from '../../../../api/src/lib/models/update-_group-_parser';
import {map} from 'rxjs/operators';
import {UserService} from './user.service';
import {GroupsService} from '../../../../api/src/lib/services';
import {KetosSocketActionEnum, KetosSocketEventEnum, KetosSocketService} from './ketos-socket.service';
import {CachedBaseService} from './cached-base.service';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GroupCachedService extends CachedBaseService {
  subject = new ReplaySubject<Group_master[]>(1);
  models: Group_master[];
  dictById: {[key: number]: Group_master} = {};

  socketSub: Subscription;
  constructor(private groupsService: GroupsService,
              private ketosSocketService: KetosSocketService,
              public authService: AuthService) {
    super();
    this.setupSubject();
  }

  setupSubject() {
    if (!this.socketSub && this.authService.getUser()?.organization_id) {
      this.socketSub = this.ketosSocketService.getSubjectForRoom(this.authService.getUser().organization_id, KetosSocketEventEnum.group_master)
        .subscribe(data => {
          this.updateFromSocket(data);
        });
    }
  }

  getCached(live = true, forceRefresh = false): Observable<Group_master[]> {
    return this.getCachedBase(live, forceRefresh);
  }

  fetchData(): Observable<Group_master[]> {
    return this.groupsService.GetGroupDetails({organizationId: UserService.getCurrentOrgId()})
      .pipe(
        map(res => {
          this.models = res;
          this.subject.next(this.models);
          res.map( group => {
            this.dictById[group.id] = group;
          });
          return this.models;
        })
      );
  }

    createGroup(group: Group_master): Observable<any> {
    return this.groupsService.CreateAGroupUsingPostAPI(group);
  }

  modifyGroup(oldGroup: Group_master, newGroup: Group_master): Observable<string> {
    oldGroup['new_group_name'] = newGroup.group_name;
    return this.groupsService.UpdateGroupDetails(oldGroup as Update_group_parser);
  }

  deleteGroup(id: number): Observable<string> {
    return this.groupsService.DeleteAGroup({id: id})
  }

  // live logic
  updateFromSocket(data: {action: KetosSocketActionEnum, event_type: KetosSocketEventEnum, organization_id: number, room: string, sensor_id: string, message: {group_master: Group_master} }) {
    if (data.action === KetosSocketActionEnum.write) {
      this.models.push(data.message.group_master);
      this.subject.next(this.models);
    } else if (data.action === KetosSocketActionEnum.update) {
      const idx = this.models.findIndex(obj => obj.id === data.message.group_master.id);
      if (idx !== -1) {
        this.models[idx] = data.message.group_master;
      }
      this.subject.next(this.models);
    } else if ( data.action === KetosSocketActionEnum.delete) {
      const idx = this.models.findIndex(obj => obj.id === data.message.group_master.id);
      if (idx !== -1) {
        this.models.splice(idx, 1);
      }
      this.subject.next(this.models);
    }
  }
}
