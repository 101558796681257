/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlankComponent } from './blank/blank.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [BlankComponent]
})
export class BlankModule { }
