import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {UiPlatformService} from "../../services/ui-platform.service";

@Injectable({
  providedIn: 'root'
})
export class PlatformAuthGuard implements CanActivate {
  constructor(private router: Router, private  uiPlatformService: UiPlatformService) {}

  canActivate(): boolean {
    const redirectPath = this.uiPlatformService.webPlatform ? 'dashboard' : 'home';
    this.router.navigate([redirectPath]);
    return false;
  }
}
