import {Injectable} from '@angular/core';
import {OrganizationService} from '../../../../api/src/lib/services/organization.service';
import {Observable} from 'rxjs';
import {Organization_settings_show_partial_results_out} from '../../../../api/src/lib/models/organization-_settings-_show-_partial-_results-_out';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganizationSettingsService {
  public displayUnitsDict: {[key: string]: boolean};

  constructor(private organizationService: OrganizationService,
  ) {
  }

  getPartialResultsEnabled(): Observable<boolean> {
    return this.organizationService.GetOrganizationSettingShowPartialResults()
      .pipe(
        map(res => {
          if (typeof res === 'object') {
            const partialSetting = res as unknown as Organization_settings_show_partial_results_out;
            if (partialSetting && partialSetting.key === 'cp_show_partial_results' && partialSetting.value === true) {
              return true;
            } else {
              return false;
            }
          }
        }));
  }

  getDisplayUnitsMap(): Observable<{[key:string]: boolean}> {
    return this.organizationService.GetOrganizationSettingDisplayUnitPpbOrPpm()
      .pipe(
        map((res: any) => {
          if (res && res.key === 'cp_display_unit' && res.value) {
            try {
              this.displayUnitsDict = JSON.parse(res.value);
            } catch (e) {
              // console.log('JSON error on unit setting decode:', e);
            }
            if (!this.displayUnitsDict || typeof this.displayUnitsDict !== 'object') {
              this.displayUnitsDict = {'ppm': true, '°F': true};
            } else {
              if (!this.displayUnitsDict['ppm'] && !this.displayUnitsDict['ppb']) {
                this.displayUnitsDict['ppm'] = true;
              }
              if (!this.displayUnitsDict['°F'] && !this.displayUnitsDict['°C']) {
                this.displayUnitsDict['°F'] = true;
              }
            }
            return this.displayUnitsDict;
          }
          this.displayUnitsDict = {'ppm': true, '°F': true};
          return this.displayUnitsDict;
        })
      );
  }

  setDisplayUnit(units: {[key: string]: boolean}): Observable<any> {
    return this.organizationService.PutOrganizationSettingDisplayUnitPpbOrPpm({value: JSON.stringify(units)})
      .pipe(
        map(res => {
          this.displayUnitsDict = units;
        })
      )
    // return this.organizationService.PutOrganizationSettingDisplayUnitPpbOrPpm({value: 'ppm'})
  }
}
