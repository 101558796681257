/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppComponent } from './app.component';
import {Router, RouterModule} from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../../../ketos-lib/src/lib/services/auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppService } from '../../../ketos-lib/src/lib/services/legacy-services/app.service';
import { AppRoutes } from './app.routes';
import { SharedModule } from '../../../ketos-lib/src/lib/components/shared/shared.module'
import { HTTPListener } from './core/api/services/httpstatus.service';
import {MatDialogModule} from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule } from '@ionic/angular';
import { LicenseGuard } from './guards/license-guard.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ApiModule } from '../../../api/src/lib/api.module';
import { environment } from '../../../../environments/environment';
import {OperationalIntelligenceModule} from "../../../ketos-lib/src/lib/pages/operational-intelligence/operational-intelligence.module";
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import {HTTPStatus} from '../../../ketos-lib/src/lib/services/http-status.service';
import {TestNotificationService} from '../../../ketos-lib/src/lib/services/test.notification.service'
import {UserNotificationService} from '../../../ketos-lib/src/lib/services/user.notification.service'
import * as Sentry from "@sentry/angular";
import { AuthGuard } from '../../../ketos-lib/src/lib/guards/auth-guard.service';
import {MainModule} from "../../../ketos-lib/src/lib/pages/main/main.module";
import {BlankModule} from "../../../ketos-lib/src/lib/pages/blank/blank.module";


const config: SocketIoConfig = { url: environment.CONSTANTS.API_NG, options: {} };
const RxJS_Services = [HTTPListener, HTTPStatus];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        ApiModule.forRoot({ rootUrl: environment.CONSTANTS.API_NG }),
        BlankModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        MainModule,
        MatDialogModule,
        NgIdleKeepaliveModule.forRoot(),
        IonicModule.forRoot(),
        ReactiveFormsModule,
        RouterModule.forRoot(AppRoutes, { useHash: true }),
        SharedModule,
        SocketIoModule.forRoot(config),
        OperationalIntelligenceModule,
    ],
    providers: [
        AppService,
        AuthService,
        AuthGuard,
        LicenseGuard,
        TestNotificationService,
        UserNotificationService,
        ...RxJS_Services,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HTTPListener,
            multi: true
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
