/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as cloneDeep from 'lodash/cloneDeep';
import {isDate} from 'moment';
import * as moment from "moment";
import { CalendarValueInterface } from '../calendar.page';
import { CALENDAR } from '../models/calendar.constant';

@Component({
  selector: 'lib-inline-calendar',
  templateUrl: './inline-calendar.component.html',
  styleUrls: ['./inline-calendar.component.scss']
})
export class InlineCalendarComponent implements OnInit {
  readonly msInterval = 15 * 60 * 1000; // 15 minute intervals in minute selector

  private _value: CalendarValueInterface | Date;
  @Input() set value (value: CalendarValueInterface) {
    this._value = value;
    this.init();
  }
  get value(): CalendarValueInterface {
    if (this.instanceOfCalendarValueInterface(this._value)) {
      return {
        min: this.roundToInterval(this._value.min, this.msInterval),
        max: this.roundToInterval(this._value.max, this.msInterval)
      };
    } else if (isDate(this._value)) {
      return {
        min: this.roundToInterval(this._value, this.msInterval),
        max: null
      };
    } else {
      return {
        min: null,
        max: null
      }
    }
  }

  private _selectRange: boolean;
  @Input() set selectRange(value: boolean) {  // note: if selectRange = false then typeof value = Date
    this._selectRange = value;
    this.init();
  }
  get selectRange() {
    return this._selectRange;
  }

  private _endTimeIndefinite: boolean;
  @Input() set endTimeIndefinite(value: boolean) {
    this._endTimeIndefinite = value;
    this.init();
  }
  get endTimeIndefinite(): boolean {
    return this._endTimeIndefinite;
  }
  @Input() forceMinutes: boolean;
  @Input() hidePreviousDates: boolean;
  @Input() hideFutureDates: boolean;
  @Input() isCalendarVisible: boolean;
  @Input() emitOnDateChange: boolean;
  @Input() hideMinutes: boolean;
  @Input() showHours: boolean; // if (true && hideMinutes === true && selectRange === false) show hours only
  @Input() hideFromTo: boolean;
  private _hideWeekends: boolean;
  @Input() set hideWeekends(value: boolean) {
    this._hideWeekends = value;
    this.init();
  }
  get hideWeekends() {
    return this._hideWeekends;
  }
  @Output() isCalendarVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() calendarRight: boolean;
  @Output() dateSelected: EventEmitter<CalendarValueInterface | Date> =
    new EventEmitter<CalendarValueInterface | Date>();

  months = CALENDAR.months;
  daysName = CALENDAR.days;

  dateIntervalFlag = 'max';
  fromTimestamp = new Date().toISOString();
  fromMinTimestamp = '1919';
  untilTimestamp = new Date().toISOString();
  untilMinTimestamp = '1919';
  limitMaxTimestamp = '2119';

  month;
  monthName;
  year;
  weeks;

  constructor() { }

  utcTimestampToLocalTImestamp = (timeStamp: string) => {
    return moment.utc(timeStamp).local().format();
  }

  public openFromPicker(event?) {
    if (this.hidePreviousDates) {
      const tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const fromDate = new Date(this.fromTimestamp);
      if (fromDate < tomorrow) {
        const date = this.roundToInterval(new Date(), this.msInterval);
        this.fromMinTimestamp = this.toIonicSpecificTimestamp(date);
      } else {
        this.fromMinTimestamp = '1919';
      }
    }
  }

  public openUntilPicker(event?, limitFromDate?: Date) {
    if (this.hidePreviousDates) {
      const dayAfterStart = new Date(limitFromDate || this.fromTimestamp);
      dayAfterStart.setHours(0, 0, 0, 0);
      dayAfterStart.setDate(dayAfterStart.getDate() + 1);
      const end = new Date(this.untilTimestamp);
      if (end < dayAfterStart) {
        const date = this.roundToInterval(new Date(limitFromDate || this.fromTimestamp), this.msInterval);
        date.setMinutes(date.getMinutes() + 1);
        this.untilMinTimestamp = this.toIonicSpecificTimestamp(date);
      } else {
        this.untilMinTimestamp = '1919';
      }
    }
  }

  ngOnInit() {
    // this.openingFromPicker();
    if (this.value && this.value.min) {
      this.month = this.value.min.getMonth();
      this.year = this.value.min.getFullYear();
    } else {
      this.month = new Date().getMonth();
      this.year = new Date().getFullYear();
    }

    this.monthName = this.months[this.month];
    this.init();
  }

  init() {
    // console.log('initing', this.value)
    if (this.selectRange) {
      let fromDate = new Date(this.fromTimestamp);
      let untilDate = new Date(this.untilTimestamp);
      if (this.endTimeIndefinite) {
        untilDate = new Date(8640000000000000);
      }
      if (isDate(this.value.min)) {
        fromDate = new Date(this.value.min.getTime());
      }
      if (isDate(this.value.max)) {
        untilDate = new Date(this.value.max);
      }
      fromDate = this.roundToInterval(fromDate, this.msInterval);
      untilDate = this.roundToInterval(untilDate, this.msInterval);
      if (fromDate > untilDate) {
        const tmp = fromDate;
        fromDate = untilDate;
        untilDate = tmp;
      }
      this.fromTimestamp = fromDate.toISOString();
      this.untilTimestamp = untilDate.toISOString();
    } else {
      let fromDate = new Date(this.fromTimestamp);
      if (this.value) {
        if (isDate(this.value.min)) {
          fromDate = new Date(this.value.min.getTime());
        }
      }
      fromDate = this.roundToInterval(fromDate, this.msInterval);
      if (this.hidePreviousDates && this.roundToInterval(new Date(), this.msInterval) > fromDate) {
        fromDate = this.roundToInterval(new Date(), this.msInterval);
      }
      this.fromTimestamp = fromDate.toISOString();
    }
    this.buildMonth();
  }

  buildMonth() {
    this.weeks = [];

    const day = CALENDAR.day;
    let i = 0;
    let j;
    let haveDays = true;
    let startDay = new Date(this.year, this.month, 1).getDay(); // get day of the week for first day of the month
    const daysInMonth = [
      31, (((this.year % 4 === 0) && (this.year % 100 !== 0)) || (this.year % 400 === 0)) ? 29 : 28,
      31, 30, 31, 30, 31, 31, 30, 31, 30, 31
    ];
    const dayModel = {
      day: day,
      month: this.month,
      year: this.year,
      selected: false,
      first: false,
      last: false,
      time: null
    };

    const fromDate = new Date(this.fromTimestamp);
    fromDate.setHours(0, 0, 0, 0);
    let untilDate = new Date(this.untilTimestamp);
    untilDate.setHours(0, 0, 0, 0);
    if (this.endTimeIndefinite) {
      untilDate = new Date(8640000000000000);
    }

    while (haveDays) {
      this.weeks[i] = [];

      for (j = 0; j < 7; j = j + 1) {
        if (!i) {
          if (j === startDay) {
            this.weeks[i][j] = cloneDeep(dayModel);
            this.weeks[i][j].time = new Date(this.year, this.month, dayModel.day).getTime();

            dayModel.day++;
            startDay++;
          } else {
            this.weeks[i][j] = '';
          }
        } else {
          if (dayModel.day <= daysInMonth[this.month]) {
            this.weeks[i][j] = cloneDeep(dayModel);
            this.weeks[i][j].time = new Date(this.year, this.month, dayModel.day).getTime();
            // if (this.weeks[i][j].time >= fromDate.getTime() && this.weeks[i][j].time <= untilDate.getTime()) {
            //   this.weeks[i][j].selected = true;
            // }
            dayModel.day++;
          } else {
            this.weeks[i][j] = '';
            haveDays = false;
          }
        }

        if (dayModel.day > daysInMonth[this.month]) {
          haveDays = false;
        }
      }
      i += 1;
    }

    if (this.selectRange) {
      // find the first and last dates selected for styling
      this.weeks.forEach((week) => {
        week.forEach((item) => {
          if (item.time >= fromDate.getTime() && item.time <= untilDate.getTime()) {
            item.selected = true;
          }
          if (item.day === fromDate.getDate() && item.month === fromDate.getMonth() && item.year === fromDate.getFullYear()) {
            item.first = true;
          }
          if (item.day === untilDate.getDate() && item.month === untilDate.getMonth() && item.year === untilDate.getFullYear()) {
            item.last = true;
          }
        });
      });
    } else {
      this.weeks.map(week => {
        week.map(d => {
          if (d !== '') {
            d.selected = d.time === fromDate.getTime();
          }
        });
      });
    }

    const ms = new Date().setHours(0, 0, 0, 0)
    this.weeks.map(week => {
      week.map(d => {
        if (d !== '') {
          d.opacity =
            this.hidePreviousDates && d.time < ms ||
            this.hideFutureDates && d.time > ms ||
            this.hideWeekends && (week.indexOf(d) === 0 || week.indexOf(d) === 6);
        }
      });
    });
  }

  updateSelectedDay(day) {
    if (!day) {
      return;
    }
    if (this.selectRange) {

      const fromDate = new Date(this.fromTimestamp);
      const fromHours = fromDate.getHours();
      const fromMinutes = fromDate.getMinutes();

      const untilDate = new Date(this.untilTimestamp);
      const untilHours = untilDate.getHours();
      const untilMinutes = untilDate.getMinutes();

      const newSelection = new Date(day.year, day.month, day.day);

      let prevDate;
      if (this.dateIntervalFlag === 'from') {
        prevDate = fromDate;
        newSelection.setHours(untilDate.getHours(), untilDate.getMinutes(), 0, 0);
      } else {
        prevDate = untilDate;
        newSelection.setHours(fromDate.getHours(), fromDate.getMinutes(), 0, 0);
      }

      if (newSelection > prevDate) {
        prevDate.setHours(fromHours, fromMinutes, 0, 0);
        this.fromTimestamp = prevDate.toISOString();
        newSelection.setHours(untilHours, untilMinutes, 0, 0);
        this.untilTimestamp = newSelection.toISOString();
        this.dateIntervalFlag = 'until';
      } else {
        newSelection.setHours(fromHours, fromMinutes, 0, 0);
        this.fromTimestamp = newSelection.toISOString();
        prevDate.setHours(untilHours, untilMinutes, 0, 0);
        this.untilTimestamp = prevDate.toISOString();
        this.dateIntervalFlag = 'from';
      }

      if (this.hidePreviousDates) {
        const nextValid = this.roundToInterval(new Date(), this.msInterval);
        const fromD = new Date(this.fromTimestamp);
        if (fromD < nextValid) {
          this.fromTimestamp = nextValid.toISOString();
        }
      }
    } else {
      const newSelection = new Date(this.fromTimestamp);
      newSelection.setFullYear(this.year);
      newSelection.setMonth(day.month);
      newSelection.setDate(day.day);

      const nextValid = this.roundToInterval(new Date(), this.msInterval);
      // const fromD = new Date(this.fromTimestamp);
      if (newSelection < nextValid) {
        this.fromTimestamp = nextValid.toISOString();
      } else {
        this.fromTimestamp = newSelection.toISOString();
      }
    }
    this.buildMonth();
    // @TODO: reimplement
    // if (this.emitOnDateChange === true) {
      this.selectDate();
    // }
  }

  nextMonth() {
    this.month = this.month + 1;

    if (this.month > 11) {
      this.month = 0;

      this.year = this.year + 1;
    }
    this.monthName = this.months[this.month];
    this.buildMonth();
  }

  prevMonth() {
    const now = new Date();
    if (this.hidePreviousDates && this.year <= now.getFullYear() && this.month <= now.getMonth()) {
      return;
    }
    this.month = this.month - 1;
    if (this.month < 0) {
      this.month = 11;

      this.year = this.year - 1;
    }
    this.monthName = this.months[this.month]; // months[this.month]
    this.buildMonth();
  }

  nextYear() {
    this.year = this.year + 1;
    this.buildMonth();
  }

  prevYear() {
    const now = new Date();
    if (this.hidePreviousDates && this.year - 1 < now.getFullYear()) { // currentdate.year
      return;
    }
    this.year = this.year - 1;

    this.buildMonth();
  }

  selectDate(event?: any, fromTO?: string) {
    if (this.selectRange === true) {
      if (this.fromTimestamp > this.untilTimestamp) {
        const tmp = this.untilTimestamp;
        this.untilTimestamp = this.fromTimestamp;
        this.fromTimestamp = tmp;
      }
      if (this.endTimeIndefinite) {
        this.dateSelected.emit({min: new Date(this.fromTimestamp), max: null});
      } else {
        this.dateSelected.emit({min: new Date(this.fromTimestamp), max: new Date(this.untilTimestamp)});
      }
    } else {
      if(event && fromTO &&fromTO === 'minSelection'){
        this.dateSelected.emit(new Date(this.untilTimestamp));
      }else if(event && fromTO &&fromTO === 'dateSelection'){
        this.untilTimestamp = event.detail.value;
        this.dateSelected.emit(new Date(event.detail.value));
      } else if (!event && !fromTO) {
        this.dateSelected.emit(new Date(this.fromTimestamp))
      }
    }
    // console.log('emit:', {min: new Date(this.fromTimestamp), max: new Date(this.untilTimestamp)}, this.value);
  }

  fromMinutesUpdated(hoursMinutesInLocal: string) {
    const minutesDate = moment(hoursMinutesInLocal).utc().toDate();
    const tmpDate = new Date(this.fromTimestamp);
    tmpDate?.setHours(minutesDate.getHours(), minutesDate.getMinutes());
    this.fromTimestamp = tmpDate.toISOString();
    this.selectDate({detail: {value: this.fromTimestamp}});
  }

  untilMinutesUpdated(hoursMinutesInLocal: string) {
    const minutesDate = moment(hoursMinutesInLocal).utc().toDate();
    const tmpDate = new Date(this.untilTimestamp);
    tmpDate?.setHours(minutesDate.getHours(), minutesDate.getMinutes());
    this.untilTimestamp = tmpDate.toISOString();
    this.selectDate({detail: {value: this.untilTimestamp}});
  }

  // Example: interval = 15 * 60 * 1000; // 15 minutes in milliseconds
  roundToInterval(date: Date, interval: number): Date {
    return new Date(Math.ceil(date.getTime() / interval) * interval);
  }

  instanceOfCalendarValueInterface(object: any): object is CalendarValueInterface {
    return object !== undefined && object !== null && 'min' in object && 'max' in object;
  }

  // ion-datetime min/max looses timezone offset
  toIonicSpecificTimestamp(date: Date): string {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds())
    ).toISOString();
  }
}
