/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Cx_update } from '../models/cx-_update';

/**
 * CX Dashboard related operations
 */
@Injectable({
  providedIn: 'root',
})
class CxDashboardService extends __BaseService {
  static readonly getCxDashboardExtPath = '/cx_dashboard/ext';
  static readonly getCxDashboardCxDashboardGetStringOrganizationIdStringCsvStringTimezonePath = '/cx_dashboard/get/{organization_id}/{csv}/{timezone}';
  static readonly getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPagePath = '/cx_dashboard/get/{organization_id}/{page}/{per_page}';
  static readonly getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValuePath = '/cx_dashboard/get/{organization_id}/{page}/{per_page}/search/{sort_column}/{order}/{search_column}/{search_value}';
  static readonly getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSortStringSortColumnStringOrderPath = '/cx_dashboard/get/{organization_id}/{page}/{per_page}/sort/{sort_column}/{order}';
  static readonly UpdateCXDashboardPath = '/cx_dashboard/{sensor_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  getCxDashboardExtResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/cx_dashboard/ext`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  getCxDashboardExt(): __Observable<null> {
    return this.getCxDashboardExtResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdStringCsvStringTimezoneParams` containing the following parameters:
   *
   * - `organization_id`: Organization ID or "all"
   *
   * - `csv`:
   *
   * - `timezone`:
   */
  getCxDashboardCxDashboardGetStringOrganizationIdStringCsvStringTimezoneResponse(params: CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdStringCsvStringTimezoneParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/cx_dashboard/get/${encodeURIComponent(String(params.organizationId))}/${encodeURIComponent(String(params.csv))}/${encodeURIComponent(String(params.timezone))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdStringCsvStringTimezoneParams` containing the following parameters:
   *
   * - `organization_id`: Organization ID or "all"
   *
   * - `csv`:
   *
   * - `timezone`:
   */
  getCxDashboardCxDashboardGetStringOrganizationIdStringCsvStringTimezone(params: CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdStringCsvStringTimezoneParams): __Observable<null> {
    return this.getCxDashboardCxDashboardGetStringOrganizationIdStringCsvStringTimezoneResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageParams` containing the following parameters:
   *
   * - `organization_id`: Organization ID or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   */
  getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageResponse(params: CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/cx_dashboard/get/${encodeURIComponent(String(params.organizationId))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageParams` containing the following parameters:
   *
   * - `organization_id`: Organization ID or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   */
  getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPage(params: CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageParams): __Observable<null> {
    return this.getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueParams` containing the following parameters:
   *
   * - `organization_id`: Organization ID or "all"
   *
   * - `sort_column`: column on which we need to sort, 'none' if no sorting
   *
   * - `search_column`: column on which we need to search
   *
   * - `search_value`: value to match
   *
   * - `page`:
   *
   * - `per_page`:
   *
   * - `order`:
   */
  getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueResponse(params: CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;







    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/cx_dashboard/get/${encodeURIComponent(String(params.organizationId))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}/search/${encodeURIComponent(String(params.sortColumn))}/${encodeURIComponent(String(params.order))}/${encodeURIComponent(String(params.searchColumn))}/${encodeURIComponent(String(params.searchValue))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueParams` containing the following parameters:
   *
   * - `organization_id`: Organization ID or "all"
   *
   * - `sort_column`: column on which we need to sort, 'none' if no sorting
   *
   * - `search_column`: column on which we need to search
   *
   * - `search_value`: value to match
   *
   * - `page`:
   *
   * - `per_page`:
   *
   * - `order`:
   */
  getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValue(params: CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueParams): __Observable<null> {
    return this.getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSortStringSortColumnStringOrderParams` containing the following parameters:
   *
   * - `organization_id`: Organization ID or "all"
   *
   * - `sort_column`: column on which we need to sort
   *
   * - `order`: asc/desc
   *
   * - `page`:
   *
   * - `per_page`:
   */
  getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSortStringSortColumnStringOrderResponse(params: CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSortStringSortColumnStringOrderParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/cx_dashboard/get/${encodeURIComponent(String(params.organizationId))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}/sort/${encodeURIComponent(String(params.sortColumn))}/${encodeURIComponent(String(params.order))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSortStringSortColumnStringOrderParams` containing the following parameters:
   *
   * - `organization_id`: Organization ID or "all"
   *
   * - `sort_column`: column on which we need to sort
   *
   * - `order`: asc/desc
   *
   * - `page`:
   *
   * - `per_page`:
   */
  getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSortStringSortColumnStringOrder(params: CxDashboardService.GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSortStringSortColumnStringOrderParams): __Observable<null> {
    return this.getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSortStringSortColumnStringOrderResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CxDashboardService.UpdateCXDashboardParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`: Sensor ID
   */
  UpdateCXDashboardResponse(params: CxDashboardService.UpdateCXDashboardParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/cx_dashboard/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CxDashboardService.UpdateCXDashboardParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `sensor_id`: Sensor ID
   */
  UpdateCXDashboard(params: CxDashboardService.UpdateCXDashboardParams): __Observable<null> {
    return this.UpdateCXDashboardResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CxDashboardService {

  /**
   * Parameters for getCxDashboardCxDashboardGetStringOrganizationIdStringCsvStringTimezone
   */
  export interface GetCxDashboardCxDashboardGetStringOrganizationIdStringCsvStringTimezoneParams {

    /**
     * Organization ID or "all"
     */
    organizationId: string;
    csv: string;
    timezone: string;
  }

  /**
   * Parameters for getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPage
   */
  export interface GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageParams {

    /**
     * Organization ID or "all"
     */
    organizationId: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;
  }

  /**
   * Parameters for getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValue
   */
  export interface GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSearchStringSortColumnStringOrderStringSearchColumnStringSearchValueParams {

    /**
     * Organization ID or "all"
     */
    organizationId: string;

    /**
     * column on which we need to sort, 'none' if no sorting
     */
    sortColumn: string;

    /**
     * column on which we need to search
     */
    searchColumn: string;

    /**
     * value to match
     */
    searchValue: string;
    page: number;
    perPage: number;
    order: string;
  }

  /**
   * Parameters for getCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSortStringSortColumnStringOrder
   */
  export interface GetCxDashboardCxDashboardGetStringOrganizationIdIntPageIntPerPageSortStringSortColumnStringOrderParams {

    /**
     * Organization ID or "all"
     */
    organizationId: string;

    /**
     * column on which we need to sort
     */
    sortColumn: string;

    /**
     * asc/desc
     */
    order: string;
    page: number;
    perPage: number;
  }

  /**
   * Parameters for UpdateCXDashboard
   */
  export interface UpdateCXDashboardParams {
    payload: Cx_update;

    /**
     * Sensor ID
     */
    sensorId: number;
  }
}

export { CxDashboardService }
