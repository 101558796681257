import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthUserResponse} from './user.service';
import {AppStorage} from '../utils/AppStorage';
import {OrganizationSettingsInterface} from '../models/organization-settings.interface';
import {OrganizationsService} from '../../../../api/src/lib/services';
import {KetosSocketActionEnum, KetosSocketEventEnum, KetosSocketService} from './ketos-socket.service';
import {CachedBaseService} from './cached-base.service';
import {Organization_settings} from '../../../../api/src/lib/models/organization-_settings';

@Injectable({
  providedIn: 'root'
})

export class OrganizationCustomParamsSettingsCachedService extends CachedBaseService {
  subject: ReplaySubject<OrganizationSettingsInterface>;
  model: OrganizationSettingsInterface;
  socketSub: Subscription;

  constructor(
    private organizationsService: OrganizationsService,
    private ketosSocketService: KetosSocketService) {
    super();
    this.setupSubject();
  }

  setupSubject() {
    if (!this.socketSub) {
      const userData: AuthUserResponse = JSON.parse(AppStorage.getItem('user'));
      if(userData?.organization_id) {
        this.socketSub = this.ketosSocketService.getSubjectForRoom(userData.organization_id, KetosSocketEventEnum.organization_settings)
          .subscribe(data => {
            this.updateFromSocket(data);
          });
      }
    }
  }

  fetchData(): Observable<OrganizationSettingsInterface> {
    const userData: AuthUserResponse = JSON.parse(AppStorage.getItem('user'));
    // console.log(userData,'udata')
    const id = userData?.organization_id;
    const settings: OrganizationSettingsInterface = {} as unknown as OrganizationSettingsInterface;
    let url;
    url = this.organizationsService.getOrganizationSettings({organizationId: id});
    return url
      .pipe(
        map((res: any) => {
          settings.allSettings = res.items;
          settings.id = id;
          settings.isForecastEnabled = res.items.filter(item => item.key === 'cp_forecast_enabled')[0].value ? true : false;
          settings.isExternalLabDataEnabled = res.items.filter(item => item.key === 'cp_external_lab_enabled')[0].value ? true : false;
          settings.isMagnesiumEnabled = res.items.filter(item => item.key === 'cp_magnesium_enabled')[0].value ? true : false;
          settings.isLsiEnabled = res.items.filter(item => item.key === 'cp_lsi_enabled')[0].value ? true : false;
          settings.isPartialResultsEnabled = res.items.filter(item => item.key === 'cp_show_partial_results')[0].value ? true : false;
          settings.isNitratesHREnabled = res.items.filter(item => item.key === 'cp_nitrates_enabled')[0].value ? true : false;
          settings.isManganeseHrEnabled = res.items.filter(item => item.key === 'cp_manganese_hr_enabled')[0]?.value ? true : false
          settings.isZincHREnabled = res.items.filter(item => item.key === 'cp_zinc_hr_enabled')[0].value ? true : false;
          this.model = settings;
          this.subject.next(this.model);
          return this.model;
        })
      );
  }

  public getCached(live = true, forceRefresh = false): Observable<OrganizationSettingsInterface> {
    return this.getCachedBase(live, forceRefresh);
  }

  // live logic
  // tslint:disable-next-line:max-line-length
  updateFromSocket(data: { action: KetosSocketActionEnum, event_type: KetosSocketEventEnum, organization_id: number, room: string, sensor_id: string, message: { organization_settings: any } }) {
    if (data.action === KetosSocketActionEnum.update) {
        this.model.isExternalLabDataEnabled = data.message.organization_settings?.key === 'cp_external_lab_enabled' ? data.message.organization_settings.value : this.model.isExternalLabDataEnabled;
        this.model.isMagnesiumEnabled = data.message.organization_settings?.key === 'cp_magnesium_enabled' ? data.message.organization_settings.value : this.model.isMagnesiumEnabled;
        this.model.isLsiEnabled = data.message.organization_settings?.key === 'cp_lsi_enabled' ? data.message.organization_settings.value : this.model.isLsiEnabled;
        this.model.isPartialResultsEnabled = data.message.organization_settings?.key === 'cp_show_partial_results' ? data.message.organization_settings.value : this.model.isPartialResultsEnabled;
        this.model.isNitratesHREnabled = data.message.organization_settings?.key === 'cp_nitrates_enabled' ? data.message.organization_settings.value : this.model.isNitratesHREnabled;
        this.model.isManganeseHrEnabled = data.message.organization_settings?.key === 'cp_manganese_hr_enabled' ? data.message.organization_settings.value : this.model.isManganeseHrEnabled;
        this.model.isZincHREnabled = data.message.organization_settings?.key === 'cp_zinc_hr_enabled' ? data.message.organization_settings.value : this.model.isZincHREnabled;
        this.subject.next(this.model);
    }
  }
}
