/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Button_test_results } from '../models/button-_test-_results';
import { Button_test_result } from '../models/button-_test-_result';
import { Button_test_result_input } from '../models/button-_test-_result-_input';

/**
 * Button test result related operations
 */
@Injectable({
  providedIn: 'root',
})
class ButtonTestResultsService extends __BaseService {
  static readonly getButtonRestResultsPath = '/button_test_results';
  static readonly postButtonRestResultsPath = '/button_test_results';
  static readonly putButtonTestResultButtonTestResultsStringButtonTestResultIdPath = '/button_test_results/{button_test_result_id}';
  static readonly postButtonTestEmailCsvPath = '/button_test_results/{sensor_id}/email_csv';
  static readonly getButtonTestLatestResultsPath = '/button_test_results/{sensor_id}/latest';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get button test results
   * @param params The `ButtonTestResultsService.GetButtonRestResultsParams` containing the following parameters:
   *
   * - `ids`: List of button test result ids
   *
   * - `sensor_ids`: List of user sensor ids
   *
   * - `plc_lines`: List of user lines
   *
   * - `updated_by`: Email address of the updater
   *
   * - `success`: Filter by success status
   *
   * - `bits`: List of bit indices
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getButtonRestResultsResponse(params: ButtonTestResultsService.GetButtonRestResultsParams): __Observable<__StrictHttpResponse<Button_test_results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.sensorIds || []).forEach(val => {if (val != null) __params = __params.append('sensor_ids', val.toString())});
    (params.plcLines || []).forEach(val => {if (val != null) __params = __params.append('plc_lines', val.toString())});
    (params.updatedBy || []).forEach(val => {if (val != null) __params = __params.append('updated_by', val.toString())});
    if (params.success != null) __params = __params.set('success', params.success.toString());
    (params.bits || []).forEach(val => {if (val != null) __params = __params.append('bits', val.toString())});
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/button_test_results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Button_test_results>;
      })
    );
  }
  /**
   * Get button test results
   * @param params The `ButtonTestResultsService.GetButtonRestResultsParams` containing the following parameters:
   *
   * - `ids`: List of button test result ids
   *
   * - `sensor_ids`: List of user sensor ids
   *
   * - `plc_lines`: List of user lines
   *
   * - `updated_by`: Email address of the updater
   *
   * - `success`: Filter by success status
   *
   * - `bits`: List of bit indices
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getButtonRestResults(params: ButtonTestResultsService.GetButtonRestResultsParams): __Observable<Button_test_results> {
    return this.getButtonRestResultsResponse(params).pipe(
      __map(_r => _r.body as Button_test_results)
    );
  }

  /**
   * Create button test result
   * @param params The `ButtonTestResultsService.PostButtonRestResultsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postButtonRestResultsResponse(params: ButtonTestResultsService.PostButtonRestResultsParams): __Observable<__StrictHttpResponse<Button_test_result>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/button_test_results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Button_test_result>;
      })
    );
  }
  /**
   * Create button test result
   * @param params The `ButtonTestResultsService.PostButtonRestResultsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  postButtonRestResults(params: ButtonTestResultsService.PostButtonRestResultsParams): __Observable<Button_test_result> {
    return this.postButtonRestResultsResponse(params).pipe(
      __map(_r => _r.body as Button_test_result)
    );
  }

  /**
   * Update button test result
   * @param params The `ButtonTestResultsService.PutButtonTestResultButtonTestResultsStringButtonTestResultIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `button_test_result_id`: Primary key (id) of button test result
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putButtonTestResultButtonTestResultsStringButtonTestResultIdResponse(params: ButtonTestResultsService.PutButtonTestResultButtonTestResultsStringButtonTestResultIdParams): __Observable<__StrictHttpResponse<Button_test_result>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/button_test_results/${encodeURIComponent(String(params.buttonTestResultId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Button_test_result>;
      })
    );
  }
  /**
   * Update button test result
   * @param params The `ButtonTestResultsService.PutButtonTestResultButtonTestResultsStringButtonTestResultIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `button_test_result_id`: Primary key (id) of button test result
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  putButtonTestResultButtonTestResultsStringButtonTestResultId(params: ButtonTestResultsService.PutButtonTestResultButtonTestResultsStringButtonTestResultIdParams): __Observable<Button_test_result> {
    return this.putButtonTestResultButtonTestResultsStringButtonTestResultIdResponse(params).pipe(
      __map(_r => _r.body as Button_test_result)
    );
  }

  /**
   * Email and Download button test results CSV
   * @param sensor_id undefined
   */
  postButtonTestEmailCsvResponse(sensorId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/button_test_results/${encodeURIComponent(String(sensorId))}/email_csv`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Email and Download button test results CSV
   * @param sensor_id undefined
   */
  postButtonTestEmailCsv(sensorId: number): __Observable<null> {
    return this.postButtonTestEmailCsvResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get latest button test results
   * @param params The `ButtonTestResultsService.GetButtonTestLatestResultsParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getButtonTestLatestResultsResponse(params: ButtonTestResultsService.GetButtonTestLatestResultsParams): __Observable<__StrictHttpResponse<Button_test_results>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/button_test_results/${encodeURIComponent(String(params.sensorId))}/latest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Button_test_results>;
      })
    );
  }
  /**
   * Get latest button test results
   * @param params The `ButtonTestResultsService.GetButtonTestLatestResultsParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getButtonTestLatestResults(params: ButtonTestResultsService.GetButtonTestLatestResultsParams): __Observable<Button_test_results> {
    return this.getButtonTestLatestResultsResponse(params).pipe(
      __map(_r => _r.body as Button_test_results)
    );
  }
}

module ButtonTestResultsService {

  /**
   * Parameters for getButtonRestResults
   */
  export interface GetButtonRestResultsParams {

    /**
     * List of button test result ids
     */
    ids?: Array<number>;

    /**
     * List of user sensor ids
     */
    sensorIds?: Array<number>;

    /**
     * List of user lines
     */
    plcLines?: Array<number>;

    /**
     * Email address of the updater
     */
    updatedBy?: Array<string>;

    /**
     * Filter by success status
     */
    success?: boolean;

    /**
     * List of bit indices
     */
    bits?: Array<number>;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for postButtonRestResults
   */
  export interface PostButtonRestResultsParams {
    payload: Button_test_result_input;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for putButtonTestResultButtonTestResultsStringButtonTestResultId
   */
  export interface PutButtonTestResultButtonTestResultsStringButtonTestResultIdParams {
    payload: Button_test_result_input;

    /**
     * Primary key (id) of button test result
     */
    buttonTestResultId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getButtonTestLatestResults
   */
  export interface GetButtonTestLatestResultsParams {
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { ButtonTestResultsService }
