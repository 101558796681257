/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Update_user_parser } from '../models/update-_user-_parser';
import { Delete_user_parser } from '../models/delete-_user-_parser';
import { Create_user_parser } from '../models/create-_user-_parser';
import { Cognito_user_credentials } from '../models/cognito-_user-_credentials';
import { Confirm_forgot_password_parser } from '../models/confirm-_forgot-_password-_parser';
import { Forgot_password_parser } from '../models/forgot-_password-_parser';
import { Get_user_parser } from '../models/get-_user-_parser';
import { User_settings } from '../models/user-_settings';
import { Update_password_parser } from '../models/update-_password-_parser';

/**
 * user related operations
 */
@Injectable({
  providedIn: 'root',
})
class UsersService extends __BaseService {
  static readonly UpdateUserAttributesPath = '/users/';
  static readonly DeleteAUserPath = '/users/';
  static readonly CreateAUserPath = '/users/';
  static readonly postAuthenticateUserPath = '/users/authenticate/';
  static readonly FindIfUserBelongsToKSE40ForKSE30Path = '/users/check_user_version/{email_id}/';
  static readonly ConfirmPasswordWithVerificationCodePath = '/users/confirm_forgot_password/';
  static readonly SendForgotPasswordEmailToUserPath = '/users/forgot-password/';
  static readonly GetUserDetailsPath = '/users/get/';
  static readonly GetUserDetails_1Path = '/users/get/{organization_id}';
  static readonly PutUserDetailsPath = '/users/profile/';
  static readonly GetUserDetails_2Path = '/users/profile/';
  static readonly UpdateUserSpecificSettingsPath = '/users/settings/';
  static readonly GetUserSpecificSettingsPath = '/users/settings/';
  static readonly ChangePasswordForAUserPath = '/users/update-password/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param payload undefined
   */
  UpdateUserAttributesResponse(payload: Update_user_parser): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/users/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  UpdateUserAttributes(payload: Update_user_parser): __Observable<null> {
    return this.UpdateUserAttributesResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  DeleteAUserResponse(payload: Delete_user_parser): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/users/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  DeleteAUser(payload: Delete_user_parser): __Observable<null> {
    return this.DeleteAUserResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  CreateAUserResponse(payload: Create_user_parser): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  CreateAUser(payload: Create_user_parser): __Observable<null> {
    return this.CreateAUserResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  postAuthenticateUserResponse(payload: Cognito_user_credentials): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/authenticate/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  postAuthenticateUser(payload: Cognito_user_credentials): __Observable<null> {
    return this.postAuthenticateUserResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param email_id undefined
   */
  FindIfUserBelongsToKSE40ForKSE30Response(emailId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/check_user_version/${encodeURIComponent(String(emailId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param email_id undefined
   */
  FindIfUserBelongsToKSE40ForKSE30(emailId: string): __Observable<null> {
    return this.FindIfUserBelongsToKSE40ForKSE30Response(emailId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  ConfirmPasswordWithVerificationCodeResponse(payload: Confirm_forgot_password_parser): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/confirm_forgot_password/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  ConfirmPasswordWithVerificationCode(payload: Confirm_forgot_password_parser): __Observable<null> {
    return this.ConfirmPasswordWithVerificationCodeResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  SendForgotPasswordEmailToUserResponse(payload: Forgot_password_parser): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/forgot-password/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  SendForgotPasswordEmailToUser(payload: Forgot_password_parser): __Observable<null> {
    return this.SendForgotPasswordEmailToUserResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetUserDetailsResponse(XFields?: string): __Observable<__StrictHttpResponse<Array<Get_user_parser>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/get/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Get_user_parser>>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetUserDetails(XFields?: string): __Observable<Array<Get_user_parser>> {
    return this.GetUserDetailsResponse(XFields).pipe(
      __map(_r => _r.body as Array<Get_user_parser>)
    );
  }

  /**
   * @param params The `UsersService.GetUserDetails_1Params` containing the following parameters:
   *
   * - `organization_id`: id of organization
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetUserDetails_1Response(params: UsersService.GetUserDetails_1Params): __Observable<__StrictHttpResponse<Array<Get_user_parser>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/get/${encodeURIComponent(String(params.organizationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Get_user_parser>>;
      })
    );
  }
  /**
   * @param params The `UsersService.GetUserDetails_1Params` containing the following parameters:
   *
   * - `organization_id`: id of organization
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetUserDetails_1(params: UsersService.GetUserDetails_1Params): __Observable<Array<Get_user_parser>> {
    return this.GetUserDetails_1Response(params).pipe(
      __map(_r => _r.body as Array<Get_user_parser>)
    );
  }

  /**
   * @param payload undefined
   */
  PutUserDetailsResponse(payload: Update_user_parser): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/users/profile/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  PutUserDetails(payload: Update_user_parser): __Observable<null> {
    return this.PutUserDetailsResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetUserDetails_2Response(XFields?: string): __Observable<__StrictHttpResponse<Get_user_parser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/profile/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Get_user_parser>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetUserDetails_2(XFields?: string): __Observable<Get_user_parser> {
    return this.GetUserDetails_2Response(XFields).pipe(
      __map(_r => _r.body as Get_user_parser)
    );
  }

  /**
   * @param payload undefined
   */
  UpdateUserSpecificSettingsResponse(payload: User_settings): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/users/settings/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  UpdateUserSpecificSettings(payload: User_settings): __Observable<null> {
    return this.UpdateUserSpecificSettingsResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param X-Fields An optional fields mask
   */
  GetUserSpecificSettingsResponse(XFields?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/users/settings/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param X-Fields An optional fields mask
   */
  GetUserSpecificSettings(XFields?: string): __Observable<null> {
    return this.GetUserSpecificSettingsResponse(XFields).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param payload undefined
   */
  ChangePasswordForAUserResponse(payload: Update_password_parser): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/users/update-password/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param payload undefined
   */
  ChangePasswordForAUser(payload: Update_password_parser): __Observable<null> {
    return this.ChangePasswordForAUserResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UsersService {

  /**
   * Parameters for GetUserDetails_1
   */
  export interface GetUserDetails_1Params {

    /**
     * id of organization
     */
    organizationId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { UsersService }
