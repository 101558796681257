/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import {Component} from '@angular/core';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {AuthService} from '../../../ketos-lib/src/lib/services/auth.service';
import {environment} from '../../../../environments/environment';
import {PreloaderService} from './core/api/services/preloader.service';
import {HTTPStatus} from '../../../ketos-lib/src/lib/services/http-status.service';
import {buildVersions} from '../../../../versions';
import {ActivationEnd, ActivationStart, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs';
import {PageDurationService} from '../../../shared/page-duration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  private lastUrl: string;

  constructor(private httpStatus: HTTPStatus, private idle: Idle, private authService: AuthService, private preloader: PreloaderService, private router: Router, private pageDurationService: PageDurationService,
  ) {
    if (environment.production) {
      if (window.location.href.includes('localhost:')) {
        console.debug(buildVersions.webBuild);
      }
    } else {
      console.log(buildVersions.webBuild);
    }
    this.httpStatus.filteredCountSubject.subscribe(count => {
      // Do not remove: this is here to make sure the preloader service loads properly
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationStart || event instanceof NavigationEnd || event instanceof ActivationStart || event instanceof ActivationEnd)
    ).subscribe(async event => {
      if (event instanceof NavigationStart) {
        if (this.lastUrl) {
          if (event.url === '/login') {
            await this.pageDurationService.sendDuration(this.lastUrl);
          } else {
            this.pageDurationService.sendDuration(this.lastUrl).catch(err => console.error(err));
          }
        }
        this.pageDurationService.markEnterTime();
      }
      if (event instanceof NavigationEnd) {
        // Check if the URL contains 'http://' or 'https://'
        if (event.urlAfterRedirects.includes('http://') || event.urlAfterRedirects.includes('https://')) {
          // Extract the pathname from the full URL
          const url = new URL(event.urlAfterRedirects);
          this.lastUrl = url.pathname;
        } else {
          // If it's a relative path, use it directly
          this.lastUrl = event.urlAfterRedirects;
        }
      }
    });

    idle.setIdle(environment.CONSTANTS.INACTIVITY_TIMEOUT);
    idle.setTimeout(0); // How long to wait after idle
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleStart.subscribe(() => {
      authService.logout();
    });

    this.idle.watch();
  }
}
