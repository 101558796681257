import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams, PopoverController} from '@ionic/angular';

@Component({
    selector: 'app-alerts-download-modal',
    templateUrl: './alerts-download-modal.component.html',
    styleUrls: ['./alerts-download-modal.component.scss']
  })

  export class AlertsDownloadModalComponent  {
 
    selectedDownload
    constructor(public popoverController: PopoverController){}
    
  async donePressed() {
    await this.popoverController.dismiss(<any>{
      selectedDownload: this.selectedDownload
    });
  }

  async cancel() {
    await this.popoverController.dismiss();
  }
   static openAsModal(popoverController: PopoverController, existingOptions, ev: Event) {
        return new Promise((resolve) => {
          popoverController.create({
            component: AlertsDownloadModalComponent,
              componentProps: {
            title: "Download Alerts",
            selectedDownload: existingOptions.selectedDownload
          },
            cssClass: 'dark-popover',
            event: ev,
            translucent: true,
          })
            .then((popover: HTMLIonPopoverElement) => {
              popover.onDidDismiss().then(result => {
                resolve(result?.data);
              });
              popover.present();
            });
        })
      }
  }