/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Metrics } from '../models/metrics';

/**
 * CX Dashboard new related operations
 */
@Injectable({
  providedIn: 'root',
})
class CxDashboardNewService extends __BaseService {
  static readonly GetCXDashboardNewMetricsPath = '/cx_dashboard_new/get/{organization_id}/{premium}/{page}/{per_page}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CxDashboardNewService.GetCXDashboardNewMetricsParams` containing the following parameters:
   *
   * - `organization_id`: Organization ID or "all"
   *
   * - `premium`: Premium organization: 0, 1 or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetCXDashboardNewMetricsResponse(params: CxDashboardNewService.GetCXDashboardNewMetricsParams): __Observable<__StrictHttpResponse<Metrics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/cx_dashboard_new/get/${encodeURIComponent(String(params.organizationId))}/${encodeURIComponent(String(params.premium))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Metrics>;
      })
    );
  }
  /**
   * @param params The `CxDashboardNewService.GetCXDashboardNewMetricsParams` containing the following parameters:
   *
   * - `organization_id`: Organization ID or "all"
   *
   * - `premium`: Premium organization: 0, 1 or "all"
   *
   * - `page`: Page number
   *
   * - `per_page`: Results per page
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetCXDashboardNewMetrics(params: CxDashboardNewService.GetCXDashboardNewMetricsParams): __Observable<Metrics> {
    return this.GetCXDashboardNewMetricsResponse(params).pipe(
      __map(_r => _r.body as Metrics)
    );
  }
}

module CxDashboardNewService {

  /**
   * Parameters for GetCXDashboardNewMetrics
   */
  export interface GetCXDashboardNewMetricsParams {

    /**
     * Organization ID or "all"
     */
    organizationId: string;

    /**
     * Premium organization: 0, 1 or "all"
     */
    premium: string;

    /**
     * Page number
     */
    page: number;

    /**
     * Results per page
     */
    perPage: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { CxDashboardNewService }
