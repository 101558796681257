import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {KetosPopupService} from '../../services/ketos-popup.service';
import {ShieldThresholdService, ShieldThresholdsService, UsersService, WaterSourcesService} from '../../../../../api/src/lib/services';
import { ExperimentConfigurationsService, ExperimentConfInterface } from '../../services/experiment-configurations.service';
import { AuthService } from '../../services/auth.service';
import { OptionInterface } from '../multi-select-popover/multi-select-popover.component';
import { WaterSourceExtended } from '../../models/extensions/water-source-extended';
import { AppState, AppStateService } from '../../services/app-state.service';
import { UnitConverter } from '../../utils/UnitConverter';
import { forkJoin, Subscription } from 'rxjs';
import { Experiments, Shield_threshold } from '../../../../../api/src/lib/models';
import { UiPlatformService } from '../../services/ui-platform.service';
import {UserSettingsCachedService} from '../../services/user-settings-cached.service';
import * as cloneDeep from 'lodash/cloneDeep';
import { Sensor_setting_input } from '../../../../../api/src/lib/models/sensor-_setting-_input';
import { Sensor_setting } from '../../../../../api/src/lib/models/sensor-_setting';
import { AuthUserResponse, UserService } from '../../services/user.service';
import {CloneDeep} from "../../utils/utils";

@Component({
  selector: 'lib-alert-setting-modal',
  templateUrl: './alert-setting-modal.component.html',
  styleUrls: ['./alert-setting-modal.component.scss'],
})
export class AlertSettingModalComponent implements OnInit {
  @Input() exp: string;
  @Input() data: any;

  alertAboveThreshold: any;
  alertBelowThreshold: any;
  alertAboveThresholdPlaceHolder: any[]=[];
  alertBelowThresholdPlaceHolder: any[]=[];
  alertAboveThresholdPlaceHolderString: string;
  alertBelowThresholdPlaceHolderString: string
  isBelowToggled:any = false;
  isAboveToggled:any = false;
  alertAboveSetingsKey: string;
  alertBelowSetingsKey: string;
  selectedExp:Experiments[] = [];
  canSubmit = false;
  metalOptions:Experiments[]=[];
  showThresholdBlock = false;
  selectedWaterSources: WaterSourceExtended[]=[];
  selectedWaterSourcesIds: number[]=[];

  public originalExternalThresholdArray: Shield_threshold[] = [];
  waterSources: WaterSourceExtended[];
  waterSourcesAlertSettings: Sensor_setting[] = []
  public selectedLocation: WaterSourceExtended;
  public originalInternalThreshold: Shield_threshold;
  public originalInternalThresholdMin: Shield_threshold;
  public originalInternalThresholdMax: Shield_threshold;

  public originalExternalThreshold: Shield_threshold;
  public originalExternalThresholdMin: Shield_threshold;
  public originalExternalThresholdMax: Shield_threshold;
  maxSensors = 3;
  public appState;
  alertAboveThresholdError = false;
  alertBelowThresholdError = false;
  alertAboveError
  nonNumericError = false;
  thresholdCompareError = false;
  alertSettingSub: Subscription;
  waterSourcesSub: Subscription;
  currentUser: AuthUserResponse;
  constructor(
    public modalController: ModalController,
    private expConfigurationsService: ExperimentConfigurationsService,
    public popupService: KetosPopupService,
    private authService: AuthService,
    public appStateService: AppStateService,
    private shieldThresholdsService: ShieldThresholdsService,
    private ketosPopupService: KetosPopupService,
    public uiPlatformService: UiPlatformService,
    private unitSettingsService: UserSettingsCachedService,
    public waterSourcesService: WaterSourcesService,
    ) {

    // this.confirm = this.confirm.bind(this)
    this.currentUser = UserService.getCurrentUser();
    console.log(this.currentUser,'cpuser')
  }

  ngOnInit() {

    if (this.alertAboveThreshold !== null && this.alertBelowThreshold !== null) {
      if (this.alertAboveThreshold < this.alertBelowThreshold) {

        this.canSubmit = false;
      }
    } else {
      this.canSubmit = true;
    }
    this.appStateService.getAppState()
    .subscribe((appState: AppState) => {
      this.appState = appState
      this.selectedLocation = appState.location

    this.waterSources = appState.locations;
    })
    // const blacklist = ExperimentConfigurationsService.getExperimentBlacklist(this.authService.getUser().organization_id,this.appState.orgSettings.isMagnesiumEnabled,this.appState.orgSettings.isLsiEnabled);
    this.selectedExp = this.appStateService.dropdownExperiments.filter(exp => this.data.key.toLowerCase() === exp.experiment);

    // this.metalOptions =this.appStateService.dropdownExperiments
    //     //.filter(conf => typeof conf.bit === 'number' && conf.method !== 'calculated'  && blacklist.indexOf(conf.experiment) === -1)

    //    // .filter(conf =>  { blacklist.indexOf(conf.experiment) === -1 && typeof conf.bit === 'number' })
    //     .map(type => {
    //       console.log(type,'type',this.data.key.toLowerCase())
    //       if(type.experiment.toLowerCase() === this.data.key.toLowerCase()){

    //         this.selectedExp.push(type)
    //       }
    //       return type
    //       //return {title: type.title, value: type.key};
    //     })

    this.selectedWaterSources=this.waterSources.filter(ws => ws.id === this.selectedLocation.id);
    console.log(this.selectedWaterSources,'this.selectedWaterSources')
    this.loadAlertTogglebuttons();
    this.onChangeSelectedExperiment(this.selectedExp)
    this.setSelectedSensors(this.selectedWaterSources)
  }
onlySpaceAndNumber(str) {
  return ( /^[0-9\s]*$/.test(str));
}
loadAlertTogglebuttons(){
  this.waterSourcesSub = this.waterSourcesService.getWaterSourcesSettings({waterSourceId:this.selectedWaterSources[0].id})
    .subscribe(res => {
      this.alertAboveSetingsKey = `cp_${this.selectedExp[0].experiment}_enable_alert_above_threshold`
      this.alertBelowSetingsKey = `cp_${this.selectedExp[0].experiment}_enable_alert_below_threshold`
      this.isAboveToggled = res.items.filter(item => item.key === this.alertAboveSetingsKey)[0]?.value
      this.isBelowToggled= res.items.filter(item => item.key === this.alertBelowSetingsKey)[0]?.value

    })
}
inputValidate(){
  if((! /^(-?[0-9\s])*(\.\d{1,20})?$/.test(this.alertAboveThreshold) && this.alertAboveThreshold !== null) ||(! /^(-?[0-9\s])*(\.\d{1,20})?$/.test(this.alertBelowThreshold) && this.alertBelowThreshold !== null )){
   // this.nonNumericError = true;
    //this.canSubmit = false;
    return true
  }
  return false;
}
// [0-9]+[0-9\s]*(\.(\s*[0-9]){2,4})?
 inputChange(prop:string,value:any) {

  if((! /^(-?[0-9\s])*(\.\d{1,20})?$/.test(this.alertAboveThreshold) && this.alertAboveThreshold !== null) || (! /^(-?[0-9\s])*(\.\d{1,20})?$/.test(this.alertBelowThreshold) && this.alertBelowThreshold !== null )){
    this.nonNumericError = true;
    this.canSubmit = false;
    return
  }
 this.nonNumericError = false;
    switch(prop){
      case 'extMax':
        this.alertAboveThreshold = value;
        this.alertAboveThresholdError = false;
        break;
      case 'extMin':
        this.alertBelowThreshold = value;
        this.alertBelowThresholdError = false;
      break;
    }
    console.log(this.selectedExp,'exp', this.alertAboveThreshold, this.alertAboveThresholdPlaceHolder[0])

    if(this.alertAboveThreshold !== '' && this.alertBelowThreshold !== ''){
    if ( (Number(this.alertAboveThreshold) < Number(this.alertBelowThreshold)) && this.selectedExp[0].experiment !== 'dissolved_oxygen' ) {
      this.thresholdCompareError = true;
      this.canSubmit = false;
    }else{
      this.thresholdCompareError = false;
      this.canSubmit = true;
    }
    return
  }
    //if (this.alertAboveThreshold !== null && this.alertBelowThreshold !== null && this.newInternalThresholdMax !== null && this.newInternalThresholdMin !== null) {
      if (this.alertAboveThreshold === undefined || this.alertBelowThreshold === undefined) {
        this.canSubmit = false;
        return;
      }
      this.thresholdCompareError = false;
      this.canSubmit = true;
    // } else {
    //   this.enableThresholdSubmitButton = true;
    //   this.errorMsg = false;
    // }
  }
  onChangeSelectedExperiment(ev:Experiments[]){
    console.log('onChangeSelectedExperiment', ev)
    this.selectedExp = [];
    if(ev.length === 0){
      this.showThresholdBlock = false;
      return
    }
    this.selectedExp = CloneDeep(ev);
    // if(this.selectedExp.length > 1){
    //   this.isAboveToggled = false;
    //   this.isBelowToggled = false;
    // } else {
      this.loadAlertTogglebuttons()
   // }
    this.setSelectedSensors(this.selectedWaterSources)

  }
  calcDecimal(val: number): number {
    //var num = theform.original.value, rounded = theform.rounded
    const with5Decimals =  Number(val.toString().match(/^-?\d+(?:\.\d{0,5})?/)[0])
    return Math.round((with5Decimals + Number.EPSILON) * 1000) / 1000;
  }

  initThresholds() {
    this.alertAboveThresholdPlaceHolderString = '';
    this.alertBelowThresholdPlaceHolderString = '';
    this.alertAboveThresholdPlaceHolder = [];
    this.alertBelowThresholdPlaceHolder = [];
    const conf = this.expConfigurationsService.getConfByExperimentMethodBitmap(this.selectedExp[0].experiment);
    for(let i = 0; i < this.originalExternalThresholdArray.length; i++){
      this.alertAboveThresholdPlaceHolder[i] = this.originalExternalThresholdArray[i]?.max_value
      this.alertBelowThresholdPlaceHolder[i] = this.originalExternalThresholdArray[i]?.min_value
      this.alertAboveThresholdPlaceHolder[i] =  this.alertAboveThresholdPlaceHolder[i] === '0E-7' ? 0 : this.alertAboveThresholdPlaceHolder[i]
      this.alertBelowThresholdPlaceHolder[i] =  this.alertBelowThresholdPlaceHolder[i] === '0E-7' ? 0 : this.alertBelowThresholdPlaceHolder[i]
      this.alertBelowThresholdPlaceHolder[i] = this.alertBelowThresholdPlaceHolder[i] !== null ? UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(this.alertBelowThresholdPlaceHolder[i], conf, this.unitSettingsService.selectedUnitsDict):null;
      this.alertAboveThresholdPlaceHolder[i] = this.alertAboveThresholdPlaceHolder[i] !== null ? UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(this.alertAboveThresholdPlaceHolder[i], conf, this.unitSettingsService.selectedUnitsDict):null;

      if (this.alertBelowThresholdPlaceHolder[i] !== null) {
        //this.alertBelowThreshold = this.countDecimals(this.alertBelowThreshold) > 3 ? Math.floor(this.alertBelowThreshold * 1000) / 1000 : this.alertBelowThreshold * 1000 / 1000;
        this.alertBelowThresholdPlaceHolder[i] = this.calcDecimal(this.alertBelowThresholdPlaceHolder[i])

      }
      if (this.alertAboveThresholdPlaceHolder[i] !== null) {
      // this.alertAboveThreshold = Math.floor(this.alertAboveThreshold * 1000) / 1000;
      //this.alertAboveThreshold = this.countDecimals(this.alertAboveThreshold) > 3 ? Math.floor(this.alertAboveThreshold * 1000) / 1000 : this.alertAboveThreshold * 1000 / 1000;
      this.alertAboveThresholdPlaceHolder[i] = this.calcDecimal(this.alertAboveThresholdPlaceHolder[i])
      }
   }
  if(this.alertAboveThresholdPlaceHolder.length === 1 && this.alertBelowThresholdPlaceHolder.length === 1){
    this.alertAboveThreshold = this.alertAboveThresholdPlaceHolder[0];
    this.alertBelowThreshold = this.alertBelowThresholdPlaceHolder[0];
  }
  else if((this.alertAboveThresholdPlaceHolder.length > 1 && this.alertAboveThresholdPlaceHolder.length <= 4) && (this.alertBelowThresholdPlaceHolder.length > 1 && this.alertBelowThresholdPlaceHolder.length <= 4 )){
    this.alertAboveThresholdPlaceHolderString = this.alertAboveThresholdPlaceHolder.filter(n => n).toString()
    this.alertBelowThresholdPlaceHolderString = this.alertBelowThresholdPlaceHolder.filter(n => n).toString()
  }else{
    this.alertAboveThresholdPlaceHolderString = this.getMinAndMax(this.alertAboveThresholdPlaceHolder.filter(n => n))
    this.alertBelowThresholdPlaceHolderString =  this.getMinAndMax(this.alertBelowThresholdPlaceHolder.filter(n => n))
  }
  }
getMinAndMax(arr){
  const fillteredArray = arr.filter(item => item !==undefined);
  if(fillteredArray.length > 0){
  const sortedArray = fillteredArray.sort((a,b) => a - b );
  return `Min value: ${sortedArray[0]} - Max value: ${sortedArray[sortedArray.length - 1]}`
  }
  return ''

}
 updateAlertSettingForMultipleWaterSources(){
  const BulkSettings:WaterSourcesService.PutBulkUpdatesOnWaterSourceSettingsParams = {} as unknown as WaterSourcesService.PutBulkUpdatesOnWaterSourceSettingsParams
  const payloadBulkSettings:Sensor_setting_input[] = []
  const alertAboveSetingsKey = []
  const alertBelowSetingsKey = []
  this.selectedExp.forEach((exp,i) => {
    alertAboveSetingsKey.push(`cp_${this.selectedExp[i].experiment}_enable_alert_above_threshold`)
    alertBelowSetingsKey.push(`cp_${this.selectedExp[i].experiment}_enable_alert_below_threshold`)
    payloadBulkSettings.push({key:alertAboveSetingsKey[i],value:this.isAboveToggled.toString()},{key:alertBelowSetingsKey[i],value:this.isBelowToggled.toString()})
    //payloadBulkSettings.push({key:aboveOrbelow === 'above' ? alertAboveSetingsKey[i]: alertBelowSetingsKey[i],value:aboveOrbelow === 'above' ? this.isAboveToggled.toString() : this.isBelowToggled.toString()})
  })

  BulkSettings.ids = this.selectedWaterSources.map(ws => ws.id);
  BulkSettings.payload ={items:payloadBulkSettings}
  console.log('updateAlertSettingForMultipleWaterSources',BulkSettings)
  this.alertSettingSub =  this.waterSourcesService.putBulkUpdatesOnWaterSourceSettings(BulkSettings)
  .subscribe(res => {
    if(this.selectedExp.length > 1){
    this.ketosPopupService.confirmOkPopup({title:'', message:'Your Alert values are updated!'})
        .then(res => {
          this.modalController.dismiss({alertSettingSubmitted: true, message: res})
        })
      }
  })
 }

  updateUnitConvertion(min,max){
    const conf = this.expConfigurationsService.getConfByExperimentMethodBitmap(this.selectedExp[0].experiment);
    this.alertBelowThreshold = min !== null ? UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(min, conf, this.unitSettingsService.selectedUnitsDict):null;
    this.alertAboveThreshold = max !== null ? UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(max, conf, this.unitSettingsService.selectedUnitsDict):null;
  }
  saveAlertSettings() {
    const conf = this.expConfigurationsService.getConfByExperimentMethodBitmap(this.selectedExp[0].experiment);
    if(this.alertBelowThreshold === undefined){
      this.alertBelowThreshold = null
    }
    if(this.alertBelowThreshold === undefined){
      this.alertBelowThreshold = null
    }

    const currentUnit = UnitConverter.getTargetUnitFromConfAndUnitsDict(conf, this.unitSettingsService.selectedUnitsDict);// UnitConverter.getTargetUnitFromConfAndDisplayUnits(conf, this.orgSettingsService.displayUnitsDict);
    const min = this.alertBelowThreshold !== null && this.alertBelowThreshold !== '' ?UnitConverter.convertFromUnitTo(this.alertBelowThreshold, currentUnit, conf.unit, conf.umolPerL):null;
    const max = this.alertAboveThreshold !== null && this.alertAboveThreshold !== '' ?UnitConverter.convertFromUnitTo(this.alertAboveThreshold, currentUnit, conf.unit, conf.umolPerL):null;

    let exThresholds: Shield_threshold[] = [];

      const exThreshold = cloneDeep(this.originalExternalThresholdArray);
      exThreshold.forEach((item)=>{
        item.max_value = max
        item.min_value = min
        item[this.data.threshold_key] = this.data.threshold_key === "max_value"?max:min;
        delete item.updated_on;
      });
    exThresholds = [exThreshold];
    forkJoin([
      this.shieldThresholdsService.putShieldThresholds({items: exThreshold}),
    ])
      .subscribe(res => {
        this.ketosPopupService.confirmOkPopup({title:'', message:'Your threshold value is set'})
        .then(res => {
          this.modalController.dismiss({alertSettingSubmitted: true, message: res})
        })
      });
  }
  loadDefaultValues() {
    this.alertBelowThreshold = ''
    this.alertAboveThreshold = ''
    this.alertAboveThresholdPlaceHolderString = '';
    this.alertBelowThresholdPlaceHolderString = '';
    const threshold = this.appStateService.thresholdsExternalService.getDefaultThreshold(this.selectedWaterSources[0].id, this.selectedExp[0].experiment);
    this.updateUnitConvertion(threshold.min_value, threshold.max_value);
    //this.newExternalThresholdMin = threshold.min_value;
    //this.newExternalThresholdMax = threshold.max_value;
    this.alertAboveThreshold = this.alertAboveThreshold?this.calcDecimal(this.alertAboveThreshold):this.alertAboveThreshold
   this.alertBelowThreshold = this.alertBelowThreshold?this.calcDecimal(this.alertBelowThreshold):this. alertBelowThreshold
    this.inputChange('extMax', this.alertAboveThreshold);
    this.inputChange('extMin', this.alertBelowThreshold);
  }
  setSelectedSensors(ev:WaterSourceExtended[]){
   // console.log('setSelectedSensors',this.selectedExp)
    this.alertAboveThreshold = '';
    this.alertBelowThreshold = '';

    this.originalExternalThresholdArray = []
    let ws = [];
    this.selectedWaterSources = [];
    this.showThresholdBlock = false;
    console.log('setSelectedSensors',ev)

    if(ev.length === 0){
      //ws = this.selectedWaterSources
      return
    }else{
      ws = cloneDeep(ev);
      this.selectedWaterSources = cloneDeep(ws);
    }

    for(let i = 0; i< this.selectedWaterSources.length; i++ ){
    this.originalExternalThresholdArray.push(this.appStateService.thresholdsExternalService.getShieldThresholdForSensorIdAndExperiment(ws[i].id, this.selectedExp[0].experiment));
    }

    this.selectedWaterSourcesIds = this.selectedWaterSources.map(ws => ws.id);
    console.log('setSelectedSensors',this.selectedWaterSourcesIds)

    //this.canSubmit = true;
    this.initThresholds();
    this.showThresholdBlock = true;

  }
  async cancel() {
    await this.modalController.dismiss();
  }
  ngOnDestroy(){
    if(this.waterSourcesSub){
      this.waterSourcesSub.unsubscribe();
    }
  }




}
