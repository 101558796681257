export const boronDataValues ={
"Grape":{
"toxicity":0.75,
"Deficiency":0.5,
  Annual:{
        xAxis: [{
            accessibility: {
            description: 'Years'
            },
            categories:['2016','2017','2018','2019','2020']
        }],

        yAxis: {
            title: {
            text: 'Ratio'
            },
            plotLines:[],
            min: 0
        },

        series: [{
            name: 'Boron',
            data: [
              [1.5, 1.7, 1.2, 0.4, 0.8],
              ]
        }]
    },
  Winter:{
      xAxis: [{
          accessibility: {
            description: 'Months'
          },
          categories:['December','January','February']
        }],
      
          yAxis: {
          title: {
          text: 'Ratio'
          },
          plotLines:[],
          min: 0
      },
      series: [{
          name: 'Boron',
          data: [
            [0.4,0.5, 1.7],
            ]
        }]
  },
  Spring:{
      xAxis: [{
          accessibility: {
            description: 'Months'
          },
          categories:['March','April','May']
        }],
      
          yAxis: {
          title: {
          text: 'Ratio'
          },
          plotLines:[],
          min: 0
      },
      series: [{
          name: 'Boron',
          data: [
            [0.2,0.4, 1.4],
            ]
        }]
  },
  Summer:{
      xAxis: [{
          accessibility: {
            description: 'Months'
          },
          categories:['June','July','August']
        }],
      yAxis: {
          title: {
            text: 'Ratio'
          },
          plotLines:[],
          min: 0
      },
      series: [{
          name: 'Boron',
          data: [
            [1.5, 1.7, 0.2],
            ]
      }]
  },
  Autumn:{
      xAxis: [{
          accessibility: {
            description: 'Months'
          },
          categories:['September','October','November']
        }],
      
        yAxis: {
          title: {
            text: 'Ratio'
          },
          plotLines:[],
          min: 0
      },
      series: [{
          name: 'Boron',
          data: [
            [0.3, 1.2, 0.4],
            ]
      }]
  },
  Daily:{
      xAxis: [{
          accessibility: {
            description: 'Days'
          },
          categories:['02/03/2020','02/04/2020','02/05/2020','02/06/2020','02/07/2020']
        }],
      
      yAxis: {
          title: {
            text: 'Ratio'
          },
          plotLines:[],
          min: 0
      },
      series: [{
          name: 'Boron',
          data: [
            [1.5, 1.7, 1.4, 1.4, 1.8],
            ]
      }]
  }
},
"Walnut":{
    "toxicity":0.75,
    "Deficiency":0.25,
    Annual:{
      xAxis: [{
          accessibility: {
          description: 'Years'
          },
          categories:['2016','2017','2018','2019','2020']
      }],

      yAxis: {
          title: {
          text: 'Ratio'
          },
          plotLines:[],
          min: 0
      },

      series: [{
          name: 'Boron',
          data: [
            [0.5, 0.7, 1.2, 1.4, 1.8],
            ]
      }]
  },
Winter:{
    xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['December','January','February']
      }],
    
        yAxis: {
        title: {
        text: 'Ratio'
        },
        plotLines:[],
        min: 0
    },
    series: [{
        name: 'Boron',
        data: [
          [1.4,1.5, 0.7],
          ]
      }]
},
Spring:{
    xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['March','April','May']
      }],
    
        yAxis: {
        title: {
        text: 'Ratio'
        },
        plotLines:[],
        min: 0
    },
    series: [{
        name: 'Boron',
        data: [
          [1.2,0.4, 1.7],
          ]
      }]
},
Summer:{
    xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['June','July','August']
      }],
    yAxis: {
        title: {
          text: 'Ratio'
        },
        plotLines:[],
        min: 0
    },
    series: [{
        name: 'Boron',
        data: [
          [0.5, 0.7, 1.2],
          ]
    }]
},
Autumn:{
    xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['September','October','November']
      }],
    
      yAxis: {
        title: {
          text: 'Ratio'
        },
        plotLines:[],
        min: 0
    },
    series: [{
        name: 'Boron',
        data: [
          [0.7, 1.2, 1.4],
          ]
    }]
},
Daily:{
    xAxis: [{
        accessibility: {
          description: 'Days'
        },
        categories:['02/03/2020','02/04/2020','02/05/2020','02/06/2020','02/07/2020']
      }],
    
    yAxis: {
        title: {
          text: 'Ratio'
        },
        plotLines:[],
        min: 0
    },
    series: [{
        name: 'Boron',
        data: [
          [0.5, 0.7, 1.2, 1.4, 1.8],
          ]
    }]
}
},
"Almond":{
    "toxicity":0.75,
    "Deficiency":0.25,
    Annual:{
      xAxis: [{
          accessibility: {
          description: 'Years'
          },
          categories:['2016','2017','2018','2019','2020']
      }],

      yAxis: {
          title: {
          text: 'Ratio'
          },
          plotLines:[],
          min: 0
      },

      series: [{
          name: 'Boron',
          data: [
            [0.5, 0.7, 0.2, 1.4, 1.8],
            ]
      }]
  },
Winter:{
    xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['December','January','February']
      }],
    
        yAxis: {
        title: {
        text: 'Ratio'
        },
        plotLines:[],
        min: 0
    },
    series: [{
        name: 'Boron',
        data: [
          [1.4,1.5, 1.7],
          ]
      }]
},
Spring:{
    xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['March','April','May']
      }],
    
        yAxis: {
        title: {
        text: 'Ratio'
        },
        plotLines:[],
        min: 0
    },
    series: [{
        name: 'Boron',
        data: [
          [1.2,1.4, 1.4],
          ]
      }]
},
Summer:{
    xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['June','July','August']
      }],
    yAxis: {
        title: {
          text: 'Ratio'
        },
        plotLines:[],
        min: 0
    },
    series: [{
        name: 'Boron',
        data: [
          [0.5, 0.7, 0.2],
          ]
    }]
},
Autumn:{
    xAxis: [{
        accessibility: {
          description: 'Months'
        },
        categories:['September','October','November']
      }],
    
      yAxis: {
        title: {
          text: 'Ratio'
        },
        plotLines:[],
        min: 0
    },
    series: [{
        name: 'Boron',
        data: [
          [1.3, 1.2, 1.4],
          ]
    }]
},
Daily:{
    xAxis: [{
        accessibility: {
          description: 'Days'
        },
        categories:['02/03/2020','02/04/2020','02/05/2020','02/06/2020','02/07/2020']
      }],
    
    yAxis: {
        title: {
          text: 'Ratio'
        },
        plotLines:[],
        min: 0
    },
    series: [{
        name: 'Boron',
        data: [
          [0.5, 1.7, 0.4, 0.4, 1.8],
          ]
    }]
}
},
"Boron":{
    Annual:{
        xAxis: [{
            accessibility: {
            description: 'Years'
            },
            categories:['2016','2017','2018','2019','2020']
        }],
  
        yAxis: {
            title: {
            text: 'Contaminant Concentration (ppm)'
            },
            plotLines:[],
            min: 0
        },
  
        series: [{
            name: 'Boron',
            data: [
                [0.1, 0.2, 0.4],
                [0.2, 0.4, 0.5],
                [0.1, 0.3, 0.7],
                [0.3, 0.5, 0.7],
                [0.2, 0.5, 0.8],
              ]
        }]
    },
    Winter:{
        xAxis: [{
            accessibility: {
              description: 'Months'
            },
            categories:['December','January','February']
          }],
        
           yAxis: {
            title: {
            text: 'Contaminant Concentration (ppm)'
            },
            plotLines:[],
            min: 0
        },
        series: [{
            name: 'Boron',
            data: [
                [0.05, 0.25, 0.4],
                [0.0, 0.3, 0.5],
                [0.0, 0.5, 1.5],
              ]
          }]
    },
    Spring:{
        xAxis: [{
            accessibility: {
              description: 'Months'
            },
            categories:['March','April','May']
          }],
        
           yAxis: {
            title: {
            text: 'Contaminant Concentration (ppm)'
            },
            plotLines:[],
            min: 0
        },
        series: [{
            name: 'Boron',
            data: [
                [0.1, 0.4, 2.7],
                [0.0, 0.3, 0.5],
                [0.6, 0.7, 3.5],
              ]
          }]
    },
    Summer:{
        xAxis: [{
            accessibility: {
              description: 'Months'
            },
            categories:['June','July','August']
          }],
        yAxis: {
            title: {
              text: 'Contaminant Concentration (ppm)'
           },
            plotLines:[],
            min: 0
        },
        series: [{
            name: 'Boron',
            data: [
                [.8, 1.0, 1.1],
                [.0, 1.2, 2.2],
                [.4, 1.0, 2.5],
              ]
        }]
    },
    Autumn:{
        xAxis: [{
            accessibility: {
              description: 'Months'
            },
            categories:['September','October','November']
          }],
        
          yAxis: {
            title: {
              text: 'Contaminant Concentration (ppm)'
           },
            plotLines:[],
            min: 0
        },
        series: [{
            name: 'Boron',
            data: [
                [.2, .8, 1.2],
                [1.6, 1.8, 5.5],
                [.7, .8, 1.5],
              ]
        }]
    },
    Daily:{
        xAxis: [{
            accessibility: {
              description: 'Days'
            },
            categories:['02/03/2020','02/04/2020','02/05/2020','02/06/2020','02/07/2020']
          }],
        
        yAxis: {
            title: {
              text: 'Contaminant Concentration (ppm)'
           },
            plotLines:[],
            min: 0
        },
        series: [{
            name: 'Boron',
            data: [
                [.0, .8, 1.5],
                [.0, .3, 4.5],
                [1.3, 2.4, 1.5],
                [1.5, 2.2, 6.6],
                [2.0, 2.0, 2.4],
              ]
        }]
    }
  }
}
  
export const chlorineDataValues ={
    "Grape":{
        "toxicity":130,
        "Deficiency":70,
        },
    "Walnut":{
        "toxicity":220,
        "Deficiency":70,
    },
    "Almond":{
        "toxicity":150,
        "Deficiency":100,
    },
"Chlorine":{
    Annual:{
        xAxis: [{
            accessibility: {
            description: 'Years'
            },
            categories:['2016','2017','2018','2019','2020']
        }],
  
        yAxis: {
            title: {
            text: 'Contaminant Concentration (ppm)'
            },
            plotLines:[],
            min: 0
        },
  
        series: [{
            name: 'Chlorine',
            data: [
                [0, 80, 140],
                [20, 100, 170],
                [10, 150, 270],
                [140, 160, 190],
                [20, 80, 210],
              ]
        }]
    },
    Winter:{
        xAxis: [{
            accessibility: {
              description: 'Months'
            },
            categories:['December','January','February']
          }],
        
           yAxis: {
            title: {
            text: 'Contaminant Concentration (ppm)'
            },
            plotLines:[],
            min: 0
        },
        series: [{
            name: 'Chlorine',
            data: [
                [20, 30, 50],
                [40, 40, 80],
                [80, 110, 140],
              ]
          }]
    },
    Spring:{
        xAxis: [{
            accessibility: {
              description: 'Months'
            },
            categories:['March','April','May']
          }],
        
           yAxis: {
            title: {
            text: 'Contaminant Concentration (ppm)'
            },
            plotLines:[],
            min: 0
        },
        series: [{
            name: 'Chlorine',
            data: [
                [100, 100, 150],
                [30, 120, 50],
                [80, 80, 90],
              ]
          }]
    },
    Summer:{
        xAxis: [{
            accessibility: {
              description: 'Months'
            },
            categories:['June','July','August']
          }],
        yAxis: {
            title: {
              text: 'Contaminant Concentration (ppm)'
           },
            plotLines:[],
            min: 0
        },
        series: [{
            name: 'Chlorine',
            data: [
                [80, 100, 110],
                [60, 70, 100],
                [60, 100, 110],
              ]
        }]
    },
    Autumn:{
        xAxis: [{
            accessibility: {
              description: 'Months'
            },
            categories:['September','October','November']
          }],
        
          yAxis: {
            title: {
              text: 'Contaminant Concentration (ppm)'
           },
            plotLines:[],
            min: 0
        },
        series: [{
            name: 'Chlorine',
            data: [
                [80, 80, 120],
                [40, 70, 80],
                [30, 80, 130],
              ]
        }]
    },
    Daily:{
        xAxis: [{
            accessibility: {
              description: 'Days'
            },
            categories:['02/03/2020','02/04/2020','02/05/2020','02/06/2020','02/07/2020']
          }],
        
        yAxis: {
            title: {
              text: 'Contaminant Concentration (ppm)'
           },
            plotLines:[],
            min: 0
        },
        series: [{
            name: 'Chlorine',
            data: [
                [30, 30, 50],
                [0, 10, 20],
                [30, 40, 50],
                [10, 20, 60],
                [20, 20, 70],
              ]
        }]
    }
  }
}
  
  
  export const nitrateDataValues = {
    "Grape":{
        "toxicity":6,
        "Deficiency":3,
    },
    "Walnut":{
        "toxicity":7,
        "Deficiency":3,
    },
    "Almond":{
        "toxicity":15,
        "Deficiency":7,
    },
    "Nitrate":{
      Annual:{
        xAxis: [{
          accessibility: {
            description: 'Years'
          },
          categories:['2016','2017','2018','2019','2020']
        }],
      
        yAxis: {
          title: {
            text: 'Contaminant Concentration (ppm)'
          },
          plotLines:[],
          min: 0
        },
      
      series: [{
          name: 'Nitrate',
          data: [
            [0, 2, 6],
            [0, 1, 1.5],
            [0, 3, 3.5],
            [1, 2, 4],
            [1, 4, 6],
          ]
        }]
      },     
      Winter:{
        xAxis: [{
          accessibility: {
            description: 'Months'
          },
          categories:['December','January','February']
        }],
      
        yAxis: {
          title: {
            text: 'Contaminant Concentration (ppm)'
          },
          plotLines:[],
          min: 0
        },
      
      series: [{
          name: 'Nitrate',
          data: [
            [0, 11, 14],
            [12, 12, 14],
            [12, 12, 6],
          ]
        }]
      },    
      Spring:{
        xAxis: [{
          accessibility: {
            description: 'Months'
          },
          categories:['March','April','May']
        }],
      
        yAxis: {
          title: {
            text: 'Contaminant Concentration (ppm)'
          },
          plotLines:[],
          min: 0
        },
      
      series: [{
          name: 'Nitrate',
          data: [
            [11, 14, 16],
            [12, 17, 22],
            [0, 4, 12],
          ]
        }]
      },
      Summer:{
      
        xAxis: [{
          accessibility: {
            description: 'Months'
          },
          categories:['June','July','August']
        }],
      
        yAxis: {
          title: {
            text: 'Contaminant Concentration (ppm)'
          },
          plotLines:[],
          min: 0
        },
      series: [{
          name: 'Nitrate',
          data: [
            [0, 1, 12],
            [0, 12, 14],
            [0, 12, 35],
          ]
        }]
      },
      Autumn:{
      
        xAxis: [{
          accessibility: {
            description: 'Months'
          },
          categories:['September','October','November']
        }],
      
        yAxis: {
          title: {
            text: 'Contaminant Concentration (ppm)'
          },
          plotLines:[],
          min: 0
        },
      
      series: [{
          name: 'Nitrate',
          data: [
            [22, 42, 45],
            [12, 22, 35],
            [22, 42, 62],
          ]
        }]
      },
      Daily:{
      
        xAxis: [{
          accessibility: {
            description: 'Days'
          },
          categories:['02/03/2020','02/04/2020','02/05/2020','02/06/2020','02/07/2020']
        }],
      
        yAxis: {
          title: {
            text: 'Contaminant Concentration (ppm)'
          },
          plotLines:[],
          min: 0
        },
      
      series: [{
          name: 'Nitrate',
          data: [
            [0, 4, 5],
            [0, 13,15],
            [13, 24, 15],
            [1.5, 2.2, 6.6],
            [2.0, 2.0, 2.4],
          ]
        }]
      
      },
    }
}
  
      