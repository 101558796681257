import { Pipe, PipeTransform } from '@angular/core';
import {UnitConverter} from '../utils/UnitConverter';
import {ExperimentConfigurationsService} from '../services/experiment-configurations.service';
import {UserSettingsCachedService} from '../services/user-settings-cached.service';

@Pipe({
  name: 'clipAdlBdl'
})
export class ClipAdlBdlPipe implements PipeTransform {
  constructor(private expConfService: ExperimentConfigurationsService,
              private userSettingsCachedService: UserSettingsCachedService) {
  }
  transform(value: number, type: string, method?: string, bitmap?: string, bdlOverride?: number, adlOverride?: number): number | string {
    if (value === null || isNaN(value)) {
      return value;
    }

    const conf = this.expConfService.getConfByExperimentMethodBitmap(type, method, bitmap);
    if (conf) {
      if (bdlOverride !== null && !isNaN(bdlOverride)) {
        const bdl = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(bdlOverride, conf, this.userSettingsCachedService.selectedUnitsDict);
        if (bdl > value) {
          return '< ' + bdl;
        }
      } else if (conf.bdl !== null && !isNaN(conf.bdl)) {
        const bdl = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(conf.bdl, conf, this.userSettingsCachedService.selectedUnitsDict);
        if (bdl > value) {
          return '< ' + bdl;
        }
      }

      if (adlOverride !== null && !isNaN(adlOverride)) {
        const adl = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(adlOverride, conf, this.userSettingsCachedService.selectedUnitsDict);
        if (adl < value) {
          return '> ' + adl;
        }
      } else if (conf.adl !== null && !isNaN(conf.adl)) {
        const adl = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(conf.adl, conf, this.userSettingsCachedService.selectedUnitsDict);
        if (adl < value) {
          return '> ' + adl;
        }
      }
      return value;
    } else {
      if (bdlOverride !== null && !isNaN(bdlOverride) && bdlOverride > value) {
        return '< ' + bdlOverride;
      }
      if (adlOverride !== null && !isNaN(adlOverride) && adlOverride < value) {
        return '> ' + adlOverride;
      }
    }
    return value;
  }
}
