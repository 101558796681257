import { Injectable } from '@angular/core';
import {AppStorage} from '../utils/AppStorage';
import {Observable, of} from 'rxjs';
import {AuthUserResponse, UserService} from './user.service';
import {Cognito_user_credentials} from '../../../../api/src/lib/models/cognito-_user-_credentials';
import {map} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {Router} from '@angular/router';
import { NavController } from '@ionic/angular';
import { UiPlatformService } from './ui-platform.service';
import {KetosSocketService} from './ketos-socket.service';

export interface ILoginResponse {
  'OwnerId': number;
  'UserId': number;
  'defaultLocationName': string; // "Ketos In House Wave 1" (sometimes "None")
  'defaultLocationNameDictionary': {
    'iceberg': {
      'country': string; // "Mexico"
      'defaultLocationName': string; // "Anahuac"
    },
    'shield': {
      'country': string; // "US", "USA", "Mexico", "India" @todo Make sure we standardize these
      'defaultLocationName': string; // "Ketos Lab 1"
    },
    'wave': {
      'country': string; // "US",
      'defaultLocationName': string; // "Ketos In House Wave 1"
    }
  },
  eula: number,
  'groupId': number,
  'groupName': string,
  'name': string; // "Ketos Admin",
  'organizationName': string; // "KetosHQ",
  'premium': number; // 0 | 1
  'roleName': UserRoleEnum;
  'token': string;
  'userToken': string;
  'username': string; // Email
  'password': string; // @todo This contains the password in clear text
  'start_date_for_billing': number;
  'water_safety_customer': number; // 0 | 1
}

export interface IUserCredentials {
  username: string;
  password: string;
}

export enum UserRoleEnum {
  SuperAdmin = 'SuperAdmin', // id 1
  OrgAdmin = 'OrgAdmin', // id 2
  GroupAdmin = 'GroupAdmin', // id 3
  BusinessUser = 'BusinessUser', // id 4
  GuestUser = 'GuestUser' // id 5
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // @todo Refactor out
  public userRoles: any = {
    SuperAdmin: {
      title: 'SuperAdmin',
      id: 1,
      logged: false
    },
    OrgAdmin: {
      title: 'OrgAdmin',
      id: 2,
      logged: false
    },
    GroupAdmin: {
      title: 'GroupAdmin',
      id: 3,
      logged: false
    },
    BusinessUser: {
      title: 'BusinessUser',
      id: 4,
      logged: false
    },
    GuestUser: {
      title: 'GuestUser',
      id: 5,
      logged: false
    }
  };

  constructor(private router: Router,
              private navCtrl: NavController,
              public uiPlatformService: UiPlatformService,
              private userService: UserService,
              private ketosSocketService: KetosSocketService) {
    this.init(ketosSocketService);
  }

  private init(ketosSocketService?: KetosSocketService) {
    if (this.isAuthenticated()) {
      Object.keys(this.userRoles).forEach((key) => {
        this.userRoles[key].logged = this.getUser().role_id === this.userRoles[key].id;
      });
      const token = AppStorage.getItem('token');
      if (ketosSocketService && token && token.length > 0) {
        ketosSocketService.connectSocket(environment.CONSTANTS.API_SOCKET, token);
      }
    }
    window.addEventListener('storage', (e) => {
        if (e.oldValue === null && e.newValue && e.key === AppStorage.getKey('user')) {
          console.log('RELOAD in 500ms: another tab logged in');
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
    });
  }

  public login(credentials: Cognito_user_credentials, saveUserDetails = true): Observable<AuthUserResponse> {
    console.log("1")
    return this.userService.authenticateUser(credentials)
      .pipe(
        map((user) => {
          user.userData = {};
          user.username = credentials.username;
          if (saveUserDetails === true) {
            this.saveUserDetails(user);
          }
          return user;
        })
      );
    // const url = this.appService.getAuthenticationUrl(userCredentials.username);
    //
    // if (this.appService.httpApiRequestsHeaders) {
    //   this.appService.httpApiRequestsHeaders['Authorization'] =
    //     'Basic ' + window.btoa(userCredentials.username + ':' + userCredentials.password);
    // }
    //
    // return this.http.post<ILoginResponse>(url,
    //   null, {
    //     headers: this.appService.httpApiRequestsHeaders
    //   }).pipe(
    //   map((res: ILoginResponse) => {
    //     res.username = userCredentials.username;
    //     res.password = userCredentials.password;
    //     return res;
    //   })
    // );
  }

  public saveUserDetails(user: AuthUserResponse) {
    this.saveToken(user.token.IdToken);
    this.ketosSocketService.connectSocket(environment.CONSTANTS.API_SOCKET, user.token.IdToken);
    for (const key of Object.keys(this.userRoles)) {
      if (this.userRoles[key].id === user.role_id) {
        user.roleName = key;
      }
    }
    AppStorage.setItem('user', JSON.stringify(user));
  }

  public saveUser(user: AuthUserResponse) {
    // console.log('saveUser', user);
    this.saveToken(user.token.IdToken);
    for (const key of Object.keys(this.userRoles)) {
      if (this.userRoles[key].id === user.role_id) {
        user.roleName = key;
      }
    }
    user.userData = {};
    AppStorage.setItem('user', JSON.stringify(user));
  }

  public saveToken(token: string) {
    AppStorage.setItem('token', token);
    this.init();
  }

  public setEula(userCredentials: AuthUserResponse) {  //: Observable<any> {
    return of(false);
    // return this.http.post<IApiResponse>(this.appService.setEulaUrl(), null,{
    //   headers: {
    //     'Authorization': 'Basic ' + window.btoa(`${userCredentials.username}:${userCredentials.password}`)
    //   }
    // });
  }

  public forgotPassword(email): Observable<any> {
    // @TODO: ng conversion reimplement
    // const url = this.appService.getForgotPasswordUrl();
    //
    // const formData = new FormData();
    // formData.append('user_email', email);
    //
    // return this.http.post(url,
    //   formData, {
    //     headers: {
    //       'Authorization': this.appService.httpApiRequestsHeaders.Authorization
    //     }
    //   });
    return of('');
  }

  public logout() {
    this.navCtrl.navigateRoot('/login')
      .then(() => {
        const prodEmail = AppStorage.getItem('prod-email');
        localStorage.clear();
        this.ketosSocketService.disconnectSocket();
        if (environment.production === false && prodEmail) {
          AppStorage.setItem('prod-email', prodEmail);
        }

        window.location.reload();
      });
  }

  // @TODO: refactor out, these are here to fix the build for now
  public getBasicAuthHeader(): string {
    return '';
  }
  public getAuthHeader(): {Authorization: string} {
    return;
  }

  public isAuthenticated(): boolean {
    return !!AppStorage.getItem('user');
  }

  public getUser(): AuthUserResponse {
    return JSON.parse(AppStorage.getItem('user'));
  }
}
