import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'ketosDate'
})
export class KetosDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  transform(value: Date | string | number | moment.Moment, format?: string, timezone?: string, locale?: string): string {
    if (typeof value === "string" && value.length > 0) {
      return this.datePipe.transform(value + 'Z' as (Date | string | number), format, timezone, locale)
    }
    return this.datePipe.transform(value as (Date | string | number), format, timezone, locale)

  }

}
