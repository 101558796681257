/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Audit_report_row } from '../models/audit-_report-_row';
import { Analytic_input } from '../models/analytic-_input';

/**
 * Customer usage metrics
 */
@Injectable({
  providedIn: 'root',
})
class AnalyticsService extends __BaseService {
  static readonly getAuditReportPath = '/analytics/report';
  static readonly postAnalyticsPath = '/analytics/save';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get audit report
   * @param params The `AnalyticsService.GetAuditReportParams` containing the following parameters:
   *
   * - `number_of_days`: How many days to include in the report
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getAuditReportResponse(params: AnalyticsService.GetAuditReportParams): __Observable<__StrictHttpResponse<Array<Audit_report_row>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.numberOfDays != null) __params = __params.set('number_of_days', params.numberOfDays.toString());
    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/analytics/report`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Audit_report_row>>;
      })
    );
  }
  /**
   * Get audit report
   * @param params The `AnalyticsService.GetAuditReportParams` containing the following parameters:
   *
   * - `number_of_days`: How many days to include in the report
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getAuditReport(params: AnalyticsService.GetAuditReportParams): __Observable<Array<Audit_report_row>> {
    return this.getAuditReportResponse(params).pipe(
      __map(_r => _r.body as Array<Audit_report_row>)
    );
  }

  /**
   * Add CP page analytics row
   * @param payload undefined
   */
  postAnalyticsResponse(payload: Analytic_input): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/analytics/save`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Add CP page analytics row
   * @param payload undefined
   */
  postAnalytics(payload: Analytic_input): __Observable<null> {
    return this.postAnalyticsResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AnalyticsService {

  /**
   * Parameters for getAuditReport
   */
  export interface GetAuditReportParams {

    /**
     * How many days to include in the report
     */
    numberOfDays?: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { AnalyticsService }
