/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiInternalConfiguration as __Configuration } from '../api-internal-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Result } from '../models/result';

/**
 * Data related operations
 */
@Injectable({
  providedIn: 'root',
})
class DaasService extends __BaseService {
  static readonly putShieldResultLatestDataDaasDataIntSensorIdPath = '/daas/data/{sensor_id}';
  static readonly getShieldResultLatestDataDaasDataIntSensorIdPath = '/daas/data/{sensor_id}';
  static readonly getShieldResultLast12HoursDaasLast12HoursIntSensorIdPath = '/daas/last_12_hours/{sensor_id}';
  static readonly getShieldResultLatestDummyDaasLatestIntSensorIdPath = '/daas/latest/{sensor_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DaasService.PutShieldResultLatestDataDaasDataIntSensorIdParams` containing the following parameters:
   *
   * - `sensor_id`: sensor_id
   *
   * - `pumpsts`: Pump status
   */
  putShieldResultLatestDataDaasDataIntSensorIdResponse(params: DaasService.PutShieldResultLatestDataDaasDataIntSensorIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.pumpsts != null) __params = __params.set('pumpsts', params.pumpsts.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/daas/data/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DaasService.PutShieldResultLatestDataDaasDataIntSensorIdParams` containing the following parameters:
   *
   * - `sensor_id`: sensor_id
   *
   * - `pumpsts`: Pump status
   */
  putShieldResultLatestDataDaasDataIntSensorId(params: DaasService.PutShieldResultLatestDataDaasDataIntSensorIdParams): __Observable<null> {
    return this.putShieldResultLatestDataDaasDataIntSensorIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `DaasService.GetShieldResultLatestDataDaasDataIntSensorIdParams` containing the following parameters:
   *
   * - `sensor_id`: sensor_id
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultLatestDataDaasDataIntSensorIdResponse(params: DaasService.GetShieldResultLatestDataDaasDataIntSensorIdParams): __Observable<__StrictHttpResponse<Array<Result>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/daas/data/${encodeURIComponent(String(params.sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Result>>;
      })
    );
  }
  /**
   * @param params The `DaasService.GetShieldResultLatestDataDaasDataIntSensorIdParams` containing the following parameters:
   *
   * - `sensor_id`: sensor_id
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getShieldResultLatestDataDaasDataIntSensorId(params: DaasService.GetShieldResultLatestDataDaasDataIntSensorIdParams): __Observable<Array<Result>> {
    return this.getShieldResultLatestDataDaasDataIntSensorIdResponse(params).pipe(
      __map(_r => _r.body as Array<Result>)
    );
  }

  /**
   * @param sensor_id sensor_id
   */
  getShieldResultLast12HoursDaasLast12HoursIntSensorIdResponse(sensorId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/daas/last_12_hours/${encodeURIComponent(String(sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_id sensor_id
   */
  getShieldResultLast12HoursDaasLast12HoursIntSensorId(sensorId: number): __Observable<null> {
    return this.getShieldResultLast12HoursDaasLast12HoursIntSensorIdResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param sensor_id sensor_id
   */
  getShieldResultLatestDummyDaasLatestIntSensorIdResponse(sensorId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/daas/latest/${encodeURIComponent(String(sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_id sensor_id
   */
  getShieldResultLatestDummyDaasLatestIntSensorId(sensorId: number): __Observable<null> {
    return this.getShieldResultLatestDummyDaasLatestIntSensorIdResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DaasService {

  /**
   * Parameters for putShieldResultLatestDataDaasDataIntSensorId
   */
  export interface PutShieldResultLatestDataDaasDataIntSensorIdParams {

    /**
     * sensor_id
     */
    sensorId: number;

    /**
     * Pump status
     */
    pumpsts?: boolean;
  }

  /**
   * Parameters for getShieldResultLatestDataDaasDataIntSensorId
   */
  export interface GetShieldResultLatestDataDaasDataIntSensorIdParams {

    /**
     * sensor_id
     */
    sensorId: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }
}

export { DaasService }
