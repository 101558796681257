import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {chartSymbols, KetosGraphBase, KetosGraphDataInterface, KetosGraphSeriesSettingsInterface} from '../ketos-graph-base';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import * as cloneDeep from 'lodash/cloneDeep';
import {AppStateService, TimeIntervalInterface} from '../../../services/app-state.service';
import {ExperimentConfigurationsService} from '../../../services/experiment-configurations.service';
import {Aggregate_results} from '../../../../../../api/src/lib/models/aggregate-_results';
import {PlotOptions, PlotSeriesOptions, SeriesBoxplotOptions, SeriesLollipopOptions} from 'highcharts';
import {Aggregate_result} from '../../../../../../api/src/lib/models/aggregate-_result';
import {ResultsService} from '../../../../../../api/src/lib/services/results.service';
import {OrganizationSettingsService} from '../../../services/organization-settings.service';
import {UnitConverter} from '../../../utils/UnitConverter';

interface PlotSeriesOptionsExtended extends PlotSeriesOptions {
  whiskerWidth: number;
}

interface PlotOptionsExtended extends PlotOptions {
  series: PlotSeriesOptionsExtended;
}

interface OptionsInterface extends Highcharts.Options {
  series: Array<SeriesLollipopOptions | SeriesBoxplotOptions>;
  plotOptions: PlotOptionsExtended;
}

const HighchartsMore = require('highcharts/highcharts-more.src');
HighchartsMore(Highcharts);
const HC_solid_gauge = require('highcharts/modules/solid-gauge.src');
HC_solid_gauge(Highcharts);

const HighChartsDumbbell = require('highcharts/modules/dumbbell');
HighChartsDumbbell(Highcharts);


const HighChartsLollipop = require('highcharts/modules/lollipop');
HighChartsLollipop(Highcharts);

const NoDataDisplay = require('highcharts/modules/no-data-to-display');
NoDataDisplay(Highcharts);

import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';
import {UiPlatformService} from '../../../services/ui-platform.service';
import {ShieldDevicesCachedService} from '../../../services/shield-devices-cached.service';
import {FormatResultValuePipe} from '../../../pipes/format-result-value.pipe';
import {UserSettingsCachedService} from '../../../services/user-settings-cached.service';
import {FormatDisplayNumberPipe} from '../../../pipes/format-display-number.pipe';
import {WaterSourcesCachedService} from '../../../services/water-sources-cached.service';
import { GraphOptionsInterface } from '../ketos-graph.page';
import {KetosPopupService} from "../../../services/ketos-popup.service";

HC_exporting(Highcharts);
HC_exportData(Highcharts);

(function (highcharts) {
  highcharts.seriesType('lowmedhigh', 'boxplot', {
    keys: ['low', 'median', 'high'],
    tooltip: {
      pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: ' +
        'Low <b>{point.low}</b> - Median <b>{point.median}</b> - High <b>{point.high}</b><br/>'
    }
  }, {
    // Change point shape to a line with three crossing lines for low/median/high
    // Stroke width is hardcoded to 1 for simplicity
    drawPoints: function () {
      var series = this;
      Highcharts.each(this.points, function (point) {
        var graphic = point.graphic,
          verb = graphic ? 'animate' : 'attr',
          shapeArgs = point.shapeArgs,
          width = shapeArgs.width,
          left = Math.floor(shapeArgs.x) + 0.5,
          right = left + width,
          crispX = left + Math.round(width / 2) + 0.5,
          highPlot = Math.floor(point.highPlot) + 0.5,
          medianPlot = Math.floor(point.medianPlot) + 0.5,
          lowPlot = Math.floor(point.lowPlot) + 0.5 - (point.low === 0 ? 1 : 0); // Sneakily draw low marker even if 0

        if (point.isNull) {
          return;
        }

        if (!graphic) {
          point.graphic = graphic = series.chart.renderer.path('point').add(series.group);
        }

        graphic.attr({
          stroke: point.color || series.color,
          'stroke-width': 1
        });

        graphic[verb]({
          d: [
            'M', left, highPlot,
            'H', right,
            'M', left, medianPlot,
            'H', right,
            'M', left, lowPlot,
            'H', right,
            'M', crispX, highPlot,
            'V', lowPlot
          ]
        });
      });
    }
  });
})(Highcharts);

Highcharts['seriesTypes'].lollipop.prototype.drawLegendSymbol =
  Highcharts['seriesTypes'].line.prototype.drawLegendSymbol;
Highcharts.addEvent(Highcharts.Series, 'afterInit', function () {
  this['symbolUnicode'] = {
    circle: '●',
    diamond: '♦',
    square: '■',
    triangle: '▲',
    'triangle-down': '▼'
  }[this['symbol']] || '●';
});

@Component({
  selector: 'lib-ketos-aggregate-graph',
  templateUrl: './ketos-aggregate-graph.component.html',
  styleUrls: ['./ketos-aggregate-graph.component.scss'],
})
export class KetosAggregateGraphComponent extends KetosGraphBase implements OnInit {
  @ViewChild('chart', {static: false}) chart;
  chartInstance: Highcharts.Chart;

  @Input() mobileDesign: boolean;
  @Input() allExperiments: string[];

  private _multiGraph: boolean;
  @Input() set multiGraph(value: boolean) {
    this._multiGraph = value;
    if (this.chartOptions) {
      this.chartOptions.exporting.filename = `Ketos-Shield-Data-` + moment().utc(false).format('YYYY-MM-DD');
      this.updateChartFlag = true;
    }
  }

  get multiGraph(): boolean {
    return this._multiGraph;
  }

  _selectedGraphOptions: GraphOptionsInterface;
  @Input() set selectedGraphOptions(value: GraphOptionsInterface) {
    if (this.chartOptions) {
      this._selectedGraphOptions = cloneDeep(value);
      if (this.ketosGraphData) {
        this.ketosGraphData = this._ketosGraphData;
      }
     // if(value?.title){
        this.setTitle(value?.title)
      // }
      this.updateChartFlag = true;
    }
    if (value?.live !== this._selectedGraphOptions?.live) {
      this.liveUpdate.emit()
      this.updateChartFlag = true;
    }
    if (!value) {
      return;
    }
    this._selectedGraphOptions = cloneDeep(value);
  }

  get selectedGraphOptions(): GraphOptionsInterface {
    return this._selectedGraphOptions;
  }

  // this.chartInstance?.setSize(value, this._height || 600, false);

  @Input() set widthChanged(value: boolean) {
    this.chartInstance?.reflow();
  }

  @Input() set heightChanged(value: boolean) {
    this.chartInstance?.reflow();
  }

  @Input() set reflowGraph(value: boolean) {
    this.chartInstance?.reflow();
  }

  _ketosGraphData: KetosGraphDataInterface;
  @Input() set ketosGraphData(value: KetosGraphDataInterface) {
    console.log('set agg graph data:', value);
    this._ketosGraphData = value;
    this.setAggregatedData(value);
    this.showThresholdLines(value);
    this.setXaxisDate(value.dateInterval);
    super.setNoDataText(value.noDataText);
    this.updateChartFlag = true;
  }
  get ketosGraphData(): KetosGraphDataInterface {
    return this._ketosGraphData;
  }

  _showZoomButton: boolean;
  @Input() set showZoomButton(value: boolean) {
    this._showZoomButton = value;
    if (value === true) {
      this.chart?.chart?.showResetZoom();
    }
  }

  get showZoomButton(): boolean {
    return this._showZoomButton;
  }
  @Output() liveUpdate: EventEmitter<TimeIntervalInterface> = new EventEmitter<TimeIntervalInterface>();

  @Output() zoomed: EventEmitter<TimeIntervalInterface> = new EventEmitter<TimeIntervalInterface>();
  ///////////////////////////////////////////////////////////
  hideYAxis = false;

  initDone = false;
  highcharts = Highcharts;
  chartOptions: OptionsInterface;
  updateChartFlag = false;

  dataIdx = 0;
  zoomedMin;
  zoomedMax;

  hardYMin: number;
  hardYMax: number;

  constructor(public appStateService: AppStateService,
              public experimentConfigurationsService: ExperimentConfigurationsService,
              public resultsService: ResultsService,
              public orgSettingsService: OrganizationSettingsService,
              public unitSettingsService: UserSettingsCachedService,
              public uiPlatformService: UiPlatformService,
              private shieldDevicesService: ShieldDevicesCachedService,
              public formatResultValuePipe: FormatResultValuePipe,
              public formatDisplayNumberPipe: FormatDisplayNumberPipe,
              public waterSourcesCachedService: WaterSourcesCachedService,
              public ketosPopupService: KetosPopupService,
  ) {
    super();
  }

  ngOnInit() {
    this.createAggregatedChart([], this.multiGraph, this.selectedGraphOptions);
  }

  getIntervalData(min,max){
   // const dayscount = min.diff(max, 'days')
   const diffInMs = Math.abs(min - max);
   const dayscount =  diffInMs / (1000 * 60 * 60 * 24);
      if(dayscount === 1){
        return 'Hours'
      }
      else if(dayscount <= 7){
        return "days";
      }
      else if(dayscount >7 && dayscount <=31) {
        return "Week";
      }
      else if (dayscount >31 && dayscount <=366){
        return "Months";
      }
      else if(dayscount > 365){
        return "Years";
      }

  }
  setTitle(val){
    this.chartOptions.title.text = val;
  }
  setXaxisDate(dateInterval: any){

    if(dateInterval && !this.zoomedMin && !this.zoomedMax){
    const interval =this.getIntervalData(dateInterval.min.getTime(),dateInterval.max.getTime()) || 'days'
    this.chartOptions.xAxis[1].title.text = `Aggregated ${interval}, ${moment(dateInterval.min.getTime()).format('MM/DD/YYYY')} - ${moment(dateInterval.max.getTime()).format('MM/DD/YYYY')} `
    }else{
    const interval =this.getIntervalData(this.zoomedMin?.getTime(),this.zoomedMax?.getTime()) || 'days'
    this.chartOptions.xAxis[1].title.text = `Aggregated ${interval}, ${moment(this.zoomedMin?.getTime()).format('MM/DD/YYYY')} - ${moment(this.zoomedMax?.getTime()).format('MM/DD/YYYY')} `
    }
    //this.updateChartFlag = true;
  }
  createAggregatedChart(seriesSettings: KetosGraphSeriesSettingsInterface[], multiGraph: boolean, selectedGraphOptions?: GraphOptionsInterface) {
    super.createChart(seriesSettings?.map(se => se.locationName).join('_') || 'initial', multiGraph, selectedGraphOptions);
    const self = this;
    this.chartOptions.tooltip = {
      //   tooltip: {
      shared: true,
      // useHTML: true,
      // split: true,
      valueDecimals: 3,
      pointFormatter: function (this: any) {
        let symbol = '●';
        if (this.series['linkedParent'] && this.series['linkedParent']['symbolUnicode']) {
          symbol = this.series['linkedParent']['symbolUnicode'];
        }
        const experiment = this.series?.userOptions?.custom?.experiment;
        const bitmap = this.series?.userOptions?.custom?.sensorBitmap;
        const low = self.formatResultValuePipe.newPipesTransformConvertedValue(this.low, experiment, null, bitmap);
        const median = self.formatResultValuePipe.newPipesTransformConvertedValue(this.median, experiment, null, bitmap);
        const high = self.formatResultValuePipe.newPipesTransformConvertedValue(this.high, experiment, null, bitmap);
        return `<span style="color:${this.series['color']}">${symbol}</span> ${this.series.name}: Min <b>${low}</b> - Avg <b>${median}</b> - Max <b>${high}</b><br/>`;
      },
    };
  }

  // setAggregatedData(experiments: string[], intervalDate: TimeIntervalInterface, res: Shield_result_aggregates) {
  setAggregatedData(aggregatesGraphData: KetosGraphDataInterface) {
    this.dataIdx += 1;
    // const experiments = [...new Set(aggregatesGraphData.seriesSettings.map(se => se.experimentKey))]
    if (!this.chartOptions) {
      this. createAggregatedChart(aggregatesGraphData.seriesSettings, this.multiGraph, this.selectedGraphOptions);
    } else {
      super.setGraphZoom(this.chartOptions, this.multiGraph, this.selectedGraphOptions);
    }

    //console.log('got aggregate data', experiments, '\n', intervalDate.max.getTime()/1000,'\n', res.items.filter(value => experiments.indexOf(value.experiment) !== -1));
    if (this.selectedGraphOptions?.seperateYaxis === true) {
      this.chartOptions.yAxis = [];
      //this.chartOptions.yAxis.push(this.createYAxis(!this.hideYAxis, i % 2 === 0));
      //this.chartOptions.yAxis[i].title.text = conf.unit;
      //this.redrawEnabled=true;
    } else {
      this.chartOptions.yAxis = [this.createYAxis(!this.hideYAxis)];
      this.chartOptions.yAxis[0].plotLines = [];
      this.chartOptions.yAxis[0].title.text = '';
      this.setYaxisMinMaxFromOptions(this.selectedGraphOptions, this.chartOptions.yAxis[0]);
    }
    if (this.chartOptions?.series?.length > 0) {
      this.chartOptions.series[0].zones = [];
    }
    this.chartOptions.plotOptions = {
      series: {
        stickyTracking: true,
        whiskerWidth: 5
      }
    };

    this.hardYMin = this._selectedGraphOptions?.ymin || 0;
    this.hardYMax = this._selectedGraphOptions?.ymax || 0;

    this.chartOptions.series = [];
    this.chartOptions.xAxis[1].categories = [];
    for (let i = 0; i < 4; i++) {
      if (i < aggregatesGraphData.seriesSettings?.length) {
        const seriesSettings = aggregatesGraphData.seriesSettings[i];
        const sensorExpData = aggregatesGraphData.aggregatesMap ? aggregatesGraphData.aggregatesMap[seriesSettings?.waterSourceId] : {items: []};
        const expData = sensorExpData.items.find(agg => seriesSettings?.experimentKey === agg.experiment);

        if (expData) {
          // this.chartOptions.xAxis[1].categories = [];
          expData.items.map((item, i, arr) => {
            this.setCategoryLabels(item, i, arr, expData, aggregatesGraphData.dateInterval);
          });
        }

        const conf = this.experimentConfigurationsService.getConfByExperimentMethodBitmap(seriesSettings?.experimentKey, null, this.shieldDevicesService.dictBySensorId[aggregatesGraphData.seriesSettings[i]?.sensorId]?.exp_bitmap);
        const unit = UnitConverter.getTargetUnitFromConfAndUnitsDict(conf, this.unitSettingsService.selectedUnitsDict);

        let seriesName = conf.title;
        if (Object.keys(aggregatesGraphData.aggregatesMap || {}).length > 1) {
          seriesName = seriesSettings.locationName + ' ' + seriesName;
        }

        let serie;
        if (seriesSettings.seriesTypeOverride) {
          serie = this.createAreaRangeSeries(seriesSettings, i, unit, seriesName, expData);
        } else {
          serie = this.creatLowMedHighSeries(seriesSettings, i, unit, seriesName, expData, seriesSettings.experimentKey, this.shieldDevicesService.dictBySensorId[seriesSettings?.sensorId]?.exp_bitmap);
        }

        if (this.selectedGraphOptions?.seperateYaxis === true) {

          this.chartOptions.yAxis.push(this.createYAxis(!this.hideYAxis, i % 2 === 0));
          this.chartOptions.yAxis[i].title.text = unit;
          serie.yAxis = i;
        } else {
          if (!this.chartOptions.yAxis[0].title.text.includes(unit)) {
            this.chartOptions.yAxis[0].title.text += unit + ', ';
          }
          this.setYaxisMinMaxFromOptions(this.selectedGraphOptions, this.chartOptions.yAxis[0]);
        }

        serie.custom.hardYMin = this.hardYMin;
        serie.custom.hardYMax = this.hardYMax;
        this.chartOptions.series[i] = serie;
        this.setBdlAdlColorZones(aggregatesGraphData.seriesSettings[i].experimentKey, i, i, aggregatesGraphData.seriesSettings[i].sensorBitmap);

        //console.log('aggregated results: ', res.items.find(agg => experiments[i] === agg.experiment));
        // this.setAggregatedSeries(experiments[i], idx, res.items.find(agg => experiments[i] === agg.experiment));
      } // else {
      //   if (this.chartOptions.series[i]) {
      //     this.chartOptions.series[i].name = '';
      //     this.chartOptions.series[idx + 1].name = '';
      //     this.chartOptions.series[idx].data = [];
      //     this.chartOptions.series[idx + 1].data = [];
      //     this.chartOptions.series[idx].showInLegend = false;
      //     this.chartOptions.series[idx + 1].showInLegend = false;
      //   }
      // }
    }

    for (let i = 0; i < aggregatesGraphData.seriesSettings?.length; i++) {
      const seriesSettings = aggregatesGraphData.seriesSettings[i];
      const sensorExpData = aggregatesGraphData.aggregatesMap ? aggregatesGraphData.aggregatesMap[seriesSettings.waterSourceId] : {items: []};
      const expData = sensorExpData.items.find(agg => seriesSettings.experimentKey === agg.experiment);

      const conf = this.experimentConfigurationsService.getConfByExperimentMethodBitmap(seriesSettings.experimentKey, null, this.shieldDevicesService.dictBySensorId[aggregatesGraphData.seriesSettings[i]?.sensorId]?.exp_bitmap);
      const unit = UnitConverter.getTargetUnitFromConfAndUnitsDict(conf, this.unitSettingsService.selectedUnitsDict);

      const seriesData: number[] = [];
      if (expData) {
        expData.items.map(item => {
          if (item.doc_count === 0) {
            seriesData.push(null);
          } else {
            seriesData.push(UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(item.avg_value.value, conf, this.unitSettingsService.selectedUnitsDict));
          }
        });
      }

      let seriesName = conf.title;
      if (Object.keys(aggregatesGraphData.aggregatesMap || {}).length > 1) {
        seriesName = seriesSettings.locationName + ' ' + seriesName;
      }
      const lpSeries = this.createAggregateCoreDatapointSeries(i, aggregatesGraphData.seriesSettings.length + i, unit, seriesName, aggregatesGraphData.seriesSettings[i].seriesTypeOverride);
      // lpSeries.linkedTo = 'lowmedhigh' + i.toString();
      if (this.selectedGraphOptions?.seperateYaxis === true) {
        lpSeries.yAxis = i;
      }

      lpSeries.data = seriesData;
      this.chartOptions.series.push(lpSeries);
      this.setBdlAdlColorZones(seriesSettings.experimentKey, aggregatesGraphData.seriesSettings.length + i, i, seriesSettings.sensorBitmap);
    }

    if (this.selectedGraphOptions?.seperateYaxis !== true) {
      if (this.chartOptions.yAxis[0].title.text.length > 0) {
        this.chartOptions.yAxis[0].title.text = this.chartOptions.yAxis[0].title.text.substring(0, this.chartOptions.yAxis[0].title.text.length - 2);
      }

    }
    this.chartOptions.xAxis[0].visible = false;
    this.chartOptions.xAxis[1].visible = true;

    // hide legends if all values empty
    // TODO Some of the series may be forecasts or other visuals so this
    //   is not the best way to do this
    let graphHasData = false;
    for (const serie of this.chartOptions.series) {
      if (serie?.data?.length > 0) {
        graphHasData = true;
        break;
      }
    }
    this.chartOptions.exporting.enabled = graphHasData && aggregatesGraphData?.disableDownloading !== true;
  }

  setCategoryLabels(item: Aggregate_result, i: number, arr: Aggregate_result[], expData: Aggregate_results, intervalDate: TimeIntervalInterface) {
    var isBefore = moment.utc(item.key_as_string).isBefore(intervalDate.max.getTime());
    // console.log(item.key_as_string,"----",intervalDate.max.getTime())
    if (i !== expData.items.length - 1 && isBefore) {
      var isAfter = moment.utc(arr[i + 1].key_as_string).isAfter(intervalDate.max.getTime());
      if (!isAfter) {
        this.chartOptions.xAxis[1].categories[i] = `${this.formatTimeIgnoreTrailingZeros(moment.utc(item.key_as_string).local())} - ${this.formatTimeIgnoreTrailingZeros(moment.utc(arr[i + 1].key_as_string).local())}`;
      } else {
        this.chartOptions.xAxis[1].categories[i] = `${this.formatTimeIgnoreTrailingZeros(moment.utc(item.key_as_string).local())} - ${this.formatTimeIgnoreTrailingZeros(moment(intervalDate.max.getTime()))}`;
        // console.log('removed:', arr.pop());
      }
    } else {
      this.chartOptions.xAxis[1].categories[i] = `${this.formatTimeIgnoreTrailingZeros(moment.utc(item.key_as_string).local())} - ${this.formatTimeIgnoreTrailingZeros(moment(intervalDate.max.getTime()))}`;
    }
  }

  formatTimeIgnoreTrailingZeros(dateTime: moment.Moment): string {
    let format = 'MM/DD/YY';
    if (dateTime.hours() !== 0 || dateTime.minutes() !== 0) {
      format += ' H:mm';
    }
    return dateTime.format(format);
  }

  zoomSelection(chart, event): boolean {
    if (event.resetSelection === true) {
      this.dataIdx += 1;
      this._showZoomButton=false;
      this.zoomed.emit();
      setTimeout(() => {
        this.zoomedMax=null;
        this.zoomedMin=null;

      }, 500);
      return true;
    } else {
      if (!this.chartOptions.series?.length) {
        return false;
      }
      let totalBuckets = 0;
      for (const series of this.chartOptions.series) {
        if (series.data?.length > 0) {
          totalBuckets = series.data.length;
          break;
        }
      }
      if (event.xAxis && event.xAxis.length > 0 && totalBuckets > 0 && this.ketosGraphData.dateInterval) {
        const selection = event.xAxis[0];
        const offset = 0.5;
        const min = Math.floor(Math.max(selection.min + offset, 0));
        const max = Math.ceil(Math.min(selection.max + offset, totalBuckets));
        // console.log('min', min, 'max', max, 'total buckets:', totalBuckets, 'ev:', event);

        const totalTimeDiffMs = this.ketosGraphData.dateInterval.max.getTime() - this.ketosGraphData.dateInterval.min.getTime();
        const timeStep = totalTimeDiffMs / totalBuckets;
        this.zoomedMin = new Date(this.ketosGraphData.dateInterval.min.getTime() + min * timeStep);
        this.zoomedMax = new Date(this.ketosGraphData.dateInterval.min.getTime() + max * timeStep);

        this.dataIdx += 1;
        this.zoomed.emit({min: this.zoomedMin, max: this.zoomedMax});
      }
    }
    return false;
  }

  getSeriesIdentifier(seriesIdx: number, experimentKey: string, sensorId: number, seriesType: string): string {
    return seriesIdx.toString() + experimentKey + sensorId.toString() + seriesType + this.dataIdx.toString();
  }

  creatLowMedHighSeries(seriesSettings: KetosGraphSeriesSettingsInterface, seriesIdx: number, unit: string, title: string, rawData: Aggregate_results, seriesType?: string, sensorBitmap?: string) {
    const seriesData = [];
    if (rawData) {
      rawData.items.map(item => {
        if (item.doc_count === 0) {
          seriesData.push(null);
        } else {
          const conf = this.experimentConfigurationsService.getConfByExperimentMethodBitmap(seriesSettings.experimentKey, null, this.shieldDevicesService.dictBySensorId[seriesSettings?.sensorId]?.exp_bitmap);
          const minValue = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(item.min_value.value, conf, this.unitSettingsService.selectedUnitsDict);
          const maxValue = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(item.max_value.value, conf, this.unitSettingsService.selectedUnitsDict);
          if (this.hardYMin > minValue) {
            this.hardYMin = minValue;
          }
          if (this.hardYMax < maxValue) {
            this.hardYMax = maxValue;
          }
          seriesData.push([
            minValue,
            UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(item.avg_value.value, conf, this.unitSettingsService.selectedUnitsDict),
            maxValue
          ]);
       }
      });
    }
    return {
      id: this.getSeriesIdentifier(seriesIdx, seriesSettings.experimentKey, seriesSettings.waterSourceId, 'lowmedhigh'),
      linkedTo: this.getSeriesIdentifier(seriesIdx, seriesSettings.experimentKey, seriesSettings.waterSourceId, 'lollipop'),
      color: this.chartOptions.colors[seriesIdx],
      name: title,
      showInLegend: false,
      type: 'lowmedhigh',
      xAxis: 1,
      yAxis: 0,
      // data: sampleData[i],
      data: seriesData,
      zones: [],
      dataLabels: {
        enabled: true,
        color: '#ffffff',
        formatter: function () {
          return parseFloat(this.point.y.toFixed(2));
        },
      },
      tooltip: {
        // pointFormatter: function () {
        //   return '';
        //   // return `<span style="color:${this.series['color']}">${this.series['symbolUnicode']}</span> ${this.series.name}: <b>${parseFloat(this.y.toFixed(3))}${this.series['tooltipOptions'].valueSuffix}</b><br/>`;
        // },
        valueSuffix: ' ' + unit,
      },
      custom: {
        sensorBitmap: sensorBitmap,
        experiment: seriesType
      }
    };
  }

  createAreaRangeSeries(seriesSettings: KetosGraphSeriesSettingsInterface, seriesIdx: number, unit: string, title: string, rawData: Aggregate_results, seriesType?: string) {
    const seriesData = [];
    if (rawData) {
      rawData.items.map(item => {
        if (item.doc_count === 0) {
          seriesData.push(null);
        } else {
          const conf = this.experimentConfigurationsService.getConfByExperimentMethodBitmap(seriesSettings.experimentKey, null, this.shieldDevicesService.dictBySensorId[seriesSettings?.sensorId]?.exp_bitmap);
          const minValue = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(item.min_value.value, conf, this.unitSettingsService.selectedUnitsDict);
          const maxValue = UnitConverter.convertFromConfDefaultToUnitSettingsDictUnit(item.max_value.value, conf, this.unitSettingsService.selectedUnitsDict);
          if (this.hardYMin > minValue) {
            this.hardYMin = minValue;
          }
          if (this.hardYMax < maxValue) {
            this.hardYMax = maxValue;
          }
          seriesData.push([
            minValue,
            maxValue
          ]);
        }
      });
    }
    return {
      id: this.getSeriesIdentifier(seriesIdx, seriesSettings.experimentKey, seriesSettings.waterSourceId, 'lowmedhigh'),
      linkedTo: this.getSeriesIdentifier(seriesIdx, seriesSettings.experimentKey, seriesSettings.waterSourceId, 'lollipop'),
      color: this.chartOptions.colors[seriesIdx],
      fillOpacity: 0.25,
      dashStyle: 'LongDash',
      zIndex: 0,
      lineWidth: 0.5,
      name: title,
      showInLegend: false,
      marker: {
        enabled: true,
        symbol: chartSymbols[seriesIdx]
      },
      type: 'arearange',
      xAxis: 1,
      yAxis: 0,
      // data: sampleData[i],
      data: seriesData,
      zones: [],
      dataLabels: {
        enabled: false,
        color: '#ffffff',
        formatter: function () {
          return parseFloat(this.point.y.toFixed(2));
        },
      },
      tooltip: {
        pointFormatter: function () {
          let seriesUnitString = '';
          if (typeof this.series?.options?.custom?.unit === 'string' && this.series.options.custom.unit.length > 0) {
            seriesUnitString = ' (' + this.series.options.custom.unit + ')';
          }

          let low: string | number = 'N/A';
          if (typeof this.low === 'number') {
            low = parseFloat(this.low.toFixed(3))
          }
          let avg: string | number = 'N/A';
          if (this.series?.linkedParent?.data && this.series.linkedParent.data[this.index] && typeof this.series.linkedParent?.data[this.index].y === 'number') {
            avg = parseFloat(this.series.linkedParent?.data[this.index].y.toFixed(3));
          }
          let high: string | number  = 'N/A';
          if (this.high) {
            high = parseFloat(this.high.toFixed(3));
          }
          return `<span style="color:${this.series.color}">\u25CF</span> ${this.series.name}${seriesUnitString}: ` +
            `Low <b>${low}</b> - Avg <b>${avg}</b> - High <b>${high}</b><br/>`;
        },
        valueSuffix: ' ' + unit,
      },
      custom: {
        unit: unit
      }
    };
  }

  createAggregateCoreDatapointSeries(colorIdx: number, seriesIdx: number, unit: string, title: string, seriesType?: string): SeriesLollipopOptions {
    const series = super.createDatapointSeries(colorIdx, this.getSeriesIdentifier(colorIdx, this.ketosGraphData.seriesSettings[colorIdx].experimentKey, this.ketosGraphData.seriesSettings[colorIdx].waterSourceId, 'lollipop'), unit, seriesType);
    series.name = title;
    if (seriesType === 'spline') {
      delete series.type;
    }
    series.lineWidth = 2.5;
    series.xAxis = 1;
    series.marker.symbol = chartSymbols[seriesIdx - this.ketosGraphData.seriesSettings.length];
    series.zIndex = 1;
    delete series.dataLabels;
    series.showInLegend = true;
    delete series.tooltip;
    series.enableMouseTracking = false;
    return series;
  }
}

