import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lib-ketos-loading-or-empty',
  templateUrl: './ketos-loading-or-empty.component.html',
  styleUrls: ['./ketos-loading-or-empty.component.scss'],
})
export class KetosLoadingOrEmptyComponent<T> implements OnInit {
  @Input() loadingData: boolean;
  @Input() noDataText: string;
  @Input() loadingDataText: string;
  @Input() mobileNoData: boolean;
  @Input() noData: boolean;
  @Input() fullHeight = false;

  constructor() { }

  ngOnInit() {}

}
