import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'runCustomFunction'
})
export class RunCustomFunctionPipe implements PipeTransform {

  transform<T, K>(value: T, customFunction: (value: T, ...funcArgs: unknown[]) => K, ...args: unknown[]): K {
    return customFunction(value, ...args);
  }
}
