import {Component, EventEmitter, Input,Output, OnInit} from '@angular/core';
import { DashboardUiStateService } from '../../services/dashboard-ui-state.service';
import {AppState, AppStateService, TimeIntervalInterface} from "../../services/app-state.service";
import { KetosAuditService } from '../../services/ketos-audit.service';
import { SensorTypes } from '../../models/sensor-types.enum';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'lib-dashboard-module-header',
  templateUrl: './dashboard-module-header.component.html',
  styleUrls: ['./dashboard-module-header.component.scss'],
})
export class DashboardModuleHeaderComponent implements OnInit {
@Input() maximize: boolean;
@Input() showRTDSettings: boolean = false;
@Input() title: string;
@Output() setMaximize: EventEmitter<boolean> = new EventEmitter<boolean>();
@Output() setCalendarDays:  EventEmitter<any> = new EventEmitter<any>();
public selectedSensorType: SensorTypes;
public lastUpdated = new Date();
  constructor(public dashboardUiStateService:DashboardUiStateService, public appStateService: AppStateService,private auditService: KetosAuditService) { }

  ngOnInit() {
    if(this.showRTDSettings){
    const appStateValue = this.appStateService.getAppState().getValue()
    this.auditService.postAuditEvent('/dashboard/real-time-data', appStateValue.sensorType + ':real-time-data', 'user navigation', appStateValue.sensorType + ':real-time-data');

    this.appStateService.getAppState()
      .subscribe((appState: AppState) => {
        this.selectedSensorType = appState.sensorType;
      });
    }
  }

  onSelectDateInterval(timeInterval: TimeIntervalInterface) {
    if (timeInterval.max.getTime() - timeInterval.min.getTime() < 1000) {
      timeInterval.min = new Date(timeInterval.max.getTime() - 1000 * 60 * 60);
    }
      this.appStateService.selectedTimeInterval = Object.assign({}, timeInterval);
      this.setCalendarDays.emit(timeInterval);
  }

  calculateDays(interval) {
    const formatted = {
      min: interval.min.getTime(),
      max: interval.max.getTime()
    };
    return Utils.calculateDays(interval);
  }

}
