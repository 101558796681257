/*
 * Copyright (C) 2016-2019 KETOS INC Confidential
 * All Rights Reserved.
 * Author(s): Brian Smith
 */

import * as cloneDeep from 'lodash/cloneDeep';

export class Utils {
  public static calculateDays(interval) {
    if (interval === undefined || interval === null) {
      return 0 + 'days';
    }

    const date1: any = new Date(interval.min);
    const date2: any = new Date(interval.max);
    const diff = Math.abs(date1.getTime() - date2.getTime()) / 3600000;
    let days = Math.ceil(diff / 24);

    if (days === 0) {
      days = days + 1;
    }

    if (days > 1) {
      return days + ' days';
    } else {
      return days + ' day';
    }
  }

  public static getMinMax(data, timeInterval) {
    let min, max;
    const sortedData = [];
    const time = {
      min: timeInterval.min.getTime(),
      max: timeInterval.max.getTime()
    };

    if (data) {
      data.forEach((item) => {
        if (item.time >= time.min && item.time <= time.max) {
          sortedData.push(item.value);
        }
      });
    }

    min = Math.min(...sortedData);
    max = Math.max(...sortedData);

    if (isFinite(min) && isFinite(max)) {
      return [min, max];
    }

    if (isFinite(min)) {
      return [min, min];
    }

    if (isFinite(max)) {
      return [max, max];
    }

    return ['N/A', 'N/A'];
  }

  public static download(dataUrl: string): boolean {
    const a = document.createElement('a');
    // a.setAttribute('target', '_blank');
    a.setAttribute('href', dataUrl);
    // a.style.setProperty('visibility', false);
    a.style.display = 'none';
    a.style.visibility = 'hidden';

    const e = document.createEvent('MouseEvents');
    e.initEvent('click', false, true);
    // e.initMouseEvent("click", true, false,
    //   document.defaultView, 0, 0, 0, 0, 0,
    //   false, false, false, false, 0, null);
    a.dispatchEvent(e);
    a.remove();

    return false;
  }
}

export function CloneDeep<T>(input: T): T {
  return cloneDeep(input);
}
