import { Injectable } from '@angular/core';
import {AlertController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class KetosPopupService {

  constructor(private alertController: AlertController) {}

  async presentOkAlert(header: string, message = '', cssClassName='') {
    const cssClass = cssClassName ? cssClassName + ' dark-alert' : 'dark-alert'
    const alert = await this.alertController.create({
      header,
      cssClass: cssClass,
      message,
      buttons: ['OK']
    });
    await alert.present();
  }

  confirmPopup(popupTexts: {title?: string, message?: string, cancel?: string, ok?: string}): Promise<boolean> {
    return new Promise<any>( async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: popupTexts.title,
        cssClass: 'dark-alert confirm-popup',
        message: popupTexts.message,
        buttons: [
          {
            text: popupTexts.cancel ? popupTexts.cancel : 'Cancel',
            role: 'cancel',
            handler: () => {
              reject('Cancel clicked');
            }
          },
          {
            text: popupTexts.ok ? popupTexts.ok : 'OK',
            handler: () => {
              resolve(true);
            }
          }
        ]
      });

      await alert.present();
    });
  }

  confirmOkPopup(popupTexts: {title: string, message?: string, ok?: string}, cssClassesString = 'dark-alert confirm-popup', backdropDismiss = true): Promise<any> {
    return new Promise<any>( async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: popupTexts.title,
        cssClass: cssClassesString,
        message: popupTexts.message,
        backdropDismiss: backdropDismiss,
        buttons: [
          {
            text: popupTexts.ok ? popupTexts.ok : 'OK'
          }
        ]
      });
      alert.onDidDismiss().then(() => {
        resolve(true);
      })

      await alert.present();
    });
  }
}
