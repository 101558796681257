import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PopoverController} from '@ionic/angular';


@Component({
  selector: 'lib-select-popover',
  templateUrl: './select-popover.component.html',
  styleUrls: ['./select-popover.component.scss'],
})
export class SelectPopoverComponent<T> implements OnInit {
  @Input() titleKey = 'title';
  @Input() componentName: string;
  @Input() uniqueKey = 'id';

  _values: T[];
  @Input() set values(values: T[]) {
    console.log(values,'options')
    this._values = values;
    const oldSelectedValuesDict = this.selectedValuesDict;
    if (Array.isArray(this._selected) && oldSelectedValuesDict) {
      this.selectedValuesDict = {};
      const newValuesIdDict: {[key: number]: any} = {};
      values?.map(value => {
        const selected = oldSelectedValuesDict[value[this.uniqueKey]] || false;
        this.selectedValuesDict[value[this.uniqueKey]] = selected;
        if (selected) {
          newValuesIdDict[value[this.uniqueKey]] = value;
        }
      });
      this._selected = this._selected.filter(val => !!newValuesIdDict[val[this.uniqueKey]]);
      this.calculateAllSelected();
      this.selectedChange.emit(this._selected);
    }
  }

  get values(): T[] {
    return this._values;
  }

  selectedValuesDict: {[key: number]: boolean} = {};
  _selected: T[];
  @Input() set selected(values: T[]) {
    console.log(values,'selected')

    if (values === undefined) {
      return;
    }
    this._selected = values;
    this.selectedValuesDict = {};
    values?.map(value => {
      this.selectedValuesDict[value[this.uniqueKey]] = true;
    });
    this.calculateAllSelected();
  }
  get selected(): T[] {
    return this._selected;
  }

  visibleValues: T[];
  selectedFilteredOut: T[];
  @Output() selectedChange = new EventEmitter<T[]>();

  @Input() showConfirm = false;
  @Input() maxSelected = Number.MAX_SAFE_INTEGER; // for efficiency use one number smaller
  @Input() showSelectAll = false;
  @Input() showClearAll = false;
  @Input() singleSelect = false;
  @Input() showSearch = false;
  @Input() updateSelected: (selected: T[]) => {};

  @Input() search = '';
  @Output() searchChange = new EventEmitter<string>();
  allSelected: boolean;

  constructor(private popoverController: PopoverController) {
  }
  filterBasedOnSearch = (array: T[],
                         searchTerm: string): T[] => {
    const search = searchTerm?.trim()?.toLowerCase();
    if (!search || search?.length < 1) {
      this.visibleValues = array?.slice();
      this.selectedFilteredOut = [];
      return array;
    }
    console.debug('filterBasedOnSearch', array, searchTerm);
    this.selectedFilteredOut = [];
    const visibleValues = array?.filter(obj => {
      const isVisible = obj[this.titleKey]?.toLowerCase().includes(search);
      if (!isVisible && this.selectedValuesDict[obj[this.uniqueKey]]) {
        this.selectedFilteredOut.push(obj);
      }
      return isVisible;
    });
    this.visibleValues = visibleValues.slice();
    return visibleValues;
  }

  ngOnInit() {
  }

  searchChanged(value: string) {
    this.searchChange.emit(value);
    // if (this.clearSelectionsOnSearch) {
    //   this.selected = [];
    // }
  }

  selectedValue(value: T, selectedFilteredIdx?: number) {
    const isSelected = !this.selectedValuesDict[value[this.uniqueKey]];
    this.selectedValuesDict[value[this.uniqueKey]] = isSelected;
    if (isSelected) {
      if (!this._selected) {
        this._selected = [value];
      } else {
        this._selected.push(value);
      }
    } else {
      this._selected = this._selected?.filter(val => val[this.uniqueKey] !== value[this.uniqueKey]);
      if (typeof selectedFilteredIdx === 'number') {
        this.selectedFilteredOut = this.selectedFilteredOut.filter(val => this.selectedValuesDict[val[this.uniqueKey]]);
      }
    }
    this.calculateAllSelected();
    this.selectedChange.emit(this.selected);
    this.updateSelected?.(this.selected);
  }

  selectedSingleValue(value: T) {
    const isSelected = !this.selectedValuesDict[value[this.uniqueKey]];
    if (!isSelected) {
      return;
    }
    if (!this._selected) {
      this._selected = [value];
    }

    this._selected.map(sel => this.selectedValuesDict[sel[this.uniqueKey]] = false);
    this.selectedValuesDict[value[this.uniqueKey]] = true;
    this._selected = [value];
   // console.log(this.uniqueKey,'uni',this._selected, this.selectedValuesDict)
    this.selectedChange.emit(this.selected);
    this.updateSelected?.(this.selected);
  }

  selectAllClicked(checked: boolean) {
    if (checked) {
      this.selected = [];
    } else {
      this.selected = this.visibleValues?.slice() || [];
    }
    if (this.selectedFilteredOut?.length > 0) {
      this.selectedFilteredOut = [];
    }
    this.selectedChange.emit(this.selected);
    this.updateSelected?.(this.selected);
  }

  clearAllClicked() {
    this.selected = [];
    this.selectedChange.emit(this.selected);
    this.updateSelected?.(this.selected);
  }

  calculateAllSelected() {
    const notSeleted = this._values?.map(val => this.selectedValuesDict?.[val[this.uniqueKey]])
      .find(flag => flag !== true);
    this.allSelected = !notSeleted;
  }


  confirm() {
    this.updateSelected(this.selected);
    this.popoverController.dismiss(this.selected);
    this.updateSelected = null;
  }

  cancel() {
    this.popoverController.dismiss();
    this.updateSelected = null;
  }
}
