import {Component, Input, OnInit,ViewChild} from '@angular/core';
import * as Highcharts from 'highcharts';
import * as baseHighcharts from 'highcharts'
import * as cloneDeep from 'lodash/cloneDeep';
import {ExperimentConfigurationsService} from '../../../services/experiment-configurations.service';
import {Subscription} from 'rxjs';
import {ChartDataService} from '../../../services/chart-data.service';
import {Shield_threshold} from '../../../../../../api/src/lib/models/shield-_threshold';
import {AppStateService, TimeIntervalInterface} from '../../../../../../ketos-lib/src/lib/services/app-state.service';

const HighchartsMore = require('highcharts/highcharts-more.src');
HighchartsMore(Highcharts);
const HC_solid_gauge = require('highcharts/modules/solid-gauge.src');
HC_solid_gauge(Highcharts);

const HighChartsDumbbell = require('highcharts/modules/dumbbell')
HighChartsDumbbell(baseHighcharts);


const HighChartsLollipop = require('highcharts/modules/lollipop')
HighChartsLollipop(baseHighcharts);
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';

HC_exporting(Highcharts);
HC_exportData(Highcharts);

(function (highcharts) {
  highcharts.seriesType('lowmedhigh', 'boxplot', {
    keys: ['low', 'median', 'high'],
    tooltip: {
      pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: ' +
        'Low <b>{point.low}</b> - Median <b>{point.median}</b> - High <b>{point.high}</b><br/>'
    }
  }, {
    // Change point shape to a line with three crossing lines for low/median/high
    // Stroke width is hardcoded to 1 for simplicity
    drawPoints: function () {
      var series = this;
      Highcharts.each(this.points, function (point) {
        var graphic = point.graphic,
          verb = graphic ? 'animate' : 'attr',
          shapeArgs = point.shapeArgs,
          width = shapeArgs.width,
          left = Math.floor(shapeArgs.x) + 0.5,
          right = left + width,
          crispX = left + Math.round(width / 2) + 0.5,
          highPlot = Math.floor(point.highPlot) + 0.5,
          medianPlot = Math.floor(point.medianPlot) + 0.5,
          lowPlot = Math.floor(point.lowPlot) + 0.5 - (point.low === 0 ? 1 : 0); // Sneakily draw low marker even if 0

        if (point.isNull) {
          return;
        }

        if (!graphic) {
          point.graphic = graphic = series.chart.renderer.path('point').add(series.group);
        }

        graphic.attr({
          stroke: point.color || series.color,
          'stroke-width': 1
        });

        graphic[verb]({
          d: [
            'M', left, highPlot,
            'H', right,
            'M', left, medianPlot,
            'H', right,
            'M', left, lowPlot,
            'H', right,
            'M', crispX, highPlot,
            'V', lowPlot
          ]
        });
      });
    }
  });
  // highcharts.wrap(highcharts.Chart.prototype, 'getDataRows', function(proceed, multiLevelHeaders) {

  //   var rows = proceed.call(this, multiLevelHeaders);

  //   rows = rows.map(row => {
  //     if (row.x) {
  //       row[0] = Highcharts.dateFormat('%d-%b-%y', row.x * 1000);
  //     }
  //     return row;
  //   });

  //   return rows;
  // });
})(Highcharts);

@Component({
  selector: 'lib-ketos-interval-chart',
  templateUrl: './ketos-interval-chart.component.html',
  styleUrls: ['./ketos-interval-chart.component.scss'],
})
export class KetosIntervalChartComponent implements OnInit {
  @ViewChild('chart', {static: false}) chart;
  self:any
  chartInstance: Highcharts.Chart;

  private _sensorId: number;
  @Input() set sensorId(value: number) {
    this._sensorId = value;
    if (this.initDone) {
      this.init();
    } else {
      this.initOnce();
    }
  }

  get sensorId(): number {
    return this._sensorId;
  }

  private _selectedInterval: string;
  @Input() set selectedInterval(value: string) {
    this._selectedInterval = value;
    if (this.initDone) {
      this.init();
    } else {
      this.initOnce();
    }
  }

  _headerWidth: number;
  @Input() set headerWidth(value: number) {
    if (value) {
      this._headerWidth = value;
      this.chartInstance?.setSize(value, 600, false);
    }
  }
  get headerWidth(): number {
    return this._headerWidth;
  }

  get selectedInterval(): string {
    return this._selectedInterval;
  }

  private _dateRange: TimeIntervalInterface = null;
  @Input() set dateRange(value: TimeIntervalInterface) {
    this._dateRange = value;
    if (this.initDone) {
      this.init();
    } else {
      this.initOnce();
    }
  }

  get dateRange(): TimeIntervalInterface {
    return this._dateRange;
  }

  _selectedItems: string[] = null;
  @Input() set selectedItems(value: string[]) {
    this._selectedItems = value;
    if (this.initDone) {
      this.init();
    } else {
      this.initOnce();
    }
  }

  get selectedItems(): string[] {
    return this._selectedItems;
  }
  loadDataCheck:boolean=true
  dynamicWidth=800;
  lowmedhighFlag:boolean=false
  lowmedhighdata=null;
  highcharts = baseHighcharts;
  lowmedhighCharts = Highcharts
  updateLowmedhighFlag;
  subscription = new Subscription();
  updateFlag;
  //chart;
  chartCallback;
  title = 'myHighchart';
  //chartConstructor = 'chart';
  chartOptions:any;
  lowmedhighoptions:any;
  sensorDetails = null;
  //sensorId=null;
  thresholdDataInternal:Shield_threshold[]=[];
  thresholdDataExternal:Shield_threshold[]=[];
  derivedLowMedHighExperiments:string[]=[];
  originalExternalThreshold;
  originalInternalThreshold;
  initDone=false;
  constructor(
    // private selectChemicalService: SelectChemicalService,
    private experimentConfigurationsService: ExperimentConfigurationsService,
    private chartDataService: ChartDataService,
    private appStateService: AppStateService) {
}

  ngOnInit() {
    // this.createLowMedHighChart();

    // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // if (isMobile) {
    //   if(window.innerHeight > window.innerWidth){
    //     this.chartOptions.chart.width=window.innerWidth;
    //     this.lowmedhighoptions.chart.width=window.innerWidth;
    //   }
    //   window.addEventListener('orientationchange', ()=>{
    //     switch(window.orientation) {
    //       case -90: case 90:
    //         console.log('landscape',this.chartOptions);
    //         this.chartOptions.chart.width=800;
    //         this.lowmedhighoptions.chart.width=800;
    //         this.updateLowmedhighFlag=true
    //         break;
    //       default:
    //         console.log('portrait');
    //         this.chartOptions.chart.width=400;
    //         this.lowmedhighoptions.chart.width=400;
    //         this.updateLowmedhighFlag=true;
    //         break;
    //     }
    //   });
    //   this.updateLowmedhighFlag=true
    // }
    // this.subscription=this.selectChemicalService.data.subscribe((data:any)=>{
    //   console.log(data)
    //   if(data && data.length && this.selectedInterval !== "all_data"){
    //     this.lowmedhighdata=cloneDeep(data)
    //     this.init()
    //   }
    // })
    // return;
  }
  initOnce() {
    if (this.initDone || !this.selectedInterval || !this.dateRange || !this.selectedItems || !this.sensorId  ) {
      return;
    }
    this.initDone = true;
    this.createLowMedHighChart();
    this.init()
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  init() {
    if (!this.selectedInterval || !this.dateRange || !this.selectedItems || !this.sensorId ) {
      return;
    }
    console.log("in------------",this.selectedItems)

    this.loadDataCheck=true;
    this.lowmedhighFlag=true;
    this.lowmedhighoptions.series=[];
    this.lowmedhighoptions.xAxis.categories=[]

    // this.sensorDetails = this.appStateService.getAppState().getValue();
    // this.sensorId = this.sensorDetails.location.id
    // this.subscription=this.selectChemicalService.data.subscribe((data:any)=>{
    //   console.log(data)
    //   if(data && data.length && this.selectedInterval !== "all_data"){
    //     this.lowmedhighdata=cloneDeep(data)
    //     //this.init()
    //   }
    // })
    // if(!this.lowmedhighoptions){
    //   this.createLowMedHighChart();
    // }
        const dataObj = {
          startDate:this.chartDataService.formatDateToDisplayTimestamp(this.dateRange.min),
          endDate:this.chartDataService.formatDateToDisplayTimestamp(this.dateRange.max),
          interval:this.selectedInterval,
          sensorId:this.sensorId.toString(),
        }
        this.chartDataService.getDerivedLowMedHighData(dataObj)
        .subscribe(res => {
          console.log(res,"DerviedLowmedhighdata")
          let yMax;
          this.derivedLowMedHighExperiments = cloneDeep(res.items)
          if(this.selectedItems && this.selectedItems.length > 0){
          this.chartDataService.getDataForInterval(this.selectedItems.map(obj => obj.toLowerCase()), this.dateRange,this.selectedInterval,this.derivedLowMedHighExperiments)
          .subscribe(results => {
            console.log(results)
            for(let i=0;i<results.length;i++){
              this.lowmedhighoptions.series.push({name:results[i].name,data:results[i].predata})
              for(let j=0; j<results[i].categories.length; j++){
                this.lowmedhighoptions.xAxis.categories.push(results[i].categories[j])
              // this.lowmedhighoptions.xAxis.labels.formatter= this.getformatter(results[i].categories[j])
              }
            }
            if(this.lowmedhighoptions.length > 0){
              yMax=this.findYAxisMax(this.lowmedhighoptions.series)
            }
            if(this.lowmedhighoptions.series.length >1){
                  //console.log(this.lowmedhighoptions.xAxis.labels.formatter)
              this.lowmedhighoptions.yAxis.plotLines=[]
            //setTimeout(()=>{
              this.lowmedhighoptions.yAxis.max= yMax
              //this.updateLowmedhighFlag=true
            //},2000)
          }else{
            //console.log(this.lowmedhighoptions.series)
            if(this.lowmedhighoptions.series.length){
            this.drawPlotLines(this.lowmedhighoptions,this.lowmedhighoptions.series[0].name.toLowerCase(),yMax)
            }else{
            this.lowmedhighoptions.yAxis.plotLines=[];
            this.loadDataCheck=false;
            }
            //this.updateLowmedhighFlag=true
          }
          })
        console.log(this.lowmedhighoptions.series)
        console.log(this.lowmedhighoptions.xAxis.categories)

        this.updateLowmedhighFlag = true;
        //this.lowmedhighoptions.series=[];
        }
        })

  }
    // getformatter(val){
    //   console.log(val, Highcharts.dateFormat('%a %d %b', val))
    //   return Highcharts.dateFormat('%a %d %b', val);
    // }
  getThresholdValue(type,exp){
    return this.appStateService[type].getShieldThresholdForSensorIdAndExperiment(this.sensorId, exp);
  }
  drawPlotLines(chartType,experiment,yMax){
    const appState = this.appStateService.getAppState().getValue();
    this.originalExternalThreshold = this.getThresholdValue("thresholdsExternalService",experiment)
    this.originalInternalThreshold = this.getThresholdValue("thresholdsInternalService",experiment)

    if(!this.lowmedhighoptions.yAxis.plotLines.length){
      if(this.originalInternalThreshold.max_value !== this.originalExternalThreshold.max_value){
      chartType.yAxis.plotLines.push(this.updatePlotLineMinObject(this.originalInternalThreshold.max_value))
      chartType.yAxis.plotLines.push(this.updatePlotLineMaxObject(this.originalExternalThreshold.max_value))
      }else{
        chartType.yAxis.plotLines.push(this.updatePlotLineMinMaxObject(this.originalInternalThreshold.max_value))
      }
      if(yMax)
      chartType.yAxis.max=this.originalExternalThreshold.max_value > yMax?this.originalExternalThreshold.max_value+5:yMax
      }
  }
  getDataPointColor(yData,config,extVal){
    //console.log(config.bdl,d.data[0].y,extVal,config.key)
    //const yData = d.processedYData[0][0]
    if (config.bdl !== 0 && yData < config.bdl) { // Below or Above Detection Limit
      return '#00ff00';
    } else if (yData > extVal  ) { // Above threshold
      // } else if (d.value >= threshold) { // Above threshold
      if (config.key === 'dissolved_oxygen') {
        return '#ffffff';
      }

      return '#ff0000';
    } else {
      if (config.key === 'dissolved_oxygen') {
        return '#ff0000';
      }

      return '#ffffff';
    }
  }
  setdataStrokeStatus(event,self){
    if(event.series.length){
      for(var i=0;i<event.series.length;i++){
        for(var exp of self.experimentConfigurationsService.experimentConfigurations){
      // for(var data of this.chartOptions.series){
        if(event.series[i].name === exp.key ){
          const externalThresholdValue= self.getThresholdValue("thresholdsExternalService",event.series[i].name)
          for(var data of event.series[i].points){
            let color = self.getDataPointColor(data.y,exp,externalThresholdValue.max_value)
            data.color=color
            data.series.color=color
            if(data.graphic !== undefined){
             console.log(data)
              if(data.graphic.element.attributes.stroke){
                data.graphic.element.attributes.stroke=color
              data.graphic.element.attributes[1].nodeValue=color;
              data.graphic.element.attributes[1].value=color
              }
              data.graphic.stroke=color
            }
          }
          }
        }
      }
    }
  }
  findYAxisMax(arr){
    // console.log(arr)
     return arr.reduce((acc,cur) =>{
       if(cur.data[0].y !== undefined){
         if(acc < cur.data[0].y ){
           acc=cur.data[0].y
         }
       }else{
         //console.log(cur.data)
         const max=[]
         for(var i=0;i<cur.data.length;i++){
           max.push(...cur.data[i])
         }
       //const max = [...cur.data[0],...cur.data[1]].sort((a,b) => b-a)
         max.sort((a,b) => b-a)
         //console.log(max)

       if(acc <  max[0]){
         acc=max[0]
       }
       }
       return acc
     }, 0)
   }
   updatePlotLineMinMaxObject(val){
     return {
       value:val,
       color: 'red',
       dashStyle: 'shortdash',
       width: 2,
       zIndex: 1.67,
       label: {
         text: '🠗 Internal Threshold Limit 🠕 External Threshold Limit',
         style: {
           color: '#ffffff'
         },
       }
     }
   }
   updatePlotLineMinObject(val){
     return {
       value:val,
       color: 'green',
       dashStyle: 'shortdash',
       width: 2,
       zIndex: 1.67,
       label: {
         text: '🠗 Internal Threshold Limit ',
         style: {
           color: '#ffffff'
         },
       }
     }
   }
   updatePlotLineMaxObject(val){
     return {
       value:val,
       color: 'red',
       dashStyle: 'shortdash',
       width: 2,
       zIndex: 1.67,
       label: {
         text: '🠕 External Threshold Limit',
         style: {
           color: '#ffffff'
         },
       }
     }
   }
  createLowMedHighChart(){
    let self = this
    this.lowmedhighoptions={
      chart:{
        type:'lowmedhigh',
        backgroundColor: '#242434',
        zoomType: 'xy',
        width:this.headerWidth,
        events:{
          redraw: function(){
            var chart = this;
            // if(self.lowmedhighdata.length)
            //   self.setdataStrokeStatus(chart,self)
            var arr = chart.options.exporting.buttons.contextButton.menuItems;
            //console.log(arr)
             var index = arr.indexOf("viewData");
             if (index !== -1) arr.splice(index, 1);
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: '',
        style: {
          color: '#ffffff'
        }
      },
      xAxis: {
            type: 'categories',
        title: {
          text: 'Date'
        },
        lineColor: '#ffffff',
        tickColor: '#ffffff',
        labels: {
          style: {
            color: '#ffffff'
          }
        },
        style: {
          color: '#ffffff'
        },
       categories: [],
        //min:this.dateRange.min,
       // max:this.dateRange.max
      },
      yAxis: {
        title: {
          text: 'Contaminant Concentration (ppm)',
          style: {
            color: '#ffffff'
          },
        },
        gridLineDashStyle: 'dot', // 'ShortDash'
        gridLineColor: '#AAAAAA',
        plotLines: [],
        style: {
          color: '#ffffff'
        },
        labels: {
          style: {
            color: '#ffffff'
          }
        },
        min: 0
      },
      legend: {
        itemStyle: {
          color: '#7cb5ec'
        },
        itemHoverStyle: {
          color: '#a4c0e4'
        }
      },
      tooltip: {
        shared: true
      },
      plotOptions: {
        series: {
          marker: {}
        }
    },
      series:[],
        exporting: {
          enabled: true,
          }
    }
  }
}
