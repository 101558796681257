import { Injectable } from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import { DropdownValueInterface,DropdownOptionsInterface } from '../../components/shared/dropdown/models/dropdown-options.interface';


export interface CropsInterface {
    title: string;
    value: string;
    checked: boolean;
}
@Injectable({
    providedIn:'root'
})
export class CropService {

  private dataSource = new BehaviorSubject<DropdownOptionsInterface[]>(null);
  data = this.dataSource.asObservable();

  constructor() { }

  updatedDataSelection(data){
    this.dataSource.next(data);
  }

}
