/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Deprecated_shield_test } from '../models/deprecated-_shield-_test';
import { Deprecated_shield_schedule_test_dto } from '../models/deprecated-_shield-_schedule-_test-_dto';
import { Deprecated_shield_test_schedule2 } from '../models/deprecated-_shield-_test-_schedule-2';
import { Shield_test_schedule1 } from '../models/shield-_test-_schedule-1';
import { Deprecated_shield_test_details } from '../models/deprecated-_shield-_test-_details';

/**
 * Shield test schedule related operations
 */
@Injectable({
  providedIn: 'root',
})
class TestScheduleService extends __BaseService {
  static readonly postCalculateExperimentTimesPath = '/test_schedule/calculate_experiment_times/{sensor_id}';
  static readonly getGetExperimentTimesPath = '/test_schedule/get_experiment_times/{sensor_id}';
  static readonly GetTestDetailsForAGivenTimeframePath = '/test_schedule/shield/completed_tests/{water_source_id}/{page}/{per_page}/';
  static readonly GenerateNextSetOfTestsFromTheTestScheduleUsedByALambdaFnPath = '/test_schedule/shield/generate_next_tests/';
  static readonly GetAllScheduledTestsToRunNowRequiredByALambdaFnPath = '/test_schedule/shield/get_tests_to_run_now/';
  static readonly ScheduleANewManualScheduledTestWithRecurringOptionsPath = '/test_schedule/shield/schedule_test/';
  static readonly GetAScheduledTestPath = '/test_schedule/shield/scheduled_test/{test_id}/';
  static readonly getScheduledTestsPath = '/test_schedule/shield/scheduled_tests/{start_date}/{end_date}/';
  static readonly UpdateATestSchedulePath = '/test_schedule/shield/schedules/';
  static readonly getTestSchedulesPath = '/test_schedule/shield/schedules/{start_date}/{end_date}/';
  static readonly DeleteTestSchedulesPath = '/test_schedule/shield/schedules/{test_ids}/';
  static readonly GetATestSchedulePath = '/test_schedule/shield/schedules/{test_id}/';
  static readonly GetTestDetailsForAGivenTimeframe_1Path = '/test_schedule/shield/tests/{start_date}/{end_date}/';
  static readonly MaintenanceForAllScheduledTestsRequiredByALambdaFnPath = '/test_schedule/shield/tests_maintenance/';
  static readonly CancelTheOngoingTestPath = '/test_schedule/shield/{sensor_id}/test/{test_number}/{operating_status}';
  static readonly GetTheNextTestNumberBasedOnSensorIdAndOperatingStatusPath = '/test_schedule/test_number/{sensor_id}/{operating_status}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param sensor_id undefined
   */
  postCalculateExperimentTimesResponse(sensorId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/test_schedule/calculate_experiment_times/${encodeURIComponent(String(sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_id undefined
   */
  postCalculateExperimentTimes(sensorId: string): __Observable<null> {
    return this.postCalculateExperimentTimesResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param sensor_id undefined
   */
  getGetExperimentTimesResponse(sensorId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/get_experiment_times/${encodeURIComponent(String(sensorId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sensor_id undefined
   */
  getGetExperimentTimes(sensorId: string): __Observable<null> {
    return this.getGetExperimentTimesResponse(sensorId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the completed tests given water_source_id
   * @param params The `TestScheduleService.GetTestDetailsForAGivenTimeframeParams` containing the following parameters:
   *
   * - `water_source_id`:
   *
   * - `page`:
   *
   * - `per_page`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetTestDetailsForAGivenTimeframeResponse(params: TestScheduleService.GetTestDetailsForAGivenTimeframeParams): __Observable<__StrictHttpResponse<Deprecated_shield_test>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/completed_tests/${encodeURIComponent(String(params.waterSourceId))}/${encodeURIComponent(String(params.page))}/${encodeURIComponent(String(params.perPage))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Deprecated_shield_test>;
      })
    );
  }
  /**
   * Get the completed tests given water_source_id
   * @param params The `TestScheduleService.GetTestDetailsForAGivenTimeframeParams` containing the following parameters:
   *
   * - `water_source_id`:
   *
   * - `page`:
   *
   * - `per_page`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetTestDetailsForAGivenTimeframe(params: TestScheduleService.GetTestDetailsForAGivenTimeframeParams): __Observable<Deprecated_shield_test> {
    return this.GetTestDetailsForAGivenTimeframeResponse(params).pipe(
      __map(_r => _r.body as Deprecated_shield_test)
    );
  }

  /**
   * Used by Lambda
   */
  GenerateNextSetOfTestsFromTheTestScheduleUsedByALambdaFnResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/generate_next_tests/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by Lambda
   */
  GenerateNextSetOfTestsFromTheTestScheduleUsedByALambdaFn(): __Observable<null> {
    return this.GenerateNextSetOfTestsFromTheTestScheduleUsedByALambdaFnResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by Lambda
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetAllScheduledTestsToRunNowRequiredByALambdaFnResponse(XFields?: string): __Observable<__StrictHttpResponse<Deprecated_shield_schedule_test_dto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XFields != null) __headers = __headers.set('X-Fields', XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/get_tests_to_run_now/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Deprecated_shield_schedule_test_dto>;
      })
    );
  }
  /**
   * Used by Lambda
   * @param X-Fields An optional fields mask
   * @return Success
   */
  GetAllScheduledTestsToRunNowRequiredByALambdaFn(XFields?: string): __Observable<Deprecated_shield_schedule_test_dto> {
    return this.GetAllScheduledTestsToRunNowRequiredByALambdaFnResponse(XFields).pipe(
      __map(_r => _r.body as Deprecated_shield_schedule_test_dto)
    );
  }

  /**
   * Schedule a new manual/scheduled test with recurring options
   * @param payload undefined
   */
  ScheduleANewManualScheduledTestWithRecurringOptionsResponse(payload: Deprecated_shield_test_schedule2): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/test_schedule/shield/schedule_test/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Schedule a new manual/scheduled test with recurring options
   * @param payload undefined
   */
  ScheduleANewManualScheduledTestWithRecurringOptions(payload: Deprecated_shield_test_schedule2): __Observable<null> {
    return this.ScheduleANewManualScheduledTestWithRecurringOptionsResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the scheduled test given test_id
   * @param params The `TestScheduleService.GetAScheduledTestParams` containing the following parameters:
   *
   * - `test_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAScheduledTestResponse(params: TestScheduleService.GetAScheduledTestParams): __Observable<__StrictHttpResponse<Deprecated_shield_schedule_test_dto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/scheduled_test/${encodeURIComponent(String(params.testId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Deprecated_shield_schedule_test_dto>;
      })
    );
  }
  /**
   * Get the scheduled test given test_id
   * @param params The `TestScheduleService.GetAScheduledTestParams` containing the following parameters:
   *
   * - `test_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetAScheduledTest(params: TestScheduleService.GetAScheduledTestParams): __Observable<Deprecated_shield_schedule_test_dto> {
    return this.GetAScheduledTestResponse(params).pipe(
      __map(_r => _r.body as Deprecated_shield_schedule_test_dto)
    );
  }

  /**
   * Get all scheduled tests
   * @param params The `TestScheduleService.GetScheduledTestsParams` containing the following parameters:
   *
   * - `start_date`:
   *
   * - `end_date`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getScheduledTestsResponse(params: TestScheduleService.GetScheduledTestsParams): __Observable<__StrictHttpResponse<Deprecated_shield_schedule_test_dto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/scheduled_tests/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Deprecated_shield_schedule_test_dto>;
      })
    );
  }
  /**
   * Get all scheduled tests
   * @param params The `TestScheduleService.GetScheduledTestsParams` containing the following parameters:
   *
   * - `start_date`:
   *
   * - `end_date`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getScheduledTests(params: TestScheduleService.GetScheduledTestsParams): __Observable<Deprecated_shield_schedule_test_dto> {
    return this.getScheduledTestsResponse(params).pipe(
      __map(_r => _r.body as Deprecated_shield_schedule_test_dto)
    );
  }

  /**
   * Update a test schedule
   * @param payload undefined
   * @return Success
   */
  UpdateATestScheduleResponse(payload: Shield_test_schedule1): __Observable<__StrictHttpResponse<Shield_test_schedule1>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/test_schedule/shield/schedules/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test_schedule1>;
      })
    );
  }
  /**
   * Update a test schedule
   * @param payload undefined
   * @return Success
   */
  UpdateATestSchedule(payload: Shield_test_schedule1): __Observable<Shield_test_schedule1> {
    return this.UpdateATestScheduleResponse(payload).pipe(
      __map(_r => _r.body as Shield_test_schedule1)
    );
  }

  /**
   * Get all test schedules
   * @param params The `TestScheduleService.GetTestSchedulesParams` containing the following parameters:
   *
   * - `start_date`:
   *
   * - `end_date`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getTestSchedulesResponse(params: TestScheduleService.GetTestSchedulesParams): __Observable<__StrictHttpResponse<Shield_test_schedule1>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/schedules/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test_schedule1>;
      })
    );
  }
  /**
   * Get all test schedules
   * @param params The `TestScheduleService.GetTestSchedulesParams` containing the following parameters:
   *
   * - `start_date`:
   *
   * - `end_date`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  getTestSchedules(params: TestScheduleService.GetTestSchedulesParams): __Observable<Shield_test_schedule1> {
    return this.getTestSchedulesResponse(params).pipe(
      __map(_r => _r.body as Shield_test_schedule1)
    );
  }

  /**
   * Delete the test schedules given test_ids
   * @param params The `TestScheduleService.DeleteTestSchedulesParams` containing the following parameters:
   *
   * - `test_ids`:
   *
   * - `sensor_ids`: Comma separated test_ids
   */
  DeleteTestSchedulesResponse(params: TestScheduleService.DeleteTestSchedulesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sensorIds != null) __params = __params.set('sensor_ids', params.sensorIds.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/test_schedule/shield/schedules/${encodeURIComponent(String(params.testIds))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the test schedules given test_ids
   * @param params The `TestScheduleService.DeleteTestSchedulesParams` containing the following parameters:
   *
   * - `test_ids`:
   *
   * - `sensor_ids`: Comma separated test_ids
   */
  DeleteTestSchedules(params: TestScheduleService.DeleteTestSchedulesParams): __Observable<null> {
    return this.DeleteTestSchedulesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the test schedule given test_id
   * @param params The `TestScheduleService.GetATestScheduleParams` containing the following parameters:
   *
   * - `test_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetATestScheduleResponse(params: TestScheduleService.GetATestScheduleParams): __Observable<__StrictHttpResponse<Shield_test_schedule1>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/schedules/${encodeURIComponent(String(params.testId))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shield_test_schedule1>;
      })
    );
  }
  /**
   * Get the test schedule given test_id
   * @param params The `TestScheduleService.GetATestScheduleParams` containing the following parameters:
   *
   * - `test_id`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetATestSchedule(params: TestScheduleService.GetATestScheduleParams): __Observable<Shield_test_schedule1> {
    return this.GetATestScheduleResponse(params).pipe(
      __map(_r => _r.body as Shield_test_schedule1)
    );
  }

  /**
   * Get the test details given timeframe
   * @param params The `TestScheduleService.GetTestDetailsForAGivenTimeframe_1Params` containing the following parameters:
   *
   * - `start_date`:
   *
   * - `end_date`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetTestDetailsForAGivenTimeframe_1Response(params: TestScheduleService.GetTestDetailsForAGivenTimeframe_1Params): __Observable<__StrictHttpResponse<Deprecated_shield_test_details>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XFields != null) __headers = __headers.set('X-Fields', params.XFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/tests/${encodeURIComponent(String(params.startDate))}/${encodeURIComponent(String(params.endDate))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Deprecated_shield_test_details>;
      })
    );
  }
  /**
   * Get the test details given timeframe
   * @param params The `TestScheduleService.GetTestDetailsForAGivenTimeframe_1Params` containing the following parameters:
   *
   * - `start_date`:
   *
   * - `end_date`:
   *
   * - `X-Fields`: An optional fields mask
   *
   * @return Success
   */
  GetTestDetailsForAGivenTimeframe_1(params: TestScheduleService.GetTestDetailsForAGivenTimeframe_1Params): __Observable<Deprecated_shield_test_details> {
    return this.GetTestDetailsForAGivenTimeframe_1Response(params).pipe(
      __map(_r => _r.body as Deprecated_shield_test_details)
    );
  }

  /**
   * Used by Lambda
   */
  MaintenanceForAllScheduledTestsRequiredByALambdaFnResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/shield/tests_maintenance/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by Lambda
   */
  MaintenanceForAllScheduledTestsRequiredByALambdaFn(): __Observable<null> {
    return this.MaintenanceForAllScheduledTestsRequiredByALambdaFnResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TestScheduleService.CancelTheOngoingTestParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `test_number`:
   *
   * - `operating_status`:
   */
  CancelTheOngoingTestResponse(params: TestScheduleService.CancelTheOngoingTestParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/test_schedule/shield/${encodeURIComponent(String(params.sensorId))}/test/${encodeURIComponent(String(params.testNumber))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TestScheduleService.CancelTheOngoingTestParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `test_number`:
   *
   * - `operating_status`:
   */
  CancelTheOngoingTest(params: TestScheduleService.CancelTheOngoingTestParams): __Observable<null> {
    return this.CancelTheOngoingTestResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TestScheduleService.GetTheNextTestNumberBasedOnSensorIdAndOperatingStatusParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `operating_status`:
   */
  GetTheNextTestNumberBasedOnSensorIdAndOperatingStatusResponse(params: TestScheduleService.GetTheNextTestNumberBasedOnSensorIdAndOperatingStatusParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/test_schedule/test_number/${encodeURIComponent(String(params.sensorId))}/${encodeURIComponent(String(params.operatingStatus))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TestScheduleService.GetTheNextTestNumberBasedOnSensorIdAndOperatingStatusParams` containing the following parameters:
   *
   * - `sensor_id`:
   *
   * - `operating_status`:
   */
  GetTheNextTestNumberBasedOnSensorIdAndOperatingStatus(params: TestScheduleService.GetTheNextTestNumberBasedOnSensorIdAndOperatingStatusParams): __Observable<null> {
    return this.GetTheNextTestNumberBasedOnSensorIdAndOperatingStatusResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TestScheduleService {

  /**
   * Parameters for GetTestDetailsForAGivenTimeframe
   */
  export interface GetTestDetailsForAGivenTimeframeParams {
    waterSourceId: string;
    page: number;
    perPage: number;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetAScheduledTest
   */
  export interface GetAScheduledTestParams {
    testId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getScheduledTests
   */
  export interface GetScheduledTestsParams {
    startDate: string;
    endDate: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for getTestSchedules
   */
  export interface GetTestSchedulesParams {
    startDate: string;
    endDate: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for DeleteTestSchedules
   */
  export interface DeleteTestSchedulesParams {
    testIds: string;

    /**
     * Comma separated test_ids
     */
    sensorIds?: string;
  }

  /**
   * Parameters for GetATestSchedule
   */
  export interface GetATestScheduleParams {
    testId: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for GetTestDetailsForAGivenTimeframe_1
   */
  export interface GetTestDetailsForAGivenTimeframe_1Params {
    startDate: string;
    endDate: string;

    /**
     * An optional fields mask
     */
    XFields?: string;
  }

  /**
   * Parameters for CancelTheOngoingTest
   */
  export interface CancelTheOngoingTestParams {
    sensorId: number;
    testNumber: number;
    operatingStatus: number;
  }

  /**
   * Parameters for GetTheNextTestNumberBasedOnSensorIdAndOperatingStatus
   */
  export interface GetTheNextTestNumberBasedOnSensorIdAndOperatingStatusParams {
    sensorId: number;
    operatingStatus: number;
  }
}

export { TestScheduleService }
